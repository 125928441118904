import * as types from './actionTypes';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { graphGet, graphPost } from '../api/expressApi';
import { parseRawVoteDataToJsonArray } from '../helpers/voteDataHelper';
import { handleCommonError } from './errorActions';
import { LATEST_VOTES_MAX_COUNT } from '../helpers/voteDataHelper';

// ======= graph data for a topic ========================================

export function queryDataForTopic(id, query){
    const userMsg = `ggdj|${id}|${query.from}|${query.until}|${query.id}|${query.graphIndex}`;
    return {type: types.SEND_SERVERMESSAGE, userMsg};
}

// obsolete - websocket call
export function wsLoadRawVoteDataForTopic(id, dataProps){
    return function(dispatch){
        dispatch(showLoading());
        // temp fix to show loading bar
        setTimeout(function() {
            const userMsg = {
                request: "getVoteRowsForTopic",
                requestBody: {
                    id: id,
                    orderBy: dataProps.orderBy,
                    direction: dataProps.sortOrder,
                    limit: dataProps.pageSize,
                    offset: dataProps.offset
                }
            };

            dispatch({type: types.SEND_SERVERMESSAGE, userMsg: JSON.stringify(userMsg)});
        }, 300);        
    };
}

export function loadLatestVotesForTopic(id, limit = LATEST_VOTES_MAX_COUNT) {
    return async dispatch => {
		try {
			dispatch(showLoading());
			const topicsLatestVotesRequest = { topics: [id], limit };
			const topicVotesStats = (await graphPost('/topicVotes/statsForSelected', topicsLatestVotesRequest))[0];
			dispatch(loadTopicVotesStatsSuccess(topicVotesStats));
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);
		}
	};
}

export function loadRawVoteDataForTopic(id, dataParams) {
    return function (dispatch) {
        dispatch(showLoading());
        graphGet(`/topicvotes/${id}/rawvotes`, dataParams).then(votes => {
            const parsedVotes = parseRawVoteDataToJsonArray(votes);
            const payload = {topicId: id, votes: parsedVotes};
            if(dataParams.pageSize && parsedVotes.length >= dataParams.pageSize){
                payload.moreExist = true;
            }
            dispatch(loadTopicVotesSuccess(payload));
			dispatch(hideLoading());            
        },
        error => {
            handleCommonError(dispatch, error);      
		});
    };
}

export function resetGraphDataForTopic(id){
     return function(dispatch){
        let payload = {topicId: id};
        dispatch({type: types.RESET_TOPICDATA_SUCCESS, payload});
        dispatch(hideLoading());
    };
}

export function receiveNewVoteForTopic(topicId, topicName, vote) {
    return function(dispatch) {
        const topic = { id: topicId, name: topicName };
		dispatch({ type: types.RECEIVE_VOTE, payload: { vote, topic } });
    };
}

// ============= for internal use only =================
function loadTopicVotesStatsSuccess(topicVotesStats) {
    return { type: types.LOAD_TOPIC_VOTES_STATS, payload: { topicVotesStats } };
}

function loadTopicVotesSuccess(payload){
    return {type: types.LOAD_RAWVOTEDATA_SUCCESS, payload};
}