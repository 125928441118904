import React, { Component } from 'react';

export default class GenericNotFoundPage extends Component {
    render() {
        return (
            <div>
                <h1>Page not found</h1>
            </div>
        );
    }
}
