import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import "./css/buttonGroup.css";

export class ButtonGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.indexOfSelected,
            guid: uniqueId('button-group-radio-input-')
        };
        this.onAfterChange = this.onAfterChange.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.indexOfSelected !== state.selected) {
            return { selected: props.indexOfSelected };
        }

        return null;
    }

    onAfterChange(e) {
        this.setState({
            selected: e.currentTarget.value
        });
        this.props.onAfterChange(e.currentTarget.value);
    }

    render() {
        return (
            <div className="switch-field clearfix">
                {this.props.buttons.map((button, index) => {
                    return (
                        <span key={index}>
                            <input
                                type="radio"
                                id={`${this.state.guid}-${index}`}
                                name={this.state.guid}
                                value={index}
                                checked={index === this.state.selected}
                                onChange={this.onAfterChange}/>
                            <label htmlFor={`${this.state.guid}-${index}`}> {button} </label>
                        </span>
                    );
                })}
            </div>
        );
    }
}

ButtonGroup.propTypes = {
    /** @prop {array} buttons button labels as array of strings */
    buttons: PropTypes.array.isRequired,
    
    indexOfSelected: PropTypes.number.isRequired,
    onAfterChange: PropTypes.func.isRequired
};

export default ButtonGroup;