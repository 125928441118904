import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Toggle } from '../common/form/toggle';
import './css/afterVoteConfig.css';
import { useTranslation } from 'react-i18next';

const AfterVoteConfig = (props) => {
    const { values, setFieldValue } = props;
    const { basicSettings, postVoteSettings } = values;

    const { t } = useTranslation();

    function onFieldValueChange(field, value) {
		setFieldValue(field, value);
	}

    return (
        <div className="after-vote-config container-fluid clearfix" >
            <div className="col-md-12 col-xs-12 form-group">
                <Field name="postVoteSettings.showPeopleStats" value={postVoteSettings.showPeopleStats} >
                    {({ field }) => 
                        <Toggle value={field.value} onChange={(value) => onFieldValueChange(field.name, value)} />
                    } 
                </Field>
                <span className="checkbox-label">{t('topic_builder_after_vote_show')} <q>{t('topic_builder_after_vote_most_people')}</q></span>
            </div>

            <div className="col-md-12 col-xs-12 form-group">
            <Field name="postVoteSettings.showGenderStats" value={postVoteSettings.showGenderStats} >
                    {({ field }) => 
                        <Toggle value={field.value} onChange={(value) => onFieldValueChange(field.name, value)} />
                    } 
                </Field>
                <span className="checkbox-label">{t('topic_builder_after_vote_show')} <q>{t('topic_builder_after_vote_most_men_women')}</q></span>
            </div>
            
            <div className="col-md-12 col-xs-12 form-group">
            <Field name="postVoteSettings.showAgeStats" value={postVoteSettings.showAgeStats} >
                    {({ field }) => 
                        <Toggle value={field.value} onChange={(value) => onFieldValueChange(field.name, value)} />
                    } 
                </Field>
                <span className="checkbox-label">{t('topic_builder_after_vote_show')} <q>{t('topic_builder_after_vote_most_people_your_age')}</q></span>
            </div>
            
            <div className="col-md-12 col-xs-12 form-group">
            <Field name="postVoteSettings.showGenderByAgeStats" value={postVoteSettings.showGenderByAgeStats} >
                    {({ field }) => 
                        <Toggle value={field.value} onChange={(value) => onFieldValueChange(field.name, value)} />
                    } 
                </Field>
                <span className="checkbox-label">{t('topic_builder_after_vote_show')} <q>{t('topic_builder_after_vote_most_men_women_your_age')}</q></span>
            </div>
            
            <div className="col-md-12 col-xs-12 form-group">
            <Field name="postVoteSettings.showLocationStats" value={postVoteSettings.showLocationStats} >
                    {({ field }) => 
                        <Toggle value={field.value} onChange={(value) => onFieldValueChange(field.name, value)} />
                    } 
                </Field>
                <span className="checkbox-label">{t('topic_builder_after_vote_show')} <q>{t('topic_builder_after_vote_most_people_in_your_city')}</q></span>
            </div>
            
            <div className="col-md-12 col-xs-12 form-group">
            <Field name="postVoteSettings.showVoteAgain" value={postVoteSettings.showVoteAgain}>
                    {({ field }) => 
                        <Toggle value={field.value} onChange={(value) => onFieldValueChange(field.name, value)} />
                    } 
                </Field>
                <span className="checkbox-label">{t('topic_builder_after_vote_show')} <q>{t('topic_builder_after_vote_vote_again')}</q></span>
            </div>
            
            <div className={'col-md-12 col-xs-12 form-group' + (basicSettings.isPrivate ? ' copy-link-wrapper-disabled-container' : '')}>
                <Field name="postVoteSettings.showCopyLink" value={postVoteSettings.showCopyLink}
                       disabled={basicSettings.isPrivate} className='uitest-showcopylink-field'>
                    {({ field }) => 
                        <Toggle value={field.value} onChange={(value) => onFieldValueChange(field.name, value)} disabled={field.disabled} />
                    }
                </Field>
                <span className="checkbox-label">{t('topic_builder_after_vote_show')} <q>{t('topic_builder_after_vote_copy_voting_link')}</q></span>
                {basicSettings.isPrivate &&
                    <div>
                        <label className='copy-link-disabled-sublabel'>{t('topic_builder_after_vote_copy_voting_link_sublabel')}</label>
                    </div>}
            </div>
            
            <div className={'col-md-12 col-xs-12 form-group' + (basicSettings.isPrivate ? ' share-on-facebook-disabled-container' : '')}>
                <Field name="postVoteSettings.showFbShare" value={postVoteSettings.showFbShare}
                       disabled={basicSettings.isPrivate} className='uitest-showfbshare-field'>
                    {({ field }) =>
                        <Toggle value={field.value} onChange={(value) => onFieldValueChange(field.name, value)} disabled={field.disabled} />
                    } 
                </Field>
                <span className="checkbox-label">{t('topic_builder_after_vote_show')} <q>{t('topic_builder_after_vote_share_on_facebook')}</q></span>
                {basicSettings.isPrivate &&
                    <div>
                        <label className='share-on-facebook-disabled-sublabel'>{t('topic_builder_after_vote_share_on_facebook_sublabel')}</label>
                    </div>}
            </div>

            {/* After Vote Rewards toggle is bing disabled currently */}
            {/* <div className="form-group">
                    <Toggle value={this.state.rewardEnabled} onChange={this.toggleRewardEnable.bind(this)}/>
                    <span className="checkbox-label">Voting reward</span>
            </div> */}
            {/* {this.state.rewardEnabled ? 
            (<div>
                <BSFormControl label={'reward description'} className="clearfix row-fluid">
                    <ValidatedLocalFormControl
                        controlComponent={Control.text}
                        model={'.reward.text'}
                        validators={[
                            { name: 'length', validator: (value) => Validators.length(value, null, 160), message: Messages.errors.length(null, 160) }
                        ]}
                        />
                </BSFormControl>
                <BSFormControl label={'image url'} className="clearfix row-fluid">
                    <Control.text model={'.reward.image'} className="form-control" />
                </BSFormControl>
                <BSFormControl label={'redeem code'} className="clearfix row-fluid">
                    <Control.text model={'.reward.code'} className="form-control" />
                </BSFormControl>
                <BSFormControl label={'website'} className="clearfix row-fluid">
                    <Control.text model={'.reward.webSite'} className="form-control" />
                </BSFormControl>
                <BSFormControl label={'contact email'} className="clearfix row-fluid">
                    <Control.text model={'.reward.email'} className="form-control" />
                </BSFormControl>
            </div>) 
            : null} */}
        </div>
    );
}; 
                
AfterVoteConfig.propTypes = {
    values: PropTypes.shape({
        postVoteSettings: PropTypes.shape({
            showPeopleStats: PropTypes.bool,
            showGenderStats: PropTypes.bool,
            showAgeStats: PropTypes.bool,
            showGenderByAgeStats: PropTypes.bool,
            showLocationStats: PropTypes.bool,
            showVoteAgain: PropTypes.bool,
            showCopyLink: PropTypes.bool,
            showFbShare: PropTypes.bool,
            reward: PropTypes.shape({
                text: PropTypes.string,
                image: PropTypes.string,
                code: PropTypes.string,
                webSite: PropTypes.string,
                email: PropTypes.string
            })
        }),
        basicSettings: PropTypes.shape({isPrivate: PropTypes.bool})
    }).isRequired,
    backendErrors: PropTypes.shape({
        postVoteSettings: PropTypes.object
    }),
	setFieldValue: PropTypes.func.isRequired,
};

export default AfterVoteConfig;