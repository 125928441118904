import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../common/modal';
import getContactFormPage from '../../scenes/ContactFormPage';

const ContactFormModal = ({ onClose, onSubmit, data }) => {
	const ContactFormPage = getContactFormPage();
	return (
		<Modal 
			className="contact-form-modal text-left"
			title='Contact kazva.bg support'
			onClose={onClose}
			width={640}
			hasFooter={false}
			enableOverlayClickClose={false}
		>
            <ContactFormPage presetData={data} onSupportContacted={onSubmit} onDiscard={onClose} />
		</Modal>
	);
};

ContactFormModal.propTypes = {
	/** @prop {func} action to close modal dialog */
    onClose: PropTypes.func.isRequired,
    
	/** @prop {func} onSubmit action callback */
	onSubmit: PropTypes.func,
	
	data: PropTypes.shape({
		email: PropTypes.string,
		subject: PropTypes.string,
		message: PropTypes.string,
		enableTechnicalData: PropTypes.bool
	})
};

export default ContactFormModal;