import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Popover from './popover';

class PopoverButton extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isOpen: false,
			isVisible: false,
			popoverStyle: {},
			carretStyle: {}
        };
        
        this.popoverContainer = React.createRef();
		
		this.handlePopoverButtonClick = this.handlePopoverButtonClick.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount(){		
		this.attachEventListeners();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.forceClose && this.props.forceClose) {
			this.setState({ isOpen: false });
		} else {
			if (prevState.isOpen !== this.state.isOpen) {
				if (this.state.isOpen === true) {
					if (this.props.onPopoverShow) {
						this.props.onPopoverShow();
					}

					this.setPopoverDimentions();
				} else {
					this.resetPopoverDimentions();
				}
			}
		}
	}

	componentWilUnmount(){
		this.removeEventListeners();
	}

	setPopoverDimentions() {
		const popoverStyle = this.getPopoverStyle();
		popoverStyle.opacity = 1;
		popoverStyle.width = this.popoverWidth;
	
		const carretStyle = this.getCarretStyle();
		carretStyle.opacity = 1;

		this.setState({
			popoverStyle,
			carretStyle,
			isVisible: true
		});
	}

	resetPopoverDimentions() {
		this.setState({
			popoverStyle: {},
			carretStyle: {},
			isVisible: false
		});
	}

	handlePopoverButtonClick(){
		const shouldBeOpen = !this.state.isOpen;
		let newState = { isOpen:  shouldBeOpen };

		if (!shouldBeOpen) {
			newState = { isVisible: false };
		}

		this.setState(newState);
	}

	handleClick(ev) {
		if (this.state.isOpen && this.popoverContainer && this.popoverContainer.current && !this.popoverContainer.current.contains(ev.target)) {
			this.setState({ isOpen: false });
		}
	}

	attachEventListeners(){
		document.addEventListener('click', this.handleClick);
	}

	removeEventListeners(){
		document.removeEventListener('click', this.handleClick);
	}

	getPopoverStyle() {
		let position = 'left';
		if (this.props.position && this.props.position.length > 0) {
			position = this.props.position;
		}

		let popoverStyle = {
			opacity: 1
		};

		switch (position) {
			case 'left' : {
				const leftPopoverStyle = this.getPopoverLeftPosition();
				popoverStyle = Object.assign({}, popoverStyle, leftPopoverStyle);
				break;
			}
			case 'right' : {
				const rightPopoverStyle = this.getPopoverRightPosition();
				popoverStyle = Object.assign({}, popoverStyle, rightPopoverStyle);
				break;
			}
			case 'top' : {
				const rightPopoverStyle = this.getPopoverTopPosition();
				popoverStyle = Object.assign({}, popoverStyle, rightPopoverStyle);
				break;
			}
			case 'bottom' : {
				const rightPopoverStyle = this.getPopoverBottomPosition();
				popoverStyle = Object.assign({}, popoverStyle, rightPopoverStyle);
				break;
			}
			default : {
				const leftPopoverStyle = this.getPopoverLeftPosition();
				popoverStyle = Object.assign({}, popoverStyle, leftPopoverStyle);
			}
		}

		popoverStyle.height = `auto`;

		return popoverStyle;
	}

	getCarretStyle() {
		let position = 'left';
		if (this.props.position && this.props.position.length > 0) {
			position = this.props.position;
		}

		let carretStyle = {};

		switch (position) {
			case 'left' : {
				const leftCarretStyle = this.getCarretLeftPosition();
				carretStyle = Object.assign({}, carretStyle, leftCarretStyle);
				break;
			}
			case 'right' : {
				const rightCarretStyle = this.getCarretRightPosition();
				carretStyle = Object.assign({}, carretStyle, rightCarretStyle);
				break;
			}
			case 'top' : {
				const topCarretStyle = this.getCarretTopPosition();
				carretStyle = Object.assign({}, carretStyle, topCarretStyle);
				break;
			}
			case 'bottom' : {
				const bottomCarretStyle = this.getCarretBottomPosition();
				carretStyle = Object.assign({}, carretStyle, bottomCarretStyle);
				break;
			}
			default : {
				const leftCarretStyle = this.getCarretLeftPosition();
				carretStyle = Object.assign({}, carretStyle, leftCarretStyle);
			}
		}

		return carretStyle;
	}

	getPopoverLeftPosition() {
		const popoverWidth = this.popoverWidth !== "auto" ? this.popoverWidth : this.popoverNode.offsetWidth;
		const popoverHeight = this.popoverNode.offsetHeight;

		const btnHeight = this.popoverButtonNode.offsetHeight;
		const style = {};

		style.marginLeft = `-${popoverWidth + (this.carretOffsetWidth / 2)}px`;

		const verticalPosition = (popoverHeight / 2) + (btnHeight / 2);
		style.marginTop = `-${verticalPosition}px`;

		return style;
	}

	getCarretLeftPosition() {
		const style = {};

		style.right = `-${this.carretOffsetWidth / 2}px`;
		style.top = `${this.popoverNode.offsetHeight / 2}px`;
		style.marginTop = `-${this.carretOffsetHeight / 2}px`;

		return style;
	}

	getPopoverRightPosition() {
		const style = {};
		const popoverWidth = this.popoverWidth !== "auto" ? this.popoverWidth : this.popoverNode.offsetWidth;
		const popoverHeight = this.popoverNode.offsetHeight;
		const btnHeight = this.popoverButtonNode.offsetHeight;

		style.marginRight = `${popoverWidth + (this.carretOffsetWidth / 2)}px`;

		const verticalPosition = (popoverHeight / 2) + (btnHeight / 2);
		style.marginTop = `-${verticalPosition}px`;

		return style;
	}

	getCarretRightPosition() {
		const style = {};

		style.left = `-${(this.carretOffsetWidth / 2)}px`;
		style.top = `${this.popoverNode.offsetHeight / 2}px`;
		style.marginTop = `-${this.carretOffsetHeight / 2}px`;

		return style;
	}

	getPopoverTopPosition() {
		const style = {};
		const popoverWidth = this.popoverWidth !== "auto" ? this.popoverWidth : this.popoverNode.offsetWidth;

		style.marginLeft = `-${(popoverWidth / 2) - (this.popoverButtonNode.offsetWidth / 2)}px`;

		const verticalPosition = (this.popoverNode.offsetHeight + this.popoverButtonNode.offsetHeight) + (this.carretOffsetHeight / 2);
		style.marginTop = `-${verticalPosition}px`;

		return style;
	}

	getPopoverBottomPosition() {
		const style = {};
		const popoverWidth = this.popoverWidth !== "auto" ? this.popoverWidth : this.popoverNode.offsetWidth;

		style.marginLeft = `-${(popoverWidth / 2) - (this.popoverButtonNode.offsetWidth / 2)}px`;

		const verticalPosition = (this.popoverButtonNode.offsetHeight) + (this.carretOffsetHeight / 2);
		style.marginTop = `${verticalPosition}px`;

		return style;
	}

	getCarretTopPosition() {
		const style = {};
		const popoverWidth = this.popoverWidth !== "auto" ? this.popoverWidth : this.popoverNode.offsetWidth;

		style.left = `${(popoverWidth / 2) - (this.carretOffsetWidth/2)}px`;
		style.top = `${this.popoverNode.offsetHeight}px`;
		style.marginTop = `-${this.carretOffsetHeight / 2}px`;

		return style;
	}

	getCarretBottomPosition() {
		const style = {};
		const popoverWidth = this.popoverWidth !== "auto" ? this.popoverWidth : this.popoverNode.offsetWidth;

		style.left = `${(popoverWidth / 2) - (this.carretOffsetWidth/2)}px`;
		style.top = `0px`;
		style.marginTop = `-${this.carretOffsetHeight / 2}px`;

		return style;
	}
	
	get getClassName(){
		return `btn btn-popover ${this.props.className} uitest-btn-popover`;
	}

	get popoverWidth() {
		const { popoverWidth } = this.props;

		return popoverWidth ? popoverWidth : 'auto';
	}

	get carretNode() {
		return this.popoverNode.getElementsByClassName('pop-over-caret')[0];
	}

	get popoverNode() {
		return this.popoverContainer.current.getElementsByClassName('pop-over-component')[0];
	}

	get popoverButtonNode() {
		return this.popoverContainer.current.getElementsByClassName('btn-popover')[0];
	}

	get carretOffsetWidth() {
		return this.carretNode ? this.carretNode.offsetWidth : 0;
	}

	get carretOffsetHeight() {
		return this.carretNode ? this.carretNode.offsetHeight : 0;
	}

	render() {
		const { text, popoverTitle, noPadding, children, popoverWidth, disabled } = this.props;
		
		return (
			<div className="pop-over-container" ref={this.popoverContainer}>
				<button type="button" disabled={disabled} className={this.getClassName} onClick={this.handlePopoverButtonClick}>
					{text}
				</button>

				{this.state.isOpen &&
					<Popover
						style={this.state.popoverStyle}
						carretStyle={this.state.carretStyle}
						className={`pop-over-component uitest-popover-component ${this.state.isOpen ? 'show' : 'hide'}`}
						isOpen={this.state.isOpen}
						title={popoverTitle}
						noPadding={noPadding}
						popoverWidth={popoverWidth}
					>
						{children}
					</Popover>
				}
			</div>
		);
	}
}

PopoverButton.propTypes = {
	/** @prop {string} text the button label text */
	text: PropTypes.any,

	/** @prop {string} popoverTitle The popover modal title. Optional */
	popoverTitle: PropTypes.string,

	/** @prop {bool} noPadding sets left and right padding to 0 or 12px. Optional. Defaults to true */
	noPadding: PropTypes.bool,

	/** @prop {number} popoverWidth the width of the file list popover in pixels. defaults to "auto" */
	popoverWidth: PropTypes.number,

	/** @prop {string} position sets the position of the tooltip. 
	 * Possible values: "top", "bottom", "left", "right" 
	 * Defaults to "left". Optional.
	*/
	position: PropTypes.string,

	/** @prop {func} onPopoverShow invoked after popover button click and right before displaying the popover.
	 * Useful for dom or prepend logic before actual show (by opacity set to 1) of the popover modal and overlay.
	 */
	onPopoverShow: PropTypes.func,

	className: PropTypes.string,

	children: PropTypes.node,

	disabled: PropTypes.bool
};

export default PopoverButton;