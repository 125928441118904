import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';

import * as entitiesActions from "../../actions/entitiesActions";
import EntitiesGrid from '../../components/entities/entitiesGrid';
import Logger from '../../helpers/logger';
import * as modalActions from "../../actions/modalActions";
import {switchToEntity} from "../../actions/entitiesActions";
import SidePanel from '../../components/common/sidePanel';
import { isOnMobile } from '../../helpers/utils';
import { PlusSquare } from 'react-feather';
import i18n from '../../i18n';


export class EntitiesPage extends Component {
    constructor(props) {
        super(props);

        let detailsOpen = false;
        let detailsInCreateMode = false;

        if (props.params.id && props.params.id.length > 0) {
            detailsOpen = true;
        } else if (props.location.pathname.indexOf('create') > -1) {
            detailsOpen = true;
            detailsInCreateMode = true;
        }

        this.state = {
            detailsOpen,
            detailsInCreateMode,
            t: i18n.t.bind(i18n)
        };

        this.handleDeleteEntity = this.handleDeleteEntity.bind(this);
        this.handleEntitySwitch = this.handleEntitySwitch.bind(this);
        this.handleClosePanelClick = this.handleClosePanelClick.bind(this);
        this.handleDetailsOpen = this.handleDetailsOpen.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (state.detailsInCreateMode && state.detailsOpen && props.location.pathname.indexOf('create') === -1) {
            return { detailsOpen: false, detailsInCreateMode: false };
        }

        return null;
    }

    componentDidMount() {
        this.props.actions.getAllEntities(true);
    }

    getChildContext() {
        return {
            showModal: this.props.actions.showModal,
            hideModal: this.props.actions.hideModal
        };
    }

    handleDeleteEntity(entityId){
        const entity = this.props.entities.find(e => e.id === entityId);
        const userCurrentEntity = this.props.userEntities.find(e => e.id === entityId);
        
        if (entity) {
            if (userCurrentEntity) {
                this.props.actions.switchToEntity(Number(this.props.userEntityId));
            }

            this.props.actions.deleteEntity(entity.id, entity.name);
            Logger.debug(`Organization with id: ${entity.name} was deleted`);
        } else {
            Logger.error(`No organization found for id: ${entityId}`);
        }
    }

    handleEntitySwitch(entityId){
        if (entityId) {
            Logger.debug('Switching to organization: ' + entityId);
            this.props.actions.switchToEntity(Number(entityId));
        } 
    }

    handleClosePanelClick() {
        this.setState({ 
            detailsOpen: false 
        });

        const location = `/entities`;
        this.props.router.push(location);
    }

    handleDetailsOpen(isEditMode, entityId) {
        const location = isEditMode ? `/entities/${entityId}` : '/entities/create';
        
        this.setState({ 
            detailsOpen: true,
            detailsInCreateMode: !isEditMode
        });

		this.props.router.push(location);
    }

    render() {
        const entities = this.props.entities;
        const t = this.state.t;
        return <React.Fragment>
            <EntitiesGrid 
                entities={entities} 
                onDetailsOpen={this.handleDetailsOpen} 
                onEntityDelete={this.handleDeleteEntity} 
                onSwitchEntity={this.handleEntitySwitch} 
                onAlertToggle={this.handleAlertToggle}
            />

            {this.state.detailsOpen && !this.state.detailsInCreateMode ?
                <SidePanel 
                    className="uitest-entitiy-details-sidepanel" 
                    title="organization details" 
                    onClose={this.handleClosePanelClick}
                >
                    { this.props.children }
                </SidePanel>

                :

                this.state.detailsOpen && this.state.detailsInCreateMode && this.props.children
            }

            {!isOnMobile() && !this.state.detailsOpen && <button onClick={() => this.handleDetailsOpen(false)} type="button" className="btn-float btn-transparent btn-float-second">
                <label>{t('org_management_button_create_new')}</label>
                <div className="btn btn-primary"><PlusSquare size={32} /></div>
            </button>}
        </React.Fragment>;
    }
}

EntitiesPage.propTypes = {
    actions: PropTypes.shape({
        getAllEntities: PropTypes.func.isRequired,
        switchToEntity: PropTypes.func.isRequired,
        deleteEntity: PropTypes.func.isRequired,
        showModal: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
    }).isRequired,
    entities: PropTypes.array,
    children: PropTypes.any,
    params: PropTypes.shape({
        id: PropTypes.string
    }),
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string
    }),
    userEntities: PropTypes.array,
    userEntityId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

EntitiesPage.childContextTypes = {
    hideModal: PropTypes.func,
    showModal: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        entities: state.entitiesList || [],
        userEntities: state.entities || [],
        userEntityId: state.auth && state.auth.user ? state.auth.user.entity.id : null
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({...entitiesActions, ...modalActions, switchToEntity}, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesPage);

