import moment from 'moment';

export const DATE_FILTER_OPTIONS = [ // TODO: objectify the values, provide methods for get_default and get_custom
    'filter_date_last_hour',
    'filter_date_today', 
    'filter_date_last_7_days', 
    'filter_date_last_30_days',
    'filter_date_select_range',
];

export function getDateRangeByPeriodIndex(periodIndex) {
    let selectedMomentInterval = { dateFrom: null, dateTo: null, isCustom: false, displayFormat: null };
    let dateToMoment = moment(Date.now());
    let dateFromMoment = moment(Date.now());

    switch (Number(periodIndex)) {
        case 0:
            dateFromMoment = moment().subtract(1, 'hours');
            break;
        case 1:
            dateFromMoment = moment().startOf('day');
            break;
        case 2:
            dateFromMoment = moment().subtract(1, 'weeks');
            break;
        case 3:
            dateFromMoment = moment().subtract(1, 'months');
            break;
        case 4:
            selectedMomentInterval.isCustom = true;
            break;
        default:
            dateFromMoment = moment().startOf('day');
    }
    selectedMomentInterval.dateFrom = dateFromMoment.format();
    selectedMomentInterval.dateTo = dateToMoment.format();
    return selectedMomentInterval;
}

