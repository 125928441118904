import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import NotificationSystem from 'react-notification-system';

class NotificationsContainer extends React.Component {

  componentDidUpdate() {
    const {message, level, autoDismiss} = this.props.notification;
    this.notificationSystem.addNotification({
      message,
      level, 
      autoDismiss
    });
  }

  render() {
    return (
      <NotificationSystem ref={notificationSystem => this.notificationSystem = notificationSystem} />
    );
  }
}

function mapStateToProps(state) {
  return {
    notification: state.notification
  };
}

NotificationsContainer.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.string,
    level: PropTypes.string,
    autoDismiss: PropTypes.any,
  }),
};

export default connect(
  mapStateToProps
)(NotificationsContainer);