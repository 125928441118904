import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Modal from '../common/modal';
import Messages from '../../helpers/messages';
import { Star } from 'react-feather';

const NoRootTopicModal = ({ data, onClose, width }) => {
	return (
		<Modal
			title={`${Messages.topics.noRootTopicTitle}`}
			onClose={onClose}
			width={width}
			hasFooter={false}
			enableOverlayClickClose={false}
			className='topic-links-modal'
		>
			{data.canEditEntity ? (
				<>
					<p>{Messages.topics.noRootTopicMessageManager}</p>
					<div className='btn-toolbar pull-right clearfix'>
						<Link to={`/account/details/${data.entityId}`} className='btn btn-outline'>
							<Star size={14} /> my organization
						</Link>
					</div>
				</>
			) : (
				<p>{Messages.topics.noRootTopicMessage}</p>
			)}
		</Modal>
	);
};

NoRootTopicModal.propTypes = {
	data: PropTypes.shape({
		entityId: PropTypes.number.isRequired,
		canEditEntity: PropTypes.bool.isRequired
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	width: PropTypes.number
};

export default NoRootTopicModal;
