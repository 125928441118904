import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Toggle } from '../../common/form/toggle';
import { 
	VOTE_FILTERING_OPTIONS_BY_AUTH_TYPE, 
	VOTE_FILTERING_OPTIONS_BY_REPEATED_VOTER,
	getDefaultFilterOptions
} from '../../../helpers/graphsService';
import AutosuggestTagsSelector from '../../common/form/autosuggestTagsSelector';
import i18n from '../../../i18n';

export class VotesFilters extends Component {
	constructor(props) {
		super(props);

		const initialSelections = { ...props.initialSelections };
		this.state = { 
			initialSelections, 
			options: initialSelections,
            t: i18n.t.bind(i18n),
		};

		this.updateOptionsValue = this.updateOptionsValue.bind(this);
		this.onSubmitFilters = this.onSubmitFilters.bind(this);
		this.onResetFilters = this.onResetFilters.bind(this);
	}

	updateOptionsValue(fieldName, value) {
		this.setState({ 
			options: Object.assign({}, this.state.options, { [fieldName]: value })
		});
	}
	
	onSubmitFilters() {
		this.props.onVotesFilterChange(this.state.options);
	}

	onResetFilters() {
		const defaultOptions = getDefaultFilterOptions();
		this.setState({
			options:  defaultOptions
		}, () => this.props.onVotesFilterChange(defaultOptions));
	}

	renderVoteFilteringTogglesByAuthType() {
		const { t } = this.state;

		return VOTE_FILTERING_OPTIONS_BY_AUTH_TYPE.map((option, index) => {
			return (
                <div className='form-group' key={index}>
                    <Toggle
                        value={this.state.options[option.fieldName]}
                        onChange={value => this.updateOptionsValue(option.fieldName, value)}
                        inverse />
                    <span className='checkbox-label'>{t(option.label)}</span>
                </div>
            );
		});
	}

	renderVoteFilteringTogglesByVoterType() {
		const { t } = this.state;

		return VOTE_FILTERING_OPTIONS_BY_REPEATED_VOTER.map((option, index) => {
			return (
                <div className='form-group' key={index}>
                    <Toggle
                        value={this.state.options[option.fieldName]}
                        onChange={value => this.updateOptionsValue(option.fieldName, value)}
                        inverse />
                    <span className='checkbox-label'>{t(option.label)}</span>
                </div>
            );
		});
	}

	render() {
		const { t } = this.state;

		return (
			<React.Fragment>
				<div className={this.props.className}>
					<div className='col-md-6 votes-filters-sources'>
						<div className='row-fluid'>
							<div className='col-md-4'>
								<label className="text-label" >{t('topic_filters_and_from_sources')}</label>
							</div>

							<div className='col-md-8'>
								<AutosuggestTagsSelector
									value={ this.state.options.sources }
									data={ this.props.sources }
									onChange={ (selectedSources) => this.updateOptionsValue('sources', selectedSources) }
									className={ `form-control votes-filters-sources-textbox` }
								/>
							</div>
						</div>
					</div>
					<div className='col-md-6'>				
						<div className="row-fluid">
							<div className="col-md-5">
								{this.renderVoteFilteringTogglesByAuthType()}
							</div>
							<div className="col-md-7">
								{this.renderVoteFilteringTogglesByVoterType()}
							</div>
						</div>
					</div>
				</div>
				<div className="btn-toolbar pull-right">
					<button type="button" className="btn btn-outline btn-white uitest-btn-apply" onClick={this.onSubmitFilters}>{t('topic_filters_button_apply')}</button>
					<button type="button" className="btn btn-outline btn-white uitest-btn-reset" onClick={this.onResetFilters}>{t('topic_filters_button_reset')}</button>
				</div>
			</React.Fragment>
		);
	}
}

VotesFilters.propTypes = {
	/** @prop {func} onVotesFilterChange send when apply filters button is clicked */
	onVotesFilterChange: PropTypes.func.isRequired,
	/** @prop {object} initialSelections filters selected options on render */
	initialSelections: PropTypes.shape({
		facebook: PropTypes.bool, 
		email: PropTypes.bool, 
		anonymous: PropTypes.bool, 
		latestPerVoter: PropTypes.bool,
		sources: PropTypes.array
	}),
	className: PropTypes.string,
	sources: PropTypes.array
};

VotesFilters.defaultProps = {
	initialSelections: {
		anonymous: true,
		facebook: true,
		email: true,
		latestPerVoter: false,
		sources: []
	},
	sources: []
};

export default VotesFilters;