import React from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Icons from 'react-feather';
import { SUBSCRIPTIONS } from 'hollerlive-shared/constants';

import SidePanel from '../../components/common/sidePanel';
import Card from '../../components/common/card';
import { isOnMobile, isOnLargeMobile } from '../../helpers/utils';

const ICON_SIZE = 20;

export const tableSpecificationsForInvoices = (invoices) => {
    const unsuccessfullInvoiceIds = invoices.filter(invoice => !invoice.status || !isInvoiceSuccessfull(invoice.status));

    function isInvoiceSuccessfull(statusInvoice) {
        return statusInvoice ?
            (SUBSCRIPTIONS.STATUSES.COMPLETED.toLowerCase() === statusInvoice.toLowerCase() 
                || SUBSCRIPTIONS.STATUSES.EXPIRED.toLowerCase() === statusInvoice.toLowerCase()) 
            :
            false;
    };

    const trCompletedPaymentClass = (invoice) => {
        const isCompleted = isInvoiceSuccessfull(invoice.status);
        return isCompleted ? 'uitest-empty' : 'text-white uitest-white';
    };

    const statusIcon = (status) => {
        switch (status.toUpperCase()) {
            case SUBSCRIPTIONS.STATUSES.COMPLETED:
            case SUBSCRIPTIONS.STATUSES.EXPIRED:
                return <Icons.Check size={ICON_SIZE} />;
            case SUBSCRIPTIONS.STATUSES.CANCELLED:
            case SUBSCRIPTIONS.STATUSES.FAILED:
                return <Icons.XCircle size={ICON_SIZE} />;
            default:
                return <span />;
        }
    };

    const dateFormat = (date) => {
        return date && date.length > 0 ? 
            moment(date).format('MMM Do, YYYY') : 
            '';
    };

    const tierNameAndRecurring = (recurring, invoice) => {
        let tierName = '', 
            recurringPeriod = '';

        if (invoice.tierId) {
            tierName = SUBSCRIPTIONS.PLANS.find(plan => plan.tierId === Number(invoice.tierId)).name;
        }         
        
        if (recurring && recurring.length > 0) {
            const timePeriod = Object.keys(SUBSCRIPTIONS.PERIODS)
                .find(periodKey => 
                    SUBSCRIPTIONS.PERIODS[periodKey].substring(0, 3).toLowerCase() === recurring.substring(0, 3).toLowerCase()
                );
            recurringPeriod = SUBSCRIPTIONS.PERIODS[timePeriod];
        }


        return `${tierName} ${recurringPeriod}`;
    };

    const invoiceAmount = (amount) => {
        // necessary because amount is saved in cents
        const amountEur = amount ? ( Number(amount) / 100 ).toFixed(2) : '';
        return <span>
            &euro;{amountEur}
        </span>;
    };

    const invoiceStatusAction = (status, invoice) => {
        if (invoice.invoiceLink && invoice.invoiceLink.length > 0) {
            switch (status.toUpperCase()) {
                case SUBSCRIPTIONS.STATUSES.COMPLETED:
                case SUBSCRIPTIONS.STATUSES.EXPIRED:
                    return <div>
                        <a href={invoice.invoiceLink} target="_blank" rel="noopener noreferrer" >
                            <Icons.Download size={ICON_SIZE} />&nbsp;
                            <span className="text-label">Invoice</span>
                        </a>
                    </div>;
                case SUBSCRIPTIONS.STATUSES.CANCELLED:
                case SUBSCRIPTIONS.STATUSES.FAILED:
                    return <div className="invoice-resolve-action">
                        <a href={invoice.invoiceLink} className="text-white" target="_blank" rel="noopener noreferrer" >
                            <Icons.AlertTriangle size={ICON_SIZE} />&nbsp;
                            <span className="text-label">Resolve</span>
                        </a>
                    </div>;
                default:
                    return <span />;
            }
        } else {
            return <span />;
        }
    };

    const setTdStyle = (_cell, invoice) => {
        let unsuccessfullTdStyle = {};

        const invoiceWithUnsuccessfullState = unsuccessfullInvoiceIds.find(unsuccessfullInvoice => invoice && unsuccessfullInvoice.id === invoice.id);
        if (invoiceWithUnsuccessfullState) {
            unsuccessfullTdStyle = {
                backgroundColor: '#E32B2B'
            }; 
        } 

        return unsuccessfullTdStyle;
    };

    const tierCellClassNameProp = (_cell, invoice) => {
        let invoiceColumnStyle = 'text-uppercase';

        const invoiceWithUnsuccessfullState = unsuccessfullInvoiceIds.find(unsuccessfullInvoice => invoice && unsuccessfullInvoice.id === invoice.id);
        if (!invoiceWithUnsuccessfullState) {
            invoiceColumnStyle += ' text-blue';
        } 

        return invoiceColumnStyle;
    };

    return {
        trCompletedPaymentClass,
        statusIcon,
        dateFormat,
        tierNameAndRecurring,
        invoiceAmount,
        invoiceStatusAction,
        setTdStyle,
        tierCellClassNameProp
    };
};

export const SubscriptionInvoicesPage = ({ invoices, router }) => {
    const statusIconColumnWidth = '3%';
    const dateColumnWidth = isOnMobile() ? '8%' : isOnLargeMobile() ? '12%' : '65%';
    const tierAndRecurringColumnWidth = '13%';
    const amountColumnWidth = '10%';
    const statusColumnWidth = '11%';
  
    const handleClosePanelClick = () => {
        router.goBack();
    };

    return <SidePanel 
        title='Payments History' 
        onClose={handleClosePanelClick}
        showButtons={false}
        className='invoice-list'
    >
        <Card title={'Payments History & Invoices'}>
            <p className="text-muted text-uppercase">See all recurring payments associated with your plan and download invoices for each payment</p>

            <BootstrapTable
                data={invoices} 
                options={{
                    defaultSortName: 'createdOn',
                    defaultSortOrder: 'desc',
                }} 
                bordered={false}
                tableHeaderClass='invisible'
                trClassName={tableSpecificationsForInvoices(invoices).trCompletedPaymentClass}>

                <TableHeaderColumn dataField='id' isKey hidden dataSort={ true } />
                <TableHeaderColumn dataField='status' 
                    width={statusIconColumnWidth} 
                    dataFormat={tableSpecificationsForInvoices(invoices).statusIcon} 
                    tdStyle={tableSpecificationsForInvoices(invoices).setTdStyle} />
                <TableHeaderColumn dataField='createdOn' 
                    width={dateColumnWidth} 
                    dataFormat={tableSpecificationsForInvoices(invoices).dateFormat} 
                    tdStyle={tableSpecificationsForInvoices(invoices).setTdStyle} />
                <TableHeaderColumn dataField='recurring' 
                    width={tierAndRecurringColumnWidth}
                    dataFormat={tableSpecificationsForInvoices(invoices).tierNameAndRecurring} 
                    tdStyle={tableSpecificationsForInvoices(invoices).setTdStyle} 
                    columnClassName={tableSpecificationsForInvoices(invoices).tierCellClassNameProp}/>
                <TableHeaderColumn dataField='amount' 
                    width={amountColumnWidth}
                    dataFormat={tableSpecificationsForInvoices(invoices).invoiceAmount} 
                    tdStyle={tableSpecificationsForInvoices(invoices).setTdStyle} />
                <TableHeaderColumn dataField='status' 
                    width={statusColumnWidth} 
                    dataFormat={tableSpecificationsForInvoices(invoices).invoiceStatusAction} 
                    dataAlign='center'
                    tdStyle={tableSpecificationsForInvoices(invoices).setTdStyle} />

            </BootstrapTable>
        </Card>
    </SidePanel>;
};

SubscriptionInvoicesPage.propTypes = {
    invoices: PropTypes.arrayOf(PropTypes.shape({
        createdOn: PropTypes.string,
        recurring: PropTypes.string,
        tierId: PropTypes.number,
        amount: PropTypes.string,
        status: PropTypes.string,
        invoiceLink: PropTypes.string,
    })).isRequired,

    router: PropTypes.object,
};

function mapStateToProps(state) {
    let invoices = [];

	if (state.entities.length > 0) {
        const entity = state.entities[0];
        invoices = entity.subscriptionsData ? entity.subscriptionsData.subscriptions : [];
    }

	return {
		invoices: invoices.filter(invoice => invoice.status && invoice.status !== SUBSCRIPTIONS.STATUSES.PENDING.toLowerCase()),
	};
}

export default connect(mapStateToProps)(SubscriptionInvoicesPage);