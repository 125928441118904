import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-feather';
import { isEmpty, isEqual } from 'lodash';
import { withRouter, browserHistory } from 'react-router';
import { Formik, Form } from 'formik';

import ProgressBar from "./progressBar";
import BasicTopicDetails from "./basicTopicDetails";
import TopicConfiguration from "./topicConfiguration";
import LinkConfiguration from './linkConfiguration';
import QuestionsAndAnswers from "./questionsAndAnswers";
import AfterVoteConfig from "./afterVoteConfig";
import GreetingMessages from "./greetings";
import Translations from "./translations";
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import { getTopicLinksModalConfig } from '../../helpers/modalDialogsHelper';
import { TOPIC_LINKS_MODAL } from '../../helpers/modalTypes';
import { COMPARATORS } from '../../helpers/featuresHelper';
import { ENTITY_FEATURES } from 'hollerlive-shared/constants';

import "./css/topicBuilder.css";
import Card from '../common/card';
import Logger from '../../helpers/logger';
import { getTopicModel } from '../../actions/models/topicModel';
import {
	STEPS_NAMES,
	stepStates,
    validateBasicTopicDetails,
    validateTopicConfiguration,
	validateQna,
	validateGreetings,
	validateTranslations,
	validateLinkConfig
} from '../../helpers/topicBuilderHelper';

import formValidators from '../../helpers/formValidators';
import ServerErrorMessage from '../common/serverErrorMessage';
import FeaturesContext from "../../scenes/contexts/featuresContext";
import NoPermissionLabel from '../common/noPermissionLabel';
import messages from '../../helpers/messages';
import ModificationStats from '../common/modificationStats';
import i18n from '../../i18n';

export class TopicBuilder extends Component {
	constructor(props,context) {
		super(props,context);
		
		const topicData = this.mapToTopicDataModel(props.topicData);
		const initialStepStatus = props.editMode ? stepStates.DONE : stepStates.NOT_STARTED;

		this.state = {
			topicData,
			errors: {},
			pendingChanges: false,
			initialized: false,
			isSubmitted: false,
			progressBarSteps: [
				{
					title: "topic_builder_step_basic_title",
					description: "topic_builder_step_basic_description",
					status: initialStepStatus,
					required: true,
					active: true,
					name: STEPS_NAMES.basic
				},
				{
					title: "topic_builder_step_config_title",
					description: "topic_builder_step_config_description",
					status: initialStepStatus,
					required: false,
					active: false,
					name: STEPS_NAMES.config
				},
				{
					title: "topic_builder_step_qna_title",
					description: "topic_builder_step_qna_description",
					status: initialStepStatus,
					required: false,
					active: false,
					name: STEPS_NAMES.qna
				},
				{
					title: "topic_builder_step_after_vote_title",
					description: "topic_builder_step_after_vote_description",
					status: initialStepStatus,
					required: false,
					active: false,
					name: STEPS_NAMES.afterVote
				},
				{
					title: "topic_builder_step_greeting_title",
					description: "topic_builder_step_greeting_description",
					status: initialStepStatus,
					required: false,
					active: false,
					name: STEPS_NAMES.greetings
				},
				{
					title: "topic_builder_step_languages_title",
					description: "topic_builder_step_languages_description",
					status: initialStepStatus,
					required: false,
					active: false,
					name: STEPS_NAMES.translations
				},
				{
					title: "topic_builder_step_distribution_title",
					description: "topic_builder_step_distribution_description",
					status: initialStepStatus,
					required: false,
					active: false,
					name: STEPS_NAMES.linkConfig
				},
			],
			isCancelModalDisplayed: false,
			topicLinksModalShown: false,
			t: i18n.t.bind(i18n)
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.validate = this.validate.bind(this);
		
		this.goTo = this.goTo.bind(this);
		this._goToPreviousStep = this._goToPreviousStep.bind(this);
		this._goToNextStep = this._goToNextStep.bind(this);
		this.confirmCancelChanges = this.confirmCancelChanges.bind(this);
		this.handleShowLinksModal = this.handleShowLinksModal.bind(this);

		this._configureRouteChange();
	}

	componentDidUpdate(prevProps) {
		if (this.props.errors && !isEqual(this.props.errors, prevProps.errors)){
            const updatedProgressSteps = this.state.progressBarSteps.map((step) => {
                if (Object.keys(this.props.errors).indexOf(step.name) !== -1) {
                    return Object.assign({}, step, { status: stepStates.HAS_ERRORS });
                } else {
                    return step;
                }
			});
            this.setState({ progressBarSteps: updatedProgressSteps });
		}
		
		const stateParentTopic = this.state.topicData.config.parent;
		const topicIsRoot = this.state.topicData.basicSettings.isRoot;
		
		if (!isEmpty(this.props.entity) && !isEmpty(this.props.entity.defaultTopicLanguage) &&
			!this.props.editMode && !this.state.isSubmitted &&
			(this.state.topicData.basicSettings.defaultLang !== this.props.entity.defaultTopicLanguage)) {
			const updatedState = Object.assign({}, this.state.topicData);
			updatedState.basicSettings.defaultLang = this.props.entity.defaultTopicLanguage;
			this.setState({ topicData: updatedState });
		}
		
		if (this.props.topics && this.props.topics.length > 0) {
			if (!topicIsRoot && (!stateParentTopic || stateParentTopic.parentTopicId === "")) {
				const updatedState = Object.assign({}, this.state.topicData);
				const parentTopic = this.props.topics.find(t => t.isParent);
				if (parentTopic.id.length > 0) {
					updatedState.config.parent = { parentTopicId: parentTopic.id, parentTopicName: parentTopic.name };
				}
				this.setState({ topicData: updatedState });
			}
		}

		if (this.props.editMode) {
			if (this.props.topicData.modifiedOn !== prevProps.topicData.modifiedOn) {
				const newState = { pendingChanges: false };
				this.setState(newState);
			}
			if (this.props.topicData.version !== prevProps.topicData.version) {
				const updatedState = Object.assign({}, this.state.topicData);
				updatedState.version = this.props.topicData.version;
				this.setState({ topicData: updatedState });
			}
		}
	}

	componentWillUnmount() {
		window.onbeforeunload = () => {};
		if (this.state.topicLinksModalShown) {
			this.context.hideModal();
		}
	}

	mapToTopicDataModel(sourceData) {
		const defaultLanguageCode = this.props.entity.defaultTopicLanguage || "en";
		let {
			basicSettings,
			config,
			qna,
			postVoteSettings,
			greetings,
			translations,
			version,
			linkConfig
		} = getTopicModel(defaultLanguageCode);
		if (sourceData && !isEmpty(sourceData.basicSettings)) {
			basicSettings = Object.assign({}, sourceData.basicSettings);
		}
		if (sourceData && !isEmpty(sourceData.config)) {
			config = Object.assign({}, config, sourceData.config);
		}
		if (sourceData && !isEmpty(sourceData.qna)) {
			qna = [...sourceData.qna];
		}
		if (sourceData && !isEmpty(sourceData.postVoteSettings)) {
			postVoteSettings = Object.assign({}, sourceData.postVoteSettings);
		}
		if (sourceData && !isEmpty(sourceData.greetings)) {
			greetings = Object.assign({}, sourceData.greetings, { messages: [...sourceData.greetings.messages] });
		}
		if (sourceData && !isEmpty(sourceData.translations)) {
			translations = Object.assign({}, sourceData.translations);
		}
		if (sourceData && !isEmpty(sourceData.linkConfig)) {
			linkConfig = Object.assign({}, sourceData.linkConfig);
		}
		if (sourceData && sourceData.version) {
			version = sourceData.version;
		}
		return {
			basicSettings,
			config,
			qna,
			postVoteSettings,
			greetings,
			translations,
			version,
			linkConfig
		};
	}

	handleSubmit(values) {
		const valuesToPublish = Object.assign({}, values, {
			basicSettings: Object.assign({}, values.basicSettings, { entityId: this.props.entity.id })
		});
		const topicDataModel = this.mapToTopicDataModel(valuesToPublish);
		this.setState({ topicData: topicDataModel, pendingChanges: false, isSubmitted: true });
		this.props.onPublish(valuesToPublish);
	}

	handleShowLinksModal() {
		const { id, title, displayName } = this.state.topicData.basicSettings;
		const topicLinksConfig = getTopicLinksModalConfig(id, title, displayName, this.state.topicData.linkConfig);
		const modalComponentProps = Object.assign({}, topicLinksConfig,
			{
				onClose: this.context.hideModal,
			});
		this.setState({ topicLinksModalShown: true }, () => {
			this.context.showModal(TOPIC_LINKS_MODAL, modalComponentProps);
		});
	}

	validate(values) {
        const formErrors = {};
        const basicTopicDetailsErrors = validateBasicTopicDetails(values.basicSettings, values.config);
        const configurationErrors = validateTopicConfiguration(values.config);
		const qnaErrors = validateQna(values.qna);
		const greetingsErrors = validateGreetings(values.greetings.messages);
		const translationErrors = validateTranslations(values.translations, values.basicSettings, values.qna);
		const linkConfigErrors = validateLinkConfig(values.linkConfig);
		let updatedProgressSteps = [...this.state.progressBarSteps];

		if (!isEmpty(basicTopicDetailsErrors)) {
			formErrors.basicSettings = basicTopicDetailsErrors;
			updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.basic, stepStates.HAS_ERRORS);
		} else {
			updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.basic, stepStates.DONE);
        }
        
        if (!isEmpty(configurationErrors)) {
			formErrors.config = configurationErrors;
			updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.config, stepStates.HAS_ERRORS);
		} else {
			updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.config, stepStates.DONE);
		}
		
		if (!isEmpty(qnaErrors)) {
			formErrors.qna = qnaErrors;
			updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.qna, stepStates.HAS_ERRORS);
		} else {
			updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.qna, stepStates.DONE);
		}

		if (!isEmpty(greetingsErrors)) {
            formErrors.greetings = greetingsErrors;
            updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.greetings, stepStates.HAS_ERRORS);
		} else {
			updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.greetings, stepStates.DONE);
		}

		if (!isEmpty(translationErrors)) {
			formErrors.translations = translationErrors;
			updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.translations, stepStates.HAS_ERRORS);
		} else {
			updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.translations, stepStates.DONE);
		}
		
		if (!isEmpty(linkConfigErrors)) {
			formErrors.linkConfig = linkConfigErrors;
			updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.linkConfig, stepStates.HAS_ERRORS);
		} else {
			updatedProgressSteps = this.getUpdatedProgressBarSteps(updatedProgressSteps, STEPS_NAMES.linkConfig, stepStates.DONE);
		}

		if (!isEmpty(updatedProgressSteps)) {
			this.setState({progressBarSteps: updatedProgressSteps, pendingChanges: true});
		}

		return formErrors;
	}

	setStatusTo(status, target) {
		const updatedProgressSteps = this.getUpdatedProgressBarSteps(this.state.progressBarSteps, target, status);
		this.setState({progressBarSteps: updatedProgressSteps});
	}

	getUpdatedProgressBarSteps(progressBarSteps, target, status) {
		return progressBarSteps.map(step => {
			if (step.name === target) {
				let newStatus = status;
				if (status === stepStates.HAS_WARNING) {
					newStatus = `${step.status} ${status}`;
				}
				return Object.assign({}, step, { status: newStatus });
			} else {
				return step;
			}
		});
	}

	_goToPreviousStep(){
		let activeNow = this._getActiveStepIndex();
		this.goTo(activeNow - 1);
	}

	_goToNextStep() {
		let activeNow = this._getActiveStepIndex();
		this.goTo(activeNow + 1);
	}

	_isFirstStep() {
		const activeStep = this._getActiveStep();
		return this.state.progressBarSteps.indexOf(activeStep) === 0;
	}

	_isLastStep() {
		const activeStep = this._getActiveStep();
		const { progressBarSteps } = this.state;
		return progressBarSteps.indexOf(activeStep) === progressBarSteps.length - 1;
	}

	goTo(index) {
		const currentActiveStepIndex = this._getActiveStepIndex();
		if (currentActiveStepIndex !== index) {
			const newActiveObject = Object.assign({}, this.state.progressBarSteps[index], { active: true });
			const oldActiveObject = Object.assign({}, this.state.progressBarSteps[currentActiveStepIndex], { active: false });
			const updatedProgressSteps = Object.assign([...this.state.progressBarSteps], { [index]: newActiveObject });
			updatedProgressSteps[currentActiveStepIndex] = oldActiveObject;
			this.setState({ progressBarSteps: updatedProgressSteps });
		}
	}

	_getActiveStep() {
		return this.state.progressBarSteps.find(step => step.active);
	}

	_getActiveStepIndex() {
		const activeStep = this._getActiveStep();
		return this.state.progressBarSteps.indexOf(activeStep);
	}
	
	_getActiveFormComponentNameByIndex(index) {
		let activeForm;
		switch (index) {
			case 0: {
				activeForm = BasicTopicDetails;
				break;
			}
			case 1: {
				activeForm = TopicConfiguration;
				break;
			}
			case 2: {
				activeForm = QuestionsAndAnswers;
				break;
			}
			case 3: {
				activeForm = AfterVoteConfig;
				break;
			}
			case 4: {
				activeForm = GreetingMessages;
				break;
			}
			case 5: {
				activeForm = Translations;
				break;
			}
			case 6: {
				activeForm = LinkConfiguration;
				break;
			}
			default: {
				Logger.error(`No builder form found for index ${ index }`);
			}
		}
		return activeForm;
	}

	isPublishDisabled(isDirty, isValid) {
		return isDirty ? 
			(!isValid || this.props.isAsyncValidating || !isEmpty(this.props.errors)) : 
			true;
	}

	confirmCancelChanges(isStateDirty) {
		const returnRoute = '/topics';
		if (!isStateDirty) {
			// If state is not dirty go back to topics list.
			return browserHistory.push(returnRoute);
		}
		const configCancelModal = {
			title: "Confirm Cancel Changes",
			message: "Are you sure you want to cancel the changes you made?",
			onClose: () => { 
				this.context.hideModal();
				this.setState({ isCancelModalDisplayed: false });	
			},
			onConfirm: () => {
				browserHistory.push(returnRoute);
				this.context.hideModal();
				this.setState({ pendingChanges: false });
			},
		};
		this.setState( { isCancelModalDisplayed: true } );
		this.context.showModal(CONFIRM_MODAL, configCancelModal);
	}

	_configureRouteChange(leaveMessage = 'You have unsaved changes. Are you sure you want to leave the page?') {
		// Navigate out from route
		this.context.router.setRouteLeaveHook(
			// Current route
			this.context.router.routes[2], 
			() => {
				if(!this.state.pendingChanges || this.state.isCancelModalDisplayed) {
					return true;
				}
				return window.confirm(leaveMessage);
			}
		);

		// Detecting browser refresh / close
		window.onbeforeunload = () => {
			if (this.state.pendingChanges) {
				return window.confirm();
			}
		};
	}

	render() {
		const { topicData } = this.state;
		const { topics, editMode, entity, errors } = this.props;
		const activeStep = this._getActiveStep();
		const activeStepIndex = this._getActiveStepIndex();
		const FormComponentName = this._getActiveFormComponentNameByIndex(activeStepIndex);

		const firstLoad = this.props.editMode ? isEmpty(this.props.topicData.modifiedOn) : true;

		const t = this.state.t;
		
		return (
			<Formik 
				initialValues={topicData}
				onSubmit={() => {}}
				validate={this.validate}
				enableReinitialize
				render={formik => (
					<Form>
						<div className="topic-builder-container">
							<div className="steps-container">
								{formValidators.fieldHasErrors(errors, 'version') && <ServerErrorMessage errors={errors} name="version" className="overall-server-error" />}

								<Card pad title={t(activeStep.title)}>
									<FormComponentName
										values={formik.values}
										errors={formik.errors}
										isDirty={formik.dirty}
										setValues={formik.setValues}
										setFieldValue={formik.setFieldValue}
										setFieldTouched={formik.setFieldTouched}
										setFieldError={formik.setFieldError}
										handleBlur={formik.handleBlur}
										model={topicData}
										editMode={editMode}
										backendErrors={isEmpty(errors) ? {} : errors}
										topics={topics}
										entity={entity}
										sources={entity.sources}
									/>
									
									<div className="btn-toolbar wizard-controls">
										{!this._isFirstStep() &&
											<button type="button" className="btn btn-outline btn-default" onClick={this._goToPreviousStep}>
												<Icon.ChevronLeft size="16"/> {t('topic_builder_button_back')}
											</button>
										}

										{!this._isLastStep() &&
											<button type="button" onClick={this._goToNextStep} className="btn btn-outline btn-primary">
												<Icon.ChevronRight size="16" /> {t('topic_builder_button_next')}
											</button>
										}
									</div>
								</Card>
							</div>

							<Card title={t('topic_builder_progress_title')} className="progressBar-container progressBar-uitest">
								<ProgressBar
									steps={this.state.progressBarSteps}
									goTo={this.goTo}
								/>
								<div className="btn-toolbar no-float wizard-controls text-center">
									<button type="button" className="btn btn-danger btn-outline" onClick={ () => this.confirmCancelChanges(formik.dirty) }>
										<Icon.X size="14" />{t('topic_builder_progress_cancel')}
									</button>
									
									<FeaturesContext 
										skipCheck={this.props.editMode === true}
										requiredFeatures={[{name: ENTITY_FEATURES.NUMBER_OF_TOPICS, compare: COMPARATORS.GREATER_THAN_OR_ZERO, value: topics.length}]}
										renderOtherwise={
											<button type="button" className="btn btn-lg btn-success"
												disabled={this.isPublishDisabled(formik.dirty, formik.isValid)}
											>
            									<NoPermissionLabel message={messages.permissions.features.topicLimitReached} />
												<Icon.CheckCircle size={22}/> {this.props.editMode ? t('topic_builder_progress_update') : t('topic_builder_progress_publish')}
											</button>
										}
									>
										<button type="button" className="btn btn-lg btn-success"
											disabled={this.isPublishDisabled(formik.dirty, formik.isValid)}
											onClick={() => {
												formik.setSubmitting(true);
												this.handleSubmit(formik.values);
											}}
										>
											<Icon.CheckCircle size={22}/> {this.props.editMode ? t('topic_builder_progress_update') : t('topic_builder_progress_publish')}
										</button>
									</FeaturesContext>
								</div>

								<div className="sub-progress">
									<Card className='meta-info'>
										{this.props.topicData &&
										<ModificationStats
											createdOn={this.props.topicData.createdOn}
											createdBy={this.props.topicData.createdBy}
											modifiedBy={this.props.topicData.modifiedBy}
											modifiedOn={this.props.topicData.modifiedOn}
										/>}
									</Card>

									{editMode &&
									<Card className="sub-progress update-status-container text-center text-white update-status-uitest">
										<div className="message">
											<button type="button" className="btn btn-xs btn-outline btn-white button-links-uitest" onClick={this.handleShowLinksModal }><Icon.Link size={16} /> {t('topic_builder_progress_links_to_topic')}</button>
											{(!formik.dirty) &&
											<p className="changes-saved text-center clearfix">
												<small><Icon.Check size="14"/></small>
												{!firstLoad && <small> {t('topic_builder_progress_changes_saved')}</small>}
												<small> {t('topic_builder_progress_up_to_date')}</small>
											</p>
											}
										</div>									
									</Card>
									}
								</div>
							</Card>
						</div>
					</Form>
				)}
			/>
		);
	}
}
TopicBuilder.propTypes = {
	topics: PropTypes.array,
	entity: PropTypes.object,
	onPublish: PropTypes.func.isRequired,
	errors: PropTypes.object,

	/** @prop {bool} editMode when used to edit topic: changes UI elements such as button names, step states */
	editMode: PropTypes.bool,

	/** @prop {object} topicData the edit topic data to be used in the topic builder */
	topicData: PropTypes.shape({
		basicSettings: PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			displayName: PropTypes.string
		}),
		config: PropTypes.shape({}),
		linkConfig: PropTypes.shape({sources: PropTypes.array}),
		qna: PropTypes.arrayOf(PropTypes.shape({})),
		postVoteSettings: PropTypes.shape({}),
		greetings: PropTypes.shape({}),
		translations: PropTypes.shape({}),
		version: PropTypes.number,
		createdOn: PropTypes.string,
		createdBy: PropTypes.string,
		modifiedBy: PropTypes.string,
		modifiedOn: PropTypes.string
	}),
	
	/** @prop {bool} isAsyncValidating true during an async call to validate any value; false otherwise */
	isAsyncValidating: PropTypes.bool,
};

TopicBuilder.contextTypes = {
	showModal: PropTypes.func,
	hideModal: PropTypes.func,
	router: PropTypes.object,
	validateTopicNameDuplicate: PropTypes.func
};

export default withRouter(TopicBuilder);
