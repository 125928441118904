import moment from 'moment';
import { map, isEmpty } from 'lodash';
import { SENTIMENT_BUCKETS, AGE_BUCKETS, GENDER_BUCKETS, STACKING_OPTIONS_NAMES } from '../../helpers/graphsService';
import { getAgeGroupName } from 'hollerlive-shared/utils';

const urlMatchRegex = /((http[s]?:)+\/)\/?([^:/\s]+)(kazva.bg|holler.live)((\/\w+)*\/)([\w-.]+[^#?\s]+)(#[\w-]+)?/gi;

export const mapToShortAnswerModel = (model) => {
    const answersModels = [];
    const { qna, answers, locations } = model;
    qna.forEach(qnaItem => {
        const answerModel = {
            vote: _getScore(qnaItem.vote),
            answer: answers ? getAnswerWithoutLink(answers[qnaItem.aInd]) : '',
            attachedImageLink: answers ? getAnswerLink(answers[qnaItem.aInd]) : '',
            gender: _getGender(qnaItem.gen),
            city: locations ? _getCity(locations, qnaItem.cInd) : '',
            country: locations ? _getCountryCode(locations, qnaItem.cInd) : '',
            ageGroup: _mapToAgeGroup(qnaItem.by, qnaItem.ts)
        };
        answersModels.push(answerModel);
    });
    return answersModels;
};

export const mapToFullVoteAndQnaModel = (model) => {
    const answersModels = [];
    const { locations, answers, questions, qna, voters, sources } = model;

    qna.forEach(voteItem => {
        const voteModel = {
            id: voteItem.id,
            voteId: voteItem.voteId ? Number(voteItem.voteId) : null,
            vote: _getScore(voteItem.vote),
            question: questions && !isNaN(voteItem.qInd) ? questions[voteItem.qInd] : null,
            sources: sources && !isNaN(voteItem.sInd) ? _getSources(sources, voteItem.sInd) : null,
            answer: answers && !isNaN(voteItem.aInd) ? getAnswerWithoutLink(answers[voteItem.aInd]) : null,
            attachedImageLink: answers && !isNaN(voteItem.aInd) ? getAnswerLink(answers[voteItem.aInd]) : null,
            gender: _getGender(voteItem.gen),
            city: locations &&  !isNaN(voteItem.cInd) ?_getCity(locations, voteItem.cInd) : null,
            country: locations &&  !isNaN(voteItem.cInd) ? _getCountryCode(locations, voteItem.cInd) : null,
            date: _mapToDateString(voteItem.ts), 
            ageGroup: _mapToAgeGroup(voteItem.by, voteItem.ts),
            timeSpent: voteItem.tta,
            voteSession: voteItem.voteId ? voteItem.voteId.toString() : _mapToVoteSessionId(voters, voteItem.vInd, voteItem.ts),
        };
        
        answersModels.push(voteModel);
    });

    return answersModels;
};

export const mapToQuestionStatsModel = (questionStructure, questionStats) => {
    const questionDetailsModel = {
        question: questionStructure.question,
        type: questionStructure.type,
        orderIndex: questionStructure.orderIndex,
        answerCount: 0,
        meanResponseTime: 0,
        vote: 0,
        scoreGroupCounts: null,
        ageGroupCounts: null,
        genderCounts: null
    };
    if (questionStats) {
        const { answerCount, meanRating, meanResponseTime, scoreGroupCounts, ageGroupCounts, genderCounts } = questionStats;
        questionDetailsModel.answerCount = answerCount;
        questionDetailsModel.vote = meanRating / 10;
        questionDetailsModel.meanResponseTime = meanResponseTime / 1000;
        questionDetailsModel.scoreGroupCounts = scoreGroupCounts;
        questionDetailsModel.ageGroupCounts = ageGroupCounts;
        questionDetailsModel.genderCounts = genderCounts;
    }
    return questionDetailsModel;
};

/** flattens the question's aggregate data (scoregroups, agegroups, gendergroups) by producing a duplicate question for each sub group.
 * It should be applied as a reducer function and the transformed data be used in a chart without additional transformations
 * @param groupingType one of the possible stacking options
 */
export const reduceToFlatQuestionData = (groupingType) => (flatData, q) => {
    const chartQuestion = Object.assign({}, q, {
        scoreGroupCounts: null,
        ageGroupCounts: null,
        genderCounts: null
    });

    let groupDictionary = {};
    let mapperFunction = () => {};

    switch (groupingType) {
        case STACKING_OPTIONS_NAMES.sentiment:
            groupDictionary = q.scoreGroupCounts;
            mapperFunction = _mapScoreGroupDataToQuestionProps;
            break;
        case STACKING_OPTIONS_NAMES.ageGroups:
            groupDictionary = q.ageGroupCounts;
            mapperFunction = _mapAgeGroupDataToQuestionProps;
            break;
        case STACKING_OPTIONS_NAMES.gender:
            groupDictionary = q.genderCounts;
            mapperFunction = _mapGenderGroupDataToQuestionProps;
            break;    
        default:
            throw new Error("Invalid stacking option: " + groupingType);
    }

    if(groupDictionary){
        for (const key in groupDictionary) {
            if (groupDictionary.hasOwnProperty(key)) {
                const count = groupDictionary[key];

                const groupData = mapperFunction(key, count);
                if(groupData){
                    const flatQuestion = Object.assign({}, chartQuestion, groupData);
                    flatData.push(flatQuestion);
                }
            }
        }
    }

    return flatData;
};

export const getVoterTypesArrayFromVoterFilters = (voterFilters) => {
    return map(voterFilters, (val, prop) => {
        if (val === true && prop !== 'latestPerVoter') {
            return prop;
        }
    }).filter(entry => entry);
};

const _mapScoreGroupDataToQuestionProps = (groupName, groupCount) => {
    let sentimentGroupName = null;
    
    switch (groupName) {
        case "1-20":
            sentimentGroupName = SENTIMENT_BUCKETS[0];
            break;
        case "21-40":
            sentimentGroupName = SENTIMENT_BUCKETS[1];
            break;
        case "41-60":
            sentimentGroupName = SENTIMENT_BUCKETS[2];
            break;
        case "61-80":
            sentimentGroupName = SENTIMENT_BUCKETS[3];
            break;
        case "81-100":
            sentimentGroupName = SENTIMENT_BUCKETS[4];
            break;
        default:
            sentimentGroupName = "undefined";
            break;
    }

    if(groupCount > 0){
        const groupData = {
            answerCount: groupCount,
            scoreGroup: sentimentGroupName
        };
        return groupData;
    }

    return null;
};

const _mapAgeGroupDataToQuestionProps = (groupName, groupCount) => {
    let ageGroupName = null;
    
    switch (groupName) {
        case "0-17":
            ageGroupName = AGE_BUCKETS[0];
            break;
        case "18-25":
            ageGroupName = AGE_BUCKETS[1];
            break;
        case "26-35":
            ageGroupName = AGE_BUCKETS[2];
            break;
        case "36-55":
            ageGroupName = AGE_BUCKETS[3];
            break;
        case "56+":
            ageGroupName = AGE_BUCKETS[4];
            break;
        default:
            ageGroupName = AGE_BUCKETS[5];
            break;
    }

    if(groupCount > 0){
        const groupData = {
            answerCount: groupCount,
            ageGroup: ageGroupName
        };
        return groupData;
    }

    return null;
};

const _mapGenderGroupDataToQuestionProps = (groupName, groupCount) => {
    let genderGroupName = null;
    
    switch (groupName) {
        case "female":
            genderGroupName = GENDER_BUCKETS[0];
            break;
        case "male":
            genderGroupName = GENDER_BUCKETS[1];
            break;
        default:
            genderGroupName = GENDER_BUCKETS[2];
            break;
    }

    if(groupCount > 0){
        const groupData = {
            answerCount: groupCount,
            gender: genderGroupName
        };
        return groupData;
    }

    return null;
};

const _getScore = (vote) => {
    return vote / 10;
};

const _getGender = (gender) => {
    switch(gender) {
        case "f": return "female";
        case "m": return "male";
        default: return "unknown";
    }
};

const _mapToAgeGroup = (birthYear, timestampUnix) => {
    if (!birthYear || birthYear <= 0 || !timestampUnix) {
		return undefined;
	}

    const age = new Date(timestampUnix * 1000).getFullYear() - birthYear;
    return getAgeGroupName(age);
};

const _mapToDateString = (timestampUnix) => {
    const dateStamp = new Date(timestampUnix * 1000);
    return moment(dateStamp).format();
};

const _getCity = (locations, index) => {
    if(isEmpty(locations) || index === null){
        return null;
    }

    const location = locations[index];
    return location.split(',')[0].trim();
};

const _getCountryCode = (locations, index) => {
    if(isEmpty(locations) || index === null){
        return null;
    }

    const location = locations[index];
    return location.split(',')[1].trim();
};

const _getSources = (sources, index) => {
    if(isEmpty(sources) || index === null){
        return [];
    }

    return sources[index] ? sources[index].split(',') : [];
};

const _mapToVoteSessionId = (voters, voterIndex, timestampUnix) => {
    if (voters) {
        const voterId = voters[voterIndex];
        return `${voterId}|${timestampUnix}`;
    }
    else return '';
};

export const getAnswerLink = (answer) => {
    let links = '';

    let urlFound;
    while ((urlFound = urlMatchRegex.exec(answer)) !== null) {
        links += urlFound[0] + '\n';
    }

    return links;
};

export const getAnswerWithoutLink = (answer) => {
    return answer ? answer.replace(urlMatchRegex, '').trim() : '';
};

export default mapToFullVoteAndQnaModel;