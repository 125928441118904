import React from 'react';
import PropTypes from 'prop-types';
import Logger from '../../../helpers/logger';
import { isEmpty } from 'lodash';

import BaseTimeScaleGraph from './baseTimeScaleGraph';
import OptionsSelector from '../../common/form/optionsSelector';
import * as graphsService from '../../../helpers/graphsService';
import VegaChart from '../../common/charts/vegaChart';
import i18n from '../../../i18n';

class ScalableTimeChart extends BaseTimeScaleGraph {
	constructor(props) {
		super(props);

		this.state = {
			selectedStackingOption: !isEmpty(props.stackFieldOptions) ? props.stackFieldOptions[0] : graphsService.STACKING_OPTIONS.none,
			selectedTimeScaleOption: this.timeScale,
			t: i18n.t.bind(i18n)
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.dateFrom !== prevProps.dateFrom || this.props.dateTo !== prevProps.dateTo || this.props.timeScaleLabel !== prevProps.timeScaleLabel) {
			this.setState({ selectedTimeScaleOption: this.timeScale });
		}
	}

	get chartConfig() {
		const stackFieldOption = this.state.selectedStackingOption ? this.state.selectedStackingOption : {};

		const colorSpec = this._isValidStackingField(stackFieldOption.name) ? graphsService.getGraphEncodingColorSpecs(stackFieldOption.displayName) : null;
		if (colorSpec && !this.props.showLegend) {
			colorSpec.legend = null;
		}

		colorSpec.legend.title = this.state.t(colorSpec.legend.title);

		let chartSpec = {
			"mark": {
				"type": "bar"
			},
			"transform": this._isValidStackingField(stackFieldOption.name) ? graphsService.TRANSFORM_EXPRESSIONS[stackFieldOption.name] : null,
			"width": this.props.width,
			"encoding": {
				"x": {
					"field": "date",
					"type": "ordinal",
					"timeUnit": null,
					"axis": { "title": null }
				},
				"y": {
					"field": this.props.dataFieldName,
					"type": "quantitative",
					"aggregate": "count",
					"axis": { "title": this.props.chartYAxisTitle }
				},
				"color": colorSpec,
				"tooltip": { "field": "*", "aggregate": "count", "type": "quantitative" }
			}
		};
		if (this.props.type === "line") {
			chartSpec = {
				"layer": [
					{
						"mark": {
							"type": "line",
							"interpolate": "cardinal",
							"color": "#ccc"
						},
						"encoding": {
							"x": {
								"field": "date",
								"timeUnit": null,
								"type": "temporal",
								"axis": { "title": null, "format": "%H:%M %e.%m.%y" }
							},
							"y": {
								"field": this.props.dataFieldName,
								"aggregate": "mean",
								"type": "quantitative",
								"axis": { "title": this.props.chartYAxisTitle },
								"scale": { "domain": [0, 10] }
							},
						}
					},
					{
						"mark": {
							"type": "circle",
							"filled": true,
							"size": 50
						},
						"encoding": {
							"x": { "field": "date", "timeUnit": null, "type": "nominal" },
							"y": { "field": this.props.dataFieldName, "aggregate": "mean", "type": "quantitative" },
							"color": colorSpec,
							"tooltip": [
								{ "field": "*", "aggregate": "count", "type": "quantitative" },
								{ "field": this.props.dataFieldName, "aggregate": "mean", "type": "quantitative" }
							]
						}
					},
				],
				"width": this.props.width
			};
		}
		const chartConfig = Object.assign({}, graphsService.getGraphBaseSpecs(), chartSpec);

		const selectedTimeUnit = this.state.selectedTimeScaleOption.value;
		graphsService.updateChartConfigTimeUnit(chartConfig, selectedTimeUnit, this.props.dateFrom, this.props.dateTo);

		if (chartConfig.encoding) {
			chartConfig.encoding.x.axis.title = this.state.t(chartConfig.encoding.x.axis.title);
		}
		
		if (chartConfig.layer) {
			for (const layer of chartConfig.layer) {
				if (layer.encoding.x.axis)
					layer.encoding.x.axis.title = this.state.t(layer.encoding.x.axis.title);
			}
		}

		return chartConfig;
	}

	_isValidStackingField(stackingField) {
		if (!stackingField || !graphsService.STACKING_OPTIONS[stackingField]) {
			Logger.error(`Invalid stack field ${stackingField}. Make sure you are using a valid STACKING_OPTIONS_NAMES and TRANSFORM_EXPRESSIONS value`);
			return false;
		}

		return true;
	}

	_handleStackingChange(index, ev) {
		if (ev) {
			ev.preventDefault();
		}

		this.setState({
			selectedStackingOption: this.props.stackFieldOptions[index]
		});
	}

	_renderStackingSelector() {
		const selectedOption = this.state.selectedStackingOption;
		const selectedOptionsDom = selectedOption.values.map((o, i) => {
			return <span key={i}>
				<label className={o.className}>{o.label}</label>
				{i < selectedOption.values.length - 1 && ", "}
			</span>;
		});

		const availableOptionsNames = this.props.stackFieldOptions.map((o, i) => {
			return {
				label: o.displayName,
				value: i.toString()
			};
		});

		const selectionIndex = this.props.stackFieldOptions.indexOf(this.state.selectedStackingOption);

		const { t } = this.state;

		return (
			<span className="ui-test-stacking-selector">
				{t('scalable_time_chart_stacked_by')}
				<OptionsSelector
					onSelection={this._handleStackingChange.bind(this)}
					options={availableOptionsNames}
					value={selectionIndex.toString()} />
				({selectedOptionsDom})
			</span>
		);
	}

	_renderTimeScaleSelector() {
		const timeScaleValue = this.state.selectedTimeScaleOption.value || graphsService.GRAPH_TIME_SCALE_OPTIONS[2].value;
		const { t } = this.state;
		return (
			<span className="ui-test-scaling-selector">
				{t('scalable_time_chart_scaled_by')}
				<OptionsSelector
					onSelection={this._handleTimeScaleChange.bind(this)}
					options={this.timeScaleOptions}
					value={timeScaleValue}
				/>
			</span>
		);
	}

	render() {
		return (
			<div>
				<h4>
					<span className="options-bar">
						{this.props.showScaleSelector && this._renderTimeScaleSelector()}
						{this.props.stackFieldOptions && this._renderStackingSelector()}
					</span>
				</h4>
				<VegaChart data={{ values: this.props.data }} spec={this.chartConfig} className={this.props.className} />
			</div>
		);
	}
}

ScalableTimeChart.propTypes = {
	/** @prop {string} type type of the chart to be visualized. Currently supports bar/line */
	type: PropTypes.string.isRequired,

	/** @prop {object} data data to be loaded in chart */
	data: PropTypes.array.isRequired,

	/** @prop {string} dataFieldName  */
	dataFieldName: PropTypes.string.isRequired,

	/** @prop {object} stackFieldOptions custom set of STACKING_OPTIONS to be displayed in options menu */
	stackFieldOptions: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		displayName: PropTypes.string.isRequired,
		values: PropTypes.array.isRequired
	})),

	/** @prop {object} chartYAxisTitle  */
	chartYAxisTitle: PropTypes.string.isRequired,

	/** @prop {object} chartXAxisTitle  */
	chartXAxisTitle: PropTypes.string.isRequired,

	/** @prop {object} width custom width of chart */
	width: PropTypes.number,

	/** @prop {bool} showLegend true to show colors legend; false otherwise */
	showLegend: PropTypes.bool,

	/** @prop {bool} showScaleSelector true to show time-scale selector; false otherwise */
	showScaleSelector: PropTypes.bool,

	/** @prop {string} className extra classes passed from parent on demand */
	className: PropTypes.string,

	dateFrom: PropTypes.string,
	dateTo: PropTypes.string
};

export default ScalableTimeChart;