import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as types from './actionTypes';
import { graphGet, graphPost, graphPut } from '../api/expressApi';
import { addNotification, requestStatusError, requestStatusPending, requestStatusSuccess } from './miscActions';
import logger from '../helpers/logger';
import { notificationLevel } from '../helpers/notification';
import { handleCommonError, handleValidationErrors, handleVersionErrors } from './errorActions';
import { destructUserResponse, mapToRequestModel } from './models/userModel';
import { INVALID_SESSION_STATUS_CODE } from '../resources/apiConstants';
import i18n from '../i18n';

function loginUserSuccess(user, entities) {
    return { type: types.LOGIN_USER_SUCCESS, payload: { user, entities } };
}

function registerUserSuccess(user) {
    return { type: types.REGISTER_USER_SUCCESS, payload: user };
}

function registerUserRequested() {
    return { type: types.REGISTER_USER_REQUESTED };
}

function logoutUserSuccess(user) {
	return { type: types.LOGOUT_USER_SUCCESS, payload: user };
}

function loginUserError(reason) {
	return { type: types.LOGIN_USER_ERROR, payload: reason };
}

function beginAuthenticating() {
	return { type: types.AUTHENTICATING_TOSERVER };
}

function clearStoreErrors() {
	return { type: types.CLEAR_AUTH_ERRORS };
}

function clearRequestStatus() {
	return { type: types.CLEAR_REQUEST_STATUS };
}

function loadUserProfileInProgress() {
    return { type: types.LOAD_USER_PROFILE_REQUESTED };
}

function loadUserProfileSuccess(user) {
    return { type: types.LOAD_USER_PROFILE_SUCCESS, payload: { user } };
}

function updateUserProfileInProgress() {
    return { type: types.UPDATE_USER_PROFILE_REQUESTED };
}

function updateUserProfileSuccess(user) {
    return { type: types.UPDATE_USER_PROFILE_SUCCESS, payload: { user } };
}

function requestForgotPasswordSuccess() {
    return {type: types.COMMON_BACKEND_ERROR_RESET};
}

function passwordChangeSuccess() {
	return { type: types.CHANGE_PASSWORD_SUCCESS };
}

function passwordChangeError(reason) {
	return { type: types.CHANGE_PASSWORD_ERROR, payload: reason };
}

function resetPasswordSuccess() {
	return { type: types.CHANGE_PASSWORD_SUCCESS };
}

function sessionExpired() {
	return { type: types.LOGIN_USER_SESSIONEXPIRED };
}

export function resetChangedPassword() {
	return { type: types.RESET_CHANGED_PASSWORD };
}

function activateUserSuccess() {
	return { type: types.ACTIVATE_USER_SUCCESS };
}

function connectToSocket() {
	return { type: 'CONNECT_SOCKET' };
}

function disconnectFromSocket() {
	return { type: 'DISCONNECT_SOCKET' };
}

export function login(userValues) {
    return async dispatch => {
        dispatch(showLoading());
        try {
            const userResponse = await graphPost('/auth/authenticate', {
                userEmail: userValues.identifier.trim(),
                password: userValues.password
            });
            const { user, entities } = destructUserResponse(userResponse);
            dispatch(loginUserSuccess(user, entities));
            dispatch(connectToSocket());
            dispatch(addNotification("logged in successfully", notificationLevel.success));
            dispatch(hideLoading());
        } catch (error) {
            logger.error(error.message);
            dispatch(loginUserError(error.message));
            dispatch(hideLoading());
        }
    };
}

export function register(userValues) {
    return async dispatch => {
        dispatch(registerUserRequested());
        dispatch(showLoading());
        
        const user = mapToRequestModel(userValues);
        const reqData = Object.assign({}, user, { captchaValue: userValues.captchaValue });
        
        try {
            await graphPost("/auth/register", reqData);    
            dispatch(registerUserSuccess(user));
            dispatch(addNotification('Account registration successfull',  notificationLevel.success));
            dispatch(hideLoading());
        } catch (error) {
            handleCommonError(dispatch, error);
            handleValidationErrors(dispatch, error, types.REGISTER_USER_ERROR);
        }
    };  
};

function setLanguage (updatedUser) {
    if(updatedUser.language) {
        i18n.changeLanguage(updatedUser.language);
    }
}

export function loadProfile() {
    return async dispatch => {
        dispatch(showLoading());
        dispatch(loadUserProfileInProgress());
        try {
            const user = await graphGet('/users/currentUserProfile');
            dispatch(loadUserProfileSuccess(user));
            dispatch(hideLoading());
        } catch (error) {
            handleCommonError(dispatch, error);
        }
    };
}

export function updateProfile(userPayload) {
    return async dispatch => {
        dispatch(showLoading());
        dispatch(updateUserProfileInProgress());
        try {
            const updatedUser = await graphPut('/users/updateCurrentUserProfile', mapToRequestModel(userPayload));
            setLanguage(updatedUser);

            dispatch(
				addNotification(
					`Profile ${userPayload.firstName} ${userPayload.lastName} edited successfully.`,
					notificationLevel.success
				)
			);
            dispatch(updateUserProfileSuccess(updatedUser));
            dispatch(hideLoading());
        } catch (error) {
            if (error.validationErrors) {
                dispatch(hideLoading());
                handleValidationErrors(dispatch, error, types.UPDATE_USER_PROFLE_ERROR);
            } else {
                handleVersionErrors(dispatch, error, { type: types.UPDATE_USER_VERSION_ERROR });
                handleCommonError(dispatch, error);
            }
        }
    };
}

export function activate(token) {
    return async dispatch => {
        dispatch(showLoading());

        try {
            await graphPost('/auth/activate', { token });
            dispatch(activateUserSuccess());
            dispatch(addNotification('Account activation successfull', notificationLevel.success));
            dispatch(hideLoading());
        } catch (error) {
            handleCommonError(dispatch, error);
        }
    };
}

export function logout(user){
    return function(dispatch){
        graphGet('/auth/logout').then(() => {   
            dispatch(logoutUserSuccess(user));
            dispatch(disconnectFromSocket());
        });
    };
}

export function requestLoginStatus() {
    return async dispatch => {
        dispatch(showLoading());
        try {
            dispatch(beginAuthenticating());
            const userResponse = await graphGet('/auth/loginstatus');
            const { user, entities } = destructUserResponse(userResponse);
            setLanguage(user);
            dispatch(loginUserSuccess(user, entities));
            dispatch(connectToSocket());
            dispatch(hideLoading());
        } catch (error) {
            dispatch(hideLoading());
            if (error.status === INVALID_SESSION_STATUS_CODE) {
                logger.info(error.message);
                dispatch(sessionExpired());
            } else {
                logger.critical(error.message);
                dispatch(addNotification("Authenticating to server failed", notificationLevel.error));
            }
        }
    };
}

export function changePassword(user, oldPass, newPass) {
    logger.debug(`Change pass for user ${JSON.stringify(user)}. Old: ${oldPass} -> new: ${newPass}`);
    return function(dispatch){
        dispatch(showLoading());
        graphPost('/users/changeCurrentUserPass', {oldPass, newPass}).then(() => {
            dispatch(addNotification('password changed successfully',  notificationLevel.success));
            dispatch(passwordChangeSuccess());
            dispatch(hideLoading());
        }, error => {
			dispatch(hideLoading());            
            dispatch(addNotification('Failed to change password.',  notificationLevel.error));
            dispatch(passwordChangeError(error.message));
		});        
    };
}

export function requestForgotPassword(userEmail){
    return (dispatch) => {
        const endpointName = '/auth/forgotpass';

        dispatch(showLoading());
        dispatch(requestStatusPending(endpointName));

        graphPost(endpointName, {userEmail}).then(() => {
            dispatch(hideLoading());
            dispatch(requestForgotPasswordSuccess());
            dispatch(requestStatusSuccess(endpointName));
            dispatch(addNotification('A link with instructions was sent to your email',  notificationLevel.success));
        }, error => {
            dispatch(requestStatusError(endpointName));
			handleCommonError(dispatch, error);
		});
    };
}

export function resetPassword(token, newPass) {
    return async dispatch => {
        dispatch(showLoading());
		try {
            await graphPost('/auth/resetpass', { newPass, token });
            dispatch(resetPasswordSuccess());
			dispatch(hideLoading());
        } catch (err) {
            handleCommonError(dispatch, err);
        }
    };
}

export function clearErrors() {
    return dispatch => {
        dispatch(clearStoreErrors());
        dispatch(clearRequestStatus());
    };
}
