import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { resetChangedPassword, resetPassword } from '../../actions/authActions';
import { Link } from 'react-router';

import Card from '../../components/common/card';
import BSFormControl from '../../components/common/form/bsFormControl';
import {Formik, Form, Field, ErrorMessage } from 'formik';
import formValidators from '../../helpers/formValidators';
import { isStrongPassword, passwordsMatch } from '../../helpers/validators';
import Messages from '../../helpers/messages';
import { CheckCircle } from 'react-feather';

export class ResetPasswordPage extends Component {
	constructor(props, context){
		super(props, context);
		
        this.state = {
			successfullyChanged: false
		};

		this.token = props.location.query.token;
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.passwordChanged && this.props.passwordChanged) {
			this.props.resetChangedPassword();
			this.setState({ successfullyChanged: true });
		}
	}

    handleSubmit(values){
		this.props.resetPassword(this.token, values.password);
	}	
	
    validate(values){
        const formErrors = {};

		if (!formValidators.required(values.password)) {
            formErrors.password = Messages.errors.required;
        } else if (!isStrongPassword(values.password)) {
            formErrors.password = Messages.errors.password;
        } else if (!passwordsMatch(values.password, values.confirmPassword)) {
            formErrors.confirmPassword = Messages.errors.confirmPassword;
		}
		
		return formErrors;
	}

	fieldHasErrors(errors, field){
		return errors[field] && errors[field].length > 0;
    }

	render() {
		const { className } = this.props;
		const initModel = {
			password: "",
			confirmPassword: ""
		};

		return (
			<div className={`col-md-4 col-md-offset-4 ${className}`}>
				<Card title={`${!this.state.successfullyChanged ? 'Reset password' : ' '}`}>
				{!this.state.successfullyChanged ? 
				<div>
					<p className="message">Input your new password. You will be then redirected to the login page, so you can sign up with your new password</p>
					
					<Formik 
						initialValues={initModel} 
						onSubmit={this.handleSubmit}
						enableReinitialize={true}
						validate={this.validate}
					>
						{({ errors, dirty, isValid }) =>
							<Form autoComplete="noautocompletepls">
								<div className="row-fluid clearfix">
									<BSFormControl label='New Password' hasErrors={this.fieldHasErrors(errors, 'password')}>
										<Field type="password" name="password" className="form-control" autoComplete="noautocompletepls" />
										<ErrorMessage name="password" component="span" className='control-label' />
									</BSFormControl>
								</div>

								<div className="row-fluid clearfix">
									<BSFormControl label='Confirm Password' hasErrors={this.fieldHasErrors(errors, 'confirmPassword')}>
										<Field type="password" name="confirmPassword" className="form-control" autoComplete="noautocompletepls" />
										<ErrorMessage name="confirmPassword" component="span" className='control-label' />
									</BSFormControl>
								</div>

								<button type="submit" className="btn btn-primary btn-lg btn-full-width" disabled={!dirty || !isValid}>Save</button>
							</Form>
						}
					</Formik>
				</div>
				:
				<div>
					<p className="text-center text-success"><CheckCircle size={48} /></p>
					<p className="text-center text-success">New password saved successfully!</p>
					<p className="text-center message"><Link to="/login" className="btn btn-primary btn-full-width">sign in</Link></p>
				</div>
				}
				</Card>
			</div>
		);
	}
}

ResetPasswordPage.propTypes = {
	className: PropTypes.string,

	/** @param {object} location the build in location property, including query param 'token' */
	location: PropTypes.shape({
		query: PropTypes.shape({
			token: PropTypes.string.isRequired
		}).isRequired
	}).isRequired,

	/** @param {bool} passwordChanged a boolean flag that the resetPassword action is complete.
	 * If the flag is true, the reset password page will show a success message screen.
	 */
	passwordChanged: PropTypes.bool,

	/** @param {function} resetPassword action containing the new password and confirm new password
	 * @property {string} newPassword the new password
	 * @property {string} confirmPassword the confirm new password
	 */
	resetPassword: PropTypes.func.isRequired,

	/** @param {function} resetChangedPassword send this action to reset passwordChanged property */
	resetChangedPassword: PropTypes.func.isRequired
};

function mapStateToProps(state){
    return {
        passwordChanged: state.auth.passwordChanged
    };
}

export default connect(mapStateToProps, {resetChangedPassword, resetPassword})(ResetPasswordPage);