import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as entitiesActions from '../../actions/entitiesActions';
import * as topicActions from '../../actions/topicActions';
import * as topicBuilderActions from '../../actions/topicBuilderActions';
import * as modalActions from '../../actions/modalActions';
import * as Icons from 'react-feather';
import { isEmpty,  } from 'lodash';
import * as utils from '../../helpers/utils';
import Logger from '../../helpers/logger';

import TopicBuilder from '../../components/topicBuilder/topicBuilder';
import { TOPIC_CREATED_MODAL, NO_ROOT_TOPIC_MODAL } from '../../helpers/modalTypes';
import { getTopicLinksModalConfig } from '../../helpers/modalDialogsHelper';
import { PERMISSIONS, userHasPermissions } from '../../helpers/permissionsHelper';
import './css/topicBuilderPage.css';
import i18n from '../../i18n';

export default (topicBuilderPageMode, isReduxConnected = true) => {
	class TopicBuilderPage extends Component {
		constructor(props) {
			super(props);
			
			this.state = {
				topicForEditLoaded: false,
				noRootModalShown: false,
				topicCreatedModalShown: false,
				t: i18n.t.bind(i18n)
			};

			this.onPublish = this.onPublish.bind(this);
			this.onCloseSuccessModal = this.onCloseSuccessModal.bind(this);
		}

		static getDerivedStateFromProps(props, state) {
			const newState = {};

			if (props.topicForEdit && !isEmpty(props.topicForEdit.basicSettings) && state.topicForEditLoaded === false) {
				newState.topicForEditLoaded = true;
			}

			if (props.entity.topicsLoaded && state.topicsForEntityLoading) {
				newState.topicsForEntityLoading = false;
			}

			if (!isEmpty(newState)) {
				return newState;
			}
			
			return null;
		}

		componentDidMount() {
			const { entity, actions } = this.props;
			
			if (!isEmpty(this.props.entity)) {
				if (!entity.topicsLoaded && !this.state.topicsForEntityLoading) {
					this.setState({
						topicsForEntityLoading: true
					}, () => {
						actions.loadTopicsForEntity(entity.id);
					});
				}

				if (entity.topicsLoaded && !this.state.topicForEditLoaded) {
					this.loadRequiredTopicData();
				}
			}
		}

		componentDidUpdate() {
			const { entity, topicForEdit, actions, user, hasRootTopic } = this.props;

			const entityIsLoaded = !isEmpty(entity);

			if (entityIsLoaded) {
				if (!entity.topicsLoaded && !this.state.topicsForEntityLoading) {
					this.setState({
						topicsForEntityLoading: true
					}, () => {
						actions.loadTopicsForEntity(entity.id);
					});
				}
			}

			if (entity.topicsLoaded && !this.state.topicForEditLoaded) {
				this.loadRequiredTopicData();
			} else {
				if (topicBuilderPageMode.create || topicBuilderPageMode.template) {
					if (!topicForEdit.saved) {
						if (!this.state.noRootModalShown && entity.topicsLoaded && !hasRootTopic) {
							const modalComponentProps = {
								onClose: () => { this.props.router.goBack(); },
								data: {
									entityId: entity.id,
									canEditEntity: userHasPermissions([PERMISSIONS.EDIT_ENTITIES], user)
								}
							};
							
							this.setState({ noRootModalShown: true }, () => {
								actions.showModal(NO_ROOT_TOPIC_MODAL, modalComponentProps);
							});
						}
					} else {
						if (!this.state.topicCreatedModalShown) {
							const { id, topicDisplayName, topicName, linkConfig } = topicForEdit.createdTopic;
							this.showTopicCreatedModal(id, topicDisplayName, topicName, linkConfig);
						}
					}
				}
			}
		}
		
		componentWillUnmount() {
			if (topicBuilderPageMode.create) {
				this.props.actions.resetValidationErrors();
			} 
			this.props.actions.resetTopicForEdit();
			
			if (this.state.topicCreatedModalShown || this.state.noRootModalShown) {
				this.props.actions.hideModal();
			}
		}

		getChildContext() {
			return {
				showModal: this.props.actions.showModal,
				hideModal: this.props.actions.hideModal,
				validateTopicNameDuplicate: this.props.actions.validateTopicNameDuplicate,
			};
		}

		onPublish(topicToPublish) {
			if (topicBuilderPageMode.create || topicBuilderPageMode.template) {
				this.setState({ linkConfig: topicToPublish.linkConfig }, () => {
					this.props.actions.saveTopic(topicToPublish);
				});
			} else if (topicBuilderPageMode.edit) {
				this.props.actions.updateTopic(topicToPublish, this.props.params.id);
			}
		}

		onCloseSuccessModal(shouldReloadTopics = false, shouldHideModal = false) {
			if (shouldReloadTopics) {
				this.props.actions.loadTopicsForEntity(this.props.entity.id);
			}
			this.props.actions.resetTopicForEdit();
			if (shouldHideModal) {
				this.props.actions.hideModal();
			}
		}

		loadRequiredDataForCreateTopic() {
			const { actions, entity } = this.props;

			if (!this.state.topicForEditLoaded) {
				if (isEmpty(entity.sources)) {
					actions.loadEntitySources(entity.id);
				}

				this.setState({ topicForEditLoaded: true });
			}
		}

		loadRequiredDataForEditTopic() {
			const { topicForEdit, params } = this.props;

			if (!this.state.topicForEditLoaded) {
				if (isEmpty(topicForEdit.basicSettings) || topicForEdit.basicSettings.id !== params.id) {
					this.loadTopic(params.id);
				}
			}
		}

		loadRequiredTopicData() {
			if (topicBuilderPageMode.create) {
				this.loadRequiredDataForCreateTopic();
			} else if (topicBuilderPageMode.edit || topicBuilderPageMode.template) {
				this.loadRequiredDataForEditTopic();
			}
		}

		loadTopic(topicId) {
            let topic = this.props.topics.find(t => t.id === topicId);
            let topicHash;
          
			if (topic) {
				topicHash = utils.createTopicHash(topic.name);
            } else if (userHasPermissions([PERMISSIONS.ADMIN], this.props.user)) {
                topic = this.props.topicsForChannel ? this.props.topicsForChannel.find(t => t.id === topicId) : null;
                topicHash = topic ? topic.hash : null;
            } else {
				Logger.error(`No topic found for id=${this.props.params.id}`);
            }
            
            if (topicHash) {
				if (topicBuilderPageMode.edit) {
					this.props.actions.loadTopicByHash(topicHash);
				} else if (topicBuilderPageMode.template) {
					this.props.actions.loadTopicForTemplate(topicHash);
				}
            }           
		}

		showTopicCreatedModal(id, topicDisplayName, topicName, linkConfig) {
			if (!this.state.topicCreatedModalShown) {
				const topicLinksConfig = getTopicLinksModalConfig(id, topicName, topicDisplayName, linkConfig);
				const modalComponentProps = Object.assign({}, topicLinksConfig , {
					onClose: this.onCloseSuccessModal
				});
				this.setState({ topicCreatedModalShown: true }, () => {
					this.props.actions.showModal(TOPIC_CREATED_MODAL, modalComponentProps);
				});
			}
		}

		render() {
			const { topics, entity, topicForEdit, errors } = this.props;
			const t = this.state.t;
			
			if (topicBuilderPageMode.create) {
				return (
					<div className="create-topic">
						<h2 className="entity-name">
							<Icons.PlusSquare size={24} /> creating new topic
						</h2>

						<TopicBuilder 
							errors={errors} 
							topics={topics || []} 
							entity={entity} 
							onPublish={this.onPublish} 
							isAsyncValidating={topicForEdit ? topicForEdit.isAsyncValidating : false}
						/>
					</div>
				);
			} else if (topicBuilderPageMode.edit) {
				return (
					<div className='edit-topic'>
						{this.state.topicForEditLoaded && (
							<>
								<div className='edit-topic-header-container'>
									<h2 className='entity-name'>
										<Icons.Edit3 size={24} />{' '}
										{t('topic_builder_title', { topicName: topicForEdit.basicSettings.title })}
										{topicForEdit.basicSettings.isRoot && (
											<small className='text-success'>
												&nbsp;&nbsp;
												<Icons.Layers size={24} /> root topic
											</small>
										)}
									</h2>
								</div>
								<TopicBuilder
									editMode
									topics={topics || []}
									entity={entity}
									topicData={topicForEdit}
									onPublish={this.onPublish}
									errors={errors}
								/>
							</>
						)}
					</div>
				);
			} else if (topicBuilderPageMode.template) {
				return (
					<div className="edit-topic">
						{this.state.topicForEditLoaded &&
						<h2 className="entity-name">
							<Icons.PlusSquare size={24} /> creating new topic from template
						</h2>
						}
						{this.state.topicForEditLoaded &&
						<TopicBuilder errors={errors} topics={topics || []} entity={entity} topicData={topicForEdit} onPublish={this.onPublish} />
						}
					</div>
				);
			}
		}
	}

	TopicBuilderPage.childContextTypes = {
		showModal: PropTypes.func,
		hideModal: PropTypes.func,
		validateTopicNameDuplicate: PropTypes.func
	};

	TopicBuilderPage.propTypes = {
		actions: PropTypes.object,
		entity: PropTypes.object.isRequired,
		topics: PropTypes.array.isRequired,
		topicForEdit: PropTypes.object.isRequired,
		errors: PropTypes.object,
		user: PropTypes.object,
		hasRootTopic: PropTypes.bool,
		params: PropTypes.object,
		topicsForChannel: PropTypes.array,
		router: PropTypes.object
	};

	function mapStateToProps(state) {
		let entity = {};
		if (state.entities && state.entities.length > 0) {
			entity = state.entities[0];
        }
        
		const channels = Object.keys(state.channelTopics);
		const topicsForChannel = channels.length > 0 ? state.channelTopics[`${channels[0]}`] : [];

		let hasRootTopic = !isEmpty(state.topics.find(t => t.isParent));

		return {
            topics: state.topics,
            topicsForChannel: topicsForChannel,
			topicForEdit: state.topicForEdit,
			entity,
			errors: state.topicForEdit.errors,
			user: state.auth.user,
			hasRootTopic
		};
	}

	function mapDispatchToProps(dispatch) {
		return {
			actions: bindActionCreators(Object.assign({}, topicActions, entitiesActions, topicBuilderActions, modalActions), dispatch)
		};
	}

	if (!isReduxConnected) {
		return TopicBuilderPage;
	}

	return connect(mapStateToProps, mapDispatchToProps)(TopicBuilderPage);
};