import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp, Clock } from 'react-feather';

import GroupByQuestionItem from './groupByQuestionItem';
import i18n from '../../../../../i18n';

const PAGE_SIZE = 10;

class GroupByQuestion extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isCollapsed: false,
			isCollapsedAllOn: props.isCollapsedAllOn,
			pageIndex: 0,
			t: i18n.t.bind(i18n)
		};

		this.handleExpandCollapseClick = this.handleExpandCollapseClick.bind(this);
		this.handleLoadMoreAnswers = this.handleLoadMoreAnswers.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.isCollapsedAllOn !== prevState.isCollapsedAllOn) {
			return {
				isCollapsed: nextProps.isCollapsedAllOn,
				isCollapsedAllOn: nextProps.isCollapsedAllOn
			};
		}
		if(nextProps.shouldResetPaging && prevState.pageIndex > 0){
			return {pageIndex: 0};
		}

		return null;
	}

	handleExpandCollapseClick(){
		this.setState({ isCollapsed: !this.state.isCollapsed });
	}

	handleLoadMoreAnswers(){
		const newPageIndex =  Math.ceil(this.props.answers.length / PAGE_SIZE);
		this.setState({ pageIndex: newPageIndex });

		const offset = newPageIndex * PAGE_SIZE;
		
		this.props.onLoadNextPage(offset, this.props.questionTitle);
	}

	get canLoadMore() {
		const { answers, totalAnswersCount } = this.props;

		const remainingAnswers = totalAnswersCount - this.state.pageIndex * PAGE_SIZE;
		if(answers.length < PAGE_SIZE || remainingAnswers < PAGE_SIZE){
			return false;
		}

		return answers.length < remainingAnswers;
	}

	get loadedAnswersText() {
		const t = this.state.t;
		const { answers, totalAnswersCount } = this.props;
		let text;

		if (this.canLoadMore) {
			text = <span>{t('topic_vote_feed_meta_info_loaded')} <strong>{answers.length}</strong> {t('topic_vote_feed_meta_info_out_of')} <strong>{totalAnswersCount}</strong> {t('topic_vote_feed_meta_info_total')}</span>;
		} else {
			text = <span>{t('topic_vote_feed_meta_info_loaded_all')} <strong>{answers.length}</strong></span>;
		}

		return text;
		
	}

	renderAnswerItems(answers) {
		return answers.map((a, i) => {
			const voteData = {
				date: a.date || "",
				vote: a.vote,
				gender: a.gender,
				ageGroup: a.ageGroup,
				sources: a.sources,
				location: {
					city: a.city,
					countryCode: a.country
				}
			};

			return <GroupByQuestionItem 
						key={i}
						question={this.props.questionTitle}
						voteData={voteData} 
						answerText={a.answer || ""} 
						attachedImageLink={a.attachedImageLink || null} 
						timeToAnswerMs={a.timeSpent} 
					/>;
		});
	}
	
	render() {
		const { questionTitle, answers, stats, questionIndex } = this.props;
		const avgTime = stats.meanResponseTime.toFixed(2);
		const t = this.state.t;

		return (
			<div className={`group-by-question-container uitest-group-by-question-container ${answers.length === 0 ? "empty-answers text-muted" : ""}`}>
				<div className={`group-item group-head-item ${this.state.isCollapsed ? 'collapsed' : 'expanded'}`}>
					<h4 onClick={answers.length > 0 ? this.handleExpandCollapseClick : null}>
						{answers.length > 0 && 
							<button type="button" className="btn transparent expand-collapse">
								{this.state.isCollapsed 
									? <ChevronDown size="18" />
									: <ChevronUp size="18" />}
							</button>
						}

						{questionIndex + 1}. {questionTitle}
					</h4>
					
					<div className="meta text-label text-light">
						<p>
						{answers.length > 0 ? 
							<span>
								{this.loadedAnswersText} {t('topic_vote_timeline_votes_with_answers')} <strong> 
									<Clock size={12} /> {avgTime} <small>{t('topic_vote_timeline_sec')}</small>
								</strong>
							</span>
						:
							<span className="text-muted">{t('topic_vote_timeline_votes_no_answers')}</span>
						}
						</p>
					</div>
				</div>

				{!this.state.isCollapsed && 
					<div>
						{this.renderAnswerItems(answers)}

						{this.canLoadMore && 
							<button type="button" className="btn btn-xs btn-outline btn-full-width" onClick={this.handleLoadMoreAnswers}>{t('topic_vote_timeline_show_next_answers', { pageSize: PAGE_SIZE })}</button>
						}
					</div>
				}

			</div>
		);
	}
}

GroupByQuestion.propTypes = {
	answers: PropTypes.array.isRequired,
	questionTitle: PropTypes.string.isRequired,
	questionIndex: PropTypes.number,
	stats: PropTypes.shape({
		answerCount: PropTypes.number,
		meanResponseTime: PropTypes.number
	}).isRequired,

	totalAnswersCount: PropTypes.number,
	shouldResetPaging: PropTypes.bool,
	isCollapsedAllOn: PropTypes.bool,

	/** @prop {func} onLoadNextPage action to get paged result */
	/** @param {number} pageSize */
	/** @param {number} offset */
	/** @param {object} filters */
	/** @param {string} filters.search */
	onLoadNextPage: PropTypes.func.isRequired
};

export default GroupByQuestion;