import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '../common/card';
import DateRange from '../common/dateRange/dateRange';
import OptionsSelection from '../common/form/optionsSelector';
import { DATE_FILTER_OPTIONS } from '../../helpers/datePickerService';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const SORT_DIRECTION_DESC = "desc";
const SORT_DIRECTION_ASC = "asc";
const SORT_OPTIONS = [
	'filter_sort_options_most',
	'filter_sort_options_least', 
	'filter_sort_options_highest', 
	'filter_sort_options_lowest'
];

const DEFAULT_INTERVAL_SELECTION = DATE_FILTER_OPTIONS.indexOf('filter_date_today');
const CUSTOM_INTERVAL_SELECTION = DATE_FILTER_OPTIONS.indexOf('filter_date_select_range');

const TopicsTreeFilterSortBar = ({ 
	dateFrom, 
	dateTo, 
	selectedPeriodIndex,
	enableCustomDateRange, 
	onDateRangeChange,
	onIntervalChange,
	actionSortByVolume,
	actionSortByRating
}) => {
	const [hideDateFilterOptions, setHideDateFilterOptions] = useState(false);
	const [dateRangeOpened, setDateRangeOpened] = useState(false);
	const [sortSelection, setSortSelection] = useState(SORT_OPTIONS[0]);

	const { t } = useTranslation();

	useEffect(() => {
		if (selectedPeriodIndex === CUSTOM_INTERVAL_SELECTION) {
			setHideDateFilterOptions(true);
		} else {
			setHideDateFilterOptions(false);
		}
	}, [selectedPeriodIndex]);

	const dateFromMoment = moment(dateFrom).format('D MMMM YYYY');
	const dateToMoment = moment(dateTo).format('D MMMM YYYY');
	
	const toggleCustomDateFilterOpen = (dateRangeVisible, dateFiltersVisible) => {
		setHideDateFilterOptions(!dateFiltersVisible);
		setDateRangeOpened(dateRangeVisible);
	};

	const cancelSetRange = () => {
        const showDateFilters = selectedPeriodIndex !== CUSTOM_INTERVAL_SELECTION;
        toggleCustomDateFilterOpen(false, showDateFilters);
	};
	
	const setDateRange = (from, to) => {
        if (from && to) {
            const dateFrom = moment(from).format();
            const dateTo = moment(to).format();
            
            onDateRangeChange(dateFrom, dateTo);
			setDateRangeOpened(false);
			setHideDateFilterOptions(true);
        }
	};

	const setDefaultRange = () => {
        onIntervalChange(DEFAULT_INTERVAL_SELECTION);
	};
	
	const clearRange = () => {
        setDefaultRange();
        toggleCustomDateFilterOpen(false, true);        
    };
	
	const filterByDate = (selectionIndex, ev) => {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }

        if (Number(selectionIndex) !== CUSTOM_INTERVAL_SELECTION) {
			onIntervalChange(selectionIndex);
        } else {
            const showDateFilters = selectedPeriodIndex !== CUSTOM_INTERVAL_SELECTION;
            toggleCustomDateFilterOpen(true, showDateFilters);
        }
    };
	
	const sortTopicsTree = (selectionIndex, ev) => {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }

		const sortSelection = SORT_OPTIONS[Number(selectionIndex)];
		setSortSelection(sortSelection);
        
        switch (Number(selectionIndex)) {
            case 0:
                actionSortByVolume(SORT_DIRECTION_DESC);
                break;
            case 1:
                actionSortByVolume(SORT_DIRECTION_ASC);
                break;
            case 2:
                actionSortByRating(SORT_DIRECTION_DESC);
                break;
            case 3:
                actionSortByRating(SORT_DIRECTION_ASC);
                break;
            default:
                break;
        }
	};
	
	return (
		<Card pad={true} className="options">
			<div className="common-filters-bar">
				<i className="glyphicon glyphicon-time"></i> {t('filter_bar_ratings_for')}
				{!hideDateFilterOptions && 
				<OptionsSelection 
					className="uitest-time-range-selector"
					onSelection={filterByDate}
					options={DATE_FILTER_OPTIONS.map((o, i) => {
						const option = { label: t(o), value: i.toString() };
						if (i === 4) {
							if (!enableCustomDateRange) {
								option.nonFree = true;
								option.disabled = true;
							}
						}
				
						return option;
					})} 
					value={DATE_FILTER_OPTIONS.indexOf(DATE_FILTER_OPTIONS[Number(selectedPeriodIndex)]).toString()} 
				/>
				}

				{hideDateFilterOptions && 
				<div className="options-selector common-options-selector uitest-custom-date-selection">
					<div className="selection-label">
						&nbsp;
						<button
							className='btn btn-link option'
							onClick={() => toggleCustomDateFilterOpen(true)}
						>
							{dateFromMoment}
						</button>
						&nbsp;to&nbsp;
						<button
							className='btn btn-link option'
							onClick={() => toggleCustomDateFilterOpen(true)}
						>
							{dateToMoment}
						</button>
						&nbsp;
						<button className='btn btn-link option' onClick={clearRange}>
							<i className='glyphicon glyphicon-remove'></i>
						</button>
						&nbsp;
					</div>
				</div>
				}

				{dateRangeOpened && 
					<DateRange
						dateFrom={Object.assign({}, dateFrom)}
						dateTo={Object.assign({}, dateTo)}
						onRangeSelected={setDateRange} 
						cancelRangeSelect={cancelSetRange} 
					/>
				}

				{t('filter_bar_sorted_by')} 
				<OptionsSelection 
					onSelection={sortTopicsTree}
					options={SORT_OPTIONS.map((o, i) => ({ label: t(o), value: i.toString() }))} 
					value={SORT_OPTIONS.indexOf(sortSelection).toString()} 
				/>
			</div>
		</Card>
	);
};

TopicsTreeFilterSortBar.propTypes = {
	dateFrom: PropTypes.string,
	dateTo: PropTypes.string,

	/** 
	 * @param {number} selectedPeriodIndex a number, representing the selected interval 
	 * index from the DATE_FILTER_OPTIONS collection. Default to "1" */
	selectedPeriodIndex: PropTypes.number,

	/** 
	 * @prop {bool} enableCustomDateRange if set to false, the date range selectior will not show the 
	 * custom date range option. Default true 
	 * */
	enableCustomDateRange: PropTypes.bool,

	/** 
	 * @prop {func} actionSortByRating action send on sort by rating selection 
	 * @param {string} direction ASC or DESC sort direction send as string
	 * */
	actionSortByRating: PropTypes.func.isRequired,

	/** 
	 * @prop {func} actionSortByVolume action send on sort by volume selection 
	 * @param {string} direction ASC or DESC sort direction send as string
	 * */
	actionSortByVolume: PropTypes.func.isRequired,

	/** 
	 * @prop {func} onIntervalChange action send on selecting a time range option
	 * @param {number} intervalIndex a number, representing the selected interval index from the DATE_FILTER_OPTIONS collection
	 * */
	onIntervalChange: PropTypes.func.isRequired,
	
	/** 
	 * @prop {func} onDateRangeChange action send on selecting custom date range from the time range calendar control
	 * @param {Date} dateFrom a date object, indicating time range start date
	 * @param {Date} dateTo a date object, indicating time range end date
	 * */
	onDateRangeChange: PropTypes.func.isRequired,
};

TopicsTreeFilterSortBar.defaultProps = {
	enableCustomDateRange: true,
	selectedPeriodIndex: 1
};

export default TopicsTreeFilterSortBar;