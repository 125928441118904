import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from "./scenes/NavHeader";
import NavMobile from "./scenes/NavMobile";
import NotificationsContainer from './components/common/notificationsContainer';
import ModalRoot from './modalRoot';
import CookieMessage from './components/common/cookieMessage';
import LiveFeed from './components/votes/liveFeed';

import * as Icon from 'react-feather';

import { logout, requestLoginStatus } from './actions/authActions';
import { showModal, hideModal } from './actions/modalActions';
import { clearContactFormErrors } from './actions/contactActions';
import { CONTACT_FORM_MODAL, CONFIRM_MODAL } from './helpers/modalTypes';
import { isOnMobile } from './helpers/utils';
import messages from './helpers/messages';
import AppVersion from './components/common/appVersion';

import i18n from './i18n';

const APP_CLASS_NAMES = "App main";

export class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			liveFeedVisible: false,
			mobileMenuOpen: false,
			liveFeedVotes: [],
			appVersion: process.env.REACT_APP_VERSION,
			currentLiveVotesCount: 0,
			unreadLiveVotesCount: 0,
			isInitialized: false,	// used to prevent child components (which need authentications) of being rendered falsy, before the requestLoginStatus action is called
            t: i18n.t.bind(i18n),
		};

        this.toggleLiveFeedVisible = this.toggleLiveFeedVisible.bind(this);
        this.closeModal = this.closeModal.bind(this);
		this.showConfirmSendModal = this.showConfirmSendModal.bind(this);
		this.handleSupportButtonClick = this.handleSupportButtonClick.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		const newState = {};

		if (state.liveFeedVisible) {
			newState.unreadLiveVotesCount = 0;
		}

		if (props.liveVotes.length !== state.currentLiveVotesCount) {
			newState.currentLiveVotesCount = props.liveVotes.length;

			if (!state.liveFeedVisible) {
				newState.unreadLiveVotesCount = state.unreadLiveVotesCount + 1;
			}
		}

		return newState;
	}

	componentDidMount(){
		this.props.requestLoginStatus();

		const contentDiv = document.getElementsByClassName('main')[0];
		contentDiv.addEventListener('scroll', this._onScrollHandler);
		this.setState({isInitialized: true});		
	}

    componentDidUpdate(){
		const resizeEvent = document.createEvent('HTMLEvents');
        resizeEvent.initEvent('resize', true, true);
        window.dispatchEvent(resizeEvent);
    }

	componentWillUnmount(){
		const contentDiv = document.getElementsByClassName('main')[0];		
		contentDiv.removeEventListener('scroll', this._onScrollHandler);
	}

	_onScrollHandler(e){
		const scrollContainer = e.target;
		const menuToggleDom = document.getElementById('menuToggle');

		menuToggleDom.style.display = "block";

		if (scrollContainer.scrollTop > 10) {
			menuToggleDom.style.display = "none";
		}
	}

	onLogoutClick(e) {
		e.preventDefault();
		const authData = this.props.auth;
		if (authData.isAuthenticated) {
			// TODO: add better popup component
			if (window.confirm("Do you want to logout?")) {
				this.props.logout(authData.user);
			}
			this.context.router.push('/');
		}
	}

	handleSupportButtonClick() {
		this.showContactForm(); 
	}

	toggleLiveFeedVisible(){
		const liveFeedVisible = !this.state.liveFeedVisible;
		this.setState({ liveFeedVisible });
	}
	
	loadLiveFeedVotes(votes = []) {
		const lastFeedVote = this.state.liveFeedVotes[this.state.liveFeedVotes.length - 1];
		let liveVotesChanged = true;

		if (lastFeedVote && votes.length > 0) {
			liveVotesChanged = lastFeedVote.date !== votes[votes.length - 1].date;
		}
		
		if (liveVotesChanged) {
			this.setState({
				liveFeedVotes: votes
			});
		}
	}

	toggleMobileMenu(){
		const newMobileMenuOpen = !this.state.mobileMenuOpen;
		
		this.appDom.className = newMobileMenuOpen ? `${APP_CLASS_NAMES} open` : APP_CLASS_NAMES ;
		this.setState({ mobileMenuOpen: newMobileMenuOpen });
	}

	showContactForm() {
		const config = {
			onSubmit: this.showConfirmSendModal,
			onClose: () => {
				this.props.clearContactFormErrors();
				this.closeModal();
			}
		};
		this.props.showModal(CONTACT_FORM_MODAL, config);
	}

	closeModal() {
		this.props.hideModal(); 
	}

    showConfirmSendModal() {
        const config = {
            title: messages.common.thankYou,
            message: messages.common.requestSendToSupport,
            onClose: this.closeModal,
            onConfirm: this.closeModal,
            hasFooter: false
        };
    
        this.props.showModal(CONFIRM_MODAL, config);
    }

	render() {
		const { liveVotes, children, auth, location, entity, showModal, hideModal } = this.props;
		const { t, liveFeedVisible, unreadLiveVotesCount, isInitialized, appVersion } = this.state;

		const childrenScenes = React.Children.map(children, (child) => {
			return React.cloneElement(child, {
				loadLiveFeed: this.loadLiveFeedVotes.bind(this)
			});
		}); 

		return (
			<div className={APP_CLASS_NAMES} ref={(app) => this.appDom = app}>
				<div className="sub-header"><LoadingBar style={{ backgroundColor: '#1F8DD6' }} /></div>
				
				{auth.isAuthenticated && auth.user.permissions && 
				<NavMobile 
					auth={auth}
					entity={entity}
					onLogoutClick={this.onLogoutClick.bind(this)}
					onOpenMobileMenu={this.toggleMobileMenu.bind(this)}
					onSupportClick={this.handleSupportButtonClick}
					isAuthenticated={auth.isAuthenticated}
					locationPathName={location.pathname}
					showModal={showModal}
					hideModal={hideModal}
				/>
				}

				<Header 
					auth={auth}
					entity={entity}
					onLogoutClick={this.onLogoutClick.bind(this)}
					onMenuItemClick={this.toggleMobileMenu.bind(this)}
					onLiveFeedToggleClick={this.toggleLiveFeedVisible}
					className={auth.isAuthenticated ? 'hide-on-mobile' : ''}
					isAuthenticated={auth.isAuthenticated}
					locationPathName={location.pathname}
					liveFeedActive={liveFeedVisible}
					unreadVotesCout={unreadLiveVotesCount}
					showModal={showModal}
					hideModal={hideModal}
				/>

				<div className="live-feed-panel">
					{auth.isAuthenticated && liveFeedVisible && <LiveFeed votes={ liveVotes } />}
				</div>

				<div className={`content ${liveFeedVisible ? 'live-feed-epxanded' : 'live-feed-collapsed'}`}>
					{isInitialized &&
						childrenScenes
					}

					{!auth.isAuthenticating && !auth.isAuthenticated && 
						<div className="uitest-cookie-notice">
							<CookieMessage />
						</div>
					}
				</div>

				<div>
					<NotificationsContainer />
				</div>

				<div className="footer clearfix">
					<div className="footer-content">
						<img style={{ height: '25px', marginRight: '10px', opacity: 0.3 }} src='/kazva-logo.png' alt="Kazva BG Logo"/>
						<span className="text-label">
							<Link className="text-muted" to="/static/Dashboard%20privacy%20policy">{t('footer_privacy_policy')}</Link> &nbsp;• &nbsp;
							<Link className="text-muted" to="/static/Dashboard%20cookie%20policy">{t('footer_cookie_policy')}</Link> &nbsp;• &nbsp; 
							<Link className="text-muted" to="/static/Your%20data%20and%20privacy%20rights">{t('footer_data_privacy')}</Link>
						</span>
						<label className="version pull-right">
							<AppVersion currentVersion={appVersion} clickable={auth.isAuthenticated} />
						</label>
					</div>
				</div>				
				<ModalRoot />

				{!isOnMobile() && <div className="btn-float" title="Send an email to kazva.bg support team" onClick={this.handleSupportButtonClick}>
					<label>{t('floating_button_contact_support')}</label>				
					<div className="btn btn-support"><Icon.MessageSquare size={28} /></div>
				</div>}
			</div>
		);
	}
}

App.propTypes = {
	auth: PropTypes.shape({
		user: PropTypes.shape({
			permissions: PropTypes.arrayOf(PropTypes.string)
		}),
		isAuthenticated: PropTypes.bool.isRequired
	}).isRequired,
	children: PropTypes.element,
	entity: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string
	}),
	location: PropTypes.shape({
		pathname: PropTypes.string
	}),
	requestLoginStatus: PropTypes.func.isRequired,
	showModal: PropTypes.func,
	hideModal: PropTypes.func,
	clearContactFormErrors: PropTypes.func,
	logout: PropTypes.func,
	latestEntityVotes: PropTypes.array,
	liveVotes: PropTypes.array
};

App.contextTypes = {
	router: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	const entity = state.entities[0];
	const { votesForEntity } = state;
	const liveVotes = votesForEntity;

	return {
		auth: state.auth,
		masterTopic: state.topicsTree.masterTopic,
		entity,
		liveVotes
	};
}

App.defaultProps = {
	liveVotes: []
};

export default connect(mapStateToProps, { showModal, hideModal, clearContactFormErrors, logout, requestLoginStatus })(App);
