import React from 'react';
import ReactDOM from 'react-dom';
import { Router, browserHistory } from 'react-router';
import routes from './routes';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import logger from './helpers/logger';
import apiServers from './resources/apiServers';
import { graphPing } from './api/expressApi';
import { CONNECTIVITY_ERROR } from './resources/apiConstants';

logger.setup();
logger.info("current version: " + process.env.REACT_APP_VERSION);
logger.info("build date: " + process.env.REACT_APP_BUILD_DATE);
logger.debug("current deploy environment: " + (process.env.REACT_APP_DEPLOY_ENV || 'localdev'));

const initApp = () => {
    // initialize store
    const store = configureStore();

    ReactDOM.render(
        <Provider store={store}>
            <Router history={browserHistory} routes={routes} />
        </Provider>,
        document.getElementById('root')
    );
};

const initErrorPage = (error, details) => {
    ReactDOM.render(
        <div className="container">
            <h2>Unable to connect: {error.message}</h2>
            {details && <p>{details}</p>}
        </div>,
        document.getElementById('root')
    );
};

export const startup = () => {
    apiServers.setup()  // setup the server endpoints
        .then(() => graphPing())  // test the connection to api service
        .then(initApp)
        .catch(error => {
            logger.error("GraphApi call failed: " + error.message);

            // error type comes from express api error handler, should happen only on blocked ports
            if (error.type === CONNECTIVITY_ERROR) {
                apiServers.setupProxy()
                    .then(initApp)
                    .catch(initErrorPage);
            } else {
                initErrorPage(error);
            }
        });
};






