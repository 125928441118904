import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { handleCommonError } from './errorActions';
import * as types from './actionTypes';
import { graphGet, graphPost, graphPut, graphDel, staticGet } from '../api/expressApi';
import { addNotification } from './miscActions';
import { notificationLevel } from '../helpers/notification';
import Logger from "../helpers/logger";
import { isValidFileName } from 'hollerlive-shared/validators';

function loadFilesList(list) {
    return { type: types.LOAD_FILES_LIST };
}
function loadFilesListSuccess(list) {
    return { type: types.LOAD_FILES_LIST_SUCCESS, payload: { list } };
}
function loadFilesListError(requestError) {
    return { type: types.LOAD_FILES_LIST_ERROR, payload: { requestError } };
} 
function loadingFile(fileName) {
   return { type: types.FILE_LOADING, payload: { fileName } };
}
function loadFileSuccess(fileName, contents) {
    return { type: types.FILE_LOADED_SUCCESS, payload: { fileName, contents } };
}
function loadFileError(fileName, requestError) {
    return { type: types.FILE_LOADED_ERROR, payload: { fileName, requestError } };
}
function editFileSuccess(fileName, contents) {
    return { type: types.FILE_EDIT_SUCCESS, payload: { fileName, contents } };
}
function editFileError(fileName, requestError) {
    return { type: types.FILE_EDIT_ERROR, payload: { fileName, requestError } };
}
function saveFileSuccess(fileName, contents) {
    return { type: types.FILE_SAVE_SUCCESS, payload: { fileName, contents } };
}
function saveFileError(fileName, error) {
    return { type: types.FILE_SAVE_ERROR, payload: { fileName, error } };
}
function deleteFileSuccess(fileName) {
    return { type: types.FILE_DELETE_SUCCESS, payload: { fileName } };
}
function deleteFileError(fileName, error) {
    return { type: types.FILE_DELETE_ERROR, payload: { fileName, error } };
}
function clearFileErrors() {
    return { type: types.RESET_FILE_REQUEST_ERRORS };
}

export function getMarkdownFilesList() {
    return async dispatch => {
        dispatch(showLoading());
        dispatch(loadFilesList());
        try { 
            const filesList = await graphGet('/contents');
			dispatch(loadFilesListSuccess(filesList));
        } catch (err) {
            dispatch(loadFilesListError(err));
            handleCommonError(dispatch, err, false);
        } finally {
			dispatch(hideLoading());
        }
    };
}

export function getMarkdownFileContentsByName(fileName) {
	return async dispatch => {
		dispatch(showLoading());
		dispatch(loadingFile(fileName));
		try {
            const timestamp = Date.now();   // taking care of server caching
            // TODO: include the extension in the file itself - currently we are only dealing with .md files
            const contents = await staticGet(`/contents/${escapeUriFileName(fileName)}.md?ts=${timestamp}`);
            dispatch(loadFileSuccess(fileName, contents));
		} catch (error) {
            dispatch(loadFileError(fileName));
			handleCommonError(dispatch, error, false);
		} finally {
            dispatch(hideLoading());
        }
	};
}

export function createMarkdownFileContentsByName(fileName, contents) {
    return async dispatch => {
        if(!isValidFileName(fileName)){
            return dispatch(saveFileError(fileName, new Error(`Filename is empty or contains forbiden characters.`)));
        }

        dispatch(showLoading());
        try {
            const mdFileContents = await graphPost('/contents/save', { fileName, contents });
            dispatch(addNotification(`File ${fileName} created successfully.`,  notificationLevel.success));
            dispatch(saveFileSuccess(fileName, mdFileContents));
        } catch (error) {
            dispatch(saveFileError(fileName, error));
            handleCommonError(dispatch, error, false);
        } finally {
            dispatch(hideLoading());
        }
    };
}

export function editMarkdownFileContentsByName(fileName, contents) {
    return async dispatch => {
		dispatch(showLoading());
		try {
			const mdFileEditedContents = await graphPut('/contents/edit', {
				fileName: fileName,
				contents
			});
            dispatch(addNotification(`File ${fileName} updated successfully.`, notificationLevel.success));
			dispatch(editFileSuccess(fileName, mdFileEditedContents));
		} catch (error) {
			dispatch(editFileError(fileName, error));
			handleCommonError(dispatch, error, false);
		} finally {
			dispatch(hideLoading());
		}
	};
}

export function deleteMarkdownFileByName(fileName) {
    return async dispatch => {
        dispatch(showLoading());
        try {
            await graphDel(`/contents/${escapeUriFileName(fileName)}/delete`);
            dispatch(addNotification(`File ${fileName} deleted.`, notificationLevel.success));
            dispatch(deleteFileSuccess(fileName));
        } catch (error) {
            dispatch(deleteFileError(fileName, error));
            handleCommonError(dispatch, error, false);
        } finally {
            dispatch(hideLoading());
        }
    };
}

export function clearMarkdownFileErrors() {
    return dispatch => {
        dispatch(clearFileErrors());
    };
}

function escapeUriFileName(fileName) {
    if(!fileName) {
        Logger.error("Cannot escape non-existing string");
    }
    return decodeURIComponent(fileName);
}
