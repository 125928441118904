import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import * as qnaActions from '../../actions/qnaActions';
import QuestionsByVolumeChart from '../../components/topics/charts/questionsByVolume';
import Card from '../../components/common/card';
import { initChartSizeConfig, updateChartWidth } from '../../helpers/topicsHelper';
import QuestionsList from '../../components/topics/qna/questionsList';
import i18n from '../../i18n';

const QUESTIONS_PAGE_SIZE = 10;

export class TopicDetailsQna extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxChartWidth: null,
            halfChartWidth: null,
            shouldUpdateChartsWidth: false,
            filters: {},
            offset: 0,
            questionPageIndex: 0,
            timeFrameChanged: false,
            timeFrame: {
                dateFrom: props.dateFrom,
                dateTo: props.dateTo
            },
            voterFiltersChanged: false,
            voterFilters: {
                anonymous: true,
                facebook: true,
                email: true,
                latestPerVoter: false,
                sources: []
            },
            t: i18n.t.bind(i18n)
        };
        this.handleResize = this.handleResize.bind(this);
        this._mapToQuestionDetailsData = this._mapToQuestionDetailsData.bind(this);
        this.loadQuestionDetails = this.loadQuestionDetails.bind(this);
        this.loadMultipleQuestionDetails = this.loadMultipleQuestionDetails.bind(this);
        this.chartsSizesConfiguration = null;
    }
    
    static getDerivedStateFromProps(nextProps, prevState) {
        let updatedState = {};
        if ((!isEmpty(prevState.timeFrame.dateFrom)) && (nextProps.dateFrom !== prevState.timeFrame.dateFrom)) {
            updatedState = {
                timeFrameChanged: true,
                timeFrame: {
                    dateFrom: nextProps.dateFrom,
                    dateTo: nextProps.dateTo
                }
            };
        }
        if (!isEqual(nextProps.voterFilters, prevState.voterFilters)){
            updatedState = {
                ...updatedState,
                voterFiltersChanged: true,
                voterFilters: Object.assign({}, nextProps.voterFilters)
            };
        }
        if (isEmpty(updatedState)) {
            return {
                timeFrameChanged: false,
                voterFiltersChanged: false
            };
        } else {
            return updatedState;
        }
    }

    componentDidMount() {
        this.chartsSizesConfiguration = initChartSizeConfig(this.chartsContainerDom, 'charts-card');
        const { halfChartWidth, maxChartWidth } = this.chartsSizesConfiguration;
        this.setState({ halfChartWidth, maxChartWidth }, () => window.addEventListener("resize", this.handleResize));
        if (!isEmpty(this.props.topic)) {
            this.loadQuestionStats();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const shouldUpdateChartSizes = this.state.shouldUpdateChartsWidth ||
            (!isEmpty(this.props.qnaForTopic.questionDetails) && isEmpty(prevProps.qnaForTopic.questionDetails));
        if (shouldUpdateChartSizes) {
            updateChartWidth(this.chartsSizesConfiguration);
            const { halfChartWidth, maxChartWidth } = this.chartsSizesConfiguration; 
            this.setState({ halfChartWidth, maxChartWidth, shouldUpdateChartsWidth: false });
        }

        if (isEmpty(prevProps.topic) && !isEmpty(this.props.topic)){
            this.loadQuestionStats();
        } else {
            const timeFrameDidChange =
                this.state.timeFrameChanged !== prevState.timeFrameChanged && this.state.timeFrameChanged;
            const voterFiltersDidChange =
                this.state.voterFiltersChanged !== prevState.voterFiltersChanged && this.state.voterFiltersChanged;
            if (timeFrameDidChange || voterFiltersDidChange) {
                this.loadQuestionStats();
                this.setState({ timeFrameChanged: false, voterFiltersChanged: false });                
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    loadQuestionStats() {
        const dateFromUtcString = moment(this.props.dateFrom).utc().format();
        const dateToUtcString = moment(this.props.dateTo).utc().format();
        this.props.actions.loadQuestionsStatsForTopic(
			this.props.topic.id,
			dateFromUtcString,
			dateToUtcString,
			this.state.voterFilters
		);
    }

    loadQuestionDetails(question) {
        const topic = this.props.topic.id;
        const dateFromUtcString = moment(this.props.dateFrom).utc().format();
        const dateToUtcString = moment(this.props.dateTo).utc().format();
        this.props.actions.loadDetailsForTopicQuestion(
			topic,
			question,
			dateFromUtcString,
			dateToUtcString,
			this.state.voterFilters
		);
    }
    loadMultipleQuestionDetails(questions) {
        const topic = this.props.topic.id;
        const dateFromUtcString = moment(this.props.dateFrom).utc().format();
        const dateToUtcString = moment(this.props.dateTo).utc().format();
        this.props.actions.loadDetailsForMultipleTopicQuestions(
            topic,
            questions,
            dateFromUtcString,
            dateToUtcString,
            this.state.voterFilters
        );
    }

    handleResize() {
        this.setState({ maxChartWidth: null, halfChartWidth: null, shouldUpdateChartsWidth: true });
    }

    _mapToQuestionDetailsData(question) {
        return Object.assign({}, question, { topicVotesCount: this.props.votesCount });
    }

    _mapToChartData(questions) {
        const chartData = [];
        [...questions].sort((a,b) => a.orderIndex - b.orderIndex).forEach((q, index) => {
            if (q && q.answerCount > 0) {
                chartData.push(Object.assign({}, q, {
                    shortQuestion: q.question.length > 15 ? `${index + 1}.${q.question.slice(0, 15)}...` : `${index + 1}.${q.question}`
                }));
            }
        });
        return chartData;
    }

    render() {
        const { questionDetails, isLoaded, isLoading } = this.props.qnaForTopic;
        const questionsByVolumeData = questionDetails || [];
        let qnaListData = [];
        if (questionsByVolumeData.length > 0) {
            qnaListData = questionsByVolumeData.map(this._mapToQuestionDetailsData);
        } 
        const volumeChartData = this._mapToChartData(questionsByVolumeData);
        const volumeChartHeight = Math.max(volumeChartData.length * 30, 300);
        const chartMaxWidth = this.state.maxChartWidth;
        const chartHalfWidth = this.state.halfChartWidth;
        const t = this.state.t;
        return (
            <div>
                <div className="topic-details-qna row-fluid" ref={chartsContainer => this.chartsContainerDom = chartsContainer}>
                    <div className="col-md-12 col-xs-12 clearfix export-pdf-questions-volume-container" >
                        <Card title={t('topic_analytics_chart_questions_title')} className="charts-card" hoffset={false}>
                            <QuestionsByVolumeChart
                                width={chartMaxWidth}
                                height={volumeChartHeight} 
                                data={volumeChartData} 
                                />
                        </Card>
                    </div>

                    <div className="row-fluid clearfix export-pdf-questionnaire-container">
                        <div className="col-md-12 col-xs-12 clearfix">
                            <Card title={t('topic_analytics_question_list_title')}>
                                <QuestionsList
                                    chartsWidth={chartHalfWidth} 
                                    resizeChartsWidth={this.handleResize}
                                    questions={qnaListData}
                                    isLoaded={isLoaded}
                                    isLoading={isLoading}
                                    shouldResetItems={this.state.timeFrameChanged || this.state.voterFiltersChanged}
                                    pageSize={QUESTIONS_PAGE_SIZE}
                                    loadQuestionAnswers={this.loadQuestionDetails}
                                    loadQuestionsAnswers={this.loadMultipleQuestionDetails}
                                />
                            </Card>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

TopicDetailsQna.propTypes = {
	qnaForTopic: PropTypes.shape({
		questionDetails: PropTypes.arrayOf(
			PropTypes.shape({
				question: PropTypes.string
			}
		)),
        isLoaded: PropTypes.bool,
        isLoading: PropTypes.bool
	}).isRequired,
	dateFrom: PropTypes.any,
	dateTo: PropTypes.any,
	topic: PropTypes.object,
	votesCount: PropTypes.number,
	voterFilters: PropTypes.object,
	actions: PropTypes.shape({
		loadQuestionsStatsForTopic: PropTypes.func,
		loadDetailsForTopicQuestion: PropTypes.func,
        loadDetailsForMultipleTopicQuestions: PropTypes.func
	})
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...qnaActions }, dispatch)
    };
}

function mapStateToProps(state, ownProps) {
    let topicId = ownProps.params.id;
    const qnaForTopic = state.qnaForTopics[topicId] ? state.qnaForTopics[topicId] : {};
    return {
        qnaForTopic,
        initialDateRange: state.topicsTree.periodFilter
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicDetailsQna);
