import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import VoteTimelineItem from "./timeline/VoteTimelineItem";

import './css/liveFeed.css'; 
import { ChevronRight, ChevronLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';

export const VOTE_ITEM_WIDTH = 256;
const SCROLL_SIZE = 256;

const LiveFeed = ({ votes }) => {
	const [currentVoteComponentsList, setCurrentVoteComponentsList] = useState([]);
	const liveFeedRef = useRef(null);

	const { t } = useTranslation();
	
	useEffect(() => {
		const voteTimeLineItems = votes.map((_, voteIndex, votesArray) => {
			const vote = votesArray[votesArray.length - voteIndex - 1];
			return (
				<VoteTimelineItem
					key={`${vote.topicId}${vote.rowId}`}
					style={{ width: `${VOTE_ITEM_WIDTH}px` }}
					vote={vote}
				/>
			);
		});
		setCurrentVoteComponentsList(voteTimeLineItems);
	}, [votes]);

	useEffect(() => {
		const itemsContainer = liveFeedRef.current.getElementsByClassName('items-container')[0];
		const scrollContainer = liveFeedRef.current.getElementsByClassName('scrollable-votes-container')[0] || {};

		if (itemsContainer) {
			if (itemsContainer.offsetWidth > scrollContainer.offsetWidth) {
				showNextBtn();
			}
		}
	}, [currentVoteComponentsList]);
	
	function setButtonsVisibility() {	
		const scrollContainer = liveFeedRef.current.getElementsByClassName('scrollable-votes-container')[0] || {};
		const scrollLeftValue = scrollContainer.scrollLeft;
		const itemsContainer = liveFeedRef.current.getElementsByClassName('items-container')[0];
		const prevBtn = liveFeedRef.current.getElementsByClassName('btn-prev')[0].parentElement;
		
		if (scrollLeftValue <= 0) {
			prevBtn.style.display = "none";
		} else {
			prevBtn.style.display = "inline-block";
		}

		if (scrollLeftValue >= (itemsContainer.offsetWidth - scrollContainer.offsetWidth)) {
			hideNextBtn();
		} else {
			showNextBtn();
		}
	}

	function showNextBtn() {
		const nextBtn = liveFeedRef.current.getElementsByClassName('btn-next')[0];

		nextBtn.parentElement.style.width = "auto";
		nextBtn.style.display = "inline-block";
	}

	function hideNextBtn() {
		const nextBtn = liveFeedRef.current.getElementsByClassName('btn-next')[0];

		nextBtn.parentElement.style.width = nextBtn.parentElement.offsetWidth - nextBtn.offsetWidth;
		nextBtn.style.display = "none";
	}

	function handleLiveFeedControlsClick(direction) {
		let interval = SCROLL_SIZE / 2;
		const scrollContainer = liveFeedRef.current.getElementsByClassName('scrollable-votes-container')[0] || {};
		
		const scrollInterval = setInterval(scrollVotes, 50);
		function scrollVotes () {
			let scrollValue;

			switch (direction) {
				case "prev":
					scrollValue = scrollContainer.scrollLeft - interval;
					break;
				case "next":
					scrollValue = scrollContainer.scrollLeft + interval;
					break;
				default:
					break;
			}

			scrollContainer.scrollLeft = scrollValue;
			interval = interval / 2;

			if (interval < 2) {
				clearInterval(scrollInterval);
				setButtonsVisibility();
			}
		};

	}

	return (
		<div className="live-feed uitest-live-feed" ref={liveFeedRef}>
			<div className="scrollable-votes-container">
				<div className="items-container">
					{ currentVoteComponentsList }
				</div>
			</div>
			
			<div style={{ display: "none" }} className="left-controls clearfix">
				<button  className="btn btn-transparent btn-prev" onClick={e => handleLiveFeedControlsClick("prev", e.target)}>
					<ChevronLeft size={18} />
				</button>
			</div>

			<div style={{ width: 'auto' }} className="right-controls clearfix">
				<button 
				 	style={{ display: "none" }}
					className="btn btn-transparent pull-left btn-next" 
					onClick={e => handleLiveFeedControlsClick("next", e.target)}
				>
					<ChevronRight size={18} />
				</button>

				<label>{t('nav_header_live_feed')}</label>
			</div>
		</div>
	);
};

LiveFeed.propTypes = {
	votes: PropTypes.arrayOf(PropTypes.shape({
		vote: PropTypes.number.isRequired,
		topicId: PropTypes.string.isRequired,
		rowId: PropTypes.number.isRequired,
		latitude: PropTypes.number,
		longitude: PropTypes.number,
		date: PropTypes.string,
		gender: PropTypes.string,
		age: PropTypes.number,
		ageGroup: PropTypes.string,
		locale: PropTypes.string,
		country: PropTypes.string,
		city: PropTypes.string,
		region: PropTypes.string,
		authType: PropTypes.string,
		voterUniqueId: PropTypes.string,
		sources: PropTypes.array,
		topicName: PropTypes.string
	}))
};

LiveFeed.defaultProps = {
	votes: []
};

export default LiveFeed;