import * as types from '../actions/actionTypes';
import { USER_ROLES } from '../helpers/user';

const initialState = {
	searchResult: null,
	usersList: [],
	createdUser: {},
	updatedUser: {},
	requestErrors: {},
	isLoading: false,
	isLoaded: false
};

export function usersReducer(state = initialState, action) {
	switch (action.type) {
		case types.LOAD_USER_SEARCH_DATA_SUCCESS: {
			const { userSearchDetails } = action.payload;
			return Object.assign({}, state, { searchResult: userSearchDetails });
		}
		case types.RESET_SEARCH_USER_DATA_SUCCESS: {
			return Object.assign({}, state, { searchResult: null });
		}
		case types.RESET_USER_SEARCH_RESULT: {
			return initialState;
		}
		case types.RESET_SELECTED_USERS: {
			const newUsersList = state.usersList.map(user => {
				const newUser = { ...user };
				newUser.isSelected = false;
				return newUser;
			});
			return Object.assign({}, state, { usersList: newUsersList });
		}
		case types.LOAD_USERS_SUCCESS: {
			const { users } = action.payload;
			const mappedUsers = users.map(user => {
				const newUser = { ...user };
				newUser.isSuperAdmin = user.roleId === USER_ROLES.superAdmin;
				newUser.isSelected = false;
				return newUser;
			});
			return Object.assign({}, state, { usersList: mappedUsers });
		}
		case types.CHANGE_USERS_SELECTED: {
			const { isSelected, userIds } = action.payload;
			const mappedUsersList = state.usersList.map(user => {
				const newUser = { ...user };
				userIds.forEach(userId => {
					if (!newUser.isSuperAdmin && newUser.id === userId) {
						newUser.isSelected = isSelected;
						return null;
					}
				});
				return newUser;
			});
			return Object.assign({}, state, { usersList: mappedUsersList });
		}
		case types.USER_STATUS_CHANGE_SUCCESS: {
			const newState = { ...state };
			const updatedUsers = newState.usersList.map(user => {
				const newUser = { ...user };
				action.payload.userIds.forEach(updatedUsersId => {
					if (user.id === updatedUsersId) {
						newUser.active = action.payload.activeStatus;
						newUser.version++;
					}
				});
				return newUser;
			});
			return Object.assign({}, state, { usersList: updatedUsers });
		}
		case types.USERS_DELETE_SUCCESS: {
			const { deletedUsers } = action.payload;
			const updatedUsers = state.usersList.filter(user => {
				return !deletedUsers.some(deletedUser => Number(user.id) === Number(deletedUser.id));
			});
			return Object.assign({}, state, { usersList: updatedUsers });
		}
		case types.CREATE_USER_SUCCESS: {
			const usersList = [...state.usersList, action.payload.user];
			return Object.assign({}, state, {
				usersList,
				createdUser: { id: action.payload.user.id },
				isLoading: false,
				isLoaded: true
			});
		}
		case types.RESET_CREATE_USER_SUCCESS: {
			return Object.assign({}, state, { createdUser: {} });
		}
		case types.CREATE_USER_ERROR: {
			return Object.assign({}, state, {
				requestErrors: Object.assign({}, action.payload.errors),
				isLoading: false,
				isLoaded: true
			});
		}
		case types.CREATE_USER_REQUESTED:
		case types.UPDATE_USER_REQUESTED: {
			return Object.assign({}, state, { isLoading: true, isLoaded: false });
		}
		case types.LOAD_USER_DATA_SUCCESS: {
			const { user } = action.payload;
			const loadedUser = Object.assign({}, user, {
                entity: {
                    id: user.entity ? user.entity.id : null,
                    name: user.entity ? user.entity.name : ""
                }
			});
			const usersList = state.usersList.map(u => {
				return u.id === loadedUser.id ? loadedUser : u;
			});
            return Object.assign({}, state, { usersList });
		}
		case types.LOAD_FILTERED_USERS: {
			return Object.assign({}, state, { isLoaded: false, isLoading: true });
		}
		case types.LOAD_FILTERED_USERS_SUCCESS: {
			const foundUsersIds = action.payload.map(u => u.id);
			const currUsersWithoutReceiving = state.usersList.filter(user => {
				return foundUsersIds.indexOf(user.id) === -1;
			});
			return Object.assign({}, state, {
				isLoaded: true,
				isLoading: false,
				usersList: [...currUsersWithoutReceiving, ...action.payload]
			});
		}
		case types.UPDATE_USER_PROFILE_SUCCESS:
		case types.UPDATE_USER_SUCCESS: {
			const { user } = action.payload;
			const updatedUsersList = state.usersList.map(u => {
				return u.id !== user.id ?
			    u :
                Object.assign({}, u, user, {
                    entity: {
                        id: user.entity.id,
                        name: user.entity.name
                    }
                });
			});
			return Object.assign({}, state, {
				usersList: updatedUsersList,
				updatedUser: { id: action.payload.user.id },
				isLoading: false,
				isLoaded: true 
			});
		}
		case types.SET_USER_ROLE_SUCCESS: {
			const { userId, roleId, version } = action.payload;
			const updatedUsersList = state.usersList.map(u => {
				return u.id !== userId ?
			    u :
                Object.assign({}, u, { roleId, version });
			});
			return Object.assign({}, state, { 
				usersList: updatedUsersList,
				isLoading: false,
				isLoaded: true 
			});
		}
		case types.RESET_EDIT_USER_SUCCESS: {
			return Object.assign({}, state, { updatedUser: {} });
		}
		case types.UPDATE_USER_ERROR: {
			return Object.assign({}, state, {
				requestErrors: Object.assign({}, action.payload.errors),
				isLoading: false,
				isLoaded: true
			});
		}
		case types.RESET_USER_REQUEST_ERRORS: {
			return Object.assign({}, state, { requestErrors: {} });
		}
		case types.HAS_VERSION_ERROR: {
			const { errors, errorsTarget } = action.payload;
			const newState = Object.assign({}, state);
			if (errorsTarget && errorsTarget.type === types.UPDATE_USER_VERSION_ERROR) {
				newState.requestErrors = Object.assign({}, state.requestErrors, { version: errors });
				newState.isLoading = false;
				newState.isLoaded = true;
			}
			return newState;
		}
		default:
			return state;
	}
}
