import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const SELECTED_CLASS_NAME = 'sel';
const SELECTED_START_CLASS_NAME = 'sel--start';
const SELECTED_END_CLASS_NAME = 'sel--end';
const OUT_OF_RANGE_CLASS_NAME = 'out--range';
const PREV_MONTH_CLASS_NAME = 'dis';

const CalendarCell = ({ date, onHover, isOutOfRange, onClick, isSelected, isSelectedStart, isSelectedEnd, isPrevMonth }) => {
	let cellClassNames = `calendar__day`;
	cellClassNames = `${cellClassNames} ${isSelected ? SELECTED_CLASS_NAME : ''}`;
	cellClassNames = `${cellClassNames} ${isSelectedStart ? SELECTED_START_CLASS_NAME : ''}`;
	cellClassNames = `${cellClassNames} ${isSelectedEnd ? SELECTED_END_CLASS_NAME : ''}`;
	cellClassNames = `${cellClassNames} ${isOutOfRange ? OUT_OF_RANGE_CLASS_NAME : ''}`;
	cellClassNames = `${cellClassNames} ${isPrevMonth ? PREV_MONTH_CLASS_NAME : ''}`;

	const cellRef = useRef(null);

	const handleHover = () => {
		onHover();
	};

	const handleClick = () => {
		onClick();
	};

	return (
		<td 
			className={cellClassNames}
			ref={cellRef}
			onClick={handleClick}
			onMouseOver={handleHover}
		>
			<div className="calendar__inner-day">
				{date}
			</div>
		</td>
	);
};

CalendarCell.propTypes = {
	date: PropTypes.number,
	isSelected: PropTypes.bool,
	isSelectedStart: PropTypes.bool,
	isSelectedEnd: PropTypes.bool,
	isOutOfRange: PropTypes.bool,
	isPrevMonth: PropTypes.bool,
	onHover: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired,
};

CalendarCell.defaultProps = {
	isSelected: false,
	isSelectedStart: false,
	isSelectedEnd: false,
	outOfRange: false,
};

export default CalendarCell;