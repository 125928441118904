import * as actionTypes from './actionTypes';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { handleCommonError } from './errorActions';
import { graphPost, streamGet } from '../api/expressApi';
import { mapToQuestionStatsModel, mapToShortAnswerModel, getVoterTypesArrayFromVoterFilters } from './models/qnaModel';
import { createTopicHash } from '../helpers/utils';

function loadMultipleQuestionsDetailsSuccess(topicId, questionsWithAnswers) {
	return { type: actionTypes.LOAD_BULK_QUESTIONS_DETAILS_SUCCESS, payload: { topicId, questionsWithAnswers } };
}
function loadQuestionDetailsSuccess(topicId, question, answers) {
	return { type: actionTypes.LOAD_QUESTION_DETAILS_SUCCESS, payload: { topicId, question, answers } };
}
function loadQuestionStatsSuccess(topicId, questionStats) {
	return { type: actionTypes.LOAD_QUESTIONSTATS_SUCCESS, payload: { topicId, questionStats } };
}

export function loadDetailsForMultipleTopicQuestions(topicId, questionTitles, dateFrom, dateTo, voterFilters) {
	return async dispatch => {
		dispatch(showLoading());
		try {
			const voterTypesArray = getVoterTypesArrayFromVoterFilters(voterFilters);
			const allDetails = await graphPost(`/topicqna/${topicId}/questionsdetails`, {
				questions: questionTitles,
				startDateTime: dateFrom,
				endDateTime: dateTo,
				voterTypes: voterTypesArray,
				lastVoteOnly: voterFilters.latestPerVoter,
				sources: voterFilters.sources && voterFilters.sources.map(source => source.name)
			});
			const questionsWithAnswers = allDetails.map(answerDetails => {
				return { question: answerDetails.question, answers: mapToShortAnswerModel(answerDetails) };
			});
			dispatch(loadMultipleQuestionsDetailsSuccess(topicId, questionsWithAnswers));
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);
		}
	};
}

export function loadDetailsForTopicQuestion(topicId, question, dateFrom, dateTo, voterFilters) {
	return async dispatch => {
		dispatch(showLoading());
		try {
			const voterTypesArray = getVoterTypesArrayFromVoterFilters(voterFilters);
			const details = await graphPost(`/topicqna/${topicId}/questiondetails`, {
				question,
				startDateTime: dateFrom,
				endDateTime: dateTo,
				voterTypes: voterTypesArray,
				lastVoteOnly: voterFilters.latestPerVoter,
				sources: voterFilters.sources && voterFilters.sources.map(source => source.name)
			});
			const answers = mapToShortAnswerModel(details);
			dispatch(loadQuestionDetailsSuccess(topicId, question, answers));
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);
		}
	};
}

export function loadQuestionsStatsForTopic(topicId, dateFrom, dateTo, voterFilters) {
	return async (dispatch, getState) => {
		dispatch(showLoading());
		dispatch({ type: actionTypes.LOAD_QUESTIONSTATS, payload: { topicId } });
		const topic = getState().topics.find(t => t.id === topicId);
		const topicHash = createTopicHash(topic.name);
		try {
            const topicStructure = await streamGet('/topics/' + topicHash, { mode: "view" });
            const qna = topicStructure.qna;
            const questions = [].concat(qna.map(q => {
                return {
                    question: q.question,
                    type: q.answerSctructure,
                    orderIndex: q.orderIndex
                };
            }));
			if (questions.length > 0) {
				const voterTypesArray = getVoterTypesArrayFromVoterFilters(voterFilters);
				const questionTitles = questions.map(q => q.question);
				const questionsStats = await graphPost(`/topicqna/${topicId}/questionstats`, {
					questions: questionTitles,
					startDateTime: dateFrom,
					endDateTime: dateTo,
					voterTypes: voterTypesArray,
					lastVoteOnly: voterFilters.latestPerVoter,
					sources: voterFilters.sources && voterFilters.sources.map(source => source.name)
				});
				const statsPayload = questions.map(q => {
					const match = questionsStats.questions.find(e => e.question === q.question);
					return mapToQuestionStatsModel(q, match);
				});
				dispatch(loadQuestionStatsSuccess(topicId, statsPayload));
			}
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);
		}
	};
}