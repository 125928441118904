const messages = {
    account: {
        confirmCancellation: 'Are you sure you want to cancel your subsciption? On confirm, your account will remain active until the next payment due date. Afterwards, your account will automatically expire and switch to a "Free" account'
    },

    topics: {
        confirmDelete(name) {
            return `Are you sure you want to delete "${name}"?`;
        },
        confirmCancelEditing(topicBiulderPageMode) {
            const pageMode = topicBiulderPageMode === "edit" ? "editing" : "creating";
            return `Are you sure you want to cancel ${pageMode} this topic?`;
        },
        topicInChannelRefusePrivate: "This topic belongs to a channel and cannot be set as private, please contact an admin for assistance",
        noRootTopicTitle: "Root topic is missing",
        noRootTopicMessage: "There is no root topic associated with your organization." +
            " Please, contact your organization manager to edit your organization details and add a new Root Topic.",
        noRootTopicMessageManager: "There is no root topic associated with your organization. Please, edit your organization details to add a new Root Topic.",
        duplicateQuestionTitles: 'Question with the same title is not allowed',
        noAnswerText: 'Empty answer',
        noQnaRegistered: 'No Q&A registered, end of user interaction',
        noQnaVisible: 'Q&A hidden due to filtering'
    },

    entities: {
        confirmDelete(name) {
            return `Are you sure you want to delete organization "${name}"?`;
        },
        tierDowngradeForbidden: "Cannot downgrade to free tier"
    },

    common: {
        defaultConfirmTitle: 'Confirm Cancel Changes',
        defaultConfirmMessage: 'Are you sure?',
        confirmUnsavedChangesMessage: 'You have unsaved changes. Are you sure you want to close the dialog and lose all changes?',
        requestSendToSupport: 'Your request has been sent to our support center. \n We will contact you as soon as possible. \n',
        thankYou: 'Thank you!'
    },

    errors: {
        required: 'Field must not be empty',
        length: (min = null, max = null) => {
            const gt = `greater than ${min} `;
            const lt = `lower than ${max} `;

            return `Length must be ${min ? gt : ''} ${min && max ? 'and' : ''} ${max ? lt : ''}`;
        },
        wrongType: 'File of this type is not allowed!',
        fileSizeExceeded: (fileSize) => {
            return `The file exceeds the allowed file-size of ${fileSize} kb`;
        },
        onlyText: 'Field must not contain special characters',
        onlyNames: 'Field must not contain special characters',
        onlyLatinLetters: 'Field must contain only latin letters',
        onlyLatinAlphaNumeric: 'Field must contain only latin letters and numbers',
        onlyLatinAphaNumericNoSpaces: 'Field must contain only latin letters and numbers with no spaces',
        onlyNumeric: 'Field must contain only numbers',
        atLeastOneNumeric: 'Field must contain at least one number',
        email: 'Field must contain a valid email address',
        password: 'Insufficient password strength! Must be 8+ char long, with at least one capital letter, one lower case letter and one digit',
        confirmPassword: 'Passwords do not match',
        url: 'Field must contain a valid url address',
        general: 'A problem occured. Please, try again, or contact support.',
        version: 'The data you are editing has been updated. Please reload the page to get all changes!'
    },

    votesFilters: {
        lastPerVoter: {
            label: "topic_filters_count_only_latest",
            subLabel: "use only the latest vote given by a repeating voter"
        },
        anon: {
            label: "topic_filters_anonymous_voters",
            subLabel: "use votes only from unregistered voters"
        },
        facebook: {
            label: "topic_filters_facebook_voters",
            subLabel: "use votes only from facebook registered voters"
        },
        email: {
            label: "topic_filters_email_voters",
            subLabel: "use votes only from email registered voters"
        }
    },

    permissions: {
        features: {
            optionsSelector: 'Upgrade to enable',
            topicLimitReached: 'Unavailable for your plan',
            noPrivateTopics: 'Unavailable for your plan',
            noCustomTopicImage: 'Unavailable for your plan',
            noLinkShare: 'Unavailable for your plan',
            noCustomSources: 'Unavailable for your plan',
            noJumpQuestions: 'Unavailable for your plan',
            noAnswerImage: 'Unavailable for your plan',
            noLanguages: 'Unavailable for your plan',
            noPdfExport: 'Unavailable for your plan',
            noCsvExport: 'Unavailable for your plan',
            noPresentationMode: 'Unavailable for your plan',
            noAlerts: 'Unavailable for your plan',
            feedFilters: 'Unavailable for your plan',
            insufficientNumberOfUsers: 'Unavailable for your plan',
            insufficientNumberOfManagers: 'Unavailable for your plan'
        }
    }
};

export default messages;
