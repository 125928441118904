export default [
	{ id: 1, name: 'lang_en', code: 'en', icon: '🇺🇸' },
	{ id: 2, name: 'lang_bg', code: 'bg', icon: '🇧🇬' },
	{ id: 3, name: 'lang_nl', code: 'nl', icon: '🇳🇱' },
	{ id: 4, name: 'lang_ru', code: 'ru', icon: '🇷🇺' },
	{ id: 5, name: 'lang_hy', code: 'hy', icon: '🇦🇲' },
	{ id: 6, name: 'lang_fr', code: 'fr', icon: '🇫🇷' },
	{ id: 7, name: 'lang_de', code: 'de', icon: '🇩🇪' },
	{ id: 8, name: 'lang_es', code: 'es', icon: '🇪🇸' },
	{ id: 9, name: 'lang_it', code: 'it', icon: '🇮🇹' },
	{ id: 10, name: 'lang_el', code: 'el', icon: '🇪🇱' },
	{ id: 11, name: 'lang_pt', code: 'pt', icon: '🇵🇹' },
	{ id: 12, name: 'lang_hr', code: 'hr', icon: '🇭🇷' },
];