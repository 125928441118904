import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { validate } from '../../actions/models/entityModel';
import EntityDetailsForm from '../../components/entities/entityDetailsForm';
import { userHasPermissions, PERMISSIONS } from '../../helpers/permissionsHelper';
import { Save } from 'react-feather';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

export function isInvalid(isDirty, isValid) {
	return isDirty ? !isValid : true;
}

export const EntityDetailsEdit = ({ 
	entityDetails, 
	user, 
	onSubmit, 
	onCancel,
	showModal,
	hideModal,
    requestErrors,
	validateTopicName
}) => {
	const [formIsDirty, setFormIsDirty] = useState(false);
	const [formIsValid, setFormIsValid] = useState(false);

	const { t } = useTranslation();
	
	const handleSubmit = (values) => {
		onSubmit(values);
	};

	const handleCancel = () => {
		if(!formIsDirty) {
			onCancel();
		} else {
			const configCancelModal = {
				title: "Confirm Cancel Changes",
				message: "Are you sure you want to cancel the changes you made?",
				onClose: () => { 
					hideModal();
				},
				onConfirm: () => {
					onCancel();
					hideModal();
				},
			};

			showModal(CONFIRM_MODAL, configCancelModal);
		}
	};

	const isSubmitDisabled = () => {
		return isInvalid(formIsDirty, formIsValid) || !isEmpty(requestErrors);
	};

	const handleValidate = (values) => {
		return validate(values);
	};

	return (
		<Formik
			initialValues={entityDetails}
			onSubmit={handleSubmit}
			enableReinitialize={true}
			validate={handleValidate}
		>
			{({ 
				setFieldValue, 
				setFieldTouched, 
				setFieldError, 
				values, 
				errors, 
				dirty, 
				isValid,
				handleBlur
			}) => {
				setFormIsDirty(dirty);
				setFormIsValid(isValid);

				return <Form autoComplete="noautocompletepls" className='clearfix entity-details-form'>
							<EntityDetailsForm 
								values={values}
								errors={errors} 
								editMode={true}
								isUserAdmin={userHasPermissions([ PERMISSIONS.ADMIN ], user )} 
								setFieldValue={setFieldValue}
								setFieldError={setFieldError}
								setFieldTouched={setFieldTouched}
								handleBlur={handleBlur}
								requestErrors={requestErrors}
								entityActiveTierId={entityDetails.tierId}
								validateTopicName={validateTopicName}
							/>

							<div className="btn-toolbar pull-right">
								<button type="submit" disabled={isSubmitDisabled()} className="btn uitest-btn-submit btn-success"><Save size={16} /> {t('modal_general_save')}</button>
								<button type="button" className="btn btn-outline uitest-btn-cancel" onClick={handleCancel}>{t('modal_general_cancel')}</button>
							</div>
						</Form>;                
			}}
		</Formik>
	);
};

EntityDetailsEdit.defaultProps = {
	validateTopicName: () => {}
};

EntityDetailsEdit.propTypes = {
	entityDetails: PropTypes.shape(),
	user: PropTypes.shape({
		permissions: PropTypes.arrayOf(PropTypes.string).isRequired
	}),
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	showModal: PropTypes.func.isRequired,
	hideModal: PropTypes.func.isRequired,
	hasSpecialPermissions: PropTypes.bool,
	requestErrors: PropTypes.shape(),
	validateTopicName: PropTypes.func.isRequired
};

export default EntityDetailsEdit;