import React from 'react';
import { Link } from 'react-router';
import Card from './card';
import { PlusCircle, Star, Grid } from 'react-feather';
import styles from './css/introGuide.module.css';

const introGuide = () => {
	return (
		<Card title="What's next" maxWidth="1152px" centered={true}>
			<div className="row-fluid clearfix">
				<div className="col-md-4">
					<h4>Create a topic</h4>
					<p className="text-label">and share a link to your selected voters to start voting on kazva.bg</p>
					
					<div className={styles.thumb}>
						<img src="/images/createTopicThumb.png" alt="Topic builder"/>
					</div>

					<p>Create yor own topic in your prefered language.</p>
					<p>Add a questionnaire to understand your voter’s ratings even better and setup your shareable topic media kit to start approaching voters</p>

					<p className="text-center"><br /><Link to="/"><button className="btn btn-outline btn-primary"><PlusCircle size={14} />Create your first topic!</button></Link></p>

					<p className="text-bold">
						<br /><Star size={16} /> Upgrade your account plan to create unlimited number of topics!
						<br /><br />See <Link to="/plans">account types</Link> for more information and payment options
					</p>
				</div>

				<div className="col-md-4">
					<h4>Your topics Overview</h4>
					<p className="text-label">Get an overview of how people feel about your topics at any given time</p>
					
					<div className={styles.thumb}>
						<img src="/images/overviewThumb.png" alt="Topics Overview"/>
					</div>

					<p>Get an overview of all your topics with overall ratings and voting trends. </p>
					<p>Select from daily, weekly and monthly time periods to get an overview of how do people rate your topics</p>

					<p className="text-center"><br /><Link to="/"><button className="btn btn-outline btn-primary"><Grid size={14} />Get started with Dashboard</button></Link></p>

					<p className="text-bold">
						<br /><Star size={16} /> Upgrade your account plan to view voting data for any time period!
						<br /><br />See <Link to="/plans">account types</Link> for more information and payment options
					</p>
				</div>

				<div className="col-md-4">
					<h4>Voting Analysis</h4>
					<p className="text-label">powerful topic voting and questionnaire analysis</p>
					
					<div className={styles.thumb}>
						<img src="/images/topicDetailsThumb.png" alt="Topic Details"/>
					</div>

					<p>Get a full insight analysis of all votes for your topic for selected time ranges.</p>
					<p>See how your voters feel about your topic in time.</p>
					<p>Get a sentiment, demographic and location understanding of your voters</p>
					<p>Understand why your voters gave their ratings via questionnaire analysis</p>
					<p>Follow how voters vote with a detailed votes feed</p>

					<p className="text-bold">
						<br /><Star size={16} /> Upgrade your account plan to export topic analysis and votes to use in your own presentations or own analysis tools
						<br /><br />See <Link to="/plans">account types</Link> for more information and payment options
					</p>
				</div>
			</div>
		</Card>
	);
};

introGuide.propTypes = {
	
};

export default introGuide;