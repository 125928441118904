import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isEqual, isEmpty } from 'lodash';
import { buildTreeStats, buildFilteredTopicsTreeByName, sortTopicsTree } from '../../helpers/topicTreeHelper';
import { calculateLifetimeStats, calculateMacroStats } from '../../helpers/voteDataHelper';
import TopicsTree from '../../components/topics/topicsTree';
import LifetimeStatBox from '../../components/common/lifetimeStatBox';

import * as topicActions from '../../actions/topicActions';
import * as topicsTreeActions from '../../actions/topicsTreeActions';
import * as entitiesActions from '../../actions/entitiesActions';
import * as modalActions from '../../actions/modalActions';
import { DATE_FILTER_OPTIONS, getDateRangeByPeriodIndex } from '../../helpers/datePickerService';

import '../../components/topics/css/topics.css';
import './css/topicOverviewPage.css';
import { PlusCircle, Check } from 'react-feather';
import { PERMISSIONS } from '../../helpers/permissionsHelper';
import PermissionsContext from '../contexts/permissionsContext';
import { isOnMobile } from '../../helpers/utils';
import TopicsTreeFilterSortBar from '../../components/topics/topicsTreeFilterSortBar';
import OverallMacroStats from './overallMacroStats';
import FeaturesContext from "../contexts/featuresContext";
import { ENTITY_FEATURES } from "hollerlive-shared/constants";
import { COMPARATORS, entityHasFeatures } from "../../helpers/featuresHelper";
import NoPermissionLabel from '../../components/common/noPermissionLabel';
import Messages from '../../helpers/messages';
import i18n from '../../i18n';

const DEFAULT_INTERVAL_SELECTION = DATE_FILTER_OPTIONS.indexOf('filter_date_today');
const CUSTOM_INTERVAL_SELECTION = DATE_FILTER_OPTIONS.indexOf('filter_date_select_range');

const SORT_BY_VOLUME = "voteCount";
const SORT_BY_RATING = "avgVote";

const TOPICS_TREE_VIEW = 'TOPICS_TREE_VIEW';
const TOPICS_OVERVIEW_STATS_VIEW = 'TOPICS_OVERVIEW_STATS_VIEW';

export class TopicOverviewPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            t: i18n.t.bind(i18n),
            dateIntervalActive: true,
            intervalIndex: DEFAULT_INTERVAL_SELECTION,
            searchText: '',
            sorting: false,
            sortBy: 'volume',
            sortDirection: 'DESC',
            topicsTreeRoot: {},
            // TODO: unite topicsTreeLeafs and filteredTreeLeafs as projection of the state:
            topicsTreeLeafs: [],
            filteredTreeLeafs: [],
            activeMobileView: TOPICS_TREE_VIEW,
            lifetimeStats: { firstVoteTime: null, lastVoteTime: null, allVotesCount: 0, allAnswersCount: 0 },
            overallMacroStats: { totalVotesCount: 0, totalAnswesCount: 0,
                highestRated: null, lowestRated: null, mostVoted: null, mostAnswered: null }
        };
        this.filterTopicsTreeByName = this.filterTopicsTreeByName.bind(this);
        this.changeSortOptionVolume = this.changeSortOptionVolume.bind(this);
        this.changeSortOptionRating = this.changeSortOptionRating.bind(this);
        this.intervalChanged = this.intervalChanged.bind(this);
        this.dateRangeChanged = this.dateRangeChanged.bind(this);
        this.handleApplyLifetimePeriodClick = this.handleApplyLifetimePeriodClick.bind(this);
        this.toggleMobileView = this.toggleMobileView.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.entity)) {
            if (!this.props.entity.topicsLoaded) {
                this.props.actions.loadTopicsForEntity(this.props.entity.id);
            } else if (isEmpty(this.props.treeStructure)) {
                if (!isEmpty(this.props.parentTopic)) {
                    this.props.actions.buildTopicsTree(this.props.parentTopic);
                }
            } else if (!isEmpty(this.props.treeStructure)) {
                const dateFrom =  this._getDateFromOrDefault();
                const dateTo = this._getDateToOrDefault();
                const intervalSelection = this._getIntervalIdexOrDefault();
                this.loadTopicsTreeData(dateFrom, dateTo, intervalSelection);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEmpty(this.props.entity)) {
            const topicsChanged = !prevProps.entity.topicsLoaded && this.props.entity.topicsLoaded;
            if (!isEqual(prevProps.entity, this.props.entity) && !this.props.entity.topicsLoaded) { // switch entity
                this.props.actions.loadTopicsForEntity(this.props.entity.id);
            }
            if (topicsChanged && !isEmpty(this.props.parentTopic)) {
                this.props.actions.buildTopicsTree(this.props.parentTopic);
            }
            const treeChanged = prevProps.isBuilding && !this.props.isBuilding;
            if (!topicsChanged && !isEmpty(this.props.treeStructure) && treeChanged) {
                const dateFrom = this._getDateFromOrDefault();
                const dateTo = this._getDateToOrDefault();
                const intervalSelection = this._getIntervalIdexOrDefault();
                this.loadTopicsTreeData(dateFrom, dateTo, intervalSelection);
            } else {
                const statsChanged = !prevProps.isLoadedStats && this.props.isLoadedStats;
                if (statsChanged && !isEmpty(this.props.topicsStats)) {
                    this._createTreeWithStats(this.props.topicsStats);
                }
            }
        }
        if (!isEqual(prevState.topicsTreeLeafs, this.state.topicsTreeLeafs)) {
            this._filterAndSortTopicTree(this.state.searchText);
            if (this.state.sorting === false) {
                if (this.state.sortBy === 'volume') {
                    this.changeSortOptionVolume(this.state.sortDirection);
                } else {
                    this.changeSortOptionRating(this.state.sortDirection);
                }
            } else {
                this.setState({ sorting: false });
            }
        }
    }
    
    _createTreeWithStats(topicsStats) {
        const { topics, treeStructure } = this.props;
        const { sortBy, sortDirection } = this.state;
        const treeWithStats = buildTreeStats(treeStructure, topicsStats);
        const newSortOptionsState =
            sortBy === 'volume'
                ? this._getSortOptionVolumeState(sortDirection, [treeWithStats])
                : this._getSortOptionRatingState(sortDirection, [treeWithStats]);
        const lifetimeStats = calculateLifetimeStats(topics); // those come along with topicsStats
        const overallMacroStats = calculateMacroStats(topicsStats);
        this.setState({
            topicsTreeRoot: Object.assign({}, treeWithStats, { children: null }),
            overallMacroStats: overallMacroStats,
            lifetimeStats: lifetimeStats,
            ...newSortOptionsState
        });
    }
    
    toggleMobileView() {
        const activeMobileView =
            this.state.activeMobileView === TOPICS_TREE_VIEW ? TOPICS_OVERVIEW_STATS_VIEW : TOPICS_TREE_VIEW;
        this.setState({ activeMobileView });
    }

    filterTopicsTreeByName(name) {
        this.setState({ searchText: name });
        this._filterAndSortTopicTree(name);
    }

    _filterAndSortTopicTree(searchTerm) { // TODO: refactor this to not carry two things at a time
        if (searchTerm !== '') {
            const filteredTopicsTree = buildFilteredTopicsTreeByName(this.state.topicsTreeLeafs, searchTerm);
            this.setState({
                filteredTreeLeafs: sortTopicsTree(this.state.sortDirection, filteredTopicsTree, this.state.sortBy)
            });
        } else {
            this.setState({ filteredTreeLeafs: [] });
        }
    }

    changeSortOptionRating(direction) {
        this.setState(this._getSortOptionRatingState(direction, this.state.topicsTreeLeafs));
    }
    _getSortOptionRatingState(direction, treeLeafs) {
        return {
            sortBy: 'rating',
            sortDirection: direction,
            topicsTreeLeafs: sortTopicsTree(direction, treeLeafs, SORT_BY_RATING),
            sorting: true
        };
    }
    changeSortOptionVolume(direction) {
        this.setState(this._getSortOptionVolumeState(direction, this.state.topicsTreeLeafs));
    }
    _getSortOptionVolumeState(direction, treeLeafs) {
        return {
            sortBy: 'volume',
            sortDirection: direction,
            topicsTreeLeafs: sortTopicsTree(direction, treeLeafs, SORT_BY_VOLUME),
            sorting: true
        };
    }

    intervalChanged(intervalIndex) {
        this.setState({ dateIntervalActive: true, intervalIndex });
        const { dateFrom, dateTo } = getDateRangeByPeriodIndex(intervalIndex);
        this.loadTopicsTreeData(dateFrom, dateTo, intervalIndex);
    }

    dateRangeChanged(dateFrom, dateTo) {
        this.setState({ dateIntervalActive: false });
        this.loadTopicsTreeData(dateFrom, dateTo, CUSTOM_INTERVAL_SELECTION);
    }

    loadTopicsTreeData(dateFrom, dateTo, intervalSelectionIndex) {
        const intervalIndex = intervalSelectionIndex !== null ? intervalSelectionIndex : DEFAULT_INTERVAL_SELECTION;
        this.props.actions.setTopicsTreeFilterPeriod(dateFrom, dateTo, intervalIndex);
        this.props.actions.loadTopicsTreeStats(dateFrom, dateTo);
    }

    _getDateToOrDefault() {
        // in this case we want to always get the current time
        return getDateRangeByPeriodIndex(DEFAULT_INTERVAL_SELECTION).dateTo;
    }
    _getDateFromOrDefault() {
        return this.props.periodFilter.dateFrom || getDateRangeByPeriodIndex(DEFAULT_INTERVAL_SELECTION).dateFrom;
    }
    _getIntervalIdexOrDefault() {
        return this.props.periodFilter.intervalIndex || DEFAULT_INTERVAL_SELECTION;
    }
    
    getChildContext() {
        return {
            hideModal: this.props.hideModal,
            showModal: this.props.showModal
        };
    }
    
    handleApplyLifetimePeriodClick() {
        const { firstVoteTime, lastVoteTime } = this.state.lifetimeStats;
        this.dateRangeChanged(firstVoteTime, lastVoteTime);
    }
    
    render() {
        const { entity, periodFilter, isLoadingStats, isLoadedStats } = this.props;
		const { t, topicsTreeRoot, topicsTreeLeafs, filteredTreeLeafs, lifetimeStats, overallMacroStats } = this.state;
        const { firstVoteTime, lastVoteTime, allVotesCount, allAnswersCount } = lifetimeStats;
        const {
            totalAnswesCount,
            totalVotesCount,
            highestRated,
            lowestRated,
            mostVoted,
            mostAnswered
        } = overallMacroStats;
        
		const topicsTreeToDisplay = filteredTreeLeafs.length !== 0 ? filteredTreeLeafs : topicsTreeLeafs;

        const currentDateRange = {
			dateFrom: periodFilter.dateFrom || getDateRangeByPeriodIndex(DEFAULT_INTERVAL_SELECTION).dateFrom,
			dateTo: periodFilter.dateTo || getDateRangeByPeriodIndex(DEFAULT_INTERVAL_SELECTION).dateTo,
			intervalIndex: periodFilter.intervalIndex || DEFAULT_INTERVAL_SELECTION
        };

        if (periodFilter.intervalIndex !== undefined && periodFilter.intervalIndex !== null) {
            currentDateRange.intervalIndex = Number(periodFilter.intervalIndex);
        } else {
            currentDateRange.intervalIndex = DEFAULT_INTERVAL_SELECTION;
        }
        
        const overallRatingCardTitleInterval =
            currentDateRange.intervalIndex !== CUSTOM_INTERVAL_SELECTION
                ? t(DATE_FILTER_OPTIONS[currentDateRange.intervalIndex])
                : t('filter_date_selected_period');
        
        const noRightsCreateButton = <button className="btn-float btn-transparent btn-float-second">
            <NoPermissionLabel message={Messages.permissions.features.topicLimitReached} />
            <div className="btn btn-primary"><PlusCircle size={32}/></div>
        </button>;
        
        return(
            <React.Fragment>
                <div className='topics-overview-header stats-header'>
                    <h2 className='page-heading'>
                        {entity.name}                        
                    </h2>
                    {!isEmpty(topicsTreeRoot)&&
                        <div className={'lifetimestats-container hide-on-mobile'}>
                            <LifetimeStatBox 
                                firstVoteTime={firstVoteTime} 
                                lastVoteTime={lastVoteTime}
                                allVotesCount={allVotesCount} 
                                allAnswersCount={allAnswersCount}
                            />
                            
                            {(firstVoteTime && lastVoteTime) && <button
                                onClick={this.handleApplyLifetimePeriodClick}
                                className='btn btn-xs btn-outline btn-default'
                            >
                                <Check size={16} />
                                {t('topic_overview_apply_period')}
                            </button>}
                        </div>
                    }
                </div>

                <TopicsTreeFilterSortBar
                    dateFrom={currentDateRange.dateFrom}
                    dateTo={currentDateRange.dateTo}
                    selectedPeriodIndex={currentDateRange.intervalIndex}
                    actionSortByVolume={this.changeSortOptionVolume}
                    actionSortByRating={this.changeSortOptionRating}
                    onIntervalChange={this.intervalChanged}
                    onDateRangeChange={this.dateRangeChanged}
                    enableCustomDateRange={entityHasFeatures([ENTITY_FEATURES.HAS_CUSTOM_DATE_RANGE], this.props.entity)}
                />
                
                <div className="topics-overview content-with-stats">
                    <div className={`stats-container ${isOnMobile() && this.state.activeMobileView === TOPICS_OVERVIEW_STATS_VIEW ? '' : 'hide-on-mobile'}`} id="statsContainer">
                        <OverallMacroStats
                            timeIntervalLabel={overallRatingCardTitleInterval}
                            totalVotesCount={totalVotesCount}
                            totalAnswersCount={totalAnswesCount}
                            highestRatedTopic={highestRated || { stats : {} }}
                            lowestRatedTopic={lowestRated || { stats : {} }}
                            mostVotedTopic={mostVoted || { stats : {} }}
                            mostAnsweredTopic={mostAnswered || { stats : {} }}
                            isLoadedStats={isLoadedStats}
                            isLoadingStats={isLoadingStats}
                        />
                    </div>

                    <div className={`overview content-container ${isOnMobile() && this.state.activeMobileView === TOPICS_TREE_VIEW ? '' : 'hide-on-mobile'}`}>
                        <div className="topics-tree-container">
                            <TopicsTree
                                topicsTree={topicsTreeToDisplay || []}
                                actionFilterByName={this.filterTopicsTreeByName}
                            />
                        </div>
                    </div>

                    {!isOnMobile() && 
                        <PermissionsContext 
                            hideIfNoPermission={true} 
                            requiredPermissions={[PERMISSIONS.CREATE_TOPICS]}
                            className='btn-float btn-float-second'
                            renderOtherwise={noRightsCreateButton}
                        >
                            <FeaturesContext 
                                requiredFeatures={[{name: ENTITY_FEATURES.NUMBER_OF_TOPICS, compare: COMPARATORS.GREATER_THAN_OR_ZERO, value: this.props.topics.length}]}
                                renderOtherwise={noRightsCreateButton}
                            >
                                <Link to="topics/create" className="btn-float btn-float-second" onlyActiveOnIndex={false}>
                                    <label>{t('topic_overview_create_new')}</label>
                                    <div className="btn btn-primary"><PlusCircle size={32}/></div>
                                </Link>
                            </FeaturesContext>
                        </PermissionsContext>
                    }

                    <div className="navbar-mobile overview-stats-switch clearfix hide-on-desktop">
                        <div className="btn-group-footer">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.toggleMobileView}> {t('topic_overview_mobile_topics')}</button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.toggleMobileView}> {t('topic_overview_mobile_statistics')}</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

TopicOverviewPage.propTypes = {
    entity: PropTypes.object,
    user: PropTypes.object,
    topics: PropTypes.array,
    parentTopic: PropTypes.object,
    treeStructure: PropTypes.object,
    topicsStats: PropTypes.array,
    isLoadingStats: PropTypes.bool,
    isLoadedStats: PropTypes.bool,
    periodFilter: PropTypes.object,
    isBuilding: PropTypes.bool,
    showModal: PropTypes.func,
    hideModal: PropTypes.func,
    actions: PropTypes.object.isRequired
};

// TODO: implement with the new Context API
TopicOverviewPage.childContextTypes = {
    showModal: PropTypes.func,
    hideModal: PropTypes.func
};

function mapStateToProps(state) {
    const entity = state.entities && state.entities.length > 0 ? state.entities[0] : {};
    const parentTopic = state.topics.find(topic => topic.isParent) || {};
    const { treeStructure, topicsStats, isLoadingStats, isLoadedStats, periodFilter, isBuilding } = state.topicsTree;
    return {
        entity,
        user: state.auth.user,
        topics: state.topics,
        parentTopic,
        treeStructure,
        topicsStats,
        isLoadingStats,
        isLoadedStats,
        periodFilter,
        isBuilding
    };
}

function mapDispatchToProps(dispatch) {
    const { showModal, hideModal } = modalActions;
    return {
        actions: bindActionCreators(Object.assign({}, topicActions, topicsTreeActions, entitiesActions), dispatch),
        ...bindActionCreators(Object.assign({}, { hideModal, showModal }), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicOverviewPage);
