/* eslint-disable no-cond-assign, no-sequences, no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { calculateTrendSlope } from '../../../helpers/utils';
import './css/miniChart.css';

const POSITIVE_COLOR_START = "#bfd0c2";
const POSITIVE_COLOR_END = "#46d061";

const NEGATIVE_COLOR_START = "#dcc5c6";
const NEGATIVE_COLOR_END = "#ef5e64";

class MiniChart extends React.Component {
	constructor(props) {
		super(props);
		
		this.graphsDictionary = {};
		this.sbGraphPadding = 2;
		this.xyFormattedData = [];

		this.state = {
			isTrendPositive: null
		};
	}
	
	componentDidMount (){
		if (this.props.dataSet.length > 0) {
			this.xyFormattedData = this.formatInputData(this.props.dataSet);
			const isTrendPositive = this._calculatePositiveTrend(this.xyFormattedData);
			this.setState({ isTrendPositive });
		}
	}

	componentDidUpdate (prevProps, prevState){
		if (this.props.dataSet.length > 0) {
			this.xyFormattedData = this.formatInputData(this.props.dataSet);		
			const isTrendPositive = this._calculatePositiveTrend(this.xyFormattedData);		
			
			if (prevState.isTrendPositive !== isTrendPositive) {
				this.setState({ isTrendPositive });
			}
			
			this.drawGraphs();
		}
	}

	_calculatePositiveTrend(dataSet) {
		const trend = calculateTrendSlope(dataSet.map(p => p.y));

		return trend >= 0;
	}

	drawGraphs() {
		if (this.xyFormattedData.length === 0) {
			return;
		}

		const canvasContainer = this.canvasElement;
		// let r = 0;

		if (this.createCanvas()) {
			let o = true;

			const lineProps = {
				lineWidth: this.props.lineWidth || 2,
				colourStart: this.state.isTrendPositive === null ? "#58b3cb" : this.state.isTrendPositive ? POSITIVE_COLOR_START : NEGATIVE_COLOR_START,
				colourEnd: this.state.isTrendPositive === null ? "#58b3cb" : this.state.isTrendPositive ? POSITIVE_COLOR_END : NEGATIVE_COLOR_END,
				colourDotStart: this.state.isTrendPositive === null ? "#58b3cb" : this.state.isTrendPositive ? POSITIVE_COLOR_START : NEGATIVE_COLOR_START,
				colourDotEnd: this.state.isTrendPositive === null ? "#58b3cb" : this.state.isTrendPositive ? POSITIVE_COLOR_END : NEGATIVE_COLOR_END,
				colourFill: "#FFF",
				colourFillStart: "#ff8800",
				colourFillEnd: "#ff8800",
				fill: false,
				fillGradient: false,
				showDots: false,
				lineCap: "round"
			};

			if (o) {
				const ctx = canvasContainer.getContext("2d");
				
				ctx.clearRect(0, 0, canvasContainer.width, canvasContainer.height);

				ctx.lineWidth = lineProps.lineWidth;
				const gradient = ctx.createLinearGradient(0, 0, canvasContainer.width, canvasContainer.height);

				if (gradient.addColorStop(0, lineProps.colourStart), gradient.addColorStop(1, lineProps.colourEnd), ctx.strokeStyle = gradient, lineProps.fill) {
					if (lineProps.fillGradient) {
						const linearGradient = ctx.createLinearGradient(0, 0, canvasContainer.width, canvasContainer.height);
						linearGradient.addColorStop(0, lineProps.colourFillStart), linearGradient.addColorStop(1, lineProps.colourFillEnd), ctx.fillStyle = linearGradient;
					} else {
						ctx.fillStyle = lineProps.colourFill;
					}
				}

				ctx.beginPath();
				const chartXYData = this.xyFormattedData,
					p = {
						totWidth: canvasContainer.width,
						totHeight: canvasContainer.height,
						padding: this.sbGraphPadding,
						elementCount: chartXYData[chartXYData.length - 1].x,
						maxY: this.getMaxY(chartXYData)
					};

				ctx.moveTo(this.calcHSpacing(p, chartXYData[0].x), this.calcVSpacing(p, chartXYData[0].y));

				for (let d = 1; d < chartXYData.length - 1; d++) {
					const x = this.calcHSpacing(p, chartXYData[d].x),
						y = this.calcVSpacing(p, chartXYData[d].y),
						x1 = this.calcHSpacing(p, chartXYData[d + 1].x),
						y1 = this.calcVSpacing(p, chartXYData[d + 1].y),
						xc = (x + x1) / 2,
						yc = (y + y1) / 2;

					ctx.quadraticCurveTo(x, y, xc, yc);
				}

				lineProps.fill && (ctx.lineTo(this.calcHSpacing(p, chartXYData.length),
					this.calcVSpacing(p, 0)),
					ctx.lineTo(this.calcHSpacing(p, chartXYData[0].x),
						this.calcVSpacing(p, chartXYData[0].y)),
					ctx.closePath()),
					ctx.stroke(),
					lineProps.fill && ctx.fill(),
					lineProps.showDots && (
						lineProps.fill && ctx.beginPath(),
						ctx.fillStyle = lineProps.colourDotStart,
						ctx.beginPath(),
						ctx.arc(this.calcHSpacing(p, chartXYData[0].x), this.calcVSpacing(p, chartXYData[0].y), 4, 0, 2 * Math.PI, true),
						ctx.closePath(),
						ctx.fill(),
						ctx.fillStyle = lineProps.colourDotEnd,
						ctx.beginPath(),
						ctx.arc(this.calcHSpacing(p, chartXYData[chartXYData.length - 1].x), this.calcVSpacing(p, chartXYData[chartXYData.length - 1].y), 4, 0, 2 * Math.PI, true),
						ctx.closePath(),
						ctx.fill()
					);
			} else {
				const ctx = canvasContainer.getContext("2d"),
					p = {
						totWidth: canvasContainer.width,
						totHeight: canvasContainer.height,
						padding: this.sbGraphPadding,
						elementCount: this.xyFormattedData,
						maxY: this.getMaxY(this.xyFormattedData)
					};

				ctx.clearRect(0, 0, canvasContainer.width, canvasContainer.height);
				
				ctx.beginPath(),
					ctx.fillStyle = lineProps.colourDotStart,
					ctx.beginPath(),
					ctx.arc(this.halfWidth(p), this.halfHeight(p), 10, 0, 2 * Math.PI, true),
					ctx.closePath(),
					ctx.fill();
			}
		}
	}

	halfWidth(e) {
		return e.totWidth / 2;
	}
	
	halfHeight(e) {
		return e.totHeight / 2;
	}
	
	calcHSpacing(e, canvasContainer) {
		return (e.totWidth - 2 * e.padding) / e.elementCount * canvasContainer + e.padding;
	}
	
	calcVSpacing(e, canvasContainer) {
		return e.totHeight - (e.totHeight - 2 * e.padding) / e.maxY * canvasContainer - e.padding;
	}
	
	getMaxY(e) {
		for (var canvasContainer = 0, graphDomIdName = 0; graphDomIdName < e.length; graphDomIdName++) {
			var r = parseInt(e[graphDomIdName].y, 10);
			r > canvasContainer && (canvasContainer = r);
		}
		return canvasContainer;
	}

	createCanvas() {
		var e = document.createElement("canvas");
		return !(!e.getContext || !e.getContext("2d"));
	}

	formatInputData(dataSet) {
		return dataSet.map((d, i) => {
			return Object.assign({}, { x: i, y: d });
		});
	}

	render() {
		return (
			<div className={`sbGraph ${this.props.className}`}>
				<canvas className='sbGraphCanvas'
					width={this.props.width || 150} 
					height={this.props.height || 50} 
					ref={(canvasElement) => this.canvasElement = canvasElement}
				/>
			</div>
		);
	}
}

MiniChart.propTypes = {
	dataSet: PropTypes.array.isRequired,
	className: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	lineWidth: PropTypes.number
};

export default MiniChart;