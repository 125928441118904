import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { mapToFormModel, validateUser } from '../../actions/models/userModel';
import EditUserForm from '../users/editUserForm';
import { Save } from 'react-feather';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import { useTranslation } from 'react-i18next';

const EntityUserCreate = ({
	onSubmit,
	onCancel,
	showModal,
	hideModal,
	requestErrors,
	resetUserRequestErrors,
	hasSpecialPermissions,
	entity,
	canSetAsManager
}) => {
	const [formIsDirty, setFormIsDirty] = useState(false);
	const [formIsValid, setFormIsValid] = useState(false);

	const { t } = useTranslation();

	const userCreateModel = mapToFormModel({
		active: true,
		entity
	});

	const initialValues = Object.assign({}, userCreateModel);
	
	const handleSubmit = (value) => {
		onSubmit(value);
	};

	const handleCancel = () => {
		if(!formIsDirty) {
			onCancel();
		} else {
			const configCancelModal = {
				title: "Confirm Cancel Changes",
				message: "Are you sure you want to cancel the changes you made?",
				onClose: () => { 
					hideModal();
				},
				onConfirm: () => {
					onCancel();
					hideModal();
				},
			};

			showModal(CONFIRM_MODAL, configCancelModal);
		}
	};

	const validate = (values) => {
		const validationOptions = { shouldValidateEmail: true, shouldValidatePassword: false };
		return validateUser(values, validationOptions);
	};

	const isSubmitDisabled = () => {
		return formIsDirty ? !formIsValid : true;
	};

	return (
		<Formik 
				initialValues={initialValues} 
				onSubmit={handleSubmit}
				validate={validate}
				enableReinitialize={false}
			>
				{({ values, touched, errors, isValid, dirty, setFieldValue, setValues, setTouched, setSubmitting, isSubmitting }) =>  {
					setFormIsDirty(dirty);
					setFormIsValid(isValid);

					return (
						<Form className="text-left clearfix">
							<EditUserForm 
								values={values}
								touched={touched}
								errors={errors} 
								requestErrors={requestErrors} 
								resetRequestErrors={resetUserRequestErrors}
								setFieldValue={setFieldValue} 
								setValues={setValues}
								setTouched={setTouched}
								emailReadOnly={false}
								hasSpecialPermissions={hasSpecialPermissions}
								isCreateMode={true}
								activeToggleDisabled={entity ? (!entity.active) : false}
								resetSubmitting={() => setSubmitting(false)}
								hideActiveToggle={true}
								hidePhone={true}
								hideTimeZone={true}
								hideEntityName={true}
								hideIndustry={true}
								hideEntityChange={true}
								hideLocale={true}
								canSetAsManager={canSetAsManager}
							/>

							<div className="btn-toolbar pull-right">
								<button type="submit" disabled={isSubmitDisabled()} className="btn btn-success"><Save size={16} /> Create &amp; Assign</button>
								<button type="button" className="btn btn-outline" onClick={handleCancel}>{t('modal_general_cancel')}</button>
							</div>
						</Form>
					);
				}}
			</Formik>
	);
};

EntityUserCreate.propTypes = {
	entity: PropTypes.shape({
		name: PropTypes.string,
		id: PropTypes.number, 
		features: PropTypes.object
	}),
	hasSpecialPermissions: PropTypes.bool,
	requestErrors: PropTypes.object,
	errors: PropTypes.object,

	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	showModal: PropTypes.func.isRequired,
	hideModal: PropTypes.func.isRequired,
	resetUserRequestErrors: PropTypes.func.isRequired,
	
    /** 
     * @prop {bool} canSetAsManager if false, a "not available for your plan" will be displayed and 
     * "organization manager" role toggle will be disabled
     * */
    canSetAsManager: PropTypes.bool
};

export default EntityUserCreate;