import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CalendarCell from './calendarCell';

const Calendar = ({ onRangeSelected, setRange, indexEnd, indexStart, date }) => {
    const [animationDirection, setAnimationDirection] = useState('');
    const [newIndexStart, setNewIndexStart] = useState(0);
    const [newIndexEnd, setNewIndexEnd] = useState(0);
    const [currentSelectedDate, setCurrentSelectedDate] = useState(date);
    const calendarDom = useRef(null);
    const today = new Date();

    useEffect(() => {
        if (date !== currentSelectedDate) {
            setCurrentSelectedDate(date);
            const newAnimationDirection = (date - currentSelectedDate.date > 0) ? "forward" : "backward";
            setAnimationDirection(newAnimationDirection);
            calendarDom.current.classList.add(`animate--${animationDirection}`);
        }
    }, [date]);

    const mouseOver = (timestamp) => {
        if (newIndexStart > 0) {
            if (timestamp > newIndexStart && timestamp) { 
                setNewIndexEnd(timestamp);
                setRange(newIndexStart, timestamp);
            }
        }
    };

    const handleClick = (timestamp) => {
        if (newIndexStart === 0) {
            setNewIndexStart(timestamp);
            setRange(timestamp, timestamp);
        } else {
            const endDate = new Date(timestamp);
            endDate.setHours(23, 59, 59, 0);

            const endIndex = endDate.getTime();
            
            setRange(newIndexStart, endIndex);
            onRangeSelected(newIndexStart, endIndex);
        }
    }; 

    const getDayElement = (date, key, month) => {
        let time = date.getTime();
        
        const selectedStart = time === newIndexStart && newIndexEnd > newIndexStart;
        const selectedEnd = time === newIndexEnd && time > newIndexStart;
        const selected = time > newIndexStart && time < newIndexEnd;
        const outOfRange = date > today.setHours(0);
        const prevMonth = date.getMonth() !== month;

        return <CalendarCell 
                    key={key}
                    date={date.getDate()} 
                    isSelected={selected} 
                    isSelectedStart={selectedStart} 
                    isSelectedEnd={selectedEnd}
                    isOutOfRange={outOfRange} 
                    isPrevMonth={prevMonth}
                    onHover={() => mouseOver(time)}
                    onClick={() => handleClick(time)}
                />;
    };

    let dateObj = new Date(date);
    let month = dateObj.getMonth();

    dateObj.setDate(1);

    if (animationDirection === "forward"){
        dateObj.setMonth(dateObj.getMonth() - 1);
    }

    let firstDay = dateObj.getDay();
    if (firstDay !== 1) {
        (firstDay === 0) ? dateObj.setDate(dateObj.getDate() - 6) : dateObj.setDate(dateObj.getDate() - (firstDay - 1));
    }
    
    dateObj.setDate(dateObj.getDate() - 1);

    return (
        <div className="calendar__wrap">
            <table className="calendar__table" ref={calendarDom} key={dateObj} cellSpacing="0">
                <tbody>
                {Array(12).fill(0).map((i, key)=> {
                    return <tr key={key}>
                        {Array(7).fill(0).map((_i, _key)=> {
                            dateObj.setDate(dateObj.getDate() + 1);
                            return getDayElement(dateObj, _key, month);
                        })}
                    </tr>;
                })}
                </tbody>
            </table>
        </div>
    );
};

Calendar.propTypes = {
    onRangeSelected: PropTypes.func.isRequired,
    setRange: PropTypes.func.isRequired,
    indexStart: PropTypes.number.isRequired, // selection start in ms
    indexEnd: PropTypes.number.isRequired,  // selection end in ms
    date: PropTypes.number.isRequired, // date to center on
};

Calendar.defaultProps = {
    date: Date.now()
};

export default Calendar;