import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '../../../../common/card';
import { Square, Minus } from 'react-feather';
import './css/feedMetaInfoBar.css';
import { useTranslation } from 'react-i18next';

const FeedMetaInfoBar = ({ total, loaded, onCollapsedAllCick, canCollapseAll, children }) => {
	const [isCollapsedAll, setIsCollapsedAll] = useState(false);

	const { t } = useTranslation();

	const handleCollapseAllClick = () => {
		onCollapsedAllCick(!isCollapsedAll);
		setIsCollapsedAll(!isCollapsedAll);
	};

	const getCollapseExpandAllButtonText = () => {
		if (isCollapsedAll === true) {
			return <div className='uitest-btngroup-qna'><Square size={14}/> <span>{t('topic_vote_feed_meta_info_expand_all')}</span></div>;
		}
		else {
			return <div className='uitest-btngroup-qna'><Minus size={14}/> <span>{t('topic_vote_feed_meta_info_collapse_all')}</span></div>;
		}
	};

	return (
		<Card inverse className="feed-options-bar clearfix">
			<label className="text-label text-light pull-left">{t('topic_vote_feed_meta_info_loaded')} <strong>{loaded}</strong> {t('topic_vote_feed_meta_info_out_of')} <strong>{total}</strong> {t('topic_vote_feed_meta_info_total')}</label>
			{children}
			{canCollapseAll && <div className="btngroup pull-right clearfix">
				<button type="button" className="btn btn-xs transparent uitest-expand-collapse-btn" onClick={handleCollapseAllClick}>{getCollapseExpandAllButtonText()}</button>
			</div>}
		</Card>
	);
};

FeedMetaInfoBar.propTypes = {
	total: PropTypes.number,

	loaded: PropTypes.number,

	onCollapsedAllCick: function(props, propName, componentName){
		if (props.canCollapseAll) {
			if (typeof(props[propName]) != 'function') {
				return new Error(
				  'Invalid prop `' + propName + '` supplied to' +
				  ' `' + componentName + '`. Validation failed.'
				);
			  }
		}
	},

	canCollapseAll: PropTypes.bool
};

FeedMetaInfoBar.defaultProps = {
	canCollapseAll: true
};

export default FeedMetaInfoBar;