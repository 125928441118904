import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Minus, Square } from 'react-feather';
import { isOnMobile } from '../../../helpers/utils';
import OptionsSelection from '../../common/form/optionsSelector';
import Switch from '../../common/form/switch';
import QuestionDetailsItem from './questionDetailsItem';
import memoizeOne from 'memoize-one';
import i18n from '../../../i18n';

const SORT_OPTIONS = [
	'topic_question_order_placeholder',
	'topic_question_order_highest_average',
	'topic_question_order_lowest_average',
	'topic_question_order_most_answers',
	'topic_question_order_least_answers'
];
const COLUMN_OPTIONS = { SINGLE: 0, DOUBLE: 1 };

export class QuestionsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSortOption: 0,
            expandedQuestionsOrderIndexes: {},
            questionsToShowCount: props.pageSize,
            columnCount: COLUMN_OPTIONS.DOUBLE,
            t: i18n.t.bind(i18n)
        };
        this.showCardsInColumns = this.showCardsInColumns.bind(this);
        this.handleSortByChange = this.handleSortByChange.bind(this);
        this.expandAllQuestions = this.expandAllQuestions.bind(this);
        this.collapseAllQuestions = this.collapseAllQuestions.bind(this);
        this.toggleCollapseExpandQuestion = this.toggleCollapseExpandQuestion.bind(this);
        this.loadNextPage = this.loadNextPage.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
		if (!prevProps.shouldResetItems && this.props.shouldResetItems) {
            this.setState({ expandedQuestionsOrderIndexes: {}, questionsToShowCount: this.props.pageSize });
        } else {
            const questionsOrderIndexesToExpand = [];
            this.props.questions.forEach(questionItem => {
                const prevQuestion = prevProps.questions.find(
                    prevQuestionItem => prevQuestionItem.question === questionItem.question
                );
                if (
                    prevQuestion &&
                    !prevQuestion.hasOwnProperty('answers') &&
                    questionItem.hasOwnProperty('answers') &&
                    !this.state.expandedQuestionsOrderIndexes[questionItem.orderIndex]
                ) {
                    questionsOrderIndexesToExpand.push(questionItem.orderIndex);
                }
            });
            if (questionsOrderIndexesToExpand.length > 0) {
                const expandedQuestionsOrderIndexesUpdate = {
                    ...this.state.expandedQuestionsOrderIndexes,
                    ...questionsOrderIndexesToExpand.reduce((acc, cur) => {
                        return { ...acc, [cur]: true };
                    }, {})
                };
                const questionsCount = this.props.questions.length;
                const questionsExpansionsCount = Object.keys(expandedQuestionsOrderIndexesUpdate).length;
                const questionsToShowCountUpdate =
                    questionsCount === questionsExpansionsCount ? questionsCount : this.state.questionsToShowCount;
                this.setState({
                    expandedQuestionsOrderIndexes: expandedQuestionsOrderIndexesUpdate,
                    questionsToShowCount: questionsToShowCountUpdate
                });
            }
        }
    }
    
    expandAllQuestions() {
        const questionsCount = this.props.questions.length;
        let questionsExpansionsCount = 0;
        let allExpanded = true;
        Object.values(this.state.expandedQuestionsOrderIndexes).forEach(isQuestionExpanded => {
            questionsExpansionsCount++;
            allExpanded = allExpanded && isQuestionExpanded;
        });
        const hasUnexpanded =
            questionsCount > 0 &&
            (questionsExpansionsCount === 0 ||
                questionsExpansionsCount < questionsCount ||
                (questionsExpansionsCount === questionsCount && !allExpanded));
        if (hasUnexpanded) {
            const allHaveAnswers = this.props.questions.reduce((acc, curr) => {
                return acc && curr.hasOwnProperty('answers');
            }, true);
            if (!allHaveAnswers) {
                const questionsTitles = this.props.questions.map(questionItem => questionItem.question);
                this.props.loadQuestionsAnswers(questionsTitles);
            } else {
                const orderIndexes = this.props.questions.map(questionItem => questionItem.orderIndex);
                this.setState({
                    expandedQuestionsOrderIndexes: orderIndexes.reduce((acc, cur) => {
                        return { ...acc, [cur]: true };
                    }, {})
                });
            }
        }
    }
    
    collapseAllQuestions() {
        const questionsCount = this.props.questions.length;
        let questionExpansionsCount = 0;
        let hasExpanded = false;
        Object.values(this.state.expandedQuestionsOrderIndexes).forEach(isQuestionExpanded => {
            questionExpansionsCount++;
            hasExpanded = hasExpanded || isQuestionExpanded;
        });
        const allUnexpanded = questionsCount === 0 || questionExpansionsCount === 0 || !hasExpanded;
        if (!allUnexpanded) {
            const expandedQuestionsOrderIndexesUpdate = Object.keys(this.state.expandedQuestionsOrderIndexes).reduce(
                (acc, cur) => {
                    return { ...acc, [cur]: false };
                },
                {}
            );
            this.setState({ expandedQuestionsOrderIndexes: expandedQuestionsOrderIndexesUpdate });
        }
    }

    toggleCollapseExpandQuestion(questionTitle) {
        const question = this.props.questions.find(questionItem => {
            return questionItem.question === questionTitle;
        });
        if (!this.state.expandedQuestionsOrderIndexes[question.orderIndex]) { 
            if (!question.hasOwnProperty('answers')) {
                this.props.loadQuestionAnswers(questionTitle);
            } else {
                this.setState({
                    expandedQuestionsOrderIndexes: {
                        ...this.state.expandedQuestionsOrderIndexes,
                        [question.orderIndex]: true
                    }
                });
            }
        } else {
            this.setState({
                expandedQuestionsOrderIndexes: {
                    ...this.state.expandedQuestionsOrderIndexes,
                    [question.orderIndex]: false
                }
            });
        }
    }

    handleSortByChange(selectedSortIndex, ev) {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }
        this.setState({
            selectedSortOption: Number(selectedSortIndex)
        });
    }
    
    showCardsInColumns() {
        const evenColumnCards = [];
		const oddColumnCards = [];
		const sortedQuestions = this.getSortedQuestions();
		const sortedQuestionsToShow = this.state.questionsToShowCount
			? sortedQuestions.slice(0, Math.min(this.state.questionsToShowCount, sortedQuestions.length))
			: sortedQuestions;
		sortedQuestionsToShow.forEach((questionItem, index) => {
			const qCard = (
				<QuestionDetailsItem
					key={questionItem.orderIndex}
					width={this.props.chartsWidth}
					question={questionItem}
					shouldResetItem={this.props.shouldResetItems}
					handleCollapseExpandItem={this.toggleCollapseExpandQuestion}
					isCollapsed={!this.state.expandedQuestionsOrderIndexes[questionItem.orderIndex]}
				/>
			);
			if (isOnMobile() || index % 2 === 0 || this.state.columnCount === COLUMN_OPTIONS.SINGLE) {
				evenColumnCards.push(qCard);
			} else {
				oddColumnCards.push(qCard);
			}
		});
		if (isOnMobile() || this.state.columnCount === COLUMN_OPTIONS.SINGLE) {
			return (
				<div className='question-details-list row-fluid clearfix'>
					<div className='col-md-12'>{evenColumnCards}</div>
				</div>
			);
		} else {
			return (
				<div className='question-details-list row-fluid clearfix'>
					<div className='col-md-6 col-xs-6'>{evenColumnCards}</div>
					<div className='col-md-6 col-xs-6'>{oddColumnCards}</div>
				</div>
			);
		}
    }

    getSortedQuestions() {
        switch (this.state.selectedSortOption) {
            case 0:
                return this._sortQuestionsByOrderIndex(this.props.questions);
            case 1:
                return this._sortAscendingQuestionsBySelection(this.props.questions, 'vote');
            case 2:
                return this._sortDescendingQuestionsBySelection(this.props.questions, 'vote');
            case 3:
                return this._sortAscendingQuestionsBySelection(this.props.questions, 'answerCount');
            case 4:
                return this._sortDescendingQuestionsBySelection(this.props.questions, 'answerCount');
            default:
                return this._sortQuestionsByOrderIndex(this.props.questions);
        }
    }

    _sortQuestionsByOrderIndex = memoizeOne(questions => [...questions].sort((a, b) => a.orderIndex - b.orderIndex));
    _sortAscendingQuestionsBySelection = memoizeOne((questions, sortSelection) =>
        [...questions].sort((a, b) =>
            b[`${sortSelection}`] - a[`${sortSelection}`] === 0
                ? a.orderIndex - b.orderIndex
                : b[`${sortSelection}`] - a[`${sortSelection}`]
        )
    );
    _sortDescendingQuestionsBySelection = memoizeOne((questions, sortSelection) =>
        [...questions].sort((a, b) =>
            a[`${sortSelection}`] - b[`${sortSelection}`] === 0
                ? a.orderIndex - b.orderIndex
                : a[`${sortSelection}`] - b[`${sortSelection}`]
        )
    );

    loadNextPage() {
        this.setState({ questionsToShowCount: this.state.questionsToShowCount + this.props.pageSize });
    }

    toggleColumnCount() {
        if (this.state.columnCount === COLUMN_OPTIONS.SINGLE) {
            this.setState({ columnCount: COLUMN_OPTIONS.DOUBLE }, () => {
                this.props.resizeChartsWidth();
            });
        } else {
            this.setState({ columnCount: COLUMN_OPTIONS.SINGLE }, () => {
                this.props.resizeChartsWidth();
            });
        }
    }

    get getRemainingTotal() {
        return this.props.questions.length - this.state.questionsToShowCount;
    }

    get sortOptions() {
        const t = this.state.t;
        return SORT_OPTIONS.map((o, i) => {
			return {
				label: t(o),
				value: i.toString()
			};
		});
    }

    render() {
        const t = this.state.t;
        return(
            <div className="questionnaire-container" >
                <div className="questions-list-options-bar clearfix">
                    <div className="text-uppercase pull-left export-pdf-question-orber-by" >
                        {t('topic_question_list_ordered_by')} 
                        <OptionsSelection 
                            onSelection={this.handleSortByChange}
                            options={this.sortOptions} 
                            value={this.state.selectedSortOption.toString()} 
                        />
                    </div>

                    {isOnMobile() === false && 
                    <div className="text-uppercase pull-left cards-column-options">
                        {t('topic_question_list_showed_in')}&nbsp;
                        <Switch
                            value={this.state.columnCount === COLUMN_OPTIONS.DOUBLE}
                            onChange={() => this.toggleColumnCount()}
                            offLabel={t('topic_question_list_one_column')}
                            onLabel={t('topic_question_list_two_column')} />
                    </div>
                    }

                    <div className="btngroup pull-right clearfix">
                        <button type='button' className='btn btn-xs transparent uitest-collapse-all-btn' onClick={this.collapseAllQuestions}>
                            <Minus size={14}/> {t('topic_question_list_collapse_all')}
                        </button>
                        <button type='button' className='btn btn-xs transparent uitest-expand-all-btn' onClick={this.expandAllQuestions}>
                            <Square size={14} /> {t('topic_question_list_expand_all')}
                        </button>
                    </div>
                </div>
    
                { this.showCardsInColumns() }
    
                {this.getRemainingTotal > 0 &&
                    <button type="button" className="btn-outline btn-full-width load-more uitest-load-more-btn" onClick={this.loadNextPage}>
                        show {Math.min(this.props.pageSize, this.getRemainingTotal)} more (of {this.getRemainingTotal})
                    </button>
                }
            </div>
        );
    }
}

QuestionsList.propTypes = {
    /** @prop {number} chartsWidth */
    chartsWidth: PropTypes.number,
    /** @prop {array} questions for loaded pages only */
    questions: PropTypes.array.isRequired,
    /** @prop {number} pageSize */
    pageSize: PropTypes.number.isRequired,
    /** 
     * @prop {func} loadQuestionAnswers action to load answers per question
     * @param {object} questionText the question title */
    loadQuestionAnswers: PropTypes.func.isRequired,
    /**
     * @prop {func} loadQuestionsAnswers action to load all answers
     * @param {array} questionTexts the question titles */
    loadQuestionsAnswers: PropTypes.func.isRequired,
    /** @prop {bool} timeFrameChanged true when time frame has changed and QnA needs to reset */
    shouldResetItems: PropTypes.bool,
    /**
     * @prop{func} resizeChartsWidth */
    resizeChartsWidth: PropTypes.func
};

export default QuestionsList;