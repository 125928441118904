import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp, User, TrendingUp, TrendingDown } from 'react-feather';
import GroupByVote from '../groupByVote/groupByVote';
import { getVoteStats } from '../../../../../helpers/voteDataHelper';
import { useTranslation } from 'react-i18next';

const GroupByVoter = ({ voterData, onRequestVotes, onResetVotes, questions, hideDeprecatedQuestions }) => {
	const [isCollapsed, setIsCollapsed] = useState(true);
	const [hasVotes, setHasVotes] = useState(false);
	const [votesCount, setVotesCount] = useState(0);

	const { t } = useTranslation();

	const votesChanged = voterData.votes && voterData.votes.length !== votesCount;

	useEffect(() => {
		if (voterData.votes && voterData.votes.length > 0) {
			setHasVotes(true);
			setVotesCount(voterData.votes.length);
		}
	}, [votesChanged, votesCount]);

	function handleExpandCollapseClick() {
		if (isCollapsed) {
			onRequestVotes(voterData.voterId);
			setIsCollapsed(false);
		} else {
			onResetVotes(voterData.voterId);
			setIsCollapsed(true);
		}
	}

	function getOpinionChange(voter) {
		let opinionChangeClassName = "opinion-change";
		let icon = "";
		let label = t("topic_vote_timeline_opinion_no_change");

		if (voter.voteChange) {
			opinionChangeClassName = voter.voteChange >= 0 ? `${opinionChangeClassName} opinion-increase` : `${opinionChangeClassName} opinion-decrease`;			
			icon = voter.voteChange >= 0 ? <TrendingUp size={16} /> : <TrendingDown size={16} />;
			label = voter.voteChange >= 0 ? t('topic_vote_timeline_opinion_increase') : t('topic_vote_timeline_opinion_decrease');
		}

		return <span className={opinionChangeClassName}>
			{label} {voter.voteChange && <span>{t('topic_vote_timeline_by')} {Math.abs(voter.voteChange / 10)} {icon} </span>}
		</span>;
	}

	function getVoterVotes(votes) {
		return votes.map((vote, index) => {
			const voteStats = getVoteStats(vote);

			return <GroupByVote
						key={index}
						voteWithQna={vote}
						stats={voteStats}
						isCollapsedAllOn={isCollapsed}
						questions={questions}
						hideDeprecatedQuestions={hideDeprecatedQuestions}
					/>;
		});
	}

	return (
		<div className="group-by-voter-container">
			<div className="grouped-answers group-by-voter-item">
				<h4 onClick={handleExpandCollapseClick}>
					<button type="button" className="btn transparent expand-collapse">
						{isCollapsed 
							? <ChevronDown size="18" />
							: <ChevronUp size="18" />}
					</button>

					<User size={16} />
					<span>{voterData.gender && <strong>{voterData.gender}, </strong>}{voterData.ageGroup && <strong>{voterData.ageGroup}</strong>}</span>
					<span>{voterData.city && <span> {t('topic_vote_timeline_from')} <strong>{voterData.city}</strong></span>} {voterData.countryCode && <span>, <strong>{voterData.countryCode}</strong></span> }</span>
					<span>{t('topic_vote_timeline_gave')} <strong>{voterData.votesCount} {voterData.votesCount > 1 ? t('topic_vote_timeline_votes') : t('topic_vote_timeline_vote')}</strong></span>
					<span>{t('topic_vote_timeline_with_overall_rating')} <strong className={`rating-${Math.trunc(Number(voterData.meanRating) / 10)}-text`}>{Number(voterData.meanRating) / 10}</strong></span>
					<span>{t('topic_vote_timeline_gives')} {getOpinionChange(voterData)}</span>
				</h4>

				{!isCollapsed && hasVotes && <div className="votes">
					{getVoterVotes(voterData.votes)}
				</div>}
			</div>			
		</div>
	);
};

GroupByVoter.propTypes = {
	voterData: PropTypes.shape({
		gender: PropTypes.string,
		voterId: PropTypes.string.isRequired,
		voteChange: PropTypes.number,
		votesCount: PropTypes.number,
		meanRating: PropTypes.number,
		city: PropTypes.string,
		countryCode: PropTypes.string,
		ageGroup: PropTypes.string,
		votes: PropTypes.arrayOf(PropTypes.shape({

		}))
	}).isRequired,

	onRequestVotes: PropTypes.func.isRequired,

	onResetVotes: PropTypes.func.isRequired,

	hideDeprecatedQuestions: PropTypes.bool
};

GroupByVoter.defaultProps = {
	voterData: {
		votes: []
	},
	hideDeprecatedQuestions: false
};

export default GroupByVoter;