import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Link } from 'react-router';
import { isEmpty } from 'lodash';

import { activate, clearErrors, requestLoginStatus } from '../../actions/authActions';
import { createRootTopicForEntityAction } from '../../actions/entitiesActions';
import Card from '../../components/common/card';
import { CheckCircle, Clock, Grid } from 'react-feather';
import IntroGuide from '../../components/common/introGuide';

export class UserActivatePage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            activatedUserSuccess: false,
            saveRootTopicSent: false,
            loginStatusRequestSent: false
        };
    }
    
    static getDerivedStateFromProps(props, state) {
        const  { activateSuccess } = props.auth.user;

        if (activateSuccess) {
            return { activatedUserSuccess: activateSuccess };
        }

        return null;
    }
    
    componentDidMount() {
        const urlQuery = this.props.location.query;
        this.props.activate(urlQuery.token);
    }

    componentDidUpdate(props, state) {
        const { auth, entity, topics, createRootTopicForEntityAction } = this.props;

        if(this.state.activatedUserSuccess) {
            if (auth.user.id !== undefined) {
                if (!isEmpty(entity) && topics.length === 0 && !this.state.saveRootTopicSent) {
                    this.setState({
                        saveRootTopicSent: true
                    }, () => {
                        createRootTopicForEntityAction(entity.name, entity.id);
                    });
                }
            } else {
                if (!this.state.loginStatusRequestSent) {
                    this.setState({
                        loginStatusRequestSent: true
                    }, () => {
                        this.props.requestLoginStatus();
                    });
                }
            }
        }
    }

    get userActivated() {
        const { entity } = this.props;
        const { activatedUserSuccess } = this.state;

        let isActivated = false;

        if (entity.rootTopicCreated && activatedUserSuccess) {
            isActivated = entity.rootTopicCreated.status === 'success' && activatedUserSuccess;
        }
        
        return isActivated;
    }
    
    render() {
        return (
            <div className="row-fluid clearfix">                
                {this.userActivated ? 
                    <React.Fragment>
                        <Card maxWidth="1152px" centered={true} title={`Activate account`}>
                            <p className="text-center text-success"><br /><CheckCircle size={48} /></p>
                            <h4 className="text-center text-success">Your free account has successfully been activated!</h4>
                            <p className="text-center message">
                                You can now open the <Link to="/" className="text-bold">Dashboard Overview</Link> and start exploring your kazva.bg dahsboard.
                            </p>
                            <p className="text-center"><br /><Link to="/"><button className="btn btn-outline"><Grid size={14} />Get started with Dashboard</button></Link></p>
                        </Card>
                    
                        <IntroGuide />
                    </React.Fragment>
                    : 
                    <Card maxWidth="1152px" centered={true} title={`Activate account`}>
                        <p className="text-center text-info"><br /><Clock size={48} /></p>
                        <p className="text-center text-success">Activating and setting up your account...</p>
                        <p className="text-center text-success">This will take a moment.</p>
                    </Card>
                }
            </div>
        );
    }
}

function mapStateToProps(state, props){
    return {
        auth: state.auth,
        entity: state.entities[0],
        topics: state.topics
    };
}

UserActivatePage.defaultProps = {
    requestErrors: {},
    entity: {},
    auth: {},
    topics: []
};

UserActivatePage.propTypes = {
    activate: PropTypes.func.isRequired,
    createRootTopicForEntityAction: PropTypes.func.isRequired,
    requestLoginStatus: PropTypes.func.isRequired,
    requestErrors: PropTypes.shape({}),
    clearErrors: PropTypes.func.isRequired,
    location: PropTypes.shape({
		query: PropTypes.object
    }),
    auth: PropTypes.shape({
		user: PropTypes.shape({
			id: PropTypes.number
		}),
    }).isRequired,
	entity: PropTypes.shape({
        id: PropTypes.number,
		name: PropTypes.string,
        rootTopicCreated: PropTypes.object,
    }),
    topics: PropTypes.array.isRequired
};

export default connect(mapStateToProps, { requestLoginStatus, activate, clearErrors, createRootTopicForEntityAction }) (UserActivatePage);