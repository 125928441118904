import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Maximize, Minimize, MoreHorizontal } from 'react-feather';

import Card from '../../common/card';
import ProgressBar from '../../common/progressBar';
import StackedHorizontalBarChart from '../charts/stackedHorizontalBarChart';
import RatingBadge from '../../common/ratingBadge';
import { STACKING_OPTIONS } from '../../../helpers/graphsService';
import FeaturesContext from "../../../scenes/contexts/featuresContext";
import { ENTITY_FEATURES } from "hollerlive-shared/constants";
import { COMPARATORS } from "../../../helpers/featuresHelper";
import i18n from '../../../i18n';

class QuestionDetailsItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			t: i18n.t.bind(i18n)
		};

		this.handleCollapseExpandItem = this.handleCollapseExpandItem.bind(this);
	}

	handleCollapseExpandItem() {
		this.props.handleCollapseExpandItem(this.props.question.question);
	}

	getCardTitle(titleText, meanRating) {
		return <span className="clearfix uitest-title">
					<RatingBadge vote={meanRating} size={14}/>&nbsp;
			
			{titleText}
			
			<FeaturesContext requiredFeatures={[{name: ENTITY_FEATURES.QNA_ANALYSIS_DETAILED, compare: COMPARATORS.IS_TRUE}]}>
					<button
						type="button"
						className={`btn btn-xs transparent animate ${this.props.isCollapsed ? 'animate-expand' : 'animate-collapse'} pull-right uitest-expand-collapse-btn`}
						onClick={this.handleCollapseExpandItem}>
						{this.props.isCollapsed ? <Maximize size={16}/> : <Minimize size={16}/>}
					</button>
			</FeaturesContext>
		</span>;
	}

	render() {
		const { question, width } = this.props;
		const answerPercentage = isNaN(question.answerCount) || isNaN(question.topicVotesCount) || question.answerCount === 0 || question.topicVotesCount === 0 ? 
			0 : 
			Math.floor((question.answerCount / question.topicVotesCount) * 100);
		const answers = question.answers || [];
		let title = `${question.orderIndex + 1}. ${question.question}`;

		const isMultipleChoice = question.type === "close";
		if ( !isMultipleChoice ) {
			title += " (OE)";
		}

		const stackFieldOptionsBarChart = isMultipleChoice ? 
			[STACKING_OPTIONS.sentiment, STACKING_OPTIONS.gender, STACKING_OPTIONS.ageGroups] :
			[STACKING_OPTIONS.ageGroups, STACKING_OPTIONS.sentiment];

		const cardTitle = this.getCardTitle(title, question.vote);

		const t = this.state.t;

		return (
			<Card className="charts-card question-details-item export-pdf-question-details-item">
				<h4>{cardTitle}</h4>

				<div className="stats-bar">
					<ProgressBar percentage={answerPercentage > 100 ? 100 : answerPercentage} />
					<label className="text-label text-light">{t('topic_question_card_percentage')}</label>
				</div>
				<p className="text-label">
					<strong>{question.answerCount > question.topicVotesCount ? question.topicVotesCount : question.answerCount}</strong> {t('topic_question_card_votes_collected')} <strong>{question.meanResponseTime}</strong>
				</p>

				<div className={`answers-chart ${this.props.isCollapsed ? 'hide' : 'show'}`}>
					<StackedHorizontalBarChart
						data={answers}
						dataFieldName={`${isMultipleChoice ? "answer" : "gender"}`}
						stackFieldOptions={stackFieldOptionsBarChart}
						chartYAxisTitle={`${isMultipleChoice ? t('topic_question_card_chart_answers') : t('topic_question_card_chart_gender')}`}
						chartXAxisTitle={t('topic_question_card_chart_xaxis')}
						showLegend={false}
						width={width} />
				</div>

				<button className="btn btn-xs btn-outline btn-full-width question-details-expand animate" type="button" onClick={this.handleCollapseExpandItem}>
					{`${this.props.isCollapsed ? t('topic_question_card_expand_graph_details') : t('topic_question_card_collapse_graph_details')}`}
				</button>

				{this.props.isCollapsed && 
					<div className="expand-card-footer uitest-dots-btn" onClick={this.handleCollapseExpandItem}>
						<MoreHorizontal size={12} />
					</div>
				}
			</Card>
		);
	}
}

QuestionDetailsItem.propTypes = {
	/** @prop {object} question to be rendered in card */
	question: PropTypes.object.isRequired,

	/** @prop {number} width of bar chart */
	width: PropTypes.number,

    /** @prop {bool} shouldResetItem true to signal change in question data */
	shouldResetItem: PropTypes.bool,

	handleCollapseExpandItem: PropTypes.func.isRequired,

	isCollapsed: PropTypes.bool
};

export default QuestionDetailsItem;
