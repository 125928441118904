import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';

import { getEntitySubscriptionStatus } from "../../actions/entitiesActions";
import Card from '../../components/common/card';
import { CheckCircle, Grid, MessageSquare } from 'react-feather';
import IntroGuide from '../../components/common/introGuide';
import ServerErrorMessage from '../../components/common/serverErrorMessage';
import { showModal, hideModal } from '../../actions/modalActions';
import { clearContactFormErrors } from '../../actions/contactActions';
import { CONTACT_FORM_MODAL, CONFIRM_MODAL } from '../../helpers/modalTypes';
import messages from '../../helpers/messages';

export class PaymentSuccessfulPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			subscriptionCheckSent: false
		};

		this.closeModal = this.closeModal.bind(this);
		this.showConfirmSendModal = this.showConfirmSendModal.bind(this);
		this.handleSupportButtonClick = this.handleSupportButtonClick.bind(this);
	}
	
	componentDidMount() {
		if (!isEmpty(this.props.entity)) {
			const urlQuery = this.props.location.query;
			this.props.actions.getEntitySubscriptionStatus(this.props.entity.id, { sessionId: urlQuery.session_id });
		}
	}
	
	componentDidUpdate(nextProps) {
		if (!isEqual(nextProps.entity, this.props.entity) && this.props.entity.id) {
			const urlQuery = this.props.location.query;
			this.setState({ subscriptionCheckSent: true });
			this.props.actions.getEntitySubscriptionStatus(this.props.entity.id, { sessionId: urlQuery.session_id });
		}
	}

	handleSupportButtonClick() {
		this.showContactForm(); 
	}

	showContactForm() {
		const config = {
			onSubmit: this.showConfirmSendModal,
			onClose: () => {
				this.props.actions.clearContactFormErrors();
				this.closeModal();
			},
            data: { enableTechnicalData: false }
		};
		this.props.actions.showModal(CONTACT_FORM_MODAL, config);
	}

	closeModal() {
		this.props.actions.hideModal(); 
	}

    showConfirmSendModal() {
        const config = {
            title: messages.common.thankYou,
            message: messages.common.requestSendToSupport,
            onClose: this.closeModal,
            onConfirm: this.closeModal,
            hasFooter: false
        };
    
        this.props.actions.showModal(CONFIRM_MODAL, config);
    }

	get subscriptionPlanName () {
		return this.props.entity ? this.props.entity.tierName : "";
	}

	get hasErrors() {
		return this.props.entity && !isEmpty(this.props.entity.errors);
	}

	render() {
		return (
			<div className="page-payment-successful">
				<Card title="Upgrade account" maxWidth="1152px" centered={true}>
					<p className="text-center text-success"><br /><CheckCircle size={48} /></p>
					<h4 className="text-center text-success">Your payment is successful</h4>
					{this.hasErrors ? 
					<div className="row clearfix text-center">
							<ServerErrorMessage errors={this.props.entity.errors} name="subscriptionError" className="control-label text-label text-medium" />
							<p className="">
								Please <button className="btn btn-dotted uitest-contact-btn" onClick={this.handleSupportButtonClick}><MessageSquare size={14} />contact support</button> to resolve it.
							</p>
					</div>
					:
					<div>
						<p className="text-center">
							Your <strong>{this.subscriptionPlanName} subscription plan</strong> is set up and ready to use. Navigate to your <Link to="/"><strong>Topics Overview</strong></Link>, <br />
							or check out <strong>What’s next steps below</strong> to quick start with your new kazva.bg Dashboard
						</p>
						<p className="text-center"><br /><Link to="/"><button className="btn btn-outline"><Grid size={14} />Get started with Dashboard</button></Link></p>
					</div>
					}
				</Card>

				<IntroGuide />
			</div>
		);
	};
};

PaymentSuccessfulPage.propTypes = {
	entity: PropTypes.object,
	actions: PropTypes.shape({
		getEntitySubscriptionStatus: PropTypes.func,
		showModal: PropTypes.func, 
		hideModal: PropTypes.func, 
		clearContactFormErrors: PropTypes.func
	}),
	location: PropTypes.shape({
		query: PropTypes.object
	})
};

function mapStateToProps(state) {
	return {
		entity: state.entities[0] || {}
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({ getEntitySubscriptionStatus, showModal, hideModal, clearContactFormErrors }, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccessfulPage);