import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as types from './actionTypes';
import { graphPost, streamGet, graphGet, graphPut } from '../api/expressApi';
import { addNotification } from './miscActions';
import { loadTopicStructureSuccess } from './topicActions';
import { loadEntitySourcesSuccess } from './entitiesActions';
import { handleCommonError, handleVersionErrors, handleValidationErrors } from './errorActions';
import { combineTopicModelsForEdit, mapToTopicEditRequestModel } from './models/topicModel';
import { notificationLevel } from '../helpers/notification';

function updateTopicSuccess(topic) {
	return { type: types.UPDATETOPIC_SUCCESS, payload: { topic } };
}

function saveTopicSuccess(response) {
    return { type: types.SAVETOPIC_SUCCESS, payload: response };
}

function loadTopicByHashSuccess(topic) {
    return {type: types.LOAD_TOPIC_BY_HASH_SUCCESS, payload: topic};
}

function loadTopicForTemplateSuccess(topic) {
    return {type: types.LOAD_TOPIC_FOR_TEMPLATE_SUCCESS, payload: topic};
}

function displayValidationErrors(errors) {
    return {type: types.SAVETOPIC_ERROR, payload: errors};
}

function loadTopicForEditRequest(){
    return {type: types.LOAD_TOPIC_FOR_EDIT_REQUEST};
}

function setAsyncValidating(){
    return {type: types.SET_ASYNC_VALIDATING};
}

function resetAsyncValidating(){
    return {type: types.RESET_ASYNC_VALIDATING};
}

function resetTopicNameError(){
    return {type: types.CLEAR_TOPIC_NAME_ERROR};
}

export function resetValidationErrors() {
    return {type: types.SAVETOPIC_ERROR_RESET};
}

export function resetTopicForEdit(){
    return { type: types.RESET_TOPIC_FOR_EDIT };
}

export function loadTopicByHash(topicHash) {
    return _loadTopicForEdit(topicHash, loadTopicByHashSuccess);
}

export function loadTopicForTemplate(topicHash) {
    return _loadTopicForEdit(topicHash, loadTopicForTemplateSuccess);
}

function _loadTopicForEdit(topicHash, successAction) {
    return (dispatch, getState) => {
        const endpointName = '/topics/' + topicHash;
        if (_shouldLoadTopic(getState())) {

            dispatch(showLoading());
            dispatch(loadTopicForEditRequest());

            return streamGet(endpointName).then(topic => {
                return graphGet(`/topics/${topic.basicSettings.id}/edit`).then(graphTopic => {
                    const editModel = combineTopicModelsForEdit(topic, graphTopic);
                    dispatch(successAction(editModel));
                    dispatch(loadTopicStructureSuccess(topic));
                    dispatch(loadEntitySourcesSuccess(Number(topic.basicSettings.entityId), graphTopic.sources));
                    dispatch(hideLoading());
                });
            },
            error => {
                handleCommonError(dispatch, error);
            });
        }

    };
}

function _shouldLoadTopic(state){
    return !state.topicForEdit.isLoading;
}

export function saveTopic(topic) {
    return async dispatch => {
        dispatch(showLoading());
        try {
            const topicModel = mapToTopicEditRequestModel(topic);
            const topicResponse = await graphPost('/topics/create', topicModel);
            dispatch(saveTopicSuccess(topicResponse));
            dispatch(hideLoading());
            dispatch(addNotification(`Topic ${topicResponse.title} created successfully.`,  notificationLevel.success));
        } catch (error) {
            handleCommonError(dispatch, error);
            dispatch(displayValidationErrors(error.validationErrors));
        }
    };
}

export function updateTopic(topic) {
    return async dispatch => {
        dispatch(showLoading());
        try {
            const topicModel = mapToTopicEditRequestModel(topic);
            const topicResponse = await graphPut('/topics/edit', topicModel);
            dispatch(updateTopicSuccess(topicResponse));
            dispatch(loadTopicStructureSuccess(topic));
            dispatch(hideLoading());
            dispatch(addNotification("Topic updated successfully.",  notificationLevel.success));
        } catch (error) {
            handleCommonError(dispatch, error);
            handleVersionErrors(dispatch, error, { type: types.UPDATE_TOPIC_VERSION_ERROR });
            dispatch(displayValidationErrors(error.validationErrors));
        }
    };
}

export function validateTopicNameDuplicate(topicName) {
    return async dispatch => {
        if (!topicName || topicName.length === 0) {
            dispatch(resetTopicNameError());
        } else {
            dispatch(setAsyncValidating());
            try {
                await graphPost('/topics/validateName', { name: topicName });
                dispatch(resetTopicNameError());
            } catch (error) {
                handleValidationErrors(dispatch, error, types.SET_TOPIC_NAME_ERROR);
            }
            dispatch(resetAsyncValidating());
        }
    };
}