import * as types from '../actions/actionTypes';
import { parseRawVoteDataToJsonArray } from '../helpers/voteDataHelper';

const initialState = {
    treeStructure: null,
    periodFilter: {},
    isBuilding: false,
    topicsStats: [],
    isLoadingStats: false,
    isLoadedStats: false
};

export function topicsTreeReducer(state = initialState, action) {
    switch (action.type) {
        case types.BUILD_TOPIC_TREE_REQUEST:
            return Object.assign({}, state, {
                isBuilding: true
            });
        case types.BUILD_TOPIC_TREE_SUCCESS: {
            return Object.assign({}, state, {
                treeStructure: action.payload,
                isBuilding: false
            });
        }
        case types.LOAD_TOPIC_TREE_REQUEST: {
            return Object.assign({}, state, {
                isLoadingStats: true,
                isLoadedStats: false
            });
        }
        case types.LOAD_TOPIC_TREE_SUCCESS: {
            const { topics, votesStats } = action.payload;
            const topicsStats = topics.map(topic => {
                const topicVotesStats = votesStats.find(voteStat => voteStat.id === topic.id) || {};
                const parsedLatestVotes = parseRawVoteDataToJsonArray(topicVotesStats.lastVotes);
                const avgVote =
                    topicVotesStats.stats && topicVotesStats.stats.votesCount > 0
                        ? Math.round(topicVotesStats.stats.votesSum / topicVotesStats.stats.votesCount) / 10
                        : 0;
                const { votesSum = 0, votesCount = 0, answersCount = 0 } = topicVotesStats.stats || {};
                return {
                    id: topic.id,
                    name: topic.name,
                    stats: { votesSum, votesCount, answersCount, avgVote },
                    latestVotes: parsedLatestVotes
                };
            });
            return Object.assign({}, state, {
                topicsStats,
                isBuilding: false,
                isLoadingStats: false,
                isLoadedStats: true
            });
        }
        case types.SET_TOPIC_TREE_PERIODFILTER: {
            const periodFilter = action.payload;
            return Object.assign({}, state, { periodFilter });
        }
        case types.RESET_TOPIC_TREE:
        case types.SAVETOPIC_SUCCESS:
        case types.DELETE_TOPIC_SUCCESS: {
            return initialState;
        }
        default:
            return state;
    }
}