import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Image, Clock, EyeOff } from 'react-feather';

import ImagePreviewButton from '../../imagePreviewButton';
import { isOnMobile } from '../../../../../helpers/utils';
import Messages from '../../../../../helpers/messages';
import { useTranslation } from 'react-i18next';

const groupByVoteItem = ({ answerItem }) => {
	const { question, answer, timeSpent, deprecated, questionIndex, attachedImageLink } = answerItem;
	const timeSpentS = moment.duration(timeSpent).asSeconds().toFixed(2);
	const hasAnswer = (answer && answer.length > 0) || (attachedImageLink && attachedImageLink.length > 0);
	const groupItemClassName = `group-item group-vote-item ${deprecated ? 'deprecated text-muted' : ''}`;
	const hasAttachedImage = attachedImageLink && (attachedImageLink.length > 0);
	const isMobile = isOnMobile();
	const { t } = useTranslation();

	const getTimeStamp = () => {
		return <small className="meta text-label text-right text-light">
			<Clock size={12} /> <span className="uitest-time-spent">{ timeSpentS }</span><small> {t('topic_vote_timeline_sec')}</small>
		</small>;
	};

	return (
		<div className={groupItemClassName}>
			<React.Fragment>
				<div className="vote-question-text uitest-question-test"><strong>{ deprecated === true ? <EyeOff size={14} /> : `${ questionIndex + 1 }.` } { question }</strong></div>
				{isMobile && getTimeStamp()}

				<div className={`vote-answer-text uitest-vote-answer-text ${hasAttachedImage && 'has-attached-image'}`}>
					<p>
						{ hasAnswer ? answer : <small className="text-left text-muted text-label">{Messages.topics.noAnswerText}</small>}</p> 
						{hasAttachedImage && 
						<ImagePreviewButton 
							buttonText = {<span><Image size="14" /> {t('topic_vote_timeline_image_preview')}</span>}
							imageLink = {attachedImageLink}
							previewTitle = {`${question} ${t('topic_vote_timeline_answer_preview')}`}
							imageTitle = {answer}
							className="pull-right uitest-image-preview-btn"
						/>}						
				</div>

				{!isMobile && getTimeStamp()}
			</React.Fragment>
		</div>
	);
};

groupByVoteItem.propTypes = {
	answerItem: PropTypes.shape({
		question: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.array
		]),
		questionIndex: PropTypes.number,
		answer: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.array
		]),
		timeSpent: PropTypes.number,
		deprecated: PropTypes.bool,
		attachedImageLink: PropTypes.string
	}).isRequired,
};

groupByVoteItem.defaultValues = {
	answerItem: {
		deprecated: false
	}
};

export default groupByVoteItem;