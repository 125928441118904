import React from 'react';
import PropTypes from 'prop-types';

import * as Icons from 'react-feather';
import AutoComplete from '../../common/form/autocomplete';

const AutoCompleteSelectorItem = props => {
	const { index, options, selection, onChange, onRemove } = props;

	return (
		<div className="ordered-form-fields">
			<div className="with-action ordered-form-fields-item">
				<div className="number">{index + 1}</div>
				<div className="form-control-container">
					<AutoComplete 
						data={options}
						value={selection ? selection[props.valueField] : ""}
						valueField={props.valueField}
						onChange={(value) => onChange(index, value)}
						disabled={selection !== undefined}
						className={selection ? 'selector-disabled' : undefined}
					/>
				</div>
				<span className="action">
					<Icons.XCircle onClick={(e) => onRemove(index, e)} className="text-danger"/>
				</span>
			</div>
		</div>
	);
};

AutoCompleteSelectorItem.propTypes = {
	index: PropTypes.number.isRequired,
	
	options: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired
	})).isRequired,

	selection: PropTypes.shape({
		name: PropTypes.string
	}),

	onChange: PropTypes.func.isRequired,

	onRemove: PropTypes.func.isRequired,
	valueField: PropTypes.string
};

export default AutoCompleteSelectorItem;