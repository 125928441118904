import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as feedActions from '../../actions/feedActions';
import moment from 'moment';
import { isEqual } from 'lodash';

import { 
	SENTIMENT_OPTIONS, 
	GENDER_OPTIONS, 
	AGE_GROUPS_OPTIONS, 
	DEFAULT_PAGE_SIZE,
	getParsedFiltersGroup 
} from '../../helpers/graphsService';

import { AlignLeft, HelpCircle, Users } from 'react-feather';
import DatasetFilters from '../../components/topics/common/datasetFilters';

import './topicFeed/css/topicFeed.css';
import i18n from '../../i18n';

export const CHILD_SCENES_NAMES = { 
	votes: 'votes', 
	questions: 'questions', 
	voters: 'voters' 
};

export class TopicDetailsFeed extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			filters: this.getDefaultFeedFilters,
			filtersActive: true,
			searchValue: null,
			activeChildSceneName: props.router.routes[props.router.routes.length - 1].path,
			t: i18n.t.bind(i18n)
		};

		this.handleApplyFilters = this.handleApplyFilters.bind(this);
		this.handleFiltersGroupValueChange = this.handleFiltersGroupValueChange.bind(this);
		this.handleDeactivateFilters = this.handleDeactivateFilters.bind(this);

		this.handleGetFilteredVotes = this.handleGetFilteredVotes.bind(this);
		this.handleResetFilteredVotes = this.handleResetFilteredVotes.bind(this);
		
		this.handleGetFilteredVoters = this.handleGetFilteredVoters.bind(this);
		this.handleGetVoterFilteredVotes = this.handleGetVoterFilteredVotes.bind(this);
		this.handleResetFilteredVoters = this.handleResetFilteredVoters.bind(this);
		this.handleResetVotesForVoter = this.handleResetVotesForVoter.bind(this);
		
		this.handleGetFilteredAnswersForQuestions = this.handleGetFilteredAnswersForQuestions.bind(this);
		this.handleGetFilteredAnswersForSingleQuestion = this.handleGetFilteredAnswersForSingleQuestion.bind(this);
		
		this.handleExportCsv = this.handleExportCsv.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.dateFrom !== this.props.dateFrom) {
            this.reloadActiveChildSceneData();
		}

		if (!isEqual(prevProps.voterFilters, this.props.voterFilters)){
			this.reloadActiveChildSceneData();
        }
	}

	handleChildTabClick(targetChildScene) {
		this.setState({ activeChildSceneName: targetChildScene });
	}

	handleApplyFilters({ sentiment, ageGroup, gender }) {
		this.setState({ 
			filters: Object.assign({}, this.state.filters, { sentiment, ageGroup, gender })
        }, () => {
            this.reloadActiveChildSceneData();
        });
	}

	handleDeactivateFilters(filtersActive) {
		this.setState({
			filtersActive
		}, () => {
			this.reloadActiveChildSceneData();
		});
	}

	handleFiltersGroupValueChange(filtersGroup) {
		this.setState({
			filters: Object.assign({}, this.state.filters, filtersGroup)
		});
	}

	handleExportCsv() {
		const filteredParams = this.getFilteredPagedRequestObject();		
		
		const requestParams = Object.assign({}, filteredParams, {
			search: this.state.searchValue
		});
		
		this.props.actions.sendCsvExportRequest(requestParams);
	}
    
	handleResetVotesForVoter(voterId) {
        this.props.actions.resetVotesForVoter(this.props.topic.id, voterId);
	}

	handleGetFilteredVotes({ offset, searchValue }) {
		this.setState({ 
			searchValue
		}, () => {
			this.sendFilteredVotesRequest(offset);
		});
	}

	handleResetFilteredVotes(){
        this.props.actions.resetFilteredVotesByTopicId(this.props.topic.id);
	}

	handleGetFilteredVoters({ offset }) {
		this.sendFilteredVotersRequest(offset);
	}

	handleGetVoterFilteredVotes({ voterId }) {
		const filteredPagedParams = this.getFilteredPagedRequestObject(0);	
		const requestParams = Object.assign({}, filteredPagedParams, {
			voterId
		});
		this.props.actions.loadVotesForVoter(requestParams);
	}

	handleResetFilteredVoters(){
        this.props.actions.resetFilteredVotersByTopicId(this.props.topic.id);
	}
	

	handleGetFilteredAnswersForQuestions() {
		const { questions } = this.props;
		const requestFilterParams = this.getFilteredPagedRequestObject(0);

		const requestParams = Object.assign({}, requestFilterParams, {
			questions
		});

		this.props.actions.loadFilteredAnswersForQuestions(requestParams);
	}

	handleGetFilteredAnswersForSingleQuestion({ offset, question }) {
		const requestFilterParams = this.getFilteredPagedRequestObject(offset, 10);
		const requestParams = Object.assign({}, requestFilterParams, {
			question
		});

		this.props.actions.loadFilteredAnswersForSingleQuestion(requestParams);
	}

	get getDefaultFeedFilters() {
		const sentiment = getParsedFiltersGroup(SENTIMENT_OPTIONS);
		const gender = getParsedFiltersGroup(GENDER_OPTIONS);
		const ageGroup = getParsedFiltersGroup(AGE_GROUPS_OPTIONS);

		return {
			sentiment,
			gender,
			ageGroup
		};
	}

	reloadActiveChildSceneData() {
		switch (this.state.activeChildSceneName) {
			case CHILD_SCENES_NAMES.votes: {
				this.props.actions.resetFilteredVotesByTopicId(this.props.topic.id);
				this.sendFilteredVotesRequest(0);
				break;
			}
			case CHILD_SCENES_NAMES.questions: {
				this.handleGetFilteredAnswersForQuestions();
				break;
			}
			case CHILD_SCENES_NAMES.voters: {
				this.props.actions.resetFilteredVotersByTopicId(this.props.topic.id);
				this.sendFilteredVotersRequest(0);

				break;
			}
			default:
				break;
		}
	}

    sendFilteredVotesRequest(offset) {
		const filteredParams = this.getFilteredPagedRequestObject(offset);

		const requestParams = Object.assign({}, filteredParams, {
			search: this.state.searchValue
		});
		
		this.props.actions.loadFilteredVotesWithQnaByTopicId(requestParams);
	}

	sendFilteredVotersRequest(offset) {
		const requestParams = this.getFilteredPagedRequestObject(offset);
		this.props.actions.loadFilteredVotersByTopicId(requestParams);
	}

	getFilteredPagedRequestObject(offset, pageSize = DEFAULT_PAGE_SIZE) {
		const { filters, filtersActive } = this.state;
		const { topic, params, dateFrom, dateTo, voterFilters } = this.props;
        const topicId = topic.id || params.id; 

        const pageConfig = {
            offset,
            pageSize,
			orderBy: 'id',
			sortDirection: 'DESC'
        };
		
		const dateFromUtcString = moment(dateFrom).utc().format();
		const dateToUtcString = moment(dateTo).utc().format();

		return { 
			topicId, 
			dateFrom: dateFromUtcString, 
			dateTo: dateToUtcString, 
			pageConfig, 
			feedFilters: filtersActive ? filters : this.getDefaultFeedFilters, 
			votersFilters: voterFilters
		};
	}

	getChildScenePropsByPath(path) {
		const props = {};

		switch (path) {
			case CHILD_SCENES_NAMES.votes:
				props.requestVotes = this.handleGetFilteredVotes;
				props.resetVotes = this.handleResetFilteredVotes; 
				props.requestExportedVotes = this.handleExportCsv;
				break;
			case CHILD_SCENES_NAMES.questions:
				props.requestQuestionsWithAnswers = this.handleGetFilteredAnswersForQuestions;
				props.requestSingleQuestionAnswers = this.handleGetFilteredAnswersForSingleQuestion;
				break;
			case CHILD_SCENES_NAMES.voters:
				props.requestVoters = this.handleGetFilteredVoters;
				props.requestVotesForVoter = this.handleGetVoterFilteredVotes;
				props.resetVoters = this.handleResetFilteredVoters; 
				props.resetVotesForVoter = this.handleResetVotesForVoter;
				break;		
			default:
				break;
		}

		return props;
	}

	render() {
		const childScenes = React.Children.map(this.props.children, (child) => {
			const childProps = this.getChildScenePropsByPath(child.props.route.path);

			return React.cloneElement(child, {...childProps});
		});

		const { t, activeChildSceneName } = this.state;

		return (
			<div className="topic-details-feed">
				<div className={`subpages-nav ${this.state.showOverviewMobile ? "hide-on-mobile" :  ""}`}>
					<h5>{t('topic_vote_feed_title')}</h5>
					<Link to={`/topics/details/${this.props.topic.id}/feed/${CHILD_SCENES_NAMES.votes}`} onClick={() => this.handleChildTabClick(CHILD_SCENES_NAMES.votes)} activeClassName="active" className="tab"><AlignLeft size={18} /> <span className="tab-text">{t('topic_vote_feed_tab_chronological')}</span></Link>
					<Link to={`/topics/details/${this.props.topic.id}/feed/${CHILD_SCENES_NAMES.questions}`} onClick={() => this.handleChildTabClick(CHILD_SCENES_NAMES.questions)} activeClassName="active" className="tab"><HelpCircle size={18} /> <span className="tab-text">{t('topic_vote_feed_tab_group_by_question')}</span></Link>
					<Link to={`/topics/details/${this.props.topic.id}/feed/${CHILD_SCENES_NAMES.voters}`} onClick={() => this.handleChildTabClick(CHILD_SCENES_NAMES.voters)} activeClassName="active" className="tab"><Users size={18} /> <span className="tab-text">{t('topic_vote_feed_tab_group_by_voter')}</span></Link>
				</div>
				
				<DatasetFilters 
					onApplyFilters={this.handleApplyFilters} 
					onFiltersGroupValueChange={this.handleFiltersGroupValueChange}
					onDeactivateFilters={this.handleDeactivateFilters}
					values={this.state.filters}
					disableSentimentOptions={activeChildSceneName === CHILD_SCENES_NAMES.voters}
				/>
				
				{ childScenes }
			</div>
		);
	}
};

TopicDetailsFeed.propTypes = {
	dateFrom: PropTypes.any,
    dateTo: PropTypes.any,
    voterFilters: PropTypes.shape({
		anonymous: PropTypes.bool,
		facebook: PropTypes.bool,
		email: PropTypes.bool,
		latestPerVoter: PropTypes.bool,
		sources: PropTypes.arrayOf(PropTypes.string)
	}),
	actions: PropTypes.shape({
        sendCsvExportRequest: PropTypes.func.isRequired,
        loadFilteredVotesWithQnaByTopicId: PropTypes.func.isRequired,
        loadFilteredVotersByTopicId: PropTypes.func.isRequired,
        loadFilteredAnswersForQuestions: PropTypes.func.isRequired,
        loadFilteredAnswersForSingleQuestion: PropTypes.func.isRequired
	}).isRequired,
	
	questions: PropTypes.arrayOf(PropTypes.string)
};

TopicDetailsFeed.defaultProps = {
	voterFilters: {
		anonymous: true,
		facebook: true,
		email: true,
		latestPerVoter: false,
		sources: []
	}
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...feedActions }, dispatch)
    };
}

function mapStateToProps(state, ownProps) {	
	const qnaDetailsForTopic = state.qnaForTopics[ownProps.params.id];
	let questions = [];

	if (qnaDetailsForTopic && qnaDetailsForTopic.questionDetails) {
		questions = qnaDetailsForTopic.questionDetails.map(q => q.question);
	}

	return {
		questions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicDetailsFeed);