import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as topicActions from '../../actions/topicActions';
import { bindActionCreators } from 'redux';
import TopicMediaEmbed from '../../components/topics/topicMediaEmbed';
import { isEmpty } from 'lodash';

export default (isReduxConnected = true) => {
	const TopicMedia = ({ topicId, topicBasicSettings, topicLinkConfig, loadTopicStructureById }) => {
		const [topicStructureLoaded, setTopicStructureLoaded] = useState(false);
		const linkSources = topicLinkConfig ? topicLinkConfig.sources : [];
		useEffect(() => {
			if (isEmpty(topicBasicSettings)) {
				loadTopicStructureById(topicId);
			} else {
				setTopicStructureLoaded(true);
			}
		}, [topicBasicSettings]);
		return topicStructureLoaded ? (
			<TopicMediaEmbed
				topicName={topicBasicSettings.title}
				topicDisplayName={topicBasicSettings.displayName}
				linkSources={linkSources}
			/>
		) : (
			<></>
		);
	};

	TopicMedia.propTypes = {
		/* @prop {string} topicId - an id of a topic. Required **/
		topicId: PropTypes.string.isRequired,
		/* @prop {object} topicBasicSettings - represents basicSettings of a topic. Optional **/
		topicBasicSettings: PropTypes.shape({title: PropTypes.string, displayName: PropTypes.string}),
		/* @prop {object} linkConfig - represents linkConfig of a topic. Optional **/
		linkConfig: PropTypes.shape({sources: PropTypes.array}),
		/* @prop {func} loadTopicStructureById - action to call when the topic structure hadn't been loaded. Optional **/
		loadTopicStructureById: PropTypes.func
	};

	function mapStateToProps(state, ownProps) {
		const topic = state.topics.find(topicElem => topicElem.id === ownProps.topicId);
		return { topicBasicSettings: topic.basicSettings, topicLinkConfig: topic.linkConfig };
	}
	function mapDispatchToProps(dispatch) {
		const { loadTopicStructureById } = topicActions;
		return bindActionCreators(Object.assign({}, { loadTopicStructureById }), dispatch);
	}

	if (!isReduxConnected) {
		return TopicMedia;
	}
	return connect(mapStateToProps, mapDispatchToProps)(TopicMedia);
};
