import React from 'react';
import PropTypes from 'prop-types';
import { Image, ExternalLink } from 'react-feather';

import PopoverButton from '../../../components/common/popover/popoverButton';
import { isOnMobile } from '../../../helpers/utils';


const imagePreviewButton = ({ buttonText, previewTitle, imageTitle, imageLink, className }) => {
    const isMobile = isOnMobile();

    const getClassName = () => {
        return className;
    };

    const renderImagePreviewLink = (text) => {
        return <a href={imageLink} target="_blank" rel="noopener noreferrer" className={`btn btn-outline-secondary ${getClassName()} uitest-preview-link-mobile`}><Image size="22" /></a>;
    };
    
    const renderImagePreviewPopover = () => {
        return <PopoverButton 
            text={buttonText} 
            className={`btn btn-xs btn-default ${getClassName()} uitest-preview-popover-mobile`}
            title={previewTitle}
            position="left"
            popoverWidth={640}
        >
            <div className="answer-attachment-preview"><img src={imageLink} alt={previewTitle} /></div>
            <p className="text-center message">{ <a href={imageLink} target="_blank" rel="noopener noreferrer"><ExternalLink size="14" /> open in a new window</a> }</p>
            <p className="message">{imageTitle}</p>
        </PopoverButton>;
    };

    return isMobile ? 
        renderImagePreviewLink()
        :
        renderImagePreviewPopover();
        
};

imagePreviewButton.propTypes = {
	/** @prop {any} text the button label text */
    buttonText: PropTypes.any, 

	/** @prop {string} previewTitle the popover title text */
    previewTitle: PropTypes.string,

	/** @prop {string} imageTitle the image label text inside popover container */
    imageTitle: PropTypes.string, 
    
    /** @prop {string} imageLink the src of the image */
    imageLink: PropTypes.string, 

    className: PropTypes.string,
};

imagePreviewButton.defaultProps = {
    buttonText: 'image preview', 
    previewTitle: '',
    imageTitle: '',
    imageLink: '', 
};

export default imagePreviewButton;