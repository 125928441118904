/* eslint-disable no-console */
const DEBUG_MESSAGE_STYLE = 'background: #555; color: #bada55';
const INFO_MESSAGE_STYLE = 'background: #555; color: #6691ff';

let isAllowedLogEnv = false; 

/* used for logging messages to the browser console. 
Only the 'info' method is allowed by default on production (the rest have to be specifically configured) */
class Logger{
    static debug(message){
        if(isAllowedLogEnv){
            console.log(message);
        }
    }

    static warn(message){
        if(isAllowedLogEnv){
            console.warn(`${message}`);
        }
    }

    static error(message){
        if(isAllowedLogEnv){
            console.error(`${message}`);
        }
    }

    // use for displaying critical errors in prod
    static critical(message){
        console.error(`${message}`);
    }

    static info(message){
        console.info(`%c ${message}`, INFO_MESSAGE_STYLE);
    }

    static logContent(message, content){
        if(isAllowedLogEnv){
            console.log(`%c ${message}`, DEBUG_MESSAGE_STYLE);
            console.log(`Content: ${content}`);
        }        
    }
    
    static setup(){
        isAllowedLogEnv = process.env.REACT_APP_ALLOWDEBUG || process.env.REACT_APP_DEPLOY_ENV !== 'production';
    }
}

export default Logger;