import * as types from '../actions/actionTypes';

// generates a state slice:
// notification = { message, level, autoDismiss }
export default function notification(state = {}, action) {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return Object.assign({}, state, {
        message: action.message,
        level: action.level,
        autoDismiss: action.autoDismiss,
      });

    default:
      return state;
  }
}