import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, uniqBy } from 'lodash';
import { Field, FieldArray, ErrorMessage } from 'formik';
import AutoComplete from '../common/form/autocomplete';
import * as Icons from 'react-feather';
import RangeSlider from '../common/form/rangeSliderSelector';
import ServerErrorMessage from '../common/serverErrorMessage';

import './css/topicBuilder.css';
import './css/topicConfiguration.css';
import Card from '../common/card';
import BSFormControl from '../common/form/bsFormControl';
import ButtonBarInput from '../common/form/buttonBarInput';
import { Toggle } from '../common/form/toggle';
import AutosuggestTagsSelector from '../common/form/autosuggestTagsSelector';
import VoteRangeLabel from './common/voteRangeLabel';

import McChannelSelector from './topicConfiguration/mcChannelSelector';
import { createTopicHash } from '../../helpers/utils';
import { getSliderMarks, DEFAULT_ALERT_TEMPLATE } from '../../helpers/topicBuilderHelper';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import formValidators from '../../helpers/formValidators';
import { VOTE_ALERTS_INTERVALS, VOTE_ALERTS_MAX_COUNT } from '../../actions/models/entityModel';
import { PERMISSIONS } from '../../helpers/permissionsHelper';
import PermissionsContext from '../../scenes/contexts/permissionsContext';
import FeaturesContext from '../../scenes/contexts/featuresContext';
import { COMPARATORS } from '../../helpers/featuresHelper';
import { ENTITY_FEATURES } from 'hollerlive-shared/constants';
import { useTranslation } from 'react-i18next';

const TopicConfiguration = (
	{
		backendErrors,
		values,
		errors,
		setValues,
		setFieldValue,
		setFieldTouched,
		topics,
		entity
	},
	{ showModal, hideModal }
) => {
	const { basicSettings, config } = values;
	const [hasFailedAttemptToDisableAlerts, setHasFailedAttemptToDisableAlerts] = useState(false);

	const topicIsRoot = !config.parent || !config.parent.parentTopicId;
	const enableAlertsWarning = config.alertsActive && !entity.voteNotifActive;

	const { t } = useTranslation();

	const enableAlertsValidationWarning =
		hasFailedAttemptToDisableAlerts &&
		errors.config &&
		errors.config.alerts.length > 0;

	const marks = getSliderMarks();

	function updateValuesState(newConfigState) {
		const newValuesState = Object.assign({}, values, {
			config: newConfigState
		});
		setValues(newValuesState);
	}

	function fieldHasErrors(field) {
		return !isEmpty(backendErrors)
			? formValidators.fieldHasErrors(backendErrors, field)
			: formValidators.fieldHasErrors(errors, field);
	}

	function setParentTopicIdandName(topicId) {
		const parentTopic = topics.find(topic => {
			return topic.id.toString() === topicId;
		});
		const topicName = parentTopic.name;
		const newParentState = {
			parentTopicId: topicId,
			parentTopicName: topicName
		};

		updateValuesState(Object.assign({}, config, { parent: newParentState }));
	}

	function handleToggleAlerts(shouldEnableAlert) {
		let updatedConfig;
		if (shouldEnableAlert) {
			setHasFailedAttemptToDisableAlerts(false);
		}
		if (errors.config && errors.config.alerts.length > 0) {
			if (!shouldEnableAlert) {
				setHasFailedAttemptToDisableAlerts(true);
			}
			updatedConfig = Object.assign({}, config, {
				alertsActive: true,
				alerts: config.alerts.length > 0 ? config.alerts : [getNewAlert()]
			});
		} else {
			updatedConfig = Object.assign({}, config, {
				alertsActive: shouldEnableAlert,
				alerts: config.alerts.length > 0 ? config.alerts : [getNewAlert()]
			});
		}
		updateValuesState(updatedConfig || {});
	}

	function handleAddAlert() {
		const newAlert = getNewAlert();

		const newAlerts = [...config.alerts, newAlert];
		updateValuesState(Object.assign({}, config, { alerts: newAlerts }));
		newAlerts.forEach((alert, index) => {
			setFieldTouched(`config.alerts.${index}.range`);
		});
	}

	function getNewAlert() {
		return Object.assign({}, DEFAULT_ALERT_TEMPLATE, {
			emails: entity.notifEmails
				? entity.notifEmails.split(",").map(email => email.trim())
				: DEFAULT_ALERT_TEMPLATE.emails,
			interval: entity.voteNotifInterval
				? entity.voteNotifInterval
				: DEFAULT_ALERT_TEMPLATE.interval
		});
	}

	function handleEmailsSelect(selectedEmails, fieldPathEmails, fieldPathRange) {
		const emailValues = selectedEmails.map(email => email.name);

		setFieldTouched(fieldPathRange);
		setFieldTouched(fieldPathEmails);

		setFieldValue(fieldPathEmails, emailValues);
	}

	function getPredefinedAlertsButtons() {
		const btns = [];
		VOTE_ALERTS_INTERVALS.forEach(interval => {
			let name;
			switch (interval) {
				case 60:
					name = t('topic_builder_alerts_interval_hourly');
					break;
				case 1440:
					name = t('topic_builder_alerts_interval_daily');
					break;
				case 10080:
					name = t('topic_builder_alerts_interval_weekly');
					break;
				default:
					name = t('topic_builder_alerts_interval_n_mins', { interval });
			}

			btns.push({ name, value: interval });
		});
		return btns;
	}

	function handleSetAlertInterval(field, value) {
		const interval = parseInt(value);
		setFieldValue(field, interval || "");
		setFieldTouched(field);
	}

	function handleConfirmDeleteAlert(index) {
		if (
			!config.alerts[index].emails ||
			config.alerts[index].emails.length === 0
		) {
			handleDeleteAlert(index);
		} else {
			const modalConfig = {
				title: t('modal_alert_delete_title'),
				message: t('modal_alert_delete_message', { alertIndex: index + 1 }),
				onClose: hideModal,
				onConfirm: () => handleDeleteAlert(index)
			};

			showModal(CONFIRM_MODAL, modalConfig);
		}
	}

	function handleDeleteAlert(index) {
		hideModal();

		const newAlerts = [...config.alerts];
		newAlerts.splice(index, 1);
		const hasActiveAlerts =
			newAlerts.length === 0 ? false : config.alertsActive;

		updateValuesState(
			Object.assign({}, config, {
				alerts: [...newAlerts],
				alertsActive: hasActiveAlerts
			})
		);
	}

	function getAutosuggestEmails() {
		const emailsList = [];
		let generatedEmailId = 1;

		config.alerts.forEach(alert => {
			if (alert.emails) {
				alert.emails.forEach(email => {
					emailsList.push({ id: generatedEmailId++, name: email });
				});
			}
		});
		return uniqBy(emailsList, "name");
	}

	function renderAlertsForTopic() {
		return config.alerts.map((alert, index) => {
			const fieldPath = `config.alerts.${index}`;

			const selectionEmailObj = alert.emails
				? alert.emails.map((email, indexE) => {
					return { id: indexE, name: email };
				})
				: [];
			const topicEmailsList = getAutosuggestEmails();

			return (
				<div key={index} className="uitest-alert">
					<Card title={`${t('topic_builder_alerts_alert')} ${index + 1}`}>
						<div className="clearfix delete-alert-button">
							<button
								type="button"
								className="btn btn-xs btn-outline btn-danger pull-right uitest-btn-delete"
								onClick={() => handleConfirmDeleteAlert(index)}
							>
								<Icons.X size={12} /> {t('topic_builder_alerts_delete')}
              				</button>
						</div>

						<BSFormControl
							className="uitest-alert-range alert-item-range"
							hasErrors={fieldHasErrors(`${fieldPath}.range`)}
						>
							<h5 className="label-description text-uppercase">
								{t('topic_builder_alerts_send_email')}{" "}
								{alert.range ? (
									<VoteRangeLabel low={alert.range[0]} high={alert.range[1]} />
								) : (
										""
									)}
							</h5>
							&nbsp;&nbsp;
              				<Field name={`${fieldPath}.range`} value={fieldPath.range}>
								{({ field }) => (
									<RangeSlider
										values={field.value}
										marks={marks}
										dots
										min={0}
										max={10}
										onAfterChange={selectedRange => {
											setFieldTouched(field.name);
											setFieldValue(field.name, selectedRange);
										}}
									/>
								)}
							</Field>
							<ErrorMessage
								name={`${fieldPath}.range`}
								component="div"
								className="control-label"
							/>
							<ServerErrorMessage
								errors={backendErrors}
								name={`${fieldPath}.range`}
								className="control-label"
							/>
						</BSFormControl>

						<div className="row-fluid clearfix uitest-alert-emails alert-item-emails">
							<BSFormControl
								label={t('topic_builder_alerts_emails_label')}
								subLabel={t('topic_builder_alerts_emails_sublabel')}
								hasErrors={fieldHasErrors(`${fieldPath}.emails`)}
							>
								<AutosuggestTagsSelector
									name={`${fieldPath}.emails`}
									value={selectionEmailObj}
									data={topicEmailsList}
									onChange={selectedEmails =>
										handleEmailsSelect(
											selectedEmails,
											`${fieldPath}.emails`,
											`${fieldPath}.range`
										)
									}
									className={`form-control`}
								/>
								<ErrorMessage
									name={`${fieldPath}.emails`}
									component="div"
									className="control-label"
								/>
							</BSFormControl>
						</div>

						<div className="uitest-alert-interval alert-item-interval">
							<BSFormControl
								subLabel={t('topic_builder_alerts_interval_sublabel')}
								hasErrors={fieldHasErrors(`${fieldPath}.interval`)}
							>
								<div className="row-fluid clearfix">
									<Field name={`${fieldPath}.interval`}>
										{({ field }) => (
											<ButtonBarInput
												value={field.value}
												type="number"
												data={getPredefinedAlertsButtons()}
												onValueChange={val =>
													handleSetAlertInterval(`${fieldPath}.interval`, val)
												}
												showCustomInput
											/>
										)}
									</Field>

									<ErrorMessage
										name={`${fieldPath}.interval`}
										component="div"
										className="control-label"
									/>
								</div>
							</BSFormControl>
						</div>
					</Card>
				</div>
			);
		});
	}

	function getParentTopicCard(nonFreeOverlay) {
		return (
			<Card title={t('topic_builder_parent_topic_title')} nonFreeOverlay={nonFreeOverlay}>
				{topicIsRoot && (
					<p className="message">
						<span className="text-label text-success uitest-root">
							&nbsp;&nbsp;
              <Icons.Layers size={24} /> {t('topic_builder_parent_topic_root')}
            </span>
					</p>
				)}
				{!topicIsRoot && (
					<BSFormControl
						label={t('topic_builder_parent_topic_selected')}
						hasErrors={fieldHasErrors("config.parent.parentTopicId")}
					>
						<Field
							name="config.parent.parentTopicId"
							value={config.parent.parentTopicId}
						>
							{({ field }) => (
								<AutoComplete
									value={field.value || ""}
									placeholder={t('topic_builder_parent_topic_placeholder')}
									className="form-control"
									data={topics || []}
									onChange={topicId => {
										setParentTopicIdandName(topicId);
									}}
								/>
							)}
						</Field>
						<ServerErrorMessage
							errors={backendErrors}
							name="config.parent.parentTopicId"
							className="control-label"
						/>
					</BSFormControl>
				)}
			</Card>
		);
	}

    // currently we only allow the addition of a single next topic
    function addNextTopicToChain(field, topicId) {
        const newtopicsChain = [];
        const nextTopic = topics.find(t => t.id === topicId);
        newtopicsChain[0] = createTopicHash(nextTopic.name);
        setFieldValue(field, newtopicsChain);
    }

    function handleDeleteTopicsChain() {
        setFieldValue('config.topicsChain', []);
    }

	function getTopicChainCard(nonFreeOverlay) {
        const nextTopicHash = config.topicsChain && config.topicsChain.length > 0 ? config.topicsChain[0] : "";
        const matchingTopic = topics.find(t => createTopicHash(t.name) === nextTopicHash);
        const nextTopicId = !isEmpty(matchingTopic) ? matchingTopic.id : null;
        const nextTopicMissing = !isEmpty(nextTopicHash) && isEmpty(nextTopicId);
        const nextTopicsList = (topics || []).filter(t => t.id !== basicSettings.id);
		return (
			<Card title={t('topic_builder_next_topics_title')} nonFreeOverlay={nonFreeOverlay}>
				<BSFormControl
					label={t('topic_builder_next_topics_label')}
					className="ordered-form-fields"
					hasErrors={fieldHasErrors("config.topicsChain")}
				>
					<div className={`${!isEmpty(nextTopicHash) ? "with-action" : ""} ordered-form-fields-item`}>
						<div className="number">{1}</div>
						<div className="form-control-container">
							<Field name="config.topicsChain" value={nextTopicId}>
                                {({ field }) => (
                                    <AutoComplete
                                        value={nextTopicId}
                                        placeholder={nextTopicMissing ? nextTopicHash : t('topic_builder_next_topics_placeholder')}
                                        className={!isEmpty(nextTopicId) ? 'selector-disabled' : ''}
                                        disabled={!isEmpty(nextTopicId)}
                                        data={nextTopicsList}
                                        onChange={topicId => addNextTopicToChain(field.name, topicId)}
                                    />
                                )}
							</Field>
                            {nextTopicMissing && (
                                <p className='message bg-warning'>
                                    <Icons.AlertTriangle size={14} />
                                    &nbsp;{t('topic_builder_next_topics_not_exist')}
                                </p>
                            )}
						</div>
						{!isEmpty(nextTopicHash) && (
							<span className="action">
								<Icons.XCircle onClick={handleDeleteTopicsChain} className="text-danger" />
							</span>
						)}
					</div>
					<ServerErrorMessage errors={backendErrors} name="config.topicsChain" className="control-label" />
				</BSFormControl>
			</Card>
		);
	}

	function getAlertsToggle(nonFree) {
		return (
			<h4>
				<Field name="config.alertsActive" value={config.alertsActive}>
					{({ field }) => {
						return (
							<Toggle value={field.value} onChange={value => handleToggleAlerts(value)} disabled={nonFree} />
						);
					}}
				</Field>
				<label className={`${nonFree ? "text-muted" : ""}`} htmlFor="alert">
					{t('topic_builder_alerts_title')}{" "}
					{nonFree ? (
						<small className="text-info text-label">
							<Icons.Star size="16" /> {t('topic_builder_alerts_unavailable')}
            </small>
					) : (
							<span />
						)}
				</label>
			</h4>
		);
	}

	return (
		<div className="topic-configuration">
			<div className="row clearfix uitest-topic-config">
				<div className="col-md-12 col-sm-12 uitest-parent-topic">
					<FeaturesContext
						requiredFeatures={[{
							name: ENTITY_FEATURES.HAS_TOPIC_ALERTS,
							compare: COMPARATORS.IS_TRUE,
						}]}
						renderOtherwise={getParentTopicCard(true)}
					>
						{getParentTopicCard(false)}
					</FeaturesContext>
				</div>

				<div className="col-md-12 col-sm-12 uitest-channels">
					<PermissionsContext requiredPermissions={[PERMISSIONS.ADMIN]} hideIfNoPermission={true}>
						<Card title={t('topic_builder_channels_title')}>
							<BSFormControl
								label={t('topic_builder_channels_label')}
								hasErrors={fieldHasErrors("config.channels")}
							>
								<Field name="config.channels" value={config.channels}>
									{({ field }) => (
										<div>
											<div className="channel-field-wrapper">
												{basicSettings.isPrivate && (
													<div className="text-warning channel-field-private-mark-wrapper">
														<Icons.AlertTriangle />
													</div>
												)}
												<span className="channel-field-selector">
													<McChannelSelector
														selectedChannels={field.value}
														onChange={selectedChannels =>
															setFieldValue(field.name, selectedChannels)
														}
													/>
												</span>
											</div>
											{basicSettings.isPrivate && (
												<div className="channel-field-private-text-wrapper">
													<label className="text-warning">
														{t('topic_builder_channels_private_warning')}
													</label>
												</div>
											)}
										</div>
									)}
								</Field>
								<ServerErrorMessage
									errors={backendErrors}
									name="config.channels"
									className="control-label"
								/>
							</BSFormControl>
						</Card>
					</PermissionsContext>
				</div>

				<div className="col-md-12  col-sm-12 uitest-next-topics">
					<FeaturesContext
						requiredFeatures={[{
							name: ENTITY_FEATURES.HAS_TOPIC_ALERTS,
							compare: COMPARATORS.IS_TRUE,
						}]}
						renderOtherwise={getTopicChainCard(true)}
					>
						{getTopicChainCard(false)}
					</FeaturesContext>
				</div>

				<div className="col-md-12 col-sm-12 config-alerts-container">
					<BSFormControl
						className={`uitest-alerts config-alerts-inner-container ${
							config.alertsActive
								? "white-background-color"
								: "transparent-background-color"
							}`}
					>
						<FeaturesContext
							requiredFeatures={[{
								name: ENTITY_FEATURES.HAS_TOPIC_ALERTS,
								compare: COMPARATORS.IS_TRUE,
							}]}		
							renderOtherwise={getAlertsToggle(true)}
						>
							{getAlertsToggle(false)}
						</FeaturesContext>
						{enableAlertsWarning && (
							<p className="message bg-warning uitest-alerts-warning">
								<Icons.AlertTriangle size={14} /> {t('topic_builder_alerts_turned_off')}
              			</p>
						)}
						{enableAlertsValidationWarning && (
							<p className="message bg-warning uitest-alerts-warning">
								<Icons.AlertTriangle size={14} /> {t('topic_builder_alerts_invalid')}
              			</p>
						)}
						{config.alertsActive && (
							<FieldArray
								name="config.alerts"
								render={() => (
									<div className="row-fluid clearfix">
										{renderAlertsForTopic()}

										<Card>
											{config.alerts &&
												config.alerts.length >= VOTE_ALERTS_MAX_COUNT ? (
													<p className="message bg-warning uitest-alerts-count-warning">
														<Icons.AlertTriangle size={14} /> {t('topic_builder_alerts_maximum_reached')}
                        							</p>
												) : (
													<button
														className="btn btn-dotted btn-outline btn-success btn-full-width uitest-btn-create-alert"
														onClick={handleAddAlert}
														type="button"
													>
														<Icons.PlusCircle size={16} /> {t('topic_builder_alerts_create_new')}
                        							</button>
												)}
										</Card>
									</div>
								)}
							/>
						)}
					</BSFormControl>
				</div>
			</div>
		</div>
	);
};

TopicConfiguration.propTypes = {
	/**
	 * @param {Object} config the data for the topic config step
	 */
	values: PropTypes.shape({
		config: PropTypes.shape({
			parent: PropTypes.shape({
				parentTopicId: PropTypes.string,
				parentTopicName: PropTypes.string
			}),
			channels: PropTypes.arrayOf(PropTypes.string),
			topicsChain: PropTypes.arrayOf(PropTypes.string),
			alerts: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					range: PropTypes.arrayOf(PropTypes.number),
					emails: PropTypes.arrayOf(PropTypes.string),
					interval: PropTypes.number
				})
			),
			alertsActive: PropTypes.bool
		})
	}).isRequired,
	backendErrors: PropTypes.shape({
		config: PropTypes.object
	}),
	errors: PropTypes.shape({
		config: PropTypes.object
	}),
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	setValues: PropTypes.func.isRequired,
	topics: PropTypes.array.isRequired,
	entity: PropTypes.shape({
		features: PropTypes.shape({
			hasTopicAlerts: PropTypes.bool
		}).isRequired
	}).isRequired
};

TopicConfiguration.contextTypes = {
	showModal: PropTypes.func,
	hideModal: PropTypes.func
};

export default TopicConfiguration;
