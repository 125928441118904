import React from 'react';
import PropTypes from 'prop-types';
import styles from "./css/bsFormControl.module.css";

const BSFormControl = props => {
	const { 
		label, 
		subLabel, 
		children, 
		horizontal, 
		className, 
		required,
		hasErrors,
		labelColWidth,
		inline
	} = props;

	const isRequired = !!required;
	let isHorizontal = horizontal === undefined ? false : horizontal;
	const errorsClassName = hasErrors ? 'has-error' : '';
	const inlineClassName = inline ? styles.inline : '';
	let horizontalLabelClassName = labelColWidth ? `col-md-${labelColWidth}` : 'col-md-3';
	let horizontalControlClassName = labelColWidth ? `col-md-${12 - labelColWidth}` : 'col-md-9';

	return (
		<div className={`form-group ${styles.bsFormControl} ${inlineClassName} ${className} ${errorsClassName} ${isHorizontal ? 'row-fluid horizontal clearfix' : ''}`}>
			{label !== undefined &&
				<label className={`control-label ${isHorizontal ? horizontalLabelClassName : ''}`}>
					{label}
					{isRequired && <span className="text-danger"> *</span>}
				</label>
			}
			<div className={`control-field ${isHorizontal ? horizontalControlClassName : ''}`}>
				{subLabel !== undefined &&
					<label className="sub-label">{subLabel}</label>
				}
				{children}
			</div>
		</div>
	);
};

BSFormControl.propTypes = {
	/** @param {string} label optional. Default false. */
	label: PropTypes.string,

	/** @param {string} subLabel optional. The secondary label */
	subLabel: PropTypes.string,

	/** @param {any} children component yield */
	children: PropTypes.any,

	/** @param {bool} horizontal indicate label+form control horizontal or one above the other */
	horizontal: PropTypes.bool,

	/** @param {bool} required indicates if form control is marked as required with an asterix after title */
	required: PropTypes.bool,

	/** @param {number} labelColWidth the bootstrap grid col-md index to be used for label widht.
	 * if form is horizontal, the field width will be calculated based on (12 - labelColWidth)
	 */
	labelColWidth: PropTypes.number,

	className: PropTypes.string,

	/** @prop {bool} inline displays the bsFormControl as inline-block element */
	inline: PropTypes.bool
};

BSFormControl.defaultProps = {
	inline: false
};

export default BSFormControl;