import React from 'react';
import PropTypes from 'prop-types';
import { XSquare } from 'react-feather';

import './css/sidePanel.css';

const sidePanel = props => {
	const { 
		title,
		children,
		onClose,
		onOk,
		className,
		okButtonClassName, 
		okButtonType, 
		okButtonText, 
		okButtonDisabled,
		cancelButtonText,
		cancelButtonClassName,
		showButtons
	} = props;
	const okBtnClassName = `btn uitest-sidepanel-ok-btn ${okButtonDisabled ? 'disabled' : ''} ${okButtonClassName ? okButtonClassName : 'btn-primary'}`;
	const okBtnType = `${okButtonType ? okButtonType : 'button'}`;
	const okBtnText = `${okButtonText ? okButtonText : 'Ok'}`;
	const cancelBtnText = `${cancelButtonText ? cancelButtonText : 'Close'}`;
	const cancelBtnClassName = `btn btn-outline ${cancelButtonClassName} uitest-panel-cancel-button`;

	return (
		<div className={`side-panel-container ${className}`}>
			<div className="side-panel uitest-side-panel">
				<h5 className="page-heading">
					{ title }
					<div className="btn-toolbar pull-right no-float clearfix">
						<button type="button" className="btn uitest-close-button transparent" onClick={onClose}><XSquare size={24} /></button>
					</div>
				</h5>

				<div className={`side-panel-content ${showButtons ? 'btn-toolbar-visible' : ""}`}>
					{ children } 
				</div>

				{showButtons &&
					<div className="btn-toolbar footer-toolbar no-float">
						<button 
							type={okBtnType} 
							disabled={okButtonDisabled} 
							className={okBtnClassName}
							onClick={okBtnType === 'submit' ? () => {} : onOk}
						>
							{okBtnText}
						</button>

						<button onClick={onClose} type="button" className={cancelBtnClassName}>{cancelBtnText}</button>
					</div>
				}
			</div>
		</div>
	);
};

sidePanel.propTypes = {
	/** @prop {string} title the title of the side panel */
	title: PropTypes.string,

	/** @prop {string} className additional css class names */
	className: PropTypes.string,

	/** @prop {array} children content yield */
	children: PropTypes.any.isRequired,

	/** @prop {func} onClose action handler for pushing the close panel button */
	onClose: PropTypes.func.isRequired,

	/** @prop {bool} showButtons if set to true, shows footer Ok and Cancel buttons toolbar
	 * Default to false
	 */
	showButtons: PropTypes.bool,

	/** @prop {string} okBtnClassName the classname of the Ok btn
	 * Default to "btn-default"
	 */
	okButtonClassName: PropTypes.string,

	/** @prop {string} okButtonText the text for the Ok button. Default to "Ok" */
	okButtonText: PropTypes.string,

	/** @prop {string} okButtonType the type for the Ok button. Can be "button" or "submit".
	 * Default to "button"
	 */
	okButtonType: PropTypes.string,

	/** @prop {bool} okButtonDisabled disable the Ok button. Defaults to false */
	okButtonDisabled: PropTypes.bool,

	/** @prop {func} onOk the Ok button click handler */
	onOk: PropTypes.func,

	/** @prop {string} cancelButtonText the text for the Cancel button. Default to "Close" */
	cancelButtonText: PropTypes.string,

	cancelButtonClassName: PropTypes.string
};

export default sidePanel;