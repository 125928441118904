import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Messages from '../../../../../helpers/messages';
import { Clock, Image } from 'react-feather';

import VoteTimelineItem from '../../../../votes/timeline/VoteTimelineItem';
import ImagePreviewButton from '../../imagePreviewButton';
import { useTranslation } from 'react-i18next';

const groupByQuestionItem = ({ answerText, timeToAnswerMs, voteData, attachedImageLink, question }) => {
	const voteTimeLineData = {
		date: voteData.date,
		city: voteData.location.city,
		country: voteData.location.countryCode,
		vote: voteData.vote,	
		gender: voteData.gender,
		ageGroup: voteData.ageGroup,
		sources: voteData.sources || []
	};
	const timeToVote = moment.duration(timeToAnswerMs).asSeconds().toFixed(2);
    const hasAttachedImage = attachedImageLink && attachedImageLink.length > 0;
	const hasAnswerText = (answerText && answerText.length > 0) || (attachedImageLink && attachedImageLink.length > 0);

	const { t } = useTranslation();

	return (
		<div className="group-item group-question-item uitest-group-item">
			<VoteTimelineItem vote={voteTimeLineData} />

			<div className={`question-item-answer-text uitest-vote-answer-text ${hasAttachedImage ? 'has-attached-image' : ''}`}>
				<p>
				{hasAnswerText ?
					<React.Fragment><span className="text-light">{t('topic_vote_timeline_answered_with')}</span> <span className="text-black">{ answerText }</span></React.Fragment>
				:
					<small className="text-left text-muted text-label">{Messages.topics.noAnswerText}</small>
				}
				</p>
					
				{hasAttachedImage && 
				<ImagePreviewButton 
					buttonText = {<span><Image size="14" /> {t('topic_vote_timeline_image_preview')}</span>}
					imageLink = {attachedImageLink}
					previewTitle = {`${question} answer preview`}
					imageTitle = {answerText}
					className="pull-right uitest-image-preview-btn"
				/>}
			</div>

			<small className="meta text-label text-right text-light">
				<Clock size={12} /> <span className="uitest-time-spent">{timeToVote}</span> <small>{t('topic_vote_timeline_sec')}</small>
			</small>
		</div>
	);
};

groupByQuestionItem.propTypes = {
	answerText: PropTypes.string,
	timeToAnswerMs: PropTypes.number.isRequired,
	attachedImageLink: PropTypes.string,
	question: PropTypes.string,
	voteData: PropTypes.shape({
		date: PropTypes.string.isRequired,
		vote: PropTypes.number.isRequired,
		gender: PropTypes.string,
		ageGroup: PropTypes.string,
		location: PropTypes.shape({
			city: PropTypes.string,
			countryCode: PropTypes.string
		})
	})
};

export default groupByQuestionItem;