import React from 'react';
import PropTypes from 'prop-types';
import './css/buttonBarInput.css';
import { useTranslation } from 'react-i18next';

const ButtonBarInput = ({ value, type, data, onValueChange, showCustomInput, disableCustom }) => {

	const { t } = useTranslation();

    const handleButtonInput = (dataItem) => {
        if(!dataItem.disabled) {
            onValueChange(dataItem.value);
        }
    };
    const handleCustomInput = (value) => {
        if(!disableCustom) {
            onValueChange(value);
        }
    };
    return (
		<div className='row-fluid clearfix button-bar-input'>
			<div className='buttons-bar btn-group pull-left'>
				{data.map((dataItem, index) => (
					<button
						type='button'
						key={index + 1}
						className={
							'btn btn-outline uitest-bar-button' +
							(dataItem.value === value ? ' selected-button' : ' unselected-button')
						}
						onClick={() => handleButtonInput(dataItem)}
						disabled={dataItem.disabled}
					>
						{dataItem.name}
					</button>
				))}
			</div>
			{showCustomInput && (
				<div className='pull-left row-fluid uitest-custominput-container'>
					<label className='custom-value-prefix text-label text-light pull-left'>{t('topic_builder_alerts_interval_or_custom')}</label>
					<span className='pull-left'>
						<input
							className='form-control uitest-custominput'
							type={type || 'text'}
							autcomplete='off'
							value={value}
							onChange={event => handleCustomInput(event.target.value)}
							disabled={disableCustom}
						/>
					</span>
				</div>
			)}
		</div>
	);
};

ButtonBarInput.propTypes = {
    /** @param {object} value - the value that one of the buttons reflects and/or the input tag(if shown) is populated with */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /** @param {string} type - the type of the input-tag value. defaults to 'text' */
    type: PropTypes.oneOf(['text', 'number']),
    /** @param {arrayOf} data - collection of button definition values in the shape of {name, value, disabled} */
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            disabled: PropTypes.bool
        })
    ).isRequired,
    /** @param {func} onValueChange - value change callback */
    onValueChange: PropTypes.func.isRequired,
    /** @param {bool} showCustomInput - show/hide the custom value. Defaults to false */
    showCustomInput: PropTypes.bool,
    /** @prop {bool} disables the bar custom input */
    disableCustom: PropTypes.bool
};

export default ButtonBarInput;
