import React from 'react';
import PropTypes from 'prop-types';
import './css/progressBar.css';
import * as Icons from 'react-feather';
import { useTranslation } from 'react-i18next';

const ProgressStep = (props) => {
    const stepClassName = `${props.status} ${props.active ? 'active' : 'inactive'}`;
    const { t } = useTranslation();
    return (
        <div className={`stepContainer ${stepClassName}`} onClick={() => {props.goTo(props.index);}} >
            <div className={`stepIndex ${stepClassName}`}>
                {props.status.indexOf("done") === -1 ? (
                    <span>{props.index + 1}</span>
                ) : (
                    <Icons.Check size={11} />
                )}
            </div>
            <div className={`${props.required ? "required" : ""} stepTitle ${stepClassName}`}>
                {t(props.title)}
                &nbsp;<span className="warn-sign"><Icons.AlertTriangle size={16} /></span>
            </div>
            <div className="stepDescription">{t(props.description)}</div>
        </div>
    );
};

ProgressStep.propTypes = {
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    goTo: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired
};

export default ProgressStep;