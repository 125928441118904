import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
	EMPTY_QUESTION_TEMPLATE, 
	EMPTY_ANSWER_TEMPLATE
} from '../../helpers/topicBuilderHelper';
import { FieldArray } from 'formik';

import Card from '../common/card';
import { Minus, Square, PlusCircle } from 'react-feather';
import QuestionItem from './qna/questionItem';
import { cloneDeep } from 'lodash';
import i18n from '../../i18n';

class QuestionsAndAnswers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isCollapseAll: false,
			focusedQuestionId: null,
			t: i18n.t.bind(i18n),
		};

		this.addQuestion = this.addQuestion.bind(this);
		this.cloneQuestion = this.cloneQuestion.bind(this);
		this.deleteQuestion = this.deleteQuestion.bind(this);
		this.onCollapseAll = this.onCollapseAll.bind(this);
		this.reorderQuestionById = this.reorderQuestionById.bind(this);
		this.handleQuestionValuesChange = this.handleQuestionValuesChange.bind(this);
		this.handleAddAnswerToQuestion = this.handleAddAnswerToQuestion.bind(this);
		this.handleRemoveAnswerFromQuestion = this.handleRemoveAnswerFromQuestion.bind(this);
		this.onFocusQuestionEnd = this.onFocusQuestionEnd.bind(this);
	}

	getChildContext() {
		return { 
			setFieldError: this.props.setFieldError,
			setFieldValue: this.props.setFieldValue,
			setValues: this.props.setValues
		};
	}

	onCollapseAll(){
		const willCollapseAll = !this.state.isCollapseAll; 
		this.setState({ isCollapseAll: willCollapseAll, newQuestionId: null });
	}

	handleQuestionValuesChange(updatedQuestion) {
		const { qna, translations } = this.props.values;
		const questionToBeUpdated = qna.find(q => Number(q.questionId) === Number(updatedQuestion.questionId));

		const updatedQuestions = qna.map(q => {
			const quest = q.questionId === updatedQuestion.questionId ? updatedQuestion : q;
			return quest;
		});

		const newStateProps = {
			qna: updatedQuestions
		};

		if (questionToBeUpdated.answerSctructure !== updatedQuestion.answerSctructure) {
			const answerContentField = updatedQuestion.answerSctructure === "open" ? "placeholderText" : "text";
			const updatedTranslations = {};

			const langs = translations ? Object.keys(translations) : [];
			langs.forEach(l => {			
				updatedTranslations[l] = Object.assign({}, translations[l], { 
					qna: Object.assign({}, translations[l].qna, {
						[updatedQuestion.questionId]: Object.assign(
							{}, 
							translations[l].qna[updatedQuestion.questionId], 
							{ 
								answers: { 
									[updatedQuestion.answers[0].answerId] : {
										[answerContentField]: "" 
									}
								} 
							},
							{
								answerSctructure: updatedQuestion.answerSctructure
							}
						)
					}) 
				});
			});
			newStateProps.translations = updatedTranslations;
		}

		const newValuesState = Object.assign({}, this.props.values, newStateProps);

		this.props.setValues(newValuesState);
	}

	handleAddAnswerToQuestion(answer, questionId) {
		const updatedQuestions = this.props.values.qna.map(q => {
			return q.questionId === questionId ? 
				Object.assign({}, q, { answers: [...q.answers, answer] }) 
				: q;
		});

		const updatedTranslations = Object.assign(this.props.values.translations);

		this.addAnswerToTranslations(updatedTranslations, answer, questionId);

		const newState = Object.assign({}, this.props.values, {
			qna: updatedQuestions,
			translations: updatedTranslations
		});

		this.props.setValues(newState);
	}

	handleRemoveAnswerFromQuestion(answerId, questionId) {
		const updatedQuestions = this.props.values.qna.map(q => {
			return q.questionId === questionId ? 
				Object.assign({}, q, { answers: q.answers.filter(a => a.answerId !== answerId) }) 
				: q;
		});

		const updatedTranslations = Object.assign(this.props.values.translations);
		
		this.removeAnswerFromTranslations(updatedTranslations, answerId, questionId);
		
		const newState = Object.assign({}, this.props.values, {
			qna: updatedQuestions,
			translations: updatedTranslations
		});

		this.props.setValues(newState);
	}

	get getButtonText() {
		const t = this.state.t;
		if (this.state.isCollapseAll) {
			return <div className='uitest-btngroup-qna'><Square size={14}/> {t('topic_builder_qna_expand_all')}</div>;
		}
		else {
			return <div className='uitest-btngroup-qna'><Minus size={14}/> {t('topic_builder_qna_collapse_all')}</div>;
		}
	}
	
	getSortedQuestionsByOrderIndex(questions) {
		const sortedQuestions = questions.map(q => Object.assign({}, q));
		sortedQuestions.sort((a, b) => a.orderIndex - b.orderIndex);

		return sortedQuestions;
	}

	addQuestion(){
		const newQuestion = this.getEmptyQuestionTemplate();
		
		const updatedTranslations = Object.assign({}, this.props.values.translations);
		const updatedQna = [...this.props.values.qna, newQuestion];

		this.addQuestionToTranslations(updatedTranslations, newQuestion);

		const newState = Object.assign({}, this.props.values, {
			qna: updatedQna,
			translations: updatedTranslations
		});

		this.props.setValues(newState);
	}

	addQuestionToTranslations(translations, question) {
		const langs = Object.keys(translations);
		langs.forEach(l => {
			if (translations[l].qna) {
				translations[l] = Object.assign({}, translations[l], { 
					qna: Object.assign({}, translations[l].qna, { 
						[question.questionId]: { 
							question: question.question 
						}
					})
				});
			} else {
				translations[l] = Object.assign({}, translations[l], { qna: {
					[question.questionId]: { question: question.question }
				}});
			}
		});
	}

	cloneQuestionTranslations(translations, fromQuestion, toQuestion) {
		const langs = translations ? Object.keys(translations) : [];
		langs.forEach(lang => {
			const answersTranslations = translations[lang].qna[fromQuestion.questionId].answers;
			translations[lang] = Object.assign({}, translations[lang], {
				qna: Object.assign({}, translations[lang].qna, {
					[toQuestion.questionId]: {
						question: "",
						answers: Object.assign({}, answersTranslations)
					}
				})
			});
		});
	}

	addAnswerToTranslations(translations, answer, questionId) {
		const langs = Object.keys(translations);

		langs.forEach(l => {
			const question = Object.assign({}, translations[l].qna[questionId]);

			if (question.answers) {
				question.answers = Object.assign({}, question.answers, { [answer.answerId]: { text: "" }});
			} else {
				question.answers = { [answer.answerId]: { text: "" } };
			}

			translations[l] = Object.assign({}, translations[l], { 
				qna: Object.assign({}, translations[l].qna, { 
					[questionId]: question
				})
			});
		});
	}

	cloneQuestion(index, cloneBelow = false) {
		let questions = this.props.values.qna;
		const translations = this.props.values.translations;

		const questionId = this.getNewQuestionId([...questions]);
		let orderIndex;
		const questionToDuplicate = questions[index];
		if (cloneBelow) {
			orderIndex = questionToDuplicate.orderIndex + 1;
		} else {
			orderIndex = this.getNewOrderIndex([...questions]);
		}

		const newQuestion = Object.assign(cloneDeep(questionToDuplicate), { questionId, orderIndex });
		if (questionToDuplicate.isJumpOnly) {
			newQuestion.isJumpOnly = false;
		}
		newQuestion.answers.forEach(answer => {
			if (answer.hasOwnProperty('jumpToQuestionEnabled')) {
				answer.jumpToQuestionEnabled = false;
			}
			if (answer.hasOwnProperty('nextQuestionId')) {
				answer.nextQuestionId = null;
			}
		});
		
		if (cloneBelow) {
			questions = [...questions].map(q => Object.assign({}, q));
			this.incrementNextOrderIndexes(orderIndex, questions);
		}
		
		const sortedQuestions = [...questions, newQuestion].sort((a, b) => a.orderIndex - b.orderIndex);
		
		const updatedTranslations = cloneDeep(translations);
		this.cloneQuestionTranslations(updatedTranslations, questionToDuplicate, newQuestion);
		const newState = Object.assign({}, this.props.values, { qna: sortedQuestions, translations: updatedTranslations });
		this.props.setValues(newState);
		this.setState({ focusedQuestionId: questionId });
	}

	deleteQuestion(index){
		const { qna } = this.props.values;
		const questionToDelete = qna[index];
		let updatedQuestions = qna.filter((q, i) => i !== index);

		updatedQuestions = this.resetJumpToSelections(questionToDelete, updatedQuestions);
		updatedQuestions = this.resetOrderIndex(updatedQuestions);
		const updatedTranslations = this.removeQuestionFromTranslations(questionToDelete.questionId);

		const newFormValuesState = Object.assign({}, this.props.values, { 
			qna: updatedQuestions,
			translations: updatedTranslations
		});
		this.props.setValues(newFormValuesState);
	}

	removeQuestionFromTranslations(questionId) {
		const { translations } = this.props.values;
		const updatedTranslations = Object.assign({}, translations);
		const langs = translations ? Object.keys(translations) : [];

		langs.forEach(l => {
			if (translations[l].qna) {
				const qIds = Object.keys(translations[l].qna);
				const updatedQuestionTranslations = {};
				qIds.forEach(id => {
					if (Number(id) !== Number(questionId)) {
						updatedQuestionTranslations[id] = translations[l].qna[id];
					}
				});

				updatedTranslations[l] = Object.assign({}, updatedTranslations[l], { qna: updatedQuestionTranslations });
			}
		});

		return updatedTranslations;
	}

	removeAnswerFromTranslations(translations, answerId, questionId){
		const langs = Object.keys(translations);

		langs.forEach(l => {
			const question = Object.assign({}, translations[l].qna[questionId], { answers: Object.assign({}, translations[l].qna[questionId].answers) });
			delete question.answers[answerId];

			translations[l] = Object.assign({}, translations[l], { 
				qna: Object.assign({}, translations[l].qna, { 
					[questionId]: question
				})
			});
		});
	}

	resetJumpToSelections(questionToDelete, questions) {
		const deleteQuestionId = questionToDelete.questionId;
		const updatedQuestions = questions.map(q => {
			if (q.answers && q.answers.length > 0) {
				const updatedAnswers = q.answers.map(a => {
					if (a.nextQuestionId && a.nextQuestionId === deleteQuestionId) {
						const updatedAnswer = Object.assign({}, a, { nextQuestionId: null });
						if (questions.length < 2) {
							updatedAnswer.jumpToQuestionEnabled = false;
						}

						return updatedAnswer;
					} else {
						return a;
					}
				});

				return Object.assign({}, q, { answers: updatedAnswers });
			} else {
				return q;
			}
		});

		return updatedQuestions;
	}

	resetOrderIndex(questions) {
		const updatedQuestions = questions.map((q, i) => {
			return Object.assign({}, q, { orderIndex: i });
		});

		return updatedQuestions;
	}

	getNewQuestionId(questions) {
		const sortedQuestionsByIdDesc = questions.sort((a, b) => Number(b.questionId) - Number(a.questionId));

		let newId = sortedQuestionsByIdDesc.length > 0 ? sortedQuestionsByIdDesc[0].questionId + 1 : 1;

		return newId;
	}

	getNewOrderIndex(questions) {
		const sortedQuestionsByIdDesc = questions.sort((a, b) => Number(b.orderIndex) - Number(a.orderIndex));

		let orderIndex = sortedQuestionsByIdDesc.length > 0 ? sortedQuestionsByIdDesc[0].orderIndex + 1 : 0;

		return orderIndex;
	}

	getEmptyQuestionTemplate(){
		const questions = this.props.values.qna;
		const questionId = this.getNewQuestionId([...questions]);
		const orderIndex = this.getNewOrderIndex([...questions]);

		const emptyQuestionTemplate = Object.assign(
			{}, 
			EMPTY_QUESTION_TEMPLATE, 
			{ 
				questionId,
				orderIndex,
				answers: []
			}
		);
		emptyQuestionTemplate.answers.push(Object.assign({}, EMPTY_ANSWER_TEMPLATE, { answerId: 1}));
		
		return emptyQuestionTemplate;
	}

	reorderQuestionById(questionId, newOrderIndex){
		const { qna } = this.props.values;
		const reorderedQuestions = qna.map(q => Object.assign({}, q));
		const questionToReorder = reorderedQuestions.find(q => q.questionId === questionId);

		if (newOrderIndex > questionToReorder.orderIndex) {
			this.decrementOrderIndex(questionToReorder.orderIndex, newOrderIndex, reorderedQuestions);
		} else {
			this.incrementOrderIndex(questionToReorder.orderIndex, newOrderIndex, reorderedQuestions);
		}
		
		questionToReorder.orderIndex = newOrderIndex;
		
		reorderedQuestions.sort((a, b) => a.orderIndex - b.orderIndex);

		const newValuesState = Object.assign({}, this.props.values, { qna: reorderedQuestions });

		this.props.setValues(newValuesState);
	}
	
	incrementNextOrderIndexes(startIndex, questions) {
		questions.forEach((q) => {
			if (q.orderIndex >= startIndex) {
				q.orderIndex++;
			}
		});
	}

	incrementOrderIndex(startIndex, endIndex, questions) {
		questions.forEach((q) => {
			if (q.orderIndex < startIndex && q.orderIndex >= endIndex) {
				q.orderIndex++;
			}
		});
	}

	decrementOrderIndex(startIndex, endIndex, questions) {
		questions.forEach((q) => {
			if (q.orderIndex > startIndex && q.orderIndex <= endIndex) {
				q.orderIndex--;
			}
		});
	}
	
	onFocusQuestionEnd() {
		this.setState({ focusedQuestionId: null });
	}

	render() {
		const { qna } = this.props.values;
		const t = this.state.t;
		return (
			<div className="qna-container">
				<Card pad className="clearfix">
					<div className="btngroup pull-right">
						<button type="button" className="btn btn-xs transparent" onClick={this.onCollapseAll}>{this.getButtonText}</button>
					</div>
				</Card>
				
				<FieldArray name="qna" render={arrayHelpers => (
					<div className="qna-items">
						{ 
							(qna && qna.length > 0) && qna.map((q, i) => {
								const jumpQuestions = qna.filter(q => (
									q.questionId !== qna[i].questionId &&
									q.question.length > 0
								));

								return (
									<QuestionItem 
										key={i}
										topicId={this.props.values.basicSettings.id}
										questionIndex={i}
										question={q}
										errors={this.props.errors}
										fieldPath={`qna.${i}`}
										isCollapsedAll={this.state.isCollapseAll && q.questionId !== this.state.newQuestionId}
										jumpQuestions={jumpQuestions || []}
										onClone={(questionId, cloneBelow) => this.cloneQuestion(i, cloneBelow)}
										onDelete={() => this.deleteQuestion(i, arrayHelpers.remove)}
										isFirst={i === 0}
										isLast={i === qna.length - 1}
										onQuestionValuesChange={this.handleQuestionValuesChange}
										onReorderIndexChanged={this.reorderQuestionById}
										onAddAnswer={this.handleAddAnswerToQuestion}
										onRemoveAnswer={this.handleRemoveAnswerFromQuestion}
										shouldAutoFocus={q.questionId === this.state.focusedQuestionId}
										onAutoFocus={this.onFocusQuestionEnd}
									/>
								); 
							})
						}

						<button 
							type="button"
							className="btn btn-dotted btn-outline btn-success btn-full-width uitest-btn-add-question"
							onClick={this.addQuestion}
						>
							<PlusCircle size={16} /> {t('topic_builder_qna_new')}
						</button>
					</div>
				)} />
					
			</div>
		);
	}
}

QuestionsAndAnswers.childContextTypes = {
	setFieldError: PropTypes.func,
	setFieldValue: PropTypes.func,
	setValues: PropTypes.func
};

QuestionsAndAnswers.propTypes = {
	/** @prop {array} values */
	values: PropTypes.shape({
		qna: PropTypes.arrayOf(PropTypes.shape({
			questionId: PropTypes.number.isRequired,
			question: PropTypes.string.isRequired,
		})),
		translations: PropTypes.object
	}),
	
	backendErrors: PropTypes.shape({
        qna: PropTypes.object
	}),
	
    errors: PropTypes.object,
    editMode: PropTypes.bool,
    setValues: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired
};

export default QuestionsAndAnswers;