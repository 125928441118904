import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PlusSquare, Edit, XCircle } from 'react-feather';
import { Link } from 'react-router';
import { CONFIRM_MODAL} from '../../helpers/modalTypes';
import { getMarkdownFilesList, deleteMarkdownFileByName } from '../../actions/filesActions';
import { showModal, hideModal } from '../../actions/modalActions';
import './css/staticContentPage.css';

export default (isReduxConnected = true) => {
    const StaticContentPage = ({ children, filesList, isLoading, isLoaded, actions }) => {
        
        const [showNoDataText, setShowNoDataText] = useState(false);
        const prevIsLoading = useRef();
        
        useEffect(() => {
            actions.getMarkdownFilesList();
		}, []);
        
        useEffect(() => {
            if (prevIsLoading.current && isLoaded) {
                if (!filesList || (filesList.length === 0)) {
                    setShowNoDataText(true);
                } else {
                    setShowNoDataText(false);
                }
            }
        }, [filesList, isLoaded]);

        useEffect(() => {
            prevIsLoading.current = isLoading;
        });
        
        function handleDelete(fileName) {
            const configDeleteModal = {
                title: 'Confirm delete',
                message: `Are you sure you want to delete ${fileName}?`,
                onConfirm: () => {
                    actions.deleteMarkdownFileByName(fileName);
                    actions.hideModal();
                },
                onClose: actions.hideModal
            };
            actions.showModal(CONFIRM_MODAL, configDeleteModal);
        }
    
        return (
            <div className="static-pages-administration">
                <h2 className="page-heading">
                    Static content administration	
                    <div className="btn-toolbar pull-right no-float clearfix">
                        <Link to='/admin/pages/create' className='btn btn-outline btn-primary'>
                            <PlusSquare size={18} /> create a new content item
                        </Link>
                    </div>			
                </h2>
    
                <div className="ui-test-files-table files-table">
                    <table className='table'>
                        <thead className='thead-light'>
                            <tr>
                                <td className='col-md-9'>File Name</td>
                                <td className='col-md-3'>Actions</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filesList.map((fileName, fileIndex) => {
                                    return (
                                        <tr key={fileIndex}>
                                            <td>{fileName}</td>
                                            <td>
                                                <Link
                                                    to={`/admin/pages/edit/${fileName}`}
                                                    className='btn btn-outline btn-xs btn-primary'
                                                >
                                                    <Edit size={14} /> edit
                                                </Link>
                                                &nbsp;
                                                <Link
                                                    to={`/static/${fileName}`}
                                                    className='btn btn-outline btn-xs btn-primary'
                                                >
                                                    <Edit size={14} /> view
                                                </Link>
                                                &nbsp;
                                                <button
                                                    type='button'
                                                    className='btn btn-xs btn-outline btn-danger'
                                                    onClick={() => handleDelete(fileName)}
                                                >
                                                    <XCircle size={14} /> delete
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    {showNoDataText && <div className='no-data-container'><span className='no-data-text'>no files created</span></div>}
                </div>
    
                {children}
                
            </div>
        );
    };
    
    StaticContentPage.propTypes = {
        filesList: PropTypes.array.isRequired,
        isLoaded: PropTypes.bool,
        isLoading: PropTypes.bool,
        children: PropTypes.object,
        actions: PropTypes.object
    };
    
    const mapStateToProps = (state) => {
        const { filesList, isLoading, isLoaded } = state.files;
		const props = {
			filesList,
			isLoading,
			isLoaded
		};

		return props;
    };
    
    const mapDispatchToProps = (dispatch) => {
        return {
            actions: bindActionCreators({ getMarkdownFilesList, deleteMarkdownFileByName, showModal, hideModal }, dispatch)
        };
    };
    
    if(!isReduxConnected) {
        return StaticContentPage;
    }
    return connect(mapStateToProps, mapDispatchToProps)(StaticContentPage);
};