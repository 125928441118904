import moment from 'moment';
import { getAgeGroupName } from 'hollerlive-shared/utils';

export const getDefaultVoteModel = () => {
    return {
        vote: null,
        latitude: null,
        longitude: null,
        date: null,
        gender: null,
        age: null,
        ageGroup : null,
        locale: null,
        country: null,
        city: null,
        region: null,
        rowId: null,
        authType: null,
        voterUniqueId: null,
        sources: []
    };
};

export const mapFromPushVoteModel = (vote) => {
    const model =  getDefaultVoteModel();
    const { x, y } = JSON.parse(vote.geo);

    model.rowId = vote.id;
    model.vote = vote.vote / 10;
    model.latitude = x ? parseFloat(x) : null;
    model.longitude = y ? parseFloat(y) : null;
    model.date = moment(model.timestamp_utc).format();
    model.gender = vote.gender;
    model.ageGroup = vote.age_group;
    model.locale = vote.locale;
    model.country = vote.country;
    model.city = vote.city;
    model.region = vote.region;
    model.authType = vote.authType;
    model.voterUniqueId = vote.voter_id;
    model.sources = vote.sources ? vote.sources.split(',') : [];

    return model;
};

export const combineLiveVotesModels = (voteModel, topicModel) => {
    return Object.assign(
        {},
        voteModel,
        // shape the expected topic info:
        { topicId: topicModel.id, topicName: topicModel.name }
    );
};

export const getGenderByCodeName = (genderCodeName) => {
    switch(genderCodeName) {
        case "f": return "female";
        case "m": return "male";
        default: return "unknown";
    }
};

export const getVoteRatingByVote = (vote) => {
    return vote / 10;
};

export const getAgeGroupByBirthYear = (birthYear, currentTimestampUnix) => {
    if (!birthYear || birthYear <= 0 || !currentTimestampUnix) {
		return undefined;
	}

    const age = new Date(currentTimestampUnix * 1000).getFullYear() - birthYear;
    return getAgeGroupName(age);
};