import formValidators from '../../helpers/formValidators';
import Messages from '../../helpers/messages';
import sharedValidators from 'hollerlive-shared/validators';

export const mapToEntityPayload = (formModel) => {
    const payloadModel = {
        id: Number(formModel.id),
        active: formModel.isActive || false,
        name: formModel.name || '',
        url: formModel.url || '',
        logoUrl: formModel.logoUrl || '',
        logoDataUrl: formModel.logoDataUrl || null,
        address: formModel.address || '',
        countryCode: formModel.countryCode || '',
        city: formModel.city || '',
        state: formModel.state || '',
        postalCode: formModel.postalCode || '',
        systemNotifActive: formModel.systemAlertsActive || false,
        entityNotifActive: formModel.entityAlertsActive || false,
        voteNotifActive: formModel.voteAlertsActive || false,
        notifEmails: formModel.alertRecipients || '',
        version: formModel.version,
        defaultTopicLanguage: formModel.defaultTopicLanguage || 'en',
        tierId: formModel.tierId || ENTITY_TIER_IDS.ENTERPRISE,
        shouldActivateUsers: formModel.shouldActivateUsers || false
    };
    if (formModel.voteAlertsInterval) {
        payloadModel.voteNotifInterval = Number(formModel.voteAlertsInterval);
    } else {
        payloadModel.voteNotifInterval = 30;
    }
    return payloadModel;
};

export const ENTITY_TIER_IDS = {
    ENTERPRISE: 1,
    BUSINESS: 2,
    PRO: 3,
    FREE: 4
};

export const ENTITY_TIERS_NAMES = {
    ENTERPRISE: 'Enterprise',
    BUSINESS: 'Business',
    PRO: 'Pro',
    FREE: 'Free'
};

export const SUBSCRIPTION_RECURRING_TYPE = { 
    MONTHLY: 'MONTHLY', 
    YEARLY: 'YEARLY' 
};

export const ENTITY_TIERS_LIST = [
    { id: ENTITY_TIER_IDS.ENTERPRISE, name: 'Enterprise' },
    { id: ENTITY_TIER_IDS.BUSINESS, name: 'Business' },
    { id: ENTITY_TIER_IDS.PRO, name: 'Pro' },
    { id: ENTITY_TIER_IDS.FREE, name: 'Free' }
];

export const isTierChangePossible = (fromTierId, toTierId) => {
	return !(
		(fromTierId === ENTITY_TIER_IDS.PRO ||
			fromTierId === ENTITY_TIER_IDS.BUSINESS ||
			fromTierId === ENTITY_TIER_IDS.ENTERPRISE) &&
		toTierId === ENTITY_TIER_IDS.FREE
	);
};

export const MAX_WIDTH_LOGO = 128;
export const MAX_HEIGHT_LOGO = 128;

export const VOTE_ALERTS_INTERVALS = [5, 30, 60, 1440, 10080];
export const VOTE_ALERTS_MIN_INTERVAL = 5;
export const VOTE_ALERTS_MAX_COUNT = 5;

export const mapToEntityFormModel = (model) => {
    return { 
        id: model.id,
        isActive: model.active === false ? false : true, // unless set, defaults to true
        logoUrl: model.logoUrl || '',
        logoDataUrl: model.logoDataUrl || null,
        name: model.name || '',
        url: model.url || '',
        address: model.address || '',
        countryCode: model.countryCode || '',
        city: model.city || '',
        state: model.state || '',
        postalCode: model.postalCode || '',
        rootTopicName: model.rootTopicName || '',
        systemAlertsActive: model.systemNotifActive || false,
        entityAlertsActive: model.entityNotifActive || false,
        voteAlertsActive: model.voteNotifActive || false,
        voteAlertsInterval: model.voteNotifInterval || 30,
        alertRecipients: model.notifEmails || '',
        version: model.version,
        defaultTopicLanguage: model.defaultTopicLanguage || 'en',
        tierId: model.tierId || ENTITY_TIER_IDS.ENTERPRISE,
        features: model.features || {}
    };
};

export function validate(values) {
    const formErrors = {};
    if (!formValidators.required(values.name)) {
        formErrors.name = Messages.errors.required;
    } else if (!formValidators.length(values.name, null, 60)) {
        formErrors.name = Messages.errors.length(null, 60);
    } else if (!formValidators.onlyText(values.name)) {
        formErrors.name = Messages.errors.onlyText;
    }
    if (!formValidators.required(values.rootTopicName)) {
        formErrors.rootTopicName = Messages.errors.required;
    }
    if (values.url && values.url.length > 0 && !formValidators.url(values.url)) {
        formErrors.url = Messages.errors.url;
    }
    if (values.address && values.address.length > 0 && !formValidators.onlyText(values.address)) {
        formErrors.address = Messages.errors.onlyText;
    }
    if (values.countryCode && values.countryCode.length > 0 && !formValidators.onlyText(values.countryCode)) {
        formErrors.countryCode = Messages.errors.onlyText;
    }
    if (values.city && values.city.length > 0 && !formValidators.onlyLatinLetters(values.city)) {
        formErrors.city = Messages.errors.onlyLatinLetters;
    }
    if (values.state && values.state.length > 0 && !formValidators.onlyLatinAphaNumeric(values.state)) {
        formErrors.state = Messages.errors.onlyLatinAlphaNumeric;
    }
    if (values.postalCode && values.postalCode.length > 0 && !formValidators.onlyLatinAphaNumeric(values.postalCode)) {
        formErrors.postalCode = Messages.errors.onlyLatinAlphaNumeric;
    }
    return formErrors;
}

export function validateAlerts(values) {
    const formErrors = {};
    if (values.alertRecipients && values.alertRecipients.length > 0) {
        const emailValues = values.alertRecipients.split(',');
        emailValues.forEach(email => {
            if (!sharedValidators.isEmailValid(email.trim()) || !formValidators.required(email.trim())) {
                formErrors.alertRecipients = Messages.errors.email;
            }
        });
    }
    if ((values.voteAlertsActive || values.systemAlertsActive || values.entityAlertsActive) &&
        !(values.alertRecipients && values.alertRecipients.length)) {
        formErrors.alertRecipients = Messages.errors.required;
    }
    if (!formValidators.onlyNumeric(values.voteAlertsInterval)) {
        formErrors.voteAlertsInterval = Messages.errors.onlyNumeric;
    } else if (values.voteAlertsInterval < VOTE_ALERTS_MIN_INTERVAL) {
        formErrors.voteAlertsInterval = Messages.errors.length(5);
    }
    return formErrors;
}

export function mapToUserEntityModel(model) {
	const { id, name, tierId } = model;
	return { id, name, tierId };
}
