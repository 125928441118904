import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { browserHistory } from 'react-router';

import * as userActions from '../../actions/usersActions';
import SidePanel from '../../components/common/sidePanel';
import EditUserForm from '../../components/users/editUserForm';
import { mapToFormModel, DEFAULT_CREATE_USER, validateUser } from '../../actions/models/userModel';
import Logger from '../../helpers/logger';
import { showModal, hideModal } from '../../actions/modalActions';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import { userHasSpecialPermissions } from '../../helpers/permissionsHelper';
import { USER_ROLES } from '../../helpers/user';
import { ENTITY_FEATURES } from 'hollerlive-shared/constants';

export default (userEditPageMode, isPlainComponent = false) => {
	class UserEditPage extends Component {
		constructor(props) {
			super(props);

			const initState = {
				submitSent: false,
				formValuesLoaded: false
			};

			if (userEditPageMode.edit === true && !isEmpty(props.user)) {
				initState.initialFormValues = mapToFormModel(props.user);
			} else {
				if (userEditPageMode.create === true) {
					const userCreateModel = mapToFormModel({
						active: true
					});
					initState.initialFormValues = Object.assign({}, userCreateModel);
				}
			}
			
			this.state = initState;

			this.dirty = false;
			
			this.handleClosePanelClick = this.handleClosePanelClick.bind(this);
			this.handleSubmit = this.handleSubmit.bind(this);
			this.validate = this.validate.bind(this);

			this.configureRouteChange();
		}

		static getDerivedStateFromProps(props, state) {
			if (userEditPageMode.edit === true) {
				if (!isEmpty(props.user) && props.user.entityId && props.entities.length > 0 && !state.formValuesLoaded) {
					const updatedInitialFormValues = Object.assign({}, state.initialFormValues,
						mapToFormModel(props.user)
					);
					return { initialFormValues: updatedInitialFormValues, formValuesLoaded: true };
				}
			}
			return null;
		}

		componentDidMount() {
			if (userEditPageMode.edit === true) {
				this.props.actions.getUserDetailsById(this.props.params.id);
			}
		}
		
		componentDidUpdate(prevProps) {
			if (userEditPageMode.edit === true) {
				if (isEmpty(prevProps.user)) {
					if (!isEmpty(this.props.user) && this.state.formValuesLoaded) {
						this.setState({ initialFormValues: mapToFormModel(this.props.user) });
					}
				} else {
					if (prevProps.user.id !== this.props.user.id || !isEqual(prevProps.user, this.props.user)) {
						this.setState({ initialFormValues: mapToFormModel(this.props.user) });
					} else {
						if (prevProps.updatedUser.id !== this.props.updatedUser.id) {
							this.setState({ initialFormValues: mapToFormModel(this.props.user) });
						}
					}
				}
			} else if (userEditPageMode.create === true) {
				if (this.state.submitSent && this.props.createdUser.id) {
					this.props.actions.resetCreatedUser();
					this.props.router.push(`/users`);
				}
			}
		}

		componentWillUnmount() {
			window.onbeforeunload = () => {};
		}

		configureRouteChange(leaveMessage = 'You have unsaved changes. Are you sure you want to leave the page?') {
			this.props.router.setRouteLeaveHook(
				// Current route
				this.props.router.routes[2], 
				() => {
					if(!this.dirty) {
						return true;
					} else {
						if (!this.state.submitSent && !this.props.createdUser.id) {
							if (!this.state.isCancelModalDisplayed) {
								return window.confirm(leaveMessage);
							}
						}
					}
				}
			);
			window.onbeforeunload = () => {
				if(this.dirty) {
					return window.confirm();
				}
			};
		}

		handleClosePanelClick(dirty) {
			const location = '/users';
			if (!dirty) {
				this.props.router.push(location);
			} else {
				const configCancelModal = {
					title: "Confirm Cancel Changes",
					message: "Are you sure you want to cancel the changes you made?",
					onClose: () => { 
						this.props.actions.hideModal();
						this.setState({ isCancelModalDisplayed: false });	
					},
					onConfirm: () => {
						browserHistory.push(location);
						this.props.actions.hideModal();
					},
				};
				this.setState( { isCancelModalDisplayed: true } );
				this.props.actions.showModal(CONFIRM_MODAL, configCancelModal);
			}
		}

		handleSubmit(values) {
			if (userEditPageMode.create === true){
				this.setState({ 
					submitSent: true 
				}, () => {
					this.props.actions.createUser(values);
				});
			} else if (userEditPageMode.edit === true) {
				this.props.actions.updateUser(this.props.params.id, values);
			} else {
				Logger.error(`User edit mode not recognized. Pass either create = true or edit = true. Now it is: ${userEditPageMode}`);
			}
		}

		validate(values) {
			const shouldValidateEmail = userEditPageMode.create === true;
			const validationOptions = { shouldValidateEmail, shouldValidatePassword: false };
			return validateUser(values, validationOptions);
		}

		get canSetAsManager() {
			const { entityUsers, entities } = this.props;
			const userEntity = entities && entities.length > 0 ? entities[0] : {};
			const managers = entityUsers.filter(user => Number(user.roleId) === USER_ROLES.entityManager);
			if (userEntity.features) {
				const numManagersLimit = userEntity.features[ENTITY_FEATURES.NUMBER_OF_MANAGERS];
				return numManagersLimit > 0 ? managers.length < numManagersLimit : true;
			}
			return false;
		}
		
		render() {
			const { user, entities, requestErrors, hasSpecialPermissions } = this.props;
			const { resetUserRequestErrors } = this.props.actions;
			const { initialFormValues } = this.state;
			const isInEditMode = userEditPageMode.edit === true;
			const className = isInEditMode ? 'user-edit-page uitest-user-edit-page' : 'user-create-page uitest-user-create-page';
			const pageHead = userEditPageMode.edit ? `Edit ${user ? user.firstName : ''} ${user ? user.lastName : ''}` : 'Create user';

			return !isEmpty(initialFormValues) ? <Formik 
				initialValues={this.state.initialFormValues} 
				onSubmit={this.handleSubmit}
				validate={this.validate}
				enableReinitialize={true}
			>
				{({ values, touched, errors, dirty, setFieldValue, setValues, setTouched, isSubmitting, setSubmitting }) =>  {
					const isFormValid = dirty && isEmpty(errors); 
					const disableSubmitBtn = !isFormValid || isSubmitting;

					this.dirty = dirty;

					let entityForUser = user ? entities.find(entity => entity.id === user.entityId) : undefined;

					return (
						<Form className="text-left">
							<SidePanel 
								className={className} 
								title={pageHead} 
								onClose={() => this.handleClosePanelClick(dirty)}
								showButtons={true}
								okButtonText={isInEditMode ? 'Update' : 'Create'}
								okButtonType="submit"
								okButtonDisabled={disableSubmitBtn}
								onOk={this.handleSubmit}
							>
								<EditUserForm 
									values={values}
									touched={touched}
									entities={entities}
									errors={errors} 
									requestErrors={requestErrors} 
									resetRequestErrors={resetUserRequestErrors}
									setFieldValue={setFieldValue} 
									setValues={setValues}
									setTouched={setTouched}
									emailReadOnly={userEditPageMode.edit === true}
									hasSpecialPermissions={hasSpecialPermissions}
									isCreateMode={userEditPageMode.create === true}
									activeToggleDisabled={entityForUser ? (!entityForUser.active) : false}
									resetSubmitting={() => setSubmitting(false)}
									canSetAsManager={this.canSetAsManager}
								/>
							</SidePanel>
						</Form>
					);
				}}
			</Formik> : <div />;
		}
	}

	UserEditPage.propTypes = {
		params: PropTypes.object,
		/** @prop {bool} hasSpecialPermissions
		 * logged user must have special permissions to access special features like adding a superadmin user */
		hasSpecialPermissions: PropTypes.bool.isRequired,
		user: PropTypes.shape({
			id: PropTypes.number,
			firstName: PropTypes.string,
			lastName: PropTypes.string,
			email: PropTypes.string,
			phone: PropTypes.string,
			roleId: PropTypes.number,
			entityId: PropTypes.number,
			active: PropTypes.bool
		}),
		createdUser: PropTypes.shape({ id: PropTypes.number }),
		updatedUser: PropTypes.shape({ id: PropTypes.number }),
		entities: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number,
				name: PropTypes.string
			})
		).isRequired,
		actions: PropTypes.shape({
			/**@prop {func} getUserDetailsById - load user by id
			 * @param {number} userId */
			getUserDetailsById: PropTypes.func,
			/**@prop {func} createUser - create user
			 * @param {object} user data */
			createUser: PropTypes.func,
			/**@prop {func} updateUser - update user
			 * @param {object} user data */
			/**@prop {func} resetCreatedUser - reset user creation */
			resetCreatedUser: PropTypes.func,
			updateUser: PropTypes.func,
			/**@prop {func} resetUserRequestErrors - reset user request errors */
			resetUserRequestErrors: PropTypes.func,
			showModal: PropTypes.func,
			hideModal: PropTypes.func,
		}).isRequired,
		requestErrors: PropTypes.shape({
			validationErrors: PropTypes.shape({
				model: PropTypes.string,
				firstName: PropTypes.string,
				lastName: PropTypes.string,
				email: PropTypes.string,
				phone: PropTypes.string,
				locale: PropTypes.string,
				entityId: PropTypes.string
			})
		}),
		router: PropTypes.object,

		entityUsers: PropTypes.arrayOf(PropTypes.shape({
			roleId: PropTypes.number
		}))
	};

	UserEditPage.defaultProps = {
		requestErrors: {},
		updatedUser: {}
	};

	const mapStateToProps = (state, props) => {
		let user;
		let entityUsers = [];

		if (userEditPageMode.create === true) {
			user = Object.assign({}, DEFAULT_CREATE_USER);
		} else {
			user = (state.usersData && (state.usersData.usersList || [])).find(u => u.id === Number(props.params.id));
		}

		if (state.usersData && state.usersData.usersList.length > 0) {
			entityUsers = state.usersData.usersList.filter(u => u.entityId === Number(props.params.id));
		}

		return {
			user,
			createdUser: state.usersData.createdUser,
			updatedUser: state.usersData.updatedUser,
			entities: state.entitiesList || [],
			entityUsers,
			requestErrors: state.usersData.requestErrors,
			hasSpecialPermissions: userHasSpecialPermissions(state.auth.user),
		};
	};

	const mapDispatchToProps = (dispatch) => {
		return {
			actions: bindActionCreators({ ...userActions, showModal, hideModal }, dispatch)
		};
	};

	if (isPlainComponent) {
		return UserEditPage;
	}

	return connect(mapStateToProps, mapDispatchToProps)(UserEditPage);
};