import * as types from '../actions/actionTypes';
import { parseRawVoteDataToJsonArray, LATEST_VOTES_MAX_COUNT } from '../helpers/voteDataHelper';

// generates a state slice:
// topics = [topic1, topic2, ...]
const initialState = [];
export function topicReducer(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_TOPICS_FOR_ENTITY_SUCCESS: {
            const parentTopic = action.payload.topics.find(topic => topic.level === 1);
            if(!parentTopic){
                return [...action.payload.topics];
            }
            const modifiedTopic = Object.assign({}, parentTopic, { isParent: true });
            return [modifiedTopic, ...action.payload.topics.filter(topic => topic.id !== modifiedTopic.id)];
        }
        case types.LOAD_TOPIC_SUCCESS: {
            const newState = state.filter(topic => topic.id !== action.payload.id);
            const loadedTopicInArray = [Object.assign({}, action.payload)];
            return [...newState, ...loadedTopicInArray];
        }
        case types.LOAD_TOPIC_VOTES_STATS: {
            const { id, stats, lastVotes } = action.payload.topicVotesStats;
            const topic = state.find(t => t.id === id);
            if (topic) {
                const latestVotes = parseRawVoteDataToJsonArray(lastVotes).slice(0, LATEST_VOTES_MAX_COUNT);
                const topicWithLatest = Object.assign({}, topic, { latestVotes, stats });
                const newState = state.filter(t => t.id !== topicWithLatest.id);
                return [...newState, topicWithLatest];
            }
            return state;
        }
        case types.LOAD_TOPIC_TREE_SUCCESS: {
            const { votesStats } = action.payload;
            if (votesStats && votesStats.length) {
                return state.map(topic => {
                    const topicVotesStats = (votesStats.find(voteStat => voteStat.id === topic.id) || {}).stats;
                    return Object.assign({}, topic, {
                        stats: {
                            firstVoteTime : topicVotesStats ? topicVotesStats.firstVoteTime : null,
                            lastVoteTime: topicVotesStats ? topicVotesStats.lastVoteTime : null,
                            lifetimeVotesCount: topicVotesStats ? topicVotesStats.lifetimeVotesCount : 0,
                            lifetimeAnswersCount:topicVotesStats ? topicVotesStats.lifetimeAnswersCount : 0
                        }
                    });
                });
            }
            return state;
        }
        case types.RECEIVE_VOTE: {
            const { vote, topic } = action.payload;
			let topicToUpdate = null;
			const newState = state.filter(t => {
				if (t.id === topic.id) {
				    // for performance, the topic of interest is refered along with filtering the array slice:
					topicToUpdate = t;
					return false;
				} else return true;
			});
			if (topicToUpdate) {
			    const latestVotes =
					!topicToUpdate.latestVotes || topicToUpdate.latestVotes.length < LATEST_VOTES_MAX_COUNT
						? [vote, ...(topicToUpdate.latestVotes || [])]
						: [vote, ...topicToUpdate.latestVotes.slice(0, LATEST_VOTES_MAX_COUNT - 1)];
				const topicWithLatest = Object.assign({}, topicToUpdate, { latestVotes });
				return [...newState, topicWithLatest];
			}
            return state;
        }
        case types.SAVETOPIC_SUCCESS:
        case types.RESET_TOPICS: {
            return initialState;
        }
        case types.LOAD_TOPIC_STRUCTURE_SUCCESS: {
            const topicStructure = action.payload.topic;
            const topic = state.find(t => t.id === topicStructure.basicSettings.id);
            if (topic) {
                const { basicSettings, qna, linkConfig } = topicStructure;
                const topicWithStructure = Object.assign({}, topic, { basicSettings, qna, linkConfig });
                const newState = state.filter(t => t.id !== topicStructure.basicSettings.id);
                return [...newState, topicWithStructure];
            }
            return state;
        }
        default:
            return state;
    }
}

// generates a state slice:
// channelTopics = { channel1: topicsFor1[], channel2: topicsFor2[] ...}
export function channelTopicsReducer(state = {}, action) {
    switch (action.type) {
        case types.LOAD_TOPICS_FOR_CHANNEL_SUCCESS:{
            return {
                ...state,
                [action.payload.channel]: mapWithOrderIndex(action.payload.topics)
            };
        }
        case types.REMOVE_TOPIC_FROM_CHANNEL_SUCCESS: {
            const { payload } = action;
            const filteredTopics = state[payload.channel].filter((topic) => topic.name !== payload.topicName);

            return {
                ...state,
                [payload.channel]: filteredTopics
            };
        }
        default:
            return state;
    }
}

// to generate a state slice adminTopics:
const defaultAdminTopicsState = { totalCount: 0, topics: [] };
export function adminTopicsReducer(state = defaultAdminTopicsState, action) {
    switch (action.type) {
        case types.GET_TOPICS_PAGE_BY_NAME_SUCCESS:{
            const {payload} = action;
            return {
                ...state,
                totalCount: payload.totalCount,
                topics: [...state.topics, ...payload.topics]
            };
        }
        case types.RESET_ADMIN_TOPICS_STATE:{
            return { ...defaultAdminTopicsState };
        }
        default:
            return state;
    }
}

// internal functions
function mapWithOrderIndex(topics) {
    let index = 0;
    return topics.map(t => {
        return Object.assign({}, t, { orderIndex: (index += 10) });
    });
}
