import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const DebouncedInputField = ({
	onDebouncedAction,
	placeholder, 
    value,
	debounceOnChangeTimeout,
	onValueChange,
	onBlur,
	onFocus,
	name
}) => {
	const [fieldValue, _setFieldValue] = useState(value);
	const [isDebouncing, setIsDebouncing] = useState(false);

	useEffect(() => {
		if (isDebouncing) {
			const debouncedOnChange = debounce(() => {
				setIsDebouncing(false);
			}, debounceOnChangeTimeout);

			debouncedOnChange();
		} else {
			if (fieldValue && fieldValue.length >= 0) {
				onDebouncedAction(fieldValue);
			}
		}
	}, [isDebouncing, debounceOnChangeTimeout, onDebouncedAction, fieldValue]);

	const handleFieldChange = (ev) => {
		if (onValueChange) {
			onValueChange(ev.target.value);
		}
		_setFieldValue(ev.target.value);

		if (!isDebouncing) {
			setIsDebouncing(true);
		}
	};
	
	return (<input 
		type="text" 
		onChange={handleFieldChange} 
		value={fieldValue} 
		className="form-control"
		placeholder={placeholder} aria-describedby="input-field"
		onBlur={onBlur}
		onFocus={onFocus}
		name={name}
		autoComplete="noautocompletepls" 
    />);
};

DebouncedInputField.propTypes = {
	/** 
	 * @prop {func} onDebouncedAction action handler invoked after debounced timeout on input value change, passing typed text value
	 * @param {string} value
	 */
	onDebouncedAction: PropTypes.func.isRequired,

	/** @prop {string} placeholder the placeholder text for the input */
	placeholder: PropTypes.string,

	/** @prop {string} value the pre-set value for the input text */
	value: PropTypes.string,

	/** @prop {number} debounceOnChangeTimeout a set timeout to invoke onDebouncedAction action */
	debounceOnChangeTimeout: PropTypes.number,

	/** @prop {func} onValueChange immediate action sent on onChange in input field */
	onValueChange: PropTypes.func,

	/** @prop {func} onBlur immediate action sent on onBlur in input field */
	onBlur: PropTypes.func.isRequired,

	/** @prop {func} onFocus immediate action sent on onFocus in input field */
	onFocus: PropTypes.func.isRequired,

	/** @prop {string} name passed as name attribute to input */
	name: PropTypes.string,
};

DebouncedInputField.defaultProps = {
	placeholder: "",
	value: "",
	debounceOnChangeTimeout: 1000,
	name: ""
};

export default DebouncedInputField;