import * as types from "../actions/actionTypes";
import { isEmpty, cloneDeep } from 'lodash';

const initialState = {
	id: null,
	active: true,
	name: '',
	url: '',
	address: '',
	countryCode: '',
	city: '',
	state: '',
	postalCode: '',
	systemNotifActive: false,
	entityNotifActive: false,
	voteNotifActive: false,
	voteNotifInterval: null,
	notifEmails: '',
	rootTopicName: '',
	errors: null,
	userCreationErrors: {},
	isLoading: false,
	isSaved: false,
	isUserSaving: false,
	isUserSaved: false
};
export function entityBuilderReducer(state = initialState, action) {
	switch (action.type) {
		case types.LOAD_ENTITY_REQUEST: {
			return Object.assign({}, initialState, { isLoading: true });
		}
		case types.LOAD_ENTITY_SUCCESS: {
			const existingErrors = state.errors; // we want to persist existing errors, in case there is a redirect from create to edit, because of an error
			return Object.assign({}, action.payload.entity, {
				isLoading: false,
				isSaved: false,
				errors: existingErrors,
				isUserSaved: false,
				isUserSaving: false
			});
		}
		case types.RESET_ENTITY_FOR_EDIT: {
			return Object.assign({}, state, {
				isLoading: false,
				isSaved: false,
				isUserSaved: false,
				isUserSaving: false,
				errors: null
			});
		}
		case types.EDIT_ENTITY_SUCCESS: {
			return Object.assign({}, state, action.payload.entity, {
				isSaved: true,
				errors: null,
				isUserSaved: false,
				isUserSaving: false
			});
		}
		case types.CREATE_ENTITY_SUCCESS: {
			return Object.assign({}, initialState, { isSaved: true });
		}
		case types.SET_ASYNC_VALIDATING: {
            return Object.assign({}, state, {
                isAsyncValidating: true
            });
        }
        case types.RESET_ASYNC_VALIDATING: {
            return Object.assign({}, state, {
                isAsyncValidating: false
            });
        }
		case types.HAS_VALIDATION_ERRORS: {
			return Object.assign({}, state, { errors: action.payload.errors, isLoading: false });
		}
		case types.CREATE_USER_ERROR: {
			return Object.assign({}, state, { userCreationErrors: action.payload.errors, isLoading: false });
		}
		case types.CREATE_USER_REQUESTED: {
			return Object.assign({}, state, { userCreationErrors: {}, isUserSaved: false, isUserSaving: true });
		}
		case types.RESET_USER_REQUEST_ERRORS: {
			return Object.assign({}, state, { userCreationErrors: {} });
		}
		case types.HAS_VERSION_ERROR: {
			const { errors, errorsTarget } = action.payload;
			const newState = Object.assign({}, state, {
				isLoading: false
			});
			if (errorsTarget && errorsTarget.type === types.UPDATE_ENTITY_VERSION_ERROR) {
				newState.errors = Object.assign({}, state.errors, {
					version: errors
				});
			}
			return newState;
		}
		case types.SET_TOPIC_NAME_ERROR: {
            const { errors } = action.payload;
			const newState = Object.assign({}, 
				state,
				{
					errors: Object.assign({}, state.errors, {
						rootTopicName: errors.title
					})
				}
			);
			return newState;
        }
        case types.CLEAR_TOPIC_NAME_ERROR: {
			const newState = Object.assign({}, cloneDeep(state));
            if (!isEmpty(newState.errors) && newState.errors.rootTopicName) {
				delete newState.errors.rootTopicName;
            } 
			return newState;
		}
		default:
			return state;
	}
}