import React from 'react';
import PropTypes from 'prop-types';
import Showdown from 'showdown';
import Xss from 'xss';

const staticMarkdownContentReader = ({ body }) => {
	const converter = new Showdown.Converter({
		simpleLineBreaks: true,
		tables: true,
	});

	const htmlContent = converter.makeHtml(body);
	
	function createMarkup() {
		return {__html: Xss(htmlContent)};
	}
	
	return (
		<div className="static-content-container" dangerouslySetInnerHTML={createMarkup()} />
	);
};

staticMarkdownContentReader.propTypes = {
	/** @param {string} body representing valid markdown text */
	body: PropTypes.string
};

export default staticMarkdownContentReader;