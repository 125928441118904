import React from 'react';
import PropTypes from 'prop-types';

import './css/topicLinksModal.css';
import Modal from '../common/modal';
import TopicMediaEmbed from '../topics/topicMediaEmbed';
import getTopicMedia from '../../scenes/topics/topicMedia';

const TopicLinksModal = ({data, width, onClose}) => {
	const TopicMedia = getTopicMedia();
	return (
		<Modal
			className='topic-links-modal'
			onClose={onClose}
			onOk={onClose}
			width={width}
		>
			{(data.topicName && data.linkConfig) ?
			<TopicMediaEmbed
				topicName={data.topicName}
				topicDisplayName={data.topicDisplayName}
				linkSources={data.linkConfig.sources}
				className='uitest-tm-embed'
			/>
			:
			<TopicMedia topicId={data.topicId} className='uitest-tm' />}
		</Modal>
	);
};

TopicLinksModal.propTypes = {
	data: PropTypes.shape({
		topicId: PropTypes.string.isRequired,
		topicName: PropTypes.string,
		topicDisplayName: PropTypes.string,
		linkConfig: PropTypes.shape({sources: PropTypes.array})
	}).isRequired,
    width: PropTypes.number,
    onClose: PropTypes.func.isRequired,
};

export default TopicLinksModal;
