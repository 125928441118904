import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XSquare } from 'react-feather';
import './css/modal.css';
import messages from '../../helpers/messages';
import ConfirmOnClose from './confirmOnClose';
import i18n from '../../i18n';

class Modal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpenConfirm: false,
			enableOverlayClickClose: this.props.enableOverlayClickClose !== false,
			isConfirmModal: this.props.isConfirmModal || false,
			t: i18n.t.bind(i18n)
		};
		
		this.listenKeyboard = this.listenKeyboard.bind(this);
		this.setCursorOutsideModal = this.setCursorOutsideModal.bind(this);

		this.onOverlayClick = this.onOverlayClick.bind(this);
		this.onDialogClick = this.onDialogClick.bind(this);

		this.onClose = this.onClose.bind(this);

		this.handleOpenConfirm = this.handleOpenConfirm.bind(this);
		this.handleUnsavedChanges = this.handleUnsavedChanges.bind(this);
		this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
		this.hasUnsavedChanges = false;
	}
	
	componentDidMount() {
		this.setCursorOutsideModal();

		if (this.props.onClose) {
			window.addEventListener('keydown', this.listenKeyboard, true);
		}
    }

	componentWillUnmount() {
		if (this.props.onClose) {
			window.removeEventListener('keydown', this.listenKeyboard, true);
		}
	}

	onClose() {
		this.props.onClose();
	}

	listenKeyboard(event) {
		if (event.key === 'Escape' || event.keyCode === 27) {
			this.onClose();
		}
	}

	setCursorOutsideModal() {
		this.divContainer.style.cursor = this.state.enableOverlayClickClose ? 'pointer' : 'default';
	}

	get bodyStyle() {
		let maxHeight = 90;
		if (!this.hideFooter) {
			maxHeight -= 10;
		}

		return { maxHeight: `${maxHeight}vh` };
	}

	get title() {
		const { title } = this.props;

		return title ? (
			<div className='modal__title'>
				<h1>
					{title}
					{this.XSquare}
				</h1>
			</div>
		) : null;
	}

	get XSquare() {
		const { hasCloseButtonInTitle } = this.props;
		const showCloseButton = hasCloseButtonInTitle !== false;

		return showCloseButton ? (
			<button className='modal__close btn btn-link' onClick={this.handleOpenConfirm}><XSquare size="24" /></button>
		) : null;
	}

	get contentStyle() {
		const style = Object.assign({}, this.modalWidth);
		
		return style;
	}

	get modalWidth() {
		const { width } = this.props;

		return width ? { maxWidth: `${width}px` } : {};
	}

	get hideFooter() {
		return this.props.hasFooter === false;
	}

	onOverlayClick() {
		if (this.state.enableOverlayClickClose) {
			this.handleOpenConfirm();
		}
	}

	onDialogClick(event) {
		event.stopPropagation();
	}

	handleOpenConfirm() {
		console.log('handleOpenConfirm', this.hasUnsavedChanges, this.state.isConfirmModal);
		if (this.hasUnsavedChanges && !this.state.isConfirmModal) {
			console.log('Form has unsaved changes; opening confirm');
			this.setState({ isOpenConfirm: true });
		} else {
			console.log('No unsaved changes; closing modal');
			this.onClose();
		}
	}

	handleUnsavedChanges(childHasUnsavedChanges) {
		console.log('Setting hasUnsavedChanges to', childHasUnsavedChanges);
		this.hasUnsavedChanges = childHasUnsavedChanges;
	}
	
	handleCloseConfirm() {
		this.setState({ isOpenConfirm: false });
	}

	get divContainer() {
		return document.getElementsByClassName('modal-container')[0];
	}

	get childrenScenes() {
		const childrenWithExtraProps = React.Children.map(this.props.children, (child) => {
			if (typeof child.type === 'string') {
				return child;
			} else {
				return React.cloneElement(child, {
					//TODO: this implicitness is ambigious and should be refactored - needs at least renaming
					onUnsavedChanges: this.handleUnsavedChanges,
					onOpenModalConfirm: this.handleOpenConfirm,
					hasOpenConfirm: this.state.isOpenConfirm
				});
			}
		});
		
		return childrenWithExtraProps;
	}

	render() {	
		const { className, hasPadding, onOk, onClose, hasOkButton, btnOkLabel, btnCancelLabel } = this.props;
		const t = this.state.t;
		return (
			<div className={`modal ${className}`}>
				<div className="modal-overlay" />
				<div className="modal-container" onClick={this.onOverlayClick}>
					<div className="modal-content" style={this.contentStyle} onClick={this.onDialogClick}>
						<div className="modal-title clearfix">
							{this.title}
						</div>
						
						<div className={`modal-body ${hasPadding === false ? 'no-padding': ''}`} style={this.bodyStyle}>
							{this.childrenScenes}
						</div>

						{this.hideFooter !== true &&
						<div className="modal-footer">
							<div className="btn-toolbar">
								{ hasOkButton &&
									<button type="button" className="btn btn-primary pull-right" onClick={onOk}>{t(btnOkLabel || 'modal_general_ok')}</button> }
								<button type="button" className="btn btn-outline pull-right" onClick={onClose}>{t(btnCancelLabel || 'modal_general_cancel')}</button>
							</div>
						</div>
						}
					</div>
				</div>
				{this.state.isOpenConfirm && 
					<ConfirmOnClose message={messages.common.confirmUnsavedChangesMessage} onConfirm={this.onClose} onDecline={this.handleCloseConfirm} />
				}
			</div>
		);
	}
}

Modal.propTypes = {
	/* @prop {string} className add any extra class names for modal dialog with space */
	className: PropTypes.string,

	/* @prop {node} children the Component(s) to be rendered inside the modal */
	children: PropTypes.node,

	/* @param {string} title of the modal dialog */
	title: PropTypes.string,

	/* @param {bool} hasCloseButtonInTitle set false to hide XSquare button in top right corner */
	hasCloseButtonInTitle: PropTypes.bool,

	/* @prop {func} onClose choose what happens when modal closes */
	onClose: PropTypes.func.isRequired,

	/* @prop {func} onOk choose what happens when modal closes after clicking 'Ok' */
	onOk: PropTypes.func,
	
	/* @param {bool} hasFooter false to hide button 'Ok' and 'Cancel' at the bottom of modal */
	hasFooter: PropTypes.bool,
	
	/* @param {string} btnCancelLabel choose what to display on 'Cancel' button */
	btnCancelLabel: PropTypes.string,

	/* @param {string} btnOkLabel choose what to display on 'Ok' button */
	btnOkLabel: PropTypes.string,
	
	/* @param {number} width choose maxWidth of modal */
	width: PropTypes.number,

	/* @param {bool} hasPadding false to remove padding of children nodes of modal */
	hasPadding: PropTypes.bool,

	/* @param {bool} enableOverlayClickClose 
	* false to disable closing modal when user clicks outside of modal
	* default: true
	*/
	enableOverlayClickClose: PropTypes.bool,

	/* @param {bool} isConfirmModal 
	* true to indicate that modal is a confirm modal only
	* default: false
	*/
	isConfirmModal: PropTypes.bool,

	/** @param {bool} hasOkButton determines showing/hiding the ok button */
	hasOkButton: PropTypes.bool
};

export default Modal;