import * as actionTypes from './actionTypes';
import { graphPost } from '../api/expressApi';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import moment from 'moment';
import Logger from '../helpers/logger';
import { loadQuestionsStatsForTopic } from './qnaActions';
import { isEmpty } from 'lodash';
import { downloadFile } from '../helpers/file-download';
import { getVoterTypesArrayFromVoterFilters, getAnswerWithoutLink, getAnswerLink } from './models/qnaModel';
import * as voteModel from './models/voteModel';
import { handleCommonError } from './errorActions';

function loadFilteredVotesWithQnaByTopicIdSuccess(votesPayload) {
	return { type: actionTypes.LOAD_FILTERED_VOTES_WITH_QNA_SUCCESS, payload: votesPayload };
}

function loadFilteredVotersByTopicIdSuccess(votersPayload) {
	return { type: actionTypes.LOAD_FILTERED_VOTERS_SUCCESS, payload: votersPayload };
}

function loadVotesByVoter(votersPayload) {
	return { type: actionTypes.LOAD_VOTES_FOR_VOTER_SUCCESS, payload: votersPayload };
}

function loadFilteredAnswersForQuestionsSuccess(questionsAndAnswersPayload) {
	return { type: actionTypes.LOAD_QUESTIONS_AND_ANSWERS_SUCCESS, payload: questionsAndAnswersPayload };
}


function loadFilteredAnswersForSingleQuestionsSuccess(answersPayload) {
	return { type: actionTypes.LOAD_SINGLE_QUESTION_ANSWERS_SUCCESS, payload: answersPayload };
}

/** loads qna page result for the provided topic id and date range.
 * @param {Object} pageConfig - object with props pageSize (num), offset (num). If null, will clear the previous state on successful loading (effectively resetting page index to 0)
 */
export function loadFilteredVotesWithQnaByTopicId({ 
	topicId, 
	dateFrom, 
	dateTo, 
	pageConfig,
	search, 
	feedFilters, 
	votersFilters
}) {
	return async dispatch => {
		dispatch(showLoading());
		const requestBodyWithFilters = buildFilteredRequestBody(topicId, dateFrom, dateTo, feedFilters, votersFilters);
		
		const pageSize = pageConfig ? pageConfig.pageSize : 10;
		const offset = pageConfig ? pageConfig.offset : 0;
		const orderBy = pageConfig ? pageConfig.orderBy : 'id';
		const sortOrder = pageConfig ? pageConfig.sortDirection : 'ASC';
		
		const requestBody = Object.assign({}, requestBodyWithFilters, {
			pageSize,
			offset,
			orderBy,
			sortOrder,
		});

		if (search) {
			requestBody.filters = Object.assign({}, requestBody.filters, {
				search
			});
		};

		try {
			const result = await graphPost(`/topicVotes/${topicId}/withQna`, requestBody);
			const votes = mapFromFilteredVotes(result.votesWithQna);
				
			dispatch(loadFilteredVotesWithQnaByTopicIdSuccess({
				topicId,
				totalVotes: result.totalCount,
				votes,
			}));
					
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);			
		}
	};
}

export function loadFilteredVotersByTopicId({
	topicId, 
	dateFrom, 
	dateTo, 
	pageConfig, 
	feedFilters, 
	votersFilters
}) {
	return async dispatch => {
		const requestBodyWithFilters = buildFilteredRequestBody(topicId, dateFrom, dateTo, feedFilters, votersFilters);
		
		const pageSize = pageConfig ? pageConfig.pageSize : 10;
		const offset = pageConfig ? pageConfig.offset : 0;
		const orderBy = pageConfig ? pageConfig.orderBy : 'id';
		const sortOrder = pageConfig ? pageConfig.sortDirection : 'DESC';
		
		const requestBody = Object.assign({}, requestBodyWithFilters, {
			pageSize,
			offset,
			orderBy,
			sortOrder,
		});

		try {
			const result = await graphPost(`/topicqna/${topicId}/votersstats`, requestBody);
			const mappedVoters = mapFromFilteredVoters(result.voters);

			dispatch(loadFilteredVotersByTopicIdSuccess({
				topicId,
				voters: mappedVoters,
				totalVoters: result.total
			}));
			
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);
		};
	};
}

export function loadVotesForVoter({
	topicId, 
	voterId,
	dateFrom, 
	dateTo, 
	pageConfig, 
	feedFilters, 
	votersFilters
}){
	return async dispatch => {
		dispatch(showLoading());
		const requestBodyWithFilters = buildFilteredRequestBody(topicId, dateFrom, dateTo, feedFilters, votersFilters);
		
		const pageSize = pageConfig ? pageConfig.pageSize : 10;
		const offset = pageConfig ? pageConfig.offset : 0;
		const orderBy = pageConfig ? pageConfig.orderBy : 'id';
		const sortOrder = pageConfig ? pageConfig.sortDirection : 'ASC';
				
		const requestBody = Object.assign({}, requestBodyWithFilters, {
			pageSize,
			offset,
			orderBy,
			sortOrder,
			voterId
		});

		try {
			const result = await graphPost(`/topicVotes/${topicId}/withQna`, requestBody);
			const votes = mapFromFilteredVotes(result.votesWithQna);
				
			dispatch(loadVotesByVoter({
				topicId,
				voterId,
				totalVotes: result.totalCount,
				votes,
			}));
					
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);			
		}
	};
}

export function loadFilteredAnswersForQuestions({
	topicId, 
	dateFrom, 
	dateTo, 
	pageConfig, 
	feedFilters, 
	votersFilters,
	questions
}) {
	return async (dispatch, getState) => {
		dispatch(showLoading());
		dispatch({ type: actionTypes.LOAD_QUESTIONS_AND_ANSWERS, payload: { topicId } });

		const requestBodyWithFilters = buildFilteredRequestBody(topicId, dateFrom, dateTo, feedFilters, votersFilters);
		
		const orderBy = pageConfig ? pageConfig.orderBy : 'id';
		const sortOrder = pageConfig ? pageConfig.sortDirection : 'ASC';
		
		const requestBody = Object.assign({}, requestBodyWithFilters, {
			orderBy,
			sortOrder,
			questions,
			maxAnswers: 10
		});

		try {
			// needed so that applying or resetting inner filters will reflect properly on the individual questions
			const qnaSlice = getState().qnaForTopics[topicId];
			if(qnaSlice && !qnaSlice.isLoading){
				dispatch(loadQuestionsStatsForTopic(topicId, dateFrom, dateTo, votersFilters));
			}
			
			const result = await graphPost(`/topicqna/${topicId}/answersByQuestions`, requestBody);
			const questionsAndAnswers = mapFromFilteredAnswersForQuestions(result.qna);
				
			dispatch(loadFilteredAnswersForQuestionsSuccess({
				topicId,
				questionsAndAnswers,
			}));
					
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);			
		}
	};
}

export function loadFilteredAnswersForSingleQuestion({
	topicId, 
	dateFrom, 
	dateTo, 
	pageConfig, 
	feedFilters, 
	votersFilters,
	question
}) {
	return async dispatch => {
		dispatch(showLoading());
		dispatch({ type: actionTypes.LOAD_SINGLE_QUESTION_ANSWERS, payload: { topicId, question } });

		const requestBodyWithFilters = buildFilteredRequestBody(topicId, dateFrom, dateTo, feedFilters, votersFilters);
		
		const orderBy = pageConfig ? pageConfig.orderBy : 'id';
		const sortOrder = pageConfig ? pageConfig.sortDirection : 'ASC';
		const pageSize = pageConfig ? pageConfig.pageSize : 10;
		const offset = pageConfig ? pageConfig.offset : 0;

		const requestBody = Object.assign({}, requestBodyWithFilters, {
			orderBy,
			sortOrder,
			pageSize,
			offset,
			question,
		});

		try {
			const result = await graphPost(`/topicqna/${topicId}/questiondetails`, requestBody);
            const questionWithAnswers = mapFromFilteredAnswersForQuestions(result);
			const answers = isEmpty(questionWithAnswers) ? [] : Object.values(questionWithAnswers[question].answers);
			
			dispatch(loadFilteredAnswersForSingleQuestionsSuccess({
				topicId,
				question,
                answers,
                answersCount: result.totalCount
			}));
					
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);			
		}
	};
}

export function resetFilteredVotesByTopicId(topicId) {
	return dispatch => {
	  const payload = { topicId };
	  dispatch({ type: actionTypes.RESET_FILTERED_VOTES_WITH_QNA, payload });
	};
}

export function resetFilteredVotersByTopicId(topicId) {
	return dispatch => {
	  const payload = { topicId };
	  dispatch({ type: actionTypes.RESET_FILTERED_VOTERS, payload });
	};
}

export function resetVotesForVoter(topicId, voterId) {
	return dispatch => {
	  const payload = { topicId, voterId };
	  dispatch({ type: actionTypes.RESET_VOTES_FOR_VOTER, payload });
	};
}

export function sendCsvExportRequest({ 
	topicId, 
	dateFrom, 
	dateTo, 
	search, 
	feedFilters, 
	votersFilters
}) {
	return async dispatch => {
		dispatch(showLoading());

		const requestBody = buildFilteredRequestBody(topicId, dateFrom, dateTo, feedFilters, votersFilters);		
		if (search) {
			requestBody.filters = Object.assign({}, requestBody.filters, {
				search
			});
		};
		
		try {
			const csvFile = await graphPost(`/topicqna/${topicId}/exportv2`, requestBody);
			dispatch(hideLoading());
			
			// Makes the first row of the .csv file the name of the file and replaces " " with "-" so ancient file systems don't panic
			const fileName = csvFile.split('\r\n')[0].split(' ').join('-');
			downloadFile(csvFile, `${fileName}.csv`);
		} catch (error) {
			handleCommonError(dispatch, error);
		}
	};
}
 
function parseSentimentValues(sentiment) {
	const ranges = Object.keys(sentiment);

	const parsedRanges = ranges.map(range => {
		const rangeParts = range.split('-');
		const parsedRange = rangeParts.reduce((prev, curr) => {
			return `${(Number(prev) * 10).toString()}-${(Number(curr) * 10).toString()}`;
		});

		return parsedRange;
	});
	
	const parsedSentiment = {};
	ranges.forEach((r, i) => {
		parsedSentiment[parsedRanges[i]] = sentiment[r];
	});

	return parsedSentiment;
}

function buildFilteredRequestBody(topicId, dateFrom, dateTo, feedFilters, votesDatasetFilters) {
	if (isEmpty(topicId)) {
		Logger.error(`Empty topicId supplied to sendCsvExport Request!`);
	}

	if (!moment(dateFrom).isValid()) {
		dateFrom = moment(dateFrom);
	}

	if (!moment(dateTo).isValid()) {
		dateFrom = moment(dateTo);
	}

	const voterTypesArray = getVoterTypesArrayFromVoterFilters(votesDatasetFilters);

	const requestBody = {
		startDateTime: dateFrom,
		endDateTime: dateTo,
		filters: Object.assign({}, feedFilters),
		voterTypes: voterTypesArray,
		lastVoteOnly: votesDatasetFilters.latestPerVoter || false
	};

	if (votesDatasetFilters.sources) {
		requestBody.sources = votesDatasetFilters.sources.map(source => source.name);
	} else {
		requestBody.sources = [];
	}

	if (feedFilters.sentiment) {
		requestBody.filters.sentiment = parseSentimentValues(feedFilters.sentiment);
	}

	if (feedFilters.ageGroup) {
		requestBody.filters.age = Object.assign({}, feedFilters.ageGroup);
	}

	if (isEmpty(requestBody.filters.search)) {
		delete requestBody.filters.search;
	}

	return requestBody;
}

export function mapFromFilteredVotes({ answers, locations, questions, sources, voters, votes }) {
	return votes.map(vote => {
		const mappedVote = mapFilteredVoteDataToViewModel(vote, locations, sources, voters);
		mappedVote.qna = mapVoteQna(vote.qna, questions, answers);

		return mappedVote;
	});
}

function mapFilteredVoteDataToViewModel(voteData, locations, sources, voters) {
	const mappedVote = {
		id: voteData.id,
		vote: voteData.vote / 10,
		date: moment(voteData.ts * 1000).format(),
		gender: voteModel.getGenderByCodeName(voteData.gen),
		ageGroup: voteModel.getAgeGroupByBirthYear(voteData.by, voteData.ts),
		city: getVoteCityFromLocations(locations, voteData.cInd),
		country: getVoteCountryCodeFromLocations(locations, voteData.cInd),
		voterUniqueId: getVoterIdFromVoters(voters, voteData.vInd),
		sources: getVoteSources(sources, voteData.sInd)
	};

	return mappedVote;
}

function mapFilteredAnswerDataToViewModel({aInd, qInd, tta}, questions, answers) {
	const mappedQna = {
		aInd,
		qInd,
		question: questions && !isNaN(qInd) ? questions[qInd] : null,
		answer: answers && !isNaN(aInd) ? getAnswerWithoutLink(answers[aInd]) : null,
		attachedImageLink: answers && !isNaN(aInd) ? getAnswerLink(answers[aInd]) : null,
		timeSpent: tta,
	};

	return mappedQna;
}

function mapVoteQna(voteQnaItems, questions, answers) {
	return voteQnaItems.map(qna => {
		const mappedQna = mapFilteredAnswerDataToViewModel(qna, questions, answers);
		return mappedQna;
	});
}

export function mapFromFilteredVoters(votersData) {
	return votersData;
}

export function mapFromFilteredAnswersForQuestions({ locations, sources, voters, questions, answers, qna }) {
	const mappedQna = {};

	qna.forEach(qnaDataRow => {
		const mappedVoteData = mapFilteredVoteDataToViewModel(qnaDataRow, locations, sources, voters);
		const mappedAnswerData = mapFilteredAnswerDataToViewModel(qnaDataRow, questions, answers);
		
		if (!mappedQna[mappedAnswerData.question]) {
			mappedQna[mappedAnswerData.question] = {
				answers: []
			};
		}

		const newAnswerItem = Object.assign({}, mappedVoteData, mappedAnswerData);
		mappedQna[mappedAnswerData.question].answers = [...mappedQna[mappedAnswerData.question].answers, newAnswerItem];
	});

	return mappedQna;
}

const getVoteCityFromLocations = (locations, index) => {
    if(isEmpty(locations) || index === null){
        return null;
    }

    const location = locations[index];
    return location.split(',')[0].trim();
};

const getVoteCountryCodeFromLocations = (locations, index) => {
    if(isEmpty(locations) || index === null){
        return null;
    }

    const location = locations[index];
    return location.split(',')[1].trim();
};

const getVoteSources = (sources, index) => {
    if(isEmpty(sources) || index === null){
        return null;
    }

    const sourceString = sources[index];
    return sourceString.split(', ');
};

const getVoterIdFromVoters = (voters, index) => {
    if(isEmpty(voters) || index === null){
        return null;
    }

    return voters[index];
};