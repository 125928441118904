import React from 'react';
import PropTypes from 'prop-types';

const voteRangeLabel = ({low, high}) => {
	return (Number.isInteger(low) && Number.isInteger(high)) ? (
		<span>({`${low}.`}<small>1</small> - {`${high}.`}<small>0</small>)</span>
	) : (<span />);
};

voteRangeLabel.propTypes = {
	low: PropTypes.number,
	high: PropTypes.number
};

export default voteRangeLabel;
