import React from 'react';
import PropTypes from 'prop-types';
import TopicsListItem from './topicsListItem';


const TopicsList = ({ nestLevel, topics, hidden }) => {
    const className = `list-group ${hidden ? 'hide' : 'show'}`;

    const generateTopicsListItems = topics.map((subTopic, index) => {
        return <TopicsListItem
            key={subTopic.id} 
            topic={subTopic} 
            index={index}
            nestLevel={nestLevel}
            isStacked={false} />;
    });

    return (
        <div className={className}>
            {generateTopicsListItems}
        </div>
    );
};

TopicsList.propTypes = {
    topics: PropTypes.array.isRequired,
    nestLevel: PropTypes.number.isRequired,
    hidden: PropTypes.bool.isRequired,
};

export default TopicsList;