import { SUBSCRIPTION_RECURRING_TYPE } from '../actions/models/entityModel';


export const PLANS = [
    {
        tierId: 4,
        title: 'Free',
        description: 'Make use of our free features!',
        priceOptions:[
            {
                price: 0,
                period: SUBSCRIPTION_RECURRING_TYPE.MONTHLY
            }
        ],
        features: [
            '1 user access account',
            '1 active topic with questionnaire',
            'Analysis of Sentiment & Questionnaire'
        ],
        mainFeatures: [
            'Email support',
            'Within a week response time',
            'Features ideas submission'
        ]
    },
    {
        tierId: 3,
        title: 'Pro',
        description: 'Perfect for startup/freelance or single owned businesses',
        priceOptions:[
            {
                price: 29,
                period: SUBSCRIPTION_RECURRING_TYPE.MONTHLY
            },
            {
                price: 295,
                period: SUBSCRIPTION_RECURRING_TYPE.YEARLY
            }
        ],
        features: [
            '2 user access accounts',
            '5 active topics with questionnaires',
            'Analyse Sentiment & Questionnaire per topic',
            'Filter by type of vote and time range',
            'Add custom topic background image',
            'Create your own flow of questionnaire'
        ],
        mainFeatures: [
            'Email support',
            '1-2 business days response time',
            'Features idea submission'
        ]
    },
    {
        tierId: 2,
        title: 'Business',
        description: 'Full analytics with multiple user acounts for small and medium sized businesses',
        priceOptions:[
            {
                price: 290,
                period: SUBSCRIPTION_RECURRING_TYPE.MONTHLY
            },
            {
                price: 2950,
                period: SUBSCRIPTION_RECURRING_TYPE.YEARLY
            }
        ],
        features: [
            '5 user account access',
            '30 active topics with questionnaires',
            'Analyse Sentiment & Questionnaire per topic',
            'Filter by type of vote and time range',
            'Add custom topic background image',
            'Create your own flow of questionnaire',
            'Filter by sentiment and demographic data',
            'Receive vote alerts by email',
            'Create custom link preview for social media',
            'Export Graphs as PDF',
            'API connections package',
        ],
        mainFeatures: [
            'Email support',
            '1-2 business days response time',
            'Features idea submission'
        ]
    },
    {
        tierId: 1,
        title: 'Enterprise',
        description: 'Medium and large business plan with unlimited access & tailored functionality to meet custom needs',
        priceOptions: [],
        features: [
            'UNLIMITED user account access',
            'UNLIMITED active topics with questionnaire',
            'Analyse Sentiment & Questionnaire per topic',
            'Filter by type of vote and time range',
            'Add custom topic background image',
            'Create your own flow of questionnaire',
            'Filter by sentiment and demographic data',
            'Receive vote alerts by email',
            'Create custom link preview for social media',
            'Export Graphs as PDF',
            'API connections package',
            'Advanced topics localization',
            'Create private topics',
            'Export data to external files',
            'Create multiple dashboard setups'
        ],
        mainFeatures: [
            'Own channel & phone support',
            'ASAP response time',
            'Co-creation features requests'
        ]
    },
];