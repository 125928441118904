import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GroupByVoter from './groupByVoter/groupByVoter';

class GroupByVoterList extends Component {
	constructor(props) {
		super(props);

		this.handleNextVotesPageClick = this.handleNextVotesPageClick.bind(this);
		this.handleResetVotesForVoter = this.handleResetVotesForVoter.bind(this);
	}

	componentWillUnmount() {
		this.props.resetVoters();
	}

	handleNextVotesPageClick(voterId) {
        this.props.onLoadVotesForVoter(voterId);
    }

	handleResetVotesForVoter(voterId) {
		this.props.onResetVotesForVoter(voterId);
    }
    
	get votersList() {
		const {
			votersStats,
			questions
		} = this.props;

		return votersStats.map((voter, index) => {
			return <GroupByVoter
						key={index}
						voterData={voter}
						onRequestVotes={() => this.handleNextVotesPageClick(voter.voterId)}
						onResetVotes={() => this.handleResetVotesForVoter(voter.voterId)}
						questions={questions}
						hideDeprecatedQuestions={this.props.hideDeprecatedQuestions}
					/>;
		});
	}

	render() {
		return (
			<div className="group-by-voters-list">
				{ this.votersList }
			</div>
		);
	}
}

GroupByVoterList.propTypes = {
	votersStats: PropTypes.arrayOf(PropTypes.shape({
		gender: PropTypes.string,
		voterId: PropTypes.string.isRequired,
		voteChange: PropTypes.number,
		votesCount: PropTypes.number,
		meanRating: PropTypes.number,
		city: PropTypes.string,
		countryCode: PropTypes.string,
		ageGroup: PropTypes.string
	})).isRequired,

	questions: PropTypes.arrayOf(PropTypes.shape({
		orderIndex: PropTypes.number,
		question: PropTypes.string
	})),

	totalVotersCount: PropTypes.number,

	onLoadVotesForVoter: PropTypes.func.isRequired,
	
	onResetVotesForVoter: PropTypes.func.isRequired,
	
	resetVoters: PropTypes.func.isRequired,

	hideDeprecatedQuestions: PropTypes.bool
};

GroupByVoterList.defaultProps = {
	votersStats: [],
	question: [],
	totalVotersCount: 0,
	hideDeprecatedQuestions: false
};

export default GroupByVoterList;