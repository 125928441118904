import React from 'react';
import PropTypes from 'prop-types';
import { ENTITY_TIERS_NAMES } from '../../actions/models/entityModel';
import { Check, Star } from 'react-feather';

const SIZE_ICONS = 15;

const planFeaturesList = ({ planName, features }) => {
	function isFree() {
        return planName.toLowerCase() === ENTITY_TIERS_NAMES.FREE.toLowerCase(); 
    }

    function isPro() {
        return planName.toLowerCase() === ENTITY_TIERS_NAMES.PRO.toLowerCase(); 
    }

    function isBusiness() {
        return planName.toLowerCase() === ENTITY_TIERS_NAMES.BUSINESS.toLowerCase(); 
    }
	
	function displayFeatures() {
        if (features) {
            return features.map((feature, i) => {
                return <li key={i}>
                    {displayListItemIcon()} {feature}
                </li>;
            });
        } else {
            return null;
        }
	}

	function displayListItemIcon() {
        return isFree() ? <Check size={SIZE_ICONS}/> :
            isPro() ? <Star color={'#3797c4'} size={SIZE_ICONS}/> :
            isBusiness() ? <Star color={'#3767C4'} fill={'#3767C4'} size={SIZE_ICONS}/> :
            null;
    }
	
	return (
		<div className="features-overview">
            <ul>
                {displayFeatures()}
            </ul>
        </div>
	);
};

planFeaturesList.propTypes = {
    planName: PropTypes.string, 
    features: PropTypes.array
};

export default planFeaturesList;