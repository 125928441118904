import { SHOW_MODAL, HIDE_MODAL } from '../actions/actionTypes';

const initialState = {
	type: null,
	props: {}
};

export default function modalReducer(state = initialState, action) {
	switch (action.type) {
		case SHOW_MODAL:
			return {
				...state,
				component: action.payload.component,
				config: action.payload.config
			};
		case HIDE_MODAL:
			return initialState;
		default:
			return state;
	}
}