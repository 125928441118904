import React from 'react';
import PropTypes from 'prop-types';
import StatsCard from '../../components/topics/statsCard';
import VotesTrendChart from '../../components/topics/votesTrendChart';
import RatingBadge from '../../components/common/ratingBadge';
import OverallPieStatsBar from '../../components/topics/overallPieStatsBar';
import { getElapsedTime } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';

const HeaderStatsBar = props => {
	const { latestVotes } = props;

    const { t } = useTranslation();

	const {
		numberOfAnonymousUsers, 
		numberOfUniqueVotes, 
		numberOfAnonymousVotes, 
		count,
		numberOfRepeatedUsers,
        sumOfRepeatedVotes,
        numberOfIncreasedVoteUsers,
        numberOfDecreasedVoteUsers,
        averageVote
	} = props.stats;

	const anonPeoplePieValues = [
		{ name: 'macro_stats_anonymous_people', value: numberOfAnonymousUsers }, 
		{ name: 'macro_stats_all_people', value: numberOfUniqueVotes }
	];

	const anonVotesPieValues = [
		{ name: 'macro_stats_anonymous_votes', value: numberOfAnonymousVotes }, 
		{ name: 'macro_stats_all_votes', value: count }
	];

	const repeatingPeoplePieValues = [
		{ name: 'macro_stats_repeating_people', value: numberOfRepeatedUsers }, 
		{ name: 'macro_stats_all_people', value: numberOfUniqueVotes }
	];

	const repeatingVotesPieValues = [
		{ name: 'macro_stats_repeating_votes', value: sumOfRepeatedVotes }, 
		{ name: 'macro_stats_all_votes', value: count }
    ];
    
    const increasedRepeatingPeoplePieValues = [
        { name: 'macro_stats_opinion_improved', value: numberOfIncreasedVoteUsers }
    ];

    const decreasedRepeatingPeoplePieValues = [
        { name: 'macro_stats_opinion_decreased', value: numberOfDecreasedVoteUsers }
    ];

	const returningColors = ['#ff7b7b', '#7ba7ff'];
	const increaseOpinionReturningColors = ['#74fe44', '#a2a2a2'];
	const decreaseOpinionReturningColors = ['#f77a56', '#a2a2a2'];

	const getLatestVoteDateTimeFormatted = () => {
		let latestVoteDate = '';
		if (latestVotes && latestVotes.length) {
			latestVoteDate = getElapsedTime(latestVotes[0].date, false);
		}
		return latestVoteDate;
	};
	
	return (
		<div className={`master-topic-ratings flex`}>
            <div className="stats-col small export-pdf-stats-row">
                <div className="stats-row">
                    <StatsCard title={`${t('macro_stats_overall_rating_for')} ${t(props.timeIntervalLabel)}`} className="text-center">
                        <div className="direct-avg-rating">
                            <div className="direct-rating"><RatingBadge vote={averageVote || 0} size={24} /></div>
                        </div>
                    </StatsCard>
                </div>

                <div className="stats-row">
                    {latestVotes && latestVotes.length > 0 &&                   
                    <StatsCard title={t('macro_stats_votes_trend')} className="text-center">
                        <VotesTrendChart
                            votesDesc={latestVotes}
                            customLabel={true}
                            customLabelTitle={t('macro_stats_last_voted')}
                            customLabelValue={getLatestVoteDateTimeFormatted()}
                        />
                    </StatsCard>
                    }			
                </div>
            </div>
			
            <div className="stats-col">
                <div className="stats-row export-pdf-stats-row">
                    <StatsCard title={`${t('macro_stats_anonymous_voters_for')} ${t(props.timeIntervalLabel)}`}>
                        <OverallPieStatsBar segment={anonPeoplePieValues[0]} all={anonPeoplePieValues[1]} />
                    </StatsCard>
                </div>

                <div className="stats-row export-pdf-stats-row">
                    <StatsCard title={`${t('macro_stats_anonymous_votes_for')} ${t(props.timeIntervalLabel)}`}>
                        <OverallPieStatsBar segment={anonVotesPieValues[0]} all={anonVotesPieValues[1]} />
                    </StatsCard>			
                </div>                
            </div>

            <div className="stats-col">
                <div className="stats-row export-pdf-stats-row">
                    <StatsCard title={`${t('macro_stats_returning_people_for')} ${t(props.timeIntervalLabel)}`}>
                        <OverallPieStatsBar colors={returningColors} segment={repeatingPeoplePieValues[0]} all={repeatingPeoplePieValues[1]} />
                    </StatsCard>
                </div>

                <div className="stats-row export-pdf-stats-row">
                    <StatsCard title={`${t('macro_stats_votes_from_returning')} ${t(props.timeIntervalLabel)}`}>
                        <OverallPieStatsBar colors={returningColors} segment={repeatingVotesPieValues[0]} all={repeatingVotesPieValues[1]} />
                    </StatsCard>
                </div>
            </div>

            <div className="stats-col">
                <div className="stats-row export-pdf-stats-row">
                    <StatsCard title={`${t('macro_stats_increased_opinion')} ${t(props.timeIntervalLabel)}`}>
                        <OverallPieStatsBar colors={increaseOpinionReturningColors} segment={increasedRepeatingPeoplePieValues[0]} all={repeatingPeoplePieValues[0]} />
                    </StatsCard>
                </div>

                <div className="stats-row export-pdf-stats-row">
                    <StatsCard title={`${t('macro_stats_decreased_opinion')} ${t(props.timeIntervalLabel)}`}>
                        <OverallPieStatsBar colors={decreaseOpinionReturningColors} segment={decreasedRepeatingPeoplePieValues[0]} all={repeatingPeoplePieValues[0]} />
                    </StatsCard>
                </div>
            </div>
		</div>
	);
};

HeaderStatsBar.propTypes = {
	latestVotes: PropTypes.array,
	stats: PropTypes.shape({
        averageVote: PropTypes.number,
        count: PropTypes.number,
        numberOfUniqueVotes: PropTypes.number,
        sumOfRepeatedVotes: PropTypes.number,
        numberOfRepeatedUsers: PropTypes.number,
        numberOfIncreasedVoteUsers: PropTypes.number,
        numberOfDecreasedVoteUsers: PropTypes.number,
        avgNumberRepeatedVotes: PropTypes.number,
        percentMultiplePeopleOfTotal: PropTypes.number,
        percentRepeatedVotesOfTotal: PropTypes.number,
        numberOfAnonymousVotes: PropTypes.number,
        numberOfAnonymousUsers: PropTypes.number,
        percentAnonymousUsersOfTotal: PropTypes.number,
        percentAnonymousVotesOfTotal: PropTypes.number
    }),

	/**
	 * @param {string} timeIntervalLabel the selected time period filter label, eg. "today", "last 7 days"
	 */
	timeIntervalLabel: PropTypes.string
};

HeaderStatsBar.defaultProps = {
    latestVotes: [],
    stats: {
        averageVote: 0,
        count: 0,
        numberOfUniqueVotes: 0,
        sumOfRepeatedVotes: 0,
        numberOfRepeatedUsers: 0,
        numberOfIncreasedVoteUsers: 0,
        numberOfDecreasedVoteUsers: 0,
        avgNumberRepeatedVotes: 0,
        percentMultiplePeopleOfTotal: 0,
        percentRepeatedVotesOfTotal: 0,
        numberOfAnonymousVotes: 0,
        numberOfAnonymousUsers: 0,
        percentAnonymousUsersOfTotal: 0,
        percentAnonymousVotesOfTotal: 0
    }
};

export default HeaderStatsBar;