import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { cancelSubscription, getSubscriptions } from '../../actions/entitiesActions';
import { showModal, hideModal } from '../../actions/modalActions';

import Card from '../../components/common/card';
import { Repeat, CreditCard, CheckCircle, ArrowUpCircle, Activity, AlertOctagon, Clock, Archive } from 'react-feather';
import messages from '../../helpers/messages';
import { isEmpty } from 'lodash';
import { ENTITY_TIERS_NAMES } from '../../actions/models/entityModel';
import PlanFeaturesList from '../../components/common/planFeaturesList';
import { PLANS } from '../../helpers/plans';
import Logger from '../../helpers/logger';
import moment from 'moment';
import { SUBSCRIPTIONS } from 'hollerlive-shared/constants';

import './css/accountDetailsPage.css';
import planColorsCss from '../../components/common/css/plansCommon.module.css';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import PermissionsContext from '../contexts/permissionsContext';
import { PERMISSIONS, userHasPermissions } from '../../helpers/permissionsHelper';
import i18n from '../../i18n';

export class AccountDetailsPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEntityForEditValid: true,
			t: i18n.t.bind(i18n)
		};
		this.handleCancelSubscriptionClick = this.handleCancelSubscriptionClick.bind(this);
	}

	componentDidMount() {
		const { entity, actions } = this.props;
		if (!isEmpty(entity)) {
			actions.getSubscriptions(entity.id);
		}
	}

	componentDidUpdate(prevProps) {
		const { entity, actions } = this.props;
		if (isEmpty(prevProps.entity) && !isEmpty(entity)) {
			if (!entity.subscriptionsData) {
				actions.getSubscriptions(entity.id);
			}
		}
	}

	handleCancelSubscriptionClick() {
		const { actions, entity } = this.props;
		const { showModal, hideModal } = actions;
		if (entity.tierId !== 1) {
			const modalConfig = {
				title: "Confirm Subscription cancellation",
				message: messages.account.confirmCancellation,
				onClose: () => hideModal(),
				onConfirm: () => {
					this.onCancelSubscriptionConfirm();
					hideModal();
				},
			};
			showModal(CONFIRM_MODAL, modalConfig);
		}
	}

	onCancelSubscriptionConfirm() {
		this.props.actions.cancelSubscription(this.props.entity.id, this.activeSubscription.id);
	}

	get activeSubscription() {
		const { entity } = this.props;
		if (entity.subscriptionsData && entity.subscriptionsData.subscriptions) {
			return this.props.entity.subscriptionsData.subscriptions[0];
		}
		return null;
	}

	get validityDate() {
		const { entity } = this.props;
		if (entity.subscriptionsData && entity.subscriptionsData.subscriptions && entity.subscriptionsData.subscriptions.length > 0) {
			const validityDate = this.props.entity.subscriptionsData.subscriptions[0].validity;
			const dateMoment = moment(validityDate).format('D MMM YYYY');
			return dateMoment;
		}
		return "";
	}

	get paymentsListLink() {
		return <PermissionsContext hideIfNoPermission={true} requiredPermissions={[PERMISSIONS.EDIT_ENTITIES]}>
						<Link to={`/account/details/${this.props.entity.id}/invoices`} className={`btn btn-xs btn-outline ${this.invertTextColor ? 'btn-white' : 'btn-primary'}`}>
							<Archive size={16} /> Payments History
						</Link>
					</PermissionsContext>;
	}

	get subscriptionTypeLabel() {
		if (!this.props.entity || isEmpty(this.props.entity)) {
			return 'N/A';
		}
		if (!this.activeSubscription) {
			return 'N/A';
		}
		switch (this.activeSubscription.recurring) {
			case "month" :
				return 'Monthly';
			case "year" :
				return 'Yerly';
			default:
				return 'N/A';
		}
	}

	getCurrentPlanInfo() {
		const { entity } = this.props;
		if (isEmpty(entity)) {
			return {};
		}
		const currentPlan = PLANS.find(plan => {
			return plan.tierId === this.props.entity.tierId;
		});
		if (!currentPlan) {
			Logger.error(`No plan with tierId=${this.props.entity.tierId} found in PLANS Info collection.`);
			return {};
		} else {
			return currentPlan;
		}
	}

	get isFree() {
		const freeSubscription = SUBSCRIPTIONS.PLANS.find(plan => plan.name.toLowerCase().indexOf('free') > -1);
		return this.props.entity.tierId === freeSubscription.tierId;
	}

	get canUpgradeAccount() {
		const { user, entity } = this.props;
		const canUserEditEntities = userHasPermissions([PERMISSIONS.EDIT_ENTITIES], user);
		// TODO: once 1761 is done, check for !Enterprise instead of Free
		const isTierEligableForUpgrade = !isEmpty(entity) && entity.tierName.toLowerCase() === ENTITY_TIERS_NAMES.FREE.toLowerCase();
		return canUserEditEntities && isTierEligableForUpgrade;
	};

	get canCancelAccount() {
		const canEditEntity = userHasPermissions([ PERMISSIONS.EDIT_ENTITIES ], this.props.user);
		
		if (!this.activeSubscription || !canEditEntity) {
			return false;
		}

		return this.activeSubscription.status.toUpperCase() === SUBSCRIPTIONS.STATUSES.COMPLETED;
	}

	get invertTextColor() {
		return !this.isFree;
	}

	get validityComponent() {
		let validityComponent = <span />;

		if (!this.activeSubscription) {
			return validityComponent;
		}

		switch (this.activeSubscription.status.toUpperCase()) {
			case SUBSCRIPTIONS.STATUSES.COMPLETED:
				validityComponent = <React.Fragment><label><CreditCard size={18} /> next payment:</label> {this.validityDate} {this.paymentsListLink}</React.Fragment>;
				break;
			case SUBSCRIPTIONS.STATUSES.CANCELLED:
				validityComponent = <React.Fragment><label><AlertOctagon size={18} /> valid to:</label> {this.validityDate} {this.paymentsListLink}</React.Fragment>;
				break;
			case SUBSCRIPTIONS.STATUSES.PENDING:
				validityComponent = <React.Fragment><label><Clock size={18} /> Waiting payment</label> {this.paymentsListLink}</React.Fragment>;
				break;
			default:
				break;
		}
		
		return validityComponent;
	}

	render() {
		const { entity, children } = this.props;
		const currentPlan = this.getCurrentPlanInfo();
		
		const t = this.state.t;

		return (
			<div className="accountDetailsPage">
				<h2 className="page-heading">{t('my_org_title')}</h2>

				<div className="flex">
					<Card title={t('my_org_card_title')} className="organization-details">
						{children}
					</Card>

					<Card title={t('my_org_plan_title')} className={`account-plan ${planColorsCss[currentPlan.title ? `${currentPlan.title.toLowerCase()}Colors` : '']}`}>
						<h3>{entity.tierName}</h3>

						{!this.isFree && <React.Fragment>
							<p className="text-label">
								<label><Repeat size={18} /> {t('my_org_plan_subscription_type')}:</label> {this.subscriptionTypeLabel}
								{this.canUpgradeAccount && <Link to="/plans" className={`btn btn-xs btn-outline ${this.invertTextColor ? 'btn-white' : 'btn-primary'}`}>
									<ArrowUpCircle size={16} /> {t('my_org_plan_change_or_upgrade')}
								</Link>}
							</p>

							<p className="text-label">
								<label><Activity size={18} /> {t('my_org_plan_status')}:</label> {this.activeSubscription && this.activeSubscription.status}
							</p>

							<p className="text-label">
								{this.validityComponent}
							</p>
							{this.canCancelAccount && <button 
								className={`btn btn-transparent ${this.invertTextColor ? 'text-white' : 'text-danger'} btn-cancel`}
								onClick={this.handleCancelSubscriptionClick}
							>
								{t('my_org_plan_cancel_subscription')}
							</button>}
						</React.Fragment>}

						{!isEmpty(entity) && <div className="featres-list">
							<PlanFeaturesList features={currentPlan.features} planName={currentPlan.title} />
							{this.canUpgradeAccount && <Link to="/plans" className={`btn btn-outline btn-lg ${this.invertTextColor ? 'btn-white' : 'btn-primary'}`}>
								<CheckCircle /> {t('my_org_plan_upgrade_to_get_more')}
							</Link>}
						</div>}
					</Card>
				</div>
			</div>
		);
	}
}

AccountDetailsPage.propTypes = {
	entity: PropTypes.shape({
		name: PropTypes.string,
		id: PropTypes.number,
		active: PropTypes.bool,
		url: PropTypes.string,
		logoUrl: PropTypes.string,
		address: PropTypes.string,
		defaultTopicLanguage: PropTypes.string,
		systemNotifActive: PropTypes.bool,
		entityNotifActive: PropTypes.bool,
		voteNotifActive: PropTypes.bool,
		voteNotifIterval: PropTypes.number,
		tierName: PropTypes.string,
		tierId: PropTypes.number,
		features: PropTypes.shape({
			numberOfTopics: PropTypes.number
		}),
		subscriptionsData: PropTypes.shape({
			isLoading: PropTypes.bool,
			isLoaded: PropTypes.bool,
			subscriptions: PropTypes.arrayOf(PropTypes.shape({
				recurring: PropTypes.string,
				validity: PropTypes.string,
				status: PropTypes.string
			}))
		})
	}),
	entityUsers: PropTypes.arrayOf(PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		email: PropTypes.string,
		role: PropTypes.number
	})),

	user: PropTypes.shape({
		permissions: PropTypes.arrayOf(PropTypes.string)
	}),

	usersData: PropTypes.shape(),

	actions: PropTypes.shape({
		showModal: PropTypes.func.isRequired,
		hideModal: PropTypes.func.isRequired,
		cancelSubscription: PropTypes.func.isRequired
	}).isRequired,

	children: PropTypes.oneOfType([
		PropTypes.elementType,
		PropTypes.arrayOf(PropTypes.elementType),
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element)
	]),

	params: PropTypes.shape({
		id: PropTypes.string.isRequired
	}).isRequired
};

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			showModal,
			hideModal,
			cancelSubscription,
			getSubscriptions
		}, dispatch)
	};
}

function mapStateToProps(state) {
	let entityUsers = [];
	let entity = {};

	if (state.entities.length > 0) {
		entity = state.entities[0];
		entityUsers = state.usersData.usersList.filter(u => u.entityId === entity.id);
	}

	return {
		entity,
		usersData: state.usersData || {},
		entityUsers,
		user: state.auth.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsPage);