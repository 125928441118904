import ConfirmModal from '../components/modals/confirmModal';
import TopicLinksModal from '../components/modals/topicLinksModal';
import CreateTopicLinksModal from '../components/modals/createTopicLinksModal';
import NoRootTopicModal from '../components/modals/noRootTopicModal';
import ContactFormModal from '../components/modals/contactFormModal';
import ActivateUsersModal from '../components/modals/activateUsersModal';
import UserProfileEditModal from '../components/modals/userProfileEditModal';
import UserChangePasswordModal from '../components/modals/userChangePasswordModal';

export const CONFIRM_MODAL = 'CONFIRM_MODAL';
export const TOPIC_CREATED_MODAL = 'TOPIC_CREATED_MODAL';
export const TOPIC_LINKS_MODAL = 'TOPIC_LINKS_MODAL';
export const NO_ROOT_TOPIC_MODAL = 'NO_ROOT_TOPIC_MODAL';
export const CONTACT_FORM_MODAL = 'CONTACT_FORM_MODAL';
export const ACTIVATE_USERS_MODAL = 'ACTIVATE_USERS_MODAL';
export const USER_PROFILE_EDIT_MODAL = 'USER_PROFILE_EDIT_MODAL';
export const USER_CHANGE_PASSWORD_MODAL = 'USER_CHANGE_PASSWORD_MODAL';

export const MODAL_TYPES_COMPONENTS = {
	CONFIRM_MODAL: ConfirmModal,
	TOPIC_LINKS_MODAL: TopicLinksModal,
	TOPIC_CREATED_MODAL: CreateTopicLinksModal,
	NO_ROOT_TOPIC_MODAL: NoRootTopicModal,
	ACTIVATE_USERS_MODAL: ActivateUsersModal,
	CONTACT_FORM_MODAL: ContactFormModal,
	USER_PROFILE_EDIT_MODAL: UserProfileEditModal,
	USER_CHANGE_PASSWORD_MODAL: UserChangePasswordModal
};