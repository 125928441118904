import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import * as Icons from 'react-feather';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './css/entitiesGrid.css';

import Card from '../common/card';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import { ENTITY_TIERS_LIST } from "../../actions/models/entityModel";
import { isOnMobile } from '../../helpers/utils';
import { SUBSCRIPTIONS } from 'hollerlive-shared/constants';
import { useTranslation } from 'react-i18next';

export const EntitiesGrid = ({entities, onEntityDelete, onDetailsOpen, onSwitchEntity}, context) => {

    const { t } = useTranslation();

    function onConfirmDeleteEntity(entityId) {
        const entityToDel = entities.find(e => e.id === entityId);
        const modalConfig = {
            title: t('modal_org_delete_title'),
            message: t('modal_org_delete_message', { orgName: entityToDel.name }),
            onClose: () => context.hideModal(),
            onConfirm: () => {
                onEntityDelete(entityId);
                context.hideModal();
            },
        };
        context.showModal(CONFIRM_MODAL, modalConfig);
    };

    const trActiveEntityClass = (entity) => {
        return entity.active ? 'entity-active' : 'entity-inactive';
    };

    const actionsButtons = (entityId) => {
        return(
            <div>
                <button  
                    type="button"
                    onClick={() => onSwitchEntity(entityId)} 
                    className="btn btn-xs btn-outline uitest-switch"
                >
                    <Icons.RefreshCcw size={16} />{isOnMobile() ? '' : ` ${t('org_management_row_switch')}`}
                </button>
                &nbsp;
                {isOnMobile() ? <br/> : ''}
                <button 
                    type="button"
                    onClick={() => onConfirmDeleteEntity(entityId)} 
                    className="btn btn-xs btn-outline btn-danger uitest-delete"
                >
                    <Icons.XCircle size={16} />{isOnMobile() ? '' : ` ${t('org_management_row_delete')}`}
                </button>              
                &nbsp;
                {isOnMobile() ? <br/> : ''}
                <button onClick={() => onDetailsOpen(true, entityId)}
                    className="btn btn-outline btn-xs btn-primary uitest-details"
                >
                    <Icons.List size={14} />{isOnMobile() ? '' : ` ${t('org_management_row_details')}`}
                </button>
            </div>
        );
    };

    const alertsLabel = (voteNotifActive) => {
        const className = voteNotifActive ? 'text-primary text-bold' : 'text-muted text-light';
        return <label className={`${className} text-label`}>
            {voteNotifActive ? <Icons.Bell size={16} /> : <Icons.BellOff size={16} />}
            {isOnMobile() ? '' : `${voteNotifActive ? t('org_management_row_active_pl') : t('org_management_row_inactive_pl')}`}
        </label>;
    };

    const activeLabel = (active) => {
        const className = active ? 'text-primary text-bold' : 'text-muted text-light';
        return <label className={`${className} text-label`}>
            {active ? <Icons.Eye size={16} /> : <Icons.EyeOff size={16} />}
            {isOnMobile() ? '' : `${active ? t('org_management_row_active_f') : t('org_management_row_inactive_f')}`}
        </label>;
    };

    const nameLabel = (name) => {
      return <label className="text-label">{name}</label>;
    };
    
    const tierLabel = (tierName) => {
        return <label className="text-label">{tierName}</label>;
    };
       
    const tierNameSearchOptions = ENTITY_TIERS_LIST.reduce((map, obj)=>{
        map[obj.name] = obj.name;
        return map;
    }, {});

    const dateLabel = (date) => {
        return date && date.length ? moment(date).format('DD.MM.YY') : '';
    };

    const createdByLabel = (createdBy) => {
        return <label className="text-label">{createdBy}</label>;
    };

    const subscriptionLabel = (subscription, entity) => {
        if (subscription) {
            const { tierName } = entity;
            const { status, recurring, validity } = subscription;

            const stateLabel = subscriptionStatusLabel(status, validity);

            return <div className="subscription flex">
                {!isOnMobile() && 
                    <div className="subscription-icon"><Icons.RefreshCcw className={`label-${stateLabel}`} size={26} /></div>
                }
                <div className="subscription-data">
                    <p>
                        <span className={`text-label label-${stateLabel}`}>{stateLabel}</span>
                        {stateLabel === 'active' ? ' to ' : ' on '}
                        {dateLabel(validity)}
                    </p>
                    <p className="text-label">{recurring ? recurringLabel(recurring) : tierName}</p>
                </div>
            </div>;
        } else {
            return <div/>;
        }
    };

    const subscriptionStatusLabel = (status, validity) => {
        const isValid = validity ? moment().isSameOrBefore(validity) : false;
        const t = this.state.t;
        switch(status.toUpperCase()) {
            case SUBSCRIPTIONS.STATUSES.COMPLETED:
                return t('org_management_row_active_m');
            case SUBSCRIPTIONS.STATUSES.CANCELLED:
                return isValid ? t('org_management_row_expiring_m') : t('org_management_row_expired_m');
            case SUBSCRIPTIONS.STATUSES.FAILED:
                return t('org_management_row_expired_m');
            default:
                return status;
        }
    };

    const recurringLabel = (timeframe) => {
        switch (timeframe) {
            case 'month':
                return SUBSCRIPTIONS.PERIODS.MONTHLY.toLowerCase();
            case 'year':
                return SUBSCRIPTIONS.PERIODS.YEARLY.toLowerCase();
            default: 
                return '';
        }
    }

    const tdStyle = (_cell, entity) => {
        let style = {
            whiteSpace: 'normal'
        };

        if (!entity.active) {
            style.backgroundColor = '#f1f1f1';
        } 

        return style;
    };

    return (
        <>
            <h2 className="page-heading">{t('org_management_title')}</h2>

            <Card className="entities-list">
                <BootstrapTable 
                    data={entities} 
                    options={{
                        defaultSortName: 'name',
                        defaultSortOrder: 'asc',
                    }}
                    hover 
                    bordered={false}
                    trClassName={trActiveEntityClass}
                    tableHeaderClass='bg-dark'
                >
                    <TableHeaderColumn dataField='id' isKey dataSort={ true } hidden >{t('org_management_header_id')}</TableHeaderColumn>
                    <TableHeaderColumn dataField='name' 
                        dataFormat={nameLabel} 
                        filter={{ type: 'TextFilter' }} 
                        className='name-width' 
                        columnClassName='name-width'
                        dataSort={ true } 
                        tdStyle={tdStyle}
                    >{t('org_management_header_name')}</TableHeaderColumn>
                    <TableHeaderColumn dataField='created' 
                        dataFormat={dateLabel} 
                        dataSort={ true } 
                        className='created-on-width' 
                        columnClassName='created-on-width'
                        tdStyle={tdStyle}
                        hidden={isOnMobile()}
                    >{t('org_management_header_created_on')}</TableHeaderColumn>
                    <TableHeaderColumn dataField='createdBy' 
                        dataFormat={createdByLabel} 
                        filter={{ type: 'TextFilter', placeholder: 'Enter User Name' }} 
                        className='created-by-width' 
                        columnClassName='created-by-width'
                        tdStyle={tdStyle}
                        hidden={isOnMobile()}
                    >{t('org_management_header_created_by')}</TableHeaderColumn>
                    <TableHeaderColumn dataField='tierName'
                        dataFormat={tierLabel}
                        filter={{ type: 'SelectFilter', options: tierNameSearchOptions }}
                        dataAlign='center' 
                        className='tiers-width' 
                        columnClassName='tiers-width'
                        tdStyle={tdStyle}
                        hidden={isOnMobile()}
                    >{t('org_management_header_tier')}</TableHeaderColumn>
                    <TableHeaderColumn dataField='subscription'
                        dataFormat={subscriptionLabel}
                        dataAlign='center' 
                        className='subscription-width' 
                        columnClassName='subscription-width'
                        tdStyle={tdStyle}
                    >{t('org_management_header_subscription')}</TableHeaderColumn>
                    <TableHeaderColumn dataField='active'
                        dataFormat={activeLabel} 
                        dataAlign='center'
                        className='active-width' 
                        columnClassName='active-width' 
                        tdStyle={tdStyle} 
                        thStyle={{ whiteSpace: 'normal' }}
                    >{t('org_management_header_active')}</TableHeaderColumn>
                    <TableHeaderColumn dataField='voteNotifActive'
                        dataFormat={alertsLabel} 
                        dataAlign='center' 
                        className='alerts-width' 
                        columnClassName='alerts-width' 
                        tdStyle={tdStyle} 
                        thStyle={{ whiteSpace: 'normal' }}
                    >{t('org_management_header_vote_alerts_1')}{isOnMobile() ? <br/> : ' '}{t('org_management_header_vote_alerts_2')}</TableHeaderColumn>
                    <TableHeaderColumn dataField='id' 
                        dataFormat={actionsButtons} 
                        dataAlign='center' 
                        className='actions-width' 
                        columnClassName='actions-width' 
                        tdStyle={tdStyle}
                    >{t('org_management_header_actions')}</TableHeaderColumn>
                </BootstrapTable>
            </Card>
        </>
    );
};

EntitiesGrid.propTypes = {
    entities: PropTypes.array.isRequired,

    /** @prop {func} onEntityDelete delete entity by id handler
     * @param {string} entityId the entity id to be deleted
     */
    onEntityDelete: PropTypes.func.isRequired,
    onSwitchEntity: PropTypes.func.isRequired,
    onDetailsOpen: PropTypes.func.isRequired
};

EntitiesGrid.contextTypes = {
    hideModal: PropTypes.func,
    showModal: PropTypes.func
};

export default EntitiesGrid;