import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import StatsCard from '../../components/topics/statsCard';
import RatingBadge from '../../components/common/ratingBadge';
import OverallPieStatsBar from '../../components/topics/overallPieStatsBar';
import { CheckCircle, ThumbsUp, BarChart2 } from 'react-feather';
import './css/overallMacroStats.css';

import { useTranslation } from 'react-i18next';

const PieChartStats = ({ label, avgVote, topicId, topicName, segmentData, allData, colors, isLoading, isLoaded }) => {
	const dataLoaded = isLoaded && !isLoading;
	const hasValue = dataLoaded && segmentData.value;

	return (
        <StatsCard title={label} className={`${hasValue ? '' : 'empty'}`}>
            {hasValue && (
                <label>
                    <RatingBadge vote={avgVote} size={14} isLoading={isLoading} />
                    <Link to={`/topics/details/${topicId}/graphs`} onlyActiveOnIndex={false}>
                        {topicName}
                    </Link>
                    <BarChart2 size={18} />
                </label>
            )}

            <OverallPieStatsBar
                segment={segmentData}
                all={allData}
                colors={colors}
                isLoading={isLoading}
                isLoaded={isLoaded}
                useAsyncLoad={true}
            />
        </StatsCard>
    );
};

const OverallMacroStats = ({ 
	timeIntervalLabel,
	totalVotesCount,
	totalAnswersCount,
	highestRatedTopic,
	lowestRatedTopic,
	mostVotedTopic,
	mostAnsweredTopic,
	isLoadedStats,
	isLoadingStats
}) => {
    const highestRatedTopicPieData = { name: '', value: highestRatedTopic.stats.votesCount };
	const lowestRatedTopicPieData = { name: '', value: lowestRatedTopic.stats.votesCount };
	const mostVotedTopicPieData = { name: '', value: mostVotedTopic.stats.votesCount };
	const mostAnsweredTopicPieData = { name: '', value: mostAnsweredTopic.stats.answersCount };
	
	const allVotesPieData = { name: 'all votes', value: totalVotesCount };
	const allAnswersPieData = { name: 'all answers', value: totalAnswersCount };

	const highestRatedColors = ['#74fe44', '#bbbbbb'];
	const lowestRatedColors = ['#f77a56', '#bbbbbb'];

	const { t } = useTranslation();

	return (
		<div className={`overall-micro-stats flex`}>
            <div className="stats-col always-horizontal">
				<div className="stats-row">
					<StatsCard title={t('macro_stats_total_votes', { timeIntervalLabel })}>
						<div className="flex">
							<div className="stats-info text-center">
								<span className="numbers"><CheckCircle size={24} /> {totalVotesCount}</span>
							</div>
						</div>
					</StatsCard>
				</div>
			
				<div className="stats-row">
					<StatsCard title={t('macro_stats_total_answers', { timeIntervalLabel })}>
						<div className="flex">
							<div className="stats-info text-center">
								<span className="numbers"><ThumbsUp size={24} /> {totalAnswersCount}</span>
							</div>
						</div>
					</StatsCard>
				</div>
			</div>	
			<div className="stats-col">
				<div className="stats-row">
					<PieChartStats
						label={t('macro_stats_most_voted_topic', { timeIntervalLabel })}
						avgVote={mostVotedTopic.stats.avgVote || 0}
						topicId={mostVotedTopic.id}
						topicName={mostVotedTopic.name}
						segmentData={mostVotedTopicPieData}
						allData={allVotesPieData}
						colors={['#7ba7ff', '#bbbbbb']}
						isLoaded={isLoadedStats}
						isLoading={isLoadingStats}
					/>
				</div>
			</div>
			<div className="stats-col">
				<div className="stats-row">
					<PieChartStats
						label={t('macro_stats_most_answered_topic', { timeIntervalLabel })}
						avgVote={mostAnsweredTopic.stats.avgVote || 0}
						topicId={mostAnsweredTopic.id}
						topicName={mostAnsweredTopic.name}
						segmentData={mostAnsweredTopicPieData}
						allData={allAnswersPieData}
						colors={['#7ba7ff', '#bbbbbb']}
						isLoaded={isLoadedStats}
						isLoading={isLoadingStats}
					/>
				</div>
			</div>
			<div className="stats-col">
				<div className="stats-row">
					<PieChartStats
						label={t('macro_stats_highest_rated_topic', { timeIntervalLabel })}
						avgVote={highestRatedTopic.stats.avgVote || 0}
						topicId={highestRatedTopic.id}
						topicName={highestRatedTopic.name}
						segmentData={highestRatedTopicPieData}
						allData={allVotesPieData}
						colors={highestRatedColors}
						isLoaded={isLoadedStats}
						isLoading={isLoadingStats}
					/>
				</div>
			</div>	
			<div className="stats-col">
				<div className="stats-row">
					<PieChartStats
						label={t('macro_stats_lowest_rated_topic', { timeIntervalLabel })}
						avgVote={lowestRatedTopic.stats.avgVote || 0}
						topicId={lowestRatedTopic.id}
						topicName={lowestRatedTopic.name}
						segmentData={lowestRatedTopicPieData}
						allData={allVotesPieData}
						colors={lowestRatedColors}
						isLoaded={isLoadedStats}
						isLoading={isLoadingStats}
					/>
				</div>
			</div>
		</div>
	);
};

PieChartStats.propTypes = {
	label: PropTypes.string, 
	avgVote: PropTypes.number, 
	topicId: PropTypes.string, 
	topicName: PropTypes.string, 
	segmentData: PropTypes.object, 
	allData: PropTypes.object,
	colors: PropTypes.array,
	isLoading: PropTypes.bool,
	isLoaded: PropTypes.bool
};

OverallMacroStats.propTypes = {
    timeIntervalLabel: PropTypes.string,
    totalVotesCount: PropTypes.number,
    totalAnswersCount: PropTypes.number,
    mostVotedTopic: PropTypes.shape({
        avgVote: PropTypes.number,
        topicVotesCount: PropTypes.number,
        stats: PropTypes.object
    }).isRequired,
    mostAnsweredTopic: PropTypes.shape({
        avgVote: PropTypes.number,
        topicAnswersCount: PropTypes.number,
        stats: PropTypes.object
    }).isRequired,
    highestRatedTopic: PropTypes.shape({
        avgVote: PropTypes.number,
        topicVotesCount: PropTypes.number,
        stats: PropTypes.object
    }).isRequired,
    lowestRatedTopic: PropTypes.shape({
        avgVote: PropTypes.number,
        topicVotesCount: PropTypes.number,
        stats: PropTypes.object
    }).isRequired,
    isLoadingStats: PropTypes.bool,
    isLoadedStats: PropTypes.bool,
    label: PropTypes.string,
    avgVote: PropTypes.number,
    topicId: PropTypes.any,
    topicName: PropTypes.string,
    segmentData: PropTypes.array,
    allData: PropTypes.array,
    colors: PropTypes.array,
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool
};

OverallMacroStats.defaultProps = {
    mostVotedTopic: { stats: {} },
    mostAnsweredTopic: { stats: {} },
    lowestRatedTopic: { stats: {} },
    highestRatedTopic: { stats: {} }
};

export default OverallMacroStats;