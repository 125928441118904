import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Toggle from '../../common/form/toggle';
import { SENTIMENT_OPTIONS, GENDER_OPTIONS, AGE_GROUPS_OPTIONS, getParsedFiltersGroup } from '../../../helpers/graphsService';
import { Check, ChevronDown, ChevronUp } from 'react-feather';
import Card from '../../common/card';

import './css/datasetFilters.css';
import FeaturesContext from "../../../scenes/contexts/featuresContext";
import { ENTITY_FEATURES } from 'hollerlive-shared/constants';
import { COMPARATORS } from "../../../helpers/featuresHelper";
import NoPermissionLabel from '../../common/noPermissionLabel';
import messages from '../../../helpers/messages';
import i18n from '../../../i18n';

export class DatasetFilters extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filtersGroupChangedName: '',
			filtersActive: true,
			filtersOpen: false,
			t: i18n.t.bind(i18n)
		};

		this.handleFiltersActiveToggle = this.handleFiltersActiveToggle.bind(this);
		this.handleFiltersChanged = this.handleFiltersChanged.bind(this);
		this.handleResetFilters = this.handleResetFilters.bind(this);
		this.handleFiltersOpenToggle = this.handleFiltersOpenToggle.bind(this);
	}

	handleFiltersOpenToggle() {
		this.setState({ filtersOpen: !this.state.filtersOpen });
	}

	handleFiltersActiveToggle() {
		const willFiltersBeActive = !this.state.filtersActive;

		this.setState({
			filtersActive: willFiltersBeActive,
			filtersGroupChangedName: ''
		}, () => {
			this.props.onDeactivateFilters(willFiltersBeActive);
		});
	}

	handleFilterOptionToggle(filterName, selection, groupFieldName) {
		this.setState({
			filtersGroupChangedName: `${this.state.filtersGroupChangedName},${groupFieldName}`
		});

		const changedGroup = Object.assign({}, this.props.values, {
			[groupFieldName]: Object.assign({}, this.props.values[groupFieldName], {
				[filterName]: selection
			})
		});

		this.props.onFiltersGroupValueChange(changedGroup);
	}

	handleFiltersChanged() {
		const { sentiment, ageGroup, gender } = this.props.values;
		this.setState({
			filtersOpen: false,
			filtersGroupChangedName: ''
		}, () => {
			this.props.onApplyFilters({ sentiment, ageGroup, gender });
		});
	}

	handleResetFilters() {
		const sentiment = getParsedFiltersGroup(SENTIMENT_OPTIONS);
		const gender = getParsedFiltersGroup(GENDER_OPTIONS);
		const ageGroup = getParsedFiltersGroup(AGE_GROUPS_OPTIONS);

		this.setState({
			filtersOpen: false,
		}, () => {
			this.props.onApplyFilters({ sentiment, ageGroup, gender });
		});
	}

	get sentimentOptionsDisabled() {
		return this.state.filtersActive ? this.props.disableSentimentOptions : true;
	}

	renderFiltersGroup(options, groupFieldName) {
		const t = this.state.t;
		return options.map((o, i) => {
			const value = this.props.values[groupFieldName][o.label.toLowerCase()];
			const disableSentimentOptions = groupFieldName === 'sentiment' ? this.sentimentOptionsDisabled : !this.state.filtersActive;

			return <div className="filter-option-toggle" key={i}>
				<Toggle
					value={value}
					onChange={(selection) => this.handleFilterOptionToggle(o.label.toLowerCase(), selection, groupFieldName)}
					disabled={disableSentimentOptions}
					className='dataset-toggle' />
				{t('topic_vote_feed_toggle_show')} <span className={`${this.state.filtersActive ? o.className : 'text-muted'}`}><strong>{t(o.displayLabel)}</strong></span>
			</div>;
		});
	}

	getFeedFilterLabelForType(filterName, label) {
		const t = this.state.t;
		let selectionsLabel = `${t('topic_vote_feed_showing_options_all')} ${t(label)}`;
		const filters = this.props.values;

		if (filters) {
			const hasSentimenFiltering = Object.values(filters[filterName]).indexOf(false) > -1;
			if (hasSentimenFiltering) {
				selectionsLabel = <strong>{t('topic_vote_feed_showing_options_filtered')} {t(label)}</strong>;
			}
		}

		return selectionsLabel;
	}

	get datasetFiltersButtonLabel() {
		const t = this.state.t;
		return (
			<React.Fragment>
				{this.getFeedFilterLabelForType('sentiment', 'topic_vote_feed_showing_options_sentiment')},&nbsp;
				{this.getFeedFilterLabelForType('gender', 'topic_vote_feed_showing_options_gender')} {t('topic_vote_feed_showing_options_and')}&nbsp;
				{this.getFeedFilterLabelForType('ageGroup', 'topic_vote_feed_showing_options_age_groups')}
			</React.Fragment>
		);
	}

	render() {
		const { className } = this.props;
		const { t, filtersActive, filtersOpen } = this.state;

		return (
			<Card className="options dataset-filters-container">
				<div className="common-filters-bar clearfix">
					<label onClick={this.handleFiltersOpenToggle}><span className="static-text">{t('topic_vote_feed_showing_feed_data')} </span>{this.datasetFiltersButtonLabel} {t('topic_vote_feed_showing_options')}</label>

					<FeaturesContext
						requiredFeatures={[{
							name: ENTITY_FEATURES.ADVANCED_FILTERING,
							compare: COMPARATORS.IS_TRUE
						}]}
						renderOtherwise={
							<span className="actions pull-right">
								<NoPermissionLabel message={messages.permissions.features.feedFilters} />
								{t('topic_vote_feed_showing_options_off')} <Toggle value={false} disabled={true} className='dataset-toggle' />
								<button className="btn btn-transparent" disabled={true}>
									<ChevronDown size="18" />
								</button>
							</span>
						}
					>
						<span className="actions pull-right">
							{filtersActive ? t('topic_vote_feed_showing_options_on') : t('topic_vote_feed_showing_options_off')}
							<Toggle value={filtersActive} onChange={this.handleFiltersActiveToggle} className='dataset-toggle' />
							<button className="btn btn-transparent" onClick={this.handleFiltersOpenToggle}>
								{filtersOpen
									? <ChevronUp size="18" />
									: <ChevronDown size="18" />
								}
							</button>
						</span>
					</FeaturesContext>
				</div>

				{filtersOpen &&
					<div className={`dataset-filters ${this.state.filtersActive ? '' : 'text-muted'} ${className}`}>
						<h5>{t('topic_vote_feed_filter_by_sentiment')}</h5>
						<div className="filter-options-group">{this.renderFiltersGroup(SENTIMENT_OPTIONS, 'sentiment')}</div>

						<hr />

						<h5>{t('topic_vote_feed_filter_by_gender')}</h5>
						<div className="filter-options-group">{this.renderFiltersGroup(GENDER_OPTIONS, 'gender')}</div>

						<hr />

						<h5>{t('topic_vote_feed_filter_by_age_group')}</h5>
						<div className="filter-options-group">{this.renderFiltersGroup(AGE_GROUPS_OPTIONS, 'ageGroup')}</div>

						<div className="btn-toolbar text-right clearfix">
							<button className="btn btn-outline" onClick={this.handleResetFilters}>{t('topic_vote_feed_filter_reset')}</button>
							<button className="btn btn-outline btn-primary" onClick={this.handleFiltersChanged} disabled={this.state.filtersGroupChangedName.length === 0}><Check size={18} /> {t('topic_vote_feed_filter_apply')}</button>
						</div>
					</div>
				}
			</Card>
		);
	}
}

DatasetFilters.propTypes = {
	className: PropTypes.string,

	/** @prop {func} onApplyFilters action handler to invoke filters apply */
	onApplyFilters: PropTypes.func.isRequired,

	/** @prop {func} onFiltersGroupValueChange action handler to indicate filters group change */
	onFiltersGroupValueChange: PropTypes.func.isRequired,

	/** 
	 * @prop {func} onDeactivateFilters action handler to indicate filters activation or deactivation 
	 * @param {bool} filters will be active/inactive */
	onDeactivateFilters: PropTypes.func.isRequired,

	disableSentimentOptions: PropTypes.bool,

	values: PropTypes.shape({
		sentiment: PropTypes.shape(),
		gender: PropTypes.shape(),
		ageGroup: PropTypes.shape()
	})
};

DatasetFilters.defaultProps = {
	disableSentimentOptions: false,
	values: {}
};

export default DatasetFilters;
