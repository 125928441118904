import React from 'react';
import PropTypes from 'prop-types';
import { getElapsedTime } from '../../helpers/utils';
import './css/modificationStats.css';
import { UploadCloud, Edit3 } from 'react-feather';
import { useTranslation } from 'react-i18next';

const ModificationStats = ({ createdOn, createdBy, modifiedOn, modifiedBy }) => {
	
	const { t } = useTranslation();

	return (
		<div className='modification-stats'>
			{createdOn && createdBy && (
				<p>
					<UploadCloud size={14} /> {t('topic_builder_progress_published')} {' '}
					<strong className='uitest-createdon'>{getElapsedTime(createdOn, false)}</strong> {t('topic_builder_progress_by')}{' '}
					<strong className='uitest-createdby'>{createdBy}</strong>
				</p>
			)}
			{modifiedOn && modifiedBy && (
				<p>
					<Edit3 size={14} /> {t('topic_builder_progress_last_edited')} {' '}
					<strong className='uitest-modifiedon'>{getElapsedTime(modifiedOn, false)}</strong> {t('topic_builder_progress_by')}{' '}
					<strong className='uitest-modifiedby'>{modifiedBy}</strong>
				</p>
			)}
		</div>
	);
};

ModificationStats.propTypes = {
	createdOn: PropTypes.string,
	createdBy: PropTypes.string,
	modifiedOn: PropTypes.string,
	modifiedBy: PropTypes.string
};

export default ModificationStats;
