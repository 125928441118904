import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import * as topicActions from '../../actions/topicActions';
import * as modalActions from '../../actions/modalActions';
import { switchToEntity } from "../../actions/entitiesActions";
import Card from '../../components/common/card';
import { Edit3, BarChart2, UserX } from 'react-feather';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';

class TopicSearchPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: 0,
			searchValue: ""
		};
		
		this.PAGE_SIZE = 25;
		
		this.handleSearchTopics = this.handleSearchTopics.bind(this);
		this.handleLoadNextPageClick = this.handleLoadNextPageClick.bind(this);
	}
	
	handleSearchTopics(e) {
		e.preventDefault();
		const searchValue = e.target.searchValue.value;
		if (searchValue) {
			this.setState({
				currentPage: 1,
				searchValue: searchValue
			}, () => {
				this.props.actions.resetAdminTopics();
				this.props.actions.searchTopicsByName(this.state.searchValue, this.state.currentPage);
			});
		}
	}
	
	handleLoadNextPageClick() {
		this.setState({
			currentPage: this.state.currentPage + 1
		}, () => {
			this.props.actions.searchTopicsByName(this.state.searchValue, this.state.currentPage);
		});
	}
	
	get nextPageTopicsCount() {
		const { totalCount } = this.props.topics;
		const topicsLeft = totalCount - (this.state.currentPage * this.PAGE_SIZE);
		
		return topicsLeft < this.PAGE_SIZE ? Math.max(topicsLeft, 0) : this.PAGE_SIZE;
	}
	
	componentWillUnmount() {
		this.props.actions.resetAdminTopics();
	}
	
	handleEditTopic(topic) {
		if (topic.entityId !== this.props.entity.id) {
			const confirmChangeEntityModal = {
				title: `Confirm switch to entity ${ topic.entityName }`,
				message: `Are you sure you want to switch to entity "${ topic.entityName }" in order to edit topic "${ topic.name }"?`,
				onClose: () => {
					this.props.actions.hideModal();
				},
				onConfirm: () => {
					this.props.actions.hideModal();
					this.props.actions.switchToEntity(topic.entityId, `/topics/edit/${ topic.id }`);
				}
			};
			
			this.props.actions.showModal(CONFIRM_MODAL, confirmChangeEntityModal);
		} else {
			this.props.router.push(`/topics/edit/${ topic.id }`);
		}
	}
	
	_getTopicsTableRows() {
		const { topics } = this.props.topics;
		return topics.map((topic, i) => {
			return (
				<tr key={ i }>
					<td>{ topic.id }</td>
					<td>{ topic.name }</td>
					<td>{ topic.entityName }</td>
					<td>
						{ topic.modifiedBy && topic.modifiedOn ?
							`${ topic.modifiedBy } on ${ moment(topic.modifiedOn).format('YYYY-MM-DD HH:mm') }` :
							topic.createdBy && topic.createdOn ?
								`${ topic.createdBy } on ${ moment(topic.createdOn).format('YYYY-MM-DD HH:mm') }` :
								<UserX size={ 18 }/> }
					</td>
					<td>
						<button onClick={()=>this.handleEditTopic(topic)} className="btn btn-outline btn-xs btn-outline-primary">
							<Edit3 size={ 14 }/> edit
						</button>
						&nbsp;
						<Link to={ `/topics/details/${ topic.id }/graphs` } className='btn btn-outline btn-xs btn-outline-primary'>
							<BarChart2 size={ 14 }/> graphs
						</Link>
					</td>
				</tr>);
		});
	}
	
	render() {
		const { topics } = this.props.topics;
		
		return (
			<Fragment>
				<h2 className="page-heading">
					Search Topics
				</h2>
				<div className="col-md-12 col-xs-12">
					<Card pad title="search topics">
						<form onSubmit={ this.handleSearchTopics }>
							<div className="form-group row">
								<label className='col-md-12 col-xs-12'>topic name</label>
								<div className="col-xs-9">
									<input className='form-control' type='text' name="searchValue"/>
								</div>
								<div className="col-xs-3">
									<button className='btn btn-success' type="submit">search</button>
								</div>
							</div>
						</form>
						
						{ topics && topics.length > 0 &&
						<Fragment>
							<table className='table'>
								<thead className='thead-light'>
								<tr>
									<td>ID</td>
									<td>Topic name</td>
									<td>Entity name</td>
									<td>Last modified</td>
									<td>Actions</td>
								</tr>
								</thead>
								<tbody>
								{ this._getTopicsTableRows() }
								</tbody>
							</table>
							<div className="text-center">
								<button disabled={ this.nextPageTopicsCount === 0 } className="btn btn-outline btn-dotted btn-full-width" type="button"
								        onClick={ this.handleLoadNextPageClick }>
									{ `load next ${ this.nextPageTopicsCount }` }
								</button>
							</div>
						</Fragment> }
					</Card>
				</div>
			</Fragment>
		);
	}
}

TopicSearchPage.defaultProps = {
	topics: {
		topics: [],
		totalCount: 0
	},
	entity: {}
};

TopicSearchPage.propTypes = {
	topics: PropTypes.object.isRequired,
	entity: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		topics: state.adminTopics,
		entity: state.entities[0]
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, topicActions, modalActions, {switchToEntity}), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicSearchPage);
