import { intersection, difference } from 'lodash';

export const COMPARATORS = {
	IS_TRUE: feature => feature === true,
	GREATER_THAN_OR_ZERO: (featureValue, value) => featureValue > value || featureValue === 0
};

/**
 * entityHasFeatures checks required features against passed entity's existing ones
 * features checked are only boolean values! do not use to check numberOf values
 * returns a boolean of whether the entity has needed features or not
 * @param {array} requiredFeatures an array of strings to check entity features against
 * @param {object} entity the current entity
 * @param {bool} matchSingle flag 
    * if true matches at least one of the required features against entity features; 
    * when false all required must match entity's features
 */
export function entityHasFeatures(requiredFeatures, entity = {}, matchSingle = false) {
	const entityFeatures = [];
	for (const feature in entity.features) {
		const featureValue = entity.features[feature];
		if (typeof featureValue === 'boolean' && entity.features[feature] === true) {
			entityFeatures.push(feature);
		}
	}

	return matchSingle ?
	  Boolean(intersection(requiredFeatures, entityFeatures).length) :
	  difference(requiredFeatures, entityFeatures).length === 0;
  }