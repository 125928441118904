import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Eye, EyeOff, ExternalLink, Globe, Box, ArrowUpCircle, Home } from 'react-feather';
import styles from './css/entityDetails.module.css';
import { ENTITY_TIERS_NAMES } from '../../actions/models/entityModel';
import PermissionsContext from '../../scenes/contexts/permissionsContext';
import { PERMISSIONS } from '../../helpers/permissionsHelper';
import { isEmpty } from 'lodash';
import * as countries from 'hollerlive-shared/resources/countries-min';
import { useTranslation } from 'react-i18next';

const entityDetails = ({ entity }) => {

	const { t } = useTranslation();

	const labelActive = entity.active ? 
							<span className="text-primary"><Eye size={18} /> active</span>
							: <span className="text-muted"><EyeOff size={18} /> inactive</span>;
	
	let numberOfTopics = "n/a";
	if (entity.features) {
		numberOfTopics = entity.features.numberOfTopics === 0 ? "unlimited" : entity.features.numberOfTopics.toString();
	}

	const entityImage = entity.logoUrl ? <img src={entity.logoUrl} alt="organization logo" /> : <Box size={32} />;

	// TODO: once 1761 is done, check for !Enterprise instead of Free
	const canUpgradeAccount = () => {
		return !isEmpty(entity) && entity.tierName.toLowerCase() === ENTITY_TIERS_NAMES.FREE.toLowerCase();
	};

	const getParsedUrl = function(url) {
		const regex = /^(https?:\/\/)[^<>!()[\]{}@'"|]*$/;
		const hasHttp = regex.test(url);

		return hasHttp ? url : `http://${url}`;
	};

	const getAddress = ({ address, city, state, postalCode, countryCode }) => {
		let addressParts = [];

		if (address) {
			if (postalCode) {
				addressParts.push(`${postalCode} ${address}`);
			} else {
				addressParts.push(address);
			}
		}

		if (city) {
			addressParts.push(city);
		}

		if (state) {
			addressParts.push(state);
		}

		if (countryCode) {
			const country = countries.find(c => c.code.toLowerCase() === countryCode.toLowerCase());
			
			if (country) {
				addressParts.push(country.name);
			}	
		}

		return addressParts.join(", ");
	};

	const addressLabel = getAddress(entity);

	return (
		<div className={styles.entityDetails}>
			<div className="flex">
				<div className={styles.logo}>
					{entityImage}
				</div>
				<div className="details">
					<p className="text-label">{labelActive}</p>
					<h4>{entity.name}</h4>
					<p>
						<label>{t('my_org_details_account_plan')}: {entity.tierName} </label>
						• <label>{t('my_org_details_topics_limit')} {numberOfTopics}</label>
						&nbsp;&nbsp;{canUpgradeAccount() && <PermissionsContext hideIfNoPermission={true} requiredPermissions={[PERMISSIONS.EDIT_ENTITIES]}>
							<Link to="/plans" className="btn btn-outline btn-xs btn-primary"><ArrowUpCircle size={14} /> {t('my_org_details_upgrade_to_get_more')}</Link>
						</PermissionsContext>}
					</p>
					<p>
						{entity.url && <a className="uitest-entity-url" href={getParsedUrl(entity.url)} target="_blank" rel="noopener noreferrer"><ExternalLink size={14} /> {entity.url}</a>}
						{addressLabel.length > 0 && <React.Fragment> • <Home size={14} /> {addressLabel}</React.Fragment>}
					</p>
					{entity.defaultTopicLanguage && <p><label>{t('my_org_details_default_language')}: </label><label className="text-label"><Globe size={14} />{entity.defaultTopicLanguage}</label></p>}
				</div>
			</div>
		</div>
	);
};

entityDetails.propTypes = {
	entity: PropTypes.shape({
		logoUrl: PropTypes.string,
		active: PropTypes.bool,
		name: PropTypes.string,
		url: PropTypes.string,
		defaultTopicLanguage: PropTypes.string,
		tierName: PropTypes.string.isRequired,
		features: PropTypes.shape({
			numberOfTopics: PropTypes.number
		})
	})
};

entityDetails.defaultProps = {
	entity: {
		features: {}
	}
};

export default entityDetails;