import * as types from './actionTypes';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { graphGet, streamGet, streamPut, graphPost } from '../api/expressApi';
import { addNotification } from './miscActions';
import { handleCommonError } from './errorActions';
import { notificationLevel} from '../helpers/notification';
import { createTopicHash } from '../helpers/utils';

export function loadTopicByIdSuccess(topic){
    return {type: types.LOAD_TOPIC_SUCCESS, payload: topic};
}

export function loadTopicStructureSuccess(topic) {
    return { type: types.LOAD_TOPIC_STRUCTURE_SUCCESS, payload: { topic } };
}

export function loadTopicsForEntitySuccess(topics, entityId) {
    return { type: types.LOAD_TOPICS_FOR_ENTITY_SUCCESS, payload: { topics, entityId } };
}

export function resetTopics(){
    return { type: types.RESET_TOPICS };
}

export function resetAdminTopics(){
    return {type: types.RESET_ADMIN_TOPICS_STATE };
}

function deleteTopicSuccess(){
    return { type: types.DELETE_TOPIC_SUCCESS };
}

function loadTopicsForChannelSuccess(channel, topics){
    return { type: types.LOAD_TOPICS_FOR_CHANNEL_SUCCESS, payload: {channel, topics}};
}

function removeTopicFromChannelSuccess(channel, topicName) {
    return { type: types.REMOVE_TOPIC_FROM_CHANNEL_SUCCESS, payload: { channel, topicName } };
}

function getTopicsByNameSuccess(topics) {
    return { type: types.GET_TOPICS_PAGE_BY_NAME_SUCCESS, payload: topics };
}

export function loadTopicById(topicId) {
    return dispatch => {
        dispatch(showLoading());
        graphGet('/topics/' + topicId).then(topic => {
            dispatch(loadTopicByIdSuccess(topic));
            dispatch(loadTopicStructureById(topicId));
			dispatch(hideLoading());
        },
        error => {
            handleCommonError(dispatch, error);        
		});
    };
}

export function loadTopicStructureById(topicId) {
    return (dispatch, getState) => {
        const topic = getState().topics.find(topicItem => topicItem.id === topicId);
        if (!(topic.basicSettings && topic.linkConfig)) {
            const topicHash = createTopicHash(topic.name);
            dispatch(showLoading());
            return streamGet(`/topics/${topicHash}`, { mode: "view" })
                .then(topic => {
                    dispatch(loadTopicStructureSuccess(topic));
                    dispatch(hideLoading());
                },
                    error => {
                        handleCommonError(dispatch, error);
                    });
        }
    };
}

export function loadTopicsForEntity(entityId) {
    return async dispatch => {
        try {
            dispatch(showLoading());
            const topics = await graphPost('/topics/forentity/' + entityId);
            dispatch(loadTopicsForEntitySuccess(topics, entityId));
            dispatch(hideLoading());
        } catch (error) {
            handleCommonError(dispatch, error);
        }
    };
}

export function deleteTopic(topicId, topicName) {
    return async (dispatch, getState) => {
        dispatch(showLoading());
        try {
            await graphGet(`/topics/${topicId}/delete`);
            if (getState().entities.length > 0) {
                dispatch(loadTopicsForEntity(getState().entities[0].id));
            }
            dispatch(addNotification(`Topic "${topicName}" deleted.`,  notificationLevel.success));
            dispatch(hideLoading());
            dispatch(deleteTopicSuccess());
        } catch (error) {
            handleCommonError(dispatch, error, false);
        }
    };
}

export function loadTopicsForChannel(channelHash){
    return dispatch => {
        dispatch(showLoading());

        streamGet(`/channels/${channelHash}`).then(topics => {
            dispatch(loadTopicsForChannelSuccess(channelHash, topics));
            dispatch(hideLoading());
        }, error => {
            handleCommonError(dispatch, error);
        });
    };
}

export function reorderTopicsInChannel(channelHash, topics){
    return dispatch => {
        dispatch(showLoading());

        const topicHashes = topics.map(t => t.hash);
        streamPut(`/channels/reorder/${channelHash}`, {topics: topicHashes}).then(() => {
            dispatch(addNotification(`Channel "${channelHash}" reordered.`, notificationLevel.success));
            dispatch(hideLoading());
        }, error => {
            handleCommonError(dispatch, error);
        });
    };
}

export function removeTopicFromChannel(channelHash, topic){
    return dispatch => {
        dispatch(showLoading());
        
        streamPut(`/channels/removetopic`, {topicName: topic.name, channelHash}).then(() => {
            dispatch(addNotification(`Topic ${topic.name} removed from channel ${channelHash}`, notificationLevel.success));
            dispatch(removeTopicFromChannelSuccess(channelHash, topic.name));
            dispatch(hideLoading());
        }, error => {
            handleCommonError(dispatch, error);
        });
    };
}

export function searchTopicsByName(topicName, page = 1){
    return dispatch => {
        dispatch(showLoading());
    
        graphGet(`/admin/searchTopics`, { topicName, page }).then((topics) => {
            dispatch(getTopicsByNameSuccess(topics));
            dispatch(hideLoading());
        }, error => {
            handleCommonError(dispatch, error);
        });
    };
}
