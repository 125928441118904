import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from '../reducers/index';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import socketMiddleware from './socketMiddleware';

export default function configureStore(initialState) {
    if(process.env.NODE_ENV !== 'production'){
        return configureDevStore();
    }else{
        return configureProdStore();
    }
}

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;

function configureDevStore(initialState){
    return createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant(), socketMiddleware))
    );
}

function configureProdStore(initialState){
    return createStore(
        rootReducer,
        initialState,
        applyMiddleware(thunk, socketMiddleware)
    );
}