import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ModalRoot = (props) => {
	if (!props.modal.component) {
		return <span />;
	}

	const ModalComponent = props.modal.component;
	const propsForModalComponent = Object.assign({}, props.modal.config);
	return <ModalComponent  {...propsForModalComponent} />;
};

ModalRoot.propTypes = {
	modal: PropTypes.shape({
		config: PropTypes.shape({
			title: PropTypes.string,
			width: PropTypes.number,
			data: PropTypes.object
		}),
        component: PropTypes.any
	}).isRequired
};

function mapStateToProps(state) {
	return {
		modal: state.modal
	};
}

export default connect(mapStateToProps, {})(ModalRoot);
