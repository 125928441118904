import React from 'react';
import PropTypes from 'prop-types';
import './css/confirmOnClose.css';
import Messages from '../../helpers/messages';

const ConfirmOnClose = props => {
	let shouldDisplayConfirm = true;

	function onConfirm(ev){
		ev.preventDefault();
		props.onConfirm();
	}

	function onCancel(ev){
		ev.preventDefault();
		props.onDecline();
	}

	const { 
		overlayClass, 
		overlayHeight, 
		overlayWidth,
	} = props;

	const overlayStyle = {};
	let fullSizeOverlay = false;

	if (overlayWidth === undefined) {
		fullSizeOverlay = true;
	} else {
		overlayStyle.width = `${overlayWidth}px`;
	}

	if (overlayHeight === undefined) {
		fullSizeOverlay = true;
	} else {
		overlayStyle.height = `${overlayHeight}px`;
	}

	if (fullSizeOverlay) {
		overlayStyle.top = 0;
		overlayStyle.left = 0;
		overlayStyle.height = '100%';
		overlayStyle.width = '100%';
	}

	return (
		<div className="confirm-on-close-holder">
			{shouldDisplayConfirm && 
			<div className={`${overlayClass} confirm-on-close-overlay ${fullSizeOverlay ? 'full-size' : ''}`} style={overlayStyle}>
				<div className="message-confirm-container">
					<p>{props.message || Messages.common.defaultConfirmMessage}</p>
					<div className="btn-toolbar">
						<button type="button" className="btn btn-xs btn-outline btn-danger" onClick={onConfirm}>Yes</button>
						<button type="button" className="btn btn-xs btn-outline" onClick={onCancel}>No</button>
					</div>
				</div>
			</div>
			}
		</div>
	);
};

ConfirmOnClose.propTypes = {
	overlayWidth: PropTypes.number,
	overlayHeight: PropTypes.number,
    overlayClass: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	onDecline: PropTypes.func.isRequired,
	message: PropTypes.string,
};

export default ConfirmOnClose;