import moment from 'moment';
import logger from './logger';
import { getAgeGroupName } from 'hollerlive-shared/utils';
import { isEmpty } from 'lodash';
import { getDefaultVoteModel } from '../actions/models/voteModel';

export function parseRawVoteDataToJsonArray(rawVoteData) {
    if(!rawVoteData){
        logger.warn("the response body is empty");
        return [];
    }
    if(rawVoteData.total === 0){
        return [];
    }

    const dictsanddatastring = rawVoteData.pipeSepData;
    const dictsanddata = dictsanddatastring.split('|');
    const cityDict = rawVoteData.cityDictionary.split('~').map(v => {
        if(v === ""){
            return "unknown";
        }
        return v;
    });
    const regionDict = rawVoteData.regionDictionary.split('~').map(v => {
        if(v === ""){
            return "unknown";
        }
        return v;
    });

    let authTypeDict = {};
    // temporary: will remove the check when version 1 is no longer supported
    if(rawVoteData.modelVersion && rawVoteData.modelVersion === 2){
        authTypeDict = rawVoteData.authTypeDictionary.split('~');
    }

    const values = [];

    for (let i = 0; i < dictsanddata.length; i++) {
        let rawRow = dictsanddata[i];

        let voteValue = Number(rawRow.substring(0, 2));
        if (voteValue === 0) { voteValue = 100; }
        voteValue /= 10;

        let geocommaidx = rawRow.indexOf(',');
        let leftparenidx = rawRow.indexOf(')');

        let latt = rawRow.substring(2, geocommaidx);
        if (!latt || !latt.trim()) {
            latt = undefined;
        } else {
            latt = parseFloat(latt);
        }

        let long = rawRow.substring(geocommaidx + 1, leftparenidx);
        if (!long || !long.trim()) {
            long = undefined;
        } else {
            long = parseFloat(long);
        }

        let timestampUnix = rawRow.substring(leftparenidx + 1, leftparenidx + 11);
        let datestamp = undefined;
        if (timestampUnix && timestampUnix.trim()) {
            datestamp = new Date(timestampUnix * 1000);
        }

        let gender = rawRow.substring(leftparenidx + 11, leftparenidx + 12).trim();
        if (gender === 'm') { gender = 'male'; }
        if (gender === 'f') { gender = 'female'; }
        if (gender === 'o' || !gender || !gender.trim()) { gender = undefined; }

        let birthYear = rawRow.substring(leftparenidx + 12, leftparenidx + 15);
        if (birthYear.indexOf('9') === 0) {
            birthYear = '1' + birthYear;
        }
        if (birthYear.indexOf('0') === 0) {
            birthYear = '2' + birthYear;
        }
        birthYear = Number(birthYear);

        let age = undefined;
        let ageGroup = undefined;
        if (birthYear > 0 && datestamp) {
			age = datestamp.getFullYear() - birthYear;
			ageGroup = getAgeGroupName(age);
		}

        let locale = rawRow.substring(leftparenidx + 15, leftparenidx + 20);
        if (!locale || !locale.trim()) { locale = undefined; }

        let countrycode = rawRow.substring(leftparenidx + 20, leftparenidx + 22);
        if (!countrycode || !countrycode.trim()) { countrycode = undefined; }

        let voterSeparatorIndex = rawRow.indexOf('!');
        let cityRegionAndIdPart;
        let authType = undefined;
        let voterUniqueId = undefined;
        if(rawVoteData.modelVersion && rawVoteData.modelVersion === 2){
            cityRegionAndIdPart = rawRow.substring(leftparenidx + 22, voterSeparatorIndex);
            const voterPart = rawRow.substring(voterSeparatorIndex + 1);
            const voterData = voterPart.split('!');
            const authIndex = Number(voterData[0]);
            authType = authTypeDict[authIndex];
            voterUniqueId = voterData[1];
        } else {
            cityRegionAndIdPart = rawRow.substring(leftparenidx + 22);
        }

        let cityRegionAndIdArray = cityRegionAndIdPart.split('~');

        let cityIndex = Number(cityRegionAndIdArray[0]);
        let cityname = undefined;
        if(cityRegionAndIdArray[0] && cityIndex >=0 && cityDict[cityIndex]){
            cityname = cityDict[cityIndex];
        }

        let regionIndex = Number(cityRegionAndIdArray[1]);
        let regionname = undefined;
        if(cityRegionAndIdArray[1] && regionIndex >=0 && regionDict[regionIndex]){
            regionname = regionDict[regionIndex];
        }
        
        let rowid = Number(cityRegionAndIdArray[2]);

        let sources = rawRow.substring(
          rawRow.lastIndexOf("[") + 1,
          rawRow.lastIndexOf("]")
        );

        if (sources.length > 0) {
            sources = sources.split(',');
        } else {
            sources = [];
        }

        const voteModel = Object.assign({}, getDefaultVoteModel(), {
            "vote": voteValue,
            "latitude": latt,
            "longitude": long,
            "date": moment(datestamp).format(),
            "gender": gender,
            "age": age,
            "ageGroup" : ageGroup,
            "locale": locale,
            "country": countrycode,
            "city": cityname,
            "region": regionname,
            "rowId": rowid,
            "authType": authType,
            "voterUniqueId": voterUniqueId,
            "sources": sources
        });

        values.push(voteModel);
    }
    return values;
}

export const VOTE_CHART_MODES = { STACK: 0, MEAN: 1 };
export const LATEST_VOTES_MAX_COUNT = 10;

export function getGenderGroupData(rawVotes) {
    const votesByGender = {};
	rawVotes.forEach(voteValue => {
		const gender = voteValue.gender;
		if (!votesByGender[gender]) {
			votesByGender[gender] = { gender, voteSum: 0, votesCount: 0 };
		}
		votesByGender[gender].voteSum += voteValue.vote;
		votesByGender[gender].votesCount++;
    });
    return Object.values(votesByGender).map(voteByGender => 
		Object.assign({}, voteByGender, {
			vote: Math.round((voteByGender.voteSum / voteByGender.votesCount) * 10) / 10
		})
    );
}

export function getAgeGroupData(rawVotes) {
    const votesByAge = {};
	rawVotes.forEach(voteValue => {
		const ageGroup = voteValue.ageGroup;
		if (!votesByAge[ageGroup]) {
			votesByAge[ageGroup] = { ageGroup, voteSum: 0, votesCount: 0 };
		}
		votesByAge[ageGroup].voteSum += voteValue.vote;
		votesByAge[ageGroup].votesCount++;
	});
	return Object.values(votesByAge).map(voteByAge =>
		Object.assign({}, voteByAge, { vote: Math.round((voteByAge.voteSum / voteByAge.votesCount) * 10) / 10 })
	);
}

// TODO: currently city coordinates are taken from the first vote only => must have some adjustment loic
export function getLocationGroupData(rawVotes){
    const cityDict = {};
    rawVotes.forEach(v => {
        const city = v.city;
        if (!cityDict[city]) {
            cityDict[city] = {
                city: city,
                cityCoords: {latitude: v.latitude, longitude: v.longitude},
                voteSum: 0,
                votesCount: 0
            };
        }
        cityDict[city].voteSum += v.vote;
        cityDict[city].votesCount += 1;        
    });
    return Object.values(cityDict).map(c => {
        return Object.assign({}, c, { vote: Math.round(c.voteSum / c.votesCount * 10) / 10 });
    });
}

export function getSourceStackData(rawVotes) {
    return rawVotes
		.map(vote => {
			if (!isEmpty(vote.sources)) {
			    // split votes by sources:
				return vote.sources.map(source => Object.assign({}, vote, { sources: [source] }));
			} else {
				return [];
			}
		})
		.reduce((votes, vote) => votes.concat(vote));
}

export function getSourceGroupData(rawVotes){
    const sourcesDict = {};

	rawVotes.forEach(v => {
		const voteSources = v.sources || [];
		voteSources.forEach(s => {
			if (!sourcesDict[s]) {
				sourcesDict[s] = {
					source: s,
					voteSum: 0,
					votesCount: 0
				};
			}
			sourcesDict[s].voteSum += v.vote;
			sourcesDict[s].votesCount += 1;
		});
	});
	return Object.values(sourcesDict).map(s => {
		return Object.assign({}, s, { vote: Math.round((s.voteSum / s.votesCount) * 10) / 10 });
	});
}

/**
 * calculates voting stats
 * @param {array} votes of objects with keys: 
 *  age, ageGroup, authType, city, country, date, gender, latitude, locale, longitude, region, rowId, sources, vote, voterUniqueId
 */
export function calculateVoteStats(votes) {
    const voteSum = votes.reduce((sum, v) => {
        return sum + v.vote;
    }, 0);
    const averageVote = votes.length === 0 ? 0 : Math.round(voteSum / votes.length * 10)/10;
    let users = new Map();
    let overallRepeatedCount = 0;
    let usersWithRepeatedVotes = 0;
    let anonymousUsers = 0;
    let anonymousVotes = 0;
    let numberOfIncreasedVoteUsers = 0;
    let numberOfDecreasedVoteUsers = 0;
    votes.forEach(vote => {
        if(vote.authType === 'anonymous')
            anonymousVotes++;
        if(users.has(vote.voterUniqueId)) {
            let tempArray = users.get(vote.voterUniqueId);
            tempArray.push(vote);
            users.set(vote.voterUniqueId, tempArray);
        } else {
            users.set(vote.voterUniqueId, [vote]);  
        }
    });
    users.forEach((value) => {
        if (value[0].authType === 'anonymous') {
            anonymousUsers++;
        }
        if ( value.length > 1) {
            usersWithRepeatedVotes++;
            overallRepeatedCount += value.length;
        }
        // find first and last votes
        let firstVote = value[0];
        let lastVote = value[0];
        for (let i = 1; i < value.length; i ++) {
            if (value[i].date < firstVote.date) {
                firstVote = value[i];
            } else if (value[i].date > lastVote.date) {
                lastVote = value[i];
            }
        } 
        // count in/decreasing vote scores
        if (firstVote.vote > lastVote.vote) {
            numberOfDecreasedVoteUsers++;
        } else if (firstVote.vote < lastVote.vote) {
            numberOfIncreasedVoteUsers++;
        }
    });
    let averageNumberOfVotes = overallRepeatedCount / usersWithRepeatedVotes;
    let percentMultiplePeople = ( usersWithRepeatedVotes / users.size ) * 100;
    let percentRepeatedVotes = ( overallRepeatedCount / votes.length ) * 100;
    let percentAnonymousUsers = ( anonymousUsers / users.size ) * 100;
    let percentAnonymousVotes = ( anonymousVotes / votes.length ) * 100;
    return {
        averageVote,
        count: votes.length,
        numberOfUniqueVotes: users.size,
        sumOfRepeatedVotes: overallRepeatedCount,
        numberOfRepeatedUsers: usersWithRepeatedVotes,
        avgNumberRepeatedVotes: (Math.round(averageNumberOfVotes * 100) / 100 ) || 0,
        numberOfIncreasedVoteUsers,
        numberOfDecreasedVoteUsers,
        percentMultiplePeopleOfTotal: (Math.round(percentMultiplePeople * 100) / 100 ) || 0,
        percentRepeatedVotesOfTotal: (Math.round(percentRepeatedVotes * 100) / 100 ) || 0,
        numberOfAnonymousVotes: anonymousVotes,
        numberOfAnonymousUsers: anonymousUsers,
        percentAnonymousUsersOfTotal: (Math.round(percentAnonymousUsers * 100) / 100 ) || 0,
        percentAnonymousVotesOfTotal: (Math.round(percentAnonymousVotes * 100) / 100 ) || 0
    };
}

export function getVoteStats(voteWithQna) {
    const defaultStats = {
        questionsAnsweredCount: 0,
        totalTime: 0,
        avgTime: 0
    };
    if (isEmpty(voteWithQna)) {
        return defaultStats;
    }
    if (isEmpty(voteWithQna.qna)) {
        return defaultStats;
    }
    const qnaWithTimeSpentData = voteWithQna.qna.filter(qna => qna.timeSpent !== null);
	return {
		questionsAnsweredCount: qnaWithTimeSpentData.length,
		totalTime: getAnswerTotalTime(qnaWithTimeSpentData),
		avgTime: getAnswerAvgTime(qnaWithTimeSpentData)
	};
}

function getAnswerTotalTime(voteQna) {
	let totalTime = 0;
	voteQna.forEach(vote => {
		totalTime += vote.timeSpent;
	});
	return totalTime;
}

function getAnswerAvgTime(voteQna) {
    if(voteQna.length === 0){
        return 0;
    }
	return getAnswerTotalTime(voteQna) / voteQna.length;
}
/**
 * calculates lifetime voting stats 
 * @param {array} topics - array of topic-objects that is expected to have stats property 
 * @returns {object} - allVotes and allAnswers counts, along with timestamps of the very first and very last vote */
export function calculateLifetimeStats(topics) {
    let firstVoteTime = null;
    let lastVoteTime = null;
    let allVotesCount = 0;
    let allAnswersCount = 0;
    if (topics && topics.length > 0) {
        topics.forEach(topicWithStats => {
            const stats = topicWithStats.stats;
            if (stats) {
                if (!firstVoteTime || stats.firstVoteTime < firstVoteTime) {
                    firstVoteTime = stats.firstVoteTime;
                }
                if (!lastVoteTime || stats.lastVoteTime > lastVoteTime) {
                    lastVoteTime = stats.lastVoteTime;
                }
                allVotesCount += !isNaN(stats.lifetimeVotesCount) ? stats.lifetimeVotesCount : 0;
                allAnswersCount += !isNaN(stats.lifetimeAnswersCount) ? stats.lifetimeAnswersCount : 0;
            }
        });
    }
    return { allVotesCount, allAnswersCount, firstVoteTime, lastVoteTime };
}

/**
 * calculates overall voting stats
 * @param {array} topicsStats - array of topic-objects that is expected to have stats property
 * @returns {object} - totalVotes and totalAnswers counts,
 * along with highest rated, lowest rated, most voted and most answered topic objects */
export function calculateMacroStats(topicsStats) {
    let totalVotesCount = 0;
    let totalAnswesCount = 0;
    let highestRated = null;
    let lowestRated = null;
    let mostVoted = null;
    let mostAnswered = null;
    if (topicsStats && topicsStats.length > 0) {
        totalVotesCount = topicsStats.reduce((acc, topicWithStats) => {
            if (topicWithStats.stats) {
                return topicWithStats.stats.votesCount ? acc + topicWithStats.stats.votesCount : acc;
            }
            return acc;
        }, 0);
        totalAnswesCount = topicsStats.reduce((acc, topicWithStats) => {
            if (topicWithStats.stats) {
                return topicWithStats.stats.answersCount ? acc + topicWithStats.stats.answersCount : acc;
            }
            return acc;
        }, 0);
        const filterTopicsWithVotes = topicsStats.filter(topic => topic.stats && topic.stats.votesCount > 0);
        const sortedByHighestRating = [...filterTopicsWithVotes].sort((a, b) => {
            return b.stats.avgVote - a.stats.avgVote;
        });
        const sortedByMostVoted = [...filterTopicsWithVotes].sort((a, b) => {
            return b.stats.votesCount - a.stats.votesCount;
        });
        const sortedByMostAnswered = [...filterTopicsWithVotes].sort((a, b) => {
            return b.stats.answersCount - a.stats.answersCount;
        });
        if (sortedByHighestRating.length > 0) {
            highestRated = sortedByHighestRating[0];
            lowestRated = sortedByHighestRating[sortedByHighestRating.length - 1];
        }
        if (sortedByMostVoted.length > 0) {
            mostVoted = sortedByMostVoted[0];
            mostAnswered = sortedByMostAnswered[0];
        }
    }
    return { totalVotesCount, totalAnswesCount, highestRated, lowestRated, mostVoted, mostAnswered };
}