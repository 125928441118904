import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { isEmpty } from 'lodash';
import utils from 'hollerlive-shared/utils';
import { Phone, Mail, Globe, Briefcase, Box, Eye, EyeOff } from 'react-feather';
import { getRoleNameById } from '../../helpers/user';
import { LOCALE } from '../../helpers/locales';
import styles from './css/userDetails.module.css';

const UserDetails = ({ user }) => {
	const labelActive = user.active ? 
		<span className="text-primary"><Eye size={18} /> active</span> 
	:
		<span className="text-muted"><EyeOff size={18} /> inactive</span>;
	
	const userImage = !isEmpty(user.avatarUrl) ? 
		<img src={user.avatarUrl} alt="user avatar" /> 
	: 
		<Box size={32} />;
    
	const userRoleInEntity = user.roleId ? getRoleNameById(user.roleId) : '';
	const userEntity = !isEmpty(user.entity) ? <Link to={`/account/details/${user.entity.id}`}>{user.entity.name}</Link> : '';
	const userEmail = <a href={`mailto:${user.email}`}>{user.email}</a>;

	const userPhone = !isEmpty(user.phone) ? user.phone : null;
    const userLocale = !isEmpty(user.locale) ?
        LOCALE.find(loc => loc.id === user.locale) : null;
	const userIndustry = user.industryId ? 
		utils.getIndustries().find(ind => ind.id === user.industryId) : null;

    return (
		<div className='user-details'>
			<div className="flex">
				<div className={`${styles.avatar} uitest-avatar`}>
					{userImage}
				</div>
				<div className="details">
					<p className="text-label uitest-active">{labelActive}</p>
					<h4 className='uitest-main-info'>{`${user.firstName} ${user.lastName}, ${userRoleInEntity.length ? `${userRoleInEntity} at ` : ''}`} {userEntity}</h4>
					<p>
						<label>
							<Mail size={14} /> {userEmail} 
						</label>
                        {userPhone && 
						<> •
							<label><Phone size={14} /> {userPhone}</label>
						</>
						}
					</p>
					<p>
                        {userLocale && 
						<label><Globe size={14} /> {userLocale.name} </label>
						}
                        {userIndustry && 
						<> •
							<label><Briefcase size={14} /> {userIndustry.name}</label>
						</>
						}
					</p>
				</div>
			</div>
		</div>
	);
};

UserDetails.propTypes = {
	user: PropTypes.shape({
		active: PropTypes.bool.isRequired,
		avatarUrl: PropTypes.string,
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        entityId: PropTypes.number,
        phone: PropTypes.string,
        locale: PropTypes.string,
        timeZone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        roleId: PropTypes.number,
        entity: PropTypes.shape()
	})
};

UserDetails.defaultProps = {
	user: {
        phone: '',
        locale: '',
	}
};

export default UserDetails;