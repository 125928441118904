import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as graphsService from '../../../helpers/graphsService';
import Logger from '../../../helpers/logger';

class BaseTimeScaleGraph extends Component {

	get timeScaleOptions() {
		const { dateFrom, dateTo } = this.props;
		
		const allScaleOptions = graphsService.GRAPH_TIME_SCALE_OPTIONS.map((o) => {
			return {
				label: o.label,
				value: o.value,
				disabled: true
			};
		});

		this._updateScaleOptions(dateFrom, dateTo, allScaleOptions);

		return allScaleOptions;
	}

	get timeScale(){
		const { dateFrom, dateTo, timeScaleLabel } = this.props;
		if(timeScaleLabel){
			const matchingTimeScale =  this.timeScaleOptions.find(t => t.label === timeScaleLabel);
			if(!matchingTimeScale){
				Logger.error(`The provided timescale: ${matchingTimeScale} is not found in the allowed list for the current date selection`);
			}
			return matchingTimeScale;
		}

		const timeUnit = graphsService.getVegaTimeUnitForPeriod(dateFrom, dateTo);
		const selectedTimeScale = this.timeScaleOptions.find(t => t.value === timeUnit);
		return selectedTimeScale;
	}

	_handleTimeScaleChange(value, ev){
        if(ev) {
			ev.preventDefault();
		}
		
		const selectedTimeScale = this.timeScaleOptions.find(t => t.value === value);
		if(!selectedTimeScale){
			Logger.error(`The selected timescale: ${selectedTimeScale} is not found in the allowed list for the current date selection`);
		}
        
        this.setState({
			selectedTimeScaleOption: selectedTimeScale
		});

		if (this.props.onTimeScaleChange) {
			this.props.onTimeScaleChange(selectedTimeScale.label);
		}
	}

	_enableScaleOptionsStateByValues(scaleOptionsAray, valuesToEnable){
		scaleOptionsAray.forEach(o => {
			if (valuesToEnable.indexOf(o.value) > -1) {
				o.disabled = false;
			}
		});
	}

	_updateScaleOptions(dateFrom, dateTo, allScaleOptions) {
		const rangeDaysDiff = moment(dateTo).diff(moment(dateFrom), 'days');

		switch (true) {
			case rangeDaysDiff === 0:
				this._enableScaleOptionsStateByValues(allScaleOptions, ['HOURSMINUTESSECONDS', 'HOURSMINUTES', 'HOURS']);
				break;
			case rangeDaysDiff > 0 && rangeDaysDiff <= 2:
				this._enableScaleOptionsStateByValues(allScaleOptions, ['HOURS', 'YEARMONTHDATE']);
				break;
			case rangeDaysDiff > 2 && rangeDaysDiff <= 31:
				this._enableScaleOptionsStateByValues(allScaleOptions, ['YEARMONTHDATE']);
				break;
			case rangeDaysDiff > 31:
				this._enableScaleOptionsStateByValues(allScaleOptions, ['YEARMONTH', 'YEARMONTHDATE']);
				break;		
			default:
				this._enableScaleOptionsStateByValues(allScaleOptions, ['HOURS', 'YEARMONTHDATE', 'YEARMONTH']);
				break;
		}

		return allScaleOptions;
	}
}

BaseTimeScaleGraph.propTypes = {
	/** @prop {string} dateFrom selected start date from time range  */
	dateFrom: PropTypes.string.isRequired,

	/** @prop {string} dateFrom selected end date from time range  */
	dateTo: PropTypes.string.isRequired,

	/** @prop {func} onTimeScaleChange action sent on change time scale option. Not requred */
	/** @param {string} selectionValue the selected time scale option */
	onTimeScaleChange: PropTypes.func,

	/** @prop {string} timeScaleLabel name of the forced time-scale to apply on the time-chart. If not provided, it will be calculated from the date range  */
	timeScaleLabel: PropTypes.string,
};

export default BaseTimeScaleGraph;