import { isEmpty } from 'lodash';

const SORT_DIRECTION_ASC = "asc";

/**
 * Builds topics' stats into tree of topics
 * @param{Object} topicTree
 * @param{Array} topicsWithStats */
export function buildTreeStats(topicTree, topicsWithStats) {
    if (!topicTree || isEmpty(topicsWithStats)) {
        return {};
    }
    return calculateTreeStats(topicTree, topicsWithStats);
}

function calculateTreeStats(topic, topicsWithStats) {
    const topicNode = Object.assign({}, topic, {
		voteSum: 0,
		voteCount: 0,
		answersCount: 0,
		avgVote: 0,
		aggregateVoteSum: 0,
		aggregateVoteCount: 0,
		avgRelatedVote: 0,
		children: []
	});
    const topicWithStats = topicsWithStats.find(t => topicNode.name === t.name);
    // this should only happen if the data from stream and graph differs. Example, stream sends child topics that belong to some parent, the parent has an entity
    // but the children, for some reason, do not. The graph db will not send any stats about these topics, so we have to exclude them from the tree
    if (isEmpty(topicWithStats)) {
        return null;
    }
    const topicStats = topicWithStats.stats;
    if (topicStats) {
        topicNode.voteSum = !isNaN(topicStats.votesSum) ? topicStats.votesSum : 0;
        topicNode.voteCount = !isNaN(topicStats.votesCount) ? topicStats.votesCount : 0;
        topicNode.answersCount = !isNaN(topicStats.answersCount) ? topicStats.answersCount : 0;
        if (!topicNode.voteCount) {
            topicNode.avgVote = 0;
        } else {
            topicNode.avgVote = Math.round(topicStats.votesSum / topicStats.votesCount) / 10;
        }
        topicNode.aggregateVoteSum = 0;
        topicNode.aggregateVoteCount = 0;
        topicNode.avgRelatedVote = 0;
    }
    topicNode.latestVotes = topicWithStats.latestVotes;
    if (topic.hasOwnProperty('children') && topic.children.length > 0) {
        for (let i = 0; i < topic.children.length; i++) {
            const child = calculateTreeStats(topic.children[i], topicsWithStats);
            if (child) {
				topicNode.children.push(child);
				topicNode.aggregateVoteSum =
                    !isNaN(child.voteSum) && !isNaN(child.aggregateVoteSum)
                        ? topicNode.aggregateVoteSum + child.voteSum + child.aggregateVoteSum
                        : topicNode.aggregateVoteSum;
                topicNode.aggregateVoteCount = !isNaN(child.voteCount)
                    ? topicNode.aggregateVoteCount + child.voteCount + child.aggregateVoteCount
                    : topicNode.aggregateVoteCount;
			}
        }
        if (!topicNode.aggregateVoteCount) {
			topicNode.avgRelatedVote = 0;
		} else {
			topicNode.avgRelatedVote = Math.round(topicNode.aggregateVoteSum / topicNode.aggregateVoteCount) / 10;
		}
    }
    return topicNode;
}

export function buildFilteredTopicsTreeByName(topicsTree, searchTerm) {
    if (searchTerm === undefined || searchTerm === null) {
        return topicsTree;
    } else if (searchTerm.length === 0) {
        return topicsTree;
    }
    const rootTreeElement = { name: 'root', children: [...topicsTree] };
    _searchTreeRecursive(rootTreeElement, searchTerm, '');
    return rootTreeElement.children.filter(c => c.match);
}

function _searchTreeRecursive(currentNode, searchTerm) {
    if (currentNode.children && currentNode.children.length > 0) {
        let childCopy;
        let matchingCollection = [];
        currentNode.children.forEach(child => {
            let matchingChildren;
            // TODO: this copying must be redundant(rename build*->map*/get* along the way)
            childCopy = Object.assign({}, child, { children: child.children ? [...child.children] : null });
            if (childCopy.children && childCopy.children.length > 0) {
                _searchTreeRecursive(childCopy, searchTerm);
                matchingChildren = childCopy.children.filter(c => c.match);

                if (matchingChildren.length > 0) {
                    childCopy.match = true;
                    childCopy.children = matchingChildren;
                } else {
                    if (childCopy.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                        childCopy.match = true;
                    }
                }
            } else {
                if (childCopy.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                    childCopy.match = true;
                }
            }
            matchingCollection.push(childCopy);
        });
        currentNode.children = matchingCollection;
    }
}

export function sortTopicsTree(sortDirection, topics, sortProperty) {
    // TODO: this copying must be redundant
    const sortedTopics = topics.map(topic => Object.assign({}, topic));
    _sortTopicsArray(sortDirection, sortedTopics, sortProperty);
    sortedTopics.forEach(topic => {
        if (topic.children && topic.children.length > 0) {
            const sortedChildTopics = topic.children.map(topic => Object.assign({}, topic));
            _sortTopicsArray(sortDirection, sortedChildTopics, sortProperty);
            topic.children = sortedChildTopics;
        }
    });
    return sortedTopics;
}

function _sortTopicsArray(sortDirection, topics, sortProperty) {
    topics.sort((a, b) => {
        const acs = Number(a[sortProperty]) - Number(b[sortProperty]);
        const desc = Number(b[sortProperty]) - Number(a[sortProperty]);
        return sortDirection === SORT_DIRECTION_ASC ? acs : desc;
    });
}