import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logger from '../../../helpers/logger';
import { isEmpty } from 'lodash';

import OptionsSelector from '../../common/form/optionsSelector';
import * as graphsService from '../../../helpers/graphsService';
import VegaChart from '../../common/charts/vegaChart';
import i18n from '../../../i18n';

class StackedHorizontalBarChart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedStackingOption: !isEmpty(props.stackFieldOptions) ? props.stackFieldOptions[0] : graphsService.STACKING_OPTIONS.none,
			chartWidth: props.width,
			chartHeight: props.height,
			t: i18n.t.bind(i18n)
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.width !== prevState.chartWidth) {
			return { chartWidth: nextProps.width };
		}
		if (nextProps.height !== prevState.height) {
			return { chartHeight: nextProps.height };
		}

		return null;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.forceResize && this.props.forceResize) {
			this.setState({ chartWidth: this.props.width, chartHeight: this.props.height });
		}
	}

	get chartConfig() {
		const stackFieldOption = this.state.selectedStackingOption ? this.state.selectedStackingOption : {};

		const colorSpec = this._isValidStackingField(stackFieldOption.name) ? graphsService.getGraphEncodingColorSpecs(stackFieldOption.displayName) : null;
		if (colorSpec && !this.props.showLegend) {
			colorSpec.legend = null;
		}

		if (colorSpec.legend)
			colorSpec.legend.title = this.state.t(colorSpec.legend.title);

		const sortProp = this._getSortingOption();

		const tranformExpression = this.props.skipTransform || !this._isValidStackingField(stackFieldOption.name) ? null : graphsService.TRANSFORM_EXPRESSIONS[stackFieldOption.name];

		return Object.assign({}, graphsService.getGraphBaseSpecs(), {
			"mark": {
				"type": "bar"
			},
			"transform": tranformExpression,
			"width": this.state.chartWidth,
			"height": this.state.chartHeight || null,
			"encoding": {
				"y": {
					"field": this.props.dataFieldName,
					"type": "nominal",
					"sort": sortProp,
					"axis": {
						"title": this.props.chartYAxisTitle,
						"labelLimit": this.props.chartYAxisLabelLimit
					}
				},
				"x": {
					"field": this.props.aggregationFieldName || "*",
					"type": "quantitative",
					"aggregate": this.props.aggregationFieldName ? null : "count",
					"axis": { "title": this.props.chartXAxisTitle }
				},
				"color": colorSpec,
				"tooltip": { "field": this.props.aggregationFieldName || "*", "aggregate": this.props.aggregationFieldName ? null : "count", "type": "quantitative" }
			}
		});
	}

	_isValidStackingField(stackingField) {
		if (!stackingField || !graphsService.STACKING_OPTIONS[stackingField]) {
			Logger.error(`Invalid stack field ${stackingField}. Make sure you are using a valid STACKING_OPTIONS_NAMES and TRANSFORM_EXPRESSIONS value`);
			return false;
		}

		return true;
	}

	_handleStackingChange(index, ev) {
		if (ev) {
			ev.preventDefault();
		}

		this.setState({
			selectedStackingOption: this.props.stackFieldOptions[index]
		});
		if (this.props.onStackingChange) {
			this.props.onStackingChange(this.props.stackFieldOptions[index]);
		}
	}

	_getSortingOption() {
		// set custom sort for ageGroup and gender only 
		switch (this.props.dataFieldName) {
			case 'ageGroup':
				return graphsService.AGE_BUCKETS;
			case 'gender':
				return graphsService.GENDER_BUCKETS;
			default:
				return this.props.sortBy ? {
					"op": this.props.sortBy.type,
					"field": this.props.sortBy.field,
					"order": this.props.sortBy.isDesc ? "descending" : "ascending"
				} : null;
		}
	}

	_renderStackingSelector() {
		const t = this.state.t;
		const selectedOption = this.state.selectedStackingOption;
		const selectedOptionsDom = selectedOption.values.map((o, i) => {
			return <span key={i}>
				<label className={o.className}>{o.label}</label>
				{i < selectedOption.values.length - 1 && ", "}
			</span>;
		});

		const availableOptionsNames = this.props.stackFieldOptions.map((o, i) => {
			return {
				label: o.displayName,
				value: i.toString()
			};
		});

		const selectionIndex = this.props.stackFieldOptions.indexOf(this.state.selectedStackingOption);

		return (
			<h4>
				<span className='options-bar'>
					{this.props.stackFieldOptions.length > 1 ?
						<>
							<label>{this.props.chartYAxisTitle} {t('scalable_time_chart_stacked_by')}</label>
							<OptionsSelector
								onSelection={this._handleStackingChange.bind(this)}
								options={availableOptionsNames}
								value={selectionIndex.toString()} />
						</>
						:
						<label>showing score groups&nbsp;</label>
					}
					({selectedOptionsDom})
				</span>
			</h4>
		);
	}

	render() {
		return (
			<div className='stacked-horizontal-barchart'>
				{!isEmpty(this.props.stackFieldOptions) && this._renderStackingSelector()}
				<VegaChart data={{ values: this.props.data }} spec={this.chartConfig} className={this.props.className} />
			</div>
		);
	}
}

StackedHorizontalBarChart.propTypes = {
	/** @prop {object} data answers data to be loaded in chart */
	data: PropTypes.array.isRequired,

	/** @prop {string} dataFieldName  */
	dataFieldName: PropTypes.string.isRequired,

	/** @prop {object} stackFieldOptions custom set of STACKING_OPTIONS to be displayed in options menu */
	stackFieldOptions: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		displayName: PropTypes.string.isRequired,
		values: PropTypes.array.isRequired
	})),

	/** @prop {string} chartYAxisTitle  */
	chartYAxisTitle: PropTypes.string.isRequired,

	/** @prop {number} chartYAxisLabelLimit */
	chartYAxisLabelLimit: PropTypes.number,

	/** @prop {string} chartXAxisTitle  */
	chartXAxisTitle: PropTypes.string.isRequired,

	/** @prop {number} width custom width of the chart */
	width: PropTypes.number,

	/** @prop {number} height custom height of the chart. If not set, auto height will be used */
	height: PropTypes.number,

	/** @prop {bool} showLegend true to show colors legend; false otherwise */
	showLegend: PropTypes.bool,

	/** @prop {string} aggregationFieldName name of the field to perform the count aggregation on. If provided, we consider the data to have been aggregated in advance. 
	 * If not - aggregation is performed on the count of the data array  */
	aggregationFieldName: PropTypes.string,

	/** @prop {bool} forceResize true to force resizing even when height/width have not changed */
	forceResize: PropTypes.bool,

	/** @prop {object} sortBy with properties: type, field, isDesc. If provided, will sort the data on aggregationFieldName */
	sortBy: PropTypes.shape({
		type: PropTypes.string,
		field: PropTypes.string,
		isDesc: PropTypes.bool
	}),

	/** @prop {bool} skipTransform true to indiciate that stacking does not need prior transformation. If false, stacking transformations will be performed depending on the selected stacking option */
	skipTransform: PropTypes.bool,

	/** @prop {func} onStackingChange handler for stacking-option change */
	onStackingChange: PropTypes.func,
};

export default StackedHorizontalBarChart;