import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import _ from 'lodash';

import AutoCompleteSelectorItem from './translations/AutoCompleteSelectorItem';
import Card from '../common/card';
import BSFormControl from '../common/form/bsFormControl';
import Toggle from '../common/form/toggle';

import Languages from '../../helpers/languages';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import { Field, ErrorMessage } from 'formik';
import { ANSWERS_STRUCTURE } from '../../helpers/topicBuilderHelper';
import formValidators from '../../helpers/formValidators';
import FeaturesContext from "../../scenes/contexts/featuresContext";
import { ENTITY_FEATURES } from "hollerlive-shared/constants";
import { COMPARATORS } from "../../helpers/featuresHelper";
import NoPermissionLabel from '../common/noPermissionLabel';
import messages from '../../helpers/messages';
import i18n from '../../i18n';

class Translations extends Component {
    constructor(props, context) {
        super(props, context);

        const defaultLanguage = Languages.find(l => l.code === props.values.basicSettings.defaultLang);
        const activeLanguages = [];
        const translKeys = Object.keys(props.values.translations);
        translKeys.forEach(k => {
            activeLanguages.push(Languages.find(l => l.code === k));
        });

        this.state = {
            defaultLanguage,
            activeLanguages,
            t: i18n.t.bind(i18n)
        };

        this.addTranslationLanguage = this.addTranslationLanguage.bind(this);
        this.selectNewLanguage = this.selectNewLanguage.bind(this);
        this.removeTransationLanguage = this.removeTransationLanguage.bind(this);
        this.confirmDeleteTranslation = this.confirmDeleteTranslation.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.values.basicSettings.defaultLang !== prevState.defaultLanguageCode) {
            const newDefaultLanguage = Languages.find(l => l.code === nextProps.values.basicSettings.defaultLang);

            return {
                defaultLanguage: newDefaultLanguage
            };
        }

        return null;
    }

    renderAlternativeLanguagesSelectors() {
        const { activeLanguages } = this.state;

        const languageOptions = Languages.filter(l => l.code !== this.props.values.basicSettings.defaultLang);

        return activeLanguages.map((t, i) => {

            const lang = languageOptions.find(l => l.code === t.code);

            // Filtering active languages so current one is kept in the languagesToRender array to render correctly
            const filteredActiveLanguages = activeLanguages.filter(al => al !== lang);
            const languagesToRender = _.pullAll([...languageOptions], filteredActiveLanguages);

            const shouldShowEnforceToggle = !_.isEmpty(t);

            return (
                <div key={i} className="form-group row-fluid clearfix lang-select-row">
                    <div className="col-md-6">
                        <AutoCompleteSelectorItem
                            index={i}
                            options={languagesToRender.map(language => ({...language, name: this.state.t(language.name)}))}
                            selection={lang}
                            onChange={this.selectNewLanguage}
                            onRemove={this.confirmDeleteTranslation}
                            valueField="code"
                        />
                    </div>


                    {shouldShowEnforceToggle &&
                    <Field name={`translations.${t.code}.enforce`} className='uitest-language-options'>
                        {({ field }) => (
                            <div className="enforce-lang-toggle-container col-md-6">
                                <Toggle value={field.value || false} onChange={() => this.setEnforceLanguage(t.code)} className='enforce-lang-toggle' />
                                <label>
                                    {this.state.t('topic_builder_languages_enfoce_language_label')} <br/>
                                    <small className="sub-label">{this.state.t('topic_builder_languages_enfoce_language_sublabel')}</small>
                                </label>
                            </div>
                        )}
                    </Field>
                    }
                </div>
            );
        });
    }

    setEnforceLanguage(code) {
        const { translations } = this.props.values;

        const updatedTranslations = Object.assign({}, translations);
        const currentEnforceCode = this._getCurrentEnforceLanguageCode();

        let newEnforce = currentEnforceCode !== code;

        this._updateTranslationsEnforce(updatedTranslations, code, newEnforce);
    }

    _getCurrentEnforceLanguageCode() {
        const { translations } = this.props.values;
        const codes = Object.keys(translations);
        let currentEnforceLang = '';
        codes.forEach(c => {
            if (translations[c].enforce) {
                currentEnforceLang = c;
            }
        });
        return currentEnforceLang;
    }

    _updateTranslationsEnforce(updatedTranslations, code, enforceFlag) {
        const codes = Object.keys(updatedTranslations);

        codes.forEach(c => {
            updatedTranslations[c] = Object.assign({}, updatedTranslations[c], { enforce: false });
        });

        // TODO
        updatedTranslations[code].enforce = enforceFlag;
        const newFormState = Object.assign({}, this.props.values, { translations: updatedTranslations });
        this.props.setValues(newFormState);
    }

    addTranslationLanguage() {
        if (this.state.activeLanguages.length < Languages.length - 1) {
            const activeLanguages = [...this.state.activeLanguages, {}];
            this.setState({ activeLanguages });
        }
    }

    confirmDeleteTranslation(index) {
        const { translations } = this.props.values;
        const language = this.state.activeLanguages[index];
        const currentLang = translations[language.code];

        const t = this.state.t;
        
        if(!_.isEmpty(currentLang)) {
            const confirmModalForm = {
                title: t('modal_translations_delete_title'),
                message: t('modal_translations_delete_message', { 
                    languageIcon: language.icon,
                    languageName: t(language.name)
                }),
                onClose: () => this.context.hideModal(),
                onConfirm: () => {
                    this.removeTransationLanguage(index);
                    this.context.hideModal();
                    const isDdirty = { isFormDirty: false };
                    this.setState({ isDdirty });
                }
            };

            this.context.showModal(CONFIRM_MODAL, confirmModalForm);
        } else {
            this.removeTransationLanguage(index);
        }
    }

    removeTransationLanguage(index) {
        const translations = Object.assign({}, this.props.values.translations);

        const code = this.state.activeLanguages[index].code;
        if (translations[code]) {
            delete translations[code];
        }

        const activeLanguages = [...this.state.activeLanguages];
        activeLanguages.splice(index, 1);

        this.setState({ activeLanguages });
        
        const newFormState = Object.assign({}, this.props.values, { translations });
        this.props.setValues(newFormState);
    }

    selectNewLanguage(selectionIndex, selectionCode) {
        const translations = Object.assign({}, this.props.values.translations, { [selectionCode]: {} });
        this.populateNewTranslationLanguage(translations[selectionCode], this.props.values);

        const activeLanguages = [...this.state.activeLanguages];
        const selectedLang = Languages.find(l => l.code === selectionCode);

        activeLanguages.splice(selectionIndex, 1, selectedLang);
        
        this.setState({ activeLanguages });
        
        //TODO add new translation with setValues
        const newFormState = Object.assign({}, this.props.values, { translations });
        this.props.setValues(newFormState);
    }

    populateNewTranslationLanguage(translationLangObject, values) {
        translationLangObject.topicName = "";
        translationLangObject.description = "";

        if (values.qna && values.qna.length > 0) {
            translationLangObject.qna = {};
            values.qna.forEach(q => {
                const answersTranslationObjects = {};

                q.answers.forEach(a => {
                    const answerField = q.answerSctructure === ANSWERS_STRUCTURE[0] ? "placeholderText" : "text";
                    answersTranslationObjects[a.answerId] = {
                        [answerField]: ""
                    };
                });

                translationLangObject.qna[q.questionId] = {
                    question: "",
                    answers: answersTranslationObjects
                };
            });
        }

        if (values.greetings.messages && values.greetings.messages.length > 0) {
            translationLangObject.greetings = {};
            values.greetings.messages.forEach(g=> {
                translationLangObject.greetings[g.id] = {
                    text: ""
                };
            });
        }        
    }

    getBasicSettingsTitleTranslationFields() {
        const { values, errors } = this.props;

        const t = this.state.t;

        return this.state.activeLanguages.map((l, i) => {
            if (values.translations[l.code]) {
                const fieldPath = `translations.${l.code}`;

                return (
                    <BSFormControl key={i} horizontal label={`${l.icon} ${t(l.name)}`} required  hasErrors={formValidators.fieldHasErrors(errors, `${fieldPath}.topicName`)}>
                        <Field type="text" name={`${fieldPath}.topicName`} className="form-control" placeholder={values.translations[l.code].basicSettings && values.translations[l.code].basicSettings.title} />
                        <ErrorMessage name={`${fieldPath}.topicName`} component="span" className='control-label' />     
                    </BSFormControl>
                );
            } else {
                return null;
            }
        });
    }

    getBasicSettingsDescriptionTranslationFields() {
        const { values, errors } = this.props;

        const t = this.state.t;

        return this.state.activeLanguages.map((l, i) => {
            if (values.translations[l.code]) {
                const fieldPath = `translations.${l.code}`;
                const descriptionValue = values.basicSettings.description;
                const isEmpty = _.isEmpty(descriptionValue);

                return (
                    <BSFormControl key={i} horizontal label={`${l.icon} ${t(l.name)}`} required  hasErrors={formValidators.fieldHasErrors(errors, `${fieldPath}.description`)}>
                        <Field component="textarea" name={`${fieldPath}.description`} className="form-control" disabled={isEmpty} />
                        <ErrorMessage name={`${fieldPath}.description`} component="span" className='control-label' />  
                    </BSFormControl>
                );
            } else {
                return null;
            }
        });
    }

    getQnaTranslationItems(qna) {
        const t = this.state.t;
        return qna.map((q, i) => {
            const questionTranslationModel = `.qna.${q.questionId}`;

            return (
                <Card key={i} className="question-translation">
                    <BSFormControl
                        horizontal
                        label={`${this.state.defaultLanguage.icon} ${t(this.state.defaultLanguage.name)}`}
                    >
                        <input type="text" disabled className="form-control dotted" value={q.question} />
                    </BSFormControl>
                    {this.getQuestionTranslationFields(q, questionTranslationModel)}

                    <div className="row-fluid clearfix">
                        <div className="col-md-9 col-md-offset-3">
                            <h4>{t('topic_builder_languages_qna_answers_title')}</h4>
                            {this.getAnswersTranslationItems(q.questionId, q.answers, questionTranslationModel)}
                        </div>
                    </div>
                </Card>
            );
        });
    }

    getQuestionTranslationFields(question, questionTranslationModel) {
        const t = this.state.t;
        return this.state.activeLanguages.map((l, i) => {
            if (this.props.values.translations[l.code]) {
                const fieldPath = `translations.${l.code}${questionTranslationModel}`;

                return (
                    <BSFormControl key={i} horizontal label={`${l.icon} ${t(l.name)}`} required  hasErrors={formValidators.fieldHasErrors(this.props.errors, `${fieldPath}.question`)}>
                        <Field type="text" name={`${fieldPath}.question`} className="form-control" />
                        <ErrorMessage name={`${fieldPath}.question`} component="span" className='control-label' />  
                    </BSFormControl>
                );
            } else {
                return null;
            }
        });
    }

    getAnswersTranslationItems(questionId, answers, questionTranslationModel) {
        const t = this.state.t;
        return answers.map((a, i) => {
            const fieldValue = a.placeholderText.length > 0 ? a.placeholderText : a.text;
            const answerTranslationModel = `${questionTranslationModel}.answers.${a.answerId}`;

            return (
                <div key={i} className="answers-translations">
                    <div className="ordered-form-fields clearfix">
                        <div className="ordered-form-fields-item">
                            <div className="number">{i + 1}</div>
                            <div className="form-control-container">
                                <BSFormControl
                                    horizontal
                                    label={`${this.state.defaultLanguage.icon} ${t(this.state.defaultLanguage.name)}`}
                                >
                                    <input type="text" disabled className="form-control dotted" value={fieldValue} />
                                </BSFormControl>
                                {this.getAnswersTranslationFields(questionId, a, answerTranslationModel)}
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            );
        });
    }

    getAnswersTranslationFields(questionId, answer, answerTranslationModel) {
        const t = this.state.t;
        return this.state.activeLanguages.map((l, i) => {
            if (this.props.values.translations[l.code]) {
                const translationField = answer.text.length > 0 ? 'text' : 'placeholderText';
                const emptyAnswer = translationField === 'placeholderText' && answer.placeholderText.length === 0;

                const fieldPath = `translations.${l.code}${answerTranslationModel}`;

                return (
                    <BSFormControl key={i} horizontal label={`${l.icon} ${t(l.name)}`} required={translationField === 'text'} hasErrors={formValidators.fieldHasErrors(this.props.errors, `${fieldPath}.${translationField}`)}>
                        {!emptyAnswer ? (
                            <div>
                                <Field type="text" name={`${fieldPath}.${translationField}`} className="form-control" />
                                <ErrorMessage name={`${fieldPath}.${translationField}`} component="span" className='control-label' />  
                            </div>                            
                        ) : (
                            <input type="text" disabled className="form-control dotted" value="" />
                        )}
                    </BSFormControl>
                );
            } else {
                return null;
            }
        });
    }

    getGreetingsTranslationItems(greetings) {
        const t = this.state.t;
        return greetings.messages.map((message, i) => {
            return (
                <div key={i} className="card">
                    <div className="ordered-form-fields clearfix">
                        <div className="ordered-form-fields-item">
                            <div className="number">{message.id}</div>
                            <div className="form-control-container">
                                <BSFormControl
                                    horizontal
                                    label={`${this.state.defaultLanguage.icon} ${t(this.state.defaultLanguage.name)}`}
                                >
                                    <input type="text" disabled className="form-control dotted" value={message.text} />
                                </BSFormControl>
                                {this.getGreetingTranslationField(message.id)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    getGreetingTranslationField(messageId) {
        const t = this.state.t;
        return this.state.activeLanguages.map((lang, i) => {
            if (this.props.values.translations[lang.code]) {
                const fieldPath = `translations.${lang.code}.greetings.${messageId}.text`;
                return (
                    <BSFormControl key={i} horizontal label={`${lang.icon} ${t(lang.name)}`} required hasErrors={formValidators.fieldHasErrors(this.props.errors, `${fieldPath}`)}>
                        <Field type="text" name={`${fieldPath}`} className="form-control" />
                        <ErrorMessage name={`${fieldPath}`} component="span" className='control-label' />  
                    </BSFormControl>
                );
            } else {
                return null;
            }
        });
    }

    render() {
        const { t, defaultLanguage } = this.state;
        const { translations, basicSettings, qna, greetings } = this.props.values;

        return (
            <div className="translations">
                <div className="form-group row-fluid clearfix">
                    <label className="col-md-3 control-label">{t('topic_builder_languages_default_label')}</label>
                    <div className="col-md-4">
                        {defaultLanguage.icon} {t(defaultLanguage.name)}
                    </div>
                </div>
                <hr />

                {this.renderAlternativeLanguagesSelectors()}
    
                <div className="translations-footer">
                    <FeaturesContext 
                        requiredFeatures={[{name: ENTITY_FEATURES.MULTI_LANGUAGES, compare: COMPARATORS.IS_TRUE}]}
                        renderOtherwise={
                            <button
                                disabled={true}
                                type="button"
                                className="btn btn-dotted btn-outline btn-success btn-full-width uitest-btn-add-language"
                                onClick={() => {}}
                            >
                                <NoPermissionLabel message={messages.permissions.features.noLanguages} />
                                <Icons.PlusCircle size={16}/> {t('topic_builder_languages_add_alternative')}
                            </button>
                        }
                    >
                        <button
                            type="button"
                            className="btn btn-dotted btn-outline btn-success btn-full-width uitest-btn-add-language"
                            onClick={this.addTranslationLanguage}
                        >
                            <Icons.PlusCircle size={16}/> {t('topic_builder_languages_add_alternative')}
                        </button>
                    </FeaturesContext>
                </div>
                
                {Object.keys(translations).length > 0 && (
                    <div className="uitest-input-details">
                        <Card title={t('topic_builder_languages_details_title')}>
                            <BSFormControl horizontal label={`${defaultLanguage.icon} ${t(defaultLanguage.name)} ${t('topic_builder_languages_details_title_label')}`}>
                                <input
                                    type="text"
                                    disabled
                                    className="form-control dotted"
                                    value={basicSettings.title}
                                />
                            </BSFormControl>
                            {this.getBasicSettingsTitleTranslationFields()}

                            <hr />

                            <BSFormControl
                                horizontal
                                label={`${defaultLanguage.icon} ${t(defaultLanguage.name)} ${t('topic_builder_languages_details_description_label')}`}
                            >
                                <textarea disabled className="form-control dotted" value={basicSettings.description} />
                            </BSFormControl>
                            {this.getBasicSettingsDescriptionTranslationFields()}
                        </Card>

                        <Card title={t('topic_builder_languages_qna_title')} pad>
                            {this.getQnaTranslationItems(qna)}
                        </Card>

                        <Card title={t('topic_builder_languages_greetings_title')} pad>
                            {this.getGreetingsTranslationItems(greetings)}
                        </Card>
                    </div>
                )}
            </div>
        );
    }
}

Translations.propTypes = {
    values: PropTypes.shape({
        translations: PropTypes.object.isRequired,
        qna: PropTypes.arrayOf(
            PropTypes.shape({
                question: PropTypes.string,
                answers: PropTypes.arrayOf(
                    PropTypes.shape({
                        text: PropTypes.string,
                        placeholderText: PropTypes.string
                    })
                )
            })
        ).isRequired,

        greetings: PropTypes.shape({
            messages: PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string
                })
            )
        }).isRequired,

        basicSettings: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            defaultLang: PropTypes.string
        })
    }).isRequired,

    errors: PropTypes.object,
    editMode: PropTypes.bool,
    setFieldValue: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired
};

Translations.contextTypes = {
    hideModal: PropTypes.func,
    showModal: PropTypes.func,
};

export default Translations;
