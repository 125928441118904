import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Switch from './form/switch';
import NoPermissionLabel from './noPermissionLabel';
import * as Icons from 'react-feather';
import { SUBSCRIPTIONS } from 'hollerlive-shared/constants';

import './css/plans.css';
import plansColorsCss from './css/plansCommon.module.css';
import PlanFeaturesList from './planFeaturesList';

const SIZE_ICONS = 15;

const ENTITY_TIERS_NAMES = {};
const entityTiersNamesArray = SUBSCRIPTIONS.PLANS.map(plan => plan.name);
entityTiersNamesArray.forEach(name => ENTITY_TIERS_NAMES[name.toUpperCase()] = name);

const ENTITY_TIER_IDS = SUBSCRIPTIONS.PLANS.map(plan => plan.tierId);

const PlanBox = ({ plan: { tierId, title, description, priceOptions, features, mainFeatures }, onSubmit, userPlanId, readonly }) => {
    const [timeFrame, setTimeFrame] = useState(SUBSCRIPTIONS.PERIODS.MONTHLY);
    
    const userPlan = SUBSCRIPTIONS.PLANS.find(plan => plan.tierId === userPlanId);
    const planConfig = SUBSCRIPTIONS.PLANS.find(plan => plan.tierId === tierId);

    function handleSubmitPlan() {
        const requestedTierId = planConfig.tierId;
        if (canShowSubmitButtons()) {
            onSubmit(requestedTierId, timeFrame);
        } 
    }

    function getClassName() {
        const planName = title ? title.toLowerCase() : '';
        const planColorCss = plansColorsCss[`${planName}Colors`];
        
        return `plan-container ${planName} ${planColorCss}`;
    }

    function getTimeFrameTitle(name) {
        switch (name) {
            case SUBSCRIPTIONS.PERIODS.MONTHLY:
                return 'monthly';
            case SUBSCRIPTIONS.PERIODS.YEARLY:
                return 'annual';
            default:
                return 'monthly';
        }       
    }

    function continueWithPlanButtonTitle() {
        switch (title) {
            case ENTITY_TIERS_NAMES.FREE:
                return `continue with free plan`;
            case ENTITY_TIERS_NAMES.PRO:
            case ENTITY_TIERS_NAMES.BUSINESS:
                return `continue with ${getTimeFrameTitle(timeFrame)} ${title}`;
            case ENTITY_TIERS_NAMES.ENTERPRISE:
                return 'request enterprise account plan';
            default:
                return 'continue';
        }
    }

    function canShowSubmitButtons() {
        const tempDisableUpgradeFromPaid = true; // TODO: once 1761 is done, remove this check
        return !readonly && tempDisableUpgradeFromPaid && userPlan.tierId === 4
			? planConfig.level > userPlan.level
			: false;
    }

    function isUserPlan() {
        return userPlanId === planConfig.tierId;
    }

    function isFree() {
        return title.toLowerCase() === ENTITY_TIERS_NAMES.FREE.toLowerCase(); 
    }

    function isPro() {
        return title.toLowerCase() === ENTITY_TIERS_NAMES.PRO.toLowerCase(); 
    }

    function isBusiness() {
        return title.toLowerCase() === ENTITY_TIERS_NAMES.BUSINESS.toLowerCase(); 
    }

    function isEnterprise() {
        return title.toLowerCase() === ENTITY_TIERS_NAMES.ENTERPRISE.toLowerCase();
    }

    function displayPriceOptions() {
        switch (title.toLowerCase()) {
            case ENTITY_TIERS_NAMES.FREE.toLowerCase():
                return <div>
                    <span className="pricetag-currency">&euro;</span>
                    <span className="pricetag-number uitest-price">{priceOptions[0].price}</span>
                </div>;
            case ENTITY_TIERS_NAMES.PRO.toLowerCase(): 
            case ENTITY_TIERS_NAMES.BUSINESS.toLowerCase():
                return <div>
                    <div className="prices">
                        <span className={`${timeFrame === SUBSCRIPTIONS.PERIODS.MONTHLY ? '' : 'fadeout'} uitest-price`}>
                            <span className="pricetag-currency">&euro;</span>
                            <span className="pricetag-number">{priceOptions[0].price}</span>
                            /{displayPeriod(SUBSCRIPTIONS.PERIODS.MONTHLY)}
                        </span>
                        <span className={`${timeFrame === SUBSCRIPTIONS.PERIODS.YEARLY ? '' : 'fadeout'} uitest-price`}>
                            <span className="pricetag-currency">&euro;</span>
                            <span className="pricetag-number">{priceOptions[1].price}</span>
                            /{displayPeriod(SUBSCRIPTIONS.PERIODS.YEARLY)}
                        </span>
                    </div>
                    <Switch
                        value={timeFrame === SUBSCRIPTIONS.PERIODS.YEARLY}
                        onChange={() => setTimeFrame(`${timeFrame === SUBSCRIPTIONS.PERIODS.MONTHLY ? SUBSCRIPTIONS.PERIODS.YEARLY : SUBSCRIPTIONS.PERIODS.MONTHLY}`)}
                        offLabel={getTimeFrameTitle(SUBSCRIPTIONS.PERIODS.MONTHLY)}
                        onLabel={getTimeFrameTitle(SUBSCRIPTIONS.PERIODS.YEARLY)} 
                        inverse />
                    <small className="prices-label">
                        <label className={`text-uppercase ${timeFrame === SUBSCRIPTIONS.PERIODS.MONTHLY ? '' : 'fadeout'}`}>paid each month</label>
                        <label className={`text-uppercase ${timeFrame === SUBSCRIPTIONS.PERIODS.MONTHLY ? 'fadeout' : ''}`}>paid yearly</label>
                    </small>
                </div>;
            default:
                return null;
        }
    }

    function displayPeriod(period) {
        switch (period) {
            case SUBSCRIPTIONS.PERIODS.MONTHLY:
                return 'mo.';
            case SUBSCRIPTIONS.PERIODS.YEARLY:
                return 'yr.';
            default: 
                return '';
        }
    }

	return <div className={getClassName()}>
        {isUserPlan() && <NoPermissionLabel mode="left" message="Your current plan" />}
        
        <div className={`title-price-overview ${canShowSubmitButtons() ? '' : 'no-price'}`}>
            <div className="title-container">
                <h3 className="text-uppercase"><strong>{title}</strong></h3>
                <p>{description}</p>
            </div>

            <div className={`price-container`}>
                {canShowSubmitButtons() ? 
                    displayPriceOptions() :
                    isUserPlan() && <h4 className="text-label"><p><Icons.CheckCircle size={22} /> your current plan</p></h4>
                }
            </div>

            {canShowSubmitButtons() && 
                <button className="btn btn-outline btn-white submit-btn choose-plan-btn uitest-btn-top" type="submit" onClick={handleSubmitPlan} >
                    {continueWithPlanButtonTitle()}
                </button>
            }
        </div>

        <div className="details">            
            <PlanFeaturesList features={features} planName={title} />

            {canShowSubmitButtons() && !isEnterprise() && 
                <button className={`${isPro() && 'btn btn-outline-primary'} ${isBusiness() && 'btn-outline-success'} btn btn-outline submit-btn choose-plan-btn uitest-btn-bottom`} 
                    type="submit" 
                    onClick={handleSubmitPlan}
                >
                    <Icons.CheckCircle size={18} /> get {isFree() ? '' : getTimeFrameTitle(timeFrame)} {title} plan
                </button>
            }
            
            <div className="mainfeatures-overview">
                <h5>With the <span className="text-uppercase">{title}</span> plan, you get:</h5>
                <ul>
                    <li>
                        <Icons.LifeBuoy size={SIZE_ICONS+2}/> &nbsp; {mainFeatures[0]}
                    </li>
                    <li>
                        <Icons.Clock size={SIZE_ICONS+2}/> &nbsp; {mainFeatures[1]}
                    </li>
                    <li>
                        <Icons.Zap size={SIZE_ICONS+2}/> &nbsp; {mainFeatures[2]}
                    </li>
                </ul>
            </div>
        </div>
    </div>;
};

PlanBox.propTypes = {
    plan: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        priceOptions: PropTypes.arrayOf(PropTypes.shape({
            price: PropTypes.number,
            period: PropTypes.string
        })),
        features: PropTypes.array,
        mainFeatures: PropTypes.array,
    }),
    userPlanId: PropTypes.number,
    onSubmit: PropTypes.func,
    readonly: PropTypes.bool
};

PlanBox.defaultProps = {
    plan: {
        title: ENTITY_TIERS_NAMES.FREE,
        description: 'Take a look around for free!',
        priceOptions:[
            {
                price: 0,
                period: SUBSCRIPTIONS.PERIODS.MONTHLY
            }
        ],
        features: [
            '1 user account access',
            '1 active topic with questionnaire',
            'Sentiment and Questionnaire analysis'
        ],
        mainFeatures: [
            'Email support',
            'Within a week response time',
            'Features idea submission'
        ]
    }, 
    userPlanId: ENTITY_TIER_IDS.FREE,
    readonly: false
};

export default PlanBox;