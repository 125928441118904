import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VegaLite from 'react-vega-lite';
import * as Icon from 'react-feather';
import {Handler} from 'vega-tooltip';

import './css/vegaCharts.css';
import i18n from '../../../i18n';

class VegaChart extends Component {

    constructor() {
        super();

        this.state = {
            t: i18n.t.bind(i18n)
        };
    }

    _handleHover() {
        //console.log(args);
    }
    
    render(){
        const { t } = this.state;
        const classes = `vega-chart-wrapper ${this.props.className || ""}`;
        const { spec, data } = this.props;
        const hasData = data.values.length > 0;

        return(
            <div className={classes}>
                {!hasData &&
                    <div className="no-data-overlay text-info">
                        <label>
                            <Icon.Info /><br />
                            {t('vega_chart_no_data_title')}<br />
                            <small>{t('vega_chart_no_data_subtitle')}</small>
                        </label>
                    </div>
                }
                <VegaLite spec={spec} data={data} tooltip={new Handler().call} />
            </div>
        );
    }
    
}

VegaChart.propTypes = {
    data: PropTypes.object.isRequired,
    spec: PropTypes.object.isRequired,
    handleHover: PropTypes.func,
    className: PropTypes.string,
};

export default VegaChart;