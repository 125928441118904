import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../common/card';

export class ChangeUsersStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areAllSelected: false
    };
    
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onStatusChangeClick = this.onStatusChangeClick.bind(this);
    this.uncheckSelectAll = this.uncheckSelectAll.bind(this);
    this.onDeleteUsersClick = this.onDeleteUsersClick.bind(this);
  }
  
  onStatusChangeClick(status) {
    this.props.onStatusChange(status);
  }
  
  onSelectAll(event) {
    const { checked } = event.target;
    this.setState({ areAllSelected: checked });
    this.props.onSelectAll(checked);
  }
  
  uncheckSelectAll(){
    this.setState({ areAllSelected: false });
  }
  
  onDeleteUsersClick(){
    this.props.onDeleteSelectedUsers();
  }
  
  render() {
    return (
        <Card inverse className={`clearfix row-fluid ${this.props.className}`}>
          <input type="checkbox"
                 checked={this.state.areAllSelected}
                 disabled={this.props.selectableUsersCount === 0}
                 onChange={this.onSelectAll}/>
                 
          <label className="text-label text-light">
            selected {this.props.selectedUsersCount} of {this.props.selectableUsersCount} selectable users
          </label>
          
          <button className="btn btn-xs btn-outline btn-white uitest-bulk-activate-users-btn"
                  disabled={this.props.shouldDisableChangeStatusBtns || this.props.selectedUsersCount === 0}
                  onClick={() => {
                    this.onStatusChangeClick(true);
                  }}>
            activate selected
          </button>
          
          <button className="btn btn-xs btn-outline btn-white uitest-bulk-deactivate-users-btn"
                  disabled={this.props.shouldDisableChangeStatusBtns || this.props.selectedUsersCount === 0}
                  onClick={() => {
                    this.onStatusChangeClick(false);
                  }}>
            deactivate selected
          </button>
          
          <button className="btn btn-xs btn-outline btn-danger"
                  disabled={this.props.shouldEnableDeleteBtn || this.props.selectedUsersCount === 0} onClick={() => {
            this.onDeleteUsersClick();
          }}>
            delete selected unassigned users
          </button>
          
          <label className="text-label text-light pull-right">
            showing {this.props.showingUsersCount} of {this.props.totalUsersCount} total users
          </label>
        </Card>
    );
  }
}

ChangeUsersStatus.propTypes = {
  /** @prop {func} onStatusChange gets called with true when activate button is clicked and vice-versa */
  onStatusChange: PropTypes.func,
  /** @prop {func} onSelectAll gets called with when the custom select all checkbox is checked */
  onSelectAll: PropTypes.func,
  /** @prop {func} onDeleteSelectedUsers gets called when delete users button is clicked */
  onDeleteSelectedUsers: PropTypes.func,
  /** @prop {number} selectableUsersCount is the number of selectable users */
  selectableUsersCount: PropTypes.number,
  /** @prop {number} selectedUsersCount is the number of selected users */
  selectedUsersCount: PropTypes.number,
  /** @prop {number} showingUsersCount is the number of shown filtered users */
  showingUsersCount: PropTypes.number,
  /** @prop {number} totalUsersCount is the number of all users */
  totalUsersCount: PropTypes.number,
  /** @prop {string} optional class for the Card component */
  className: PropTypes.string,
  /** @prop {bool} shouldEnableDeleteBtn is a boolean for disabling delete users btn when there are active users selected */
  shouldEnableDeleteBtn: PropTypes.bool,
  /** @prop {bool} shouldDisableChangeStatusBtns is a boolean for disabling change status buttons when there are inactive users selected*/
  shouldDisableChangeStatusBtns: PropTypes.bool
};

export default ChangeUsersStatus;