import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVoterPreviewUrl } from '../../helpers/utils';
import './css/presentationModePage.css';
import QRCode from 'qrcode.react';
import { loadTopicById } from '../../actions/topicActions';
import { bindActionCreators } from 'redux';

export default (isReduxConnected = true) => {
	const PresentationModePage = ({ params, topic, loadTopicById }) => {
		const { topicName, votes } = topic;
		const [topicUrl, setTopicUrl] = useState(null);
		const [startDate] = useState(new Date());
		const votesForTopic = votes.filter(vote => new Date(vote.date) > startDate);
		
		const allPossibleVoteValues = [
			[0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
			[1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.0],
			[2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3.0],
			[3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9, 4.0],
			[4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 5.0],
			[5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 6.0],
			[6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7.0],
			[7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8.0],
			[8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9.0],
			[9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10.0]
		];
		
		const moodAngry = { range: { from: 0.1, to: 1.3 } };
		const moodAnnoyed = { range: { from: 1.4, to: 2.7 } };
		const moodSad = { range: { from: 2.8, to: 4.2 } };
		const moodNeutral = { range: { from: 4.3, to: 5.6 } };
		const moodPositive = { range: { from: 5.7, to: 7.0 } };
		const moodHappy = { range: { from: 7.1, to: 8.5 } };
		const moodDelighted = { range: { from: 8.6, to: 10.0 } };
		
		const getImage = voteValue => {
			let res = null;
			if (voteValue >= moodAngry.range.from && voteValue <= moodAngry.range.to) {
				res = '/images/faces/angry.svg';
			} else if (voteValue >= moodAnnoyed.range.from && voteValue <= moodAnnoyed.range.to) {
				res = '/images/faces/annoyed.svg';
			} else if (voteValue >= moodSad.range.from && voteValue <= moodSad.range.to) {
				res = '/images/faces/sad.svg';
			} else if (voteValue >= moodNeutral.range.from && voteValue <= moodNeutral.range.to) {
				res = '/images/faces/neutral.svg';
			} else if (voteValue >= moodPositive.range.from && voteValue <= moodPositive.range.to) {
				res = '/images/faces/positive.svg';
			} else if (voteValue >= moodHappy.range.from && voteValue <= moodHappy.range.to) {
				res = '/images/faces/happy.svg';
			} else if (voteValue >= moodDelighted.range.from && voteValue <= moodDelighted.range.to) {
				res = '/images/faces/delighted.svg';
			}
			return res;
		};
		const getColorClassName = voteValue => {
			return `rating-${Math.ceil(voteValue)}`;
		};
		
		const getVoteBox = voteValue => {
			return (
				<div
					className={`htmlsuchs-${voteValue} ${getColorClassName(voteValue)} uitest-votebox`}
					style={{ visibility: 'hidden' }}
				>
					<img src={getImage(voteValue)} alt={voteValue} />
				</div>
			);
		};

		const animateVote = voteValue => {
			const voteBox = document.getElementsByClassName(`htmlsuchs-${voteValue.vote}`)[0];
			if (voteBox) {
				if (voteBox.style.animation && voteBox.style.animation.length > 0) {
					// reseting animations is done by replacing the target element:
					const voteBoxClone = voteBox.cloneNode(true);
					voteBox.parentNode.replaceChild(voteBoxClone, voteBox);
					voteBoxClone.style.animation = 'act-vote-given 20s';
				} else {
					voteBox.style.animation = 'act-vote-given 20s';
				}
			}
		};

		const getLastTwoVotes = () => {
			return votesForTopic.slice(0, 2).reverse().map((voteValue, vIndex) => (
				<span key={`csssuchs${vIndex}`} className='votes-text-entry uitest-latestvotes'>
					<span className={`${getColorClassName(voteValue.vote)}-text uitest-latestvotevalue`}>
						{voteValue.vote === 10 ? voteValue.vote : voteValue.vote.toFixed(1)}
					</span>{' '}
					from{' '}
					<span className='vote-location uitest-votelocation'>
						{voteValue.city ? voteValue.city : 'undefined'},{' '}
						{voteValue.country ? voteValue.country : 'undefined'}
					</span>
				</span>
			));
		};
		
		useEffect(() => {
			const latestVoteForTopic = votesForTopic[0];
			if (latestVoteForTopic) {
				animateVote(latestVoteForTopic);
			}
		}, [votesForTopic.length]);
		
		useEffect(() => {
			if (topicName) {
				setTopicUrl(getVoterPreviewUrl(topicName, 'presentation'));
			} else {
				loadTopicById(params.id);
			}
		}, [topicName]);
		
		useEffect(() => {
			// add logo after the topicUrl, which is the data for the qr, is set
			const qrPreview = document.getElementsByClassName('qr-preview')[0];
			if (qrPreview) {
				const viewBox = qrPreview.getAttribute('viewBox').split(' ');
				const viewWidth = viewBox[2];
				const viewHeight = viewBox[3];

				const logo = document.createElementNS('http://www.w3.org/2000/svg', 'image');
				logo.setAttributeNS(null, 'height', '20%');
				logo.setAttributeNS(null, 'width', '20%');
				logo.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '/images/logo192.png');
				logo.setAttributeNS(null, 'x', `${viewWidth / 2.0 - (0.2 * viewWidth) / 2.0}`);
				logo.setAttributeNS(null, 'y', `${viewHeight / 2.0 - (0.2 * viewHeight) / 2.0}`);
				logo.setAttributeNS(null, 'visibility', 'visible');

				qrPreview.appendChild(logo);
			}
		}, [topicUrl]);

		return (
			<div className='presentation-mode-page'>
				<div className='topic-preview uitest-topicinfo'>
					Voting on {topicName} at <a href={`${topicUrl}`} target="_blank" rel="noopener noreferrer">{topicUrl}</a>
				</div>
				<div className='votes-frame-container'>
					<div className='votes-frame'>
						{allPossibleVoteValues.map((votesRow, yIndex) =>
							votesRow.map((voteValue, xIndex) => (
								<div
									key={`jquerysuchs${yIndex}${xIndex}`}
									className='vote-box'
									style={{
										//translating the allPossibleVoteValues matrix requires fliping axises(column uses xIndex, row uses yIndex)
										gridColumn: `col-start ${xIndex + 1}`,
										gridRow: `${10 - yIndex}`,
										width: '100%',
										height: '0',
										paddingBottom: '100%'
									}}
								>
									{getVoteBox(voteValue)}
								</div>
							))
						)}
					</div>
					<div className='vote-qr'>
						{topicUrl && <QRCode className='qr-preview' size={1024} value={topicUrl} renderAs='svg' />}
					</div>
				</div>
				<div className='votes-text'>
					{getLastTwoVotes()}
				</div>
			</div>);
	};

	PresentationModePage.propTypes = {
		params: PropTypes.shape({ id: PropTypes.string }),
		topic: PropTypes.shape({ topicId: PropTypes.string, topicName: PropTypes.string, votes: PropTypes.array })
	};

	function mapStateToProps(state, ownProps) {
		const { topics } = state;
		const targetTopic = topics.find(topic => topic.id === ownProps.params.id);
		return {
			topic: {
				topicId: targetTopic ? targetTopic.id : null,
				topicName: targetTopic && targetTopic.basicSettings ? targetTopic.basicSettings.title : '',
				votes: targetTopic && targetTopic.latestVotes ? targetTopic.latestVotes : []
			}
		};
	}
	function mapDispatchToProps(dispatch) {
		return bindActionCreators({ loadTopicById }, dispatch);
	}

	if (!isReduxConnected) {
		return PresentationModePage;
	}
	return connect(mapStateToProps, mapDispatchToProps)(PresentationModePage);
};
