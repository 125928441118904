import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChangePasswordForm from '../../components/login/ChangePasswordForm';
import { changePassword, clearErrors } from '../../actions/authActions';
import { validatePassChangeInput } from '../../helpers/validators';
import './css/userChangePassword.css';

export class UserChangePassword extends Component {
    constructor(props, context){
        super(props, context);
        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            errors: {},
            isLoading: false
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const errorReason = nextProps.authData.errors;
        if(errorReason && errorReason !== prevState.errors.form){
            return({errors: {form: errorReason}, isLoading: false});
        }

        return null;
    }    
    
    componentWillUnmount() {
        if(this.props.authData.errors){
            this.props.clearErrors();   // this is needed in case the user receives a backend error when changing the password, but then decides to go to another screen
        }
    }

    onSubmit(e) {
        e.preventDefault();
        if (this._validateChange()) {
            this.setState({ errors: {}, isLoading: true });
            this.props.changePassword(this.props.authData.user, this.state.oldPassword, this.state.newPassword);
            if(this.props.onDiscard && typeof this.props.onDiscard === 'function') {
                this.props.onDiscard();
            }
        }
    }

    onChange(e) {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }    

    _validateChange() {
        const { errors, isValid } = validatePassChangeInput(this.state);

        if (!isValid) {
          this.setState({ errors });
        }
        
        return isValid;
    }

    render() {
        return (
            <div className='user-change-password'>
                <ChangePasswordForm
                    errors={this.state.errors}
                    oldPassword={this.state.oldPassword}
                    newPassword={this.state.newPassword}
                    confirmPassword={this.state.confirmPassword}
                    isLoading={this.state.isLoading}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                />
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        authData: state.auth
    };
}

UserChangePassword.propTypes = {
    authData: PropTypes.object.isRequired,
    changePassword: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    onDiscard: PropTypes.func
};

export default connect(mapStateToProps, {changePassword, clearErrors}) (UserChangePassword);