import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { Settings, Edit } from 'react-feather';

import PopoverButton from '../../components/common/popover/popoverButton';
import Toggle from '../../components/common/form/toggle';
import SearchField from '../common/searchField';
import FeaturesContext from '../../scenes/contexts/featuresContext';
import NoPermissionLabel from '../../components/common/noPermissionLabel';

import { USER_ROLES, isCurrentRole, getRoleNameById } from '../../helpers/user';
import { getElapsedTime, isOnMobile } from '../../helpers/utils';
import { Mail, Phone, Eye, EyeOff } from 'react-feather';
import { ENTITY_TIERS_LIST } from '../../actions/models/entityModel';
import { COMPARATORS } from '../../helpers/featuresHelper';
import messages from '../../helpers/messages';
import { ENTITY_FEATURES } from 'hollerlive-shared/constants';

class UsersGrid extends Component {
	constructor(props) {
		super(props);

		this.state = { filterValue: "" };

		this.usersGridDom = null;
		this.onSearch = this.onSearch.bind(this);
		this.onClear = this.onClear.bind(this);
		this.getRoleConfigActionCell = this.getRoleConfigActionCell.bind(this);
		this.getEditActionCell = this.getEditActionCell.bind(this);
		this.getNameAndEmail = this.getNameAndEmail.bind(this);
		this.getActive = this.getActive.bind(this);
		this.getTier = this.getTier.bind(this);
		this.onSelectAll = this.onSelectAll.bind(this);
		this.onRowSelect = this.onRowSelect.bind(this);
	}

	handleToggleUserRole(user, newUserRoleId) {
		this.props.onRoleChange(user, newUserRoleId);
	}

	onSearch(filterValue) {
		if (this.props.resetSelectedUsers) {
			this.props.resetSelectedUsers();
		}
		this.setState({ filterValue });
	}
	
	onClear() {
		this.onSearch("");
	}

	get filteredUsers() {
		let filteredUsers;
		if (this.state.filterValue && this.state.filterValue.length > 0) {
			filteredUsers = this.props.users.filter(user => {
				const stringToSearchIn = this.props.isUserManagement ?
					`${user.firstName} ${user.lastName} ${user.email} ${user.id}` :
					`${user.firstName} ${user.lastName}`;
				if (stringToSearchIn.toLowerCase().indexOf(this.state.filterValue.toLowerCase().trim()) > -1) {
					// For each user this checks if it's ID has been selected and pushes it into the selectedFilteredUserIds array
					return user;
				} else {
					return false;
				}
			});
		} else {
			filteredUsers = this.props.users;
		}
		return filteredUsers;
	}

	onSelectAll(areAllSelected) {
		const allShowingUsersIds = this.filteredUsers.map(user => user.id);
		this.props.onUsersSelect(allShowingUsersIds, areAllSelected);
	}

	onRowSelect({ id }, isSelected) {
		this.props.onUsersSelect([id], isSelected);
	}

	get unselectableUsersIds() {
		return this.filteredUsers.filter(user => user.isSuperAdmin).map(user => user.id);
	}

	get selectedUsersIds() {
		return this.props.users.filter(user => user.isSelected).map(user => user.id);
	}

	recalculatePopoverScrollPosition(rowIndex) {
		const rowClicked = this.usersGridDom.getElementsByTagName('tr')[rowIndex + 1];
		const cellClicked = rowClicked.getElementsByClassName('action-cell')[0];
		const popover = cellClicked.getElementsByClassName('pop-over-component')[0];
		const scrollContainer = this.usersGridDom.getElementsByClassName('react-bs-container-body')[0];
		if (popover && !popover.style.top) {
			popover.style.top = `${popover.offsetTop - scrollContainer.scrollTop}px`;
		}
	}

	isChecked(userId, user) {
		return user.isSelected;
	}

	getNameAndEmail(userId, user) {
		let className = 'user-fullname-email';
		if (isCurrentRole(user.roleId, 'superAdmin')) {
			className = `${className} role-super-user`;
		}
		return <span className={className}>
			<b>{`${user.firstName} ${user.lastName}`}&nbsp;</b>
			{this.props.isUserManagement &&
				<>
					| {user.entity ? `${user.entity.name}` : "Not assigned to an organization."} | ID: {user.id} &nbsp;
					{user.isOnline &&
						<img src='/images/online-status-circle_60.svg' alt='online' className='uitest-online-status-circle'
							 width={12} height={12} />}
				</>
			}
			<br />
			<small className='text-muted'><Mail size={11} /> <a href={`mailto:${user.email}`}>{user.email}</a></small>&nbsp;
            {user.phone && <><small className='text-muted'><Phone size={11} /> {user.phone} </small>&nbsp;</>}
			{this.props.isUserManagement && !user.isOnline && user.lastOnlineOn && <>
				<small className='text-muted uitest-last-online-status-date'>
					last online: {getElapsedTime(user.lastOnlineOn, false)}
				</small>&nbsp;</>}
		</span>;
	}

	getActive(active) {
		const className = active ? 'text-primary text-bold' : 'text-muted text-light';
		return <label className={`${className} text-label`}>
			{active ? <Eye size={16} /> : <EyeOff size={16} />}
			{`${active ? "active" : "inactive"}`}
		</label>;
	}
	
	getTier(tierId) {
		if (tierId) {
			const {name} = ENTITY_TIERS_LIST.find(tier => tier.id === tierId);
			return <span className='text-label text-bold'>{name}</span>;
		}
		return null;
	}
	
	getRolePopoverButton(user, index) {
        const role = user.roleId;
		return (
			<PopoverButton
				text={<Settings size={18} />}
				className='transparent'
				popoverTitle={`${user.firstName} ${user.lastName} roles`}
				noPadding={false}
				onPopoverShow={() => this.recalculatePopoverScrollPosition(index)}
			>
				<div className='user-roles-toggles'>
					<div className='form-group clearfix'>
						<FeaturesContext 
							requiredFeatures={[{
								name: ENTITY_FEATURES.NUMBER_OF_MANAGERS,
								compare: COMPARATORS.GREATER_THAN_OR_ZERO,
								value: this.organizationManagersCount,
								matchValue: this.props.entityFeatures[ENTITY_FEATURES.NUMBER_OF_MANAGERS]
							}]}
							renderOtherwise={
								<div>
									<NoPermissionLabel mode='left' message={messages.permissions.features.insufficientNumberOfManagers} />
                                    <Toggle value={isCurrentRole(role, 'entityManager')} disabled={true} />
									<label className='control-label'>Organization Manager</label>
									<label className='sub-label'>edit &amp; view organization details, create &amp; manage topics, view topics analytics</label>
								</div>
							}
						>
                            <Toggle value={isCurrentRole(role, 'entityManager')} onChange={() => this.handleToggleUserRole(user, USER_ROLES.entityManager)} disabled={isCurrentRole(role, 'entityManager')} />
							<label className='control-label'>Organization Manager</label>
							<label className='sub-label'>edit &amp; view organization details, create &amp; manage topics, view topics analytics</label>
						</FeaturesContext>
					</div>
					<hr />
					<div className='form-group clearfix'>
                        <Toggle value={isCurrentRole(role, 'entityViewer')} onChange={() => this.handleToggleUserRole(user, USER_ROLES.entityViewer)} disabled={isCurrentRole(role, 'entityViewer')} />
						<label className='control-label'>Organization Viewer</label>
						<label className='sub-label'>view organization details, view topics analytics</label>
					</div>
					<hr />
					<div className='form-group clearfix'>
                        <Toggle value={isCurrentRole(role, 'topicEditor')} onChange={() => this.handleToggleUserRole(user, USER_ROLES.topicEditor)} disabled={isCurrentRole(role, 'topicEditor')} />
						<label className='control-label'>topic editor</label>
						<label className='sub-label'>create &amp; manage topics, view topics analytics</label>
					</div>
				</div>
			</PopoverButton>);
	}

	getRoleConfigActionCell(userId, user, enumObject, index) {
        return <span className='user-actions'>
            {!isCurrentRole(user.roleId, 'superAdmin') &&
                this.getRolePopoverButton(user, index)
            }
            </span>;
	}

	getEditActionCell(userId) {
        return <button onClick={() => this.props.onUserEdit(userId)} className='btn btn-transparent user-actions'>
                <Edit size={18} />
            </button>;
    }
    
    get organizationManagersCount() {
        return this.filteredUsers.filter(u => u.roleId === USER_ROLES.entityManager).length;
    }

	render() {
		const options = {
			noDataText: this.props.isUserManagement
				? "Loading or no users matching the criteria."
				: "No users assigned to organization."
		};
		const selectRowProp = this.props.isUserManagement ? {
			mode: 'checkbox',
			onSelect: this.onRowSelect,
			selected: this.selectedUsersIds,
			unselectable: this.unselectableUsersIds,
			className: this.isChecked ? 'checked' : ''
		} : {};
		const childComponents = React.Children.map(this.props.children, child => {
			if (typeof child.type === 'string') { // exclude html dom elements(alleviate warning upon receiving props)
				return child;
			}
			return React.cloneElement(child, {
				onSelectAll: this.onSelectAll,
				selectableUsersCount: this.filteredUsers.length - this.unselectableUsersIds.length,
				selectedUsersCount: this.selectedUsersIds.length,
				showingUsersCount: this.filteredUsers.length
			});
		});

		return (
			<div className='ui-test-users-grid users-grid' ref={(grid) => this.usersGridDom = grid}>
				<SearchField
					onSearch={this.onSearch} onClear={this.onClear}
					placeholder={this.props.isUserManagement ? "filter user list by name, email or ID" : "Search associated users"}
					isClearButtonVisible />

				{ childComponents }
				
				<BootstrapTable data={this.filteredUsers} options={options} scrollTop={'Top'}
								hover bordered={false} selectRow={selectRowProp}>
					<TableHeaderColumn dataField='id' isKey dataFormat={this.getNameAndEmail} dataAlign={'start'} />
					{this.props.isUserManagement &&
						<TableHeaderColumn dataField='tierId' dataFormat={this.getTier} dataAlign={'right'} />}
					{this.props.isUserManagement &&
						<TableHeaderColumn width='128' dataField='active' dataFormat={this.getActive} dataAlign={'right'}/>}
					 	<TableHeaderColumn width='192' dataField='roleId' dataFormat={getRoleNameById} dataAlign={'left'}/>	
					{!isOnMobile() &&
						<TableHeaderColumn width='52' columnClassName='action-cell' tdStyle={{textAlign: 'center'}}
										   dataField='id' dataFormat={this.getEditActionCell}
										   dataAlign='end' hidden={!this.props.isEditAllowed} />}
					{!this.props.isUserManagement &&
						<TableHeaderColumn width='52' columnClassName='action-cell' tdStyle={{textAlign: 'center'}}
										   dataField='id' dataFormat={this.getRoleConfigActionCell}
										   dataAlign='end' hidden={!this.props.isEditRolesAllowed} />}
				</BootstrapTable>
			</div>
		);
	}
}

UsersGrid.propTypes = {
	/** @prop {array} users associated with organization; Required */
	users: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
			email: PropTypes.string.isRequired
		})
	).isRequired,
	/** @prop {object} entityFeatures features to compare against if userGrid is used in entity edit scene */
	entityFeatures: PropTypes.object,
	/** @prop {func} onRoleChange action up to signify role change per id */
	onRoleChange: PropTypes.func,
	/** @prop {bool} isUserManagement true for users grid used in Admin user management */
	isUserManagement: PropTypes.bool,
    /** @prop {func} onSelectedUsersChange action up to update selected users array
     * @param {array} contains all selected users
     * @param {boolean} value to set isSelected to */
	onUsersSelect: PropTypes.func,
    /** @prop {func} onUserEdit action up to handle edit action
     * @param {number} id selected user id */
	onUserEdit: PropTypes.func,
	resetSelectedUsers: PropTypes.func,
	/** @prop {number} maxHeight custom max height of table; 700px otherwise */
	maxHeight: PropTypes.string,
	/** @prop {bool} isEditAllowed true for logged-in users who are allowed to edit other users */
	isEditAllowed: PropTypes.bool,
	/** @prop {bool} isEditRolesAllowed show edit alerts button */
	isEditRolesAllowed: PropTypes.bool,
	/** @prop {node} children additional data/filters for table to be placed inbetween search field and table */
	children: PropTypes.node
};

export default UsersGrid;