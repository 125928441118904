//entities
export const GET_ALL_ENTITIES_SUCCESS = 'GET_ALL_ENTITIES_SUCCESS';
export const DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS';
export const EDIT_ENTITY_SUCCESS = 'EDIT_ENTITY_SUCCESS';
export const LOAD_ENTITY_SUCCESS = 'LOAD_ENTITY_SUCCESS';
export const LOAD_ENTITY_REQUEST = 'LOAD_ENTITY_REQUEST';
export const CREATE_ENTITY_SUCCESS = 'CREATE_ENTITY_SUCCESS';
export const SWITCH_TO_ENTITY_SUCCESS = 'SWITCH_TO_ENTITY_SUCCESS';
export const RESET_ENTITY_FOR_EDIT = 'RESET_ENTITY_FOR_EDIT';
export const LOAD_ENTITY_SOURCES_SUCCESS = 'LOAD_ENTITY_SOURCES_SUCCESS';
export const UPDATE_ENTITY_VERSION_ERROR = 'UPDATE_ENTITY_VERSION_ERROR';
export const CREATE_ROOT_TOPIC_FOR_ENTITY_SUCCESS = 'CREATE_ROOT_TOPIC_FOR_ENTITY_SUCCESS';
export const CREATE_ROOT_TOPIC_FOR_ENTITY_ERROR = 'CREATE_ROOT_TOPIC_FOR_ENTITY_ERROR';
export const LOAD_UPGRADEDSUBSCRIPTION_ERROR = 'LOAD_UPGRADEDSUBSCRIPTION_ERROR';
export const LOAD_ENTITY_SUBSCRIPTIONS_SUCCESS = 'LOAD_ENTITY_SUBSCRIPTIONS_SUCCESS';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const LOAD_UPGRADEDSUBSCRIPTION_SUCCESS = 'LOAD_UPGRADEDSUBSCRIPTION_SUCCESS';

// topics
export const LOAD_TOPIC_SUCCESS = 'LOAD_TOPIC_SUCCESS';
export const LOAD_NEWTOPICVOTE_SUCCESS = 'LOAD_NEWTOPICVOTE_SUCCESS';
export const LOAD_TOPICS_FOR_ENTITY_SUCCESS = 'LOAD_TOPICS_FOR_ENTITY_SUCCESS';
export const DELETE_TOPIC_SUCCESS = 'DELETE_TOPIC_SUCCESS';
export const LOAD_TOPICS_FOR_CHANNEL_SUCCESS = 'LOAD_TOPICS_FOR_CHANNEL_SUCCESS';
export const REMOVE_TOPIC_FROM_CHANNEL_SUCCESS = 'REMOVE_TOPIC_FROM_CHANNEL_SUCCESS';
export const RESET_TOPICS = 'RESET_TOPICS';
export const LOAD_TOPIC_STRUCTURE_SUCCESS = 'LOAD_TOPIC_STRUCTURE_SUCCESS';
export const UPDATE_TOPIC_VERSION_ERROR = 'UPDATE_TOPIC_VERSION_ERROR';
export const GET_TOPICS_PAGE_BY_NAME_SUCCESS = 'GET_TOPICS_PAGE_BY_NAME_SUCCESS';
export const RESET_ADMIN_TOPICS_STATE = 'RESET_ADMIN_TOPICS_STATE';

// Votes Feed
export const LOAD_FILTERED_VOTES_WITH_QNA_SUCCESS = 'LOAD_FILTERED_VOTES_WITH_QNA_SUCCESS';
export const RESET_FILTERED_VOTES_WITH_QNA = 'RESET_FILTERED_VOTES_WITH_QNA';

export const LOAD_FILTERED_VOTERS_SUCCESS = 'LOAD_FILTERED_VOTERS_SUCCESS';
export const LOAD_VOTES_FOR_VOTER_SUCCESS = 'LOAD_VOTES_FOR_VOTER_SUCCESS';
export const RESET_FILTERED_VOTERS = 'RESET_FILTERED_VOTERS';
export const RESET_VOTES_FOR_VOTER = 'RESET_VOTES_FOR_VOTER';

export const LOAD_QUESTIONS_AND_ANSWERS = 'LOAD_QUESTIONS_AND_ANSWERS';
export const LOAD_QUESTIONS_AND_ANSWERS_SUCCESS = 'LOAD_QUESTIONS_AND_ANSWERS_SUCCESS';
export const LOAD_SINGLE_QUESTION_ANSWERS = 'LOAD_SINGLE_QUESTION_ANSWERS';
export const LOAD_SINGLE_QUESTION_ANSWERS_SUCCESS = 'LOAD_SINGLE_QUESTION_ANSWERS_SUCCESS';

// QnA
export const LOAD_QUESTIONSTATS = 'LOAD_QUESTIONSTATS';
export const LOAD_QUESTIONSTATS_SUCCESS = 'LOAD_QUESTIONSTATS_SUCCESS';
export const LOAD_BULK_QUESTIONS_DETAILS_SUCCESS = 'LOAD_BULK_QUESTIONS_DETAILS_SUCCESS';
export const LOAD_QUESTION_DETAILS_SUCCESS = 'LOAD_QUESTION_DETAILS_SUCCESS';

// topics tree
export const BUILD_TOPIC_TREE_REQUEST = 'BUILD_TOPIC_TREE_REQUEST';
export const LOAD_TOPIC_TREE_REQUEST = 'LOAD_TOPIC_TREE_REQUEST';
export const LOAD_TOPIC_TREE_SUCCESS = 'LOAD_TOPIC_TREE_SUCCESS';
export const BUILD_TOPIC_TREE_SUCCESS = 'BUILD_TOPIC_TREE_SUCCESS';
export const SET_TOPIC_TREE_PERIODFILTER = 'SET_TOPIC_TREE_PERIODFILTER';
export const RESET_TOPIC_TREE = 'RESET_TOPIC_TREE';

// authentication
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_REQUESTED = 'REGISTER_USER_REQUESTED';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOAD_USER_PROFILE_REQUESTED = 'LOAD_USER_PROFILE_REQUESTED';
export const LOAD_USER_PROFILE_SUCCESS = 'LOAD_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_REQUESTED = 'UPDATE_USER_PROFILE_REQUESTED';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFLE_ERROR = 'UPDATE_USER_PROFLE_ERROR';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const RESET_CHANGED_PASSWORD = 'RESET_CHANGED_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const LOGIN_USER_SESSIONEXPIRED = 'LOGIN_USER_SESSIONEXPIRED';

// users
export const CREATE_USER_REQUESTED = 'CREATE_USER_REQUESTED';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const RESET_CREATE_USER_SUCCESS = 'RESET_CREATE_USER_SUCCESS';
export const RESET_EDIT_USER_SUCCESS = 'RESET_EDIT_USER_SUCCESS';
export const UPDATE_USER_REQUESTED = 'UPDATE_USER_REQUESTED'; 
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const SET_USER_ROLE_SUCCESS = 'SET_USER_ROLE_SUCCESS';
export const LOAD_USER_DATA_SUCCESS = 'LOAD_USER_DATA_SUCCESS';
export const LOAD_USER_SEARCH_DATA_SUCCESS = 'LOAD_USER_SEARCH_DATA_SUCCESS';
export const RESET_SEARCH_USER_DATA_SUCCESS = 'RESET_SEARCH_USER_DATA_SUCCESS';
export const RESET_USER_SEARCH_RESULT = 'RESET_USER_SEARCH_RESULT';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_FILTERED_USERS = 'LOAD_FILTERED_USERS';
export const LOAD_FILTERED_USERS_SUCCESS = 'LOAD_FILTERED_USERS_SUCCESS';
export const USER_STATUS_CHANGE_SUCCESS = 'USER_STATUS_CHANGE_SUCCESS';
export const CHANGE_USERS_SELECTED = 'CHANGE_USERS_SELECTED';
export const RESET_SELECTED_USERS = 'RESET_SELECTED_USERS';
export const RESET_USER_REQUEST_ERRORS = 'RESET_USER_REQUEST_ERRORS';
export const UPDATE_USER_VERSION_ERROR = 'UPDATE_USER_VERSION_ERROR';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';

// other
export const SEND_SERVERMESSAGE = 'SEND_SERVERMESSAGE';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const AUTHENTICATING_TOSERVER = 'AUTHENTICATING_TOSERVER';
export const HAS_VALIDATION_ERRORS = 'HAS_VALIDATION_ERRORS';
export const COMMON_BACKEND_ERROR = 'COMMON_BACKEND_ERROR';
export const COMMON_BACKEND_ERROR_RESET = 'COMMON_BACKEND_ERROR_RESET';
export const REQUEST_STATUS_ERROR = 'REQUEST_STATUS_ERROR';
export const REQUEST_STATUS_PENDING = 'REQUEST_STATUS_PENDING';
export const REQUEST_STATUS_SUCCESS = 'REQUEST_STATUS_SUCCESS';
export const CLEAR_REQUEST_STATUS = 'CLEAR_REQUEST_STATUS';
export const HAS_VERSION_ERROR = 'HAS_VERSION_ERROR';
export const CONNECT_SOCKET = 'CONNECT_SOCKET';
export const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET';

// contact support
export const CONTACT_FORM_SUCCESS = 'CONTACT_FORM_SUCCESS';
export const SET_CONTACT_FORM_ERRORS = 'SET_CONTACT_FORM_ERRORS';
export const CLEAR_CONTACT_FORM = 'CLEAR_CONTACT_FORM';
export const CLEAR_CONTACT_FORM_ERRORS = 'CLEAR_CONTACT_FORM_ERRORS';

// graph data
export const LOAD_RAWVOTEDATA_SUCCESS = 'LOAD_RAWVOTEDATA_SUCCESS';
export const LOAD_TOPIC_VOTES_STATS = 'LOAD_TOPIC_VOTES_STATS';
export const RESET_TOPICDATA_SUCCESS = 'RESET_TOPICDATA_SUCCESS';

// notifications
export const RECEIVE_VOTE = 'RECEIVE_VOTE';

// topic builder
export const SAVETOPIC_SUCCESS = 'SAVETOPIC_SUCCESS';
export const UPDATETOPIC_SUCCESS = 'UPDATETOPIC_SUCCESS';
export const SAVETOPIC_ERROR = 'SAVETOPIC_ERROR';
export const LOAD_TOPIC_BY_HASH_SUCCESS = 'LOAD_TOPIC_BY_HASH_SUCCESS';
export const LOAD_TOPIC_FOR_TEMPLATE_SUCCESS = 'LOAD_TOPIC_FOR_TEMPLATE_SUCCESS';
export const RESET_TOPIC_FOR_EDIT = 'RESET_TOPIC_FOR_EDIT';
export const SAVETOPIC_ERROR_RESET = 'SAVETOPIC_ERROR_RESET';
export const LOAD_TOPIC_FOR_EDIT_REQUEST = 'LOAD_TOPIC_FOR_EDIT_REQUEST';
export const SET_ASYNC_VALIDATING = 'SET_ASYNC_VALIDATING';
export const RESET_ASYNC_VALIDATING = 'RESET_ASYNC_VALIDATING';
export const SET_TOPIC_NAME_ERROR = 'SET_TOPIC_NAME_ERROR';
export const CLEAR_TOPIC_NAME_ERROR = 'CLEAR_TOPIC_NAME_ERROR';

// modal
export const SHOW_MODAL = 'SHOW_MODAL'; 
export const HIDE_MODAL = 'HIDE_MODAL';

// loaded files types
export const LOAD_FILES_LIST = 'LOAD_FILES_LIST';
export const LOAD_FILES_LIST_SUCCESS = 'LOAD_FILES_LIST_SUCCESS';
export const LOAD_FILES_LIST_ERROR = 'LOAD_FILES_LIST_ERROR';
export const FILE_LOADING = 'FILE_LOADING';
export const FILE_LOADED_SUCCESS = 'FILE_LOADED_SUCCESS';
export const FILE_LOADED_ERROR = 'FILE_LOADED_ERROR';
export const RESET_FILE_REQUEST_ERRORS = 'RESET_FILE_REQUEST_ERRORS';
export const FILE_EDIT_SUCCESS = 'FILE_EDIT_SUCCESS';
export const FILE_EDIT_ERROR = 'FILE_EDIT_SUCCESS';
export const FILE_SAVE_SUCCESS = 'FILE_SAVE_SUCCESS';
export const FILE_SAVE_ERROR = 'FILE_SAVE_ERROR';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const FILE_DELETE_ERROR = 'FILE_DELETE_ERROR';

// organization account
export const LOAD_CHECKOUT_SESSION_SUCCESS = 'LOAD_CHECKOUT_SESSION_SUCCESS';