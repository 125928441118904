import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import { debounce } from 'lodash';

import './css/searchField.css';
import { t } from 'i18next';

const SearchField = ({
	onSearch, 
	placeholder, 
	isClearButtonVisible, 
	isSubmitButtonVisible, 
	onSubmit, 
	onClear, 
	value,
	debounceOnSearch,
	debounceOnSearchTimeout
}) => {
	const [fieldValue, setFieldValue] = useState(value);
	const [isDebouncing, setIsDebouncing] = useState(false);
	const [isOnFocus, setisOnFocus] = useState(false);

	useEffect(() => {
		if (isDebouncing) {
			const debouncedOnChange = debounce(() => {
				setIsDebouncing(false);
			}, debounceOnSearchTimeout);

			debouncedOnChange();
		} else {
			if (isOnFocus) {
				if (fieldValue.length > 0) {
					onSearch(fieldValue);
				} else {
					onClear();
				}
			}
		}
	}, [isDebouncing, isOnFocus]);

	const handleSearchChange = (ev) => {
		setFieldValue(ev.target.value);

		if (!debounceOnSearch) {
			onSearch(ev.target.value);
		} else {
			if (!isDebouncing) {
				setIsDebouncing(true);
			}
		}
	};

	const handleInputBlur = (ev) => {
		setisOnFocus(false);
	};

	const handleInputFocus = (ev) => {
		setisOnFocus(true);
	};
	
	const clearTextField = () => {
		setFieldValue('');
		onClear();
	};
	
    const handleSubmitForm = (ev) => {
        ev.preventDefault();
        onSubmit();
    };

	return (
		<form className="form-inline" onSubmit={handleSubmitForm}>
			<div className="row-fluid clearfix">
				<div className={`input-group input-group-lg search-field ${isClearButtonVisible ? 'has-clear-button' : ''} ${isSubmitButtonVisible ? 'has-submit-button' : ''}`}>
					<span className="input-group-addon" id="search-icon"><i className="glyphicon glyphicon-search"></i></span>
					
					<input 
						type="text" 
						onChange={handleSearchChange} 
						onBlur={handleInputBlur}
						onFocus={handleInputFocus}
						value={fieldValue} 
						className="form-control uitest-searchterm"
						placeholder={placeholder} aria-describedby="search-icon" id="search-input"
					/>
						
					{isSubmitButtonVisible &&
					<button type="submit" className="btn btn-xs btn-outline hide-on-mobile">
						{t('label_search_button_search')}
					</button>
					}
					{isClearButtonVisible &&
					<React.Fragment>
						&nbsp;
						<button type="button" className="btn btn-xs btn-outline hide-on-mobile uitest-clearbutton"
								onClick={clearTextField}>
							<Icons.X size={14}/> {t('label_search_button_clear')}
						</button>
					</React.Fragment>
					}
				</div>
			</div>
		</form>
	);
};

SearchField.propTypes = {
	/** 
	 * @prop {func} onSearch action handler invoked on search input value change, passing search text value
	 * @param {string} value
	 */
	onSearch: PropTypes.func.isRequired,
	
	onClear: PropTypes.func.isRequired,

	/** 
	 * @prop {func} onSearch action handler invoked clicking the submit button. Required if isSubmitButtonVisible is true
	 * @param {string} value
	 */
	onSubmit: function(props, propName, componentName){
		if (props.isSubmitButtonVisible) {
			if (typeof(props[propName]) != 'function') {
				return new Error(
				  'Invalid prop `' + propName + '` supplied to' +
				  ' `' + componentName + '`. Validation failed.'
				);
			  }
		}
	},

	/** @prop {string} placeholder the placeholder text for the search input */
	placeholder: PropTypes.string,

	/** @prop {string} value the pre-set value for the search input text */
	value: PropTypes.string,

	/** @prop {bool} isClearButtonVisible will show a clear text button */
	isClearButtonVisible: PropTypes.bool,

	/** @prop {bool} isSubmitButtonVisible will show a submit button */
	isSubmitButtonVisible: PropTypes.bool,

	/** @prop {bool} debounceOnSearch will invoke the onSearch action on a set timeout */
	debounceOnSearch: PropTypes.bool,

	debounceOnSearchTimeout: PropTypes.number
};

SearchField.defaultProps = {
	debounceOnSearch: false,
	debounceOnSearchTimeout: 1000,
	isClearButtonVisible: false,
	isSubmitButtonVisible: false,
	value: ""
};

export default SearchField;