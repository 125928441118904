import PropTypes from "prop-types";

const renderByPermission = ({
	children,
	hasPermissions,
	renderOtherwise
}) => {
	return !hasPermissions ? 
			renderOtherwise
			:
			children;
};

renderByPermission.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element)
	]),
	hasPermissions: PropTypes.bool
};

export default renderByPermission;
