import React from 'react';
import PropTypes from 'prop-types';

import MiniChart from '../common/charts/miniChart';
import './css/miniTrendChart.css';
import { useTranslation } from 'react-i18next';

const DEFAULT_NUMBER_TO_DISPLAY = 10;

const VotesTrendChart = props => {

	const { t } = useTranslation();

	const maxNumber = props.maxCount || DEFAULT_NUMBER_TO_DISPLAY;
	let votesSlice = props.votesDesc || [];
	if(votesSlice.length > maxNumber){
		votesSlice = votesSlice.slice(0, maxNumber);
	}

	const parsedVotesAscending = votesSlice.map(v => v.vote * 10).reverse();

	return (
		<div className='mini-chart'>
			<div>
				<MiniChart lineWiddth={props.lineWidth || 2} width={100} height={30} dataSet={parsedVotesAscending} />
				{props.customLabel ? (
					<>
						<div className='custom-label-title'>{props.customLabelTitle}</div>
						<div className='custom-label-value'>{props.customLabelValue}</div>
					</>
				) : props.smallLabel ? (
					<small>{t('topics_tree_trend')}</small>
				) : (
					<div className='text-in-bar'>{t('topics_tree_trend')}</div>
				)}
			</div>
		</div>
	);
};

VotesTrendChart.propTypes = {
	votesDesc: PropTypes.array,
	lineWidth: PropTypes.number,
	maxCount: PropTypes.number,
	smallLabel: PropTypes.bool,
	customLabel: PropTypes.bool,
	customLabelTitle: PropTypes.string,
	customLabelValue: PropTypes.string
};

export default VotesTrendChart;