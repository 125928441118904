import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';
import "./css/slider.css";

export class RangeSliderSelector extends Component {
    constructor(props) {
        super(props);

        const { values, min, max } = props;
        const initValues = values && values.length > 0 ? [...values] : [min, max];

        this.state = {
            values: initValues 
        };

        this.onAfterChange = this.onAfterChange.bind(this);
    }

    onAfterChange(value) {
        this.props.onAfterChange(value);
    }

    render() {
        return (
            <div className="range-slider-selector">
                <Range min={this.props.min}
                    max={this.props.max}
                    defaultValue={this.state.values}
                    pushable={true}
                    onAfterChange={this.onAfterChange}
                    marks={this.props.marks}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

RangeSliderSelector.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    values: PropTypes.array,
    onAfterChange: PropTypes.func.isRequired,
    marks: PropTypes.object,
    disabled: PropTypes.bool
};

export default RangeSliderSelector;