import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Edit3, XCircle, AlertTriangle } from 'react-feather';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import './css/orderedTopicList.css';

export default class OrderedTopicList extends Component {
    constructor(props) {
      super(props);
    
      this.handleSubmit = this.handleSubmit.bind(this);
      this.validate = this.validate.bind(this);
      this.handleTopicRemove = this.handleTopicRemove.bind(this);
    }

    handleSubmit(values){
        const orderedTopics = [...values.topics].sort((a,b) => {
            return a.orderIndex - b.orderIndex;
        }); 
        this.props.onOrderChange(orderedTopics);
    }
    
    handleTopicRemove(ev, topic) {
        ev.preventDefault();
        this.props.onTopicRemove(topic);
    }

    validate(values){
        const errors = {
            topics: []
        };

        values.topics.forEach((t,i) => {
            if(!t.orderIndex || Number(t.orderIndex < 1)){
                errors.topics[i] = "Invalid order index, cannot be less than 0";
            } else{
                const orderIsDuplicate = values.topics.some((other,j) => j !== i && other.orderIndex === t.orderIndex);
                if(orderIsDuplicate){
                    errors.topics[i] = "Duplicate order index, all numbers should be unique";
                }
            }
        });

        return errors.topics.length > 0 ? errors : {};
    }
    
    render() {
        return (
            <Formik 
                    initialValues={{topics: this.props.topics}} 
                    onSubmit={this.handleSubmit}
                    enableReinitialize={true}
                    validate={this.validate}
                >
                {({ values, isValid }) =>
                    <Form className='ordered-topic-list'>
                        <table className='table'>
                            <thead className='thead-light'>
                                <tr>
                                    <td>ID</td>
                                    <td>Topic name</td>
                                    <td>Actions</td>
                                    <td>Order (1 is highest)</td>
                                </tr>
                            </thead>
                            <FieldArray name="topics" className="form-control">
                                <tbody>
                                    {
                                        values.topics.map((t, i) => {
                                            return (
                                            <tr key={i}>
                                                <td className='topic-id-cell'>
                                                    <span className='topic-id-cell-content'>
                                                        <span>{t.id}</span>
                                                        {t.isPrivate ?
                                                            <span className='text-warning text-uppercase private-mark'>
															    <AlertTriangle size={16} />
                                                                private topic
															</span>
                                                         : ''
                                                        }
                                                    </span>
                                                </td>
                                                <td>{t.name}</td>
                                                <td>
                                                    <Link to={`/topics/edit/${t.id}`} className="btn btn-outline btn-xs btn-outline-primary"><Edit3 size={14} /> edit</Link>
                                                    &nbsp;
                                                    <button type="button" className="btn btn-xs btn-outline btn-danger" onClick={(ev)=>this.handleTopicRemove(ev, t)}><XCircle size={14} /> remove</button>
                                                </td>
                                                <td>
                                                    <Field type="number" className="form-control" name={`topics.${i}.orderIndex`} />
                                                    <ErrorMessage name={`topics.${i}`} component="span" className='control-label' />
                                                </td>
                                            </tr>);
                                        })
                                    }
                                </tbody>
                            </FieldArray>
                        </table>
                        <button className="btn btn-lg btn-success" disabled={!isValid} type='submit'>Save order</button>
                    </Form>
                }
            </Formik>
        );
    }
}
OrderedTopicList.propTypes = {
    topics: PropTypes.array.isRequired,
    onOrderChange: PropTypes.func,
    onTopicRemove: PropTypes.func
};
