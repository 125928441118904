import moment from 'moment';
import Messages from './messages';
import { isEmpty } from 'lodash';

/* todo: define the vega scale units in a more convinient way.
	now we depend on the naming everywhere. Changing one of 
	of the units must be changed in all occurances!
*/

export const DEFAULT_PAGE_SIZE = 25;

export const GRAPH_TIME_SCALE_OPTIONS = [
	{ label: "chart_filter_time_seconds", value: 'HOURSMINUTESSECONDS', disabled: false },
	{ label: "chart_filter_time_minutes", value: 'HOURSMINUTES', disabled: false },
	{ label: "chart_filter_time_hours", value: 'HOURS', disabled: false },
	{ label: "chart_filter_time_days", value: 'YEARMONTHDATE', disabled: false },
	{ label: "chart_filter_time_months", value: 'YEARMONTH', disabled: false }
];

export const getTimeScaleUnitIndexBylabel = (timeScaleUnit) => {
	if (timeScaleUnit) {
		const selectedTimeScaleOption = GRAPH_TIME_SCALE_OPTIONS.find(t => t.value === timeScaleUnit.toUpperCase());
		return GRAPH_TIME_SCALE_OPTIONS.indexOf(selectedTimeScaleOption);
	}

	return 0;
};

export const TIME_SCALE_UNITS = { 
	HOURSMINUTESSECONDS: 'HOURSMINUTESSECONDS',
	HOURSMINUTES: 'HOURSMINUTES',
	HOURS: 'HOURS',
	MONTHDATE: 'MONTHDATE',
	YEARMONTHDATE: 'YEARMONTHDATE',
	YEARMONTH: 'YEARMONTH'
};

export const SENTIMENT_COLORS = {
	veryLow: '#cc3a3a',
	low: '#e35f77',
	medium: '#5fa0e3',
	high: '#32de5e',
	veryHigh: '#4af86b'
};

export const SENTIMENT_BUCKETS = ["0-2", "2.1-4", "4.1-6", "6.1-8", "8.1-10"];
export const AGE_BUCKETS = ["0-17", "18-25", "26-35", "36-55", "56+", "undefined"];
export const GENDER_BUCKETS = ["female", "male", "undefined"];

const baseSpecs = {
	$schema: "https://vega.github.io/schema/vega-lite/v2.json",
	"autosize": {
		"type": "fit",
		"contains": "padding"
	},
};

const fullScoreColorSpec = { 
	"field": "vote", 
	"type": "quantitative", 
	"aggregate": "mean",
	"scale": {
		"domain": [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
		"range": [
			SENTIMENT_COLORS.veryHigh,
			SENTIMENT_COLORS.veryHigh,
			SENTIMENT_COLORS.high,
			SENTIMENT_COLORS.high,
			SENTIMENT_COLORS.medium, 
			SENTIMENT_COLORS.medium, 
			SENTIMENT_COLORS.low,
			SENTIMENT_COLORS.low,
			SENTIMENT_COLORS.veryLow,
			SENTIMENT_COLORS.veryLow,
			SENTIMENT_COLORS.veryLow
		]
	},
	"legend": {
		title: "chart_filter_legend_vote_rates",
		tickCount: 10,
		type: "symbol"
	}
};

const sentimentScoreGroupColorSpec = { 
	"field": "scoreGroup", 
	"type": "nominal",
	"scale": {
		"domain": [...SENTIMENT_BUCKETS].reverse(),
		"range": [
			SENTIMENT_COLORS.veryHigh,
			SENTIMENT_COLORS.high,
			SENTIMENT_COLORS.medium, 
			SENTIMENT_COLORS.low,
			SENTIMENT_COLORS.veryLow,
		]
	},
	"legend": {
		title: "chart_filter_legend_score_group",
		tickCount: 5,
		type: "symbol"
	}
};

const genderColorSpec = { 
	"field": "gender", 
	"type": "nominal", 
	"scale": {
		"domain": [...GENDER_BUCKETS],
		"range": ["#a63ea4", "#3e6fa3", "#d7d7d7"]
	},
	"legend": {
		title: "gender",
		tickCount: 3,
		type: "symbol"
	}
};

const ageGroupColorSpec = { 
	"field": "ageGroup", 
	"type": "nominal", 
	"scale": {
		"domain": [...AGE_BUCKETS],
		"range": ["#a6d948", "#48d984", "#48d9d4", "#4895d9", "#7348d9", "#d7d7d7"]
	},
	"legend": {
		title: "age groups",
		tickCount: 6,
		type: "symbol"
	}
};

export const SENTIMENT_OPTIONS = [
	{ displayLabel: 'label_sentiment_group_0_20', label: SENTIMENT_BUCKETS[0], className: 'option-sentiment-veryLow' },
	{ displayLabel: 'label_sentiment_group_21_40', label: SENTIMENT_BUCKETS[1], className: 'option-sentiment-low' },
	{ displayLabel: 'label_sentiment_group_41_60', label: SENTIMENT_BUCKETS[2], className: 'option-sentiment-medium' },
	{ displayLabel: 'label_sentiment_group_61_80', label: SENTIMENT_BUCKETS[3], className: 'option-sentiment-high' },
	{ displayLabel: 'label_sentiment_group_81_100', label: SENTIMENT_BUCKETS[4], className: 'option-sentiment-veryHigh' }				
];

export const GENDER_OPTIONS = [
	{ displayLabel: 'label_gender_male', label: 'Male', className: 'option-gender-male' },
	{ displayLabel: 'label_gender_female', label: 'Female', className: 'option-gender-female' },
	{ displayLabel: 'label_gender_other', label: 'Other', className: 'option-gender-other' }
];

export const AGE_GROUPS_OPTIONS = [
	{ displayLabel: 'label_age_group_0_17', label: '0-17', className: 'option-age-group-1' },
	{ displayLabel: 'label_age_group_18_25', label: '18-25', className: 'option-age-group-2' },
	{ displayLabel: 'label_age_group_26_35', label: '26-35', className: 'option-age-group-3' },				
	{ displayLabel: 'label_age_group_36_55', label: '36-55', className: 'option-age-group-4' },
	{ displayLabel: 'label_age_group_56', label: '56+', className: 'option-age-group-5' },
    { displayLabel: 'label_age_group_undefined', label: 'undefined', className: 'option-age-group-6' }
];

export const STACKING_OPTIONS_NAMES = {
	sentiment: "chart_filter_stacking_sentiment",
	gender: "chart_filter_stacking_gender",
	ageGroups: "chart_filter_stacking_age_group"
};

export const STACKING_OPTIONS = {
	sentiment: {name: "sentiment", displayName: "chart_filter_stacking_sentiment", values: SENTIMENT_OPTIONS},
	gender: {name: "gender", displayName: "chart_filter_stacking_gender", values: GENDER_OPTIONS},
	ageGroups: {name: "ageGroups", displayName: "chart_filter_stacking_age_group", values: AGE_GROUPS_OPTIONS},
	none: {name: "none", displayName: "chart_filter_stacking_none"}
};

export function getParsedFiltersGroup(options, incomingValues = null) {
	const filtersGroup = {};
	
	(options || []).forEach(o => {
		const incomingValue = incomingValues ? incomingValues[o.label.toLowerCase()] : null;
		filtersGroup[o.label.toLowerCase()] = incomingValue ? incomingValue : true;
	});

	return filtersGroup;
}

export const VOTE_FILTERING_OPTIONS_BY_AUTH_TYPE = [
	{ 
		label: Messages.votesFilters.anon.label, 
		subLabel: Messages.votesFilters.anon.subLabel, 
		fieldName: "anonymous",
		default: true
	},
	{ 
		label: Messages.votesFilters.facebook.label, 
		subLabel: Messages.votesFilters.facebook.subLabel, 
		fieldName: "facebook",
		default: true
	},
	{ 
		label: Messages.votesFilters.email.label, 
		subLabel: Messages.votesFilters.email.subLabel, 
		fieldName: "email",
		default: true
	}
];

export const VOTE_FILTERING_OPTIONS_BY_REPEATED_VOTER = [
	{ 
		label: Messages.votesFilters.lastPerVoter.label, 
		subLabel: Messages.votesFilters.lastPerVoter.subLabel, 
		fieldName: "latestPerVoter",
		default: false
	}
];

export function getDefaultFilterOptions() {
	const defaultOptions = {};

	VOTE_FILTERING_OPTIONS_BY_AUTH_TYPE.forEach(o => defaultOptions[o.fieldName] = o.default);
	VOTE_FILTERING_OPTIONS_BY_REPEATED_VOTER.forEach(o => defaultOptions[o.fieldName] = o.default);

	defaultOptions.sources = [];
	
	return defaultOptions;
}

/* Holds the transform expressions that are needed for the color encoding.
It is only needed when the data is not transformed in advance (e.g. at the moment we have ageGroup as property of 'vote', but not score-group) */
export const TRANSFORM_EXPRESSIONS = {
	sentiment: [
		{ "calculate": `["${SENTIMENT_BUCKETS.join('", "')}"][ceil(datum.vote/2)-1]`, "as": "scoreGroup"}
	],
	gender: null,
	ageGroups: null
};

export function getGraphBaseSpecs() {
	return baseSpecs;
}

export function getGraphEncodingColorSpecs(specName){
	switch (specName) {
		case STACKING_OPTIONS_NAMES.sentiment:
			return Object.assign({}, sentimentScoreGroupColorSpec);
		case STACKING_OPTIONS_NAMES.gender:
			return Object.assign({}, genderColorSpec);
		case STACKING_OPTIONS_NAMES.ageGroups:
			return Object.assign({}, ageGroupColorSpec);
		default:
			return Object.assign({}, fullScoreColorSpec);	// no stacking option selected (or null)
	}
}

export function getVegaTimeUnitForPeriod(dateFrom, dateTo){
	let timeUnit = TIME_SCALE_UNITS.YEARMONTHDATE;

	if(!dateFrom || !dateTo){
		return timeUnit;
	}
	const rangeDaysCount = moment(dateFrom).diff(moment(dateTo), 'days');
	const rangeHoursCount = moment.duration(moment(dateTo).diff(moment(dateFrom))).asHours();

	if (rangeDaysCount === 0) {
		if (rangeHoursCount < 2) {
			timeUnit = TIME_SCALE_UNITS.HOURSMINUTES;
		} else {
			timeUnit = TIME_SCALE_UNITS.HOURS;
		}
	} 

	return timeUnit;
}

export function updateChartConfigTimeUnit(chartConfig, timeUnit, dateFrom, dateTo) {
	if(!timeUnit){
		timeUnit = getVegaTimeUnitForPeriod(dateFrom, dateTo);
	}
	const axisProps = getAxisLabelAndFormatByTimeUnit(timeUnit);

	if (chartConfig.layer) {
		chartConfig.layer.forEach(layer => {
			layer.encoding.x.timeUnit = timeUnit.toLowerCase();
			if (layer.encoding.x.axis) {
				layer.encoding.x.axis.title = axisProps.title;
				layer.encoding.x.axis.format = axisProps.format;
			}
		});
	} else {
		chartConfig.encoding.x.timeUnit = timeUnit.toLowerCase();
		if (chartConfig.encoding.x.axis) {
			chartConfig.encoding.x.axis.title = axisProps.title;
			chartConfig.encoding.x.axis.format = axisProps.format;
		}
	}
}

/* Changes the color encoding settings and the data transform for the desired stacking option */
export function updateChartConfigStackingOption(chartConfig, stackOptionName){
	
	switch (stackOptionName) {
		case STACKING_OPTIONS_NAMES.sentiment:
			chartConfig.encoding.color = sentimentScoreGroupColorSpec;
			chartConfig.encoding.order = {"field": "scoreGroup", "type": "ordinal"};
			chartConfig.transform = TRANSFORM_EXPRESSIONS.sentiment;
			break;
		case STACKING_OPTIONS_NAMES.gender:
			chartConfig.encoding.color = genderColorSpec;
			chartConfig.transform = TRANSFORM_EXPRESSIONS.gender;
			break;
		case STACKING_OPTIONS_NAMES.ageGroups:
			chartConfig.encoding.color = ageGroupColorSpec;
			chartConfig.transform = TRANSFORM_EXPRESSIONS.ageGroups;
			break;
		default:
			chartConfig.encoding.color = sentimentScoreGroupColorSpec;
			chartConfig.encoding.order = {"field": "scoreGroup", "type": "ordinal"};
			chartConfig.transform = TRANSFORM_EXPRESSIONS.sentiment;
			break;
	}
}

export function getAxisLabelAndFormatByTimeUnit(timeUnit){
	let config = {};
	switch (true) {
		case timeUnit === TIME_SCALE_UNITS.HOURS:
			config.title = "chart_filter_time_hours";
			config.format = "%H:%M";
			break;
		case timeUnit === TIME_SCALE_UNITS.HOURSMINUTES:
			config.title = "chart_filter_time_minutes";
			config.format = "%H:%M";
			break;
		case timeUnit === TIME_SCALE_UNITS.HOURSMINUTESSECONDS:
			config.title = "chart_filter_time_seconds";
			config.format = "%H:%M:%S";
			break;
		case timeUnit === TIME_SCALE_UNITS.YEARMONTH:
			config.title = "chart_filter_time_months";
			config.format = "%Y %b";
			break;
		case timeUnit === TIME_SCALE_UNITS.MONTHDATE:
		case timeUnit === TIME_SCALE_UNITS.YEARMONTHDATE:
			config.title = "chart_filter_time_days";		
			config.format = "%b %e";
			break;
		default:
			config.title = "chart_filter_time_hours";
			config.format = "%H:%M";	
			break;
	}

	return config;
}

export function gethMarkedDeprecatedAnswers(answers = [], questions = []) {
	if (isEmpty(answers)) {
		return answers;
	}

	const markedAnswers = answers.map(answer => {
		if (answer.question && answer.question.length > 0) {
			const questionsListContainsQuestion = questionTitlesContainQuestion(answer.question, questions);
			
			if (!questionsListContainsQuestion) {
				return Object.assign({}, answer, { deprecated: true });
			}
		}

		return answer;
	});

	return markedAnswers;
}

function questionTitlesContainQuestion(question, questions) {
	let contains = false;

	for (let i = 0; i < questions.length; i++) {
		const title = questions[i];
		if (question && (question.toLowerCase().trim() === title.toLowerCase().trim())) {
			contains = true;
			break;
		}
	}

	return contains;
}	