import React from 'react';
import TextFieldGroup from '../common/textFieldGroup';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const ChangePasswordForm = ({ errors, oldPassword, newPassword, confirmPassword, isLoading, onChange, onSubmit }) => {

    const { t } = useTranslation();

    return (
        <form onSubmit={onSubmit} autoComplete="on" className="change-password-form">
            {errors.form && <div className="alert alert-danger">{errors.form}</div>}

            <TextFieldGroup
                field="oldPassword"
                label={t('forgotten_password_form_label_current')}
                value={oldPassword}
                error={errors.oldPassword}
                onChange={onChange}
                type="password"
            />

            <TextFieldGroup
                field="newPassword"
                label={t('forgotten_password_form_label_new')}
                value={newPassword}
                error={errors.newPassword}
                onChange={onChange}
                type="password"
            />

            <TextFieldGroup
                field="confirmPassword"
                label={t('forgotten_password_form_label_confirm')}
                value={confirmPassword}
                error={errors.confirmPassword}
                onChange={onChange}
                type="password"
            />

            <div className="form-group">
                <button type="submit" className="btn btn-primary btn-lg" disabled={isLoading}>
                    {t('forgotten_password_form_button_confirm')}
                </button>
            </div>
        </form>
    );
};

ChangePasswordForm.propTypes = {
    className: PropTypes.string,
    errors: PropTypes.object,
    oldPassword: PropTypes.string,
    newPassword: PropTypes.string,
    confirmPassword: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

export default ChangePasswordForm;