import {isEmpty} from 'lodash';
import messages from './messages';

export function validateLoginInput(identifier, password) {
  let errors = {};

  if (isEmpty(identifier)) {
    errors.identifier = messages.errors.required;
  }

  if (isEmpty(password)) {
    errors.password = messages.errors.required;
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}

export function validatePassChangeInput(data){
  let errors = {};

  if (isEmpty(data.oldPassword)) {
    errors.oldPassword = messages.errors.required;
  }

  if (isEmpty(data.newPassword)) {
    errors.newPassword = messages.errors.required;
  }

  if (data.confirmPassword !== data.newPassword) {
    errors.confirmPassword = messages.errors.confirmPassword;
  }

  if(!isStrongPassword(data.newPassword)){
    errors.newPassword = messages.errors.password;
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}

// will match password that is 8+ char long, has at least: 1 capital letter, 1 lower-case letter, 1 digit
// e.g. b2gbadroboT
export const isStrongPassword = (password) => {
  const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{8,})/;  
  return password.search(pattern) > -1 ? true : false;
};

export const validateStrongPassword = (password) => {
  const errors = {};
  
  if(!isStrongPassword(password)){
    errors.password = messages.errors.password;
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
};

export const passwordsMatch = (password, confirmPassword) => {
  return password === confirmPassword;
};
