import html2canvas from 'html2canvas';
import { downloadPDF } from './utils';

// values in pixels 
export const STANDARD_A4_WIDTH = 446.46;
export const STANDARD_A4_HEIGHT = 631.56;
export const STANDARD_A4_MARGIN = 45;
export const SPACE_BETWEEN_ADJACENT_ITEMS = 8;

// -------------------------------- Generic Methods For Arrangement and Creation ----------------------------------------

/**
 * Generic method to add an object to an array
 * The object represents a text with its margins set accordingly
 * @param {string} text - to be placed on page
 * @param {array} page - to hold the text
 * @param {number} marginLeft 
 * @param {number} marginTop 
 * @param {object} options - optional parameters for text customization; check jsPDF.text() docs for details
 */
function fillInTextOnPage(text, page, marginLeft = STANDARD_A4_MARGIN, marginTop = STANDARD_A4_MARGIN, options) {
    page.push({
        text: {
            text,
            marginLeft,
            marginTop,
            options: options ? options : null
        }
    });
}

/**
 * Generic method to add an object to an array
 * The object represents an image with its margins and max dimensions set accordingly
 * @param {*} canvas - accepts HTMLCanvasElement or HTMLImageElement
 * @param {*} page - an array holder of the objects to be placed on a given page
 * @param {*} marginLeft 
 * @param {*} marginTop 
 * @param {*} maxWidth 
 * @param {*} maxHeight 
 */
async function fillInCanvasOnPage(canvas, page, 
marginLeft = STANDARD_A4_MARGIN, marginTop = STANDARD_A4_MARGIN, 
maxWidth = (STANDARD_A4_WIDTH - (2 * STANDARD_A4_MARGIN)), maxHeight = (STANDARD_A4_HEIGHT - (2 * STANDARD_A4_MARGIN))) {
    let image;
    if (canvas instanceof HTMLCanvasElement) {
        image = await loadImage(canvas.toDataURL('image/png'), canvas.width, canvas.height);
    } else if (canvas instanceof HTMLImageElement) {
        image = canvas;
    } else {
        return;
    }

    setImageDimensions(image, maxWidth, maxHeight);
    
    page.push({
        imageData: {
            data: image,
            format: 'PNG',
            marginLeft: marginLeft,
            marginTop: marginTop,
            width: image.width,
            height: image.height,
        }
    });    
}

/**
 * Generic method to convert using html2canvas
 * Returns an object with the same keys as provided object.
 * Values of the returned object are arrays with Canvas representations of given DOM Elements.
 * @param {object} htmlElements:
    * key can be anything
    * value is an array with DOM Elements
 */
async function convertHtmlElementsToCanvases(htmlElements = {}) {
    const canvases = {};
    const options = {
        logging: false,
    };

    const distinctElementsKeys = Object.keys(htmlElements);
    for (let i = 0; i < distinctElementsKeys.length; i ++) {
        const currentKey = distinctElementsKeys[i];
        canvases[currentKey] = [];
        for (let i = 0; i < htmlElements[currentKey].length; i ++){
            const canvas = await html2canvas(htmlElements[currentKey][i], options);
            canvases[currentKey].push(canvas);
        }   
    }
    
    return canvases;
}

/**
 * Generic method to adjust image dimensions inplace.
 * Does not return.
 * @param {object} image - an HTMLImageElement
 * @param {number} maxWidth - max width to be reached after adjustments
 * @param {number} maxHeight - max height to be reached after adjustments
 */
function setImageDimensions(image, maxWidth, maxHeight) {
    if (!image || !maxWidth || !maxHeight) {
        return;
    }

    const imageSizeRatio = image.width / image.height;

    if ( (maxWidth / imageSizeRatio) > maxHeight ) {
        image.height = maxHeight;
        image.width = maxHeight * imageSizeRatio;
    } else {
        image.height = maxWidth / imageSizeRatio;
        image.width = maxWidth;
    }
}

/**
 * Creates an HTMLImageElement from given src, width and height.
 * Returns a Promise
 * @param {string} imgSrc - the source of the image to be created
 * @param {number} imgWidth 
 * @param {number} imgHeight 
 */
function loadImage(imgSrc, imgWidth, imgHeight) {
    return new Promise( (resolve, reject) => {
        const img = new Image(imgWidth, imgHeight);
        
        img.onload = () => {
            resolve(img);
        };
        img.onerror = (err) => {
            reject(err.error);
        };

        img.src = imgSrc;
    });
}

// -------------------------------- Custom TopicDetailsPage Methods For Arrangement and Creation ----------------------------------------

/** customize elements for PDF according to topic Details Graphs needs
 * @param {object} elementsToPrint:
    * keys: texts, htmlElements, votesAnalysisElements, questionOrderSelectionLabel, holderCanvasElement, fileName
 * @field {object} texts - contains organization, author, topic, dateRange, includingData, showingData
 * @field {object} htmlElements - holds HTML Elements from parent's document object *keys: statsItems, questionsByVolume, questionsList
 * @field {array} votesAnalysisElements - holds HTML Elements from parent's document object
 * @field {string} questionOrderSelectionLabel - user's choice of question list ordering
 * @field {object} holderCanvasElement - a stand-alone HTMLCanvasElement to be drawn on and added to PDF
 * @field {string} fileName - to be used when saving file
 */
export async function generateTopicDetailsPDF(elementsToPrint) {
    let customizedElementsToPDF = {
        options: {
            orientation: 'portrait',
            unit: 'px',
            format: 'a4'
        },
        fileName: elementsToPrint.fileName,
        page1: [],
    };
    let canvasesToPrint = {};

    try {
        const votesAnalysis = await convertVotesElementsToCanvases(elementsToPrint.votesAnalysisElements);
        const elements = await convertHtmlElementsToCanvases(elementsToPrint.htmlElements);

        canvasesToPrint = {...canvasesToPrint, votesAnalysis, ...elements};
    }
    catch(error) {
        throw new Error(`attempting to convert html2Canvas: ${error}`);
    }

    try {        
        await generateTopicDetailsMainPage(customizedElementsToPDF.page1, elementsToPrint, canvasesToPrint);
        await generateTopicDetailsContentPages(customizedElementsToPDF, elementsToPrint, canvasesToPrint);
    } catch(error) {
        throw new Error(`printing data to PDF: ${error}`);
    }    
   
    return downloadPDF(customizedElementsToPDF);
}

async function generateTopicDetailsMainPage(mainPage, elementsToPrint, canvasesToPrint) {
    const maxHeightOnPage = STANDARD_A4_HEIGHT - (2 * STANDARD_A4_MARGIN);
    const maxWidthOnPage = STANDARD_A4_WIDTH - (2 * STANDARD_A4_MARGIN);

    const titlePageCanvas = setUpCanvasTextsForTopicDetailsTitlePage(elementsToPrint.texts, STANDARD_A4_WIDTH, STANDARD_A4_HEIGHT, elementsToPrint.holderCanvasElement);
    const maxWidthTitleText = (2 * ( maxWidthOnPage - SPACE_BETWEEN_ADJACENT_ITEMS )) / 3;

    if (titlePageCanvas) {
        // place premade customized texts as canvas on page
        await fillInCanvasOnPage(titlePageCanvas, mainPage, STANDARD_A4_MARGIN, STANDARD_A4_MARGIN, maxWidthTitleText, maxHeightOnPage);
    } else {
        // place plain texts on page if no canvas provided
        let spaceInbetween = 0;
        for (const textKey in elementsToPrint.texts) {
            fillInTextOnPage(elementsToPrint.texts[textKey], 
                mainPage, 
                STANDARD_A4_MARGIN, 
                STANDARD_A4_MARGIN + ( spaceInbetween * 20 ),
                {
                    maxWidth: maxWidthTitleText
                }, 
            );
            spaceInbetween++;
        }
    }

    const statsItemsCanvases = canvasesToPrint.statsItems;
    if (statsItemsCanvases) {
        const maxHeightStatsContainer = maxHeightOnPage / 2; 

        const maxWidthPerStatsItem = ( maxWidthOnPage - SPACE_BETWEEN_ADJACENT_ITEMS ) / 3;
        const maxHeightPerStatsItem = maxHeightStatsContainer / statsItemsCanvases.length;

        for ( let i = 0; i < statsItemsCanvases.length; i++ ){
            const statsItem = statsItemsCanvases[i];
            await fillInCanvasOnPage(statsItem, mainPage, 
                STANDARD_A4_MARGIN + maxWidthTitleText + SPACE_BETWEEN_ADJACENT_ITEMS, STANDARD_A4_MARGIN + ( maxHeightPerStatsItem * i ),
                maxWidthPerStatsItem, maxHeightPerStatsItem);
        }
    }
}

function setUpCanvasTextsForTopicDetailsTitlePage(
    { entity, author, topic, dateRange, showingData, includingData } 
    =
    { entity: 'organization', author: 'author', topic: 'topic', dateRange: 'unknown', showingData: 'unknown', includingData: 'unknown' }, 
    maxWidth = STANDARD_A4_WIDTH, 
    maxHeight = STANDARD_A4_HEIGHT,
    canvasHolder) 
{
    if (canvasHolder) {
        canvasHolder.width = maxWidth;
        canvasHolder.height = maxHeight;
        const canvasContext = canvasHolder.getContext('2d');
        
        canvasContext.font = '46px Roboto';
    
        canvasContext.fillText('kazva.bg', 0, 35, maxWidth);
        canvasContext.fillText('Data snapshot for', 0, 90, maxWidth);
    
        canvasContext.font = 'bold 46px Roboto';
        canvasContext.fillText(topic, 0, 160, maxWidth);
    
        const offsetText = 220;
        const lineHeightPerText = 26;
    
        canvasContext.font = 'lighter 22px Roboto';
        canvasContext.fillText('Based on:', 0, offsetText, maxWidth);
        canvasContext.font = 'normal 22px Roboto';
        canvasContext.fillText(dateRange, 0, offsetText + (lineHeightPerText * 1), maxWidth);
    
        canvasContext.font = 'lighter 22px Roboto';
        canvasContext.fillText('Including:', 0, offsetText + (lineHeightPerText * 2), maxWidth);
        canvasContext.font = 'normal 22px Roboto';
        canvasContext.fillText(includingData, 0, offsetText + (lineHeightPerText * 3), maxWidth);
    
        canvasContext.font = 'normal 22px Roboto';
        canvasContext.fillText(`and showing ${showingData}.`, 0, offsetText + (lineHeightPerText * 4), maxWidth);
    
        canvasContext.font = 'lighter 22px Roboto';
        canvasContext.fillText('Generated by:', 0, offsetText + (lineHeightPerText * 6), maxWidth);
        canvasContext.font = 'normal 22px Roboto';
        canvasContext.fillText(author, 0, offsetText + (lineHeightPerText * 7), maxWidth);
    
        canvasContext.font = 'normal 22px Roboto';
        canvasContext.fillText(entity, 0, offsetText + (lineHeightPerText * 8), maxWidth);
    } 

    return canvasHolder;
}

async function generateTopicDetailsContentPages(contentPages, elementsToPrint, canvasesToPrint) {
    let estimatedContentHeightOnPage = 0; // increases on each text/image/element addition

    contentPages.page2 = [];
    const maxHeightOnPage = STANDARD_A4_HEIGHT - (2 * STANDARD_A4_MARGIN);

    if (canvasesToPrint.votesAnalysis && canvasesToPrint.votesAnalysis.length > 0) {
        fillInTextOnPage('Votes Analysis', contentPages.page2, (STANDARD_A4_WIDTH / 2 - 30));
        estimatedContentHeightOnPage += 15;

        const maxHeightPerVotesAnalysisItem = (maxHeightOnPage - estimatedContentHeightOnPage) / canvasesToPrint.votesAnalysis.length;

        for ( let i = 0; i < canvasesToPrint.votesAnalysis.length; i++ ){
            const votesAnalysisItem = canvasesToPrint.votesAnalysis[i];
            const { labelText, modeCanvas, chartCanvas } = votesAnalysisItem;

            let maxWidthPerVotesAnalysisItem = STANDARD_A4_WIDTH - (2 * STANDARD_A4_MARGIN);
            let marginLeft = STANDARD_A4_MARGIN;
            let marginTop = STANDARD_A4_MARGIN + estimatedContentHeightOnPage + (maxHeightPerVotesAnalysisItem * i);
            let labelTextHeight = 6;

            if ( labelText && labelText.length > 0 ) {
                const labelToPrint = labelText.charAt(0).toUpperCase() + labelText.slice(1).toLowerCase();
                await fillInTextOnPage( labelToPrint, contentPages.page2, marginLeft, marginTop, { lineHeightFactor: '0.7' } );
                marginTop += labelTextHeight;
            }

            let maxHeightMode;
            if (modeCanvas) {
                maxHeightMode = 5;
                await fillInCanvasOnPage(modeCanvas, contentPages.page2,
                    marginLeft, marginTop,
                    maxWidthPerVotesAnalysisItem, maxHeightMode);
                    
                marginTop += maxHeightMode;      
            }

            if (chartCanvas) {
                const maxHeightChart = modeCanvas ? 
                    (maxHeightPerVotesAnalysisItem - (maxHeightMode + labelTextHeight + SPACE_BETWEEN_ADJACENT_ITEMS)) 
                    : 
                    (maxHeightPerVotesAnalysisItem - (labelTextHeight + SPACE_BETWEEN_ADJACENT_ITEMS));
                await fillInCanvasOnPage(chartCanvas, contentPages.page2,
                    marginLeft, marginTop,
                    maxWidthPerVotesAnalysisItem, maxHeightChart);
            }
        }

    } else if (canvasesToPrint.questionsByVolume || canvasesToPrint.questionsList) {
        if (canvasesToPrint.questionsByVolume && canvasesToPrint.questionsByVolume.length > 0) {
            fillInTextOnPage('Questionnaire Analysis', contentPages.page2, (STANDARD_A4_WIDTH / 2 - 30));
            estimatedContentHeightOnPage += 15;

            fillInTextOnPage('Questions by Answer Volume', 
                contentPages.page2, 
                STANDARD_A4_MARGIN, 
                STANDARD_A4_MARGIN + estimatedContentHeightOnPage + SPACE_BETWEEN_ADJACENT_ITEMS
            );  
            estimatedContentHeightOnPage += 15;

            await fillInCanvasOnPage(canvasesToPrint.questionsByVolume[0], 
                contentPages.page2, 
                STANDARD_A4_MARGIN, 
                STANDARD_A4_MARGIN + estimatedContentHeightOnPage, 
                ( STANDARD_A4_WIDTH - (2 * STANDARD_A4_MARGIN) ), 
                STANDARD_A4_HEIGHT - ((2 * STANDARD_A4_MARGIN) + estimatedContentHeightOnPage)
            );
            estimatedContentHeightOnPage += contentPages.page2[2].imageData.height;
        }
        if (canvasesToPrint.questionsList && canvasesToPrint.questionsList.length > 0) {            
            fillInTextOnPage(`Questions List: questions ordered by ${elementsToPrint.questionOrderSelectionLabel}`, 
                contentPages.page2, 
                STANDARD_A4_MARGIN, 
                STANDARD_A4_MARGIN + estimatedContentHeightOnPage + (2 * SPACE_BETWEEN_ADJACENT_ITEMS)
            );  
            estimatedContentHeightOnPage += 20; 

            const pageOffset = 2;
            const spaceLeftOnCurrentPage = maxHeightOnPage - estimatedContentHeightOnPage;
            const maxWidthPerQuestionItem = (STANDARD_A4_WIDTH - (2 * STANDARD_A4_MARGIN)) / 2; // to order question cards in two columns 

            await fillInQuestionItems(contentPages, 
                canvasesToPrint.questionsList, 
                pageOffset, 
                spaceLeftOnCurrentPage, 
                maxWidthPerQuestionItem, 
                maxHeightOnPage
            );
        }     
    }
}

async function fillInQuestionItems(contentPages, questionsListCanvases, currentPageIndex, spaceLeftOnCurrentPage, maxWidth, maxHeight) {       
    // start counting pages and space left to arrange question items accordingly
    let currentPage = currentPageIndex;
    let spaceLeft = spaceLeftOnCurrentPage;

    for ( let i = 0; i < questionsListCanvases.length; i++ ){
        const questionItem = questionsListCanvases[i];
        const questionItemImage = await loadImage(questionItem.toDataURL('image/png'), questionItem.width, questionItem.height);

        setImageDimensions(questionItemImage, maxWidth, maxHeight);
        
        // reset page index and offset when reached and passed middle of question list
        if ( i === Math.ceil(questionsListCanvases.length / 2) ) {
            currentPage = currentPageIndex;
            spaceLeft = spaceLeftOnCurrentPage;
        }

        // arrange first half on left side, second half on right side 
        let marginLeft, marginTop;
        if ( i < Math.ceil(questionsListCanvases.length / 2) ) {
            marginLeft = STANDARD_A4_MARGIN;
        } else {
            marginLeft = STANDARD_A4_MARGIN + maxWidth;   
        }
        
        // calculate space left before placing question item
        spaceLeft -= questionItemImage.height;

        // if it fits it sits, otherwise create new page and place there
        if( spaceLeft >= 0 ) {            
            marginTop = STANDARD_A4_HEIGHT - ( spaceLeft + questionItemImage.height + STANDARD_A4_MARGIN );
            spaceLeft -= SPACE_BETWEEN_ADJACENT_ITEMS;
        } else {
            currentPage += 1;
            if ( !contentPages[`page${currentPage}`] ) {
                contentPages[`page${currentPage}`] = [];
            }

            marginTop = STANDARD_A4_MARGIN;
            spaceLeft = STANDARD_A4_HEIGHT - ( (2 * STANDARD_A4_MARGIN) + questionItemImage.height + SPACE_BETWEEN_ADJACENT_ITEMS );
        }

        contentPages[`page${currentPage}`].push({
            imageData: {
                data: questionItemImage,
                format: 'PNG',
                marginLeft: marginLeft,
                marginTop: marginTop,
                width: questionItemImage.width,
                height: questionItemImage.height,
            }
        });
    }
}

/**
 * Converts mode and chart from DOM Elements to Canvases and returns 
 * a similarly structured array to the one passes initially
 * @param {array} elements each element is an object with keys label, mode, chart 
 * each object is a representation of the title, mean/stack mode and chart of any graph
 */
async function convertVotesElementsToCanvases(elements) {
    const canvases = [];
    const options = {
        logging: false,
    };

    for (let i = 0; i < elements.length; i ++) {
        const currentElement = elements[i];

        // convert found Elements to canvases 
        const { mode, chart } = currentElement;
        let modeCanvas, chartCanvas;
        if (mode) {
            modeCanvas = await html2canvas(mode, options);
        }
        if (chart) {
            chartCanvas = await html2canvas(chart, options);
        }

        canvases.push({
            labelText: currentElement.label,
            modeCanvas,
            chartCanvas
        }); 
    }
    
    return canvases.length > 0 ? canvases : null;
}