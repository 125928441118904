import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell } from 'recharts';
import './css/overallRatingBar.css';
import { useTranslation } from 'react-i18next';

const DEFAULT_COLORS = ['#bbbbbb', '#7ba7ff'];

export const getAllValue = function(all, segment){
	const delta = all.value - segment.value;
	const newAllValue = Object.assign({ value: delta });
	
	newAllValue.name = all.name;

	return newAllValue;
};

const OverallPieStatsBar = props => {
	const { segment, all, colors, isLoaded, isLoading, useAsyncLoad } = props;

	const { t } = useTranslation();

	const dataLoaded = useAsyncLoad ? (isLoaded && !isLoading) : true;
	const hasValue = dataLoaded && segment.value;
	const pieColors = hasValue ? 
						colors !== undefined ? colors : DEFAULT_COLORS :
						["#eee"];

	const percentage = ((segment.value / all.value) * 100).toFixed(2);
	const pieAllValue = getAllValue(all, segment);

	const pieData = hasValue ? [segment, pieAllValue] : [{value: 0}, {value: 100}];

	return (
		<div className="card-content flex">
			<div className="stats-chart">
				<PieChart width={50} height={50}>
					<Pie
						data={pieData}
						cx="50%"
						cy="50%"
						innerRadius={20}
						outerRadius={25} 
						dataKey="value"
						padding={4}
						>
						{
							pieData.map((entry, index) => <Cell key={index} fill={pieColors[index % pieColors.length]}/>)
						}
						</Pie>
				</PieChart>
			</div>
			<div className="stats-info">
				<span className="numbers">
					{hasValue ? 
					<React.Fragment>
						<span style={{color: pieColors[0]}}>{segment.value}</span> / 
						<span style={{color: pieColors[1]}}>{all.value}</span>
					</React.Fragment> :
						<span>{t('macro_stats_no_data')}</span>
					}
				</span>
                {
                    isNaN(percentage) ?
						<small className="description">{t(segment.name)} <label>{t('macro_stats_no_data')}</label></small> :
						<small className="description">
                            {t(segment.name)} <label>{percentage}%</label> {t('macro_stats_of')} {t(all.name)}
						</small>
                }
			</div>
		</div>
	);
};

OverallPieStatsBar.propTypes = {
	/**
	 * @param {Object} segment The segment data supplied to the PieChart with structure:
	 */
	segment: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}).isRequired,

	/**
	 * @param {Object} all The all data supplied to the PieChart with structure:
	 */
	all: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}).isRequired,

	/**
	 * @param {Array} colors Colors of the segments as hex strings in an array 
	 */
	colors: PropTypes.array,

	/**
	 * @param {bool} isLoaded true if data has finished loading and can be displayed, false otherwise
	 */
	isLoaded: PropTypes.bool,

	/**
	 * @param {bool} isLoading true if request has been sent to fetch data, false otherwise
	 */
	isLoading: PropTypes.bool,

	/**
	 * @param {bool} useAsyncLoad true if isLoaded and isLoading should be used accordingly, false if data loading is not visible to user
	 */
	useAsyncLoad: PropTypes.bool,
};

OverallPieStatsBar.defaultProps = {
	segment: {
		value: null
	},

	all: {
		value: null
	},

	isLoaded: false,
	isLoading: false,
	useAsyncLoad: false
};

export default OverallPieStatsBar;