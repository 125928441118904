import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import './css/optionsSelector.css';
import { Search, ChevronDown } from 'react-feather';
import messages from "../../../helpers/messages";
import NoPermissionLabel from "../noPermissionLabel";

import i18n from '../../../i18n';

class OptionsSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dropDownOpen: false,
			dropDownStyle: {},
			filterOptionsText: "",
            t: i18n.t.bind(i18n),
		};

		this.handleSelect = this.handleSelect.bind(this);
		this.handleToggleDropDownOpen = this.handleToggleDropDownOpen.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		this.handleSearchText = this.handleSearchText.bind(this); 
	}

	componentDidMount() {
		document.body.addEventListener('click', this.handleOutsideClick);
		this.setDropdownStyles(this.osDom);
	}

	componentWillUnmount() {
		document.body.removeEventListener('click', this.handleOutsideClick);
	}

	handleOutsideClick(e) {
		if (e.target.classList.contains('options-selector-overlay')) {
			this.setState({ 
				dropDownOpen: false,
				filterOptionsText: ""
			});
		}
	}
		
	handleSelect(option){
		if (!option.disabled) {
			this.setState({ 
				dropDownOpen: false,
				filterOptionsText: ""
			}, this.props.onSelection(option.value));
		}
	}

	handleToggleDropDownOpen() {
		this.setState({ dropDownOpen: !this.state.dropDownOpen });
	}

	handleSearchText(ev) {
		const searchText = ev.target.value;
		this.setState({ filterOptionsText: searchText });
	}

	setDropdownStyles(container) {
		const selectedLabel = container.getElementsByClassName('selection-label')[0];
		const dropDown = container.getElementsByClassName('options-dropdown')[0];

		let marginTop = "0px";
		switch(this.props.position) {
			case "middle" : {
				marginTop = `-${dropDown.clientHeight/2 + selectedLabel.clientHeight/2}px`;
				break;
			}
			case "bottom": {
				marginTop = "0px";
				break;
			}
			default: {
				marginTop = "0px";
			}
		}

		this.setState({ dropDownStyle: { marginTop }});
	}

	get selectionValue() {
		const { value, options } = this.props;
		let selectionValue = value;

		if (isEmpty(value)) {
			selectionValue = options[0].value;
		}

		return selectionValue;
	}

	renderOptions(options) {
		const { t } = this.state;
		const selectionValue = this.selectionValue;	

		const domElements = options.map((o, i) => {
			const isSelected = o.value === selectionValue;

			return (
				<li
					key={i}
					onClick={() => this.handleSelect(o)}
					className={`option uitest-option ${o.disabled ? 'disabled' : ''} ${isSelected ? 'selected' : ''}`}
				>
					{t(o.label)}{' '}
					{o.nonFree && (
						<span className='non-free-account-icon'>
							{' '}
							<NoPermissionLabel message={messages.permissions.features.optionsSelector} size='small' />{' '}
						</span>
					)}
				</li>
			);
		});

		const searchElement = <li key={-1} className="search"><Search size={14} /> <input type="text" value={this.state.filterOptionsText} onChange={this.handleSearchText} /></li>;

		if (this.props.isSearchable === true) {
			domElements.unshift(searchElement);
		}

		return domElements;
	}

	get selectionLabel() {
		const selectionValue = this.selectionValue;	

		const selectionByValue = this.props.options.find(o => o.value.toLowerCase() === selectionValue.toLowerCase());
		return selectionByValue.label;
	}

	get filteredOptions() {
		let filteredOptions;
		const { options } = this.props;
		const { filterOptionsText } = this.state;

		if (filterOptionsText && filterOptionsText.length > 0) {
			filteredOptions = options.filter(o => o.label.toLowerCase().indexOf(filterOptionsText.toLowerCase()) > -1);
		} else {
			filteredOptions = options;
		}

		return filteredOptions;
	}

	render() {
		const { className } = this.props;
		const { t, dropDownOpen, dropDownStyle } = this.state;

		return (
			<div className={`options-selector ${className}`} ref={osDom => this.osDom = osDom}>
				<label className="selection-label uitest-selection-label" onClick={this.handleToggleDropDownOpen}>
                    <span className="export-pdf-label">{t(this.selectionLabel)}</span>
                    <ChevronDown size="16" />
                </label>
				<ul style={dropDownStyle} className={`options-dropdown uitest-options-dropdown ${dropDownOpen ? 'open' : 'closed'}`}>
					{this.renderOptions(this.filteredOptions)}
				</ul>

				{dropDownOpen && <div className="options-selector-overlay" />}
			</div>
		);
	}
}

OptionsSelector.propTypes = {
	/** @prop {array} options the options array for the selector dropdown */
	options: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
		disabled: PropTypes.bool,
		nonFree: PropTypes.bool
	})).isRequired,

	/** @prop {value} selection the selected option value */
	value: PropTypes.string.isRequired,
	
	/** @prop {func} onSelection an action invoked on selecting an option from the dropdown list */
	onSelection: PropTypes.func.isRequired,

	disabled: PropTypes.bool,

	/** @prop {bool} isSearchable if set to true, the dropdown list will contain a search field to 
	 * filter the dropdown values. defaults to false
	 */
	isSearchable:PropTypes.bool,

	className: PropTypes.string,

	/** @prop {string} position the vertical position of the drop down list. Available options: "bottom"|"middle". Defaults to "middle" */
	position: PropTypes.string
};

OptionsSelector.defaultProps = {
	position: "middle"
};

export default OptionsSelector;