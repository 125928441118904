import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/usersActions';
import { editEntity, getEntityForEdit, resetEntityForEdit } from '../../actions/entitiesActions';
import { validateTopicNameDuplicate } from '../../actions/topicBuilderActions';

import { isEmpty } from 'lodash';
import { CONFIRM_MODAL, ACTIVATE_USERS_MODAL } from '../../helpers/modalTypes';
import { showModal, hideModal } from '../../actions/modalActions';
import { userHasSpecialPermissions, PERMISSIONS, userHasPermissions } from '../../helpers/permissionsHelper';
import PermissionsContext from '../contexts/permissionsContext';
import FeaturesContext from '../contexts/featuresContext';
import EntityDetails from '../../components/entities/entityDetails';
import { Edit3, UserPlus } from 'react-feather';
import NoPermissionLabel from '../../components/common/noPermissionLabel';
import messages from '../../helpers/messages';
import EntityAlerts from '../../components/entities/entityAlerts';
import { COMPARATORS } from '../../helpers/featuresHelper';
import { ENTITY_FEATURES } from 'hollerlive-shared/constants';
import UsersGrid from '../../components/users/usersGrid';

import './css/entityDetailsPage.css';
import EntityDetailsEdit from '../../components/entities/entityDetailsEdit';
import { mapToEntityFormModel } from '../../actions/models/entityModel';
import EntityAlertsEdit from '../../components/entities/entityAlertsEdit';
import EntityUserCreate from '../../components/entities/entityUserCreate';
import { USER_ROLES } from '../../helpers/user';

export const IN_EDIT_PANEL_NAMES = { DETAILS: 'DETAILS', ALERTS: 'ALERTS', USERS: 'USERS' };

export class EntityDetailsPage extends Component {
	constructor(props) {
		super(props);

		this.state = { activeInEditPanel: null };

		this.handleEntityEditClick = this.handleEntityEditClick.bind(this);
		this.handleAlertsEditClick = this.handleAlertsEditClick.bind(this);
		this.handleCloseInPageForm = this.handleCloseInPageForm.bind(this);
		this.handleEntityDetailsSubmit = this.handleEntityDetailsSubmit.bind(this);
		this.handleEntityAlertsSubmit = this.handleEntityAlertsSubmit.bind(this);
		this.handleUserCreateClick = this.handleUserCreateClick.bind(this);
		this.handleEntityUserCreate = this.handleEntityUserCreate.bind(this);
		this.handleUserRoleChange = this.handleUserRoleChange.bind(this);
		this.validateTopicName = this.validateTopicName.bind(this);
	}
	
	componentDidMount() {
		const { actions, params } = this.props;
		if (this.canEditEntity) {
			actions.getEntityForEdit(Number(params.id));
		}
		if (this.canViewUsers) {
			actions.getFilteredUsers({ entityId: Number(params.id) });
		}
	}

	componentDidUpdate(prevProps) {
		const { usersData, actions, params, entityForEdit, createdUser } = this.props;
		if (!usersData.isLoading && !usersData.isLoaded) {
			if (this.canViewUsers) {
				actions.getFilteredUsers({ entityId: params.id });
			}
		}
		if ((entityForEdit.isSaved && !prevProps.entityForEdit.isSaved) ||
			(!isEmpty(createdUser) && isEmpty(prevProps.createdUser))) {
			if (isEmpty(entityForEdit.requestErrors) && this.state.activeInEditPanel !== null) {
				actions.resetEntityForEdit();
				actions.resetCreatedUser();
				this.handleCloseInPageForm();
			}
		}
	}

	handleEntityEditClick() {
		this.setState({ activeInEditPanel: IN_EDIT_PANEL_NAMES.DETAILS });
	}

	handleAlertsEditClick() {
		this.setState({ activeInEditPanel: IN_EDIT_PANEL_NAMES.ALERTS });
	}

	handleUserCreateClick() {
		this.setState({ activeInEditPanel: IN_EDIT_PANEL_NAMES.USERS });
	}

	handleCloseInPageForm() {
		this.setState({ activeInEditPanel: null });
	}

	handleEntityDetailsSubmit(values) {
		const { entityForEdit, entityUsers, actions } = this.props;
		if (entityUsers.length > 0) {
			if (values.isActive !== entityForEdit.active) {
				this.showUsersDeactivateConfirm(values);
			} else {
				actions.editEntity(values);
			}
		} else {
			actions.editEntity(values);
		}
	}

	handleEntityAlertsSubmit(values) {
		const { entityForEdit, actions } = this.props;
		const updatedEntity = Object.assign({}, mapToEntityFormModel(entityForEdit), values);
		actions.editEntity(updatedEntity);
	}
	
	handleEntityUserCreate(values) {
		this.props.actions.createUser(values);
	}

	handleUserRoleChange(user, roleId) {
		this.props.actions.setUserRole(user, roleId, this.entityData.id);
	}

	showUsersDeactivateConfirm(values) {
		const { actions, entityUsers } = this.props;
		if (values.isActive) {
			const activateUsersModalConfig = {
				onClose: () => {
					actions.hideModal();
				},
				onSubmit: (shouldActivateUsers) => {
					actions.hideModal();
					actions.editEntity({ ...values, shouldActivateUsers });
				},
				data: {
					entityName: values.name,
					usersCount: entityUsers.length
				}
			};
			actions.showModal(ACTIVATE_USERS_MODAL, activateUsersModalConfig);
		} else {
			const deactivateUsersModalConfig = {
				title: "DEACTIVATE ORGANIZATION",
				message: <span>
					<p>You are about to deactivate organization <b>&quot;{ values.name }&quot;</b>.</p>
					<p>This will also deactivate all <b>{ entityUsers.length } assigned users</b>, revoking their access! Do you want to deactivate the organization?</p>
				</span>,
				onClose: () => {
					actions.hideModal();
				},
				onConfirm: () => {
					actions.hideModal();
					actions.editEntity(values);
				}
			};
			actions.showModal(CONFIRM_MODAL, deactivateUsersModalConfig);
		}
	}

	get canEditEntity() {
		return userHasPermissions([ PERMISSIONS.EDIT_ENTITIES ], this.props.dashUser);
	}

	get canViewUsers() {
		return userHasPermissions([ PERMISSIONS.VIEW_USERS ], this.props.dashUser);
	}

	get editModeEnabled() {
		const { activeInEditPanel} = this.state;
		return activeInEditPanel !== null;
	}

	get entityData() {
		const { dashUser, entityForView, entityForEdit } = this.props;
		const canEdit = userHasPermissions([ PERMISSIONS.EDIT_ENTITIES ], dashUser);
		return canEdit ? entityForEdit : entityForView;
	}

	get canSetAsManager() {
		const { entityUsers, entityForEdit } = this.props;
		const managers = entityUsers.filter(user => Number(user.roleId) === USER_ROLES.entityManager);
		const numManagersLimit = entityForEdit.features[ENTITY_FEATURES.NUMBER_OF_MANAGERS];
		return numManagersLimit > 0 ? managers.length < numManagersLimit : true;
	}
	
	validateTopicName(value) {
		this.props.actions.validateTopicNameDuplicate(value);
	}
	
	render() {
		const { hasSpecialPermissions, entityUsers, actions, dashUser, children } = this.props;
		const { activeInEditPanel } = this.state;
		return (
			this.entityData && Number.isInteger(this.entityData.id) ?
			<div className={`entity-details-page ${this.editModeEnabled ? 'in-edit' : ''}`}>
				<div className={`entity-details-panel panel ${activeInEditPanel === IN_EDIT_PANEL_NAMES.DETAILS ? 'in-edit' : ''}`}>
					{activeInEditPanel !== IN_EDIT_PANEL_NAMES.DETAILS ? 
						<>
							<PermissionsContext hideIfNoPermission={true} requiredPermissions={[PERMISSIONS.EDIT_ENTITIES]}>
								<button className={`btn btn-outline btn-xs btn-edit`} onClick={this.handleEntityEditClick}><Edit3 size={14} /> edit organization</button>
							</PermissionsContext>
							<EntityDetails entity={this.entityData} />
						</>
						:
						<EntityDetailsEdit
							entityDetails={mapToEntityFormModel(this.entityData)}
							user={dashUser}
							requestErrors={this.entityData.errors || {}}
							hasSpecialPermissions={hasSpecialPermissions}
							onSubmit={this.handleEntityDetailsSubmit}
							onCancel={this.handleCloseInPageForm}
							showModal={actions.showModal}
							hideModal={actions.hideModal}
							validateTopicName={this.validateTopicName}
						/>
					}
				</div>

				{this.canEditEntity && <div className={`alerts-config-panel panel ${activeInEditPanel === IN_EDIT_PANEL_NAMES.ALERTS ? 'in-edit' : ''}`}>
					{activeInEditPanel !== IN_EDIT_PANEL_NAMES.ALERTS ? 
					<>
						<h5>
							<PermissionsContext hideIfNoPermission={true} requiredPermissions={ [PERMISSIONS.EDIT_ENTITIES] } >
								<FeaturesContext 
									requiredFeatures={[{ 
										name: ENTITY_FEATURES.HAS_TOPIC_ALERTS, 
										compare: COMPARATORS.IS_TRUE,
										matchValue: this.entityData.features[ENTITY_FEATURES.HAS_TOPIC_ALERTS]
									}]}
									renderOtherwise={
										<button className="btn btn-outline btn-xs btn-edit" disabled>
											<NoPermissionLabel position="right" message={messages.permissions.features.noAlerts} />
											<Edit3 size={14} /> edit alerts
										</button>		
									}
								>		
									<button className={`btn btn-outline btn-xs btn-edit`} onClick={this.handleAlertsEditClick}><Edit3 size={14} /> edit alerts</button>
								</FeaturesContext>
							</PermissionsContext>
							default alerts configuration
						</h5>
						<EntityAlerts 
							className="panel"
							systemNotifActive={this.entityData.systemNotifActive}
							entityNotifActive={this.entityData.entityNotifActive}
							voteNotifActive={this.entityData.voteNotifActive}
							voteNotifInterval={this.entityData.voteNotifInterval}
						/>
					</>
					:
					<EntityAlertsEdit 
						entityData={mapToEntityFormModel(this.entityData)}
						onSubmit={this.handleEntityAlertsSubmit}
						onCancel={this.handleCloseInPageForm}
						showModal={actions.showModal}
						hideModal={actions.hideModal}
					/>
					}
				</div>}

				<PermissionsContext hideIfNoPermission={true} requiredPermissions={ [PERMISSIONS.VIEW_USERS] } >
					<div className={`users-grid panel ${activeInEditPanel === IN_EDIT_PANEL_NAMES.USERS ? 'in-edit' : ''}`}>
						<h5>
							{activeInEditPanel !== IN_EDIT_PANEL_NAMES.USERS &&
							<PermissionsContext hideIfNoPermission={true} requiredPermissions={ [PERMISSIONS.CREATE_AND_ASSIGN_USERS] } >
								<FeaturesContext 
									requiredFeatures={[{
										name: ENTITY_FEATURES.NUMBER_OF_USERS,
										compare: COMPARATORS.GREATER_THAN_OR_ZERO,
										value: entityUsers.length,
										matchValue: this.entityData.features[ENTITY_FEATURES.NUMBER_OF_USERS]
									}]}
									renderOtherwise={
										<button 
											type="button"
											disabled={true}
											className="btn btn-xs btn-outline btn-edit" 
											onClick={()=>{}}
										>
											<NoPermissionLabel position="right" message={messages.permissions.features.insufficientNumberOfUsers} />
											<UserPlus size={16} /> add or assign users
										</button>
									}>
									<button onClick={this.handleUserCreateClick} className="btn btn-xs btn-outline btn-edit" type="button">
										<UserPlus size={16} /> add or assign users
									</button>
								</FeaturesContext>							
							</PermissionsContext>}
							{activeInEditPanel !== IN_EDIT_PANEL_NAMES.USERS ? "Associated users" : 'Associate a new user' }
						</h5>
						
						<UsersGrid 
							users={entityUsers}
							isEditRolesAllowed={true}
							entityFeatures={this.entityData.features}
							onRoleChange={this.handleUserRoleChange}
						/>
					
						{activeInEditPanel !== IN_EDIT_PANEL_NAMES.USERS ? !this.isMobileView && 
							<PermissionsContext hideIfNoPermission={true} requiredPermissions={ [PERMISSIONS.CREATE_AND_ASSIGN_USERS] } >
								<FeaturesContext 
									requiredFeatures={[{
										name: ENTITY_FEATURES.NUMBER_OF_USERS,
										compare: COMPARATORS.GREATER_THAN_OR_ZERO,
										value: entityUsers.length,
										matchValue: this.entityData.features[ENTITY_FEATURES.NUMBER_OF_USERS]
									}]}
									renderOtherwise={
										<button 
											type="button"
											disabled={true}
											className="btn btn-outline btn-dotted btn-full-width btn-success btn-non-free-account" 
											onClick={()=>{}}
										>
											<NoPermissionLabel mode="left" message={messages.permissions.features.insufficientNumberOfUsers} />
											<UserPlus size={16} /> assign new user
										</button>
									}>
									<p className="message">
										<button onClick={this.handleUserCreateClick} className="btn btn-outline btn-dotted btn-full-width btn-success" type="button">
											<UserPlus size={16} /> assign new user
										</button>
									</p>
								</FeaturesContext>
							</PermissionsContext>
						:
							<EntityUserCreate 
								hasSpecialPermissions={hasSpecialPermissions}
								entity={this.entityData}
								onSubmit={this.handleEntityUserCreate}
								requestErrors={this.entityData.userCreationErrors}
								onCancel={this.handleCloseInPageForm}
								showModal={actions.showModal}
								hideModal={actions.hideModal}
								resetUserRequestErrors={actions.resetUserRequestErrors}
								canSetAsManager={this.canSetAsManager}
							/>
						}
					</div>
				</PermissionsContext>
				{children}
			</div>
			:
			<div />
		);
	}
};

EntityDetailsPage.propTypes = {
	/** @prop {bool} hasSpecialPermissions
	 * logged user must have special permissions to access special features like adding a superadmin user */
	hasSpecialPermissions: PropTypes.bool.isRequired,
	router: PropTypes.shape({ push: PropTypes.func }),
	/** @param {object} entityForEdit the organization which will be modified */
	entityForEdit: PropTypes.shape({
		id: PropTypes.oneOfType([
			() => null,
			PropTypes.number
		]),
		active: PropTypes.bool,
		isSaved: PropTypes.bool,
		isUserSaved: PropTypes.bool,
		userCreationErrors: PropTypes.object
	}).isRequired,
	/** @prop {object} entityForView the organization data which will be dispayed to users without edit permissions */
	entityForView: PropTypes.shape({
		id: PropTypes.oneOfType([
			() => null,
			PropTypes.number
		]),
		active: PropTypes.bool,
		name: PropTypes.string
	}).isRequired,
	entityUsers: PropTypes.arrayOf(PropTypes.shape()),
	/** @prop {object} createdUser Successfully created user */
	createdUser: PropTypes.shape({ id: PropTypes.number }),
	/** @prop {object} updatedUser Successfully updated user */
	updatedUser: PropTypes.shape({ id: PropTypes.number }),
	userId: PropTypes.number,
	params: PropTypes.shape({ id: PropTypes.string }),
	dashUser: PropTypes.shape({ permissions: PropTypes.arrayOf(PropTypes.string).isRequired }),
	usersData: PropTypes.shape({ isLoaded: PropTypes.bool, isLoading: PropTypes.bool }),
	actions: PropTypes.shape(),
	children: PropTypes.any
};

EntityDetailsPage.defaultProps = {
	hasSpecialPermissions: false,
	entityForEdit: {},
	entityForView: {}
};

const mapStateToProps = (state, props) => {
	let entityUsers = [];
	let dashUser = {};
	let entities = [];
	if (state.entitiesList && state.entitiesList.length > 0) { // when managing organizations(admin)
		entities = state.entitiesList;
	} else if (state.entities && state.entities.length > 0) { // when changing organization details(org manager)
		entities = state.entities;
	}
	const entityForView = entities.find(e => e.id === Number(props.params.id));
	if (state.usersData && state.usersData.usersList.length > 0) {
		entityUsers = state.usersData.usersList.filter(u => u.entityId === Number(props.params.id));
	}
	if (state.auth && state.auth.user) {
		dashUser = state.auth.user;
	}

	return {
		hasSpecialPermissions: userHasSpecialPermissions(state.auth.user),
		createdUser: state.usersData.createdUser,
		updatedUser: state.usersData.updatedUser,
		user: (state.usersData && (state.usersData.usersList || [])).find(u => u.id === Number(props.params.userid)),
		userId: Number(props.params.userid),
		entityUsers,
		entityForEdit: state.entityForEdit,
		entityForView,
		usersData: state.usersData || {},
		dashUser
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
				...userActions,
				editEntity,
				getEntityForEdit,
				resetEntityForEdit,
				validateTopicNameDuplicate,
				showModal,
				hideModal
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityDetailsPage);
