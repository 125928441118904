import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RatingBadge from "../../common/ratingBadge";
import './css/timeline.css';
import { getElapsedTime } from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';

const VoteTimelineItem = (props) => {
	const { vote, className, searchTerm } = props;
	const [classNames, setClassName] = useState('vote-timeline-item');
	const [iteration, setIterationsCount] = useState(0);

	const { t } = useTranslation();

	useEffect(() => {
		if (props.vote.vote) {
			if (iteration === 0) {
				setIterationsCount(iteration + 1);
				setClassName(`${className} vote-timeline-item animate`);
			} else if (iteration === 1) {
				setTimeout(() => {
					setClassName(`${className} vote-timeline-item`);
					setIterationsCount(iteration + 1);
				}, 400);
			}
		}
	}, [props.vote.vote, iteration]);

	let timeVoted = vote.date ? getElapsedTime(vote.date) : '';
	let sourcesString = "";

	if (vote.sources) {
		sourcesString = vote.sources.join(', ');

		if (searchTerm && searchTerm.length > 0) {
			const reg = new RegExp(`(${searchTerm})`, 'gi');
			const textParts = sourcesString.split(reg);
				
			sourcesString = textParts.map((part, index) => (part.match(reg) ? <span key={index} className='search-text-highlight'>{part}</span> : part));
		}
	}

	let ageGenderText = "";

	if (vote.gender && vote.gender.length > 0) {
		ageGenderText = `${ageGenderText} ${t('topic_vote_timeline_by')} ${vote.gender}`;
		if (vote.ageGroup && vote.ageGroup.length > 0) {
			ageGenderText = `${ageGenderText}, ${vote.ageGroup}`;
		}
	} else {
		if (vote.ageGroup && vote.ageGroup.length > 0) {
			ageGenderText = `${t('topic_vote_timeline_by_unknown')}, ${vote.ageGroup}`;
		}
	}

	return (
		<div className={classNames}>			
			<div className="timeline-item-dot">
				<RatingBadge vote={vote.vote} size={14} />
			</div>

			<div className="timeline-item-info">
				<small className="time-voted text-muted">{timeVoted}</small> 
				{ vote.topicName && vote.topicName.length > 0 && <small className="topic-name">{t('topic_vote_timeline_on')} { vote.topicName }<br /></small> }
				<small className="age-gender">{ ageGenderText }</small>
				{ vote.city && vote.city.length > 0 && <small className="location">, {t('topic_vote_timeline_from')} {vote.city}{vote.country && vote.country.length > 0 && `, ${vote.country}`}</small> }
				{ sourcesString && sourcesString.length > 0 && <span className="sources"><small className="text-muted">, {t('topic_vote_timeline_sources')}: </small><small>{ sourcesString }</small></span> }
			</div>
		</div>
	);
};

VoteTimelineItem.propTypes = {
	vote: PropTypes.shape({
		topicName: PropTypes.string,
		date: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		vote: PropTypes.number,
		gender: PropTypes.string,
		ageGroup: PropTypes.string,
		sources: PropTypes.arrayOf(PropTypes.string)		
	}).isRequired,
	searchTerm: PropTypes.string,
	className: PropTypes.string
};

export default VoteTimelineItem;