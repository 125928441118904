import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { isEmpty } from 'lodash';

import LoginForm from '../../components/login/LoginForm';
import { login, requestLoginStatus, requestForgotPassword, clearErrors } from '../../actions/authActions';
import {validateLoginInput} from '../../helpers/validators';
import messages from '../../helpers/messages';
import Card from '../../components/common/card';
import TextFieldGroup from '../../components/common/textFieldGroup';
import { CheckCircle } from 'react-feather';
import i18n from '../../i18n';

export class UserLandingPage extends Component {
    constructor(props, context){
        super(props, context);
        this.state = {
            identifier: '',
            password: '',
            errors: {},
            isLoading: false,
            showForgotInput: false,
            showSuccessRequestSent: false,
            t: i18n.t.bind(i18n),
        };
        
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onPassResetSubmit = this.onPassResetSubmit.bind(this);
        this._showEmailInputField = this._showEmailInputField.bind(this);
        this.resetToLoginForm = this.resetToLoginForm.bind(this);
    }
    
    componentDidUpdate(prevProps, prevState){
        // the purpose of this is to reenable the login form (although it should not show while the user is logged-in)
        if( (!prevProps.auth.isAuthenticated && this.props.auth.isAuthenticated) || 
            (!isEmpty(this.props.auth.errors) && prevState.isLoading) ){
            this.setState({isLoading: false});
        }
    }

    static getDerivedStateFromProps(nextProps) {
        const isForgotPassRequest = nextProps.requestStatus && nextProps.requestStatus.endpointName.indexOf('forgotpass') > -1;
        if (isForgotPassRequest && (nextProps.requestStatus && nextProps.requestStatus.success)) {
            return { showSuccessRequestSent: true };
        }

        return { showSuccessRequestSent: false };
    }

    _showEmailInputField(){
        this.setState({showForgotInput: true}, this.props.clearErrors);   
    }

    resetToLoginForm(){
        this.setState({
            showForgotInput: false,
            identifier: '',
            password: '',
            showSuccessRequestSent: false
        }, this.props.clearErrors);
    }

    onPassResetSubmit(e){
        e.preventDefault();
        if(!this.state.identifier){
            this.setState({ errors: Object.assign({}, this.state.errors, {identifier: messages.errors.required}) });
        } else{
            this.props.requestForgotPassword(this.state.identifier);
        }
    }

    isValid(validation) {
        const { errors, isValid } = validation;

        if (!isValid) {
            this.setState({ errors });
        }

        return isValid;
    }

    onSubmit(e, isValid) {
        e.preventDefault();
        if (isValid) {
            this.setState({ isLoading: true }, this.props.clearErrors);
            this.props.login(this.state);
        }
    }

    onChange(e) {
        this.setState({ 
            [e.target.name]: e.target.value, 
            errors: Object.assign({}, this.state.errors, { [e.target.name]: '' }) });
    }


    render() {
        const { showForgotInput, showSuccessRequestSent, errors, identifier, password, isLoading } = this.state;
        const serverError = this.props.auth ? this.props.auth.errors : null;

        return (
            <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    <Card title={`${showForgotInput ? this.state.t('login_page_title_forgotten') : this.state.t('login_page_title')}`}>
                        {!showForgotInput ?
                            <div>
                                <p className="message">{this.state.t('login_page_subtitle')}</p>

                                <LoginForm
                                    className={`login-form clearfix ${showForgotInput ? 'animate animate-close' : 'animate animate-open'}`}
                                    serverError={serverError}
                                    formErrors={errors}
                                    identifier={identifier}
                                    password={password}
                                    isLoading={isLoading}
                                    onChange={this.onChange}
                                    onSubmit={(e) => { this.onSubmit(e, this.isValid(validateLoginInput(identifier, password))); }}
                                    onForgotPassword={this._showEmailInputField}
                                />
                            </div>
                        :
                            <form className={`forgot-password ${showForgotInput ? 'animate animate-open' : 'animate animate-close'}`}>
                                {!showSuccessRequestSent ?
                                    <div>
                                        {serverError && !isEmpty(serverError) && <div className="alert alert-danger">{`${serverError}`}</div>}
                                        <p className="message">{this.state.t('login_page_subtitle_forgotten')}</p>
                                        <TextFieldGroup
                                            field="identifier"
                                            label={this.state.t('forgotten_password_form_label_email')}
                                            value={identifier}
                                            error={errors.identifier}
                                            onChange={this.onChange}
                                            type="text"
                                        />
                                        <button className="btn btn-primary btn-full-width" type="button" onClick={this.onPassResetSubmit}>{this.state.t('forgotten_password_form_button_submit')}</button>
                                    </div>
                                :
                                    <p className="message text-center">
                                        <span className="text-success"><CheckCircle size={14} /></span> {this.state.t('forgotten_password_form_message_success')} <strong>{identifier}</strong>.
                                    </p> 
                                }
                                <p className="message text-center">
                                    <button type="button" className="btn btn-link" onClick={this.resetToLoginForm}>{this.state.t('forgotten_password_form_button_back_to_login')}</button>
                                </p>
                            </form>
                        }
                    </Card>
                    
                </div>
            </div>
          
        );
    }
}

function mapStateToProps(state){
    return {
        auth: state.auth,
        requestStatus: state.requestStatus
    };
}

UserLandingPage.propTypes = {
    auth: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    requestLoginStatus: PropTypes.func.isRequired,
    requestForgotPassword: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    requestStatus: PropTypes.shape({
        endpointName: PropTypes.string,
        error: PropTypes.bool,
        success: PropTypes.bool,
        pending: PropTypes.bool
    })
};

UserLandingPage.contextTypes = {
    router:PropTypes.object.isRequired
};

export default connect(mapStateToProps, {login, requestLoginStatus, requestForgotPassword, clearErrors}) (UserLandingPage);