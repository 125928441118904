import React from 'react';
import PropTypes from 'prop-types';
import Toggle from './toggle';
import styles from './css/switch.module.css';
import { isRequiredFuncIfNotSetFlag } from '../../../helpers/utils';

export const Switch = ({ value, onLabel, offLabel, onChange, disabled, inverse }) => {
    return (
        <span className={`${styles.switch} ${disabled ? styles.disabled : ''} ${inverse ? styles.inverse : ''}`}>
            {offLabel && (
                <label className={`${!value ? styles.toggledLabel : styles.untoggledLabel}`}>{offLabel}&nbsp;</label>
            )}
            <Toggle
                value={value}
                onChange={onChange}
                disabled={disabled}
                className={`${styles.switchToggle} ${inverse ? styles.switchToggleInverse : ''}`}
            />
            {onLabel && (
                <label className={`${value ? styles.toggledLabel : styles.untoggledLabel}`}>&nbsp;{onLabel}</label>
            )}
        </span>
    );
};

Switch.propTypes = {
    /** @prop {bool} value indicates whether switch is on or off */
    value: PropTypes.bool.isRequired,
    /** @prop {string} onLabel display label for the on-position */
    onLabel: PropTypes.string.isRequired,
    /** @prop {string} offLabel display label for the off-position */
    offLabel: PropTypes.string.isRequired,
    /** @prop {func} onChange action on flipping the switch */
    onChange: isRequiredFuncIfNotSetFlag('disabled'),
    /** @prop {bool} disabled optional indicates whether the toggle is disabled */
    disabled: PropTypes.bool,
    /** @prop {bool} inverse optional two-tone thematic color flip. Default is false */
    inverse: PropTypes.bool
};

export default Switch;
