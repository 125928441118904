import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intersection, difference } from "lodash";

import RenderByPermission from '../../components/common/renderByPermission';
import { userHasFullAccess } from '../../helpers/permissionsHelper';

const PermissionsContext = (props) => {
    const { user, permissions, requiredPermissions, matchSingle, hideIfNoPermission, message, renderOtherwise } = props;

    if (userHasFullAccess(user, requiredPermissions)) {
        return props.children || null;
    }

    const hasPermissions = () => {
		if (matchSingle) {
			return intersection(permissions, requiredPermissions).length > 0;
		}

		return difference(requiredPermissions, permissions).length === 0;
	};

    return !hasPermissions() ? (
        !hideIfNoPermission ? <RenderByPermission renderOtherwise={renderOtherwise} hasPermissions={hasPermissions()} hideIfNoPermission={hideIfNoPermission} message={message}>
            {props.children}
        </RenderByPermission> : null
    ) : props.children;
};

PermissionsContext.propTypes = {
    requiredPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.arrayOf(PropTypes.elementType),
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]),
    renderOtherwise: PropTypes.element,
    matchSingle: PropTypes.bool,

    permissions: PropTypes.arrayOf(PropTypes.string),
    user: PropTypes.any,
    entity: PropTypes.any,
    hideIfNoPermission: PropTypes.bool
};

PermissionsContext.defaultProps = {
    hideIfNoPermission: false
};

function mapStateToProps(state) {
    const entity = state.entities[0] || {};
    const user = state.auth.user || {};

    return {
        permissions: user.permissions ? user.permissions : [],
        user,
        entity,
    };
}

export default connect(mapStateToProps, {})(PermissionsContext);