import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import './css/topicLinksModal.css';
import Modal from '../common/modal';
import TopicMediaEmbed from '../topics/topicMediaEmbed';
import { BarChart, Edit3, CheckCircle } from 'react-feather';

const CreateTopicLinksModal = ({ onClose, width, data }) => {
	const topicNameLabel = data.topicDisplayName && data.topicDisplayName.length > 0 ? data.topicDisplayName : data.topicName;

	return (
		<Modal 
			className="topic-links-modal"
			onClose={onClose}
			onOk={onClose}
			width={width}
			hasFooter={false}
			hasPadding={false}
		>
			<div className="topic-links-modal-heading">
				<h2>Great!</h2>
				<p>The topic {topicNameLabel} is live!</p>
			</div>

			<div className="check-circle">
				<CheckCircle size={56} color={'#fff'} />
			</div>

			<TopicMediaEmbed 
				topicName={data.topicName}
				topicDisplayName={data.topicDisplayName}
				linkSources={data.linkConfig.sources}
			/>

			<div className="modal-footer">
				<div className="btn-toolbar pull-right clearfix">
					<Link 
						onClick={() => onClose(false, true)}
						to={`/topics/edit/${data.topicId}`} 
						className="btn btn-outline"
					>
						<Edit3 size={14} /> edit topic
					</Link>

					<Link 
						onClick={() => onClose(true, true)}
						to={`/topics`} 
						className="btn btn-outline btn-outline-primary"
					>
						<BarChart size={14} /> Go to topics management
					</Link>
				</div>
			</div>
		</Modal>
	);
};

CreateTopicLinksModal.propTypes = {
	data: PropTypes.shape({
		topicId: PropTypes.string.isRequired,
		topicName: PropTypes.string.isRequired,
		topicDisplayName: PropTypes.string,
		linkConfig: PropTypes.object
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	width: PropTypes.number
};

export default CreateTopicLinksModal;