import * as types from './actionTypes';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {graphPost, } from '../api/expressApi';
import { handleCommonError, handleValidationErrors } from './errorActions';
import { addNotification } from './miscActions';
import { notificationLevel} from '../helpers/notification';

export function sendContactSupportEmail(messagePayload){
	return (dispatch) => {
		dispatch(showLoading());
		
        const formData = new FormData();
        if (messagePayload.attachments && messagePayload.attachments.length > 0){
        messagePayload.attachments.forEach((file)=>{
            formData.append('attachments', file);
        });
        }
        
        formData.append('email', messagePayload.email);
        formData.append('subject', messagePayload.subject || " ");
        formData.append('message', messagePayload.message);
        
        graphPost('/contact', formData).then(() => {
            dispatch(addNotification(`Message sent. We’ll reply via email shortly.`,  notificationLevel.success));

            dispatch(hideLoading());
            dispatch(sendContactFormSuccess());
		}, 
		error => {
            dispatch(hideLoading());
			if (error.validationErrors) {
			    // TODO: remove this re-mapping when error collections return types are unified
                error.validationErrors = error.validationErrors.reduce((errsObj, err) => {
					return Object.assign(errsObj, err);
				}, {});
				handleValidationErrors(dispatch, error, types.SET_CONTACT_FORM_ERRORS);
			} else {
                handleValidationErrors(dispatch, 
                    {
                        validationErrors: {serverError: 
                                `Server is not responding at the moment. We are sorry for the inconvenience. 
                                Please call +31643884770 if there is anything we can do for you while we are fixing this problem.
                                You can try to contact us via this form in a while.`} 
                    }, 
                    types.SET_CONTACT_FORM_ERRORS);
				handleCommonError(dispatch, error, false);
			}
		});
	};
}

export function clearContactForm() {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_CONTACT_FORM });
    };
}

export function clearContactFormErrors() {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_CONTACT_FORM_ERRORS });
    };
}

function sendContactFormSuccess() {
    return {type: types.CONTACT_FORM_SUCCESS};
}

