import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GroupByVoteList from '../../../components/topics/qna/answersFeed/groupByVoteList';
import Toggle from '../../../components/common/form/toggle';
import FeedMetaInfoBar from '../../../components/topics/qna/answersFeed/common/feedMetaInfoBar';
import { DEFAULT_PAGE_SIZE, gethMarkedDeprecatedAnswers } from '../../../helpers/graphsService';
import SearchField from '../../../components/common/searchField';
import { isEmpty } from 'lodash';
import DismissableMessage from '../../../components/common/dismissableMessage';
import { EyeOff, Download, HelpCircle } from 'react-feather';
import PopoverButton from '../../../components/common/popover/popoverButton';
import FeaturesContext from '../../../scenes/contexts/featuresContext';
import { COMPARATORS } from '../../../helpers/featuresHelper';
import { ENTITY_FEATURES } from 'hollerlive-shared/constants';
import NoPermissionLabel from '../../../components/common/noPermissionLabel';
import messages from '../../../helpers/messages';
import i18n from '../../../i18n';

class TopicDetailsFeedVotes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			areDeprecatedAnswersVisible: true,
			isCollapsedAll: false,
			searchValue: '',
			t: i18n.t.bind(i18n)
		};

		this.handleDeprecatedAnswersVisibilityToggle = this.handleDeprecatedAnswersVisibilityToggle.bind(this);
		this.handleCollapseExpandAll = this.handleCollapseExpandAll.bind(this);
		this.handleRequestNextVotes = this.handleRequestNextVotes.bind(this);
		this.handleDebouncedSearchQuestionChange = this.handleDebouncedSearchQuestionChange.bind(this);
		this.handleSearchClear = this.handleSearchClear.bind(this);
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
		this.handleExportCsvClick = this.handleExportCsvClick.bind(this);
	}
	
	componentDidMount() {
		this.props.requestVotes({ offset: 0 });
	}

	handleDebouncedSearchQuestionChange(searchValue) {
		if (searchValue !== this.state.searchValue) {
			this.setState({ searchValue });

			this.props.resetVotes();
			this.handleSearchSubmit(searchValue);
		}
	}

	handleSearchClear() {
		if (this.state.searchValue) {
			this.setState({ searchValue: '' });
			this.props.resetVotes();
			this.props.requestVotes({ offset: 0 });
		}
	}

	handleSearchSubmit(searchFieldValue) {
		this.props.requestVotes({ offset: 0, searchValue: searchFieldValue });
	}

	handleExportCsvClick() {
		this.props.requestExportedVotes(this.state.searchValue);
	}

	handleRequestNextVotes() {
		const nextPage = Math.ceil(this.props.votes.length / DEFAULT_PAGE_SIZE);
        const searchValue = this.state.searchValue;
        
		this.props.requestVotes({ offset: nextPage * DEFAULT_PAGE_SIZE, searchValue });
	}

	handleDeprecatedAnswersVisibilityToggle() {
		const shouldBeDeprecatedVisible = !this.state.areDeprecatedAnswersVisible;

		this.setState({
			areDeprecatedAnswersVisible: shouldBeDeprecatedVisible
		});
	}

	handleCollapseExpandAll() {
		this.setState({ isCollapsedAll: !this.state.isCollapsedAll });
	}

	getVotesList() {
		const { votes, totalVotes, questions, resetVotes } = this.props;

		return <GroupByVoteList
					votes={votes}
					questions={questions}
					totalVotesCount={totalVotes}
					isCollapsedAllOn={this.state.isCollapsedAll}
					onLoadNextPage={this.handleRequestNextVotes}
					resetVotes={resetVotes}
					searchTerm={this.state.searchValue}
					hideDeprecatedQuestions={this.state.areDeprecatedAnswersVisible === false}
				/>;
	}

	render() {
		const t = this.state.t;
		const { votes, totalVotes, hasDeprecatedQuestions } = this.props;
		return (
			<div className="feed-view chronological-view uitest-chronological-view">
				<div className={`search-filter-panel clearfix`}>
						<SearchField onSearch={this.handleDebouncedSearchQuestionChange}
									 placeholder={t('topic_vote_feed_search_placeholder')}
									 isClearButtonVisible={true}
									 onClear={this.handleSearchClear}
									 debounceOnSearch={true}
						/>
				</div>
				
				<FeedMetaInfoBar total={totalVotes} loaded={(votes || []).length}
								 onCollapsedAllCick={this.handleCollapseExpandAll}>
					
					<React.Fragment>
						<FeaturesContext
							requiredFeatures={[{
								name: ENTITY_FEATURES.HAS_DATA_EXPORT,
								compare: COMPARATORS.IS_TRUE
							}]}
							renderOtherwise={
								<button type="button" disabled={true} className="btn btn-xs btn-outline">
									<NoPermissionLabel message={messages.permissions.features.noCsvExport} />
									<Download size="12"/> {t('topic_vote_feed_export_as_csv')}
								</button>
							}
						>
							<button type="button" disabled={votes.length === 0} className="btn btn-xs btn-outline" onClick={this.handleExportCsvClick}>
								<Download size="12"/> {t('topic_vote_feed_export_as_csv')}
							</button>
						</FeaturesContext>
						
						<PopoverButton text={<HelpCircle size={18}/>} className="btn btn-transparent"
									   position="bottom" popoverWidth={400}>
							<p className="text-info message">{t('topic_vote_feed_export_popover')}</p>
						</PopoverButton>
					</React.Fragment>
				</FeedMetaInfoBar>
				
				{hasDeprecatedQuestions && 
					<DismissableMessage messageKey="deprecatedQuestionsMessage" type="warning">
						{t('topic_vote_feed_deprecated_questions_message_1')} <EyeOff size={16} />, {t('topic_vote_feed_deprecated_questions_message_2')}
					</DismissableMessage>
				}

				<div className="text-left form-group message">
					<Toggle value={this.state.areDeprecatedAnswersVisible} onChange={this.handleDeprecatedAnswersVisibilityToggle} />
					<label className="control-label">{t('topic_vote_feed_show_edited_or_removed')}</label>
				</div>
				
				{votes && votes.length > 0 && this.getVotesList()}
			</div>
		);
	}
}

TopicDetailsFeedVotes.propTypes = {
	/** 
	 * @prop {func} requestVoters request paged votes 
	 * @param {object} requestParams object containtg offset and searchFieldValue props */
	requestVotes: PropTypes.func.isRequired,

	resetVotes: PropTypes.func.isRequired,

	/** 
	 * @prop {func} requestExportedVotes request paged votes 
	 * @param {object} requestParams object containtg offset and searchFieldValue props */
	requestExportedVotes: PropTypes.func.isRequired,

	votes: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		voterUniqueId: PropTypes.string,
		vote: PropTypes.number,
		gender: PropTypes.string,
		ageGroup: PropTypes.string,
		date: PropTypes.string,
		city: PropTypes.string,
		sources: PropTypes.arrayOf(PropTypes.string),
		country: PropTypes.string,
		qna: PropTypes.arrayOf(PropTypes.shape({
			question: PropTypes.string,
			answer: PropTypes.string,
			timeSpent: PropTypes.number
		})),
	})),

	totalVotes: PropTypes.number,
	questions: PropTypes.array,
	hasDeprecatedQuestions: PropTypes.bool
};

function mapStateToProps(state, ownProps) {	
	const topicId = ownProps.params.id;
	const votesFeedForTopic = state.votesFeed[topicId];
	
	let questionDetails = [];
	if (state.qnaForTopics[topicId] && state.qnaForTopics[topicId].questionDetails) {
		questionDetails = state.qnaForTopics[topicId].questionDetails;
	}

	const questions = questionDetails.map(q => {
		return {
			question: q.question,
			orderIndex: q.orderIndex
		};
	});

	let votesWithMarkedDeprecatedQuestions = [];
	let totalVotes = 0;
	let hasDeprecatedQuestions = false;

	if (votesFeedForTopic && votesFeedForTopic.votesData) {
		totalVotes = votesFeedForTopic.votesData.totalVotes;

		const questionsTitles = (questions || []).map(q => q.question);

		votesWithMarkedDeprecatedQuestions = (votesFeedForTopic.votesData.votes || []).map(vote => {
			const markedAnswers = gethMarkedDeprecatedAnswers(vote.qna, questionsTitles);
			const foundDeprecatedQuestion = markedAnswers.find(a => a.deprecated === true);
			
			if (!hasDeprecatedQuestions && !isEmpty(foundDeprecatedQuestion)) {
				hasDeprecatedQuestions = true;
			}
			
			return Object.assign({}, vote, {
				qna: markedAnswers
			});
		});
	}

	const entity = state.entities[0] || {};
	
	return {
		totalVotes,
		votes: votesWithMarkedDeprecatedQuestions,
		questions,
		hasDeprecatedQuestions,
		entity
	};
}

export default connect(mapStateToProps)(TopicDetailsFeedVotes);
