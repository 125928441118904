import DomHelpers from './domHelper';
import { isOnMobile } from './utils';

export function initChartSizeConfig(chartsContainerDom, cardsClassName) {
	const chartsSizesConfig = {
		chartsContainer: null,
		cardsClassName: 'graph-container',
		maxChartWidth: null,
		halfChartWidth: null
	};
	chartsSizesConfig.chartsContainer = chartsContainerDom;
	chartsSizesConfig.cardsClassName = cardsClassName;
	updateChartWidth(chartsSizesConfig);
	return chartsSizesConfig;
}

export function updateChartWidth(chartsSizesConfiguration) {
	const widths = getChartsWidth(chartsSizesConfiguration);
	const isMobile = isOnMobile();                
	chartsSizesConfiguration.halfChartWidth = isMobile ? widths.halfChartWidth : widths.halfChartWidth;
	chartsSizesConfiguration.maxChartWidth = widths.maxChartWidth;
}

function getChartsWidth(chartsSizesConfiguration) {
	if (!chartsSizesConfiguration.chartsContainer) {
		return {
			halfChartWidth: null,
			maxChartWidth: null
		};
	}
	const foundCards = DomHelpers.findElementByClassName(chartsSizesConfiguration.chartsContainer, chartsSizesConfiguration.cardsClassName);
	if (foundCards.length === 0) {
		return {
			halfChartWidth: null,
			maxChartWidth: null
		};
	}
	const maxChartWidth = foundCards.map(e => { return e.clientWidth; }).reduce((max, n) => n > 0 && n > max ? n : max);
	const minChartWidth = foundCards.map(e => { return e.clientWidth; }).reduce((min, n) => n > 0 && n < min ? n : min);
	const firstCardStyle = window.getComputedStyle(foundCards[0]);
	const paddingWidth = parseInt(firstCardStyle.paddingLeft, 10) + parseInt(firstCardStyle.paddingRight, 10);
	return {
		halfChartWidth: minChartWidth - paddingWidth,
		maxChartWidth: maxChartWidth - paddingWidth
	};
}