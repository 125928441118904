import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import Card from '../../components/common/card';
import * as entitiesActions from "../../actions/entitiesActions";
import * as usersActions from '../../actions/usersActions';
import * as topicActions from '../../actions/topicActions';
import { Link } from 'react-router';

import OrderedTopicList from '../../components/admin/orderedTopicList';

const CURRENT_CHANNEL = 'now';

export class AdminPage extends Component {
    constructor(props){
        super(props);

        this.handleLoadUserData = this.handleLoadUserData.bind(this);
        this.loadChannelTopics = this.loadChannelTopics.bind(this);
        this.handleTopicOrderChange = this.handleTopicOrderChange.bind(this);
        this.handleTopicRemove = this.handleTopicRemove.bind(this);
    }

    componentDidMount(){
        this.timer = null;

        if(this.props.entities.length === 0){
            this.props.actions.getAllEntities();
        }
    }

    componentWillUnmount(){
        this.timer = null;
        this.props.actions.resetUserSearchResult();
    }

    loadChannelTopics(e){
        e.preventDefault();
        this.props.actions.loadTopicsForChannel(CURRENT_CHANNEL);
    }

    handleLoadUserData(e){
        e.preventDefault();
        const userEmail = e.target.userEmail.value;
        if(userEmail){
            this.props.actions.getUserDetailsByEmail(userEmail);
        }
    }

    handleTopicOrderChange(topics){
        this.props.actions.reorderTopicsInChannel(CURRENT_CHANNEL, topics);
    }
    
    handleTopicRemove(topic){
        this.props.actions.removeTopicFromChannel(CURRENT_CHANNEL, topic);
    }

    _renderUserData(userData){
        return (
            <div>
                {
                    Object.keys(userData).map((prop, i) => {
                        return (
                            <p className="text-left" key={i}>
                                <span className="text-uppercase">{prop}:</span> {JSON.stringify(userData[prop])}
                            </p>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        return (
            <div className='row-fluid clearfix'>
                 <h2 className="page-heading">
                    Dashboard Administration
                    <div className="btn-toolbar pull-right no-float clearfix">
                        <Link to="/admin/topics" className="btn btn-default btn-outline">Search Topics</Link>
                        <Link to="/admin/pages" className="btn btn-default btn-outline">Manage Pages</Link>
                    </div>
                    
                 </h2>
                
                <div className="col-md-12 col-xs-12">
                    <Card pad title="check user">
                        <form onSubmit={this.handleLoadUserData}>
                            <div className="form-group row">
                                <label className='col-md-12 col-xs-12'>enter user email</label>
                                <div className="col-md-9 col-xs-12">
                                    <input className='form-control' type='email' name="userEmail" />
                                </div>
                                <div className="col-md-3 col-xs-12">
                                    <button className='btn btn-success' type="submit">load user</button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
                <div className="col-md-12 col-xs-12">
                    {this.props.userData && 
                        <Card pad title={`user: ${this.props.userData.firstName} ${this.props.userData.lastName}`}>
                            {this._renderUserData(this.props.userData)}
                        </Card>
                    }
                </div>
                <div className="col-md-12 col-xs-12">
                    <Card pad title="view 'NOW' channel">
                        <button type="button" className='btn btn-info clearfix' onClick={this.loadChannelTopics}>{this.props.channelTopics.length === 0 ? "Load topics" : "Reload topics"}</button>
                        <OrderedTopicList topics={this.props.channelTopics} onOrderChange={this.handleTopicOrderChange} onTopicRemove={this.handleTopicRemove}/>
                    </Card>
                </div>
            </div>
        );
    }
}

AdminPage.propTypes = {
    actions: PropTypes.object,
    entities: PropTypes.array,
    channelTopics: PropTypes.array,
    userData: PropTypes.object,
};

const mapStateToProps = (state) => {
    const nowTopics = state.channelTopics[CURRENT_CHANNEL];
    const userData = state.usersData.searchResult;
    
    return {
        entities: state.entitiesList || [],
        channelTopics: nowTopics || [],
        userData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...entitiesActions, ...usersActions, ...topicActions }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
