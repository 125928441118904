import React, { Component } from 'react';
import {mcChannels} from "../../../helpers/topicBuilderHelper";
import PropTypes from 'prop-types';
import _ from 'lodash'

import * as Icons from 'react-feather';
import AutoCompleteSelectorItem from "../translations/AutoCompleteSelectorItem";

class  McChannelSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedChannels: this.props.selectedChannels,
            options: mcChannels,
            isAddChannelDisabled: false
        };
        this.addNewChannel = this.addNewChannel.bind(this);
        this.removeChannel = this.removeChannel.bind(this);
        this.selectNewChannel = this.selectNewChannel.bind(this);
    }

    componentDidMount() {
        // This is because the server returns channels as an array of strings
        const { selectedChannels } = this.state;
        if (selectedChannels.length && _.every(selectedChannels, _.isObject) === false) {
            this.setState({
                selectedChannels: _.flatMap(selectedChannels, (name, index) => {
                    return { id: index, name: name }
                }),
                isAddChannelDisabled: true
            })
        }
    }

    renderChannelSelectors() {
        return this.state.selectedChannels.map((channel, index) => {
            const selectedChannel = this.state.options.find(ch => ch.id === channel.id);

            return <AutoCompleteSelectorItem
                key={index}
                index={index}
                selection={selectedChannel}
                options={this.state.options}
                onChange={this.selectNewChannel}
                onRemove={this.removeChannel}
                valueField="name"
            />;
        });
    }

    addNewChannel(){
            const selectedChannels = [...this.state.selectedChannels, {}];
            this.setState({ selectedChannels, isAddChannelDisabled: true });
    }

    removeChannel(index) {
        const selectedChannels = [...this.state.selectedChannels];
        selectedChannels.splice(index, 1);

        this.setState({ selectedChannels, isAddChannelDisabled: false });
        this.props.onChange(selectedChannels.map(c => c.name));
    }

    selectNewChannel(index, value) {
        const selectedChannels = [...this.state.selectedChannels];
        const channel = mcChannels.find(l => l.name === value);
        selectedChannels.splice(index, 1, channel);

        this.setState({ selectedChannels });
        
        this.props.onChange(selectedChannels.map(c => c.name));
    }

    render() {
        return (
            <div>
                {this.renderChannelSelectors()}
                {this.state.options.length > 0 && !this.state.isAddChannelDisabled &&
                <button 
                    type="button"
                    className="btn btn-dotted btn-outline btn-success btn-full-width"
                    onClick={this.addNewChannel}
                >
                    <Icons.PlusCircle size={16}/> add in new channel
                </button>
                }
            </div>
        );
    }
}

McChannelSelector.propTypes = {
    selectedChannels: PropTypes.array,
    onChange: PropTypes.func
};

export default McChannelSelector;