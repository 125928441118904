import * as types from '../actions/actionTypes';

// generates a state slice:
const inititalContactSupportState = { 
    isSent: false,
    contactSupportErrors: null,
};

export default function contactReducer(state = inititalContactSupportState, action) {
  switch (action.type) {
    case types.CONTACT_FORM_SUCCESS: 
      return Object.assign({}, state, {
        contactSupportErrors: null,
        isSent: true
      });
    case types.CLEAR_CONTACT_FORM: 
      return inititalContactSupportState;
    case types.SET_CONTACT_FORM_ERRORS:
      return Object.assign({}, state, {
        contactSupportErrors: action.payload.errors,
        isSent: false
      });
    case types.CLEAR_CONTACT_FORM_ERRORS:
      return Object.assign({}, state, {
        contactSupportErrors: null
      });
    default:
      return state;
  }
}