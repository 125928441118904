import React from 'react';
import './css/cookiesMessage.css';
import DismissableMessage from './dismissableMessage';
import { useTranslation } from 'react-i18next';

const CookieMessage = () => {

	const { t } = useTranslation();

	return (
		<div className="cookie-message">
			<DismissableMessage messageKey='cookieMessage' type="info" dismissButtonLabel={t('cookie_message_dismiss')}>
				{t('cookie_message_body')}
			</DismissableMessage>
		</div>	
	);
};

export default CookieMessage;