import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history3/redirect';

import App from './App';
import TopicOverviewPage from './scenes/overview/TopicOverviewPage';
import TopicsPage from './scenes/topics/TopicsPage';
import TopicDetailsPage from './scenes/topics/TopicDetailsPage';
import TopicDetailsGraphs from './scenes/topics/TopicDetailsGraphs';
import TopicDetailsQna from './scenes/topics/TopicDetailsQna';
import TopicDetailsFeed from './scenes/topics/TopicDetailsFeed';
import TopicDetailsFeedVotes from './scenes/topics/topicFeed/TopicDetailsFeedVotes';
import TopicDetailsFeedQuestions from './scenes/topics/topicFeed/TopicDetailsFeedQuestions';
import TopicDetailsFeedVoters from './scenes/topics/topicFeed/TopicDetailsFeedVoters';

import UserLandingPage from './scenes/login/UserLandingPage';
import UserRegisterPage from './scenes/login/UserRegisterPage';
import UserActivatePage from './scenes/login/UserActivatePage';
import getUserEditProfile from './scenes/login/UserEditProfile';
import UserChangePassword from './scenes/login/UserChangePassword';
import ResetPasswordPage from './scenes/login/ResetPasswordPage';
import PaymentSuccessfulPage from './scenes/account/PaymentSuccessfulPage';

import AccountDetailsPage from './scenes/account/AccountDetailsPage';
import SubscriptionInvoicesPage from './scenes/account/SubscriptionInvoicesPage';

import AdminPage from './scenes/admin/AdminPage';

import StaticContentPage from './scenes/admin/StaticContentPage';
import ContentEditPage from './scenes/admin/ContentEditPage';
import GenericNotFoundPage from './scenes/GenericNotFoundPage';

import TopicBuilderPage from './scenes/topicBuilder/TopicBuilderPage';

import EntitiesPage from './scenes/entities/EntitiesPage';
import UsersPage from './scenes/users/UsersPage';
import UserEditPage from './scenes/users/UserEditPage';
import UserDetailsPage from './scenes/users/UserDetailsPage';
import EntityCreatePage from './scenes/entities/EntityCreatePage';
import EntityDetailsPage from './scenes/entities/EntityDetailsPage';
import AdminsTopicPage from './scenes/admin/TopicSearchPage';
import StaticContentPageViewer from './scenes/static/StaticContentPageViewer';
import getPresentationModePage from "./scenes/topics/PresentationModePage";
import PlansPage from "./scenes/PlansPage";

import { PERMISSIONS, userHasPermissions } from './helpers/permissionsHelper';

// Redirects to /login by default
const UserIsAuthenticated = connectedRouterRedirect({
    authenticatedSelector: state => state.auth.isAuthenticated, // how to get the user state
    authenticatingSelector: state => state.auth.isAuthenticating,
    wrapperDisplayName: 'UserIsAuthenticated', // a nice name for this auth check
    redirectPath: '/login',
});

const UserIsNotAuthenticated = connectedRouterRedirect({
    authenticatedSelector: state => !state.auth.isAuthenticated,
    redirectPath: (state, ownProps) => ownProps.location.query.redirect || '/',
    wrapperDisplayName: 'UserIsNotAuthenticated',
    allowRedirectBack: false
});

const UserIsAdmin = connectedRouterRedirect({
    authenticatedSelector: state => state.auth.user && state.auth.user.permissions && userHasPermissions([PERMISSIONS.ADMIN], state.auth.user),
    redirectPath: (state, ownProps) => ownProps.location.query.redirect || '/',
    wrapperDisplayName: 'UserIsAdmin',
    allowRedirectBack: false
});

const UserIsEntityEditor = connectedRouterRedirect({
    authenticatedSelector: state => state.auth.user && state.auth.user.permissions && userHasPermissions([PERMISSIONS.EDIT_ENTITIES], state.auth.user),
    redirectPath: (state, ownProps) => ownProps.location.query.redirect || '/',
    wrapperDisplayName: 'UserIsEntityEditor',
    allowRedirectBack: false
});

const UserIsTopicEditor = connectedRouterRedirect({
    authenticatedSelector: state => state.auth.user && state.auth.user.permissions && userHasPermissions([PERMISSIONS.EDIT_TOPICS], state.auth.user),
    redirectPath: (state, ownProps) => ownProps.location.query.redirect || '/topics',
    wrapperDisplayName: 'UserIsTopicEditor',
    allowRedirectBack: false
});


// dummy component used to protect multiple routes
// I don't knwow why, but using a HOC auth component with nested graphs/voyager routes, we have duplicated Topic Details component..
const Authenticated = UserIsAuthenticated((props) => React.cloneElement(props.children, props));

export default (
    <Route path="/" component={App} name="app">
        <Route component={Authenticated}>
            <IndexRoute component={TopicOverviewPage} />
            <Route path="/topics" exact component={TopicsPage} />
            <Route path="/topics/edit/:id" component={UserIsTopicEditor(TopicBuilderPage({ edit: true }))} />
            <Route path="/topics/create" component={UserIsTopicEditor(TopicBuilderPage({ create: true }))} />
            <Route path="/topics/createfromtemplate/:id" component={UserIsTopicEditor(TopicBuilderPage({ template: true }))} />
            <Route path="/admin" component={UserIsAdmin(AdminPage)} />
            <Route path="/profile" component={getUserEditProfile()} />
            <Route path="/changepassword" component={UserChangePassword} />
            <Route path="/topics/pmode/:id" component={getPresentationModePage()} />
            
            <Route path="/account/payments/successful" component={UserIsAuthenticated(PaymentSuccessfulPage)} />
            <Route path="/plans" component={UserIsAuthenticated(UserIsEntityEditor(PlansPage))} />
        </Route>

        <Route path="/admin/pages" component={UserIsAuthenticated(UserIsAdmin(StaticContentPage()))}>
            <Route path="create" component={UserIsAuthenticated(UserIsAdmin(ContentEditPage({ create: true })))} />
            <Route path="edit/:fileName" component={UserIsAuthenticated(UserIsAdmin(ContentEditPage({ edit: true })))} />
        </Route>

        <Route path="/admin/topics" component={UserIsAuthenticated(UserIsAdmin(AdminsTopicPage))} />

        <Route path="/login" component={UserIsNotAuthenticated(UserLandingPage)} />
        <Route path="/register" component={UserIsNotAuthenticated(UserRegisterPage)} />
        <Route path="/activate" component={UserActivatePage} />
        <Route path="/reset" component={UserIsNotAuthenticated(ResetPasswordPage)} />
        

        <Route path="/topics/details/:id" component={UserIsAuthenticated(TopicDetailsPage)}>
            <Route path="graphs" component={TopicDetailsGraphs} />
            <Route path="qna" component={TopicDetailsQna} />
            <Route path="feed" component={TopicDetailsFeed}>
                <Route path="votes" component={TopicDetailsFeedVotes} />
                <Route path="questions" component={TopicDetailsFeedQuestions} />
                <Route path="voters" component={TopicDetailsFeedVoters} />
            </Route>
        </Route>

        <Route path="/users" component={UserIsAuthenticated(UserIsAdmin(UsersPage))}>
            <Route path="create" component={UserEditPage({ create: true })} />
            <Route path=":id" component={UserDetailsPage} />
        </Route>

        <Route path="/entities" component={UserIsAuthenticated(UserIsAdmin(EntitiesPage))}>
            <Route path="create" component={UserIsAuthenticated(UserIsAdmin(EntityCreatePage))} />
            <Route path=":id" component={UserIsAuthenticated(UserIsEntityEditor(EntityDetailsPage))} />
        </Route>

        <Route path="/account" component={UserIsAuthenticated(AccountDetailsPage)}>
            <Route path="details/:id" component={EntityDetailsPage}>
                <Route path="invoices" component={SubscriptionInvoicesPage} /> 
            </Route>
        </Route> 

        {/* static pages */}
        <Route path="/static/:filename" component={StaticContentPageViewer} />

        <Route path='*' exact={true} component={GenericNotFoundPage} />
    </Route>
);