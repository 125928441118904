import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { isEqual } from 'lodash';

import './css/autocomplete.css';
import i18n from '../../../i18n';

const DEFAULT_VALUE_FIELD = "id";
const DEFAULT_LABEL_FIELD = "name";
const DEFAULT_PLACEHOLDER_TEXT = "label_autocomplete_select";

class AutoComplete extends Component {
    constructor(props) {
        super(props);

        const foundSelection = this.getSelectedOption();
        
		this.state = {
            options: props.data,
            autocompleteOpen: false,
            initialDataChanged: false,
            selectionName: foundSelection ? foundSelection[props.labelField] : '',
            t: i18n.t.bind(i18n)
		};	        

        this.handleClick = this.handleClick.bind(this);

        this.inputProps = {
            placeholder: this.state.t(props.placeholder),
            value: '',
            onChange: () => {},
            autoFocus: true
        };
    }

	getSelectedOption(){
		return this.props.data.find(option => option[this.props.valueField].toString() === (this.props.value || '').toString());
	}
	
	removeSelectedOption(options){
		const selectedOption = this.getSelectedOption();
		return options.filter(option => option !== selectedOption);
	}

    componentDidUpdate(prevProps, prevState) {
        const initialDataChanged = this.props.value && this.props.value.toString().length > 0 && !isEqual(this.props.value, prevProps.value);
        const selection = this.getSelectedOption();
        const newSelectionName = selection ? selection[this.props.labelField] : '';
        if (initialDataChanged || newSelectionName !== prevState.selectionName) {
            this.setState({selectionName: newSelectionName});
        }
    }

    componentDidMount() {
        document.body.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.handleClick);
    }

    handleClick(ev) {
        const shouldOpenAutocomplete = this.autocompleteDom.contains(ev.target);

        if (shouldOpenAutocomplete) {
            const searchFields = this.autocompleteDom.getElementsByTagName('input');
            if (searchFields.length > 0) {
                searchFields[0].focus();
            }
        }

        this.inputProps.value = '';
        this.setState({ autocompleteOpen: shouldOpenAutocomplete });
    }

    getOptions(option) {
        const currValue = option.value || '';
        const inputValue = currValue.trim().toLowerCase();

        const foundOptions = this.props.data.filter(d => {
            return d[this.props.labelField].toLowerCase().indexOf(inputValue) > -1;
        });

		const removedSelectedOption = this.removeSelectedOption(foundOptions);
        return removedSelectedOption;
    }

    getOptionsValue(option) {
        return option[this.props.labelField];
    }

    renderSuggestion(option) {
        return <div>{option[this.props.labelField]}</div>;
    }

    onSelected(event, selectionProps) {
        this.setState({
            selectionName: selectionProps.suggestion[this.props.labelField],
            value: '',
            options: this.props.data,
            autocompleteOpen: false
        });

        this.props.onChange(selectionProps.suggestion[this.props.valueField]);
    }

    onSuggestionsFetchRequested(value) {
        this.inputProps.value = value.value;
        this.setState({ options: this.getOptions(value) });
    }

    onSuggestionsClearRequested() {
        this.setState({ options: [] });
    }

    get theme() {
        return {
            input: this.props.className,
            suggestionsContainer: 'autocomplete-dropdown',
            suggestionsContainerOpen: 'autocomplete-dropdown-open',
            suggestionsList: 'autocomplete-options',
            suggestion: 'autocomplete-option',
            suggestionHighlighted: 'autocomplete-option-highlighted',
            container: 'autocomplete-container'
        };
    }

    render() {
        if (this.state.autocompleteOpen && this.autocompleteDom) {
            const dropDown = this.autocompleteDom.getElementsByClassName('autocomplete-container')[0];

            if (dropDown) {
                dropDown.style.width = `${this.autocompleteDom.clientWidth}px`;
            }
        }

        const t = this.state.t;

        return (
            <div
                className={`autocomplete ${this.props.className} ${this.props.disabled ? 'disabled' : ''}`}
                ref={autocomplete => (this.autocompleteDom = autocomplete)}
            >
                <label className={`selection ${this.state.selectionName ? '' : 'empty'}`}>
                    {this.state.selectionName ? this.state.selectionName : t(this.props.placeholder)}
                </label>

                {!this.props.disabled && <span className="glyphicon glyphicon-chevron-down" />}

                {this.state.autocompleteOpen &&
                    !this.props.disabled && (
                        <Autosuggest
                            suggestions={this.state.options}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
                            onSuggestionSelected={this.onSelected.bind(this)}
                            getSuggestionValue={this.getOptionsValue.bind(this)}
                            renderSuggestion={this.renderSuggestion.bind(this)}
                            inputProps={this.inputProps}
                            alwaysRenderSuggestions={true}
                            theme={this.theme}
                        />
                    )}
            </div>
        );
    }
}

AutoComplete.propTypes = {
    /** @prop {string} placeholder the placeholder text for the autocomplete selection label */
    placeholder: PropTypes.string,

    /**
     * @prop {Array[Object]} data the data feed to the autocomplete dropdown.
     * Expected array of objects with default keys: id and name.
     * Key names can be configured to be named otherwise by valueField and labelField
     * */
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,

    /** @prop {string} labelField the label field from the data object. Defaults to "name" */
    labelField: PropTypes.string,

    /** @prop {string} valueField the value field from the data object. Defaults to "id" */
    valueField: PropTypes.string,

    /**
     * @prop {func} onChange the onChange handler to be invoked on autocomplete option selection
     * @argument {number} value the selection value, default: "id"
     * */
    onChange: PropTypes.func.isRequired,

    className: PropTypes.string,

    /** @prop {string} value the initial value to be selected from the data prop in the autocomplete */
    value: PropTypes.any,

    /** @prop {bool} disables the selector */
    disabled: PropTypes.bool
};

AutoComplete.defaultProps = {
    valueField: DEFAULT_VALUE_FIELD,
    labelField: DEFAULT_LABEL_FIELD,
    placeholder: DEFAULT_PLACEHOLDER_TEXT
};

export default AutoComplete;
