import * as actionTypes from '../actions/actionTypes';

const initialState = {};

export function qnaReducer(state = initialState, action) {
	switch (action.type) {
        case actionTypes.LOAD_QUESTIONSTATS: {
			return Object.assign({}, state, {
				[action.payload.topicId]: Object.assign({}, state[action.payload.topicId], {
					isLoaded: false,
					isLoading: true
				})
			});
		}
		case actionTypes.LOAD_QUESTIONSTATS_SUCCESS: {
			const currentQna = state[action.payload.topicId];
			const newQna = Object.assign({}, currentQna, {
				isLoaded: true,
				isLoading: false,
				questionDetails: [...action.payload.questionStats]
			});
			return {
				...state,
				[action.payload.topicId]: newQna
			};
		}
		case actionTypes.LOAD_QUESTION_DETAILS_SUCCESS: {
		    const { topicId, question, answers } = action.payload;
			const currentQnaSlice = state[topicId];
			const currentQuestions = currentQnaSlice.questionDetails;
			let newQuestions = currentQuestions;
			const questionToChange = currentQuestions.find(questionItem => questionItem.question === question);
			if (questionToChange) {
                const modifiedQuestion = Object.assign({}, questionToChange, { answers });
                newQuestions = [
                    ...currentQuestions.filter(questionItem => questionItem.question !== question),
                    modifiedQuestion
                ];
            }
			return {
				...state,
				[topicId]: Object.assign({}, currentQnaSlice, { questionDetails: newQuestions })
			};
		}
        case actionTypes.LOAD_BULK_QUESTIONS_DETAILS_SUCCESS : {
            const { topicId, questionsWithAnswers } = action.payload;
            const currentQnaSlice = state[topicId];
            const currentQuestions = currentQnaSlice.questionDetails;
            let newQuestions = currentQuestions;
            questionsWithAnswers.forEach(questionWithAnswers => {
                const { question, answers } = questionWithAnswers;
                const questionToChange = currentQuestions.find(questionItem => questionItem.question === question);
                if (questionToChange) {
                    const modifiedQuestion = Object.assign({}, questionToChange, { answers });
                    newQuestions = [
                        ...newQuestions.filter(questionItem => questionItem.question !== question),
                        modifiedQuestion
                    ];
                }
            });
            return {
                ...state,
                [topicId]: Object.assign({}, currentQnaSlice, { questionDetails: newQuestions })
            };
		}
		default:
			return state;
	}
}