import React from 'react';
import PropTypes from 'prop-types';
import { Link, IndexLink } from 'react-router';
import * as Icon from 'react-feather';
import './nav-mobile.css';
import PermissionsContext from './contexts/permissionsContext';
import { PERMISSIONS } from '../helpers/permissionsHelper';
import { USER_CHANGE_PASSWORD_MODAL, USER_PROFILE_EDIT_MODAL } from '../helpers/modalTypes';

const NavMobile = props => {
	let menuToggle = null;
	
	const showChangePasswordModal = () => {
		const config = { onClose: props.hideModal, width: 640 };
		props.showModal(USER_CHANGE_PASSWORD_MODAL, config);
	};
	const showEditProfileModal = () => {
		const config = { onClose: props.hideModal, onRedirectToChangePassword: showChangePasswordModal, width: 840 };
		props.showModal(USER_PROFILE_EDIT_MODAL, config);
	};
	const toggleMobileMenu = function(){
		if (menuToggle !== null) {
			const mobileMenuOpen = menuToggle.getElementsByTagName('input')[0].checked;
			menuToggle.getElementsByTagName('input')[0].checked = !mobileMenuOpen;
		}

		props.onOpenMobileMenu();
	};

	return (
		<div className="mobile-navigation menu-mobile">
			<div id="menuToggle" onClick={props.onOpenMobileMenu} ref={(menuToggleDom) => menuToggle = menuToggleDom}>
				<input type="checkbox" />
				
				<span className="hamburger"></span>
				<span className="hamburger"></span>
				<span className="hamburger"></span>
			</div>

			<ul id="menu" className="nav navbar-nav">
				<li>
					<IndexLink to="/" activeClassName="active" onClick={toggleMobileMenu}>
						<Icon.Grid />
						<span>OVERVIEW</span>
					</IndexLink>
				</li>
				<li>
					<Link to="/topics" activeClassName="active" onClick={toggleMobileMenu}>
						<Icon.List />
						<span>topics</span>
					</Link>
				</li>

				<li>
					<PermissionsContext hideIfNoPermission={true} requiredPermissions={[PERMISSIONS.EDIT_ENTITIES]}>
						<Link to={`/account/details/${props.entity.id}`} activeClassName="active" onClick={ toggleMobileMenu }>
							<Icon.Star size={18}/><span>My account</span>
						</Link>
					</PermissionsContext>
				</li>

				<li>
					<button type='button' className="menu-mobile btn btn-transparent" onClick={showEditProfileModal}>
						<Icon.User />
						<span>PROFILE</span>
					</button>
				</li>
				<li>
					<button type='button' className='menu-mobile btn btn-transparent' onClick={showChangePasswordModal}>
						<Icon.Key />
						<span>PASSWORD</span>
					</button>
				</li>

				<PermissionsContext hideIfNoPermission={true} requiredPermissions={ [PERMISSIONS.ADMIN] }>
					<li>
						<Link to="/admin" className="active" onClick={ toggleMobileMenu }>
							<Icon.User/>
							<span>ADMIN</span>
						</Link>
					</li>
					<li>
						<Link to="/topics/create" className="active" onClick={ toggleMobileMenu }>
							<Icon.Plus/>
							<span>CREATE TOPIC</span>
						</Link>
					</li>
					<li>
						<Link to="/entities" className="active" onClick={ toggleMobileMenu }>
							<Icon.Box/>
							<span>ORGANIZATIONS</span>
						</Link>
					</li>
					<li>
						<Link to="/users" className="active" onClick={ toggleMobileMenu }>
							<Icon.Users/>
							<span>USERS</span>
						</Link>
					</li>
				</PermissionsContext>

				<li>
					<Link to="/" onClick={(e) => {props.onSupportClick(e);  props.onOpenMobileMenu(e);}}>
						<Icon.MessageSquare size={18} /><span>support</span>
					</Link>
				</li>

				<li>
					<Link to="/" onClick={(e) => {props.onLogoutClick(e);  props.onOpenMobileMenu(e);}}>
						<Icon.LogOut size={18} /><span>logout</span>
					</Link>
				</li>
			</ul>
		</div>
	);
};

NavMobile.propTypes = {
	/**
	 * @param {func} onLogoutClick outer function handler for logging out
	 */
	onLogoutClick: PropTypes.func.isRequired,

	/**
	 * @param {object} auth the auth object containgin all session details, if the user has admin permissions - an additional menu item is displayed. 
	 */
	auth: PropTypes.object.isRequired,
	entity: PropTypes.shape({
		id: PropTypes.number.isRequired
	}),

	/**
	 * @param {func} onOpenMobileMenu outer function handler for opening the mobile menu
	 */
	onOpenMobileMenu: PropTypes.func.isRequired,

	onSupportClick: PropTypes.func.isRequired,
	showModal: PropTypes.func.isRequired,
	hideModal: PropTypes.func.isRequired
};

NavMobile.defaultProps = {
	showModal: () => {},
	hideModal: () => {}
};

export default NavMobile;