// browser check and init
var userAgent = window.navigator.userAgent.toLowerCase();
// eslint-disable-next-line no-console
console.log(userAgent);
var unsupportedBrowser = /msie|rv:11|trident/.test(userAgent) || /opera-mini/.test(userAgent);
var unsupportedEs6Feature = false;
if(!unsupportedBrowser){
  try {
    new Promise(() => {});
    Object.assign({}, {test: "test"});
  } catch (error) {
    unsupportedEs6Feature = true;
  }
}

if (unsupportedBrowser || unsupportedEs6Feature) {
  var message = "You are using a browser that is not supported. Make sure you are not using Internet Explorer(IE), Opera mini, or browser version older than 2 years";
  var details = '<a href="https://browserl.ist/?q=%3E0.2%25%2Cnot+dead%2Cnot+ie+%3C%3D+11%2Cnot+op_mini+all" target="_blank" rel="noopener noreferrer">Supported browsers</a>';
  var element = document.getElementById('root');
  element.innerHTML= `<div class='container'><h2>${message}</h2><p>${details}</p></div>`;
} else {
  require('./initApp').startup();
}