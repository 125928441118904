import { isEmpty } from 'lodash';

import { ADD_NOTIFICATION, REQUEST_STATUS_ERROR, REQUEST_STATUS_PENDING, REQUEST_STATUS_SUCCESS } from './actionTypes';
import { notificationDismissInterval } from '../helpers/notification';
import logger from '../helpers/logger';

const getDismissInterval = (level, dismissInterval) => {
	return isNaN(dismissInterval) ?
		notificationDismissInterval[level] :
		dismissInterval;
};

// sends notification action to the notification component 
// Level of the notification: success, error, warning, info
// dismissInterval: measured in seconds; timeout for notification to be on screen
export function addNotification(message, level, dismissInterval) {
	return {
		type: ADD_NOTIFICATION,
		message,
		level,
		autoDismiss: getDismissInterval(level, dismissInterval)
	};
}

export function requestStatusPending(endpointName) {
	return getAction(REQUEST_STATUS_PENDING, endpointName);
}

export function requestStatusSuccess(endpointName) {
	return getAction(REQUEST_STATUS_SUCCESS, endpointName);
}

export function requestStatusError(endpointName) {
	return getAction(REQUEST_STATUS_ERROR, endpointName);
}

function getAction(type, endpointName) {
	if (isEmpty(endpointName)) {
		logger.error(`request endpoint name must not be empty string. Request type: ${type}`);
	} else {
		return ({ type, payload: { endpointName }});
	}
}