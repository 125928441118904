import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import './css/dismissableMessage.css';

const dismissableMessage = ({ children, messageKey, type, dismissButtonLabel }) => {
	const getStorageKeyName = function() {
		return `${messageKey}Dismissed`;
	};

	const hasBeenDismissed = function() {
		const keyName = getStorageKeyName();

		const storageItem = localStorage.getItem(keyName);

		return !isEmpty(storageItem);
	};

	let dismissed = hasBeenDismissed();

	const handleDismissClick = function(e) {
		const keyName = getStorageKeyName();
		localStorage.setItem(keyName, true);

		const messageDom = e.target.parentNode;
		messageDom.classList.add('hide');
	};

	return !dismissed ? (
		<p className={`message bg-${type} text-${type} dismissable-message`}>
			<span>{ children }</span>
			<button className="btn btn-transparent" onClick={handleDismissClick}>{ dismissButtonLabel }</button>
		</p>
	): (<span />);
};

dismissableMessage.propTypes = {
	/** @prop {string} messageKey The key name associated with the dismissable message, 
	 * which will store it's dismissed state in localStorage. Required. */
	messageKey: PropTypes.string.isRequired,

	/** @prop {string} type the type of the message background. available "warning|error|success|info|transparent".
	 * Default: "info"
	 */
	type: PropTypes.string,

	dismissButtonLabel: PropTypes.string
};

dismissableMessage.defaultProps = {
	type: "info",
	dismissButtonLabel: "dismiss"
};

export default dismissableMessage;