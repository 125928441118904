import * as actionTypes from '../actions/actionTypes';
import { isEmpty, cloneDeep } from 'lodash';

const initialState = {
	
};

export function feedReducer(state = initialState, action) {
	switch(action.type) {
		case actionTypes.LOAD_FILTERED_VOTES_WITH_QNA_SUCCESS: {
			if (!isEmpty(action.payload)) {
				const { totalVotes, votes } = action.payload;

				let currentFeedVotesData = {};
				let currentVotes = [];

				if (state[action.payload.topicId]) {
					currentFeedVotesData = state[action.payload.topicId].votesData;

					if (currentFeedVotesData && currentFeedVotesData.votes) {
						currentVotes = currentFeedVotesData.votes;
					}
				}

				const newFeedVotesByTopicId = Object.assign({}, currentFeedVotesData, {
					votes: [...currentVotes, ...votes],
					totalVotes
				});

				return Object.assign({}, state, {
					[action.payload.topicId]: Object.assign({}, state[action.payload.topicId], {
						votesData: newFeedVotesByTopicId
					})
				});
			}
			
			return state;
		}

		case actionTypes.RESET_FILTERED_VOTES_WITH_QNA: {
			return Object.assign({}, state, {
				[action.payload.topicId]: Object.assign({}, state[action.payload.topicId], {
					votesData: {}
				})
			});
		}

		case actionTypes.LOAD_FILTERED_VOTERS_SUCCESS: {
			if (!isEmpty(action.payload)) {
				const { totalVoters, voters } = action.payload;
				
				let currentFeedVotersData = {};
				let currentVoters = [];

				if (state[action.payload.topicId]) {
					currentFeedVotersData = state[action.payload.topicId].votersData;

					if (currentFeedVotersData && currentFeedVotersData.voters) {
						currentVoters = currentFeedVotersData.voters;
					}
				}

				const newFeedVotesByTopicId = Object.assign({}, currentFeedVotersData, {
					voters: [...currentVoters, ...voters],
					totalVoters
				});

				return Object.assign({}, state, {
					[action.payload.topicId]: Object.assign({}, state[action.payload.topicId], {
						votersData: newFeedVotesByTopicId
					})
				});
			}

			return state;
		}

		case actionTypes.LOAD_VOTES_FOR_VOTER_SUCCESS: {
			if (!isEmpty(action.payload)) {
				if (state[action.payload.topicId] && state[action.payload.topicId].votersData) {
					const selectedVoter = state[action.payload.topicId].votersData.voters.find(voter => voter.voterId === action.payload.voterId);

					if (selectedVoter) {
						const updatedVoters = state[action.payload.topicId].votersData.voters.map(voter => {
							if (voter.voterId === action.payload.voterId) {
								return Object.assign({}, selectedVoter, {
									votes: action.payload.votes,
									totalVotes: action.payload.totalVotes
								});
							} else {
								return voter;
							}
						});

						const updatedVotersData = Object.assign({}, state[action.payload.topicId].votersData, {
							voters: updatedVoters
						});
						const updatedVotersDataState = Object.assign({}, state[action.payload.topicId], {
							votersData: updatedVotersData
						});
						const updatedState = Object.assign({}, state, {
							[action.payload.topicId]: updatedVotersDataState
						});

						return updatedState;
					}
				}				
			}	
			
			return state;
		}

		case actionTypes.RESET_FILTERED_VOTERS: {
			return Object.assign({}, state, {
				[action.payload.topicId]: Object.assign({}, state[action.payload.topicId], {
					votersData: {}
				})
			});
		}

		case actionTypes.RESET_VOTES_FOR_VOTER: {
			const updatedVoters = state[action.payload.topicId].votersData.voters.map(voter => {
				if (voter.voterId === action.payload.voterId) {
					return Object.assign({}, voter, { votes: [] });
				} else {
					return voter;
				}
			});

			return Object.assign({}, state, {
				[action.payload.topicId]: Object.assign({}, state[action.payload.topicId], {
					votersData: Object.assign({}, state[action.payload.topicId].votesData, {
						voters: updatedVoters
					})
				})
			});
		}

		case actionTypes.LOAD_QUESTIONS_AND_ANSWERS: {
			let currentQuestionsData = {};
			if (state[action.payload.topicId]) {
				currentQuestionsData = state[action.payload.topicId].questionsData;
			}

			return Object.assign({}, state, {
				[action.payload.topicId]: Object.assign({}, state[action.payload.topicId], {
					questionsData: Object.assign({}, currentQuestionsData, {
						isLoading: true,
						isLoaded: false
					})
				})
			});
		}

		case actionTypes.LOAD_QUESTIONS_AND_ANSWERS_SUCCESS: {
			if (!isEmpty(action.payload)) {
				const { questionsAndAnswers } = action.payload;
				let currentFeedByTopicId = state[action.payload.topicId];

				let currentFeedQuestions = {};
				if (currentFeedByTopicId && currentFeedByTopicId.questionsData) {
					currentFeedQuestions = currentFeedByTopicId.questionsData.questions;
				} else {
					currentFeedByTopicId = {
						[action.payload.topicId]: {
							questionsData: {}
						}
					};
				}

				const newFeedQuestions = Object.assign({}, cloneDeep(currentFeedQuestions), questionsAndAnswers);
				for (const question in newFeedQuestions) {
					if (newFeedQuestions.hasOwnProperty(question)) {
						const questionData = newFeedQuestions[question];
						const newAnswers = questionsAndAnswers[question] ? questionsAndAnswers[question].answers : [];
						questionData.answers = newAnswers;
					}
				}
								
				return Object.assign({}, state, {
					[action.payload.topicId]: Object.assign({}, currentFeedByTopicId, {
						questionsData: {
							isLoaded: true,
							isLoading: false,
							questions: newFeedQuestions
						}
					})
				});
			}
			return state;
		}

		case actionTypes.LOAD_SINGLE_QUESTION_ANSWERS: {
			const { question } = action.payload;
			let currentQuestionsData = {};
			if (state[action.payload.topicId]) {
				currentQuestionsData = state[action.payload.topicId].questionsData;

				if (currentQuestionsData.questions && currentQuestionsData.questions[action.payload.question]) {
					const currentFeedQuestions = currentQuestionsData.questions;

					const updatedQuestions = Object.assign({}, currentFeedQuestions, {
						[question]: Object.assign({}, currentFeedQuestions[question], {
							isLoading: true,
							isLoaded: false
						})
					});

					return Object.assign({}, state, {
						[action.payload.topicId]: Object.assign({}, state[action.payload.topicId], {
							questionsData: Object.assign({}, currentQuestionsData, {
								isLoaded: true,
								isLoading: false,
								questions: updatedQuestions
							})
						})
					});
				}
			}

			return state;
		}

		case actionTypes.LOAD_SINGLE_QUESTION_ANSWERS_SUCCESS: {
			if (!isEmpty(action.payload)) {
				const { answers, answerCount } = action.payload;

				const currentFeedByTopicId = state[action.payload.topicId];

				let currentFeedQuestions = {};

				if (currentFeedByTopicId && currentFeedByTopicId.questionsData) {
					currentFeedQuestions = currentFeedByTopicId.questionsData.questions;
				}

				const updatedQuestions = Object.assign({}, currentFeedQuestions, {
					[action.payload.question]: Object.assign({}, currentFeedQuestions[action.payload.question], {
						isLoading: false,
						isLoaded: true,
                        answers: [...currentFeedQuestions[action.payload.question].answers, ...answers],
                        answerCount
					})
				});

				return Object.assign({}, state, {
					[action.payload.topicId]: Object.assign({}, state[action.payload.topicId], {
						questionsData: Object.assign({}, currentFeedByTopicId.questionsData, {
							isLoaded: true,
							isLoading: false,
							questions: updatedQuestions
						})
					})
				});
			}

			return state;
		}

		default: {
			return state;
		}
	}
}