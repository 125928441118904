import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../common/modal';
import Messages from '../../helpers/messages';

const confirmModal = props => {
    const message = props.message ? props.message : Messages.common.defaultConfirmMessage;
    const hasFooter = props.hasFooter !== undefined ? props.hasFooter : true;
    const hideConfirmButton = props.hideConfirmButton;
	const closeLabel = props.closeButtonLabel;
	return (
		<Modal 
			title={props.title}
			className="confirm-modal"
			onClose={props.onClose}
			hasOkButton={!hideConfirmButton}
			onOk={props.onConfirm}
            isConfirmModal={true}
            hasFooter={hasFooter}
			btnCancelLabel={closeLabel}
		>
			<div>{message}</div>
		</Modal>
	);
};

confirmModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	hideConfirmButton: PropTypes.bool,
	onConfirm: PropTypes.func.isRequired,
	message: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element // in case of jsx
	]),
	title: PropTypes.string,
	closeButtonLabel: PropTypes.string,
	hasFooter: PropTypes.bool
};

export default confirmModal; 