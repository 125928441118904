import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { isEmpty } from 'lodash';

import * as entitiesActions from "../../actions/entitiesActions";
import * as modalActions from "../../actions/modalActions";
import * as userActions from "../../actions/usersActions";
import * as topicBuilderActions from "../../actions/topicBuilderActions";
import { isOnMobile } from "../../helpers/utils";

import './css/entityCreatePage.css';
import EntityDetailsForm from '../../components/entities/entityDetailsForm';
import { mapToEntityFormModel, validate } from '../../actions/models/entityModel';
import { PERMISSIONS, userHasPermissions } from '../../helpers/permissionsHelper';
import SidePanel from '../../components/common/sidePanel';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import { Formik, Form } from 'formik';
import ServerErrorMessage from '../../components/common/serverErrorMessage';

export class EntityCreatePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCancelModalShown: false
        };

        this.isDirty = false;
        this.isValid = false;

        this.formSubmitHandler = this.formSubmitHandler.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleClosePanelClick = this.handleClosePanelClick.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
        this.validateTopicName = this.validateTopicName.bind(this);
        this._onRouteChange();
    }

    componentDidUpdate(props) {
        if (props.entityForEdit.isSaved) {
            this.props.actions.getAllEntities();
            browserHistory.push('/entities');
        }
    }

    componentDidMount(){
        this.props.actions.resetEntityForEdit();
    }

    componentWillUnmount(){
        this.closeModal();
        this.props.actions.resetEntityForEdit();
        window.onbeforeunload = () => {};
    }

    get requestErrors(){
        const urlQuery = this.props.location.query;
        const hasErrFlag = urlQuery && urlQuery.rootErr;

        const errors = this.props.entityForEdit.errors || {};
        if(!errors['rootTopicName'] && hasErrFlag){
            return Object.assign({}, errors, {rootTopicName: "Failed to set root topic. Possible duplicate entry."});
        }

        return errors;
    }

    get isSubmitDisabled() {
        return this.isDirty ? 
            (!this.isValid || 
            (!isEmpty(this.props.entityForEdit) && (this.props.entityForEdit.isAsyncValidating || !isEmpty(this.props.entityForEdit.errors) ) )) 
        : 
            true;
    }

    get isMobileView() {
        return isOnMobile();
    }

    handleClosePanelClick() {
        const leaveRoute = '/entities';
        const { showModal, hideModal } = this.props.actions;

        if(!this.isDirty) {
            return browserHistory.push(leaveRoute);
        }

        const configShowModal = {
            title: "Cancel Changes",
            message: "Are you sure you want to cancel saving your changes?",
            onClose: () => {
                hideModal();
                this.setState({isCancelModalShown: false});
            },
            onConfirm: () => {
                hideModal();
                this.setState({isCancelModalShown: false});
                return browserHistory.push(leaveRoute);
            }
        };
        this.setState({isCancelModalShown: true});
        showModal(CONFIRM_MODAL, configShowModal);
    };
    
    _onRouteChange(leaveMessage = "You have unsaved changes. Are you sure you want to leave the page?") {
        // Refreshing / closing browser
        window.onbeforeunload = () => {
            if(this.isDirty) {
                return window.confirm(leaveMessage);
            }
        };

        // Navigating out from current route
        this.props.router.setRouteLeaveHook(
            this.props.router.routes[2],
            () => {
                if(!this.state.isCancelModalShown && this.isDirty && !this.props.entityForEdit.isSaved) {
                    return window.confirm(leaveMessage);
                }
            }
        );
    }

    formSubmitHandler(formEntityModel){
        this.props.actions.createEntity(formEntityModel);
    }

    closeModal() {
        this.props.actions.hideModal();
    }

    fieldHasErrors(errors, field) {
        if (this.requestErrors[field] && this.requestErrors[field].length > 0) {
            return true;
        }
        return errors[field] && errors[field].length > 0;
    }

    handleValidate (values) {
		return validate(values);
    };
    
    validateTopicName(value) {
        this.props.actions.validateTopicNameDuplicate(value);
    }

    render() {
        const { initialEntityModel } = this.props;
        
        return (
            <div className="entity-create-form row-fluid clearfix">
                <Formik
                    initialValues={initialEntityModel}
                    onSubmit={this.formSubmitHandler}
                    enableReinitialize={true}
                    validate={this.handleValidate}
                >
                    {({ 
                        setFieldValue, 
                        setFieldTouched, 
                        setFieldError, 
                        values, 
                        errors, 
                        dirty, 
                        isValid,
                        handleBlur
                    }) => {
                        this.isDirty = dirty;
                        this.isValid = isValid;

                        return <Form autoComplete="noautocompletepls" className='entity-details-form'>
                            {this.fieldHasErrors(this.requestErrors, 'version') && <ServerErrorMessage errors={this.requestErrors} name="version" className="control-label" />}
                            <SidePanel 
                                className="entity-create" 
                                title="create organization"
                                onClose={this.handleClosePanelClick}
                                onOk={this.formSubmitHandler}
                                okButtonType="submit"
                                okButtonDisabled={this.isSubmitDisabled}
                                showButtons={true}
                                okButtonText="Submit"
                                cancelButtonText="Cancel"
                            >
                                <EntityDetailsForm 
                                    values={values}
                                    errors={errors} 
                                    editMode={false}
                                    isUserAdmin={ userHasPermissions([ PERMISSIONS.ADMIN ], this.props.user ) } 
                                    setFieldValue={setFieldValue}
                                    setFieldError={setFieldError}
									setFieldTouched={setFieldTouched}
                                    requestErrors={this.requestErrors}
                                    handleBlur={handleBlur}
                                    validateTopicName={this.validateTopicName}
                                />
                            </SidePanel>
                        </Form>;                
                    }}
                </Formik>
            </div>
        );
    }
}

EntityCreatePage.propTypes = {
    params: PropTypes.object,
    location: PropTypes.shape({
        query: PropTypes.shape({
            path: PropTypes.string
        })
    }),
    actions: PropTypes.shape({
        resetEntityForEdit: PropTypes.func.isRequired,
        createEntity: PropTypes.func.isRequired,
        showModal: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        getAllEntities: PropTypes.func.isRequired,
        validateTopicNameDuplicate: PropTypes.func.isRequired      
    }).isRequired,
    entityForEdit: PropTypes.shape({
        errors: PropTypes.object,
        isSaved: PropTypes.bool,
        isAsyncValidating: PropTypes.bool
    }),

    /** @prop {object} initialEntityModel 
     * initial empty entity model, provided for the 
     * create entity form component 
     */
    initialEntityModel: PropTypes.shape({
        address: PropTypes.string,
        alertRecepients: PropTypes.string,
        city: PropTypes.string,
        countryCode: PropTypes.string,
        defaultTopicLanguage: PropTypes.string,
        entityAlertsActive: PropTypes.bool,
        features: PropTypes.object,
        id: PropTypes.number,
        isActive: PropTypes.bool,
        logoDataUrl: PropTypes.string,
        logoUrl: PropTypes.string,
        name: PropTypes.string,
        postalCode: PropTypes.string,
        rootTopicName: PropTypes.string,
        state: PropTypes.string,
        systemAlertsActive: PropTypes.bool,
        tierId: PropTypes.number,
        url: PropTypes.string,
        version: PropTypes.number,
        votesAlertsActive: PropTypes.bool,
        votesAlertsInterval: PropTypes.bool,
    }),
    router: PropTypes.shape({
        setRouteLeaveHook: PropTypes.func.isRequired,
        routes: PropTypes.array.isRequired
    }),
    user: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.arrayOf(PropTypes.elementType),
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]),
};

const mapStateToProps = (state) => {  
    return {
        entityForEdit: state.entityForEdit || {},
        user: state.auth.user,
        initialEntityModel: mapToEntityFormModel({}),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...entitiesActions, ...modalActions, ...userActions, ...topicBuilderActions }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityCreatePage);
