import React from 'react';
import PropTypes from 'prop-types';
import ProgressStep from "./progressStep";

import "./css/progressBar.css";

const ProgressBar = (props) => {
    const steps = props.steps || [];
    const stepItems = steps.map((step, index) => {
        return <ProgressStep key={index}
                             index={index}
                             title={step.title}
                             description={step.description}
                             status={step.status}
                             active={step.active}
                             required={step.required}
                             goTo={props.goTo}/>;
    });
    

    return (
        <div className="steps-container">
            {stepItems}
        </div>
    );
};

ProgressBar.propTypes = {
    steps: PropTypes.array.isRequired,
    goTo: PropTypes.func.isRequired,

};

export default ProgressBar;