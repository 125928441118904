import React from 'react';
import PropTypes from 'prop-types';
import { Star } from 'react-feather';
import styles from './css/renderByPermission.module.css';

const noPermissionLabel = ({ message, position, mode, size }) => {
	return (
		<div className={`${styles.planNotAvailable} ${styles[position]} ${styles[mode]} ${styles[size]}`}>
			<div className={styles.nonFreeAccountIcon}>
				<Star size={size === 'small' ? 12 : 14} fill='#31708f' className='text-info text-label' />
			</div>
			<label>
				<span>{message}</span>
			</label>
		</div>
	);
};

noPermissionLabel.propTypes = {
	message: PropTypes.string,
	position: PropTypes.string,
	mode: PropTypes.string,
	size: PropTypes.string
};

noPermissionLabel.defaultProps = {
	message: 'Unavailable for your plan',
	position: 'right',
	mode: 'icon',
	size: 'regular'
};

export default noPermissionLabel;