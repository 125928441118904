import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../common/modal';
import { Toggle } from '../common/form/toggle';


class ActivateUsersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldActivateUsers: false
    };
  }
  
  render() {
    const { data } = this.props;
    return (
      <Modal
        className=""
        title='ACTIVATE ORGANIZATION'
        onClose={ this.props.onClose }
        onOk={ () => {
          this.props.onSubmit(this.state.shouldActivateUsers);
        } }
        hasOkButton={true}
        btnOkLabel={"Activate"}
      >
        <div>
          <p>Organization <b>"{ data.entityName }"</b> has { data.usersCount } inactive users.</p>
          <p>Do you want to activate the assigned users as well?</p>
          <div className="form-group clearfix">
              <Toggle
                  value={this.state.shouldActivateUsers}
                  onChange={value => this.setState({ shouldActivateUsers: value })}
              />
            <label className="control-label">activate all { data.usersCount } assigned users</label>
          </div>
        </div>
      </Modal>
    );
  }
}

ActivateUsersModal.propTypes = {
  /** @prop {func} action to close modal dialog */
  onClose: PropTypes.func.isRequired,
  /** @prop {func} onSubmit action to send form data to server */
  /** @param {array} values the form data to be sent as an email to support team */
  onSubmit: PropTypes.func.isRequired,
  /** @prop {object} existing data to be filled in form, if any */
  data: PropTypes.object
};

export default ActivateUsersModal;