import { hideLoading } from 'react-redux-loading-bar';
import { addNotification } from './miscActions';
import { 
    EXPIREDCOOKIE_ERROR, 
    NEWER_VERSION_ERROR,
    TOPIC_NEWER_VERSION_ERROR
} from '../resources/apiConstants';
import { 
    LOGOUT_USER_SUCCESS, 
    HAS_VALIDATION_ERRORS, 
    COMMON_BACKEND_ERROR, 
    HAS_VERSION_ERROR 
} from './actionTypes';
import Logger from '../helpers/logger';
import { notificationLevel} from '../helpers/notification';
import messages from '../helpers/messages';

/* 
* handleCommonError to display notification and set error state to display to user
* setErrorState to false to display notification only
*/
export function handleCommonError(dispatch, error, setErrorState = true){
    if(dispatch && error){
        dispatch(hideLoading()); 
        
        let errorMessage = "Unexpected error";

        if(error.message){
            errorMessage = error.message;
        }
        
        dispatch(addNotification(errorMessage,  notificationLevel.error));

        if(setErrorState) {
            dispatch({ type: COMMON_BACKEND_ERROR, payload: errorMessage });
        }

        if(error.message === EXPIREDCOOKIE_ERROR){
            dispatch({ type: LOGOUT_USER_SUCCESS });
        }
    }
}

export function handleValidationErrors(dispatch, response, type = null) {
    if (!dispatch) {
        Logger.error(`no 'dispatch' function provided`);
        return false;
    }

    if (response.validationErrors) {
        const payload = {
            errors: response.validationErrors 
        };

        dispatch({
            type: type || HAS_VALIDATION_ERRORS,
            payload
        });
    }
}

export function handleVersionErrors(dispatch, errors, errorsTarget) {
    if (!errors.message) {
        return;
    }

    const hasNewerVersion = errors.message.toLowerCase().indexOf(NEWER_VERSION_ERROR.toLowerCase()) > -1;
    const hasTopicNewerVersion= errors.message.toLowerCase().indexOf(TOPIC_NEWER_VERSION_ERROR.toLowerCase()) > -1;
    
    if (hasNewerVersion || hasTopicNewerVersion) {
        const payload = {
            errors: messages.errors.version 
        };

        if (errorsTarget) {
            payload.errorsTarget = errorsTarget;
        }

        dispatch({
            type: HAS_VERSION_ERROR,
            payload
        });
    }
}