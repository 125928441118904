import * as types from '../actions/actionTypes';

// generates a state slice:
// votesForTopics = { 1: votesFor1[], 2: votesFor2[] ... , latestVotes: [] }
export function votesReducer(state = {}, action){
    switch (action.type) {
        case types.LOAD_NEWTOPICVOTE_SUCCESS:{
            const existingVotesForTopic = state[action.payload.topicId];
            return {
                ...state,
                [action.payload.topicId]: [...existingVotesForTopic, Object.assign({}, action.payload.vote)]
            };
        }
        case types.LOAD_RAWVOTEDATA_SUCCESS:{
            return {
                ...state,
                [action.payload.topicId]: [...action.payload.votes]
            };
        }
        case types.RESET_TOPICDATA_SUCCESS:
            return {
                ...state,
                [action.payload.topicId]: []
            };

        default:
            return state;
    }
}