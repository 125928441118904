import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE, gethMarkedDeprecatedAnswers } from '../../../helpers/graphsService';
import FeedMetaInfoBar from '../../../components/topics/qna/answersFeed/common/feedMetaInfoBar';
import Toggle from '../../../components/common/form/toggle';
import GroupByVoterList from '../../../components/topics/qna/answersFeed/groupByVoterList';
import { isEmpty } from 'lodash';
import DismissableMessage from '../../../components/common/dismissableMessage';
import { EyeOff } from 'react-feather';
import i18n from '../../../i18n';

class TopicDetailsFeedVoters extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			areDeprecatedAnswersVisible: true,
			isCollapsedAll: false,
			t: i18n.t.bind(i18n)
		};

		this.handleDeprecatedAnswersVisibilityToggle = this.handleDeprecatedAnswersVisibilityToggle.bind(this);
		this.handleRequestNextVoters = this.handleRequestNextVoters.bind(this);
		this.handleNextVotesByVoter = this.handleNextVotesByVoter.bind(this);
		this.handleResetVotesForVoter = this.handleResetVotesForVoter.bind(this);
	}

	componentDidMount() {
		this.props.requestVoters({ offset: 0 });
	}

	handleRequestNextVoters() {
		const nextPage = Math.ceil(this.props.voters.length / DEFAULT_PAGE_SIZE);
		this.props.requestVoters({ offset: nextPage * DEFAULT_PAGE_SIZE});
	}

	handleNextVotesByVoter(voterId) {
		this.props.requestVotesForVoter({ voterId });
	}

	handleResetVotesForVoter(voterId) {
		this.props.resetVotesForVoter({ voterId });
	}

	handleDeprecatedAnswersVisibilityToggle() {
		this.setState({
			areDeprecatedAnswersVisible: !this.state.areDeprecatedAnswersVisible
		});
	}

	getVotersList() {
		const { voters, totalVoters, questions, resetVoters } = this.props;
		
		return <GroupByVoterList
					onLoadVotesForVoter={this.handleNextVotesByVoter}
					onResetVotesForVoter={this.handleResetVotesForVoter}
					resetVoters={resetVoters}
					votersStats={voters}
					totalVotersCount={totalVoters}
					questions={questions}
					hideDeprecatedQuestions={this.state.areDeprecatedAnswersVisible === false}
				/>;
	}

	get canLoadMore (){
		const { voters, totalVoters } = this.props;

		if (voters && voters.length > 0) {
			return voters.length < totalVoters;
		}

		return false;
	}

	render() {
		const { voters, totalVoters, hasDeprecatedQuestions } = this.props;
		const t = this.state.t;

		return (
			<div className="feed-view view-by-voter">
				<FeedMetaInfoBar total={totalVoters} loaded={(voters || []).length} canCollapseAll={false} />
				
				{hasDeprecatedQuestions && 
					<DismissableMessage messageKey="deprecatedQuestionsMessage" type="warning">
						The answers feed contains votes data related to deleted questions or modified questions titles. Those questions, marked with <EyeOff size={16} />, are not included in the Questionnaire analysis.
					</DismissableMessage>
				}

				<div className="text-left form-group message">
					<Toggle value={this.state.areDeprecatedAnswersVisible} onChange={this.handleDeprecatedAnswersVisibilityToggle} />
					<label className="control-label">{t('topic_vote_feed_show_edited_or_removed')}</label>
				</div>

				{voters && voters.length > 0 && this.getVotersList()}

				{this.canLoadMore &&
				<button className="btn btn-outline btn-dotted btn-full-width" type="button" onClick={this.handleRequestNextVoters}>
					{t('topic_vote_timeline_load_next_voters')}
				</button>
				}
			</div>
		);
	}
}

TopicDetailsFeedVoters.propTypes = {
	/** @prop {func} requestVoters request paged voters */
	requestVoters: PropTypes.func.isRequired,

	/** @prop {func} requestVotesForVoter request paged votes for voter by voterId */
	requestVotesForVoter: PropTypes.func.isRequired,

	resetVoters: PropTypes.func.isRequired,

	resetVotesForVoter: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {	
	const topicId = ownProps.params.id;
	const votersData = state.votesFeed[topicId] ? state.votesFeed[topicId].votersData : {};
	
	let questionDetails = [];
	if (state.qnaForTopics[topicId] && state.qnaForTopics[topicId].questionDetails) {
		questionDetails = state.qnaForTopics[topicId].questionDetails;
	}

	const questions = questionDetails.map(q => {
		return {
			question: q.question,
			orderIndex: q.orderIndex
		};
	});

	let votersWithMarkedDeprecatedQuestionsInVotes = [];
	const questionsTitles = (questions || []).map(q => q.question);
	let hasDeprecatedQuestions = false;

	if (votersData && !isEmpty(votersData.voters)) {
		votersWithMarkedDeprecatedQuestionsInVotes = votersData.voters.map(voter => {
			const markedVotes = (voter.votes || []).map(vote => {
				const markedAnswers = gethMarkedDeprecatedAnswers(vote.qna, questionsTitles);
				const foundDeprecatedQuestion = markedAnswers.find(a => a.deprecated === true);
				if (!hasDeprecatedQuestions && !isEmpty(foundDeprecatedQuestion)) {
					hasDeprecatedQuestions = true;
				}
		
				return Object.assign({}, vote, {
					qna: markedAnswers
				});
			});

			return Object.assign({}, voter, {
				votes: markedVotes
			});
		});
	} 

	return {
		totalVoters: votersData ? votersData.totalVoters : 0,
		voters: votersWithMarkedDeprecatedQuestionsInVotes,
		questions,
		hasDeprecatedQuestions
	};
}

export default connect(mapStateToProps)(TopicDetailsFeedVoters);