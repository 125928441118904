import * as types from './actionTypes';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { graphGet, graphPut, graphDel, graphPost } from '../api/expressApi';
import { handleCommonError, handleValidationErrors, handleVersionErrors } from './errorActions';
import { addNotification } from './miscActions';
import { resetTopics } from './topicActions';
import { resetTopicTree } from './topicsTreeActions';
import { getTopicModel } from './models/topicModel';
import { mapToEntityPayload } from './models/entityModel';
import { browserHistory } from 'react-router';
import Logger from '../helpers/logger';
import { notificationLevel} from '../helpers/notification';
import { SUBSCRIPTIONS } from 'hollerlive-shared/constants';

function switchToEntitySuccess(entity) {
	return { type: types.SWITCH_TO_ENTITY_SUCCESS, payload: { entity } };
}

function getAllEntitiesSuccess(entities) {
	return { type: types.GET_ALL_ENTITIES_SUCCESS, payload: { entities } };
}

function deleteEntitySuccess(entityId) {
	return { type: types.DELETE_ENTITY_SUCCESS, payload: { entityId } };
}

function entityEditSuccess(entity) {
	return { type: types.EDIT_ENTITY_SUCCESS, payload: { entity } };
}

function loadEntityForEditSuccess(entity) {
	return { type: types.LOAD_ENTITY_SUCCESS, payload: { entity } };
}

function loadEntityForEditRequest() {
	return { type: types.LOAD_ENTITY_REQUEST };
}

function createEntitySuccess(entity) {
	return { type: types.CREATE_ENTITY_SUCCESS, payload: { entity } };
}

export function resetEntityForEdit() {
	return dispatch => {
		dispatch({ type: types.RESET_ENTITY_FOR_EDIT });
	};
}

export function loadEntitySourcesSuccess(entityId, sources) {
	return { type: types.LOAD_ENTITY_SOURCES_SUCCESS, payload: { entityId, sources } };
}

function createRootTopicForEntityActionSuccess(rootTopicName, entityId) {
	return { type: types.CREATE_ROOT_TOPIC_FOR_ENTITY_SUCCESS, payload: { entityId, topicName: rootTopicName } };
}

function getSubscriptionsSuccess(entityId, subscriptions) {
	return { type: types.LOAD_ENTITY_SUBSCRIPTIONS_SUCCESS, payload: { entityId, subscriptions } };
}

function cancelSubscriptionSuccess(entityId, canceledSubscription) {
	return { type: types.CANCEL_SUBSCRIPTION_SUCCESS, payload: { entityId, canceledSubscription } };
}

function loadCheckoutSessionSuccess(entityId, checkoutSession) {
	return { type: types.LOAD_CHECKOUT_SESSION_SUCCESS, payload: { entityId, checkoutSession } };
}

function loadSubscriptionStatusSuccess(entityId) {
	return { type: types.LOAD_UPGRADEDSUBSCRIPTION_SUCCESS, payload: { entityId } };
}

function loadSubscriptionStatusError(entityId, error) {
	return { type: types.LOAD_UPGRADEDSUBSCRIPTION_ERROR, payload: { entityId, error } };
}

export function getAllEntities(withSubscription) {
	return dispatch => {
		dispatch(showLoading());
		return graphGet(`/entities${withSubscription ? '?withSubscription=true' : ''}`).then(
			response => {
				dispatch(getAllEntitiesSuccess(response));
				dispatch(hideLoading());
			},
			error => {
				handleCommonError(dispatch, error);
			});
	};
}

// should be used only for the admin functionality "Switch entity"
export function switchToEntity(entityId, redirectUrl) {
	return async (dispatch, getState) => {
		dispatch(showLoading());
		try {
			let foundEntity = getState().entitiesList.find(loadedEntity => loadedEntity.id === Number(entityId));
			if (!foundEntity) {
				const entities = await graphGet('/entities');
				foundEntity = entities.find(entity => entity.id === Number(entityId));
				if (!foundEntity) {
					dispatch(addNotification(`Cannot find organization with id ${entityId}.`, notificationLevel.warning));
					dispatch(hideLoading());
					return;
				}
			}
			dispatch(addNotification(`Switched to organization ${foundEntity.name}.`,  notificationLevel.success));
			dispatch(switchToEntitySuccess(foundEntity));
			dispatch(resetTopics());
			dispatch(resetTopicTree());
			dispatch(hideLoading());
			if (redirectUrl && redirectUrl.length > 0) {
				browserHistory.push(redirectUrl);
			}
		} catch (error) {
			handleCommonError(dispatch, error);
		}
	};
}

export function deleteEntity(entityId, entityName) {
	return (dispatch) => {
		dispatch(showLoading());
		
		return graphDel(`/entities/${entityId}/delete`).then(
			() => {
				dispatch(addNotification(`Organization ${entityName} was deleted.`,  notificationLevel.success));
				dispatch(deleteEntitySuccess(entityId));
				dispatch(hideLoading());
			},
			error => {
				handleCommonError(dispatch, error);
			}
		);
	};
}

/** Retrieves the entity edit model with associated root topic (if available) */
export function getEntityForEdit(entityId){
	return async (dispatch, getState) => {
		if(getState().entityForEdit.isLoading){
			return;
		}
		
		dispatch(showLoading());
		dispatch(loadEntityForEditRequest());
		
		try {
			const entity = await graphGet(`/entities/${entityId}/edit`);
			const topics = await graphPost(`/topics/forentity/${entityId}`);
			const rootTopic = topics.find(t => t.level === 1);
			if (rootTopic) {
				entity.rootTopicName = rootTopic.name;
			}
			dispatch(loadEntityForEditSuccess(entity));
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);
		}
	};
}

/** Creates an entity and a root topic, if one is provided */
export function createEntity(entity){
	return async (dispatch) => {
		dispatch(showLoading());
		
		const entityCreateModel = mapToEntityPayload(entity);
		let createdEntityId = null;
		
		const rootTopic = entity.rootTopicName;
		if (!rootTopic) {
			handleCommonError(dispatch, { message: 'no root topic found' });
			return false;
		}
		
		try {
			const createdEntity = await graphPost('/entities/create', entityCreateModel);
			createdEntityId = createdEntity.id;
			
			dispatch(addNotification(`Organization ${entity.name} was created.`,  notificationLevel.success));
			await createRootTopicForEntity(rootTopic, createdEntityId, entityCreateModel.defaultTopicLanguage);

			dispatch(createEntitySuccess(createdEntity));
			dispatch(addNotification(`Root topic ${rootTopic} created successfully.`,  notificationLevel.success));
			dispatch(hideLoading());
		} catch (error) {
			let hasRootTopicErr = false;
			if(error.validationErrors && error.validationErrors.hasOwnProperty('basicSettings')){
				error.validationErrors.rootTopicName = `${error.validationErrors.basicSettings.title} Go to edit this organization to add a root topic.`;
				hasRootTopicErr = true;
			}
			handleValidationErrors(dispatch, error);
			
			if (hasRootTopicErr) {
				handleRootTopicCreationError(dispatch, error, "Failed to create root topic in graph.", createdEntityId);
			} else {
				handleCommonError(dispatch, error);
			}
		}
	};
}

/**
 * Send PUT request to edit entity. If entity for edit does not have a root topic,
 * will send a request to create a root topic.
 * @param {object} entity
 */
export function editEntity(entity) {
	return async (dispatch, getState) => {
		dispatch(showLoading());
		const payloadModel = mapToEntityPayload(entity);
		
		try {
			const editedEntity = await graphPut('entities/edit', payloadModel);
			
			dispatch(addNotification(`Organization "${payloadModel.name}" is saved`,  notificationLevel.success));
			
			const rootTopic = entity.rootTopicName;
			const existingRootTopic = getState().entityForEdit.rootTopicName;
			
			if (!existingRootTopic && rootTopic) {
				await createRootTopicForEntity(rootTopic, entity.id, entity.defaultTopicLanguage);
				
				editedEntity.rootTopicName = rootTopic;
			}
			
			dispatch(entityEditSuccess(editedEntity));
			dispatch(hideLoading());
		} catch (error) {
			let hasRootTopicErr = false;
			if(error.validationErrors && error.validationErrors.hasOwnProperty('basicSettings')){
				error.validationErrors.rootTopicName = error.validationErrors.basicSettings.title;
				hasRootTopicErr = true;
			}
			
			handleValidationErrors(dispatch, error);
			handleVersionErrors(dispatch, error, { type: types.UPDATE_ENTITY_VERSION_ERROR });
			
			if (hasRootTopicErr) {
				handleRootTopicCreationError(dispatch, error, "Failed to create root topic in graph.", entity.id);
			} else {
				handleCommonError(dispatch, error);
			}
		}
	};
}

export function updateEntityTier(entityId, entityPayload) {
	Logger.debug(`Send a request to update entity ${entityId} to tier ${entityPayload.plan} and pay per ${entityPayload.period}`);
	
	return async dispatch => {
		dispatch(showLoading());
		
		let period = "";
		switch (entityPayload.period) {
			case SUBSCRIPTIONS.PERIODS.MONTHLY:
				period = 'month';
				break;
			case SUBSCRIPTIONS.PERIODS.YEARLY:
				period = 'year';
				break;
			default:
				break;
		}
		
		const requestBody = Object.assign({}, entityPayload, {
			period
		});
		
		try {
			const checkoutSession = await graphPost(`/entities/${entityId}/upgradetier`, requestBody);
			Logger.debug(checkoutSession);
			dispatch(loadCheckoutSessionSuccess(entityId, checkoutSession));
			dispatch(hideLoading());
		} catch (e) {
			dispatch(hideLoading());
		}
	};
}

export function getSubscriptions(entityId) {
	return async dispatch => {
		dispatch(showLoading());
		
		try {
			const subscriptions = await graphGet(`/entities/${entityId}/getsubscriptions`);
			dispatch(getSubscriptionsSuccess(entityId, subscriptions));
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);
		}
	};
}

export function getEntitySubscriptionStatus(entityId, subscriptionPayload) {
	return async dispatch => {
		dispatch(showLoading());
		Logger.debug(`checking subscription for ${entityId} entity with ${subscriptionPayload}`);
		try {
			await graphPost(`/entities/${entityId}/checksubscription`, subscriptionPayload);
			dispatch(loadSubscriptionStatusSuccess(entityId));
			dispatch(hideLoading());
		} catch (e) {
			dispatch(loadSubscriptionStatusError(entityId, e));
			dispatch(hideLoading());
		}
	};
}

export function cancelSubscription(entityId, subscriptionId) {
	return async dispatch => {
		dispatch(showLoading());
		
		try {
			const canceledSubscription = await graphPut(`/entities/${entityId}/cancelsubscription`, { id: subscriptionId });
			dispatch(cancelSubscriptionSuccess(entityId, canceledSubscription));
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);
		}
	};
}

export function createRootTopicForEntityAction(rootTopicName, entityId) {
	return async dispatch => {
		try {
			const newTopic = await createRootTopicForEntity(rootTopicName, entityId);
			
			dispatch(addNotification(`Root topic ${newTopic.title} created successfully.`,  notificationLevel.success));
			dispatch(hideLoading());
			dispatch(createRootTopicForEntityActionSuccess(rootTopicName, entityId));
		} catch (error) {
			let hasRootTopicErr = false;
			if(error.validationErrors && error.validationErrors.hasOwnProperty('basicSettings')){
				error.validationErrors.rootTopicName = error.validationErrors.basicSettings.title;
				hasRootTopicErr = true;
			}
			handleValidationErrors(dispatch, error);
			
			if (hasRootTopicErr) {
				dispatch({
					type: types.CREATE_ROOT_TOPIC_FOR_ENTITY_ERROR,
					payload: {
						error: error,
						entityId: entityId,
						topicName: rootTopicName
					}
				});
				handleRootTopicCreationError(dispatch, error, "Failed to create root topic in graph.", entityId);
			} else {
				handleCommonError(dispatch, error);
			}
		}
	};
}

export function loadEntitySources(entityId) {
	return async dispatch => {
		try {
			if (entityId) {
				dispatch(showLoading());
				const sources = await graphGet(`/entities/${entityId}/sources`);
				dispatch(loadEntitySourcesSuccess(entityId, sources));
				dispatch(hideLoading());
			} else {
				Logger.error("Empty entityId supplied to loadEntitySources!");
			}
		} catch (error) {
			handleCommonError(dispatch, error);
		}
	};
}

async function createRootTopicForEntity(topicName, entityId, defaultLang = 'en') {
	const topicModel = getTopicModel();
	topicModel.basicSettings.entityId = entityId;
	topicModel.basicSettings.title = topicName;
	topicModel.basicSettings.defaultLang = defaultLang;
	topicModel.level = 1;
	topicModel.basicSettings.isRoot = true;
	topicModel.config.parent = null;
	return await graphPost('/topics/create', topicModel);
}

function handleRootTopicCreationError(dispatch, error, displayMessage, entityId) {
	Logger.debug(JSON.stringify(error));
	const errorMessage = displayMessage + " " + error.message;
	handleCommonError(dispatch, {message: errorMessage});
}