import { SHOW_MODAL, HIDE_MODAL } from '../actions/actionTypes';
import { MODAL_TYPES_COMPONENTS } from '../helpers/modalTypes';

export function showCustomModal(component, config) {
	return dispatch => {
		dispatch({ type: SHOW_MODAL, payload: { component, config }});
	};
}

export function showModal(componentName, config) {
	return dispatch => {
		const component = MODAL_TYPES_COMPONENTS[componentName];
		dispatch({ type: SHOW_MODAL, payload: { component, config }});
	};
}

export function hideModal() {
	return dispatch => {
		dispatch({ type: HIDE_MODAL });
	};
}