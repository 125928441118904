import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PlanBox from '../components/common/planBox';
import Card from '../components/common/card';
import { PLANS } from '../helpers/plans';
import { SUBSCRIPTIONS } from 'hollerlive-shared/constants';
import { SUBJECT_OPTIONS } from '../helpers/contactSupport';
import Logger from '../helpers/logger';
import { showModal, hideModal } from '../actions/modalActions';
import { clearContactFormErrors } from '../actions/contactActions';
import { updateEntityTier } from '../actions/entitiesActions';
import { CONTACT_FORM_MODAL, CONFIRM_MODAL } from '../helpers/modalTypes';
import { STRIPE_PUBLIC_KEY } from '../resources/apiConstants';

export const PlansPage = ({ user, entity, checkoutSession, updateEntityTier, showModal, hideModal, clearContactFormErrors }) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            if(checkoutSession && scriptLoaded){
                Logger.debug("Has valid session and stripe ref. Redirecting to Stripe...");
                var stripe = window.Stripe(STRIPE_PUBLIC_KEY);
                stripe.redirectToCheckout({
                    sessionId: checkoutSession.id
                  }).then((result) => {
                    Logger.debug(result);
                });
            }
        } else {
            Logger.debug("Loading stripe script...");

            const script = document.createElement("script");
            script.async = true;
            script.src = "https://js.stripe.com/v3";
            script.onload = () => {
                setScriptLoaded(true);
            };

            document.body.appendChild(script);
            didMountRef.current = true;
        }
    }, [checkoutSession, scriptLoaded]);

    function showConfirmSendModal() {
        const config = {
            title: 'All set!',
            message: `Your request has been forwarded to our client team. 
            We will contact you as soon as possible. 
            Happy to be working with you, ${user.firstName}!`,
            onClose: hideModal,
            onConfirm: hideModal,
            hasFooter: false
        };
    
        showModal(CONFIRM_MODAL, config);
    }

    function showContactForm() {
        const config = {
            onSubmit: showConfirmSendModal,
            onClose: () => {
                clearContactFormErrors();
                hideModal();
            },
            data: { subject: SUBJECT_OPTIONS[6].subject, enableTechnicalData: false }
        };
        showModal(CONTACT_FORM_MODAL, config);
    }

    function handleUpdateUserPlan(newTierId, period) {
        const enterprisePlan = SUBSCRIPTIONS.PLANS.find(plan => plan.name.toLowerCase().indexOf('enterprise') > -1);
        if (newTierId === enterprisePlan.tierId) {
            showContactForm();
        } else {
            updateEntityTier(entity.id, { plan: newTierId, period });
        }
    }

    return entity.tierId ? <Card title="upgrade your account">
        <p>Do even more with your kazva.bg with our paid accounts</p>
        <p>Upgrade your kazva.bg account and get premium features such as multiple topics &amp; users access, advanced questionnaires and data filtering, customized topic voting experience, voting data exports, alerts and more. Select from <strong>Pro</strong> or <strong>Business</strong> accounts or talk with us if your organization needs more analytics power with our <strong>Enterprise</strong> plan.</p>

        <div className="plans-container">
            {PLANS.map((plan, i) => {
                return (
					<PlanBox
						plan={plan}
						userPlanId={Number(entity.tierId)}
						onSubmit={(tierId, period) => handleUpdateUserPlan(tierId, period)}
						key={i}
					/>
				);
            })}
        </div>
        
        <p className="text-muted">
            * All payments are securely processed by <strong>stripe.com</strong> and include VAT, based on your country. 
            Actual VAT depends on your purchase information, including billing country. After selecting a plan, you will be directed to the stripe.com payment website. 
            After a successful payment your kazva.bg account will be updated and ready to use. kazva.bg does not keep any information about payments, credit cards or any other
            payment information.
        </p>
    </Card> : <div />;
};

PlansPage.propTypes = {
	user: PropTypes.shape({
		permissions: PropTypes.array.isRequired,
		hasSpecialPermissions: PropTypes.bool,
	}).isRequired,
	entity: PropTypes.shape({ id: PropTypes.number, tierId: PropTypes.number }).isRequired,
	checkoutSession: PropTypes.object,
	// action props
	updateEntityTier: PropTypes.func.isRequired,
	showModal: PropTypes.func,
	hideModal: PropTypes.func,
	clearContactFormErrors: PropTypes.func
};

PlansPage.defaultProps = {
	user: {},
	entity: {},
	updateEntityTier: () => {}
};

function mapStateToProps(state) {
	if (state.auth.user && state.auth.user.entity) {
		const checkoutSession = state.entities.length > 0 ? state.entities[0].checkoutSession : null;
		return { user: state.auth.user, entity: state.auth.user.entity, checkoutSession };
	}
	return {};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ showModal, hideModal, clearContactFormErrors, updateEntityTier }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansPage);