import React from 'react';
import PropTypes from 'prop-types';
import styles from './css/toggle.module.css';
import { isRequiredFuncIfNotSetFlag } from '../../../helpers/utils';

export const Toggle = ({ value, onChange, disabled, inverse, className }) => {
    return (
        <label className={`holler-toggle ${styles.toggle} ${className}`}>
            <input
                type='checkbox'
                checked={value}
                onChange={ev => onChange(ev.target.checked)}
                disabled={disabled}
                className='uitest-toggle-input'
            />
            <span className={`${styles.slider} ${disabled ? styles.disabled : ''} ${inverse ? styles.inverse : ''}`} />
        </label>
    );
};

Toggle.defaultProps = { value: false, onChange: () => {}, disabled: false, inverse: false, className: '' };

Toggle.propTypes = {
    /** @prop {bool} value indicates whether toggle is true or false */
    value: PropTypes.bool.isRequired,
    /** @prop {func} onChange action on toggling the value */
    onChange: isRequiredFuncIfNotSetFlag('disabled'),
    /** @prop {bool} disabled optional indicates whether the toggle is disabled */
    disabled: PropTypes.bool,
    /** @prop {bool} inverse optional two-tone thematic color flip. Default is false */
    inverse: PropTypes.bool,
    /** @prop {string} className optional */
    className: PropTypes.string
};

export default Toggle;
