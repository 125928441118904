import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';
import moment from 'moment';
import { isEqual, isEmpty } from 'lodash';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import PermissionsContext from '../contexts/permissionsContext';
import { PERMISSIONS } from '../../helpers/permissionsHelper';


import * as topicActions from '../../actions/topicActions';
import * as qnaActions from '../../actions/qnaActions';
import * as graphDataActions from '../../actions/graphDataActions';
import * as modalActions from '../../actions/modalActions';
import { addNotification } from '../../actions/miscActions';

import logger from '../../helpers/logger';
import { calculateVoteStats } from '../../helpers/voteDataHelper';
import { getTopicLinksModalConfig } from '../../helpers/modalDialogsHelper';
import { DATE_FILTER_OPTIONS, getDateRangeByPeriodIndex } from '../../helpers/datePickerService';
import { generateTopicDetailsPDF } from '../../helpers/customPDFGenerator';

import Card from '../../components/common/card';
import DateRange from '../../components/common/dateRange/dateRange';
import OptionsSelection from '../../components/common/form/optionsSelector';
import VotesFilters from '../../components/topics/common/votesFilters';

import HeaderStatsBar from "../../components/topics/headerStatsBar";
import { Check, Link as LinkIcon, Edit, BarChart2, CheckCircle, Filter, ChevronDown, ChevronUp, ArrowDown, List } from 'react-feather';
import { TOPIC_LINKS_MODAL } from '../../helpers/modalTypes';
import { isOnMobile } from '../../helpers/utils';
import { getDefaultFilterOptions } from '../../helpers/graphsService';

import './css/topics.css';
import { notificationLevel } from '../../helpers/notification';
import LifetimeStatBox from '../../components/common/lifetimeStatBox';
import FeaturesContext from "../contexts/featuresContext";
import { ENTITY_FEATURES } from "hollerlive-shared/constants";
import { COMPARATORS, entityHasFeatures } from "../../helpers/featuresHelper";
import NoPermissionLabel from '../../components/common/noPermissionLabel';
import messages from '../../helpers/messages';
import i18n from '../../i18n';

const DEFAULT_INTERVAL_SELECTION = DATE_FILTER_OPTIONS.indexOf("filter_date_today");
const CUSTOM_INTERVAL_SELECTION = DATE_FILTER_OPTIONS.indexOf("filter_date_select_range");

export class TopicDetailsPage extends Component {
    constructor(props, context) {
        super(props, context);

        const { selectedDateRange, selectedPeriodIndex, hideDateFilterOptions } = this._getDefaultRange(props);

        this.state = {
            t: i18n.t.bind(i18n),
            dateRangeOpened: false,
            hideDateFilterOptions,
            selectedPeriodIndex,
            selectedDateRange,
            showOverviewMobile: false,
            filteredVoteStats: {
                averageVote: 0,
                count: 0,
                numberOfUniqueVotes: 0,
                sumOfRepeatedVotes: 0,
                numberOfRepeatedUsers: 0,
                numberOfIncreasedVoteUsers: 0,
                numberOfDecreasedVoteUsers: 0,
                avgNumberRepeatedVotes: 0,
                percentMultiplePeopleOfTotal: 0,
                percentRepeatedVotesOfTotal: 0,
                numberOfAnonymousVotes: 0,
                numberOfAnonymousUsers: 0,
                percentAnonymousUsersOfTotal: 0,
                percentAnonymousVotesOfTotal: 0
            },
            showVotesBy: {
                anonymous: true,
                facebook: true,
                email: true,
                latestPerVoter: false,
                sources: []
            },
            voterFiltersExpanded: false,
            votesForTopic: [],
            isExportAllowed: true
        };
        
        this.handleApplyPeriodClick = this.handleApplyPeriodClick.bind(this);
        this.toggleCustomDateFilterOpen = this.toggleCustomDateFilterOpen.bind(this);
        this.onPeriodChange = this.onPeriodChange.bind(this);
        this.onDateRangeCleared = this.onDateRangeCleared.bind(this);
        this.onDateRangeSet = this.onDateRangeSet.bind(this);
		this.cancelSetRange = this.cancelSetRange.bind(this);
        this.showOverview = this.showOverview.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.handleOpenLinksModal = this.handleOpenLinksModal.bind(this);
        this.onVotesFilterChange = this.onVotesFilterChange.bind(this);
        this.handleExpandVotesFiltering = this.handleExpandVotesFiltering.bind(this);
        this.handleResetVotesFilters = this.handleResetVotesFilters.bind(this);
        this.handleGeneratePDF = this.handleGeneratePDF.bind(this);
        this.adjustVoteFiltersWrapperStyle = this.adjustVoteFiltersWrapperStyle.bind(this);
        this.handleExportAllowed = this.handleExportAllowed.bind(this);

        this.topicDetailsPageRef = React.createRef();
        this.voterFiltersWrapperRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {};

        const filteredVotesForCurrentTopic = _getFilteredVotesForCurrentTopic(nextProps.rawVotesForCurrentTopic, prevState.showVotesBy);
        if(!isEqual(filteredVotesForCurrentTopic, prevState.votesForTopic)){
            const voteStats = calculateVoteStats(filteredVotesForCurrentTopic);
            newState = { ...newState, votesForTopic: filteredVotesForCurrentTopic, filteredVoteStats: voteStats };
        }

        // hide export to pdf button on hard reload of votes feed
        if (!isEmpty(nextProps.location) && nextProps.location.pathname.includes('feed/votes')) {
            newState = { ...newState, isExportAllowed: false };
        }

        if (isEmpty(newState)) {
            return null;
        } else {
            return newState;
        }
    }    

    componentDidMount() {
        const { actions, topic, params } = this.props;
        const topicId = topic.id;

        const { showVotesBy, selectedDateRange } = this.state;
        const { dateFrom, dateTo } = selectedDateRange;

        if (!topicId) {
            actions.loadTopicById(params.id);
        } else {
            actions.loadLatestVotesForTopic(topicId);
            actions.loadQuestionsStatsForTopic(topicId, dateFrom, dateTo, showVotesBy);
            
            this._loadVoteDataForTopic(topicId, dateFrom, dateTo);
            actions.loadTopicStructureById(topicId);
        }

        this.attachScrollEvent();
    }

    componentDidUpdate(prevProps, prevState) {
        const { actions, topic } = this.props;
        const { showVotesBy, dateRangeOpened, votesForTopic } = this.state;

        if (prevProps.topic.id !== topic.id) {
            const { selectedDateRange, selectedPeriodIndex, hideDateFilterOptions } = this._getDefaultRange(this.props);
            const { dateFrom, dateTo } = selectedDateRange;

            actions.loadLatestVotesForTopic(topic.id);
            actions.loadQuestionsStatsForTopic(topic.id, dateFrom, dateTo, showVotesBy);

            this._loadVoteDataForTopic(topic.id, dateFrom, dateTo);
            this.setState({ selectedDateRange, selectedPeriodIndex, hideDateFilterOptions });
        } 

        const timeRangeChanged = prevState.selectedDateRange.dateFrom !== this.state.selectedDateRange.dateFrom ||
                                    prevState.selectedDateRange.dateTo !== this.state.selectedDateRange.dateTo;
        
        
        const newState = {};

        if (!dateRangeOpened && (timeRangeChanged || !isEqual(prevState.votesForTopic, votesForTopic))) {
            newState.filteredVoteStats =  calculateVoteStats(votesForTopic);
        }

        if (!isEmpty(newState)) {
            this.setState(newState);
        }
    }

    componentWillUnmount() {
        this.dettachScrollEvent();
        this.props.actions.resetGraphDataForTopic(this.props.topic.id);
    }

    handleGeneratePDF() {
        if (!entityHasFeatures([ENTITY_FEATURES.CHARTS_EXPORT], this.props.entity)){
            return false;
        }

        this.props.actions.showLoading();

        const texts = this.getTextsToPrint();
        const votesAnalysisElements = this.getVotesAnalysisElementsToPrint();
        const htmlElements = this.getHtmlElementsToPrint();

        let questionOrderSelectionLabel = '';
        if (this.props.location.pathname.includes('qna')) {
            questionOrderSelectionLabel = document
                .getElementsByClassName('export-pdf-question-orber-by')[0]
                .getElementsByClassName('export-pdf-label')[0]
                .innerHTML;
        }

        const elementsForPDF = {
            texts,
            votesAnalysisElements,
            htmlElements,
            questionOrderSelectionLabel,
            holderCanvasElement: document.createElement('canvas'),
            fileName: `${this.props.topic.name}`,
        };

        generateTopicDetailsPDF(elementsForPDF).then(
            () => {
                this.props.actions.hideLoading();
            }, (error) => {
                logger.error(`Error trying to download PDF: ${error}`);
                this.props.actions.hideLoading();
                this.props.actions.addNotification('Cannot download PDF', notificationLevel.error);
            }
        );
    }

    getTextsToPrint() {
        let firstName, lastName;
        if (this.props.auth.user && this.props.auth.user.firstName) {
            firstName = this.props.auth.user.firstName;
        } 
        if (this.props.auth.user && this.props.auth.user.lastName) {
            lastName = this.props.auth.user.lastName;
        }

        let selectedTimePeriod;
        if (Number(this.state.selectedPeriodIndex) === CUSTOM_INTERVAL_SELECTION) {
            selectedTimePeriod = `${moment(this.state.selectedDateRange.dateFrom).format('D MMMM YYYY')} to ${moment(this.state.selectedDateRange.dateTo).format('D MMMM YYYY')}`;
        } else {
            selectedTimePeriod = `${DATE_FILTER_OPTIONS[this.state.selectedPeriodIndex]}`;
        }

        return {
            entity: this.props.entity ? this.props.entity.name : null,
            author: (firstName || lastName) ? `${firstName} ${lastName}` : null,
            topic: this.props.topic ? this.props.topic.name : null,
            dateRange: selectedTimePeriod,
            includingData: this.selectedAuthFiltersText,
            showingData: this.selectedVoterTypeFiltersText,
        };
    }

    getVotesAnalysisElementsToPrint() {
        let elementsToPrint = [];

        let chartsWrappers = [];
        if (this.props.location.pathname.includes('graphs')) {
            if ( document.getElementsByClassName('export-pdf-votes-analysis-container') && 
                !getComputedStyle(document.getElementsByClassName('export-pdf-votes-analysis-container')[0], null).display.includes('none') 
            ) {
                chartsWrappers = document.querySelectorAll('.pdf-export-votes-graph-wrapper');
            }
        }
        
        for (let i = 0; i < chartsWrappers.length; i ++) {
            const currentChart = chartsWrappers[i];
            const label = currentChart.getElementsByClassName('export-pdf-card-title')[0].innerText.split(/\r?\n/)[0]; // get the first line of the first h4 tag
            
            let mode, chart;
            if (currentChart.getElementsByClassName('export-pdf-chart-control-options')) {
                mode = currentChart.getElementsByClassName('export-pdf-chart-control-options')[0];
            }

            if (currentChart.getElementsByClassName('export-pdf-chart-wrapper')) {
                chart = currentChart.getElementsByClassName('export-pdf-chart-wrapper')[0];
            }
            
            elementsToPrint.push({ label, mode, chart });
        }

        return elementsToPrint;
    }

    getHtmlElementsToPrint() {
        let elementsToPrint = {};

        if ( document.getElementsByClassName('export-pdf-stats-container') && 
            !getComputedStyle(document.getElementsByClassName('export-pdf-stats-container')[0], null).display.includes('none') 
        ) {
            elementsToPrint.statsItems = document.getElementsByClassName('export-pdf-stats-row');
        }
    
        if (this.props.location.pathname.includes('qna')) {
            if ( document.getElementsByClassName('export-pdf-questions-volume-container') && 
                !getComputedStyle(document.getElementsByClassName('export-pdf-questions-volume-container')[0], null).display.includes('none') 
            ) {
                const questionsVolumeContainerDiv = document.getElementsByClassName('export-pdf-questions-volume-container')[0];
                elementsToPrint.questionsByVolume = questionsVolumeContainerDiv.getElementsByClassName('export-pdf-chart-wrapper');
            }
    
            if ( document.getElementsByClassName('export-pdf-questionnaire-container') && 
                !getComputedStyle(document.getElementsByClassName('export-pdf-questionnaire-container')[0], null).display.includes('none') 
            ) {
                elementsToPrint.questionsList = document.getElementsByClassName('export-pdf-question-details-item');
            }
        }

        return elementsToPrint;
    }
    
    handleApplyPeriodClick() {
        const { firstVoteTime, lastVoteTime } = this.props.topic.stats;
        this.onDateRangeSet(firstVoteTime, lastVoteTime);
    }
    
    static getFilteredVotesForCurrentTopic(votes, filters) {
        return _getFilteredVotesForCurrentTopic(votes, filters);
    }
    
    handleResetVotesFilters() {
        const defaultOptions = getDefaultFilterOptions();
        this.onVotesFilterChange(defaultOptions);
    }

    handleExpandVotesFiltering(e, shouldCollapse = true) {
        const nextExpandState = !this.state.voterFiltersExpanded;

        if (!e.target.className.toString().indexOf('time-range-selector') > -1) {
            if (nextExpandState === false) {
                if (shouldCollapse) {
                    this.setState({ voterFiltersExpanded: nextExpandState });
                }
            } else {
                this.setState({ voterFiltersExpanded: nextExpandState });
            }
        }
    }

    handleOpenLinksModal() {
        const t = this.state.t;
        const topicLinksConfig = getTopicLinksModalConfig(this.props.topic.id);
        const modalComponentProps = Object.assign({}, topicLinksConfig, {
            onClose: this.props.actions.hideModal,
            btnCancelLabel: t('modal_general_close')
        });

        this.props.actions.showModal(TOPIC_LINKS_MODAL, modalComponentProps);
    }
    
    get topicDataLoaded() {
        return this.props.topic.basicSettings && this.props.topic.linkConfig;
    }
    
    _getDefaultRange(props){
        const {dateFrom, dateTo, intervalIndex} = props.initialDateRange;

        const defaultDateRange = getDateRangeByPeriodIndex(DEFAULT_INTERVAL_SELECTION);
        const selectedInterval = intervalIndex || DEFAULT_INTERVAL_SELECTION;
        const isCustomIntervalSelected = selectedInterval === CUSTOM_INTERVAL_SELECTION;
        
        return { 
            selectedDateRange: {
                dateFrom: dateFrom || defaultDateRange.dateFrom,
                dateTo: isCustomIntervalSelected && dateTo ? dateTo : defaultDateRange.dateTo   // in all cases except custom date range, we want the upper range to be NOW
            },
            selectedPeriodIndex: selectedInterval,
            hideDateFilterOptions: isCustomIntervalSelected                
        };
    }

    _loadVoteDataForTopic(topicId, dateFromMoment, dateToMoment){
        const dataParams = {orderBy: 'id', sortOrder: 'DESC', pageSize: 200000, offset: 0};
        if(dateFromMoment){
            dataParams.startDateTime = dateFromMoment;
        }
        if(dateToMoment){
            dataParams.endDateTime = dateToMoment;
        }
        const topic = topicId || this.props.topic.id;
        this.props.actions.loadRawVoteDataForTopic(topic, dataParams);
    }

    toggleCustomDateFilterOpen(dateRangeVisible, dateFiltersVisible) {
        this.setState({
            hideDateFilterOptions: !dateFiltersVisible,
            dateRangeOpened: dateRangeVisible
        });
    }

    onPeriodChange(selectedPeriodIndex, ev) {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }

        if (Number(selectedPeriodIndex) !== CUSTOM_INTERVAL_SELECTION) {
            const dateRange = getDateRangeByPeriodIndex(Number(selectedPeriodIndex));
            
            this.setState({
                selectedDateRange: {
                    dateFrom: dateRange.dateFrom,
                    dateTo: dateRange.dateTo
                },
                selectedPeriodIndex: selectedPeriodIndex,
            });

            this._loadVoteDataForTopic(this.props.topic.id, dateRange.dateFrom, dateRange.dateTo);
        } else {
            const showDateFilters = this.state.selectedPeriodIndex !== CUSTOM_INTERVAL_SELECTION;
            this.toggleCustomDateFilterOpen(true, showDateFilters);
        }
    }

    onDateRangeSet(from, to) {
        if (from && to) {
            const dateFrom = moment(from).utc().format();
            const dateTo = moment(to).utc().format();
            
            this.setState({ 
                dateRangeOpened: false,
                selectedDateRange: {
                    dateFrom,
                    dateTo
                },
                selectedPeriodIndex: CUSTOM_INTERVAL_SELECTION,
                hideDateFilterOptions: true            
            });
            
            this._loadVoteDataForTopic(this.props.topic.id, dateFrom, dateTo);
        }
    }

    onDateRangeCleared(){
        const defaultDateRange = getDateRangeByPeriodIndex(DEFAULT_INTERVAL_SELECTION);
        this.setState({ 
            selectedDateRange: {
                dateFrom: defaultDateRange.dateFrom,
                dateTo: defaultDateRange.dateTo
            },
            selectedPeriodIndex: DEFAULT_INTERVAL_SELECTION                
        });
        
        this.toggleCustomDateFilterOpen(false, true);        
        this._loadVoteDataForTopic(this.props.topic.id, defaultDateRange.dateFrom, defaultDateRange.dateTo);
    }

    cancelSetRange() {
        const selectedPeriodIndex = this.state.selectedPeriodIndex;
        const showDateFilters = selectedPeriodIndex !== CUSTOM_INTERVAL_SELECTION;
        this.toggleCustomDateFilterOpen(false, showDateFilters);
    }

    _addLiveLable() {
        return (window.screen.width > 795 || window.screen.height > 1024) ? '<label class="label label-danger label-live">LIVE!</label>' : '';
    }

    getDateRangeSelector() {
        const { t, selectedDateRange } = this.state;
        const dateFromMoment = moment(selectedDateRange.dateFrom).format('D MMMM YYYY');
        const dateToMoment = moment(selectedDateRange.dateTo).format('D MMMM YYYY');
        const dateFilterOptionCaptions = this.isMobileView ? DATE_FILTER_OPTIONS.filter((o, i) => i !== 4) : [...DATE_FILTER_OPTIONS];

        const periodOptions = dateFilterOptionCaptions.map((o, i) => {
            const option = {
                label: o,
                value: i.toString()
            };
            if (i === 4) {
                if (this.props.entity.features && !this.props.entity.features.hasCustomDateRange) {
                    option.nonFree = true;
                    option.disabled = true;
                }
            }

            return option;
		});

        return(
            <div className={`time-range-selector text-label ${this.state.hideDateFilterOptions ? 'custom-range' : ''}`}>
                { this.isMobileView ? `${t('topic_details_mobile_votes_from')} ` : `${t('topic_details_votes_from')} ` }

                {!this.state.hideDateFilterOptions &&                 
                <OptionsSelection 
                    onSelection={this.onPeriodChange}
                    options={periodOptions} 
                    value={this.state.selectedPeriodIndex.toString()} />
                }

                {this.state.hideDateFilterOptions && 
                <div className="options-selector">
                    <div className="selection-label">
                        &nbsp;
                        <button
                            type='button'
                            className='btn btn-link'
                            onClick={() => this.toggleCustomDateFilterOpen(true, false)}
                        >
                            {dateFromMoment}
                        </button>
                        &nbsp;to&nbsp;
                        <button
                            type='button'
                            className='btn btn-link'
                            onClick={() => this.toggleCustomDateFilterOpen(true, false)}
                        >
                            {dateToMoment}
                        </button>
                        &nbsp;
                        <button type='button' className='btn btn-link' onClick={this.onDateRangeCleared}>
                            <i className='glyphicon glyphicon-remove' />
                        </button>
                        &nbsp;
                    </div>
                </div>
                }

                {this.state.dateRangeOpened && 
                <DateRange
                        dateFrom={new Date(dateFromMoment)}
                        dateTo={new Date(dateToMoment)}
                        onRangeSelected={this.onDateRangeSet}
                        cancelRangeSelect={this.cancelSetRange}
                /> }
            </div>
        );
    }
  
    showOverview() {
        this.setState({showOverviewMobile: true});
    }

    showDetails() {
        this.setState({showOverviewMobile: false});
    }

    onVotesFilterChange(options) {
        const filteredVotesForCurrentTopic = _getFilteredVotesForCurrentTopic(this.props.rawVotesForCurrentTopic, options);
        this.setState({
            voterFiltersExpanded: false,
            votesForTopic: filteredVotesForCurrentTopic,
            showVotesBy: options
        });
    }

    _sortVotesByDate(arr) {
        return arr.sort((a, b) => {return new Date(a.date) - new Date(b.date); });
    }

    get hasAuthFiltersActive() {
        const { facebook, email, anonymous } = this.state.showVotesBy;
        return facebook || email || anonymous;
    }

    get hasVoterTypeFiltersActive(){
        const { latestPerVoter } = this.state.showVotesBy;
        return latestPerVoter; 
    }

    get selectedAuthFiltersText() {
        const { t } = this.state;
        const { facebook, email, anonymous } = this.state.showVotesBy;
        return `${facebook ? `${t('topic_filters_facebook')}, ` : ''} ${email ? `${t('topic_filters_email')}, ` : ''} ${anonymous ? `${t('topic_filters_anonymous')}, ` : ''}`;
    }

    get selectedVoterTypeFiltersText() {
        const { t } = this.state;
        return `${this.state.showVotesBy.latestPerVoter ? t('topic_filters_showing_only_latest') : t('topic_filters_showing_all')}`;
    }
    
    get sourcesFromVotes() {
        const sources = new Set();
        this.props.rawVotesForCurrentTopic.forEach(vote => {
            vote.sources.forEach(source=>{
                sources.add(source);
            });
        });
    
        return [...sources].map(source => {
            return {
                name: source,
                id: source
            };
        });
    }

    get isMobileView() {
        return isOnMobile();
    }

    attachScrollEvent() {
        if (this.topicDetailsPageRef.current) {
            // so strange to rely that the parent(styled with the 'content' class) is scrollable
            // TODO: find a way to remove this hardcoding
            const scrollNode = this.topicDetailsPageRef.current.parentElement;
            scrollNode.addEventListener('scroll', this.adjustVoteFiltersWrapperStyle);
        }
    }
    
    dettachScrollEvent() {
        const scrollNode = this.topicDetailsPageRef.current.parentElement;
		scrollNode.removeEventListener('scroll', this.adjustVoteFiltersWrapperStyle);
    }

    get isContentScolledBelowHeader() {
        const scrollNode = this.topicDetailsPageRef.current.parentElement;
        return scrollNode.scrollTop > this.topicDetailsPageHeadingRef.offsetHeight;
    }

    handleExportAllowed(isAllowed) {
        this.setState({ isExportAllowed: isAllowed });
    }

    adjustVoteFiltersWrapperStyle() {
        if (this.isContentScolledBelowHeader) {
            this.voterFiltersWrapperRef.current.classList.add('stick-top');
        } else {
            this.voterFiltersWrapperRef.current.classList.remove('stick-top');
        }
    }

    get childrenNavigation(){
        const feedRoute = this.props.routes.find(r => r.path.indexOf('feed') > -1); 
        const feedActiveTabClass = `tab ${ feedRoute ? 'active' : '' }`;
        const { t } = this.state;

        return <div className={`subpages-nav ${this.state.showOverviewMobile ? "hide-on-mobile" :  ""}`}>
                    <Link to={`/topics/details/${this.props.topic.id}/graphs`} activeClassName="active" className="tab" onClick={() => this.handleExportAllowed(true)}><BarChart2 size={18} /> <span className="tab-text">{t('topic_analytics_tab_votes')}</span></Link>
                    <Link to={`/topics/details/${this.props.topic.id}/qna`} activeClassName="active" className="tab" onClick={() => this.handleExportAllowed(true)}><CheckCircle size={18} /> <span className="tab-text">{t('topic_analytics_tab_questionnaire')}</span></Link>
                    <Link to={`/topics/details/${this.props.topic.id}/feed/votes`} activeClassName="active" className={feedActiveTabClass} onClick={() => this.handleExportAllowed(false)}><List size={18} /> <span className="tab-text">{t('topic_analytics_tab_feed')}</span></Link>
                </div>;
    }

    get canExportPDF() {
        return !this.isMobileView && this.state.isExportAllowed;
    }

    render() {
        const { firstVoteTime, lastVoteTime, lifetimeVotesCount, lifetimeAnswersCount } = this.props.topic.stats || {};
        const { voterFiltersExpanded, showVotesBy } = this.state;

        const { t } = this.state;
        
        const childrenScenes = React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, {
                votes: this.state.votesForTopic || [],
                votesCount: this.state.votesForTopic.length,
                dateFrom: this.state.selectedDateRange.dateFrom,
                dateTo: this.state.selectedDateRange.dateTo,
                topic: this.props.topic,
                periodIndex: Number(this.state.selectedPeriodIndex),
                voterFilters: Object.assign({}, showVotesBy)
            });
        });
        
        const latestVotes = this.props.topic.latestVotes ? this._sortVotesByDate([...this.props.topic.latestVotes]) : [];
        const latestVotesDesc = latestVotes.reverse();
        const overallRatingCardTitleInterval = !this.state.hideDateFilterOptions ? DATE_FILTER_OPTIONS[this.state.selectedPeriodIndex] : t('filter_date_selected_period');

        const showMobileClass = `${this.state.showOverviewMobile ? 'hide-on-mobile' : ''}`;
        const expandCollapsedClass = `${voterFiltersExpanded ? 'votes-filters-expanded' : 'votes-filters-collapsed'}`;
        const voterFiltersClassName = `votes-filters clearfix ${showMobileClass} ${expandCollapsedClass}`;
        
        return (
            <div className="topic-details-page" ref={this.topicDetailsPageRef}>
                <div className='topic-details-header-container'>
                    <h2 className="page-heading" ref={topicDetailsPageHeading => this.topicDetailsPageHeadingRef = topicDetailsPageHeading}>
                        {this.props.topic.name}
                    </h2>
                    <div className='buttons-n-stats-container'>
                        {!isEmpty(this.props.topic) && !this.isMobileView &&
                        <div className='lifetimestats-container uitest-lifetimestatsbox-container'>
                            <LifetimeStatBox
                                firstVoteTime={firstVoteTime}
                                lastVoteTime={lastVoteTime}
                                allVotesCount={lifetimeVotesCount}
                                allAnswersCount={lifetimeAnswersCount}
                            />
                            {(firstVoteTime && lastVoteTime) && <button
                                onClick={this.handleApplyPeriodClick}
                                className='btn btn-xs btn-outline btn-default uitest-lifetimeperiodbutton'
                            >
                                <Check size={16} />
                                {t('topic_overview_apply_period')}
                            </button>}
                        </div>
                        }
                        <div className='buttons-container btn-toolbar'>
                            {this.canExportPDF &&
                            <FeaturesContext
                                requiredFeatures={[{name: ENTITY_FEATURES.CHARTS_EXPORT, compare: COMPARATORS.IS_TRUE}]}
                                renderOtherwise={
                                    <button
                                        type="button"
                                        className={`btn btn-xs btn-outline btn-default uitest-btn-pdf btn-non-free-account`}
                                        onClick={() => {}}
                                        disabled={true}
                                    >
                                        <NoPermissionLabel message={messages.permissions.features.noPdfExport} />
                                        <ArrowDown size={12}/> {t('topic_details_export_pdf')}
                                    </button>
                                }
                            >
                                <button
                                    type="button"
                                    className={`btn btn-xs btn-outline btn-default uitest-btn-pdf btn-non-free-account`}
                                    onClick={this.handleGeneratePDF}>
                                    <ArrowDown size={12}/> {t('topic_details_export_pdf')}
                                </button>
                            </FeaturesContext>
                            }
                            <FeaturesContext
                                requiredFeatures={[{name: ENTITY_FEATURES.PRESENTER_MODE, compare: COMPARATORS.IS_TRUE}]}
                                renderOtherwise={
                                    <button disabled={true} className="btn btn-xs btn-outline btn-default">
                                        <NoPermissionLabel message={messages.permissions.features.noPresentationMode} />
                                        <img src='/images/faces/activityIcon.svg' alt='' /> {t('topic_details_presentation_mode')}
                                    </button>
                                }
                            >
                                <Link to={`/topics/pmode/${this.props.topic.id}`} className="btn btn-xs btn-outline btn-default"><img src='/images/faces/activityIcon.svg' alt='' /> {t('topic_details_presentation_mode')}</Link>
                            </FeaturesContext>
                            <button type="button" className="btn btn-xs btn-outline btn-default" onClick={this.handleOpenLinksModal} disabled={!this.topicDataLoaded}><LinkIcon size={12} /> {t('topic_details_links_to_topic')}</button>
                            <PermissionsContext hideIfNoPermission={true} requiredPermissions={ [PERMISSIONS.EDIT_TOPICS] } >
                                <Link to={`/topics/edit/${this.props.topic.id}`} className="btn btn-xs btn-outline btn-default"><Edit size={12} /> {t('topic_details_edit_topic')}</Link>
                            </PermissionsContext>
                        </div>
                    </div>
                </div>

                <div ref={this.voterFiltersWrapperRef} className='votes-filters-wrapper'>
                    <Card inverse className={`${voterFiltersClassName}`}>
                        <div className='filter-votes-title-text'>
                            <label className="text-label" onClick={(e) => this.handleExpandVotesFiltering(e, false)}>
                                <Filter size={18} /> { voterFiltersExpanded ? t('topic_details_filter_voters_data_set') : ''}
                            </label>
                        </div>
    
                        <div className="actions btn-toolbar">
                            <FeaturesContext 
                                requiredFeatures={[{name: ENTITY_FEATURES.DATA_FILTERING, compare: COMPARATORS.IS_TRUE}]}
                                renderOtherwise={
                                    <button disabled={true} type="button" className="btn transparent pull-right" onClick={this.handleExpandVotesFiltering}>
                                        <NoPermissionLabel message={messages.permissions.features.noCustomSources} />
                                        <ChevronDown size={18} /> {t('topic_details_show_filters')}
                                    </button>
                                }
                            >
                                <button type="button" className="btn transparent pull-right" onClick={this.handleExpandVotesFiltering}>
                                    {voterFiltersExpanded 
                                        ? <ChevronUp size={18} /> 
                                        : this.isMobileView ? <ChevronDown size={18} /> : <span>{t('topic_details_show_filters')} <ChevronDown size={18} /></span> 
                                    }
                                </button>
                            </FeaturesContext>
                        </div>
    
                        <div className="filter-controls">
                            <div className={`votes-range ${voterFiltersExpanded ? '' : 'collapsed'}`}>
                                {this.getDateRangeSelector()}
                            </div>
    
                            {voterFiltersExpanded ?
                                <FeaturesContext 
                                    requiredFeatures={[{name: ENTITY_FEATURES.DATA_FILTERING, compare: COMPARATORS.IS_TRUE}]}
                                    hideIfNoPermission={true}
                                >
                                    <div className='votes-filters-details-container'>
                                        <VotesFilters className="filter-toggles clearfix row-fluid"
                                                      initialSelections={showVotesBy}
                                                      onVotesFilterChange={this.onVotesFilterChange}
                                                      sources={this.sourcesFromVotes}/>
                                    </div>
                                </FeaturesContext>
                                :
                                (this.isMobileView === false) && 
                                <div className='votes-filters-details-container' >
                                    <label className="text-label clearfix filter-toggles" onClick={(e) => this.handleExpandVotesFiltering(e, false)}>
                                        { this.hasAuthFiltersActive && 
                                            <span>
                                                <span className="text-light">{t('topic_details_including')}</span> {this.selectedAuthFiltersText} <br />
                                            </span>
                                        }
                                        <span>
                                            <span className="text-light">{t('topic_details_including')}</span> {this.selectedVoterTypeFiltersText}
                                        </span>
                                    </label>
                                </div>
                            }
                        </div>
                    </Card>
                </div>
                    
                <div className="topic-details content-with-stats">
                    <div className={`stats-container export-pdf-stats-container ${this.state.showOverviewMobile ? '' : 'hide-on-mobile'}`} id="statsContainer">
                        <HeaderStatsBar
                            latestVotes={latestVotesDesc}
                            stats={this.state.filteredVoteStats}
                            timeIntervalLabel={overallRatingCardTitleInterval}
                        />
                    </div>

                    <div className={`content-container ${showMobileClass}`}>
                        {this.childrenNavigation}

                        <Card pad={true} className="topics-charts-content clearfix">
                            {childrenScenes}
                        </Card>
                    </div>

                    <div className="navbar-mobile clearfix hide-on-desktop">
                        <div className="btn-group-footer">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.showDetails}> Details </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.showOverview}> Overview </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
  
 }

TopicDetailsPage.propTypes = {
	auth: PropTypes.shape({
        user: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string
        })
    }).isRequired,
    topic: PropTypes.object.isRequired,
    entity: PropTypes.object.isRequired,
    actions: PropTypes.shape({
        /** 
         * @prop {func} loadTopicById load topic data by id 
         * @param {number} topicId
         * */
        loadTopicById: PropTypes.func.isRequired,

        /** 
         * @prop {func} loadLatestVotesForTopic load latest topic votes data by topic id 
         * @param {number} topicId
         * */
        loadLatestVotesForTopic: PropTypes.func.isRequired,

        /** 
         * @prop {func} loadQuestionsStatsForTopic load the aggregated questions stats for the
         * topic by topicId, date range and voters filters
         * @param {number} topicId
         * @param {string} dateFrom
         * @param {string} dateTo
         * @param {object} filters
         * */
        loadQuestionsStatsForTopic: PropTypes.func.isRequired,

        /** 
         * @prop {func} loadTopicStructureById load the topic structure by topic id
         * @param {number} topicId
         * */
        loadTopicStructureById: PropTypes.func.isRequired,

        /** 
         * @prop {func} loadRawVoteDataForTopic load the topic raw votes by topic id and page+dateRange config 
         * @param {number} topicId
         * @param {object} filters
         * */
        loadRawVoteDataForTopic: PropTypes.func.isRequired,

        resetGraphDataForTopic: PropTypes.func.isRequired, 

        showLoading: PropTypes.func.isRequired,
        hideLoading: PropTypes.func.isRequired,
        addNotification: PropTypes.func.isRequired,
        showModal: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired
        
    }).isRequired,

    /* props derived from reduced state from redux store: */
    rawVotesForCurrentTopic: PropTypes.array,
    initialDateRange: PropTypes.object,
    children: PropTypes.node,
    params: PropTypes.object,
    location: PropTypes.shape({
		pathname: PropTypes.string
    }),
    routes: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    let topicId = ownProps.params.id;
    let topic = {};

    let entity = {};
    if (state.entities && state.entities.length > 0) {
        entity = state.entities[0];
    }

    if (state.topics.length > 0) {
        const matching = state.topics.filter(t => t.id == topicId); // eslint-disable-line
        if (matching.length) {
            topic = matching[0]; // since filter always returns an array
        }
    }
    
    let rawVotesForCurrentTopic = state.votesForTopics[topicId] || [];
       
    return {
        auth: state.auth,
        topic,
        entity,
        qnaStats: state.qnaForTopics[topicId] || {},
        rawVotesForCurrentTopic: rawVotesForCurrentTopic,
        initialDateRange: state.topicsTree.periodFilter
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({...topicActions, ...qnaActions, ...graphDataActions, ...modalActions, showLoading, hideLoading, addNotification}, dispatch)
    };
}


function _getFilteredVotesForCurrentTopic(votes, filters) {
    const areAllFiltersDisabled = Object.values(filters || {}).every(item => item === false);

    if (areAllFiltersDisabled) {
        return [];
    }

    let topicVotes = votes;

    if (filters.latestPerVoter) {
        const votersWithLatestVote = {};
        topicVotes.forEach((vote) => {
            if (votersWithLatestVote.hasOwnProperty(vote.voterUniqueId)) {
                const prevUnixTs = Math.round((new Date(votersWithLatestVote[vote.voterUniqueId].date)).getTime() / 1000);
                const currentUnixTs = Math.round((new Date(vote.date)).getTime() / 1000);

                if (prevUnixTs < currentUnixTs) {
                    votersWithLatestVote[vote.voterUniqueId] = vote;
                }
            } else {
                votersWithLatestVote[vote.voterUniqueId] = vote;
            }
        });

        topicVotes = Object.values(votersWithLatestVote);
    }
    
    return topicVotes.filter(vote => {
        let shouldShowVote = false;
        for (let key in filters) {
            // filters' keys are the same as the auth types
            if (key !== "latestPerVoter" && key!== "sources" && filters[key] === true && vote.authType === key) {
                shouldShowVote = true;
            }
        }
        
        let shouldShowVoteBySource = isEmpty(filters.sources);
        
        filters.sources.forEach(filterSource=>{
            if(vote.sources.includes(filterSource.name)){
                shouldShowVoteBySource = true;
            }
        });
        
        return shouldShowVote && shouldShowVoteBySource;
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicDetailsPage);
