import React from 'react';
import PropTypes from 'prop-types';
import './css/ratingBadge.css';

const RatingBadge = (props) => {
	const whole = props.vote.toString().split('.')[0];
	const part = props.vote.toString().split('.')[1];
	const colorClass = Math.ceil(props.vote);
	
	const avgVoteBadge = `rating-${colorClass} rating-badge ${props.className}`;

	const badgeStyle = props.size !== undefined ? {
		fontSize: props.size,
		width: props.size * 2 + Math.round(props.size / 4),
		height: props.size * 2 + Math.round(props.size / 4)
	} : {};


	return (
		<span className={avgVoteBadge} style={badgeStyle}>
			<span>{whole !== "10" ? `${whole}.` : whole}</span>
			{whole !== "10" &&
				<small>{part || "0"}</small>
			}
		</span>
	);
};

RatingBadge.propTypes = {
	/**
	 * @param {number} vote eg. 1.2
	 */
	vote: PropTypes.number.isRequired,
	
	className: PropTypes.string,

	/**
	 * @param {size} the Proved the size of the badge label in pixels and set via "style" attribute.
	 * The size of the circle will computed the number. If not provided, the size will be taken from default css.
	 */
	size: PropTypes.number
};

export default RatingBadge;