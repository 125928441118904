import * as types from '../actions/actionTypes';

const initState = {
	fileName: '',
	contents: null,
	isLoaded: false,
	isLoading: false,
	filesList: [],
	requestError: null
};

export default function filesReducer(state = initState, action) {
	switch (action.type) {
		case types.LOAD_FILES_LIST: {
			return Object.assign({}, state, {
				isLoaded: false,
				isLoading: true
			});
		}
		case types.LOAD_FILES_LIST_SUCCESS: {
			return Object.assign({}, state, {
				isLoaded: true,
				isLoading: false,
				filesList: action.payload.list,
				requestError: null
			});
		}
		case types.LOAD_FILES_LIST_ERROR: {
			return Object.assign({}, state, {
				isLoaded: true,
				isLoading: false,
				requestError: action.payload.requestError
			});
		}
		case types.FILE_LOADING: {
			return Object.assign({}, state, {
				isLoaded: false,
				isLoading: true,
				fileName: action.payload.fileName
			});
		}
		case types.FILE_LOADED_SUCCESS: {
			return Object.assign({}, state, {
				isLoaded: true,
				isLoading: false,
				fileName: action.payload.fileName,
				contents: action.payload.contents,
				requestError: null
			});
		}
		case types.FILE_LOADED_ERROR: {
			return Object.assign({}, state, {
				isLoaded: true,
				isLoading: false,
				fileName: action.payload.fileName,
				requestError: action.payload.requestError
			});
		}
		case types.FILE_EDIT_SUCCESS: {
			return Object.assign({}, state, {
				isLoaded: true,
				isLoading: false,
				fileName: action.payload.fileName,
				contents: action.payload.contents,
				requestError: null
			});
		}
		case types.FILE_EDIT_ERROR: {
			return Object.assign({}, state, {
				fileName: action.payload.fileName,
				requestError: action.payload.requestError
			});
		}
		case types.RESET_FILE_REQUEST_ERRORS: {
			return Object.assign({}, state, {
				requestError: null
			});
		}
		case types.FILE_SAVE_SUCCESS: {
			const { fileName } = action.payload;
			return Object.assign({}, state, {
				filesList: [...state.filesList, fileName],
				requestError: null
			});
		}
		case types.FILE_SAVE_ERROR: {
			return Object.assign({}, state, {
				fileName: action.payload.fileName,
				requestError: action.payload.error
			});
		}
		case types.FILE_DELETE_SUCCESS: {
			const { fileName } = action.payload;
			const newState = state.filesList.filter(file => file !== fileName);
			return Object.assign({}, state, { filesList: newState, requestError: null });
		}
		case types.FILE_DELETE_ERROR: {
			return Object.assign({}, state, {
				fileName: action.payload.fileName,
				requestError: action.payload.error
			});
		}
		default: {
			return state;
		}
	}
}
