import * as actionTypes from '../actions/actionTypes';
import { combineLiveVotesModels } from '../actions/models/voteModel';

const initState = [];
const DEFAULT_MAX_VOTES_PER_ENTITY = 20;

export default function entityVotesReducer(state = initState, action) {
	switch (action.type) {
		case actionTypes.RECEIVE_VOTE: {
			const { vote, topic } = action.payload;
			let newState = [...state];

			if (newState.length >= DEFAULT_MAX_VOTES_PER_ENTITY) {
				newState.splice(0, 1);
			}

			newState.push(combineLiveVotesModels(vote, topic));
			return newState;
		}
		default:
			return state;
	}
}