import React, { Component } from 'react';
import { XCircle, RefreshCcw, Edit, Eye, PlusCircle } from 'react-feather';
import PropTypes from 'prop-types';
import { Field, FieldArray, ErrorMessage } from 'formik';
import { isEmpty } from 'lodash';

import './css/basicTopicDetails.css';

import MediaDnD from '../common/form/MediaDnD';
import BSFormControl from '../common/form/bsFormControl';
import AutosuggestTagsSelector from '../common/form/autosuggestTagsSelector';
import { getValueByFieldPath, getVoterPreviewUrl } from '../../helpers/utils';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import { LINK_SOURCES_MAX_COUNT } from '../../helpers/topicBuilderHelper';
import FeaturesContext from "../../scenes/contexts/featuresContext";
import { ENTITY_FEATURES } from "hollerlive-shared/constants";
import { COMPARATORS } from "../../helpers/featuresHelper";
import messages from '../../helpers/messages';
import NoPermissionLabel from '../common/noPermissionLabel';
import i18n from '../../i18n';
import { processOGLinkImage } from '../../helpers/imageUtils';

export class LinkConfiguration extends Component {
	constructor(props, context) {
		super(props, context);
		const { linkConfig } = props.values;

		this.state = {
			imgPreview : linkConfig.linkImage,
			isPreviewEnabled: true,
			t: i18n.t.bind(i18n)
		};

		this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
		this.switchLinkConfigMode = this.switchLinkConfigMode.bind(this);
		this.handleSourceSelect = this.handleSourceSelect.bind(this);
		this.handleAddSourcesLinkClick = this.handleAddSourcesLinkClick.bind(this);
	}

	handleDeleteLinkSourcesClick(sourcesLinkId) {
		const { sources } = this.props.values.linkConfig;
		let configEntry = sources.find(sourceObj => sourceObj.id === sourcesLinkId);
		
		if (isEmpty(configEntry.value)) {
            this.handleConfirmDeleteLink(sourcesLinkId);
        } else {
            this.handleOpenConfirmDelete(configEntry);
        }
	}

	handleOpenConfirmDelete(linkConfig) {
		const t = this.state.t;
        const modalConfig = {
            title: t('modal_link_config_delete_title'),
			message: t('modal_link_config_delete_message', { voterPreviewUrl: getVoterPreviewUrl(this.props.values.basicSettings.title, linkConfig.value) }),
            onClose: () => this.context.hideModal(),
            onConfirm: () => this.handleConfirmDeleteLink(linkConfig.id),
            isConfirmModal: true
        };

        this.context.showModal(CONFIRM_MODAL, modalConfig);
	}
	
	handleConfirmDeleteLink(sourcesLinkId) {
        this.context.hideModal();
		const { values, setValues } = this.props;
		const { sources } = values.linkConfig;

		const newFormValuesState = Object.assign({}, values, {
			linkConfig: Object.assign({}, values.linkConfig, {
				sources: sources.filter(s => s.id !== sourcesLinkId)
			})
		});

		setValues(newFormValuesState);
	}

	handleAddSourcesLinkClick() {
		const { values, setValues, setFieldTouched } = this.props;
		const { sources } = values.linkConfig;

		let newId = 0;
		if (sources.length > 0) {
			const orederdSources = [...sources].sort((a, b) => Number(b.id) - Number(a.id));
			newId = Number(orederdSources[0].id) + 1;
		}

		const newLinkWithSources = {
			id: newId,
			value: ""
		};

		const newFormState = Object.assign({}, values, {
			linkConfig: Object.assign({}, values.linkConfig, {
				sources: [...sources, newLinkWithSources]
			})
		});

		setValues(newFormState);
		const fieldPath = `linkConfig.sources.${sources.length}.value`;
		setFieldTouched(fieldPath);
	}

	onImageError(setFieldError, reason) {
		setFieldError('linkConfig.linkImage', reason);
	}

	fieldHasErrors(errors = {}, field) {
		const fieldError = getValueByFieldPath(errors, field);
		return fieldError && fieldError.length > 0;
	}

	handleFieldValueChange(field, value) {
		this.props.setFieldValue(field, value);
	}

	switchLinkConfigMode(shouldEnablePreview) {
		this.setState({ isPreviewEnabled: shouldEnablePreview });
	}

	handleOpenConfirmReset() {
		const t = this.state.t;
		const modalConfig = {
			title: t('modal_link_config_reset_title'),
			message: t('modal_link_config_reset_message'),
			onClose: () => this.context.hideModal(),
			onConfirm: () => this.onConfirmReset(),
			isConfirmModal: true
		};
		this.context.showModal(CONFIRM_MODAL, modalConfig);
	}

	handleSourceSelect(sourcesLinkId, selectedSources, fieldPath) {
		const { setFieldValue, setFieldTouched } = this.props;

		const updatedLinkConfigValue = this.buildLinkConfigValueFromSources(selectedSources);
		
		setFieldValue(fieldPath, updatedLinkConfigValue);
		setFieldTouched(fieldPath, true, true);
	}

	onConfirmReset() {
		this.context.hideModal();
		this.handleFieldValueChange('linkConfig.linkDescription', '');
		this.clearImage();
	}

	clearImage() {
		this.handleFieldValueChange('linkConfig.linkImage', undefined);
		this.setState({ imgPreview: null });
	}

	buildLinkConfigValueFromSources(sourceValues) {
		return sourceValues.map(sourceValue => sourceValue.name.trim()).join(',');
	}

	_renderLinkConfig() {
		const {
			//backendErrors,
			errors,
			values,
			setFieldValue,
			setFieldError,
		} = this.props;

		const t = this.state.t;

		if (this.state.isPreviewEnabled === false) {
			return (
				<div>
					<p className="link-text-description">{t('topic_builder_link_config_upload_custom')}</p>

					{!this.props.values.basicSettings.id ?
						<p style={{ margin: '10px 0 30px 10px' }}>
							Снимка може да се прикачва към линкове за разпространение само на вече публикувана тема.
						</p> :
						<MediaDnD
							//mediaUrl={this.props.values.linkConfig.linkImage}
							mediaUrl={(() => {
								if(this.props.values.linkConfig.ogImageUrl)
									return this.props.values.linkConfig.ogImageUrl;
								else if(this.props.values.linkConfig.linkImage)
									return this.props.values.linkConfig.linkImage;
								return null;
							})()}
							uploadImageEndpoint={`/topics/${this.props.values.basicSettings.id}/upload_images/meta`}
							imageProcessor={processOGLinkImage}
							setFieldValue={setFieldValue}
							setFieldError={setFieldError}
							fieldNames={['linkConfig.ogImageUrl']}
						/>
					}

					<BSFormControl
						label={t('topic_builder_link_config_link_description_label')}
						subLabel={t('topic_builder_link_config_link_description_sublabel')}
						hasErrors={this.fieldHasErrors(errors, 'linkConfig.linkDescription')}
					>
						<Field type="text" name="linkConfig.linkDescription" className="form-control" placeholder={values.linkConfig.linkDescription} />
						<ErrorMessage name="linkConfig.linkDescription" component="span" className='control-label' />
					</BSFormControl>

					<div className='input-group-btn text-right'>
						<button type='button' className='btn btn-outline' onClick={() => { this.switchLinkConfigMode(true); }}>
							<Eye size='16' /> {t('topic_builder_link_config_link_preview_link')}
                </button>
					</div>
				</div>
			);
		}
	}

	getLinkSourceItems() {
		const { errors } = this.props;
		const { linkConfig, basicSettings } = this.props.values;
		const { sources } = linkConfig;
		const t = this.state.t;

		return sources.map((sourceObj, indexS) => {
			const selectionTagObj = sourceObj.value.split(',').map((sourceValue, indexSValue) => { 
				return { id: indexSValue, name: sourceValue || "" };
			});

			const fieldPath = `linkConfig.sources.${indexS}.value`;

			return <div key={indexS} className="form-group ordered-form-fields uitest-sources-fields">
				<div className="ordered-form-fields-item with-action">
					<div className="number">{indexS + 1}</div>

					<div className="form-control-container">
						<BSFormControl required hasErrors={this.fieldHasErrors(errors, fieldPath)}>
							<AutosuggestTagsSelector
								name={fieldPath}
								value={selectionTagObj}
								data={this.props.sources}
								onChange={(selectedSources) => this.handleSourceSelect(sourceObj.id, selectedSources, fieldPath)}
								className={`form-control uitest-sources-field`}
								maxSelections={LINK_SOURCES_MAX_COUNT}
							/>
							<ErrorMessage name={fieldPath} component="div" className='control-label' />
							<p className="message">{t('topic_builder_link_config_link_preview')}: <strong>{ getVoterPreviewUrl(basicSettings.title, sourceObj.value) }</strong></p>
						</BSFormControl>
					</div>

					<span className="action">
						<XCircle className="text-danger" onClick={() => this.handleDeleteLinkSourcesClick(sourceObj.id)} />
					</span>
				</div>
			</div>;
		});
	}

	_renderLinkPreview() {
		const { values } = this.props;
		const { linkConfig, basicSettings } = values;
		const isCustomized = linkConfig.linkImage || linkConfig.linkDescription;

		const t = this.state.t;

		if (this.state.isPreviewEnabled === true) {
			return (
				<div>
					<FeaturesContext 
					requiredFeatures={[{
						name: ENTITY_FEATURES.LINK_SHARING_CONFIG,
						compare: COMPARATORS.IS_TRUE
					}]}
					renderOtherwise={<p className="link-text-description">
						{t('topic_builder_link_config_description')}
					</p>}
					>
						<p className="link-text-description">
						{t('topic_builder_link_config_description')} {t('topic_builder_link_config_description_1')}
							<button className="btn-link" onClick={() => { this.switchLinkConfigMode(false); }}>{t('topic_builder_link_config_description_2')}</button>
							{t('topic_builder_link_config_description_3')}
						</p>
					</FeaturesContext>
					
					<div className='link-preview-wrapper'>
						<div className='link-preview'>
							<div ref={linkPreviewCaption => this.linkPreviewCaption = linkPreviewCaption}>
								<p><span className="text-primary">kazva.bg</span> - {basicSettings.displayName ? basicSettings.displayName : basicSettings.title} </p>
								<p>Виж какво казват другите спрямо твоето мнение с kazva.bg. Става за секунди и без инсталация.</p>
							</div>
							<div className="link-preview-img">
								<img className="img-responsive" src={linkConfig.linkImage || basicSettings.imageUrl} alt=""
									onLoad={() => this.linkPreviewCaption.className = 'link-preview-caption'} />
							</div>
						</div>
					</div>

					<div className='input-group-btn text-right right-childs-margin-half-vw'>
						{isCustomized &&
							<button type='button' className='btn btn-outline' onClick={() => this.handleOpenConfirmReset()}>
								<RefreshCcw size='16' /> {t('topic_builder_link_config_reset_to_default')}
							</button>
						}
						
						<FeaturesContext 
							requiredFeatures={[{
								name: ENTITY_FEATURES.LINK_SHARING_CONFIG,
								compare: COMPARATORS.IS_TRUE
							}]}
							renderOtherwise={
								<button type='button' disabled={true} className='btn btn-outline btn-primary' onClick={() => {}}>
	                                <NoPermissionLabel message={messages.permissions.features.noLinkShare} />
									<Edit size='16'/> {t('topic_builder_link_config_customize')}
								</button>
							}
						>
							
							<button type='button' className='btn btn-outline btn-primary'
									onClick={() => this.switchLinkConfigMode(false)}>
								<Edit size='16'/> {t('topic_builder_link_config_customize')}
							</button>
						</FeaturesContext>
					
					</div>
				</div>
			);
		}
	}

	render() {
		const t = this.state.t;
		return (
				<div className="link-configuration">
					<div className="link-config-preview">
						{this._renderLinkConfig()}
						{this._renderLinkPreview()}
					</div>
					
					<div className="links-with-sources">
						<h4>
							{t('topic_builder_link_config_sources_title')}
							<span className="subtitle">
								{t('topic_builder_link_config_sources_subtitle')}
								
							</span>
						</h4>
						
						<div className="sources-fields">
							<FieldArray
								name="linkConfig.sources"
								render={() => (
									<div className="row-fluid clearfix">
										{this.getLinkSourceItems()}
										
										<FeaturesContext 
											requiredFeatures={[{
												name: ENTITY_FEATURES.LINK_SOURCES_CONFIG,
												compare: COMPARATORS.IS_TRUE
											}]}
											renderOtherwise={
												<button
													className="btn btn-dotted btn-outline btn-success btn-full-width"
													onClick={() => {}}
													type="button"
													disabled={true} 
												>
                                    				<NoPermissionLabel message={messages.permissions.features.noCustomSources} />
													<PlusCircle size={16}/> {t('topic_builder_link_config_add_link_sources')}
												</button>
											}
										>
											
											<button
												className="btn btn-dotted btn-outline btn-success btn-full-width"
												onClick={this.handleAddSourcesLinkClick}
												type="button"
											>
												<PlusCircle size={16}/> {t('topic_builder_link_config_add_link_sources')}
											</button>
										
										</FeaturesContext>
									
									</div>
								)}
							/>
						</div>
					</div>
				</div>
		);
	}
}

LinkConfiguration.contextTypes = {
	showModal: PropTypes.func,
	hideModal: PropTypes.func
};

LinkConfiguration.propTypes = {
	values: PropTypes.shape({
		basicSettings: PropTypes.shape({
			title: PropTypes.string.isRequired,
			displayName: PropTypes.string.isRequired
		}).isRequired,
		linkConfig: PropTypes.shape({
			linkDescription: PropTypes.string,
			linkImage: PropTypes.string,
			sources: PropTypes.arrayOf(PropTypes.shape({
				id: PropTypes.number.isRequired,
				value: PropTypes.string.isRequired
			}))
		}).isRequired
	}).isRequired,
	sources: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		value: PropTypes.string
	})),
	backendErrors: PropTypes.shape({
		linkConfig: PropTypes.object
	}),
	errors: PropTypes.object,
	editMode: PropTypes.bool,
	setValues: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func,
	setFieldError: PropTypes.func.isRequired,
	isDirty: PropTypes.bool
};

export default LinkConfiguration;
