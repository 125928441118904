import * as types from '../actions/actionTypes';
import { isEmpty, cloneDeep } from 'lodash';

const initialTopicForEdit = {
    basicSettings: {},
    config: {},
    linkConfig: {},
    qna: [],
    postVoteSettings: {},
    greetings: [],
    translations: {},
    isLoading: false,
    errors: {},
    saved: false,
        isAsyncValidating: false
};

function resetQuestionsOrderIndex(questions) {
    questions.forEach((q, i) => {
        q.orderIndex = i;
    });
}

function checkOrderIndexValidity(questions) {
    let valid = true;

    if (!questions) {
        return valid;
    }
    
    let expectedOrderIndex = 0;
    questions.forEach((q) => {
        if (!q.hasOwnProperty('orderIndex')) {
            valid = false;
        } else {
            if (q.orderIndex !== expectedOrderIndex) {
                valid = false;
            } else {
                expectedOrderIndex++;
            }
        }
    });

    return valid;
}

function populdateQnAJumpToQuestionsEnabled(qna = []) {
    qna.forEach(q => {
        if (q.answers) {
            q.answers.forEach(a => {
                if (!a.hasOwnProperty('jumpToQuestionEnabled')) {
                    a.jumpToQuestionEnabled = !!a.nextQuestionId;
                }
            });
        }
    });
}

function mapQnaWithLinks(qna = []) {
    return qna.map(question => {
        question.answers.forEach(answer => {
            if (answer.link && answer.link.length > 0) {
                answer.isLink = true;
                return null;
            }
            answer.isLink = false;
            answer.link = '';
        });
        return question;
    });
}

export function topicBuilderReducer(state = initialTopicForEdit, action) {
    switch (action.type) {
        case types.LOAD_TOPIC_FOR_EDIT_REQUEST: {
            return Object.assign({}, state, {isLoading: true});
        }
        case types.LOAD_TOPIC_BY_HASH_SUCCESS: {
            action.payload.qna = mapQnaWithLinks(action.payload.qna);
            const orderIndexesValid = checkOrderIndexValidity(action.payload.qna);
            if (!orderIndexesValid) {
                resetQuestionsOrderIndex(action.payload.qna);
            }
            populdateQnAJumpToQuestionsEnabled(action.payload.qna);
            action.payload.saved = false;
            action.payload.isLoading = false;
            return action.payload;
        }
        case types.LOAD_TOPIC_FOR_TEMPLATE_SUCCESS: {
            const topicData = action.payload;
            action.payload.qna = mapQnaWithLinks(action.payload.qna);
            const orderIndexesValid = checkOrderIndexValidity(topicData.qna);
            if (!orderIndexesValid) {
                resetQuestionsOrderIndex(topicData.qna);
            }
            populdateQnAJumpToQuestionsEnabled(action.payload.qna);
            if (topicData.basicSettings.isRoot) {
                topicData.basicSettings.isRoot = false;
                const { id, title } = topicData.basicSettings;
                topicData.config.parent = { parentTopicId: id, parentTopicName: title };
            }
            topicData.basicSettings.title = '';
            topicData.basicSettings.displayName = '';
            topicData.saved = false;
            action.payload.isLoading = false;
            return topicData;
        }
        case types.RESET_TOPIC_FOR_EDIT: {
            return Object.assign({}, initialTopicForEdit);
        }
        case types.SAVETOPIC_ERROR: {
            return Object.assign({}, state, {
                errors: Object.assign({}, state.errors, action.payload),
                isLoading: false
            });
        }
        case types.SAVETOPIC_ERROR_RESET: {
            return Object.assign({}, state, {
                errors: {},
                isLoading: false
            });
        }
        case types.SAVETOPIC_SUCCESS: {
            return Object.assign({}, state, {
                errors: {},
                isLoading: false,
                saved: true,
                version: action.payload.version,
                createdTopic: {
                    id: action.payload.id,
                    topicName: action.payload.title,
                    topicDisplayName: action.payload.displayName,
                    linkConfig: action.payload.linkConfig
                }
            });
        }
        case types.UPDATETOPIC_SUCCESS: {
            const { modifiedOn, modifiedBy, version } = action.payload.topic;
            return Object.assign({}, state, {
                errors: {},
                isLoading: false,
                saved: true,
                modifiedOn,
                modifiedBy,
                version
            });
        }
        case types.HAS_VERSION_ERROR: {
			const { errors, errorsTarget } = action.payload;

			const newState = Object.assign({}, state, {
				isLoading: false
			});

			if (errorsTarget && errorsTarget.type === types.UPDATE_TOPIC_VERSION_ERROR) {
				newState.errors = Object.assign({}, state.errors, {
					version: errors
				});
			}

			return newState;
        }
        case types.SET_ASYNC_VALIDATING: {
            return Object.assign({}, state, {
                isAsyncValidating: true
            });
        }
        case types.RESET_ASYNC_VALIDATING: {
            return Object.assign({}, state, {
                isAsyncValidating: false
            });
        }
        case types.SET_TOPIC_NAME_ERROR: {
            const { errors } = action.payload;
            const newState = Object.assign({}, state);

            const basicSettingsErrors = (state.errors && !isEmpty(state.errors.basicSettings)) ? 
                Object.assign({}, state.errors.basicSettings) : 
                {};

            newState.errors = Object.assign({}, state.errors, {
                basicSettings: {
                    ...basicSettingsErrors,
                    title: errors.title
                }
            });
			
			return newState;
        }
        case types.CLEAR_TOPIC_NAME_ERROR: {
            const newState = Object.assign({}, cloneDeep(state));
            if (!isEmpty(newState.errors)) {
                if (!isEmpty(newState.errors.basicSettings)) {
                    delete newState.errors.basicSettings.title;
                }
                // if there is nothing else in basicSettings, delete key from errors object
                if (isEmpty(newState.errors.basicSettings)) {
                    delete newState.errors.basicSettings;
                }
            } 
			return newState;
        }
        default: {
            return state;
        }
    }
}
