import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from '../common/modal';
import getUserEditProfile from '../../scenes/login/UserEditProfile';
import { useTranslation } from 'react-i18next';

const userProfileEditModal = ({ onClose, onRedirectToChangePassword, width }) => {
	const UserEditProfile = getUserEditProfile();
	
	// refs as instance fields that don't cause re-render:
	const shouldRedirectToChangePasswordModal = useRef(false);
	const preventCloseModal = useRef(false);

	const { t } = useTranslation();
	
	const handleCloseModal = () => {
		if (!preventCloseModal.current || shouldRedirectToChangePasswordModal.current) {
			onClose();
		}
		if (shouldRedirectToChangePasswordModal.current) {
			onRedirectToChangePassword();
		}
	};
	const handleDiscardForm = () => {
		onClose();
		if (shouldRedirectToChangePasswordModal.current) {
			onRedirectToChangePassword();
		}
	};
	const handleFormChanged = (isDirty, shouldChangePasswordModalOpen) => {
		preventCloseModal.current = isDirty;
		shouldRedirectToChangePasswordModal.current = shouldChangePasswordModalOpen;
	};
	return (
		<Modal
			title={t('edit_profile_title')}
			onClose={handleCloseModal}
			hasFooter={false}
			width={width}
			enableOverlayClickClose={false}
		>
			<UserEditProfile
				onDiscard={handleDiscardForm}
				onChanged={handleFormChanged}
				className='uitest-usereditprofile' />
		</Modal>
	);
};

userProfileEditModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	onRedirectToChangePassword: PropTypes.func.isRequired,
	width: PropTypes.number
};
export default userProfileEditModal;
