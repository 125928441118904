import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import "./css/slider.css";

export class SliderSelector extends Component {
    constructor(props) {
        super(props);

        this.onAfterChange = this.onAfterChange.bind(this);
    }

    onAfterChange(value) {
        this.props.onAfterChange(value);
    }

    render() {
        return (
            <div className="slider-selector">
                <Slider
                    min={this.props.min}
                    max={this.props.max}
                    defaultValue={this.props.value}
                    onAfterChange={this.onAfterChange}
                    marks={this.props.marks}
                />
            </div>
        );
    }
}

SliderSelector.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onAfterChange: PropTypes.func.isRequired,
    marks: PropTypes.object
};

export default SliderSelector;