export const USER_ROLES = {
    superAdmin: 1,
    entityManager: 2,
    entityViewer: 3,
    topicEditor: 4
};

export const isCurrentRole = ( roleId, roleName ) => {
    return USER_ROLES[roleName] === roleId;
};

export const getRoleNameById = (roleId) => {
    const roleIds = Object.values(USER_ROLES);
    const roleNames = Object.keys(USER_ROLES);
    const roleIdIndex = roleIds.indexOf(roleId);

    switch (roleNames[roleIdIndex]) {
        case 'superAdmin':
            return "Super Admin";
        case 'entityManager':
            return "Organization Manager";
        case 'entityViewer':
            return "Organization Viewer";
        case 'topicEditor':
            return "Topic Editor";
        default: 
            return "unknown";
    }
};

export const ACTIVE_STATUSES = {
    ACTIVE_INACTIVE: "ACTIVE_INACTIVE",
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
};

export const ASSIGNED_STATUSES = {
    ASSIGNED_UNASSIGNED: "ASSIGNED_UNASSIGNED",
    ASSIGNED: "ASSIGNED",
    UNASSIGNED: "UNASSIGNED"
};

export const USER_ACTIVE_STATUS_OPTIONS = [
    {
        label: "ACTIVE AND INACTIVE",
        value: ACTIVE_STATUSES.ACTIVE_INACTIVE
    },
    {
        label: "ACTIVE USERS",
        value: ACTIVE_STATUSES.ACTIVE
    }, {
        label: "INACTIVE USERS",
        value: ACTIVE_STATUSES.INACTIVE
    }
];

export const USER_ASSIGNED_STATUS_OPTIONS = [
    {
        label: "ASSIGNED AND UNASSIGNED",
        value: ASSIGNED_STATUSES.ASSIGNED_UNASSIGNED
    },
    {
        label: "ASSIGNED USERS",
        value: ASSIGNED_STATUSES.ASSIGNED
    }, {
        label: "UNASSIGNED USERS",
        value: ASSIGNED_STATUSES.UNASSIGNED
    }
];

export const ALL_ENTITIES_OPTION = {
    label: 'ALL ORGANIZATIONS',
    value: 'ALL_ENTITIES'
};

export const ALL_ROLES_OPTION = {
    label: 'ALL ROLES',
    value: 'ALL_ROLES'
};

export const ALL_TIERS_OPTION = {
    label: 'ALL TIERS',
    value: 'ALL_TIERS'
};

export const CONSENTIO_ENTITY_ID = 1;

export const DEFAULT_FILTER_OPTIONS_SELECTION = {
	selectedEntityId: ALL_ENTITIES_OPTION.value,
	selectedRole: ALL_ROLES_OPTION.value,
	selectedActiveStatus: ACTIVE_STATUSES.ACTIVE_INACTIVE,
	selectedAssignedStatus: ASSIGNED_STATUSES.ASSIGNED_UNASSIGNED,
	selectedTier: ALL_TIERS_OPTION.value
};