export const LOCALE = [
    {id: 'af_NA', name: 'Afrikaans (Namibia)'},
    {id: 'af_ZA', name: 'Afrikaans (South Africa)'},
    {id: 'af', name: 'Afrikaans'},
    {id: 'ak_GH', name: 'Akan (Ghana)'},
    {id: 'ak', name: 'Akan'},
    {id: 'sq_AL', name: 'Albanian (Albania)'},
    {id: 'sq', name: 'Albanian'},
    {id: 'am_ET', name: 'Amharic (Ethiopia)'},
    {id: 'am', name: 'Amharic'},
    {id: 'ar_DZ', name: 'Arabic (Algeria)'},
    {id: 'ar_BH', name: 'Arabic (Bahrain)'},
    {id: 'ar_EG', name: 'Arabic (Egypt)'},
    {id: 'ar_IQ', name: 'Arabic (Iraq)'},
    {id: 'ar_JO', name: 'Arabic (Jordan)'},
    {id: 'ar_KW', name: 'Arabic (Kuwait)'},
    {id: 'ar_LB', name: 'Arabic (Lebanon)'},
    {id: 'ar_LY', name: 'Arabic (Libya)'},
    {id: 'ar_MA', name: 'Arabic (Morocco)'},
    {id: 'ar_OM', name: 'Arabic (Oman)'},
    {id: 'ar_QA', name: 'Arabic (Qatar)'},
    {id: 'ar_SA', name: 'Arabic (Saudi Arabia)'},
    {id: 'ar_SD', name: 'Arabic (Sudan)'},
    {id: 'ar_SY', name: 'Arabic (Syria)'},
    {id: 'ar_TN', name: 'Arabic (Tunisia)'},
    {id: 'ar_AE', name: 'Arabic (United Arab Emirates)'},
    {id: 'ar_YE', name: 'Arabic (Yemen)'},
    {id: 'ar', name: 'Arabic'},
    {id: 'hy_AM', name: 'Armenian (Armenia)'},
    {id: 'hy', name: 'Armenian'},
    {id: 'as_IN', name: 'Assamese (India)'},
    {id: 'as', name: 'Assamese'},
    {id: 'asa_TZ', name: 'Asu (Tanzania)'},
    {id: 'asa', name: 'Asu'},
    {id: 'az_Cyrl', name: 'Azerbaijani (Cyrillic)'},
    {id: 'az_Cyrl_AZ', name: 'Azerbaijani (Cyrillic, Azerbaijan)'},
    {id: 'az_Latn', name: 'Azerbaijani (Latin)'},
    {id: 'az_Latn_AZ', name: 'Azerbaijani (Latin, Azerbaijan)'},
    {id: 'az', name: 'Azerbaijani'},
    {id: 'bm_ML', name: 'Bambara (Mali)'},
    {id: 'bm', name: 'Bambara'},
    {id: 'eu_ES', name: 'Basque (Spain)'},
    {id: 'eu', name: 'Basque'},
    {id: 'be_BY', name: 'Belarusian (Belarus)'},
    {id: 'be', name: 'Belarusian'},
    {id: 'bem_ZM', name: 'Bemba (Zambia)'},
    {id: 'bem', name: 'Bemba'},
    {id: 'bez_TZ', name: 'Bena (Tanzania)'},
    {id: 'bez', name: 'Bena'},
    {id: 'bn_BD', name: 'Bengali (Bangladesh)'},
    {id: 'bn_IN', name: 'Bengali (India)'},
    {id: 'bn', name: 'Bengali'},
    {id: 'bs_BA', name: 'Bosnian (Bosnia and Herzegovina)'},
    {id: 'bs', name: 'Bosnian'},
    {id: 'bg_BG', name: 'Bulgarian (Bulgaria)'},
    {id: 'bg', name: 'Bulgarian'},
    {id: 'my_MM', name: 'Burmese (Myanmar [Burma])'},
    {id: 'my', name: 'Burmese'},
    {id: 'ca_ES', name: 'Catalan (Spain)'},
    {id: 'ca', name: 'Catalan'},
    {id: 'tzm_Latn', name: 'Central Morocco Tamazight (Latin)'},
    {id: 'tzm_Latn_MA', name: 'Central Morocco Tamazight (Latin, Morocco)'},
    {id: 'tzm', name: 'Central Morocco Tamazight'},
    {id: 'chr_US', name: 'Cherokee (United States)'},
    {id: 'chr', name: 'Cherokee'},
    {id: 'cgg_UG', name: 'Chiga (Uganda)'},
    {id: 'cgg', name: 'Chiga'},
    {id: 'zh_Hans', name: 'Chinese (Simplified Han)'},
    {id: 'zh_Hans_CN', name: 'Chinese (Simplified Han, China)'},
    {id: 'zh_Hans_HK', name: 'Chinese (Simplified Han, Hong Kong SAR China)'},
    {id: 'zh_Hans_MO', name: 'Chinese (Simplified Han, Macau SAR China)'},
    {id: 'zh_Hans_SG', name: 'Chinese (Simplified Han, Singapore)'},
    {id: 'zh_Hant', name: 'Chinese (Traditional Han)'},
    {id: 'zh_Hant_HK', name: 'Chinese (Traditional Han, Hong Kong SAR China)'},
    {id: 'zh_Hant_MO', name: 'Chinese (Traditional Han, Macau SAR China)'},
    {id: 'zh_Hant_TW', name: 'Chinese (Traditional Han, Taiwan)'},
    {id: 'zh', name: 'Chinese'},
    {id: 'kw_GB', name: 'Cornish (United Kingdom)'},
    {id: 'kw', name: 'Cornish'},
    {id: 'hr_HR', name: 'Croatian (Croatia)'},
    {id: 'hr', name: 'Croatian'},
    {id: 'cs_CZ', name: 'Czech (Czech Republic)'},
    {id: 'cs', name: 'Czech'},
    {id: 'da_DK', name: 'Danish (Denmark)'},
    {id: 'da', name: 'Danish'},
    {id: 'nl_BE', name: 'Dutch (Belgium)'},
    {id: 'nl_NL', name: 'Dutch (Netherlands)'},
    {id: 'nl', name: 'Dutch'},
    {id: 'ebu_KE', name: 'Embu (Kenya)'},
    {id: 'ebu', name: 'Embu'},
    {id: 'en_AS', name: 'English (American Samoa)'},
    {id: 'en_AU', name: 'English (Australia)'},
    {id: 'en_BE', name: 'English (Belgium)'},
    {id: 'en_BZ', name: 'English (Belize)'},
    {id: 'en_BW', name: 'English (Botswana)'},
    {id: 'en_CA', name: 'English (Canada)'},
    {id: 'en_GU', name: 'English (Guam)'},
    {id: 'en_HK', name: 'English (Hong Kong SAR China)'},
    {id: 'en_IN', name: 'English (India)'},
    {id: 'en_IE', name: 'English (Ireland)'},
    {id: 'en_JM', name: 'English (Jamaica)'},
    {id: 'en_MT', name: 'English (Malta)'},
    {id: 'en_MH', name: 'English (Marshall Islands)'},
    {id: 'en_MU', name: 'English (Mauritius)'},
    {id: 'en_NA', name: 'English (Namibia)'},
    {id: 'en_NZ', name: 'English (New Zealand)'},
    {id: 'en_MP', name: 'English (Northern Mariana Islands)'},
    {id: 'en_PK', name: 'English (Pakistan)'},
    {id: 'n_PH', name: 'English (Philippines)'},
    {id: 'en_SG', name: 'English (Singapore)'},
    {id: 'en_ZA', name: 'English (South Africa)'},
    {id: 'en_TT', name: 'English (Trinidad and Tobago)'},
    {id: 'en_UM', name: 'English (U.S. Minor Outlying Islands)'},
    {id: 'en_VI', name: 'English (U.S. Virgin Islands)'},
    {id: 'en_GB', name: 'English (United Kingdom)'},
    {id: 'en_US', name: 'English (United States)'},
    {id: 'en_ZW', name: 'English (Zimbabwe)'},
    {id: 'en', name: 'English'},
    {id: 'eo', name: 'Esperanto'},
    {id: 'et_EE', name: 'Estonian (Estonia)'},
    {id: 'et', name: 'Estonian'},
    {id: 'ee_GH', name: 'Ewe (Ghana)'},
    {id: 'ee_TG', name: 'Ewe (Togo)'},
    {id: 'ee', name: 'Ewe'},
    {id: 'fo_FO', name: 'Faroese (Faroe Islands)'},
    {id: 'fo', name: 'Faroese'},
    {id: 'fil_PH', name: 'Filipino (Philippines)'},
    {id: 'fil', name: 'Filipino'},
    {id: 'fi_FI', name: 'Finnish (Finland)'},
    {id: 'fi', name: 'Finnish'},
    {id: 'fr_BE', name: 'French (Belgium)'},
    {id: 'fr_BJ', name: 'French (Benin)'},
    {id: 'fr_BF', name: 'French (Burkina Faso)'},
    {id: 'fr_BI', name: 'French (Burundi)'},
    {id: 'fr_CM', name: 'French (Cameroon)'},
    {id: 'fr_CA', name: 'French (Canada)'},
    {id: 'fr_CF', name: 'French (Central African Republic)'},
    {id: 'fr_TD', name: 'French (Chad)'},
    {id: 'fr_KM', name: 'French (Comoros)'},
    {id: 'fr_CG', name: 'French (Congo - Brazzaville)'},
    {id: 'fr_CD', name: 'French (Congo - Kinshasa)'},
    {id: 'fr_CI', name: 'French (Côte d’Ivoire)'},
    {id: 'fr_DJ', name: 'French (Djibouti)'},
    {id: 'fr_GQ', name: 'French (Equatorial Guinea)'},
    {id: 'fr_FR', name: 'French (France)'},
    {id: 'fr_GA', name: 'French (Gabon)'},
    {id: 'fr_GP', name: 'French (Guadeloupe)'},
    {id: 'fr_GN', name: 'French (Guinea)'},
    {id: 'fr_LU', name: 'French (Luxembourg)'},
    {id: 'fr_MG', name: 'French (Madagascar)'},
    {id: 'fr_ML', name: 'French (Mali)'},
    {id: 'fr_MQ', name: 'French (Martinique)'},
    {id: 'fr_MC', name: 'French (Monaco)'},
    {id: 'fr_NE', name: 'French (Niger)'},
    {id: 'fr_RW', name: 'French (Rwanda)'},
    {id: 'fr_RE', name: 'French (Réunion)'},
    {id: 'fr_BL', name: 'French (Saint Barthélemy)'},
    {id: 'fr_MF', name: 'French (Saint Martin)'},
    {id: 'fr_SN', name: 'French (Senegal)'},
    {id: 'fr_CH', name: 'French (Switzerland)'},
    {id: 'fr_TG', name: 'French (Togo)'},
    {id: 'fr', name: 'French'},
    {id: 'ff_SN', name: 'Fulah (Senegal)'},
    {id: 'ff', name: 'Fulah'},
    {id: 'gl_ES', name: 'Galician (Spain)'},
    {id: 'gl', name: 'Galician'},
    {id: 'lg_UG', name: 'Ganda (Uganda)'},
    {id: 'lg', name: 'Ganda'},
    {id: 'ka_GE', name: 'Georgian (Georgia)'},
    {id: 'ka', name: 'Georgian'},
    {id: 'de_AT', name: 'German (Austria)'},
    {id: 'de_BE', name: 'German (Belgium)'},
    {id: 'de_DE', name: 'German (Germany)'},
    {id: 'de_LI', name: 'German (Liechtenstein)'},
    {id: 'de_LU', name: 'German (Luxembourg)'},
    {id: 'de_CH', name: 'German (Switzerland)'},
    {id: 'de', name: 'German'},
    {id: 'el_CY', name: 'Greek (Cyprus)'},
    {id: 'el_GR', name: 'Greek (Greece)'},
    {id: 'el', name: 'Greek'},
    {id: 'gu_IN', name: 'Gujarati (India)'},
    {id: 'gu', name: 'Gujarati'},
    {id: 'guz_KE', name: 'Gusii (Kenya)'},
    {id: 'guz', name: 'Gusii'},
    {id: 'ha_Latn', name: 'Hausa (Latin)'},
    {id: 'ha_Latn_GH', name: 'Hausa (Latin, Ghana)'},
    {id: 'ha_Latn_NE', name: 'Hausa (Latin, Niger)'},
    {id: 'ha_Latn_NG', name: 'Hausa (Latin, Nigeria)'},
    {id: 'ha', name: 'Hausa'},
    {id: 'haw_US', name: 'Hawaiian (United States)'},
    {id: 'haw', name: 'Hawaiian'},
    {id: 'he_IL', name: 'Hebrew (Israel)'},
    {id: 'he', name: 'Hebrew'},
    {id: 'hi_IN', name: 'Hindi (India)'},
    {id: 'hi', name: 'Hindi'},
    {id: 'hu_HU', name: 'Hungarian (Hungary)'},
    {id: 'hu', name: 'Hungarian'},
    {id: 'is_IS', name: 'Icelandic (Iceland)'},
    {id: 'is', name: 'Icelandic'},
    {id: 'ig_NG', name: 'Igbo (Nigeria)'},
    {id: 'ig', name: 'Igbo'},
    {id: 'id_ID', name: 'Indonesian (Indonesia)'},
    {id: 'id', name: 'Indonesian'},
    {id: 'ga_IE', name: 'Irish (Ireland)'},
    {id: 'ga', name: 'Irish'},
    {id: 'it_IT', name: 'Italian (Italy)'},
    {id: 'it_CH', name: 'Italian (Switzerland)'},
    {id: 'it', name: 'Italian'},
    {id: 'ja_JP', name: 'Japanese (Japan)'},
    {id: 'ja', name: 'Japanese'},
    {id: 'kea_CV', name: 'Kabuverdianu (Cape Verde)'},
    {id: 'kea', name: 'Kabuverdianu'},
    {id: 'kab_DZ', name: 'Kabyle (Algeria)'},
    {id: 'kab', name: 'Kabyle'},
    {id: 'kl_GL', name: 'Kalaallisut (Greenland)'},
    {id: 'kl', name: 'Kalaallisut'},
    {id: 'kln_KE', name: 'Kalenjin (Kenya)'},
    {id: 'kln', name: 'Kalenjin'},
    {id: 'kam_KE', name: 'Kamba (Kenya)'},
    {id: 'kam', name: 'Kamba'},
    {id: 'kn_IN', name: 'Kannada (India)'},
    {id: 'kn', name: 'Kannada'},
    {id: 'kk_Cyrl', name: 'Kazakh (Cyrillic)'},
    {id: 'kk_Cyrl_KZ', name: 'Kazakh (Cyrillic, Kazakhstan)'},
    {id: 'kk', name: 'Kazakh'},
    {id: 'km_KH', name: 'Khmer (Cambodia)'},
    {id: 'km', name: 'Khmer'},
    {id: 'ki_KE', name: 'Kikuyu (Kenya)'},
    {id: 'ki', name: 'Kikuyu'},
    {id: 'rw_RW', name: 'Kinyarwanda (Rwanda)'},
    {id: 'rw', name: 'Kinyarwanda'},
    {id: 'kok_IN', name: 'Konkani (India)'},
    {id: 'kok', name: 'Konkani'},
    {id: 'ko_KR', name: 'Korean (South Korea)'},
    {id: 'ko', name: 'Korean'},
    {id: 'khq_ML', name: 'Koyra Chiini (Mali)'},
    {id: 'khq', name: 'Koyra Chiini'},
    {id: 'ses_ML', name: 'Koyraboro Senni (Mali)'},
    {id: 'ses', name: 'Koyraboro Senni'},
    {id: 'lag_TZ', name: 'Langi (Tanzania)'},
    {id: 'lag', name: 'Langi'},
    {id: 'lv_LV', name: 'Latvian (Latvia)'},
    {id: 'lv', name: 'Latvian'},
    {id: 'lt_LT', name: 'Lithuanian (Lithuania)'},
    {id: 'lt', name: 'Lithuanian'},
    {id: 'luo_KE', name: 'Luo (Kenya)'},
    {id: 'luo', name: 'Luo'},
    {id: 'luy_KE', name: 'Luyia (Kenya)'},
    {id: 'luy', name: 'Luyia'},
    {id: 'mk_MK', name: 'Macedonian (Macedonia)'},
    {id: 'mk', name: 'Macedonian'},
    {id: 'jmc_TZ', name: 'Machame (Tanzania)'},
    {id: 'jmc', name: 'Machame'},
    {id: 'kde_TZ', name: 'Makonde (Tanzania)'},
    {id: 'kde', name: 'Makonde'},
    {id: 'mg_MG', name: 'Malagasy (Madagascar)'},
    {id: 'mg', name: 'Malagasy'},
    {id: 'ms_BN', name: 'Malay (Brunei)'},
    {id: 'ms_MY', name: 'Malay (Malaysia)'},
    {id: 'ms', name: 'Malay'},
    {id: 'ml_IN', name: 'Malayalam (India)'},
    {id: 'ml', name: 'Malayalam'},
    {id: 'mt_MT', name: 'Maltese (Malta)'},
    {id: 'mt', name: 'Maltese'},
    {id: 'gv_GB', name: 'Manx (United Kingdom)'},
    {id: 'gv', name: 'Manx'},
    {id: 'mr_IN', name: 'Marathi (India)'},
    {id: 'mr', name: 'Marathi'},
    {id: 'mas_KE', name: 'Masai (Kenya)'},
    {id: 'mas_TZ', name: 'Masai (Tanzania)'},
    {id: 'mas', name: 'Masai'},
    {id: 'mer_KE', name: 'Meru (Kenya)'},
    {id: 'mer', name: 'Meru'},
    {id: 'mfe_MU', name: 'Morisyen (Mauritius)'},
    {id: 'mfe', name: 'Morisyen'},
    {id: 'naq_NA', name: 'Nama (Namibia)'},
    {id: 'naq', name: 'Nama'},
    {id: 'ne_IN', name: 'Nepali (India)'},
    {id: 'ne_NP', name: 'Nepali (Nepal)'},
    {id: 'ne', name: 'Nepali'},
    {id: 'nd_ZW', name: 'North Ndebele (Zimbabwe)'},
    {id: 'nd', name: 'North Ndebele'},
    {id: 'nb_NO', name: 'Norwegian Bokmål (Norway)'},
    {id: 'nb', name: 'Norwegian Bokmål'},
    {id: 'nn_NO', name: 'Norwegian Nynorsk (Norway)'},
    {id: 'nn', name: 'Norwegian Nynorsk'},
    {id: 'nyn_UG', name: 'Nyankole (Uganda)'},
    {id: 'nyn', name: 'Nyankole'},
    {id: 'or_IN', name: 'Oriya (India)'},
    {id: 'or', name: 'Oriya'},
    {id: 'om_ET', name: 'Oromo (Ethiopia)'},
    {id: 'om_KE', name: 'Oromo (Kenya)'},
    {id: 'om', name: 'Oromo'},
    {id: 'ps_AF', name: 'Pashto (Afghanistan)'},
    {id: 'ps', name: 'Pashto'},
    {id: 'fa_AF', name: 'Persian (Afghanistan)'},
    {id: 'fa_IR', name: 'Persian (Iran)'},
    {id: 'fa', name: 'Persian'},
    {id: 'pl_PL', name: 'Polish (Poland)'},
    {id: 'pl', name: 'Polish'},
    {id: 'pt_BR', name: 'Portuguese (Brazil)'},
    {id: 'pt_GW', name: 'Portuguese (Guinea-Bissau)'},
    {id: 'pt_MZ', name: 'Portuguese (Mozambique)'},
    {id: 'pt_PT', name: 'Portuguese (Portugal)'},
    {id: 'pt', name: 'Portuguese'},
    {id: 'pa_Arab', name: 'Punjabi (Arabic)'},
    {id: 'pa_Arab_PK', name: 'Punjabi (Arabic, Pakistan)'},
    {id: 'pa_Guru', name: 'Punjabi (Gurmukhi)'},
    {id: 'pa_Guru_IN', name: 'Punjabi (Gurmukhi, India)'},
    {id: 'pa', name: 'Punjabi'},
    {id: 'ro_MD', name: 'Romanian (Moldova)'},
    {id: 'ro_RO', name: 'Romanian (Romania)'},
    {id: 'ro', name: 'Romanian'},
    {id: 'rm_CH', name: 'Romansh (Switzerland)'},
    {id: 'rm', name: 'Romansh'},
    {id: 'rof_TZ', name: 'Rombo (Tanzania)'},
    {id: 'rof', name: 'Rombo'},
    {id: 'ru_MD', name: 'Russian (Moldova)'},
    {id: 'ru_RU', name: 'Russian (Russia)'},
    {id: 'ru_UA', name: 'Russian (Ukraine)'},
    {id: 'ru', name: 'Russian'},
    {id: 'rwk_TZ', name: 'Rwa (Tanzania)'},
    {id: 'rwk', name: 'Rwa'},
    {id: 'saq_KE', name: 'Samburu (Kenya)'},
    {id: 'saq', name: 'Samburu'},
    {id: 'sg_CF', name: 'Sango (Central African Republic)'},
    {id: 'sg', name: 'Sango'},
    {id: 'seh_MZ', name: 'Sena (Mozambique)'},
    {id: 'seh', name: 'Sena'},
    {id: 'sr_Cyrl', name: 'Serbian (Cyrillic)'},
    {id: 'sr_Cyrl_BA', name: 'Serbian (Cyrillic, Bosnia and Herzegovina)'},
    {id: 'sr_Cyrl_ME', name: 'Serbian (Cyrillic, Montenegro)'},
    {id: 'sr_Cyrl_RS', name: 'Serbian (Cyrillic, Serbia)'},
    {id: 'sr_Latn', name: 'Serbian (Latin)'},
    {id: 'sr_Latn_BA', name: 'Serbian (Latin, Bosnia and Herzegovina)'},
    {id: 'sr_Latn_ME', name: 'Serbian (Latin, Montenegro)'},
    {id: 'sr_Latn_RS', name: 'Serbian (Latin, Serbia)'},
    {id: 'sr', name: 'Serbian'},
    {id: 'sn_ZW', name: 'Shona (Zimbabwe)'},
    {id: 'sn', name: 'Shona'},
    {id: 'ii_CN', name: 'Sichuan Yi (China)'},
    {id: 'ii', name: 'Sichuan Yi'},
    {id: 'si_LK', name: 'Sinhala (Sri Lanka)'},
    {id: 'si', name: 'Sinhala'},
    {id: 'sk_SK', name: 'Slovak (Slovakia)'},
    {id: 'sk', name: 'Slovak'},
    {id: 'sl_SI', name: 'Slovenian (Slovenia)'},
    {id: 'sl', name: 'Slovenian'},
    {id: 'xog_UG', name: 'Soga (Uganda)'},
    {id: 'xog', name: 'Soga'},
    {id: 'so_DJ', name: 'Somali (Djibouti)'},
    {id: 'so_ET', name: 'Somali (Ethiopia)'},
    {id: 'so_KE', name: 'Somali (Kenya)'},
    {id: 'so_SO', name: 'Somali (Somalia)'},
    {id: 'so', name: 'Somali'},
    {id: 'es_AR', name: 'Spanish (Argentina)'},
    {id: 'es_BO', name: 'Spanish (Bolivia)'},
    {id: 'es_CL', name: 'Spanish (Chile)'},
    {id: 'es_CO', name: 'Spanish (Colombia)'},
    {id: 'es_CR', name: 'Spanish (Costa Rica)'},
    {id: 'es_DO', name: 'Spanish (Dominican Republic)'},
    {id: 'es_EC', name: 'Spanish (Ecuador)'},
    {id: 'es_SV', name: 'Spanish (El Salvador)'},
    {id: 'es_GQ', name: 'Spanish (Equatorial Guinea)'},
    {id: 'es_GT', name: 'Spanish (Guatemala)'},
    {id: 'es_HN', name: 'Spanish (Honduras)'},
    {id: 'es_419', name: 'Spanish (Latin America)'},
    {id: 'es_MX', name: 'Spanish (Mexico)'},
    {id: 'es_NI', name: 'Spanish (Nicaragua)'},
    {id: 'es_PA', name: 'Spanish (Panama)'},
    {id: 'es_PY', name: 'Spanish (Paraguay)'},
    {id: 'es_PE', name: 'Spanish (Peru)'},
    {id: 'es_PR', name: 'Spanish (Puerto Rico)'},
    {id: 'es_ES', name: 'Spanish (Spain)'},
    {id: 'es_US', name: 'Spanish (United States)'},
    {id: 'es_UY', name: 'Spanish (Uruguay)'},
    {id: 'es_VE', name: 'Spanish (Venezuela)'},
    {id: 'es', name: 'Spanish'},
    {id: 'sw_KE', name: 'Swahili (Kenya)'},
    {id: 'sw_TZ', name: 'Swahili (Tanzania)'},
    {id: 'sw', name: 'Swahili'},
    {id: 'sv_FI', name: 'Swedish (Finland)'},
    {id: 'sv_SE', name: 'Swedish (Sweden)'},
    {id: 'sv', name: 'Swedish'},
    {id: 'gsw_CH', name: 'Swiss German (Switzerland)'},
    {id: 'gsw', name: 'Swiss German'},
    {id: 'shi_Latn', name: 'Tachelhit (Latin)'},
    {id: 'shi_Latn_MA', name: 'Tachelhit (Latin, Morocco)'},
    {id: 'shi_Tfng', name: 'Tachelhit (Tifinagh)'},
    {id: 'shi_Tfng_MA', name: 'Tachelhit (Tifinagh, Morocco)'},
    {id: 'shi', name: 'Tachelhit'},
    {id: 'dav_KE', name: 'Taita (Kenya)'},
    {id: 'dav', name: 'Taita'},
    {id: 'ta_IN', name: 'Tamil (India)'},
    {id: 'ta_LK', name: 'Tamil (Sri Lanka)'},
    {id: 'ta', name: 'Tamil'},
    {id: 'te_IN', name: 'Telugu (India)'},
    {id: 'te', name: 'Telugu'},
    {id: 'teo_KE', name: 'Teso (Kenya)'},
    {id: 'teo_UG', name: 'Teso (Uganda)'},
    {id: 'teo', name: 'Teso'},
    {id: 'th_TH', name: 'Thai (Thailand)'},
    {id: 'th', name: 'Thai'},
    {id: 'bo_CN', name: 'Tibetan (China)'},
    {id: 'bo_IN', name: 'Tibetan (India)'},
    {id: 'bo', name: 'Tibetan'},
    {id: 'ti_ER', name: 'Tigrinya (Eritrea)'},
    {id: 'ti_ET', name: 'Tigrinya (Ethiopia)'},
    {id: 'ti', name: 'Tigrinya'},
    {id: 'to_TO', name: 'Tonga (Tonga)'},
    {id: 'to', name: 'Tonga'},
    {id: 'tr_TR', name: 'Turkish (Turkey)'},
    {id: 'tr', name: 'Turkish'},
    {id: 'uk_UA', name: 'Ukrainian (Ukraine)'},
    {id: 'uk', name: 'Ukrainian'},
    {id: 'ur_IN', name: 'Urdu (India)'},
    {id: 'ur_PK', name: 'Urdu (Pakistan)'},
    {id: 'ur', name: 'Urdu'},
    {id: 'uz_Arab', name: 'Uzbek (Arabic)'},
    {id: 'uz_Arab_AF', name: 'Uzbek (Arabic, Afghanistan)'},
    {id: 'uz_Cyrl', name: 'Uzbek (Cyrillic)'},
    {id: 'uz_Cyrl_UZ', name: 'Uzbek (Cyrillic, Uzbekistan)'},
    {id: 'uz_Latn', name: 'Uzbek (Latin)'},
    {id: 'uz_Latn_UZ', name: 'Uzbek (Latin, Uzbekistan)'},
    {id: 'uz', name: 'Uzbek'},
    {id: 'vi_VN', name: 'Vietnamese (Vietnam)'},
    {id: 'vi', name: 'Vietnamese'},
    {id: 'vun_TZ', name: 'Vunjo (Tanzania)'},
    {id: 'vun', name: 'Vunjo'},
    {id: 'cy_GB', name: 'Welsh (United Kingdom)'},
    {id: 'cy', name: 'Welsh'},
    {id: 'yo_NG', name: 'Yoruba (Nigeria)'},
    {id: 'yo', name: 'Yoruba'},
    {id: 'zu_ZA', name: 'Zulu (South Africa)'},
    {id: 'zu', name: 'Zulu'}
];