import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GroupByVoteItem from './groupByVoteItem';
import VoteTimelineItem from '../../../../votes/timeline/VoteTimelineItem';

import moment from 'moment';
import { isEmpty } from 'lodash';
import { Clock, ChevronDown, ChevronUp } from 'react-feather';
import i18n from '../../../../../i18n';

export class GroupByVote extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isCollapsed: false,
			isCollapsedAllOn: props.isCollapsedAllOn,
			t: i18n.t.bind(i18n)
		};

		this.handleExpandCollapseClick = this.handleExpandCollapseClick.bind(this);
		this.getVoteData = this.getVoteData.bind(this);
		this.renderGroupByVoteItem = this.renderGroupByVoteItem.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.isCollapsedAllOn !== prevState.isCollapsedAllOn) {
			return {
				isCollapsed: nextProps.isCollapsedAllOn,
				isCollapsedAllOn: nextProps.isCollapsedAllOn
			};
		}

		return null;
	}

	handleExpandCollapseClick(){
		this.setState({ isCollapsed: !this.state.isCollapsed });
	}

	mapVoteData() {
		const mappedVoteData = {
			date: this.getVoteData('date'),
			city: this.getVoteData('city'),
			country: this.getVoteData('countryCode'),
			state: this.getVoteData('state'),
			vote: this.props.voteWithQna.vote,
			gender: this.getVoteData('gender'),
			ageGroup: this.getVoteData('ageGroup'),
			sources: this.getVoteData('sources', [])
		};

		return mappedVoteData;
	}

	getVoteData(requestedData, defaultValue = "") {
		const { voteWithQna } = this.props;

		if (voteWithQna) {
			const hasVoteItem = !isEmpty(voteWithQna[requestedData]);
			
			if( hasVoteItem ) {
				return voteWithQna[requestedData];
			} else {
				return defaultValue;
			}
		} else {
			return defaultValue;
		}
	}

	get visibleAnswers() {
		if (this.props.hideDeprecatedQuestions) {
			return (this.props.voteWithQna.qna || []).filter(a => !a.deprecated);
		}

		return this.props.voteWithQna.qna;
	}

	renderGroupByVoteItem(answerItems) {
		const { questions, searchTerm } = this.props;

		const answersWithQuestionIndex = (answerItems || []).map(( answer ) => {
			const questionItem = questions.find(q => q.question === answer.question);
			let answerData = answer;
			
			if (questionItem) {
				const questionIndex = questionItem.orderIndex;
				answerData = Object.assign({}, answer, { questionIndex: questionIndex });
			}
			
			return answerData;
		});

		answersWithQuestionIndex.sort((a, b) => a.questionIndex - b.questionIndex);

		return answersWithQuestionIndex.map((answer, index) => {
			const highlightedAnswer = Object.assign({}, answer, {
				answer: searchTerm ? this.answerWithHighligtedSearch(searchTerm, answer.answer) : answer.answer,
				question: searchTerm ? this.answerWithHighligtedSearch(searchTerm, answer.question) : answer.question
			});
			return <GroupByVoteItem key={index} answerItem={highlightedAnswer} />;
		});
	}

	answerWithHighligtedSearch(searchTerm, text) {
		if (!searchTerm || !text) {
			return text;
		}

		const reg = new RegExp(`(${searchTerm})`, 'gi');
		const textParts = text.split(reg);
			
		return textParts.map((part, index) => (part.match(reg) ? <span key={index} className='search-text-highlight'>{part}</span> : part));
	}

	get hasAnswersToDisplay() {
		const { voteWithQna } = this.props;
		return voteWithQna.qna.length > 0 && this.visibleAnswers.length > 0;
	}

	get isQnaEmpty() {
		const { voteWithQna } = this.props;
		return voteWithQna.qna && voteWithQna.qna.length === 0;
	}

	renderQnaMetaLabel(message) {
		return <p className="text-right">{ message }</p>;
	}

	render() {
		const { stats, searchTerm } = this.props;
		const voteData = this.mapVoteData();
		const totalTimeToAnswer = moment.duration(stats.totalTime).asSeconds().toFixed(2);
		const avgTimeToAnswer = moment.duration(stats.avgTime).asSeconds().toFixed(2);

		const answersToDisplay = this.visibleAnswers;

		const t = this.state.t;

		return (
			<div className="group-by-vote-container">
				<div className={`group-item group-head-item ${!this.hasAnswersToDisplay && "empty-qna"} ${this.state.isCollapsed ? 'collapsed' : 'expanded'}`} onClick={this.handleExpandCollapseClick}>
					{ this.hasAnswersToDisplay && <button type="button" className="btn transparent expand-collapse">
						{this.state.isCollapsed 
							? <ChevronDown size="18" />
							: <ChevronUp size="18" />}
					</button> }

					<div className="text-left group-head-left">	
						<VoteTimelineItem vote={voteData} searchTerm={searchTerm} />
					</div>

					<div className="meta text-label text-right text-light">
						{ this.hasAnswersToDisplay ?
							<p className="question-item-answer-text">
								<span className="text-light">{t('topic_vote_timeline_answered')} </span><span className="text-black">{ stats.questionsAnsweredCount } </span><span className="text-light">{t('topic_vote_timeline_questions')} </span>
								<span className="text-light">{t('topic_vote_timeline_took')} </span><span className="text-black">{ totalTimeToAnswer }<small> {t('topic_vote_timeline_sec')} </small></span>
								<span className="text-light">{t('topic_vote_timeline_answering_time')} </span>
								<span className="text-label text-right text-light">
									<Clock size={12} /> { avgTimeToAnswer }<small> {t('topic_vote_timeline_sec')}</small>
								</span>
							</p>
						:
							this.renderQnaMetaLabel(this.isQnaEmpty ? t('messages_topics_no_qna_registered') : t('messages_topics_no_qna_visible'))
						}
					</div>
				</div>
				
				{ this.hasAnswersToDisplay && !this.state.isCollapsed && this.renderGroupByVoteItem(answersToDisplay) }
			</div>
		);
	}
}

GroupByVote.propTypes = {
	voteWithQna: PropTypes.shape({
		id: PropTypes.number,
		voterUniqueId: PropTypes.string,
		vote: PropTypes.number,
		gender: PropTypes.string,
		ageGroup: PropTypes.string,
		date: PropTypes.string,
		city: PropTypes.string,
		sources: PropTypes.arrayOf(PropTypes.string),
		country: PropTypes.string,
		qna: PropTypes.arrayOf(PropTypes.shape({
			question: PropTypes.string,
			answer: PropTypes.string,
			timeSpent: PropTypes.number
		})),

		/** @prop {bool} deprecated indicated the answer is given to a questionm which is modified or deleted */
		deprecated: PropTypes.bool
	}).isRequired,
	
	questions: PropTypes.arrayOf(PropTypes.shape({
		question: PropTypes.string,
		orderIndex: PropTypes.number
	})),

	stats: PropTypes.shape({
		questionsAnsweredCount: PropTypes.number,
		totalTime: PropTypes.number,
		avgTime: PropTypes.number
	}).isRequired,

	isCollapsedAllOn: PropTypes.bool,
	
	/** @prop {bool} hideDeprecatedQuestions indicates if the answer should be visible in the list */
	hideDeprecatedQuestions: PropTypes.bool,

	searchTerm: PropTypes.string
};

GroupByVote.defaultProps = {
	hideDeprecatedQuestions: false,
	searchTerm: null
};

export default GroupByVote;