import * as types from "../actions/actionTypes";

// generates a state slice:
// entities = []
const initialState = [];

export function userEntitiesReducer(state = initialState, action) {
	switch (action.type) {
		case types.LOGIN_USER_SUCCESS: {
			const { entities } = action.payload;
			return entities;
		}
		case types.SWITCH_TO_ENTITY_SUCCESS: {
			const { entity } = action.payload;
			return [entity];
		}
		case types.EDIT_ENTITY_SUCCESS: {
			const { entity } = action.payload;
			const isUserEntityEdited = state.find(userEntity => entity.id === userEntity.id);
			if (isUserEntityEdited) {
				const newState = state.filter(entity => action.payload.entity.id !== entity.id);
				return [...newState, action.payload.entity];
			} else {
				return state;
			}
		}
		case types.LOAD_TOPICS_FOR_ENTITY_SUCCESS: {
			const entityId = action.payload.entityId;
			const entity = state.find(e => e.id === entityId);
			
			if (entity && !entity.topicsLoaded) {
				const newState = state.filter(e => e.id !== entityId);
				return [Object.assign({}, entity, { topicsLoaded: true }), ...newState];
			}
			return state;
		}
		case types.SAVETOPIC_SUCCESS:
		case types.RESET_TOPICS: {
			const withTopicsPerviouslyLoaded = state.filter(e => e.topicsLoaded);
			if (withTopicsPerviouslyLoaded && withTopicsPerviouslyLoaded.length > 0) {
				const newState = state.filter(e => !e.topicsLoaded);
				let entities = [];
				withTopicsPerviouslyLoaded.forEach(withLoadedEntity => entities.push(Object.assign({}, withLoadedEntity, { topicsLoaded: false })));
				return [...entities, ...newState];
			}
			return state;
		}
		case types.LOAD_ENTITY_SOURCES_SUCCESS: {
			const sourcesObjects = convertSourcesToObjects(action.payload.sources);
			const entityToExtend = state.find(entity => entity.id === action.payload.entityId);
			if (!entityToExtend) {
				// it is possible that an admin user edits a topic of another organization, in this case we cannot use the original organization's the sources
				if(state.length === 0){
					return state;
				}
				const currentEntity = state[0];	// the current functionality limits own organizations to just 1
				const newState = state.filter(entity => entity.id !== currentEntity.id);
				return [Object.assign({}, currentEntity, { sources: [] }), ...newState];
			} else {
				const newState = state.filter(entity => entity.id !== action.payload.entityId);
				return [Object.assign({}, entityToExtend, { sources: sourcesObjects }), ...newState];
			}
		}
		case types.CREATE_ROOT_TOPIC_FOR_ENTITY_SUCCESS: {
			const entityToUpdate = state.find(entity => action.payload.entityId === entity.id);
			const entitesExceptUpdated = state.filter(entity => action.payload.entityId !== entity.id);
			const updatedEntity = Object.assign({}, entityToUpdate, {
				rootTopicCreated: {
					status: 'success',
					topicName: action.payload.topicName
				}
			});
			return [...entitesExceptUpdated, updatedEntity];
		}
		case types.CREATE_ROOT_TOPIC_FOR_ENTITY_ERROR: {
			const entityToUpdate = state.find(entity => action.payload.entityId === entity.id);
			const entitesExceptUpdated = state.filter(entity => action.payload.entityId !== entity.id);
			const updatedEntity = Object.assign({}, entityToUpdate, {
				rootTopicCreated: {
					status: 'error',
					topicName: action.payload.topicName,
					error: action.payload.error
				}
			});
			return [...entitesExceptUpdated, updatedEntity];
		}
		case types.LOAD_ENTITY_SUBSCRIPTIONS_SUCCESS: {
			const entityToUpdate = state.find(entity => action.payload.entityId === entity.id);
			const entitesExceptUpdated = state.filter(entity => action.payload.entityId !== entity.id);
			const updatedEntity = Object.assign({}, entityToUpdate, {
				subscriptionsData: {
					isLoading: false,
					isLoaded: true,
					subscriptions: action.payload.subscriptions
				}
			});
			return [...entitesExceptUpdated, updatedEntity];
		}
		case types.CANCEL_SUBSCRIPTION_SUCCESS: {
			const entityToUpdate = state.find(entity => action.payload.entityId === entity.id);
			const oldSubscriptions = entityToUpdate.subscriptionsData.subscriptions.filter(s => s.id !== action.payload.canceledSubscription.id);
			const entitesExceptUpdated = state.filter(entity => action.payload.entityId !== entity.id);
			const updatedEntity = Object.assign({}, entityToUpdate, {
				subscriptionsData: {
					isLoading: false,
					isLoaded: true,
					subscriptions: [action.payload.canceledSubscription, ...oldSubscriptions]
				}
			});
			return [...entitesExceptUpdated, updatedEntity];
		}
		case types.LOAD_CHECKOUT_SESSION_SUCCESS: {
			const entityToUpdate = state.find(entity => action.payload.entityId === entity.id);
			const entitesExceptUpdated = state.filter(entity => action.payload.entityId !== entity.id);
			const updatedEntity = Object.assign({}, entityToUpdate, {
				checkoutSession: action.payload.checkoutSession
			});
			return [...entitesExceptUpdated, updatedEntity];
		}
		case types.LOAD_UPGRADEDSUBSCRIPTION_SUCCESS: {
			const entityToUpdate = state.find(entity => action.payload.entityId === entity.id);
			const entitiesExceptUpdated = state.filter(entity => action.payload.entityId !== entity.id);
			const updatedEntity = Object.assign({}, entityToUpdate, {
				errors: null
			});
			return [...entitiesExceptUpdated, updatedEntity];
		}
		case types.LOAD_UPGRADEDSUBSCRIPTION_ERROR: {
			const entityToUpdate = state.find(entity => action.payload.entityId === entity.id);
			const entitiesExceptUpdated = state.filter(entity => action.payload.entityId !== entity.id);
			const updatedEntity = Object.assign({}, entityToUpdate, {
				errors: {
					subscriptionError: action.payload.error.message
				}
			});
			return [...entitiesExceptUpdated, updatedEntity];
		}
		default:
			return state;
	}
}

function convertSourcesToObjects(sources = []) {
	return sources.map((sName, i) => {
		return {
			id: i + 1,
			name: sName
		};
	});
}