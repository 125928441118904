import { intersection, difference } from 'lodash';
import { USER_PERMISSIONS } from 'hollerlive-shared/constants';

// TODO: at the moment only some of the available permissiopns are matched, because the rest are not used by the dashboard
export const PERMISSIONS = {
  // user rights
  ADMIN: USER_PERMISSIONS.FULL_ACCESS,
  EDIT_ENTITIES: USER_PERMISSIONS.EDIT_ENTITY,
  EDIT_TOPICS: USER_PERMISSIONS.EDIT_TOPICS,
  CREATE_TOPICS: USER_PERMISSIONS.CREATE_TOPICS,
  // user action rights
  CREATE_AND_ASSIGN_USERS: USER_PERMISSIONS.CREATE_AND_ASSIGN_USERS,
  EDIT_USERS: USER_PERMISSIONS.EDIT_USERS,
  VIEW_USERS: USER_PERMISSIONS.VIEW_USERS,
  VIEW_ENTITY: USER_PERMISSIONS.VIEW_ENTITY,
  // special
  HAS_SPECIAL_PERMISSIONS: 'hasSpecialPermissions'

};

/**
 * userHasPermissions checks required permissions against user and entity's existing ones
 * returns a boolean of whether the user has needed permissions or not
 * @param {array} requiredPermissions an array of strings to check user permissions against
 * @param {object} user the currently logged-in user 
 * @param {object} entity the current entity
 * @param {bool} matchSingle flag 
    * if true matches at least one of the required permissions against user permissions; 
    * when false all required must match the user's permissions
 */
export function userHasPermissions(requiredPermissions, user = {}, matchSingle = false) {
  if (userHasFullAccess(user, requiredPermissions)) {
    return true;
  }
  const userPermissions = user.permissions;

  if (user.hasSpecialPermissions) {
    userPermissions.push('hasSpecialPermissions');
  }

  return matchSingle ?
    Boolean(intersection(userPermissions, requiredPermissions).length > 0) :
    difference(requiredPermissions, userPermissions).length === 0;
}

export function userHasSpecialPermissions(user){
  if(user.permissions && user.permissions.includes(PERMISSIONS.ADMIN) && user.hasSpecialPermissions){
    return true;
  }
  return false;
}

export function userHasFullAccess(user, requiredPermissions){
  if(userHasSpecialPermissions(user) ||
    (user.permissions && user.permissions.includes(PERMISSIONS.ADMIN) && !requiredPermissions.includes(PERMISSIONS.HAS_SPECIAL_PERMISSIONS))){
    return true;
  }
  return false;
}