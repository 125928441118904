import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StaticMarkdownContentReader from '../../components/common/staticMarkdownContentReader';
import Card from '../../components/common/card';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getMarkdownFileContentsByName } from '../../actions/filesActions';

export const staticContentPageViewer = ({ loadedFile, contents, isLoaded, isLoading, actions, params }) => {
	useEffect(() => {
		if ((!isLoaded && !isLoading) || loadedFile.toLowerCase() !== params.filename.toLowerCase()) {
			actions.getMarkdownFileContentsByName(params.filename);
		}
	}, [isLoaded, isLoading, params.filename]);

	return (
		<Card title={loadedFile} className='static-page'>
			<StaticMarkdownContentReader body={contents} />
		</Card>
	);
};

staticContentPageViewer.propTypes = {
	loadedFile: PropTypes.string,
	contents: PropTypes.string,
	isLoaded: PropTypes.bool,
	isLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	const { fileName, contents, isLoaded, isLoading } = state.files;
	const props = {
		loadedFile: fileName || "",
		contents: contents,
		isLoaded,
		isLoading
	};

	return props;
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({ getMarkdownFileContentsByName }, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(staticContentPageViewer);
