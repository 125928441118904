export const notificationLevel = {
    success: 'success',
    error: 'error',
    warning: 'warning',
    info: 'info'
};

// default interval measured in seconds; each number (sec) corresponds to a level of notifications
export const notificationDismissInterval = {
    success: 6, 
    error: 10, 
    warning: 8, 
    info: 10
};