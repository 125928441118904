import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { isEmpty } from 'lodash';
import { Link } from 'react-router';
import ReCAPTCHA from "react-google-recaptcha";

import EditUserForm from '../../components/users/editUserForm';
import { register, clearErrors } from '../../actions/authActions';
import Card from '../../components/common/card';
import ServerErrorMessage from '../../components/common/serverErrorMessage';
import { Formik, Form } from 'formik';
import { validateUser, mapToFormModel } from '../../actions/models/userModel';
import { LOCALE } from '../../helpers/locales';
import formValidators from '../../helpers/formValidators';
import Messages from '../../helpers/messages';
import { CheckCircle } from 'react-feather';

import './css/register.css';
import { isOnMobile } from '../../helpers/utils';

const RECAPTCHA_SITE_KEY = "6Lc9r_ApAAAAAKzwYaLZw1qxH0-CDRIAGA_T-Pm2";

export class UserRegisterPage extends Component {
    constructor(props, context){
        super(props, context);

        const currentBrowserLocale = LOCALE.find(l => l.name.toLowerCase() === window.navigator.language.toLowerCase());
        
        const defaultUserModel = mapToFormModel({
            locale: !isEmpty(currentBrowserLocale) ? currentBrowserLocale.id : null
        });
        const userModel = Object.assign({}, defaultUserModel, {
            active: false,
            password: "",
            confirmPassword: "",
            industryId: null,
            entityName: "",
        });

        this.state = {
            userModel,
            isCaptchaExpired: false,
            isCaptchaOk: false,
            isCaptchaErrored: false,
        };

        this.reCaptchaRef = React.createRef();

        this.dirty = false;
        this.setSubmitting = null;
        this.setValues = null;
        this.errors = {};

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentDidUpdate(props) {
        if (!isEmpty(this.props.requestErrors) && isEmpty(props.requestErrors)){
            this.setSubmitting(false);

            if(this.props.requestErrors.captcha) {
                this.reCaptchaRef.current.reset();
            }
        }
    }
    
    handleSubmit(values) {
        if (this.state.isCaptchaOk) {
            const valuesToSubmit = Object.assign({}, values, { captchaValue: this.reCaptchaRef.current.getValue() });
            this.props.register(valuesToSubmit);
        }
    }

    isSubmitDisabled(dirty, isSubmitting, errors) {
        return !dirty || isSubmitting || !isEmpty(errors) || !this.state.isCaptchaOk; 
    }

    validate(values) {
        const validationOptions = {
            shouldValidateEntity: false
        };

        const validationsDefault = validateUser(values, validationOptions);
        const additionalValidations = {};

        if (!formValidators.required(values.entityName)) {
            additionalValidations.entityName = Messages.errors.required;
        } else if (!formValidators.length(values.entityName, null, 60)) {
            additionalValidations.entityName = Messages.errors.length(null, 60);
        } else if (!formValidators.onlyText(values.entityName)) {
            additionalValidations.entityName = Messages.errors.onlyText;
        }

        return Object.assign({}, validationsDefault, additionalValidations);
    }

    handleCaptchaChange() {
        const value = this.reCaptchaRef.current.getValue();

        // is null only when stoken has expired
        if (value === null) {
            this.setState({ isCaptchaOk: false, isCaptchaExpired: true, isCaptchaErrored: false });
            this.reCaptchaRef.current.reset();
        } else {
            this.setState({ isCaptchaOk: true, isCaptchaExpired: false, isCaptchaErrored: false });
            this.props.clearErrors();
        }
    }

    handleCaptchaErrored() {
        this.setState({ isCaptchaErrored: true });
    }

    render() {
        const { userModel } = this.state;
        const { requestErrors, clearErrors, auth } = this.props;
        
        return (
            <Card className="register-form row-fluid clearfix">
                <div className="greeting">
                    <h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 40 40">
                            <g fill="none"><rect width="36" height="36" fill="#000" rx="6"/><g fill="#FFF" transform="translate(7.976 4.524)">
                                <path d="M0 26.943v-26.943h6.479v10.437h.131c1.046-2.726 3.267-4.22 6.367-4.22 4.537 0 7.058 2.875 7.058 7.674v13.051h-6.61v-11.614c0-2.297-1.139-3.716-3.323-3.716-2.222 0-3.473 1.587-3.473 3.865v11.464h-6.628z"/><circle cx="10.024" cy="26.952" r="4.167" stroke="#000"/></g>
                            </g>
                        </svg>
                        <br /><br />
                        Welcome to the<br />kazva.bg dashboard
                    </h2>

                    {isOnMobile() && <h4 className="message"><br />Already have an account? <Link to="/login">Sign in</Link></h4>}
                    
                    <br />
                    
                    <img src="https://business.holler.live/assets/img/gallery-60-4.jpg" alt="kazva bg dashboard" />

                    <div className="text-left">
                        <br />
                        <h3>Sign up now with a free account so you can</h3>
                        <p>Create your own topic and ask everyone how do they feel about it</p>
                        <p>Track your voters opinion and get detailed sentiment and demographic analysis</p>
                    </div>
                </div>
                <div className="form-container">
                    {auth.user && auth.user.registerSuccess ? 
                        <div className="success">
                            <p className="text-center text-success"><br /><CheckCircle size={48} /></p>
                            <p className="text-center text-success">You have successfully signed up for a free account!</p>
                            <p className="text-center message">
                                You will receive an activation link in your email box.<br />
                                The activation link will be active for 24 hours. Follow the activation link
                                to activate your kazva.bg Dashboard account and setup your topics!
                            </p>
                        </div>
                        : 
                        <div className="form" title={`Sign up`}>
                            <Formik
                                initialValues={userModel}
                                onSubmit={this.handleSubmit}
                                validate={this.validate}
                                enableReinitialize={true}
                            >
                                {({ values, errors, isSubmitting, setValues, setFieldValue, setTouched, dirty, setSubmitting }) => {
                                    this.dirty = dirty;
                                    this.setSubmitting = setSubmitting;
                                    this.setValues = setValues;
                                    this.errors = errors;
                                    
                                    return (
                                    <Form autoComplete="noautocompletepls">
                                        <h2>Sign up</h2>
                                        <h5>for a kazva.bg dashboard free account</h5>

                                        <EditUserForm 
                                            values={values}
                                            includePassword={true}
                                            includeAvatar={false}
                                            hideEntityChange={true}
                                            hideActiveToggle={true}
                                            hidePhone={true}
                                            hideTimeZone={true}
                                            hideRoles={true}
                                            hideEntityName={false}
                                            errors={errors}
                                            requestErrors={requestErrors}
                                            setFieldValue={setFieldValue}
                                            setTouched={setTouched}
                                            resetRequestErrors={clearErrors}
                                            entities={[]}
                                            hideIndustry={false}
                                            resetSubmitting={setSubmitting}
                                        />

                                        <div className="captcha-container"> 
                                            <ReCAPTCHA
                                                sitekey={RECAPTCHA_SITE_KEY}
                                                onChange={() => this.handleCaptchaChange()}
                                                onErrored={() => this.handleCaptchaErrored()}
                                                ref={this.reCaptchaRef}
                                            />
                                        </div>
                                        {requestErrors.captcha && requestErrors.captcha.length > 0 && 
                                        <ServerErrorMessage errors={requestErrors} name="captcha" className="control-label" />
                                        }


                                        {this.state.isCaptchaExpired &&
                                        <p className="message bg-warning text-warning">Your attempt to use CAPTCHA has expired. Please try again</p>
                                        }
                                        {this.state.isCaptchaErrored &&
                                        <p className="message bg-warning text-warning">Cannot verify CAPTCHA at the moment. Please try again later</p>
                                        }

                                        <div className="btn-toolbar">
                                            <button type="submit" className="btn btn-lg btn-success" disabled={this.isSubmitDisabled(dirty, isSubmitting, errors)}>
                                                <CheckCircle size="18" /> Sign up
                                            </button>
                                            <span>or <Link to="/login">Sign in</Link></span>
                                        </div>
                                    </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    }
                </div>
            </Card>
        );
    }
}

function mapStateToProps(state){
    return {
        auth: state.auth,
        requestErrors: state.auth.errors ? state.auth.errors.errors : {}
    };
}

UserRegisterPage.defaultProps = {
    requestErrors: {}
};

UserRegisterPage.propTypes = {
    register: PropTypes.func.isRequired,
    requestErrors: PropTypes.shape({}),
    clearErrors: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { register, clearErrors }) (UserRegisterPage);