import * as types from "../actions/actionTypes";

// generates a state slice:
// entitiesList = []
const initialState = [];
export function entitiesListReducer(state = initialState, action) {
	switch (action.type) {
		case types.GET_ALL_ENTITIES_SUCCESS: {
			const sortedEntitiesByName = action.payload.entities.sort((a, b) => {
				return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
			});
			return [...sortedEntitiesByName];
		}
		case types.DELETE_ENTITY_SUCCESS: {
			return state.filter(entity => action.payload.entityId !== entity.id);
		}
		case types.EDIT_ENTITY_SUCCESS: {
			return state.map(entity => {
				return entity.id !== action.payload.entity.id ? entity : action.payload.entity;
			});
		}
		default:
			return state;
	}
}
