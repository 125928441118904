import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { getValueByFieldPath } from '../../helpers/utils';

const ServerErrorMessage = ({errors, name, component, className}) => {
    const TagName = component || "p";
	const errorObj = getValueByFieldPath(errors, name);
	
	const error = isEmpty(errorObj) ? "" : errorObj;
 
    return error ? (
        <TagName className={`message bg-danger ${className}`}>
            {error}
        </TagName>
    ) : null;
};

ServerErrorMessage.propTypes = {
	/** errors object */
	errors: PropTypes.objectOf(PropTypes.shape),

	/** name of error field */
	name: PropTypes.string.isRequired,

	/** tag name of display element. optional. defaults to "<p>" */
	component: PropTypes.string,

	/** css classes for the display element. optional */
	className: PropTypes.string
};

export default ServerErrorMessage;