import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import { Map, Circle, TileLayer, Tooltip } from 'react-leaflet';
import {SENTIMENT_COLORS} from '../../helpers/graphsService';

const COLORS_PALLETE = [
    SENTIMENT_COLORS.veryLow,
    SENTIMENT_COLORS.veryLow,
    SENTIMENT_COLORS.low,
    SENTIMENT_COLORS.low,
    SENTIMENT_COLORS.medium,
    SENTIMENT_COLORS.medium,
    SENTIMENT_COLORS.high,
    SENTIMENT_COLORS.high,
    SENTIMENT_COLORS.veryHigh,
    SENTIMENT_COLORS.veryHigh,
];
const DEFAULT_RADIUS = 4000; // meters
const RADIUS_FACTOR = 100;  // each vote will add 50 meters

class VotesMap extends Component {
    constructor(props, context) {
        super(props, context);
        
        // coordinates of Vienna, to be able to center over Europe
        this.state = {
            mapLattitude: 48.195,
            mapLongitude: 16.35,
            mapZoom: 7,
            dataCoords: []
        };
    }
    
    componentDidMount() {
        let sortedData = [].concat(this.props.data).sort((a,b) => {return b.votesCount - a.votesCount;});
        this._centerMapOnTopCity(sortedData);
        const circleOptions = this._mapCityDataToCircleCoords(this.props.data);
        this.setState({dataCoords: circleOptions});
    } 

    componentDidUpdate(prevProps) {
        if(prevProps.data !== this.props.data){
            let sortedData = [].concat(this.props.data).sort((a,b) => {return b.votesCount - a.votesCount;});
            this._centerMapOnTopCity(sortedData);
            const circleCoords = this._mapCityDataToCircleCoords(this.props.data);
            this.setState({dataCoords: circleCoords});
        }        
    }

    _centerMapOnTopCity(cities) {
        if(cities[0]) {
            if (cities[0].cityCoords.latitude && cities[0].cityCoords.longitude && cities[0].city !== 'unknown') {
                this.setState({
                    mapLattitude: cities[0].cityCoords.latitude,
                    mapLongitude: cities[0].cityCoords.longitude
                });
            }
            else if (cities[1]) {
                this.setState({
                    mapLattitude: cities[1].cityCoords.latitude,
                    mapLongitude: cities[1].cityCoords.longitude
                });
            }
        }
    }
    
    _mapCityDataToCircleCoords(cities){
        const circleCoords = [];
        cities.forEach(city => {

            if(isNaN(city.cityCoords.latitude) || isNaN(city.cityCoords.longitude))
                return;

            circleCoords.push({
                position: [city.cityCoords.latitude, city.cityCoords.longitude],
                radius: this._setRadiusRelativeToVoteCount(city.votesCount),
                vote: city.vote,
                count: city.votesCount,
                color: this._setColorRelativeToVoteScore(city.vote)
            });
        });

        return circleCoords;
    }
        
    _setRadiusRelativeToZoom(zoomLevel){
        const defaultSize = DEFAULT_RADIUS;  // in meters
        
        if(zoomLevel >= 13){
            return defaultSize;
        }

        return (13 - zoomLevel) * defaultSize;
    }

    _setRadiusRelativeToVoteCount(votesCount){
        return DEFAULT_RADIUS + votesCount * RADIUS_FACTOR;  // each vote will add 50 meters
    }

    _setColorRelativeToVoteScore(voteScore){
        let colorIndex = 0;
        if(voteScore > 1){
            colorIndex = Math.floor(voteScore) - 1;  // will produce a number bewteen 0 and 9
        }

        return COLORS_PALLETE[colorIndex];
    }

    _renderMapCircles(displayProps) {
        return displayProps.map((displayProp, i) => {
            return( 
            <Circle center={displayProp.position} color={displayProp.color} fillColor={displayProp.color} fillOpacity='0.5' radius={displayProp.radius} key={i} >
                <Tooltip>
                    <span>mean {displayProp.vote} from {displayProp.count} votes</span>
                </Tooltip>
            </Circle>);
        });
    }

    render() {
        const position = [this.state.mapLattitude, this.state.mapLongitude];
        return (
            <Map center={position} zoom={this.state.mapZoom}>
                <TileLayer
                    attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.'
                    // url="http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                />

                {this._renderMapCircles(this.state.dataCoords)}                
            </Map>
        );
    }
}

VotesMap.propTypes = {
    data: PropTypes.array.isRequired
};

export default VotesMap;