import React from 'react';
import PropTypes from 'prop-types';
import { BellOff, Bell } from 'react-feather';
import { getAlertsLabels } from '../../helpers/utils';

const entityAlerts = ({ systemNotifActive, entityNotifActive, voteNotifActive, voteNotifInterval }) => {
	const intervalTimeLabels = getAlertsLabels();
	const intervalTimeLabelSelection = intervalTimeLabels.find(label => label.value === voteNotifInterval);

	// const entityAlertsLabel = entityNotifActive ? <span className="text-primary"><Bell size={14} /> organization alerts active</span>
	// 											: <span className="text-muted"><BellOff size={14} /> organization alerts inactive</span>;

	// const systemAlertsLabel = systemNotifActive ? <span className="text-primary"><Bell size={14} /> system alerts active</span>
	// 											: <span className="text-muted"><BellOff size={14} /> system alerts inactive</span>;


	const selectedIntervalName = intervalTimeLabelSelection ? intervalTimeLabelSelection.name : `every ${voteNotifInterval} minutes`;
	const voteAlertsLabel = voteNotifActive ? <React.Fragment><span className="text-primary"><Bell size={14} /> votes alerts active, sent </span><label>{selectedIntervalName}</label></React.Fragment>
												: <span className="text-muted"><BellOff size={14} /> vote alerts inactive</span>;
												
	return (
		<div className="entity-alerts text-label">
			{ /* Nickey: uncomment when both alerts are implemented:
			<p>{entityAlertsLabel}</p>
			<p>{systemAlertsLabel}</p> */}
			<p>{voteAlertsLabel}</p>
		</div>
	);
};

entityAlerts.propTypes = {
	systemNotifActive: PropTypes.bool, 
	entityNotifActive: PropTypes.bool, 
	voteNotifActive: PropTypes.bool, 
	voteNotifInterval: PropTypes.number
};

export default entityAlerts;