export const topicLinksDefaultConfig = { 
	width: 650,
	hasPadding: false
};

export function getTopicLinksModalConfig(topicId, topicName = null, topicDisplayName = null, linkConfig = null) {
	const config = Object.assign({}, topicLinksDefaultConfig, {
		data: {
			topicId,
			topicName,
			topicDisplayName,
			linkConfig
		}
	});

	return config;
}