import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BSFormControl from '../common/form/bsFormControl';
import { Field, ErrorMessage, Formik, Form } from 'formik';
import Toggle from '../common/form/toggle';
import ButtonBarInput from '../common/form/buttonBarInput';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import { Save } from 'react-feather';
import { getAlertsLabels } from '../../helpers/utils';
import { validateAlerts } from '../../actions/models/entityModel';
import { useTranslation } from 'react-i18next';

const EntityAlertsEdit = ({ entityData, requestErrors, onSubmit, onCancel, hideModal, showModal }) => {
	const [formIsDirty, setFormIsDirty] = useState(false);
	const [formIsValid, setFormIsValid] = useState(false);

	const { t } = useTranslation();

	const initialValues = {
		voteAlertsActive: entityData.voteAlertsActive,
		voteAlertsInterval: entityData.voteAlertsInterval,
		alertRecipients: entityData.alertRecipients
	};

	const handleSubmit = (values) => {
		onSubmit(values);
	};

	const handleCancel = () => {
		if(!formIsDirty) {
			onCancel();
		} else {
			const configCancelModal = {
				title: "Confirm Cancel Changes",
				message: "Are you sure you want to cancel the changes you made?",
				onClose: () => { 
					hideModal();
				},
				onConfirm: () => {
					onCancel();
					hideModal();
				},
			};

			showModal(CONFIRM_MODAL, configCancelModal);
		}
	};

	const isSubmitDisabled = () => {
		return formIsDirty ? !formIsValid : true;
	};

	const fieldHasErrors = (errors, field) => {
        if (requestErrors && requestErrors[field] && requestErrors[field].length > 0) {
            return true;
        }
        return errors[field] && errors[field].length > 0;
	};
	
	const handleSetValue = (value, field, setFieldValue) => {
        setFieldValue(`${field}`, value);
	};
	
	const handleVoteAlertsPredefinedIntevalSet = (value, setFieldValue, setFieldTouched) => {
		const interval = parseInt(value);
		
        setFieldValue('voteAlertsInterval', Number.isInteger(interval) ? interval : '');
        setFieldTouched('voteAlertsInterval');
    };

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			enableReinitialize={true}
			validate={validateAlerts}
		>
			{({ 
				setFieldValue, 
				setFieldTouched, 
				errors, 
				dirty, 
				isValid
			}) => {
				setFormIsDirty(dirty);
				setFormIsValid(isValid);

				return <Form autoComplete="noautocompletepls" className='clearfix entity-details-form'>
					<div className="row-fluid clearfix">
						<BSFormControl label='alerts recipients emails' hasErrors={fieldHasErrors(errors, 'alertRecipients')}>
							<label className="sub-label">default recipient emails to be configured in topic details</label>
							<label className="sub-label">list, separated by a comma (e.g. email@example.com, email2@example.com, email3@example.com)</label>

							<Field 
								type="text" 
								className="form-control" 
								name="alertRecipients"
								placeholder="e.g. email@example.com, email2@example.com"
								autoComplete="noautocompletepls"
							/>
							<ErrorMessage name="alertRecipients" component="div" className='control-label'/>
						</BSFormControl>
					</div>
					
					{/* Do not delete.
					To be used once we have functionality for organization and system alerts.
					<div className="form-group clearfix">
						<Field name="systemAlertsActive">
							{({ field }) =>
								<Toggle value={field.value} onChange={(value) => this.handleSetValue(value, field.name, setFieldValue)} />
							}
						</Field>
						<label className="control-label" htmlFor="timezone">system alerts</label>
						<label className="sub-label">receive email alerts for system related events</label>
					</div>
					<div className="form-group clearfix">
						<Field name="entityAlertsActive">
							{({ field }) =>
								<Toggle value={field.value} onChange={(value) => this.handleSetValue(value, field.name, setFieldValue)} />
							}
						</Field>
						<label className="control-label" htmlFor="timezone">organization alerts</label>
						<label className="sub-label">receive email alerts for changes made to this organization</label>
					</div> */}
					
					<div className="form-group clearfix">
						<Field name="voteAlertsActive">
							{({ field }) =>
								<Toggle value={field.value} onChange={(value) => handleSetValue(value, field.name, setFieldValue)} />
							}
						</Field>
						<label className="control-label" htmlFor="timezone">vote alerts</label>
						<label className="sub-label">configure time interval for topics vote check
							and receive email alerts</label>
						<div className="clearfix">
							<BSFormControl
								hasErrors={fieldHasErrors(errors, 'voteAlertsInterval')}>
								<Field name='voteAlertsInterval'>
									{({ field }) => <ButtonBarInput value={field.value}
																	type='number'
																	data={getAlertsLabels()}
																	onValueChange={(val) => handleVoteAlertsPredefinedIntevalSet(val, setFieldValue, setFieldTouched)}
																	showCustomInput/>}
								</Field>
								<ErrorMessage name="voteAlertsInterval" component="div"
												className='control-label'/>
							</BSFormControl>
						</div>
					</div>

					<div className="btn-toolbar pull-right">
						<button type="submit" disabled={isSubmitDisabled()} className="btn btn-success"><Save size={16} /> {t('modal_general_save')}</button>
						<button type="button" className="btn btn-outline" onClick={handleCancel}>{t('modal_general_cancel')}</button>
					</div>
				</Form>;                
			}}
		</Formik>
	);
};

EntityAlertsEdit.propTypes = {
	entityData: PropTypes.shape(),
	requestErrors: PropTypes.object,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	showModal: PropTypes.func.isRequired,
	hideModal: PropTypes.func.isRequired,
};

export default EntityAlertsEdit;