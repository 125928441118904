import React from 'react';
import TextFieldGroup from '../common/textFieldGroup';
import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const LoginForm = ({ className, formErrors, serverError, identifier, password, isLoading, onChange, onSubmit, onForgotPassword }) => {

	const { t } = useTranslation();

	return (
		<form onSubmit={onSubmit} autoComplete="on" className={`login-form ${className}`}>
			{serverError && !isEmpty(serverError) && <div className="alert alert-danger">{`${serverError}`}</div>}

			<TextFieldGroup
				field="identifier"
				label={t('login_form_label_username')}
				value={identifier}
				error={formErrors.identifier}
				onChange={onChange}
				type="text"
			/>

			<TextFieldGroup
				field="password"
				label={t('login_form_label_password')}
				value={password}
				error={formErrors.password}
				onChange={onChange}
				type="password"
			/>

			<button type="submit" className="btn btn-primary btn-lg btn-full-width" disabled={isLoading}>
				{t('login_form_button_login')}
			</button>

			<p className="message text-center">
				<button className="btn btn-link" onClick={onForgotPassword}>
					{t('login_form_button_forgotten_password')}
				</button>
			</p>
		</form>
	);
};

LoginForm.propTypes = {
	className: PropTypes.string, 
	formErrors: PropTypes.object, 
	serverError: PropTypes.string,
	identifier: PropTypes.string, 
	password: PropTypes.string, 
	isLoading: PropTypes.bool, 
	onChange: PropTypes.func, 
	onSubmit: PropTypes.func.isRequired, 
	onForgotPassword: PropTypes.func
};

export default LoginForm;