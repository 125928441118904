import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import FeedMetaInfoBar from '../../../components/topics/qna/answersFeed/common/feedMetaInfoBar';
import GroupByQuestion from '../../../components/topics/qna/answersFeed/groupByQuestion/groupByQuestion';

class TopicDetailsFeedQuestions extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isCollapsedAll: false,
			questionStatsInitialized: false,
			answersInitialized: false,
			shouldResetPaging: false
		};

		this.handleCollapseExpandAll = this.handleCollapseExpandAll.bind(this);
		this.handleLoadNextAnswers = this.handleLoadNextAnswers.bind(this);
	}

	componentDidMount() {
		if (this.state.questionStatsInitialized && !this.state.answersInitialized){
			this.props.requestQuestionsWithAnswers({ offset: 0 });
			this.setState({answersInitialized: true});
		}
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (this.state.questionStatsInitialized && !this.state.answersInitialized){
			this.props.requestQuestionsWithAnswers({ offset: 0 });
			this.setState({answersInitialized: true});
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (isEmpty(props.qnaForTopic) || props.qnaForTopic.isLoading) {
			return { questionStatsInitialized: false };
		} else if (props.qnaForTopic.isLoaded && !state.questionStatsInitialized){
			return { questionStatsInitialized: true, shouldResetPaging: true };
		}

		return null;
	}

	handleCollapseExpandAll() {
		this.setState({ isCollapsedAll: !this.state.isCollapsedAll });
	}

	handleLoadNextAnswers(offset, question) {
		this.setState({shouldResetPaging: false});
		this.props.requestSingleQuestionAnswers({ offset, question });
	}

	getQuestionsAndAnswersList() {
		const { questionsData, questionsDetails } = this.props;
		
		return questionsDetails.map((questionDetails, i) => {
			const { question } = questionDetails;

			const currQuestionDetails = questionsDetails.find(q => q.question === question);
			let questionOrderIndex = null;
			
			if (currQuestionDetails) {
				questionOrderIndex = currQuestionDetails.orderIndex;
			}

			let answers = [];
			let totalAnswers = 0;

			if (questionsData.questions[question]) {
				answers = questionsData.questions[question].answers;
				totalAnswers = currQuestionDetails.answerCount;
			}

			return <GroupByQuestion
				key={i}
				isCollapsedAllOn={this.state.isCollapsedAll}
				answers={answers}
				questionTitle={question}
				questionIndex={questionOrderIndex}
				stats={currQuestionDetails}
				onLoadNextPage={this.handleLoadNextAnswers}
				totalAnswersCount={totalAnswers}
				shouldResetPaging={this.state.shouldResetPaging}
			/>;
		});
	}

	get questionsTitles() {
		return this.props.questionsDetails.map(q => q.question);
	}

	render() {
		const { questionsData } = this.props;
		
		return (
			<div className="feed-view by-questions">
				<FeedMetaInfoBar total={this.questionsTitles.length} loaded={this.questionsTitles.length} onCollapsedAllCick={this.handleCollapseExpandAll} />
				
				{questionsData.questions && this.getQuestionsAndAnswersList()}
			</div>
		);
	}
}

TopicDetailsFeedQuestions.propTypes = {
	questionsData: PropTypes.shape({
		isLoaded: PropTypes.bool,
		isLoading: PropTypes.bool,
		questions: PropTypes.object
	}),

	/** @prop {func} requestSingleQuestionAnswers request paged answers for single question */
	requestSingleQuestionAnswers: PropTypes.func.isRequired,

	/** @prop {func} requestQuestionWithAnswers request paged answers for all questions */
	requestQuestionsWithAnswers: PropTypes.func.isRequired,
};

TopicDetailsFeedQuestions.defaultProps = {
	questionsDetails: [],
	questionsData: {}
};

function mapStateToProps(state, ownProps) {
	const votesFeedForTopic = state.votesFeed[ownProps.params.id];
	
	let questionsDetails = [];
	let questionsData = {};
	const qnaForTopic = state.qnaForTopics[ownProps.params.id] || {};

	if (votesFeedForTopic) {
		questionsData = votesFeedForTopic.questionsData;
	}

	if (qnaForTopic && qnaForTopic.questionDetails) {
		questionsDetails = qnaForTopic.questionDetails;
	}

	return {
		qnaForTopic,
		questionsData,
		questionsDetails
	};
}

export default connect(mapStateToProps)(TopicDetailsFeedQuestions);