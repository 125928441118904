import {
	REQUEST_STATUS_ERROR,
	REQUEST_STATUS_PENDING,
	REQUEST_STATUS_SUCCESS,
	CLEAR_REQUEST_STATUS
} from '../actions/actionTypes';

const initialState = {
	pending: false,
	success: false,
	error: false,
	endpointName: ""
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case REQUEST_STATUS_PENDING : {
			return {
				pending: true,
				success: false,
				error: false,
				endpointName: action.payload.endpointName || ""
			};
		}
		case REQUEST_STATUS_SUCCESS : {
			return {
				pending: false,
				success: true,
				error: false,
				endpointName: action.payload.endpointName || ""
			};
		}
		case REQUEST_STATUS_ERROR : {
			return {
				pending: false,
				success: false,
				error: true,
				endpointName: action.payload.endpointName || ""
			};
		}
		case CLEAR_REQUEST_STATUS : {
			return {
				pending: false,
				success: false,
				error: false,
				endpointName: ""
			};
		}
		default : 
			return state;
	}
};