import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from './autocomplete';
import Autosuggest from 'react-autosuggest';

import './css/autosuggestTagsSelector.css';
import { X } from 'react-feather';
import { isEmpty } from 'lodash';
import { isOnMobile } from '../../../helpers/utils';
import i18n from '../../../i18n';

class AutosuggestTagsSelector extends Autocomplete {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.state, {
            tagInputValue: "",
            t: i18n.t.bind(i18n)
        });

        this.refInputField = React.createRef();

        this.handleSelectionInput = this.handleSelectionInput.bind(this);
        this.handleKeyPressed = this.handleKeyPressed.bind(this);
        this.handleSelectionInputAddToSelected = this.handleSelectionInputAddToSelected.bind(this);
        this.handleRemoveSelectionTag = this.handleRemoveSelectionTag.bind(this);

        this.tagSelectorInputProps = Object.assign({}, this.inputProps, {
            type: "hidden"
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.autocompleteOpen !== prevState.autocompleteOpen) {
            if (!isOnMobile()) {
                if (this.state.autocompleteOpen) {
                    this.attachKeyEvents();
                    this.setState({keyeventtest: 'true'});
                } else {
                    this.removeKeyEvents();
                    this.setState({keyeventtest: 'false'});
                }

                this.setState({ tagInputValue: "" });
            }
        }
    }

    attachKeyEvents() {
        this.refInputField.current.addEventListener('keypress', this.handleKeyPressed, true);
    }

    removeKeyEvents() {
        this.refInputField.current.removeEventListener('keypress', this.handleKeyPressed, true);
    }

    handleKeyPressed(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            this.handleSelectionInputAddToSelected();
        }
    }

    handleSelectionInput(e) {
        let value = e.target.value;

        if(value.includes(',')) {
            value = value.replace(/,/g, '');
            this.setState({ tagInputValue: value }, this.handleSelectionInputAddToSelected);
        } else {
            this.setState({ tagInputValue: value });
        }
    }

    handleSelectionInputAddToSelected() {
        const { tagInputValue } = this.state;
        const { data } = this.props;

        let foundSource = data.find(s => s.name === tagInputValue);
        if (!foundSource) {
            foundSource = {
                id: tagInputValue, 
                name: tagInputValue
            };
        }

        this.sendOnChange(foundSource);
        this.setState({ tagInputValue: "" });
    }

    handleRemoveSelectionTag(ev, tag) {
        ev.preventDefault();
        ev.stopPropagation();
        const { value, valueField } = this.props;

        const updatedSelections = value.filter(v => {
            return v[valueField] !== tag[valueField];
        });
        this.props.onChange(updatedSelections);
    }

    onSelected(event, selectionProps) {
        this.setState({ tagInputValue: "" });
        this.sendOnChange(selectionProps.suggestion);
    }

    sendOnChange(newSelection) {
        const { value } = this.props;
        let updatedSelections;

        const foundInCurrentSelection = value.find(v => v.name === newSelection.name);
        if (!foundInCurrentSelection) {
            updatedSelections = [...value, newSelection];
        } else {
            updatedSelections = [...value];
        }

        const notEmptySelections = updatedSelections.filter(s => !isEmpty(s.name));
        this.props.onChange(notEmptySelections);
    }

    get selectionsTags() {
        const { value, labelField } = this.props;

        return value.filter(s => s[labelField].length > 0).map((s, i) => {
            return <span className="selection-tag" key={i}>
                {s[labelField]} 
                <X className="uitest-remove-tag-btn" size={16} onClick={(e) => this.handleRemoveSelectionTag(e, s)} />
            </span>;
		});
    }

    get autosuggestTheme() {
        return Object.assign({}, this.theme, { input: `${this.theme.input} autocomplete-search-input` });
    }

    //overload method
    removeSelectedOption(options) {
        return options;
    }

    get maxSelectionsReached() {
        const { maxSelections, value } = this.props;

        if (maxSelections) {
            return value.length >= maxSelections;
        }

        return false;
    }

    render() {
        const { value, data, disabled, maxSelections, labelField } = this.props;
        const { t, tagInputValue, autocompleteOpen } = this.state;

        if (this.state.autocompleteOpen && this.autocompleteDom) {
            const dropDown = this.autocompleteDom.getElementsByClassName('autocomplete-container')[0];

            if (dropDown) {
                dropDown.style.width = `${this.autocompleteDom.clientWidth}px`;
            }
        }

        const filteredData = data.filter(d => d[labelField].indexOf(tagInputValue) > -1);
        const inputPlaceholderText = this.maxSelectionsReached ?
            t('topic_filters_autosuggest_maximum_added', { maxSelections })
            : t('topic_filters_autosuggest_type_to_add');

        return (
            <div
                className={`autosuggest-tags-selector ${this.props.className}`}
                ref={autocomplete => (this.autocompleteDom = autocomplete)}
            >
                <label className={`selections ${value.length > 0 ? '' : 'empty'}`}>
                    {this.selectionsTags}
                    <span className="tag-input-container">
                        <input
                            ref={this.refInputField}
                            className="search-tag-input uitest-search-tag-input text-light"
                            placeholder={inputPlaceholderText}
                            type="text"
                            onChange={this.handleSelectionInput}
                            onBlur={this.handleSelectionInputBlur}
                            disabled={disabled || this.maxSelectionsReached}
                            value={tagInputValue}
                        />
                        {tagInputValue.length > 0 && <small className="text-muted search-tag-input-tips">&nbsp;&nbsp;{t('topic_filters_autosuggest_hit')} <em>"," </em>{!isOnMobile() && <span> {t('topic_filters_autosuggest_or')}<em> "Enter"</em></span>} {t('topic_filters_autosuggest_to_add_tag')}</small> }
                    </span>
                </label>

                {!disabled && <span className="glyphicon glyphicon-chevron-down" />}

                {autocompleteOpen && (!disabled && !this.maxSelectionsReached) && filteredData && (filteredData.length > 0) && (
                    <Autosuggest
                        suggestions={filteredData}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
                        onSuggestionSelected={this.onSelected.bind(this)}
                        getSuggestionValue={this.getOptionsValue.bind(this)}
                        renderSuggestion={this.renderSuggestion.bind(this)}
                        inputProps={this.tagSelectorInputProps}
                        alwaysRenderSuggestions={true}
                        theme={this.autosuggestTheme}
                    />
                )}
            </div>
        );
    }
}

AutosuggestTagsSelector.propTypes = {
    /** @prop {array} value the selected items to show as tags. An array of objects with default key-value signature:
     * ```{
     *      id: PropTypes.number
     *      name: PropTypes.string
     * }
     *
     * id signature key can be changed by passing "valueField"
     * name signature key can be changed by passing "labelField"
     */
    value: PropTypes.arrayOf(PropTypes.shape()).isRequired,

    /** @prop {string} labelField the label field from the data object. Defaults to "name" */
    labelField: PropTypes.string,

    /** @prop {string} valueField the value field from the data object. Defaults to "id" */
    valueField: PropTypes.string,

    /**
     * @prop {Array[Object]} data the data feed to the autocomplete dropdown.
     * Expected array of objects with default keys: id and name.
     * Key names can be configured to be named otherwise by valueField and labelField
     * */
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,

    maxSelections: PropTypes.number,

    /** @prop {bool} disables the selector */
    disabled: PropTypes.bool,
};

export default AutosuggestTagsSelector;
