import { isEmpty } from 'lodash';
import { getValueByFieldPath } from './utils';

const formValidators = {
	getValidatedState(formData, validationConfig) {
		const value = formData[validationConfig.field];
		let isValid = (validationConfig.isValid !== null && validationConfig.isValid !== undefined) ? validationConfig.isValid : true;

		validationConfig.validators.forEach(v => {
			let shouldValidate = v.shouldValidate;

			if (v.shouldValidate === undefined || v.shouldValidate === null) {
				shouldValidate = true;
			}

			if (v.precondition) {
				shouldValidate = formData[v.precondition.field] === v.precondition.value;
			}
			
			if (shouldValidate === true) {
				const fieldValid = v.validator(value);
				isValid = fieldValid;
			}
		});

		return isValid;
	},

	fieldHasErrors(errors, field){
		const fieldError = getValueByFieldPath(errors, field);

        return fieldError && fieldError.length > 0;
    },

	required: (value) => {
		let val = value;

		if (Number.isInteger(value)) {
			val = value.toString();
		}
		
		return !isEmpty(val);
	},

	length: (value, min = null, max = null) => {
		if (value) {
            return (min ? value.length > min : true) && (max ? value.length < max : true);
        } else {
			return true;
		}
	},

	onlyText: (value) => {
		const regex = /([^\s.,?!'"@&()-\u2070-\uFFFF])+/gu;
		const containsInvalid = regex.test(value);

		return !containsInvalid;
	},
	
	onlyNames: (value) => {
		const regex = /([\u00a9\u00ae`~!@#$%^&*()+=[{\]}|\\<,.>?/";:_\u2070-\uFFFF\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}])+/gu;
		const containsInvalid = regex.test(value);

		return !containsInvalid;
	},

	onlyLatinLetters: (value) => {
		const regex = /^[a-z\-_\s]+$/i;
		const containsOnlyInvalid = regex.test(value);

		return containsOnlyInvalid;
	},

	onlyLatinAphaNumeric: (value, acceptEmpty = false) => {
		if (isEmpty(value)) {
			return acceptEmpty;
		} else {
			const regex = /^[a-z\d\-_\s]+$/i;
			const containsOnlyInvalid = regex.test(value);

			return containsOnlyInvalid;
		}
	},

	onlyLatinAphaNumericNoSpaces: (value, acceptEmpty = false) => {
		if (isEmpty(value)) {
			return acceptEmpty;
		} else {
			const regex = /^[a-z\d\-_]+$/i;
			const isValid = regex.test(value);

			return isValid;
		}
	},

	onlyNumeric: (value) => {
		const regex = /^[0-9]*$/;
		const isValid = regex.test(value);

		return isValid;
	},

	atLeastOneNumeric: (value) => {
		const regex = /^[0-9]+$/;
		const isValid = regex.test(value);

		return isValid;
	},

	url: (value, matchStrict = false) => {
		//const regex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
		//const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
		//const regex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
     
		//const isValid = regex.test(value);

		// problem with fb://profile/100406665838294
		
		return true;// isValid;
	},
	
	isTrimmedStringEqual: (str1, str2) => {
		return str1.toLocaleLowerCase().trim() === str2.toLocaleLowerCase().trim();
	},

	isTrimmedStringDifferent: (str1, str2) => {
		return str1.toLocaleLowerCase().trim() !== str2.toLocaleLowerCase().trim();
	},

	isLocaleCode(value) {
		return /^[a-z]{1,3}(_([A-Za-z0-9]{2,4}))?(_([A-Z]{2}))?$/.test(value.trim());
	}
  
};

export default formValidators;