import Logger from '../helpers/logger';
import moment from 'moment';
import jsPDF from 'jspdf';
import { STANDARD_A4_MARGIN, STANDARD_A4_HEIGHT } from './customPDFGenerator';
import { VOTE_ALERTS_INTERVALS } from '../actions/models/entityModel';

export function uniq(arrArg) {
	return arrArg.filter((elem, pos, arr) => {
		return arr.indexOf(elem) === pos;
	});
}

/**
 * performs linear regression on an list of points (sorted chronologically) to determine the trend
 */
export function calculateTrendSlope(dataPoints) {
	let sumX = 0;
	let sumY = 0;
	for (let i = 0; i < dataPoints.length; i++) {
		sumX += i + 1;
		sumY += dataPoints[i];
	}
	const meanX = sumX / dataPoints.length;
	const meanY = sumY / dataPoints.length;

	let sumSquares = 0;
	let sumDeviations = 0;
	for (let i = 0; i < dataPoints.length; i++) {
		const xDev = (i + 1) - meanX;
		const yDev = dataPoints[i] - meanY;

		sumSquares += Math.pow(xDev, 2);
		sumDeviations += xDev * yDev;
	}

	const regressionSlope = sumDeviations / sumSquares;
	return precisionRound(regressionSlope, 2);
}

function precisionRound(number, precision) {
	var factor = Math.pow(10, precision);
	return Math.round(number * factor) / factor;
}

/** Generates PDF from given values
 * @param {object} pages of shape:
	* @field {object} options carries information about orientation, unit, format of PDF
	* @field {string} fileName the name of the pdf when downloaded
	* @field {number} marginLeft to be used if no margin is specified for any object to be placed on any page
	* @field {number} marginTop as marginLeft
	* @field {arrays} page1, page2, page3, etc, each with objects of shape:
		* { text: { text: string, marginLeft: number, marginTop: number, options: object } }
		* and/or
		* { imageData: { data: string/Image-Element/Canvas-Element/Uint8Array, format: string, marginLeft: number, marginTop: number, width: number, height: number } }
 */
export function downloadPDF(pages) {
	let orientation = 'portrtait';
	let unit = 'px';
	let format = 'a4';

	if (pages && pages.options) {
		const { options } = pages;
		orientation = options.orientation ? options.orientation : orientation;
		unit = options.unit ? options.unit : unit;
		orientation = options.format ? options.format : format;
	}
	const doc = new jsPDF({ orientation, unit, format, compress: true });
	doc.setFont('helvetica');
	doc.setFontSize(14);

	const pagesCount = Object.keys(pages).filter(key => key.includes('page')).length;
	const dateNow = new Date();

	for (let i = 1; i <= pagesCount; i++) {
		const elementsOnPage = pages[`page${i}`];
		const footerNote = `Date: ${moment(dateNow).format('MMM D YYYY')}. Page ${i} of ${pagesCount}.`;

		elementsOnPage.forEach((element) => {
			const elementTypesHolder = Object.keys(element); // has to be only one type per element
			const elementType = `${Object.keys(element)[0]}`;

			if (elementTypesHolder.length === 1) {
				if (elementType === 'text') {
					const { text, marginLeft, marginTop, options } = element.text;
					doc.text(text, marginLeft || pages.marginLeft || null, marginTop || pages.marginTop || null, options || null);

				} else if (elementType === 'imageData') {
					const { data, format, marginLeft, marginTop, width, height } = element.imageData;
					doc.addImage(data, format || null, marginLeft || pages.marginLeft || null, marginTop || pages.marginTop || null, width || null, height || null);
				}
			}
		});

		doc.text(footerNote,
			STANDARD_A4_MARGIN * 3,
			STANDARD_A4_HEIGHT - (STANDARD_A4_MARGIN / 2),
			{
				charSpace: 0,
				lineHeightFactor: '0.6'
			}
		);

		if (i + 1 <= pagesCount) {
			doc.addPage();
		}
	}

	return doc.save(`${pages.fileName ? `${pages.fileName}` : 'Capture_view'}.pdf`, { returnPromise: true });
}

// fits an image into maxWidth by maxHeight
export function applyImageFit(sourceImageFile, maxWidth, maxHeight, scalingQuality = 0.7) {

	return new Promise((resolve, reject) => {
		if (sourceImageFile.type.indexOf('image') === -1) {
			reject('Cannot process a file that is not a valid image file.');
		}
		let image = document.createElement('img');
		let resizedImageDataUrl;

		image.onload = () => {
			const imageWidth = image.width;
			const imageHeight = image.height;

			let canvas = document.createElement('canvas');
			canvas.width = image.width;
			canvas.height = image.height;

			// use full canvas for full image as a start
			canvas.getContext('2d').drawImage(image, 0, 0, imageWidth, imageHeight, 0, 0, canvas.width, canvas.height);

			do {
				canvas = resizeCanvas(canvas, maxWidth, maxHeight);
			}
			while (canvas.width > maxWidth && canvas.height > maxHeight);

			resizedImageDataUrl = canvas.toDataURL('image/png', scalingQuality);

			canvas = null;
			image = null;

			resolve(resizedImageDataUrl);
		};
		image.src = sourceImageFile.preview;

		image.onerror = (err) => {
			reject(err.error);
		};
	});
}

function resizeCanvas(canvas, maxWidth, maxHeight) {
	const height = canvas.height;
	const width = canvas.width;
	const fitRatio = Math.min(maxWidth / width, maxHeight / height);

	// use this as a smaller step to reduce the size of the canvas iteratively
	const minDownscalingRatio = 3 / 4;

	const resizedCanvas = document.createElement('canvas');

	if (minDownscalingRatio > fitRatio) {
		resizedCanvas.width = width * minDownscalingRatio;
		resizedCanvas.height = height * minDownscalingRatio;
	} else {
		resizedCanvas.width = width * fitRatio;
		resizedCanvas.height = height * fitRatio;
	}

	resizedCanvas.getContext('2d').drawImage(canvas, 0, 0, width, height, 0, 0, resizedCanvas.width, resizedCanvas.height);

	return resizedCanvas;
}

export function getBase64Data(sourceImageFile) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			const base64ImageData = reader.result;
			resolve(base64ImageData);
		};
		reader.onerror = (err) => { Logger.error('Cannot process file'); reject(err); };
		reader.readAsDataURL(sourceImageFile);
	});
}

export function createTopicHash(topicName) {
	if (!topicName) {
		Logger.error('Cannot create hash from empty name!');
	}
	return topicName.toLowerCase().replace(/\s+/g, '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '');
}

function getCurrentDomainByEnv() {
	const env = process.env.REACT_APP_DEPLOY_ENV || 'localdev';
	let domain;

	switch (env) {
		case 'production': {
			domain = { domainName: 'kazva.bg', templatesDomainName: 'm.kazva.bg/m' };
			break;
		}
		case 'staging': {
			domain = { domainName: 'staging.kazva.bg', templatesDomainName: 'ms.kazva.bg/m' };
			break;
		}
		case 'localdev': {
			domain = {
				domainName: 'localhost:3000',
				templatesDomainName: 'ms.kazva.bg/m'
			};
			break;
		}
		default: {
			domain = { domainName: 'kazva.bg', templatesDomainName: 'm.kazva.bg/m' };
		}
	}

	return domain;
}

export function getTopicPreviewUrl(title) {
	const domain = getCurrentDomainByEnv();
	const urlPreview = createTopicHash(title);
	return `https://${domain.domainName}/${urlPreview}`;
}

export function getVoterPreviewUrl(title, sourcesCsv = null) {
	const domain = getCurrentDomainByEnv();
	const topicHash = createTopicHash(title);
	let topicUrl = `https://${domain.templatesDomainName}/${topicHash}.html`;
	if (sourcesCsv && sourcesCsv.length > 0) {
		const sourcesGetParams = sourcesCsv.replace(/,/gi, '&s=');
		topicUrl = `${topicUrl}?s=${sourcesGetParams}`;
	}
	return topicUrl;
}

export function getValueByFieldPath(obj = {}, fieldPath) {
	return fieldPath.split('.').reduce((o, i) => {
		return o[i] !== undefined ? o[i] : {};
	}, obj);
}

export function isOnMobile() {
	return document.body.clientWidth < 769;
}

export function isOnLargeMobile() {
	return document.body.clientWidth >= 769 && document.body.clientWidth <= 1366;
}

export function getElapsedTime(date, includeDayOfWeek = true, useShortDateFormat = false) {
	if (!date) {
		Logger.error('Cannot get elapsed time without a date!');
		return '';
	}

	const now = moment(new Date());
	const dateVoted = moment(date.toString());
	const hoursSinceVote = moment.duration(now.diff(dateVoted)).asHours();

	// From 22 to 35 hours moment.fromNow() starts to display "a day ago"
	if (hoursSinceVote >= 22) {
		return moment(date.toString()).format(includeDayOfWeek ? "ddd, Do MMM YYYY" : useShortDateFormat ? "DD/MM/YY" : "Do MMM YYYY");
	} else {
		return moment(date.toString()).fromNow();
	}
}

export const getAlertsLabels = () => {
	const btns = [];
	VOTE_ALERTS_INTERVALS.forEach(interval => {
		let name;

		switch (interval) {
			case 60:
				name = "hourly";
				break;
			case 1440:
				name = "daily";
				break;
			case 10080:
				name = "weekly";
				break;
			default:
				name = `every ${interval} min`;
		}

		btns.push({ name, value: interval });
	});
	return btns;
};

/** isRequired proptype HOC for a handler that checks a boolean flag
 * @param{string} flagPropName */
export function isRequiredFuncIfNotSetFlag(flagPropName) {
	return (props, propName, component) => {
		if ((!props.hasOwnProperty(flagPropName) || props[flagPropName] === false) && !props.hasOwnProperty(propName)) {
			return new Error(`The prop '${propName}' is marked as required in '${component}', but its value is not set.`);
		}
		if (typeof props[propName] !== 'function') {
			return new Error(
				`The prop '${propName}' in '${component}' is of type '${typeof props[
				propName
				]}', which is not a handler.'`
			);
		}
	};
} 