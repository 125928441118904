import logger from '../helpers/logger';

const _defaultServers = {
    stagingGraph: "https://staging2.holler.live:3001",
    stagingStream: "https://staging2.holler.live:3002",
    stagingStatic: "https://staging2.holler.live",
    prodGraph: 'https://api1.kazva.bg',
    prodStream: 'https://api2.kazva.bg',
    prodStatic: "https://keycdn.kazva.bg",
};

const _defaultProxyServers = {
    stagingGraph: "https://proxy1.holler.live/staginggraphapi",
    stagingStream: "https://proxy1.holler.live/stagingstreamapi",
    stagingStatic: "https://staging2.holler.live",  // Since the request is on port 443, we are unlikely to need a proxy config
    prodGraph: "https://proxy1.holler.live/graphapi",
    prodStream: "https://proxy1.holler.live/streamapi",
    prodStatic: "https://prod2.holler.live",    // Since the request is on port 443, we are unlikely to need a proxy config
};

const currentServers = {
    streamServers: [
        {
            uri: ""
        }
    ],
    graphServers: [
        {
            uri: ""
        }
    ],
    staticServer: {
        uri: ""
    }   
};

class ApiServers{
    static setup(useProxy = false){
        const env = process.env.REACT_APP_DEPLOY_ENV || 'localdev';
        const servers = useProxy ? _defaultProxyServers : _defaultServers;
    
        switch (env) {
            case 'production':
                currentServers.graphServers[0].uri = servers.prodGraph;
                currentServers.streamServers[0].uri = servers.prodStream;
                currentServers.staticServer.uri = servers.prodStatic;
                break;
            case 'staging':
                currentServers.graphServers[0].uri = servers.stagingGraph;
                currentServers.streamServers[0].uri = servers.stagingStream;
                currentServers.staticServer.uri = servers.stagingStatic;
                break;
            default:
                // local servers are always the same, regardless of proxy
                currentServers.graphServers[0].uri = "http://localhost:4001";
                currentServers.streamServers[0].uri = "http://localhost:4002";
                currentServers.staticServer.uri = "http://localhost:4001/api";     // on local env, we use the graph service for serving static content
                break;
        }
        logger.debug(`Graph api url used for ${env} environment: ${currentServers.graphServers[0].uri}`);
        logger.debug(`Stream api url used for ${env} environment: ${currentServers.streamServers[0].uri}`);
        logger.debug(`Static content url used for ${env} environment: ${currentServers.staticServer.uri}`);
        
        return Promise.resolve();
    }

    static setupProxy() {
        logger.info("switching to proxy servers");
        return this.setup(true);
    }

    static getGraphUrl(){ 
        return `${currentServers.graphServers[0].uri}`;
     }

    static getStreamUrl() {
        return `${currentServers.streamServers[0].uri}`;
    }

    static getStaticContentUrl(){
        return `${currentServers.staticServer.uri}`;
    }
}

export default ApiServers;