import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../common/card';
import TopicsList from './topicsList';
import i18n from '../../i18n';

class TopicsTree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterName: "",
            treeInitialized: false,
            t: i18n.t.bind(i18n),
        };

        this.filterByName = this.filterByName.bind(this);
    }

    filterByName(ev) {
        ev.preventDefault();
        this.setState({ filterName: ev.target.value });
        this.props.actionFilterByName(ev.target.value);
    }

    render() {
        const topicsTree = this.props.topicsTree || [];

        return (
            <div className="topic-tree-scroll-container">
                <Card className="topics-tree">
                    <form action="" className="form-inline common-filters-bar">
                        <div className="row-fluid clearfix">
                            <div className="input-group input-group-lg search-topics">
                                <span className="input-group-addon" id="basic-addon1">
                                    <i className="glyphicon glyphicon-search" />
                                </span>
                                <input
                                    type="text"
                                    value={this.state.filterName}
                                    onChange={this.filterByName}
                                    className="form-control"
                                    placeholder={this.state.t('topics_tree_search_topics')}
                                    aria-describedby="basic-addon1"
                                />
                            </div>
                        </div>
                    </form>

                    <TopicsList topics={topicsTree} nestLevel={0} hidden={false} />
                </Card>
            </div>
        );
    }
}

TopicsTree.propTypes = {
    topicsTree: PropTypes.array.isRequired,
    actionFilterByName: PropTypes.func.isRequired
};

export default TopicsTree;