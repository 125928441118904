import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OptionsSelector from '../common/form/optionsSelector';
import Card from '../common/card';
import {
	ALL_ENTITIES_OPTION,
	ALL_ROLES_OPTION,
	USER_ACTIVE_STATUS_OPTIONS,
	USER_ASSIGNED_STATUS_OPTIONS,
	USER_ROLES,
	DEFAULT_FILTER_OPTIONS_SELECTION,
	ASSIGNED_STATUSES,
	ALL_TIERS_OPTION
} from '../../helpers/user';
import { X } from 'react-feather';
import {ENTITY_TIERS_LIST} from "../../actions/models/entityModel";

export class UserFilters extends Component {
	constructor(props) {
		super(props);

		this.state = { ...DEFAULT_FILTER_OPTIONS_SELECTION, hideAllExceptAssignedStatus: false };

		this.onFiltersChange = this.onFiltersChange.bind(this);
		this.onEntityFilterChange = this.onEntityFilterChange.bind(this);
		this.onUserActiveStatusFilterChange = this.onUserActiveStatusFilterChange.bind(this);
		this.onUserAssignedStatusFilterChange = this.onUserAssignedStatusFilterChange.bind(this);
		this.onUserRoleFilterChange = this.onUserRoleFilterChange.bind(this);
		this.handleResetFilters = this.handleResetFilters.bind(this);
		this.onUserTierFilterChange = this.onUserTierFilterChange.bind(this);
	}

	onFiltersChange() {
		this.props.onFiltersChange(this.state);
	}

	onEntityFilterChange(selection) {
		this.setState({
			selectedEntityId: selection
		}, () => {
			this.onFiltersChange();
		});
	}

	onUserActiveStatusFilterChange(selection) {
		this.setState({
			selectedActiveStatus: selection
		}, () => {
			this.onFiltersChange();
		});
	}

	onUserAssignedStatusFilterChange(selection) {
		let newState = {};

		if (selection === ASSIGNED_STATUSES.UNASSIGNED) {
			newState = Object.assign({}, DEFAULT_FILTER_OPTIONS_SELECTION, {
				selectedAssignedStatus: selection,
				hideAllExceptAssignedStatus: true
			});
		} else {
			newState = {
				selectedAssignedStatus: selection,
				hideAllExceptAssignedStatus: false
			};
		}

		this.setState(newState, () => {
			this.onFiltersChange();
		});
	}

	onUserRoleFilterChange(selection) {
		this.setState({
			selectedRole: selection
		}, () => {
			this.onFiltersChange();
		});
	}
	
	onUserTierFilterChange(selection) {
		this.setState({
			selectedTier: selection
		}, () => {
			this.onFiltersChange();
		});
	}

	handleResetFilters() {
		this.setState({  
			...DEFAULT_FILTER_OPTIONS_SELECTION, 
			hideAllExceptAssignedStatus: false  
		}, () => {
			this.onFiltersChange();
		});
	}

	get entityOptions() {
		const mappedEntities = this.props.entities.map(entity => {
			return {
				value: entity.id.toString(),
				label: entity.name
			};
		});

		return [ALL_ENTITIES_OPTION, ...mappedEntities];
	}

	get roleOptions() {
		const mappedRoles = [];

		for (let role in USER_ROLES) {
			mappedRoles.push({
				value: USER_ROLES[role].toString(),
				label: role
			});
		}

		return [ALL_ROLES_OPTION, ...mappedRoles];
	}
	
	get tierOptions() {
		const mappedTiers = ENTITY_TIERS_LIST.map((tier) => {
			return {
				value: tier.id.toString(),
				label: tier.name
			};
		});
		
		return [ALL_TIERS_OPTION, ...mappedTiers];
	}
	
	render() {
		return (
			<Card className="options pad">
				<div className={`filters-container text-uppercase ${this.props.className}`}>
					manage
          			<OptionsSelector
						onSelection={this.onUserAssignedStatusFilterChange}
						options={USER_ASSIGNED_STATUS_OPTIONS}
						value={this.state.selectedAssignedStatus}
					/>
					{this.state.hideAllExceptAssignedStatus ? null :
						<span className="users-filter-management">
							for
                        	<OptionsSelector
								onSelection={this.onEntityFilterChange}
								options={this.entityOptions}
								isSearchable={true}
								value={this.state.selectedEntityId} 
								position="bottom"
							/>
							showing
                        	<OptionsSelector
								onSelection={this.onUserActiveStatusFilterChange}
								options={USER_ACTIVE_STATUS_OPTIONS}
								value={this.state.selectedActiveStatus} 
							/>
							users with
                        	<OptionsSelector
								onSelection={this.onUserRoleFilterChange}
								options={this.roleOptions}
								value={this.state.selectedRole} 
							/>
							from
							<OptionsSelector
								onSelection={this.onUserTierFilterChange}
								options={this.tierOptions}
								value={this.state.selectedTier}
							/>
						</span>
					}
				</div>
				<button className="btn btn-xs btn-outline btn-primary" onClick={this.handleResetFilters}><X size={14} /> reset filters</button>
			</Card>
		);
	}
}

UserFilters.propTypes = {
	/** @prop {func} onFiltersChange fire when any of the filters are changed */
	onFiltersChange: PropTypes.func,

	className: PropTypes.string,

	entities: PropTypes.array
};

export default UserFilters;