import React from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router';
import * as Icons from 'react-feather';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { getElapsedTime } from '../../helpers/utils';
import Card from '../common/card';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import PermissionsContext from '../../scenes/contexts/permissionsContext';
import { PERMISSIONS, userHasPermissions } from '../../helpers/permissionsHelper';
import { isOnMobile } from '../../helpers/utils';
import FeaturesContext from "../../scenes/contexts/featuresContext";
import { COMPARATORS } from '../../helpers/featuresHelper';
import { ENTITY_FEATURES } from 'hollerlive-shared/constants';
import NoPermissionLabel from '../common/noPermissionLabel';
import messages from '../../helpers/messages';
import { useTranslation } from 'react-i18next';

const TopicsGrid = ({topics, onTopicDelete, onShowLinksModal}, context) => {

    const { t } = useTranslation();

    const onConfirmDeleteTopic = (topicId) => {

        const topicName = (topics.find(t => t.id === topicId)).name;

        const configModal = {
            title: t('modal_topic_delete_title'),
            message: t('modal_topic_delete_message', { topicName }),
            onClose: () => context.hideModal(),
            onConfirm: () => {
                onTopicDelete(topicId);
                context.hideModal();
            },
        };
        context.showModal(CONFIRM_MODAL, configModal);
    };

    const buttonsColumnsWidth = !isOnMobile() ? '25%' : '5em';
    const modifiedOnColumnWidth = !isOnMobile() ? '25%' : '5em';

    const modifiedOnDateStamp = (modifiedOn) => { 
        const showShortDateStamp = isOnMobile();
        return modifiedOn ? getElapsedTime(modifiedOn, false, showShortDateStamp) : '';
    };
    const analyticsButtons = (topicId) => {
        return(
            <div>
                <Link to={`/topics/details/${topicId}/graphs`} className="btn btn-xs btn-outline btn-primary"><Icons.BarChart size={14} />
                    {isOnMobile() ? '' : ` ${t('topic_management_row_graph')}`}
                </Link>&nbsp;
                {isOnMobile() ? <br/> : ''}
                <button type="button" className="btn btn-xs btn-outline btn-info" onClick={ () => onShowLinksModal(topicId) }><Icons.Link size={16} />
                    {isOnMobile() ? '' : ` ${t('topic_management_row_links')}`}
                </button>
            </div>        
        );
    };
    
    const actionsButtons = (topicId) => {
        return(
                <div>
                    <Link
                        to={`/topics/createfromtemplate/${topicId}`}
                        className="btn btn-outline btn-xs btn-success"
                    >
                        <Icons.Copy size={14}/>{isOnMobile() ? '' : ` ${t('topic_management_row_use_as_template')}`}
                    </Link>&nbsp;
                    {isOnMobile() ? <br/> : ''}
                    <FeaturesContext hideIfNoPermission={true} requiredFeatures={[{
                        name: ENTITY_FEATURES.TOPIC_EDIT,
                        compare: COMPARATORS.IS_TRUE
                    }]}>
                        <Link to={`/topics/edit/${topicId}`} className="btn btn-outline btn-xs btn-primary">
                            <Icons.Edit3 size={14}/>{isOnMobile() ? '' : `  ${t('topic_management_row_edit')}`}
                        </Link>
                    </FeaturesContext>
                    &nbsp;
                    {isOnMobile() ? <br/> : ''}
                    <button
                        type="button"
                        onClick={() => onConfirmDeleteTopic(topicId)}
                        className="btn btn-xs btn-outline btn-danger"
                    >
                        <Icons.XCircle size={14}/>{isOnMobile() ? '' : `  ${t('topic_management_row_delete')}`}
                    </button>
                </div>

        );
    };
    
    const options = {
        defaultSortName: 'name',
        defaultSortOrder: 'asc'
    };

    const noRightsCreateButton = <button className="btn-float btn-transparent btn-float-second">
        <NoPermissionLabel message={messages.permissions.features.topicLimitReached} />
        <div className="btn btn-primary"><Icons.PlusCircle size={32}/></div>
    </button>;

    return (
        <div>
            <h2 className="page-heading">{t('topic_management_title')}</h2>

            <Card className="topics-list">
                <BootstrapTable
                    data={topics}
                    options={options}
                    striped
                    hover
                    condensed
                    bordered={false}
                    tableHeaderClass='bg-dark'
                >
                    <TableHeaderColumn dataField='id' isKey dataSort={true} hidden>
                        {t('topic_management_header_id')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='name'
                        filter={{ type: 'TextFilter', delay: 500 }}
                        dataSort={true}
                        tdStyle={{ whiteSpace: 'normal' }}
                    >
                        {t('topic_management_header_name')}
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='modifiedOn' width={modifiedOnColumnWidth} dataSort={true} dataFormat={modifiedOnDateStamp}>
                        {t('topic_management_header_modified')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='id'
                        width={buttonsColumnsWidth}
                        dataFormat={analyticsButtons}
                        tdStyle={{ whiteSpace: 'normal' }}
                    >
                        {t('topic_management_header_analytics')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='id'
                        width={buttonsColumnsWidth}
                        dataFormat={actionsButtons}
                        tdStyle={{ whiteSpace: 'normal' }}
                        hidden={!userHasPermissions([PERMISSIONS.EDIT_TOPICS], context.user)}
                    >
                        {t('topic_management_header_actions')}
                    </TableHeaderColumn>
                </BootstrapTable>
            </Card>
    
            {!isOnMobile() &&
            <PermissionsContext 
                hideIfNoPermission={true} 
                requiredPermissions={[PERMISSIONS.CREATE_TOPICS]}
                className='btn-float btn-float-second'
                renderOtherwise={noRightsCreateButton}
            >
                {/*TODO: This works but should really fix the css, completely broken for floating buttons. */}
                <FeaturesContext 
                    requiredFeatures={[{name: ENTITY_FEATURES.NUMBER_OF_TOPICS, compare: COMPARATORS.GREATER_THAN_OR_ZERO, value: topics.length}]}
                    renderOtherwise={noRightsCreateButton}
                >
                    <Link to="topics/create" className="btn-float btn-float-second">
                        <label>{t('topic_management_button_create_new')}</label>
                        <div className="btn btn-primary"><Icons.PlusCircle size={32}/></div>
                    </Link>
                </FeaturesContext>
            </PermissionsContext>}
        </div>
    );
};

TopicsGrid.propTypes = {
    topics: PropTypes.array.isRequired,

    /** @prop {func} onTopicDelete delete topic by id handler
     * @param {string} topicId the topic id to be deleted
     */
    onTopicDelete: PropTypes.func,
    onShowLinksModal: PropTypes.func,
};

TopicsGrid.contextTypes = {
    hideModal: PropTypes.func,
    showModal: PropTypes.func,
    user: PropTypes.object
};


export default TopicsGrid;
