export default class { 
	static findElementByClassName(root, className=null){
		let foundElements = [];
		
		if (root.childNodes) {
			_recurseFindByClassName(root.childNodes, className, foundElements);
		}

		return foundElements;
	}
}

function _recurseFindByClassName (nodes, className, foundElements){
	nodes.forEach(n => {
		if (n.className) {
			if (typeof n.className === "string" && n.className.indexOf(className) > -1){
				foundElements.push(n);
			}
		}

		if (n.childNodes) {
			_recurseFindByClassName(n.childNodes, className, foundElements);
		}
	});
}