import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Minus, Square, Star } from 'react-feather';

import './css/card.css';
class Card extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isCurrentlyCollapsed: props.isCollapsed,
			isForcedCollapsed: props.isCollapsed
		};
		
		this.toggleCollapseCard = this.toggleCollapseCard.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState){
		if (nextProps.isCollapsed !== prevState.isForcedCollapsed) {
			return { isForcedCollapsed: nextProps.isCollapsed };
		}

		return null;
	}

	componentDidUpdate (prevProps, prevState) {
		if (prevState.isForcedCollapsed !== this.state.isForcedCollapsed) {
			this.setState({ isCurrentlyCollapsed: this.state.isForcedCollapsed });
		}
	}
	
	toggleCollapseCard(){
		const cardWillBeCollapsed = !this.state.isCurrentlyCollapsed; 

		this.props.onCollapsedChange(cardWillBeCollapsed);
		this.setState({ isCurrentlyCollapsed: cardWillBeCollapsed });
	}

	render() {
		const { hoffset, inverse, className, pad, title, children, collapsible, cardActions, nonFreeOverlay, maxWidth, centered } = this.props;
		const { isCurrentlyCollapsed } = this.state;
		
		const inverseClass = inverse ? "dark" : "light";
		let cardClasses = `card ${className || ""}`;

		if (!pad) {
			cardClasses += ` ${inverseClass}`;
		} else {
			cardClasses += ` pad`;		
		}

		if (hoffset === false) {
			cardClasses += ' no-h-offset';
		}

		if (isCurrentlyCollapsed) {
			cardClasses += ' collapsed';
		}
		
		const styles = {};
		if (maxWidth) {
			styles.maxWidth = maxWidth;
		}

		if (centered) {
			styles.marginLeft = 'auto';
			styles.marginRight = 'auto';
		}

		const isTitleVisible = (title && title.length > 0) || collapsible;
		
		return (
			<div className={cardClasses} style={styles}>
				{nonFreeOverlay && <div className="card-overlay text-center">
					<p>
						<span className="text-bold text-label text-info"><Star size="16" /> Unavailable for your plan</span><br /><br />
						Please, contact support for more information on kazva.bg paid dashboard accounts.
					</p>
				</div>}

				{isTitleVisible && 
					<h4 className="clearfix">
						<span className="export-pdf-card-title">{title}</span>

						<div className={`btngroup pull-right`}>
							{cardActions}

							{collapsible && isCurrentlyCollapsed &&
								<button type="button" className="btn transparent" onClick={this.toggleCollapseCard}><Square size={18} /></button>
							}
							{collapsible && !isCurrentlyCollapsed &&
								<button type="button" className="btn transparent" onClick={this.toggleCollapseCard}><Minus size={18} /></button>
							}
						</div>
					</h4>
				}

				<div className={`card-content ${isCurrentlyCollapsed ? 'hide animate' : 'show animate'}`}>
					{children}
				</div>

				
			</div>
		);
	}
}

Card.propTypes = {
	/** @prop {string} title the card title. Leave blank if no title is required */
	title: PropTypes.string,

	/** @prop {bool} inverse card with a darker style. Defaults to false */
	inverse: PropTypes.bool,

	/** @prop {bool} pad the card will have styling to act as a pad contaner. Defaults to false */
	pad: PropTypes.bool,

	/** @prop {bool} hoffset horizontal offset: ads left and right margins. Defaults to true */
	hoffset: PropTypes.bool,

	/** @prop {bool} collapsible will enable collapse/expand on card content */
	collapsible: PropTypes.bool,

	/** @prop {bool} isCollapsed force collapsed from outside */
	isCollapsed: PropTypes.bool,

	/** @prop {func} onCollapsedChange define action to do when manually collapsed from inside */
	onCollapsedChange: PropTypes.func,

	cardActions: PropTypes.object,

	className: PropTypes.string,

	/** @param {bool} nonFreeOverlay and overlay to show a non-free account tier message */
	nonFreeOverlay: PropTypes.bool,

	/** @prop {any} children card yield content */
	children: PropTypes.any,

	/** @prop {string} maxWidth maximum width set as a css rule. Accepts value in px or % */
	maxWidth: PropTypes.string,
	
	/** @prop {string} centered the card should display centered with margin left and right set to auto */
	centered: PropTypes.bool
};

export default Card;