import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paperclip, X } from 'react-feather';
import PopoverButton from '../popover/popoverButton';

import './css/uploadFiles.css';

class UploadFiles extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			files: props.files ? props.files : [],
		};

		this.handleUploadFile = this.handleUploadFile.bind(this);
		this.removeFile = this.removeFile.bind(this);
	}

	handleUploadFile(e) {
		const files = [];
            
        // TODO: check file size before adding it - 5MB max (limit of 5242880 in backend)

		for (let i = 0; i < e.target.files.length; i++) {
			files.push(e.target.files[i]);
		}

        const excludeExistingFiles = files.filter(f => {
			const foundInExisting = this.state.files.find(ef => ef.name === f.name);
			return foundInExisting === undefined;
		});

		let updatedFiles = [...this.state.files, ...excludeExistingFiles];
		
		if (this.props.maxFiles) {
			updatedFiles = updatedFiles.splice(0, this.props.maxFiles);
		}
		
		this.setState({
			files: updatedFiles
		}, () => { 
			this.props.onAttach(updatedFiles); 
		});
	}

	removeFile(fileName) {
		const updatedFiles = this.state.files.filter(f => f.name !== fileName);
		this.setState({ 
			files: updatedFiles 
		}, () => { 
			this.props.onAttach(updatedFiles); 
		});
	}

	get attachedFilesCount() {
		return this.state.files.length;
	}

	get btnLabel() {
		const { label } = this.props;
		return label ? label : "attach files";
	}

	get getFilesListItems() {
		return this.state.files.map((f, i) => {
			return <li key={i}>
						<span className="file-name">{f.name}</span>
						<X size={16} onClick={() => this.removeFile(f.name)} />
					</li>;
		});
	}

	get popoverPosition() {
		const { filesListPosition } = this.props;

		return filesListPosition ? filesListPosition : 'left';
	}

	get shouldDisableAttach() {
		if (this.props.maxFiles) {
			return this.attachedFilesCount >= this.props.maxFiles;
		}

		return false;
	}

	render() {
		const { className, filesListWidth, maxFiles } = this.props;

		return (
			<div className={`upload-control  ${className}`}>
				<button type="button" className={`btn btn-outline btn-upload ${this.shouldDisableAttach ? 'disabled' : ''}`} disabled={this.shouldDisableAttach}>
					<Paperclip size={18} /> <span>{this.btnLabel}</span>
					<input disabled={this.shouldDisableAttach} type="file" multiple onChange={this.handleUploadFile} />
				</button>

				{this.attachedFilesCount > 0 &&
				<PopoverButton 
					className={`btn transparent`}
					text={<span><button type="button" className="btn btn-link">{`${this.attachedFilesCount} files`}</button> attached</span>}
					position={this.popoverPosition}
					popoverWidth={filesListWidth}
					forceClose={this.attachedFilesCount === 0}
				>
					<ul className="uploaded-files-list">
						{this.getFilesListItems}
					</ul>
				</PopoverButton>
				}

				{maxFiles &&
					<p className="message"><small>maximum of {maxFiles} files allowed</small></p>
				}
			</div>
		);
	}
}

UploadFiles.propTypes = {
	className: PropTypes.string,

	label: PropTypes.string,

	/** @prop {array} files currently selected files. Default to empty array */
	files: PropTypes.array,

	/** @prop {string} filesListPosition the position of the files list popover. 
	 * Use it depending on the position of the "attached files" label. Possible values: "left|right|top|bottom". Defaults to "left" 
	 * */
	filesListPosition: PropTypes.string,

	/** @prop {number} filesListWidth the width of the file list popover in pixels. defaults to "auto" */
	filesListWidth: PropTypes.number,

	/** @prop {number} maxFiles the max files available to upload. If reached, the upload button is disabled */
	maxFiles: PropTypes.number,

	/** @prop {func} onAttach action invoked on each proccessed file */
	/** @param {array} files array of currently processed files */
	onAttach: PropTypes.func.isRequired
};

export default UploadFiles;