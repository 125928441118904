import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import ReactMde from 'react-mde';
import Showdown from 'showdown';

import "react-mde/lib/styles/css/react-mde-all.css";
import './css/contentEditPage.css';
import SidePanel from '../../components/common/sidePanel';
import ServerErrorMessage from '../../components/common/serverErrorMessage';
import * as fileActions from '../../actions/filesActions';
import * as modalActions from '../../actions/modalActions';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';

export default (pageMode, isReduxConnected = true) => {
	const ContentEditPage = ({ contents, isLoading, isLoaded, requestError, params, actions, router }) => {
		const { fileName } = params;
		const [pageContents, setPageContents] = useState('');
		const prevIsLoading = useRef();
		const [newFileName, setNewFileName] = useState('');
		const [selectedTab, changeTab] = useState('write');
		const isDirty = useRef(false);

		useEffect(() => {
			if(pageMode.create && !isDirty.current) {
				actions.clearMarkdownFileErrors();
			}
		}, [newFileName]);
		
		useEffect(() => {
			if (pageMode && pageMode.edit) {
				actions.clearMarkdownFileErrors();
				actions.getMarkdownFileContentsByName(fileName);
			} 
		}, []);
		
		useEffect(() => {
			if (prevIsLoading.current && isLoaded) {
				if (contents) {
					setPageContents(contents);
				}
			}
		}, [contents, isLoaded]);
		
		useEffect(() => {
			prevIsLoading.current = isLoading;
		});
		
		function handleNewFileNameChange(event) {
			setNewFileName(event.target.value);
			actions.clearMarkdownFileErrors();
			isDirty.current = true;
		}

		function handleContentsChange(value) {
			setPageContents(value);
			if(requestError){
				actions.clearMarkdownFileErrors();
			}
			isDirty.current = true;
		}
		
		function handleSubmit() {
			if (pageMode.edit) {
				actions.editMarkdownFileContentsByName(fileName, pageContents);
			} else {
				actions.createMarkdownFileContentsByName(newFileName, pageContents);
			}
			isDirty.current = false;
		}

		function handleClosePanelClick(dirty) {
			const location = '/admin/pages';

			if(!dirty) {
				router.push(location);
			} else {
				const configCancelModal = {
					title: "Confirm Cancel Changes",
					message: "Are you sure you want to cancel the changes you made?",
					onClose: () => { 
						actions.hideModal();
					},
					onConfirm: () => {
						browserHistory.push(location);
						actions.hideModal();
					},
				};

				actions.showModal(CONFIRM_MODAL, configCancelModal);
			}
		}

		const converter = new Showdown.Converter({
			tables: true,
			simplifiedAutoLink: true,
			strikethrough: true,
			tasklists: true
		});
		
		const className = pageMode.edit
			? 'static-page-edit uitest-static-page-edit'
			: 'static-page-create uitest-static-page-create';
		const pageHead = pageMode.edit ? `Edit ${fileName ? fileName : ""}` : "Create static text";
		const mdeContainerStyle = { height: pageMode.create ? `calc(100% - 65px)` : '100%' };
		const textAreaProps = Object.assign(
			{ style: { height: `calc(100% - 65px` } },
			pageMode.edit ? { autoFocus: 'autofocus' } : {}
		);
		
		return (
			<div className='contentEditPage'>
				<SidePanel
					className={className}
					title={pageHead}
					onClose={() => handleClosePanelClick(isDirty.current)}
					showButtons={true}
					okButtonText={pageMode.edit ? 'Update' : 'Create'}
					okButtonType='button'
					okButtonDisabled={
						(pageMode.create && newFileName.length === 0) || (pageMode.edit && !isDirty.current)
					}
					onOk={handleSubmit}
				>
					{requestError && (
						<ServerErrorMessage
							errors={{ serverError: requestError.message }}
							name='serverError'
							className='control-label'
							component='div'
						/>
					)}
					{pageMode.create && (
						<div className='form-group fileNameFieldSet'>
							<span className='col-md-2 labelContent'>
								<label className='control-label' htmlFor='fileName'>
									file name
								</label>
							</span>
							<span className='col-md-8'>
								<input
									className='form-control'
									type='text'
									id='fileName'
									onChange={handleNewFileNameChange}
									autoFocus={pageMode.create}
								/>
							</span>
						</div>
					)}
					<div className='mde-container' style={mdeContainerStyle}>
						<ReactMde
							value={pageContents}
							generateMarkdownPreview={markdown => Promise.resolve(converter.makeHtml(markdown))}
							onChange={handleContentsChange}
							onTabChange={changeTab}
							selectedTab={selectedTab}
							textAreaProps={textAreaProps}
						/>
					</div>
				</SidePanel>
			</div>
		);
	};

	ContentEditPage.propTypes = {
		/** @param {string} contents - holds the textual conetents of the file */
		contents: PropTypes.string,
		/** @param {bool} isLoading - determines whether loading is in progress */
		isLoading: PropTypes.bool,
		/** @param {bool} isLoaded - determines whether the content is loaded */
		isLoaded: PropTypes.bool,
		/** @param {object} requestError - server errors object */
		requestError: PropTypes.object,
	};

	const mapStateToProps = (state) => {
		const { contents, isLoading, isLoaded, requestError } = state.files;
		return {
			contents,
			isLoading,
			isLoaded,
			requestError
		};
	};
	
	const mapDispatchToProps = (dispatch) => {
		return {
			actions: bindActionCreators({ ...fileActions, ...modalActions }, dispatch)
		};
	};

	if (!isReduxConnected) {
		return ContentEditPage;
	}
	return connect(mapStateToProps, mapDispatchToProps)(ContentEditPage);
};
