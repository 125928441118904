import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage} from 'formik';
import sharedValidators from 'hollerlive-shared/validators';

import BSFormControl from './form/bsFormControl';
import ServerErrorMessage from './serverErrorMessage';
import { Send } from 'react-feather';
import { Toggle } from './form/toggle';
import formValidators from '../../helpers/formValidators';
import Messages from '../../helpers/messages';
import { isEmpty } from 'lodash';

import UploadFiles from './form/uploadFiles';
import { SUBJECT_OPTIONS } from '../../helpers/contactSupport';
import { mapToFormModel } from '../../actions/models/contactSupportModel';
import './css/contactForm.css';

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attachments: [],
            showRequestErrors: false,
        };

        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDirtyState = this.handleDirtyState.bind(this);
        this.onAttachFiles = this.onAttachFiles.bind(this);
        this.hasUnsavedChanges = false;
        this.setSubmitting = null;
    }

    componentDidUpdate() {
        if ( this.props.requestErrors && !isEmpty(this.props.requestErrors) && this.setSubmitting && !this.state.showRequestErrors ) {
            this.setState({ showRequestErrors: true });
            this.setSubmitting(false);
        }
    }

    handleSubmit(values, { setSubmitting }) {
        let valuesToSend = Object.assign(
            {}, {
                email: values.email,
                subject: values.subject,
                message: values.message,
                attachments: [...this.state.attachments],
            }
        );

        if ( values.enableTechnicalData ) {
            // Note by MDN: "you really have no guarantee that the browser agent is indeed the one advertised by this property"
            valuesToSend.message = `${valuesToSend.message}
            The following parameters might be of help:
            Client browser window size: ${window.innerWidth}x${window.innerHeight}px
            Screen resolution: ${window.screen.width * window.devicePixelRatio}x${window.screen.height * window.devicePixelRatio}px
            Client user agent: ${window.navigator.userAgent}
            Cookies enabled: ${window.navigator.cookieEnabled}`;
        }

        this.setSubmitting = setSubmitting;
        this.props.onSend(valuesToSend);
    }

    handleCancel() {
        if(this.hasUnsavedChanges && this.props.onDiscardConfirm) {
            this.props.onDiscardConfirm();
        } else {
            this.props.onDiscard();
        }
    }

    validate(values){
        let errors = {};
        const { email, subject, message } = values;
        
        // validate email
        if (!formValidators.required(email)) {
            errors.email = Messages.errors.required;
        } else if(!sharedValidators.isEmailValid(email.trim())){
            errors.email = Messages.errors.email;
        }

        // validate subject
        if (!formValidators.required(subject)) {
            errors.subject = Messages.errors.required;
        }

        // validate message
        if (!formValidators.required(message)) {
            errors.message = Messages.errors.required;
        } 
        
        // hide server errors if form is clean after validation
        if (this.state.showRequestErrors && isEmpty(errors)) {
            this.setState({ showRequestErrors: false });
        }
                
        return errors;
    }

    fieldHasErrors (errors, field) {
        if (
			this.props.requestErrors &&
			this.state.showRequestErrors &&
			this.props.requestErrors[field] &&
			this.props.requestErrors[field].length > 0
		) {
			return true;
		}
		return errors[field] && errors[field].length > 0;
    }

    onAttachFiles(attachedFiles) {
        this.setState({ attachments: attachedFiles });
    }

    _getSubjectOptions(){
		return SUBJECT_OPTIONS.map((sb) => {
			return <option key={sb.id} value={sb.subject}>{sb.subject}</option>;
		});
    }

    handleDirtyState(dirty) {
        if (this.hasUnsavedChanges !== dirty) {
            this.hasUnsavedChanges = dirty;
            if(this.props.onUnsavedChanges) {
                this.props.onUnsavedChanges(dirty);
            }
        }
    }

    render() {
        return (
            <div className="contact-form-inner-content ui-test-contact-form" style={this.props.style ? this.props.style : {}} >
                <Formik 
                    initialValues={mapToFormModel(this.props.data)} 
                    onSubmit={this.handleSubmit}
                    validate={this.validate}
                >
                    {({ values, errors, dirty, setFieldValue, isSubmitting }) =>  {
                    const isFormValid =  dirty && isEmpty(errors); 
                    const disableSendBtn = !(isFormValid) || isSubmitting;
                    this.handleDirtyState(dirty);

                    return (
                        <Form className="text-left contact-form" encType="multipart/form-data">
                            <div className="row-fluid clearfix">
                                <div className="col-md-12 col-xs-12">
                                    {this.state.showRequestErrors && <ServerErrorMessage errors={this.props.requestErrors} name="serverError" className="control-label" component="div" />}
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <div className="form-group">
                                        <BSFormControl label='from' required hasErrors={this.fieldHasErrors(errors, 'email')} >
                                            <label className="sub-label" htmlFor="email">this is your registered email. you may change it to another email</label>
                                            <Field type="text" name="email" className="form-control" value={values.email} />
                                            <ErrorMessage name="email" component="div" className='control-label' />
                                            {this.state.showRequestErrors && <ServerErrorMessage errors={this.props.requestErrors} name="email" className="control-label" component="div" />}
                                        </BSFormControl>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <div className="form-group">
                                        <BSFormControl label='subject' required hasErrors={this.fieldHasErrors(errors, 'subject')} >
                                            <label className="sub-label" htmlFor="subject">choose a request type from the list, or select other</label>
                                            <Field component="select" name="subject" className="form-control" value={values.subject} >
                                                {this._getSubjectOptions()}
                                            </Field>
                                            <ErrorMessage name="subject" component="div" className='control-label'/>
                                            {this.state.showRequestErrors && <ServerErrorMessage errors={this.props.requestErrors} name="subject" className="control-label" component="div" />}
                                        </BSFormControl>
                                    </div>
                                </div>

                                <div className="col-md-12 col-xs-12">
                                    <BSFormControl label='your message' required hasErrors={this.fieldHasErrors(errors, 'message')} >
                                        <label className="sub-label" htmlFor="message">write your request here. we will do our best to address your thoughts as soon as possible via the email you provide</label>
                                        <Field component="textarea" name="message" className="form-control" rows={12} cols={48} value={values.message} />
                                        <ErrorMessage name="message" component="div" className='control-label'/>
                                        {this.state.showRequestErrors && <ServerErrorMessage errors={this.props.requestErrors} name="message" className="control-label" component="div" />}
                                    </BSFormControl>
                                </div>

                                <div className="col-md-12 col-xs-12">
                                    <div className="form-group">
                                        <UploadFiles 
                                            className='text-left'
                                            label="Attach files"
                                            onAttach={this.onAttachFiles}
                                            filesListPosition="right"
                                            filesListWidth={400}
                                            maxFiles={5}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12 col-xs-12">
                                    <div className="form-group">
                                        <Field name="enableTechnicalData">       
                                            {({field}) =>
                                                <div>
                                                    <Toggle value={field.value} onChange={(value) => setFieldValue(field.name, value)} />
                                                    <label className="control-label" htmlFor="enableTechnicalData">include technical data</label>
                                                    <label className="sub-label">This allows us to obtain generic technical information about the device you need support with like screen resolution, browser type and check if cookies are enabled. The information is used for support purposes only.</label>
                                                </div> 
                                            }                             
                                        </Field>
                                        <ErrorMessage name="enableTechnicalData" component="div" className='control-label'/>
                                    </div>
                                </div>
                            </div>


                            <div className="btn-toolbar no-float text-right">
                                <button className="btn btn-lg" type="button" onClick={this.handleCancel}>cancel</button>
                                <button className="btn btn-lg btn-success submit-btn" type="submit" disabled={disableSendBtn}>
                                    <Send size={22} />send
                                </button>
                            </div>  
                        </Form>
                    );}}
                </Formik>
            </div>
        );
    }
}

ContactForm.propTypes = {
	/* @prop {func} onSend action. Should send form data to email
        /** @param {object} values - the form data to be sent as an email */
	onSend: PropTypes.func.isRequired,

	/* @prop {func} onDiscard action to discard sending the contact form */
	onDiscard: PropTypes.func.isRequired,

    /* @prop {func} onDiscardConfirm passed from parent; opens confirm message if there are unsaved changes on an attempt to close */
    onDiscardConfirm: PropTypes.func,
    
    /* @prop {func} onUnsavedChanges passed from parent; sets unsaved changes in parent */
    /** @param {bool} childHasUnsavedChanges true if form state is dirty; false otherwise */
    onUnsavedChanges: PropTypes.func,
    
	/* @prop {object} data existing data to be filled in form, if any */
	data: PropTypes.shape({
		email: PropTypes.string
	}),

	/** @prop {object} requestErrors holds errors from redux state slice to be handled in form */
	requestErrors: PropTypes.object,

	/** @prop {object} style object to be applied to outtermost <div> tag */
	style: PropTypes.object
};

export default ContactForm;