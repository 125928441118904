import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as contactActions from '../actions/contactActions';
import { bindActionCreators } from 'redux';
import ContactForm from '../components/common/contactForm';

export default (isReduxConnected = true) => {
	
	const contactFormPage = ({
		contactSupport,
		auth,
		sendContactSupportEmail,
		clearContactForm,
		onSupportContacted,
		onDiscard,
		onUnsavedChanges,
		onOpenModalConfirm,
		presetData
	}) => {
		const contactSupportData = { ...presetData, email: auth.isAuthenticated ? auth.user.email : '' };
		const onDiscardCallback = () => {
			if (contactSupport.isSent) {
				clearContactForm();
			}
			onDiscard();
		};

		useEffect(() => {
			if (contactSupport.isSent && !contactSupport.contactSupportErrors) {
				if (onSupportContacted) {
					onSupportContacted();
				}
				clearContactForm();
			}
		}, [contactSupport.isSent]);

		return <ContactForm
					onSend={sendContactSupportEmail}
					onDiscard={onDiscardCallback}
					data={contactSupportData}
					onUnsavedChanges={onUnsavedChanges}
					onDiscardConfirm={onOpenModalConfirm}
					requestErrors={contactSupport.contactSupportErrors} />;
	};
	
	contactFormPage.propTypes = {
		/* @prop {object} contactSupport - state-store slice data **/
		contactSupport: PropTypes.shape({
			isSent: PropTypes.bool.isRequired,
			contactSupportErrors: PropTypes.object
		}).isRequired,

		/* @prop {object} auth - state-store authentication object carrying logged-in user **/
		auth: PropTypes.shape({
			isAuthenticated: PropTypes.bool.isRequired,
			user: PropTypes.shape({ email: PropTypes.string }).isRequired
		}).isRequired,

		/* @prop {func} sendContactSupportEmail - action to send contact support request **/
		sendContactSupportEmail: PropTypes.func.isRequired,

		/* @prop {func} clearContactForm - action to clear contact form state **/
		clearContactForm: PropTypes.func.isRequired,

		/* @prop {func} onSupportContacted callback to the parent. Triggered when the contact form had been sent successfully. Optional **/
		onSupportContacted: PropTypes.func,

		/* @prop {func} onDiscard callback to the parent. Triggered when the contact form had been dismissed **/
		onDiscard: PropTypes.func.isRequired,

		presetData: PropTypes.shape({
			email: PropTypes.string,
			subject: PropTypes.string,
			message: PropTypes.string,
			enableTechnicalData: PropTypes.bool
		})
	};
	
	function mapStateToProps(state) {
		return { contactSupport: state.contactSupport, auth: state.auth };
	}
	function mapDispatchToProps(dispatch) {
		const { sendContactSupportEmail, clearContactForm } = contactActions;
		return bindActionCreators(Object.assign({}, { sendContactSupportEmail, clearContactForm }), dispatch);
	}
	
	if (!isReduxConnected) {
		return contactFormPage;
	}
	
	return connect(mapStateToProps, mapDispatchToProps)(contactFormPage);
	
};
