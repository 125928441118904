import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TopicsGrid from '../../components/topics/topicsGrid';
import * as topicActions from '../../actions/topicActions';
import * as entitiesActions from '../../actions/entitiesActions';
import Logger from '../../helpers/logger';
import * as modalActions from '../../actions/modalActions';
import { getTopicLinksModalConfig } from '../../helpers/modalDialogsHelper';
import { TOPIC_LINKS_MODAL } from '../../helpers/modalTypes';

class TopicsPage extends Component {
    constructor(props) {
        super(props);

        this.deleteTopic = this.deleteTopic.bind(this);
        this.handleOpenLinksModal = this.handleOpenLinksModal.bind(this);
    }

    componentDidMount() {
        if (this.props.entities && this.props.entities.length &&
            this.props.topics.length <= 1 && this.props.topicsForEntityLoaded === false) {
            this._loadTopicsForEntity(this.props.entities[0].id);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.entities.length > 0) {
            if (prevProps.entities !== this.props.entities) {
                this._loadTopicsForEntity(this.props.entities[0].id);
            }
        }
    }

    getChildContext() {
        return { 
            showModal: this.props.actions.showModal,
            hideModal: this.props.actions.hideModal,
            user: this.props.user
        };
    }

    deleteTopic(topicId) {
        const topic = this.props.topics.find(t => t.id === topicId);

        if (topic) {
            this.props.actions.deleteTopic(topic.id, topic.name);
        } else {
            Logger.error(`No topic found for id: ${topicId}`);
        }
    }

    handleOpenLinksModal(topicId) {
        const topic = this.props.topics.find(t => t.id === topicId);
        const topicLinksConfig = getTopicLinksModalConfig(topic.id);
        const modalComponentProps = Object.assign({}, topicLinksConfig,
            {
                onClose: this.props.actions.hideModal
            });
        this.props.actions.showModal(TOPIC_LINKS_MODAL, modalComponentProps);
    }

    _loadTopicsForEntity(entityId) {
        this.props.actions.loadTopicsForEntity(entityId);
    }
    
    render(){
        const topics = this.props.topics;
        
        return (
            <TopicsGrid topics={topics} onTopicDelete={this.deleteTopic} onShowLinksModal={this.handleOpenLinksModal} />
        );
    }  
}

TopicsPage.propTypes = {
    topics: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    entities: PropTypes.array,
    topicsForEntityLoaded: PropTypes.bool,
    user: PropTypes.object
};

TopicsPage.childContextTypes = {
    showModal: PropTypes.func,
    hideModal: PropTypes.func,
    user: PropTypes.object
};

function mapStateToProps(state){
    const topicsForEntityLoaded = state.topics.length > 0 && state.topics.some(t => t.isParent);

    return {
        topics: state.topics,
        lastLoadedTopic: state.topic,
        topicsForEntityLoaded: topicsForEntityLoaded,
        entities: state.entities,
        user: state.auth.user,
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(Object.assign({}, topicActions, entitiesActions, modalActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicsPage);