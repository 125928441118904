import React from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-feather';
import { Link } from 'react-router';
import logger from '../../helpers/logger';
import { useTranslation } from 'react-i18next';

const LAST_SEEN_LOCAL_KEY = "lastSeenVer";
const CHANGELOG_URL = "/static/Changelog";

const getLastSeenVersion = () => {
    const version = localStorage.getItem(LAST_SEEN_LOCAL_KEY);
    return version;
};

const setLastSeenVersion = (version) => {
    localStorage.setItem(LAST_SEEN_LOCAL_KEY, version);
};

const hasNewSemanticVersion = (currentVersion, lastSeenVersion) => {
    const pattern = /\d{1}\.\d+\.\d+/i;
    if(!currentVersion || !pattern.test(currentVersion)){
        logger.error("Invalid or missing app version: " + currentVersion);
        return false;
    }

    if(!lastSeenVersion){
        return true;    // no prior value, set one
    } 
    if(!pattern.test(lastSeenVersion)){
        logger.warn("Invalid format of local storage version: " + currentVersion);
        return true;    // in this case return true because we want to fix the value
    }

    const currVersionParts = currentVersion.substring(0, currentVersion.lastIndexOf("."));
    const lastSeenParts = lastSeenVersion.substring(0, lastSeenVersion.lastIndexOf("."));

    if(currVersionParts !== lastSeenParts){
        return true;
    } else {
        return false;
    }
};

const AppVersion = ({currentVersion, clickable}) => {

    const { t } = useTranslation();

    const lastSeenVersion = getLastSeenVersion();
    let hasNewVersion = false;;
    if(currentVersion !== lastSeenVersion && hasNewSemanticVersion(currentVersion, lastSeenVersion)){
        hasNewVersion = true;
    }

    const handleClick = () => {
        setLastSeenVersion(currentVersion);
    };

    return (
        <React.Fragment>
            {clickable &&
                <Link to={CHANGELOG_URL} className="text-info" onClick={handleClick}>
                    {hasNewVersion ? <><Icon.Bell size={18} /> <span className="notification">!</span></> : <Icon.Bell size={18} className="text-muted" />}
                </Link>
            }

            <Icon.Info size={18} className="text-muted" /> 
            <div className="pull-left">
                <span className="text-muted">{hasNewVersion ? <strong>{currentVersion}</strong> : <span>{currentVersion}</span>}</span> 
                <small className="text-muted">{hasNewVersion ? <strong>{t('footer_app_version')}</strong> : <span>{t('footer_app_version')}</span>}</small>
            </div>
        </React.Fragment>
    );
};

AppVersion.propTypes = {
    currentVersion: PropTypes.string,
    clickable: PropTypes.bool,
};

export default AppVersion;
