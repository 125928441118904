import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { topicReducer, channelTopicsReducer, adminTopicsReducer } from './topicReducer';
import { topicBuilderReducer } from './topicBuilderReducer';
import { votesReducer } from './votesReducer';
import { topicsTreeReducer } from './topicsTreeReducer';
import modalReducer from './modalReducer';
import auth from './authReducer';
import notification from './notificationsReducer';
import requestStatus from './requestStatus';
import { entityBuilderReducer } from './entityBuilderReducer';
import { entitiesListReducer } from './entitiesListReducer';
import { userEntitiesReducer } from './userEntitiesReducer';
import { usersReducer } from './usersReducer';
import { qnaReducer } from './qnaReducer';
import { feedReducer } from './feedReducer';
import contactReducer from './contactReducer';
import filesReducer from './filesReducer';
import entityLiveVotesReducer from './entityLiveVotesReducer';
import { LOGOUT_USER_SUCCESS } from '../actions/actionTypes';

const appReducer = combineReducers({
    topics: topicReducer,
    topicForEdit: topicBuilderReducer,
    topicsTree: topicsTreeReducer,
    votesForTopics: votesReducer,
    auth,
    loadingBar: loadingBarReducer,
    requestStatus,
    notification,
    entities: userEntitiesReducer,
    entitiesList: entitiesListReducer,
    entityForEdit: entityBuilderReducer,
    modal: modalReducer,
    channelTopics: channelTopicsReducer,
    adminTopics: adminTopicsReducer,
    usersData: usersReducer,
    qnaForTopics: qnaReducer,
    votesFeed: feedReducer,
    contactSupport: contactReducer,
    files: filesReducer,
    votesForEntity: entityLiveVotesReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER_SUCCESS) {
        // don't want to reset the whole state, so we leave out these state pieces
        const { notification, auth } = state;
        state = { notification, auth } ;
    }
    
    return appReducer(state, action);
};

export default rootReducer;