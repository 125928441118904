import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import { validateUser } from '../../actions/models/userModel';
import EditUserForm from '../../components/users/editUserForm';
import { Key } from 'react-feather';
import { browserHistory } from 'react-router';
import { mapToFormModel } from '../../actions/models/userModel';
import { loadProfile, updateProfile, clearErrors } from '../../actions/authActions';
import './css/userEditProfile.css';
import i18n from '../../i18n';

export default (isPlainCompnent = false) => {
	class UserEditProfile extends Component {
		constructor(props) {
			super(props);
			this.state = { 
				shouldChangePasswordModalOpen: false,
				t: i18n.t.bind(i18n)
			};
			this.validate = this.validate.bind(this);
			this.handleChangePasswordClick = this.handleChangePasswordClick.bind(this);
			this.handleSubmit = this.handleSubmit.bind(this);
			this.handleCancelClick = this.handleCancelClick.bind(this);
			this.notifyOnDirty = this.notifyOnDirty.bind(this);
			this.notifyParent = this.notifyParent.bind(this);
			this.handleDiscard = this.handleDiscard.bind(this);
			this.hasUnsavedChanges = undefined;
			this.isSubmitting = false;
		}
		componentDidMount() {
			this.props.loadProfile();
		}
		componentDidUpdate(prevProps, prevState, snapshot) {
			if (prevState.shouldChangePasswordModalOpen && !this.state.shouldChangePasswordModalOpen) {
				// clear changePassword intent:
				this.notifyParent(this.hasUnsavedChanges, this.state.shouldChangePasswordModalOpen);
			}
			if (!prevProps.hasOpenConfirm && this.props.hasOpenConfirm) {
				// allow closing in confirmation dialog:
				this.notifyParent(false, this.state.shouldChangePasswordModalOpen);
			}
			if (!prevProps.requestFinished && this.props.requestFinished) {
				if (isEmpty(this.props.requestErrors) && this.isSubmitting) {
					this.handleDiscard();
				}
			}
		}

		static getDerivedStateFromProps(props, state) {
			const newState = {};
			if (state.shouldChangePasswordModalOpen && !props.hasOpenConfirm) {
				newState.shouldChangePasswordModalOpen = false;
			}
			if (!isEmpty(newState)) {
				return newState;
			}
			return null;
		}
		
		validate(values) {
			return validateUser(values, { shouldValidateEmail: false, shouldValidatePassword: false });
		}
		
		notifyParent(isDirty, passwordChangeIntentSet = false) {
			if (this.props.onChanged && typeof this.props.onChanged === 'function') {
				this.props.onChanged(isDirty, passwordChangeIntentSet);
			}
		}
		handleDiscard() {
			if (this.props.onDiscard) {
				this.props.onDiscard();
				return true;
			}
			return false;
		}
		
		handleChangePasswordClick() {
			// mark changePassword intent:
			this.notifyParent(this.hasUnsavedChanges, true);
			if (this.hasUnsavedChanges) {
				if (this.props.onOpenModalConfirm) {
					this.props.onOpenModalConfirm();
				}
				this.setState({ shouldChangePasswordModalOpen: true });
			} else {
				if (!this.handleDiscard()) {
					browserHistory.push('/changepassword'); // non-modal redirects to change pass page
				}
			}
		}
		
		handleSubmit(userValues) {
			this.props.updateProfile(userValues);
		}
		
		handleCancelClick() {
			if (this.hasUnsavedChanges) {
				if (this.props.onOpenModalConfirm) {
					this.props.onOpenModalConfirm();
				}
			} else {
				if (!this.handleDiscard()) {
					browserHistory.push('/');// non-modal cancelation redirects to home
				}
			}
		}
		
		notifyOnDirty(dirty, isSubmitting) {
			if (this.hasUnsavedChanges !== dirty) {
				this.hasUnsavedChanges = dirty;
				if (this.props.onUnsavedChanges) {
					this.props.onUnsavedChanges(dirty);
				}
			}
			this.isSubmitting = isSubmitting;
			this.notifyParent(dirty);
		}
		
		render() {
			const { user, auth, requestErrors, resetRequestErrors } = this.props;
			const t = this.state.t;
			return (
				!isEmpty(user) && (
					<Formik initialValues={user}
							validate={this.validate}
							onSubmit={this.handleSubmit}
							enableReinitialize={true}>
						{({ values, touched, errors, dirty, setFieldValue, setValues, setTouched,
						  	setSubmitting, isSubmitting }) => {
							const isFormValid = dirty && isEmpty(errors);
							const shouldDisableSaveButton = !(isFormValid) || isSubmitting;
							this.notifyOnDirty(dirty, isSubmitting);
							return (
								<Form className='user-edit-profile text-left'>
									<EditUserForm
										values={values}
										touched={touched}
										setFieldValue={setFieldValue}
										setValues={setValues}
										setTouched={setTouched}
										hideActiveToggle={true}
										hideEntityChange={true}
										hideEntityName={true}
										hideRoles={true}
										emailReadOnly={true}
										hasSpecialPermissions={auth.hasSpecialPermissions}
										errors={errors}
										requestErrors={requestErrors}
										resetRequestErrors={resetRequestErrors}
										resetSubmitting={() => setSubmitting(false)}
									/>
									<div className='change-password-section'>
										<label className='change-pass-title'>{t('edit_profile_title_change_password')}</label>
										<div>
											<button
												className='btn btn-xs btn-outline btn-primary uitest-changepass-button'
												type='button'
												onClick={this.handleChangePasswordClick}
											>
												{' '}
												<Key size={16} /> {t('edit_profile_button_change_your_password')}
											</button>
										</div>
									</div>
									<div className='btn-toolbar no-float text-right'>
										<button
											className='btn btn-lg btn-success uitest-update-profile-button'
											type='submit'
											disabled={shouldDisableSaveButton}
										>
											{t('modal_general_save')}
										</button>
										<button
											className='btn btn-lg uitest-cancel'
											type='button'
											onClick={this.handleCancelClick}
										>
											{t('modal_general_cancel')}
										</button>
									</div>
								</Form>
							);
						}}
					</Formik>
				)
			);
		}
	}
	
	UserEditProfile.propTypes = {
		user: PropTypes.shape({
			id: PropTypes.number,
			firstName: PropTypes.string,
			lastName: PropTypes.string,
			email: PropTypes.string,
			phone: PropTypes.string,
			timeZone: PropTypes.string,
			roleId: PropTypes.number,
			active: PropTypes.bool,
			language: PropTypes.string
		}),
		auth: PropTypes.shape({ hasSpecialPermissions: PropTypes.bool }).isRequired,
		requestFinished: PropTypes.bool.isRequired,
		requestErrors: PropTypes.shape({
			validationErrors: PropTypes.shape({
				model: PropTypes.string,
				firstName: PropTypes.string,
				lastName: PropTypes.string,
				email: PropTypes.string,
				phone: PropTypes.string,
				locale: PropTypes.string,
				entityId: PropTypes.string,
				language: PropTypes.string
			})
		}).isRequired,
		updateProfile: PropTypes.func.isRequired,
		loadProfile: PropTypes.func.isRequired,
		resetRequestErrors: PropTypes.func.isRequired,
		/* @prop {func} onDiscard callback to the parent **/
		onDiscard: PropTypes.func,
		/* @prop {func} onChange callback to the parent - receives isDirty and passwordChangeIntent **/
		onChanged: PropTypes.func,
		onUnsavedChanges: PropTypes.func,
		onOpenModalConfirm: PropTypes.func,
		hasOpenConfirm: PropTypes.bool
	};
	
	const mapStateToProps = (state, ownProps) => {
		const hasSpecialPermissions = state.auth.hasSpecialPermissions;
		return {
			user: mapToFormModel(state.auth.user),
			auth: {
				hasSpecialPermissions
			},
			requestFinished: state.auth.isLoaded,
			requestErrors: state.auth.errors ? state.auth.errors : {}
		};
	};
	const mapDispatchToProps = dispatch => {
		return bindActionCreators({ loadProfile, updateProfile, resetRequestErrors: clearErrors }, dispatch);
	};
	if (isPlainCompnent) {
		return UserEditProfile;
	}
	return connect(mapStateToProps, mapDispatchToProps)(UserEditProfile);
};
