import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchField from '../common/searchField';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const UserEntitiesGrid = ({ entities }) => {
	const [filterValue, setFilterValue] = useState('');
    
    const onSearch = (value) => {
		setFilterValue(value);
	};
	
	const onClear = () => {
		onSearch("");
    };

    const filteredEntities = (filterValue && filterValue.length > 0) ?
        entities.filter(entity => {
            const stringToSearchIn = entity.name;
            if (stringToSearchIn.toLowerCase().indexOf(filterValue.toLowerCase().trim()) > -1) {
                return entity;
            } else {
                return false;
            }
        })
    :
        entities;
    
    const options = {
        noDataText: "No organizations found for user"
    };
     
    const getLogoByUrl = (url) => {
        const hasUrl = url && url.length > 0;
        return <span className={`logo ${hasUrl ? '' : 'hidden'}`}><img alt="logo" width="28" height="28" src={hasUrl ? url : ''}/></span>;
    };

    const getEntityName = (name) => {
        return <strong>{name}</strong>;
    };
    
    return (
        <div className='user-entities-grid'>
            <SearchField
                onSearch={onSearch} onClear={onClear}
                placeholder='Search organization by name'
                isClearButtonVisible />
            
            <BootstrapTable data={filteredEntities} options={options} scrollTop={'Top'} hover bordered={false} >
                <TableHeaderColumn dataField='id' isKey hidden />
                <TableHeaderColumn dataField='logoUrl' width='8' dataFormat={getLogoByUrl} dataAlign={'start'} />
                <TableHeaderColumn dataField='name' width='128' dataFormat={getEntityName} dataAlign={'start'}/>               
            </BootstrapTable>
        </div>
    );
};

UserEntitiesGrid.propTypes = {
    entities: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        logoUrl: PropTypes.string
    })),
};

export default UserEntitiesGrid;