import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import '../css/dateRange.css';
import Range from './range';
import Calendar from './calendar';
import Header from './calendarHeader';

class DateRange extends React.Component {
    constructor(props){
        super(props);
        
        const nowDate = new Date();
        let selectionStart = Date.now();
        let selectionEnd = Date.now();

        this.state = {
            date: nowDate.setHours(0, 0, 0, 0),
            selectionStart,
            selectionEnd
        };

		this.setRange = this.setRange.bind(this);
        this.prevMonth = this.prevMonth.bind(this);
        this.nextMonth = this.nextMonth.bind(this);
    }

    prevMonth(){
        let date = new Date(this.state.date);
        date.setMonth(date.getMonth() - 1);
        this.setState({date:date.getTime()});
    }

    nextMonth(){
        let date = new Date(this.state.date);
        date.setMonth(date.getMonth() + 1);
        this.setState({date:date.getTime()});
    }

    setRange(selectionStart = 0, selectionEnd = 0){
        this.setState({selectionStart, selectionEnd});
    }

    render(){
       let { date, selectionStart, selectionEnd} = this.state;
       let { onRangeSelected, cancelRangeSelect } = this.props;

       return (
           <div>
                <div className="calendar">
                    <Range cancelRangeSelect={cancelRangeSelect} dateFrom={moment(selectionStart).format('YYYY-MM-DD')} dateTo={moment(selectionEnd).format('YYYY-MM-DD')}/>
                    <Header date={date} prevMonth={this.prevMonth} nextMonth={this.nextMonth}/>
                    <Calendar date={date}
                                indexStart={selectionStart}
                                indexEnd={selectionEnd}
                                setRange={this.setRange}
                                onRangeSelected={onRangeSelected}
                                />
                </div>
                <div className="overlay" />
            </div>
       );
    }
}

DateRange.propTypes = {
    onRangeSelected: PropTypes.func.isRequired,
    cancelRangeSelect: PropTypes.func.isRequired,
    dateFrom: PropTypes.any,
    dateTo: PropTypes.any,
};

export default DateRange;