import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';
import * as usersActions from '../../actions/usersActions';
import * as modalActions from '../../actions/modalActions';
import * as entitiesActions from '../../actions/entitiesActions';
import UsersGrid from '../../components/users/usersGrid';
import UserFilters from '../../components/users/userListFilters';
import ChangeUsersStatus from '../../components/users/changeUserStatus';
import { isEmpty } from 'lodash';
import {
	ACTIVE_STATUSES,
	ASSIGNED_STATUSES,
	ALL_ROLES_OPTION,
	ALL_ENTITIES_OPTION,
	DEFAULT_FILTER_OPTIONS_SELECTION,
	ALL_TIERS_OPTION
} from '../../helpers/user';
import { UserPlus } from 'react-feather';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import { isOnMobile } from '../../helpers/utils';
import './css/users.css';
import { userHasPermissions, PERMISSIONS } from '../../helpers/permissionsHelper';
import Toggle from '../../components/common/form/toggle';
import SidePanel from '../../components/common/sidePanel';

export class UsersPage extends Component {
	constructor(props) {
		super(props);

		let detailsOpen = false;
        let detailsInCreateMode = false;
        if (props.params.id && props.params.id.length > 0) {
            detailsOpen = true;
        } else if (props.location.pathname.indexOf('create') > -1) {
            detailsOpen = true;
            detailsInCreateMode = true;
		}
		
		this.state = {
			selectedFilters: DEFAULT_FILTER_OPTIONS_SELECTION,
			showOnlyOnline: false,
			detailsOpen,
            detailsInCreateMode
		};

		this.changeUsersStatus = this.changeUsersStatus.bind(this);
		this.onFiltersChange = this.onFiltersChange.bind(this);
		this.resetSelectedUsers = this.resetSelectedUsers.bind(this);
		this.handleUsersSelect = this.handleUsersSelect.bind(this);
		this.deleteUsers = this.deleteUsers.bind(this);
		this.changeUsersStatusComponentRef = React.createRef();
		this.handleToggleOnlyOnlineUsersClick = this.handleToggleOnlyOnlineUsersClick.bind(this);
		this.handleClosePanelClick = this.handleClosePanelClick.bind(this);
        this.handleDetailsOpen = this.handleDetailsOpen.bind(this);
	}

	componentDidMount() {
		this.props.actions.getAllUsers();
		if (!this.props.entities || this.props.entities.length === 0) {
			this.props.actions.getAllEntities();
		}
	}

	componentWillUnmount() {
		this.resetSelectedUsers();
	}

	changeUsersStatus(activeStatus) {
		const selectedUsersIds = this.props.users.filter(user => user.isSelected).map(user => user.id);
		const actionName = activeStatus ? 'activate' : 'deactivate';
		const { actions } = this.props;
		const modalConfig = {
			title: `Confirm ${ actionName }`,
			message: `Are you sure you want to ${ actionName } the ${selectedUsersIds.length} selected users?`,
			onClose: () => actions.hideModal(),
			onConfirm: () => {
				actions.changeUsersStatus(selectedUsersIds, activeStatus);
				actions.hideModal();
			}
		};
		actions.showModal(CONFIRM_MODAL, modalConfig);
	}
	
	deleteUsers() {
		const selectedUsersIds = this.props.users.filter(user => user.isSelected).map(user => user.id);
		const { actions } = this.props;
		const modalConfig = {
			title: `Confirm deletion of users`,
			message: `Are you sure you want to delete the ${selectedUsersIds.length} selected users permanently?`,
			onClose: () => actions.hideModal(),
			onConfirm: () => {
				actions.deleteUsers(selectedUsersIds);
				actions.hideModal();
			}
		};
		actions.showModal(CONFIRM_MODAL, modalConfig);
	}

	onFiltersChange(filters) {
		this.resetSelectedUsers();
		this.setState({ selectedFilters: filters });
	}

	getFilteredUsers(filters, onlineOnly) {
		let users = [...this.props.users];
		const { selectedEntityId, selectedActiveStatus, selectedAssignedStatus, selectedRole, selectedTier } = filters;
		if (selectedEntityId !== ALL_ENTITIES_OPTION.value) {
			users = users.filter(user => {
				if (isEmpty(user.entity)) {
					return false;
				}
				return user.entity.id === Number(selectedEntityId);
			});
		}
		if (selectedRole !== ALL_ROLES_OPTION.value) {
			users = users.filter(user => {
				if (isNaN(user.roleId)) {
					return false;
				}
				return user.roleId === Number(selectedRole);
			});
		}
		if (selectedTier !== ALL_TIERS_OPTION.value) {
			users = users.filter(user => {
				if (isEmpty(user.entity) || isNaN(user.entity.tierId)) {
					return false;
				}
				return user.entity.tierId === Number(selectedTier);
			});
		}
		if (selectedActiveStatus !== ACTIVE_STATUSES.ACTIVE_INACTIVE) {
			users = users.filter(user => {
				return ((selectedActiveStatus === ACTIVE_STATUSES.ACTIVE && user.active === true) ||
					(selectedActiveStatus === ACTIVE_STATUSES.INACTIVE && user.active === false));
			});
		}
		if (selectedAssignedStatus !== ASSIGNED_STATUSES.ASSIGNED_UNASSIGNED) {
			users = users.filter(user => {
				const hasEntity = !isEmpty(user.entity) && !isEmpty(user.entity.name) && !isNaN(user.entity.id);
				return ((selectedAssignedStatus === ASSIGNED_STATUSES.ASSIGNED && hasEntity) ||
					(selectedAssignedStatus === ASSIGNED_STATUSES.UNASSIGNED && !hasEntity));
			});
		}
		if (onlineOnly) {
			users = users.filter(user => user.isOnline);
		}
		return users;
	}

	handleUsersSelect(ids, isSelected) {
		this.props.actions.changeUsersSelection(ids, isSelected);
	}

	resetSelectedUsers() {
		this.changeUsersStatusComponentRef.current.uncheckSelectAll();
		this.props.actions.resetSelectedUsers();
	}
	
	handleToggleOnlyOnlineUsersClick() {
		this.setState({ showOnlyOnline: !this.state.showOnlyOnline });
	}
	
	get hasSelectedAssignedUsers() {
		return this.props.users.some((user) => {
			return user.isSelected && user.entity;
		});
	}
	
	get hasSelectedUsersWithInactiveEntity() {
		let hasSelectedUsersWithInactiveEntity = false;
		for (let user of this.props.users) {
			for (let entity of this.props.entities) {
				if (entity.id === user.entityId && !entity.active && user.isSelected) {
					hasSelectedUsersWithInactiveEntity = true;
					break;
				}
			}
		}
		return hasSelectedUsersWithInactiveEntity;
	}
	
	get showCreateUserButton() {
		return this.props.routes[this.props.routes.length - 1].path === '/users';
	}

	handleClosePanelClick() {
        this.setState({ 
            detailsOpen: false 
        });

        const location = `/users`;
        this.props.router.push(location);
    }

    handleDetailsOpen(isEditMode, userId) {
        const location = isEditMode ? `/users/${userId}` : '/users/create';
        
        this.setState({ 
            detailsOpen: true,
            detailsInCreateMode: !isEditMode
        });

		this.props.router.push(location);
    }

	render() {
		const { entities, dashUser, users } = this.props; 
		const { selectedFilters, showOnlyOnline } = this.state;
		const filteredUsers = this.getFilteredUsers(selectedFilters, showOnlyOnline);
		return (
			<div className='users-page clearfix row-fluid'>
				<h2 className='page-heading'>Manage Users</h2>
				<div className='list-container'>
					<div className='list-filters'>
						<UserFilters entities={entities} onFiltersChange={this.onFiltersChange} />
					</div>
					<div className='grid-container'>
						<div className='user-management-grid-container'>
							<UsersGrid
								users={filteredUsers}
								isUserManagement={true}
								onUsersSelect={this.handleUsersSelect}
								onUserEdit={(id) => this.handleDetailsOpen(true, id)}
								resetSelectedUsers={this.resetSelectedUsers}
								isEditAllowed={userHasPermissions([PERMISSIONS.ADMIN], dashUser)}
							>
								<div className='text-left form-group message online-only-filter'>
									<Toggle value={this.state.showOnlyOnline} onChange={this.handleToggleOnlyOnlineUsersClick} />
									<label className='control-label'>show only currently online users</label>
								</div>
								<ChangeUsersStatus
									ref={this.changeUsersStatusComponentRef}
									onDeleteSelectedUsers={this.deleteUsers}
									onStatusChange={this.changeUsersStatus}
									totalUsersCount={users.length}
									shouldEnableDeleteBtn={this.hasSelectedAssignedUsers}
									shouldDisableChangeStatusBtns={this.hasSelectedUsersWithInactiveEntity}
									className='grid-container-filters' />
							</UsersGrid>
						</div>
					</div>
				</div>

				{this.state.detailsOpen && !this.state.detailsInCreateMode ?
				<SidePanel 
					className="user-details-sidepanel uitest-user-details-sidepanel" 
					title="user details" 
					onClose={this.handleClosePanelClick}
				>
					{ this.props.children }
				</SidePanel>

				:

				this.state.detailsOpen && this.state.detailsInCreateMode && this.props.children
				}
				
				{!isOnMobile() && this.showCreateUserButton && (
					<Link to='/users/create' onClick={() => this.handleDetailsOpen(false)} className='btn-float btn-float-second' onlyActiveOnIndex={false}>
						<label>create a new user</label>
						<div className='btn btn-primary'>
							<UserPlus size={24} />
						</div>
					</Link>
				)}
			</div>
		);
	}
}

UsersPage.propTypes = {
	/** @prop {object} redux actions */
	actions: PropTypes.object.isRequired,
	/** @prop {object} entities from app state */
	entities: PropTypes.array,
	/** @prop {object} users from app state */
	users: PropTypes.array.isRequired,
	/** @prop {object} dashUser from app state */
	dashUser: PropTypes.object.isRequired,
	/** @prop {array} routes - routing info array(expects objects that have a path prop)*/
	routes: PropTypes.array,
	/** @prop {React.Nodes} children the component inner content */
	children: PropTypes.any,
	params: PropTypes.shape({
        id: PropTypes.string
    }),
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string
    })
};

const mapStateToProps = (state) => {
	return {
		dashUser: state.auth.user,
		users: state.usersData.usersList || [],
		entities: state.entitiesList || []
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({ ...usersActions, ...modalActions, ...entitiesActions }, dispatch)
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
