import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GroupByVote from './groupByVote/groupByVote';
import { getVoteStats } from '../../../../helpers/voteDataHelper';
import { DEFAULT_PAGE_SIZE } from '../../../../helpers/graphsService';

export class GroupByVoteList extends Component {
	constructor(props) {
		super(props);
		this.handleNextPageClick = this.handleNextPageClick.bind(this);
	}

	componentWillUnmount() {
		this.props.resetVotes();
	}

	handleNextPageClick() {
        this.props.onLoadNextPage();
	}

	getMoreVotesCount() {
		const { totalVotesCount, votes } = this.props;
		const answersLeftCount = totalVotesCount - votes.length;
		return answersLeftCount > DEFAULT_PAGE_SIZE ? DEFAULT_PAGE_SIZE : answersLeftCount;
	}

	getGroupByVoteItems() {
		return this.props.votes.map((vote, index) => {
			const voteStats = getVoteStats(vote);
			
			return <GroupByVote
                        key={index}
						voteWithQna={vote}
                        stats={voteStats}
						isCollapsedAllOn={this.props.isCollapsedAllOn}
						questions={this.props.questions}
						hideDeprecatedQuestions={this.props.hideDeprecatedQuestions}
						searchTerm={this.props.searchTerm}
                    />;
		});
	}

	renderAnswersGroupedByVote() {
		const moreAnswersCount = this.getMoreVotesCount();
		const grouByVoteItems = this.getGroupByVoteItems();
		return <div>
					{grouByVoteItems}
					{moreAnswersCount > 0 && 
						<button className="btn btn-outline btn-dotted btn-full-width" type="button" onClick={this.handleNextPageClick}>
							{`load next votes`}
						</button>
					}
				</div>;
	}

	render() {
		const { className, votes } = this.props;
		
		return (
			<div className={`grouped-answers answers-grouped-by-question ${className ? className : ''}`}>
				{votes && votes.length > 0 && this.renderAnswersGroupedByVote()}
			</div>
		);
	}
}

GroupByVoteList.propTypes = {
	/** @prop {array} answersData current loaded qna answersData */
	votes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			voterUniqueId: PropTypes.string,
			vote: PropTypes.number,
			gender: PropTypes.string,
			ageGroup: PropTypes.string,
			date: PropTypes.string,
			city: PropTypes.string,
			sources: PropTypes.arrayOf(PropTypes.string),
			country: PropTypes.string,
			qna: PropTypes.arrayOf(PropTypes.shape({
				question: PropTypes.string,
				answer: PropTypes.string,
				timeSpent: PropTypes.number
			})),

			/** @prop {bool} deprecated indicated the answer is given to a questionm which is modified or deleted */
			deprecated: PropTypes.bool,

			/** @prop {bool} hidden indicates if the answer should be visible in the list */
			hidden: PropTypes.bool
		})
	).isRequired,

	totalVotesCount: PropTypes.number,

	onLoadNextPage: PropTypes.func,

	resetVotes: PropTypes.func,

	isCollapsedAllOn: PropTypes.bool,

	questions: PropTypes.arrayOf(PropTypes.shape({
		question: PropTypes.string,
		orderIndex: PropTypes.number
	})),

	className: PropTypes.string,

	hideDeprecatedQuestions: PropTypes.bool,

	searchTerm: PropTypes.string
};

GroupByVoteList.defaultProps = {
	totalVotesCount: 0,
	hideDeprecatedQuestions: false
};

export default GroupByVoteList;
