import React from 'react';
import PropTypes from 'prop-types';

import '../common/css/card.css';
import './css/statsCard.css';

const StatsCard = (props) => {
	const inverseClass = props.inverse ? "dark" : "light";
	let cardClasses = `stats-card card ${props.className || ""}`;

	if (!props.pad) {
		cardClasses += ` ${inverseClass}`;
	} else {
		cardClasses += ` pad`;		
	}

	if (props.hoffset === false) {
		cardClasses += ' no-h-offset';
	}

	return (
		<div className={cardClasses}>
			{props.title && <h4 title={props.title}>{props.title}</h4>}
			<div className="clearfix"></div>
			{props.children}
		</div>
	);
};

StatsCard.propTypes = {
	title: PropTypes.string,
	inverse: PropTypes.bool,
	className: PropTypes.string,
	pad: PropTypes.bool,
	hoffset: PropTypes.bool,
	children: PropTypes.any
};

export default StatsCard;