import pica from 'pica';

const loadImage = (imageFile: File | Blob) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image();

        image.onload = () => {
            resolve(image);
        };
        image.onerror = () => {
            reject(new Error('Failed to load image'));
        };

        const imageUrl = URL.createObjectURL(imageFile);
        image.src = imageUrl;
    });
};

const getBrandSVG = async () => {

    const watermarkOpacity = 0.3;

    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(`
        <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            version="1.1"
            id="svg1"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg"
        >
            <defs id="defs1">
                <linearGradient id="linearGradient1">
                    <stop
                        style="stop-color:#e63939;stop-opacity:${watermarkOpacity};"
                        offset="0"
                        id="stop1" />
                    <stop
                        style="stop-color:#ff4d40;stop-opacity:${watermarkOpacity};"
                        offset="0.2"
                        id="stop3" />
                    <stop
                        style="stop-color:#c36eff;stop-opacity:${watermarkOpacity};"
                        offset="0.4"
                        id="stop5" />
                    <stop
                        style="stop-color:#538cff;stop-opacity:${watermarkOpacity};"
                        offset="0.6"
                        id="stop7" />
                    <stop
                        style="stop-color:#53b7ff;stop-opacity:${watermarkOpacity};"
                        offset="0.8"
                        id="stop6" />
                    <stop
                        style="stop-color:#1ed931;stop-opacity:${watermarkOpacity};"
                        offset="1"
                        id="stop4" />
                </linearGradient>
                <linearGradient
                    xlink:href="#linearGradient1"
                    id="linearGradient10"
                    x1="19.320452"
                    y1="121.03438"
                    x2="19.924728"
                    y2="8.3219604"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-178.426,-35.048275)" />
                    <linearGradient
                    xlink:href="#linearGradient1"
                    id="linearGradient2"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(2.0726326,0,0,2.072633,171.15358,80.137332)"
                    x1="-51.065437"
                    y1="20.675869"
                    x2="-50.942684"
                    y2="-34.004875" />
            </defs>
            <g id="layer1" transform="scale(0.48247819)" style="stroke-width:2.07263">
                <rect
                    style="fill:none;fill-opacity:${watermarkOpacity};stroke:url(#linearGradient2);stroke-width:18.65369293;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;filter:url(#filter70-1)"
                    id="rect1-1"
                    width="0.48775682"
                    height="94.67942"
                    x="65.08036"
                    y="18.984554" />
                <circle
                    style="fill:#ffffff;fill-opacity:${watermarkOpacity};stroke:none;stroke-width:41.45265095;stroke-linecap:round;stroke-linejoin:round;filter:url(#filter11-7);stroke-dasharray:none"
                    id="path1-0"
                    cx="65.324242"
                    cy="66.324257"
                    r="9.260417" />
            </g>
        </svg>
    `, "image/svg+xml");

    const serializer = new XMLSerializer();
    let svgStr = serializer.serializeToString(svgDoc.documentElement);

    const svgBlob = new Blob([svgStr], { type: "image/svg+xml;charset=utf-8" });

    return loadImage(svgBlob);
};

const cropImage = async (imageObj: File, aspectRatio: number): Promise<HTMLCanvasElement> => {

    const image = await loadImage(imageObj);

    const imgAspectRatio = image.width / image.height;

    let cropWidth, cropHeight;
    if (imgAspectRatio > aspectRatio) {
        cropHeight = image.height;
        cropWidth = image.height * aspectRatio;
    } else {
        cropWidth = image.width;
        cropHeight = image.width / aspectRatio;
    }

    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');
    if (!croppedCtx) throw new Error('Could not get context');

    croppedCanvas.width = cropWidth;
    croppedCanvas.height = cropHeight;
    
    const offsetX = (image.width - cropWidth) / 2;
    const offsetY = (image.height - cropHeight) / 2;

    croppedCtx.drawImage(image, offsetX, offsetY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

    return croppedCanvas;
};

const fitImage = async (imageObj: File, aspectRatio: number): Promise<HTMLCanvasElement> => {

    const image = await loadImage(imageObj);

    const imgAspectRatio = image.width / image.height;

    let targetWidth, targetHeight;
    if (imgAspectRatio > aspectRatio) {
        targetWidth = image.width;
        targetHeight = image.width / aspectRatio; 
        //cropHeight = image.height;
        //cropWidth = image.height * aspectRatio;
    } else {
        targetHeight = image.height;
        targetWidth = image.height * aspectRatio;
        //cropWidth = image.width;
        //cropHeight = image.width / aspectRatio;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not get context');

    canvas.width = targetWidth;
    canvas.height = targetHeight;
    
    const offsetX = (targetWidth - image.width) / 2;
    const offsetY = (targetHeight - image.height) / 2;

    ctx.clearRect(0, 0, targetWidth, targetHeight);
    ctx.drawImage(image, offsetX, offsetY, image.width, image.height);

    return canvas;
};

const applyWatermark = async (canvas: HTMLCanvasElement, referenceLength: number): Promise<HTMLCanvasElement> => {
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not get context');

    const logoImg = await getBrandSVG();

    const logoSize = referenceLength / 7;
    const logoMargin = referenceLength / 42;

    ctx.drawImage(logoImg, logoMargin, canvas.height - logoMargin - logoSize, logoSize, logoSize);

    return canvas;
};

const enforceMaximumSize = async (canvas: HTMLCanvasElement, maxWidth: number, maxHeight: number, imageType: string = 'image/jpeg'): Promise<Blob> => {
    let targetWidth = canvas.width;
    let targetHeight = canvas.height;

    if (canvas.width > maxWidth || canvas.height > maxHeight) {
        const widthRatio = maxWidth / canvas.width;
        const heightRatio = maxHeight / canvas.height;
        const ratio = Math.min(widthRatio, heightRatio);

        targetWidth = canvas.width * ratio;
        targetHeight = canvas.height * ratio;
    }
    
    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = targetWidth;
    resizedCanvas.height = targetHeight;

    await pica().resize(canvas, resizedCanvas);
    const blob = await pica().toBlob(resizedCanvas, imageType, 0.80);

    return blob;
};


export const limitHorizontalSize = async (imageObj: File, horizontalLimit: number): Promise<Blob> => {
    const image = await loadImage(imageObj);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not get context');

    canvas.width = image.width;
    canvas.height = image.height;
    
    ctx.drawImage(image, 0, 0);

    if(image.width > horizontalLimit) {
        
        const scaleFactor = canvas.width / horizontalLimit;
        
        const resizedCanvas = document.createElement('canvas');
        resizedCanvas.width = horizontalLimit;
        resizedCanvas.height = canvas.height / scaleFactor;

        await pica().resize(canvas, resizedCanvas);
        const blob = await pica().toBlob(resizedCanvas, 'image/jpeg', 0.80);
    
        return blob;
    }

    const blob = await pica().toBlob(canvas, 'image/jpeg', 0.80);

    return blob;
};

const generateThumbnail = async (canvas: HTMLCanvasElement, aspectRatio: number): Promise<Blob> => {
    const thumbnailCanvas = document.createElement('canvas');
    thumbnailCanvas.width = 600;
    thumbnailCanvas.height = 600 / aspectRatio;

    await pica().resize(canvas, thumbnailCanvas);
    const thumbnailBlob = await pica().toBlob(thumbnailCanvas, 'image/jpeg', 0.80);

    return thumbnailBlob;
};

export const processBackgroundImage = async (imageObj: File): Promise<Blob[]> => {
    const maxWidth = 1920;
    const maxHeight = 1080;
    const aspectRatio = 16 / 9;

    const croppedCanvas = await cropImage(imageObj, aspectRatio);
    const canvasWithWatermark = await applyWatermark(croppedCanvas, croppedCanvas.width);

    const backgroundBlob = await enforceMaximumSize(canvasWithWatermark, maxWidth, maxHeight);
    const thumbnailBlob = await generateThumbnail(canvasWithWatermark, aspectRatio);

    return [backgroundBlob, thumbnailBlob];
};

export const processBackgroundPortraitImage = async (imageObj: File): Promise<Blob[]> => {
    const maxWidth = 1080;
    const maxHeight = 1920;
    const aspectRatio = 9 / 16;

    const croppedCanvas = await cropImage(imageObj, aspectRatio);
    const canvasWithWatermark = await applyWatermark(croppedCanvas, croppedCanvas.height);

    const backgroundPortraitBlob = await enforceMaximumSize(canvasWithWatermark, maxWidth, maxHeight);

    return [backgroundPortraitBlob];
};

export const processQuestionImage = async (imageObj: File): Promise<Blob[]> => {
    const blob = await limitHorizontalSize(imageObj, 900);
    return [blob];
};

export const processOGLinkImage = async (imageObj: File): Promise<Blob[]> => {
    const maxWidth = 1200;
    const maxHeight = 630;
    const aspectRatio = 1200 / 630;

    const croppedCanvas = await cropImage(imageObj, aspectRatio);
    const canvasWithWatermark = await applyWatermark(croppedCanvas, croppedCanvas.height);

    const ogLinkImageBlob = await enforceMaximumSize(canvasWithWatermark, maxWidth, maxHeight);

    return [ogLinkImageBlob];
}

export const processAvatarImage = async (imageObj: File): Promise<Blob[]> => {
    const maxWidth = 300;
    const maxHeight = 300;
    const aspectRatio = 1;

    const croppedCanvas = await cropImage(imageObj, aspectRatio);
    const avatarImageBlob = await enforceMaximumSize(croppedCanvas, maxWidth, maxHeight);

    return [avatarImageBlob];
};

export const processLogoImage = async (imageObj: File): Promise<Blob[]> => {
    const maxWidth = 300;
    const maxHeight = 300;
    const aspectRatio = 1;

    const croppedCanvas = await fitImage(imageObj, aspectRatio);
    const logoImageBlob = await enforceMaximumSize(croppedCanvas, maxWidth, maxHeight, 'image/png');

    return [logoImageBlob];
};