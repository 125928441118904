import React from 'react';
import PropTypes from 'prop-types';

import { isOnMobile, isOnLargeMobile } from '../../../helpers/utils';
import { omit } from 'lodash';
import './css/popover.css';

const Popover = ({ children, title, noPadding, className, style, carretStyle }) => {
	const isMobile = isOnMobile();
	const isLargeMobile = isOnLargeMobile();

	const getPopoverStyle = () => {
		let adjustedStyle = {...style}; 

		if (isMobile || isLargeMobile) {			
			adjustedStyle.maxWidth = '80vw';
			adjustedStyle.left = '5%';

			adjustedStyle = omit(adjustedStyle, ['marginLeft', 'marginRight']);
        } 

        // adjust or remove set width according to screen size
        if (isMobile) {
			adjustedStyle.width = `${getFreeScreenSpace()}px`;
        } else if (isLargeMobile) {
			adjustedStyle = omit(adjustedStyle, ['width']);
        }

		return adjustedStyle;
	};

	const getFreeScreenSpace = () => {
		return window.innerWidth * 0.95;
	};

	const getTitle = () => {
		if (title && title.length > 0) {
			return <h4 className="pop-over-title">{title}</h4>;
		}
	};

	const getClassName = () => {
		let classNames = `pop-over ${className}`;

		if (noPadding === true) {
			classNames += ` no-padding`;
		}

		return classNames;
	};

	return (
		<div className={getClassName()} style={getPopoverStyle()}>
			{(!isMobile && !isLargeMobile) && 
            <div className="pop-over-caret" style={carretStyle}></div>
            }
			
			{getTitle()}

			<div className="pop-over-content">
				{children}
			</div>
		</div>
	);
};

Popover.propTypes = {
	/** @prop {React.Nodes} children the component inner content */
	children: PropTypes.node,

	/** @prop {string} title The title of the popover modal. Optional.  */
	title: PropTypes.string,

	/** @prop {bool} noPadding removes left and right padding, enabling content from edge to edge. Optional. */
	noPadding: PropTypes.bool,

	/** @prop {string} className css class names to be added to .popover */
	className: PropTypes.string,

	/** @prop {object} style additional "style" definitions to render as an attribute */
	style: PropTypes.object,

	/** @prop {object} style additional "style" definitions for the popover carret to render as an attribute */
	carretStyle: PropTypes.object
};

export default Popover;