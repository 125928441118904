import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import RenderByPermission from '../../components/common/renderByPermission';

const FeaturesContext = (props) => {
	const { requiredFeatures, hideIfNoPermission, entityFeatures, matchSingle, renderOtherwise, skipCheck } = props;
	
	let hasPermissions = false;
	for (const requiredFeature of requiredFeatures) {
		const { name, compare, matchValue, value } = requiredFeature;
		const featureValue = matchValue === undefined || matchValue === null ?
			entityFeatures[name] :
			matchValue;

			if (compare(featureValue, value)) {
			hasPermissions = true;
			if (matchSingle) {
				break;
			}
		} else {
            hasPermissions = false;
            if (!matchSingle) {
				break;
			}
		}
	}

	if (skipCheck) {
		return props.children;
	} else {
		if (hasPermissions) {
			return props.children;
		} else {
			if (hideIfNoPermission) {
				return null;
			} else {
				return (<RenderByPermission renderOtherwise={renderOtherwise} hasPermissions={hasPermissions} hideIfNoPermission={hideIfNoPermission}>
					{props.children}
				</RenderByPermission>);
			}
		}
	}
};

FeaturesContext.propTypes = {
	requiredFeatures: PropTypes.arrayOf(PropTypes.object).isRequired,
	children: PropTypes.oneOfType([
		PropTypes.elementType,
		PropTypes.arrayOf(PropTypes.elementType),
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element)
	]),
	
	/** @prop {element} renderOtherwise if features check fails, render this element instead of props.children */
	renderOtherwise: PropTypes.element,
	
	/** 
	 * @prop {bool} matchSingle will do a single feature match using intersection with required. 
	 * Otherwise will check if any of provided feature is present in required features and will
	 * resolve with true if all provided features are included in required  */
	matchSingle: PropTypes.bool,

	entityFeatures: PropTypes.object,

	/** @prop {bool} hideIfNoPermission if set to true, will not render the renderOtherwise component */
	hideIfNoPermission: PropTypes.bool,
	skipCheck: PropTypes.bool
};

FeaturesContext.defaultProps = {
	hideIfNoPermission: false,
	skipCheck: false
};

function mapStateToProps(state) {
	const entity = state.entities[0] || {};
	
	let entityFeatures = {};
	if (entity && !isEmpty(entity.features)) {
		entityFeatures = entity.features;
	}
	
	return {
		entityFeatures
	};
}

export default connect(mapStateToProps, {})(FeaturesContext);
