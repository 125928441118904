import * as types from '../actions/actionTypes';
import isEmpty from 'lodash/isEmpty';

const initialState = {
	isAuthenticated: false,
	isAuthenticating: false,
	user: {},
	errors: null,
	sessionExpired: false,
	passwordChanged: false,
	isLoading: false,
	isLoaded: false
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case types.AUTHENTICATING_TOSERVER:
			return Object.assign({}, initialState, {
				isAuthenticating: true
			});
		case types.LOGIN_USER_SUCCESS: {
			const { user } = action.payload;
			return Object.assign({}, state, {
				isAuthenticated: !isEmpty(user) && parseInt(user.id, 10) > 0,
				user: Object.assign({}, state.user, user),
				errors: null,
				isAuthenticating: false,
				sessionExpired: false
			});
		}
		case types.LOGOUT_USER_SUCCESS:
			return initialState;
		case types.LOGIN_USER_ERROR:
			return Object.assign({}, state, {
				isAuthenticated: false,
				user: {},
				errors: action.payload,
				isAuthenticating: false
			});
		case types.LOGIN_USER_SESSIONEXPIRED:
			return Object.assign({}, state, {
				isAuthenticated: false,
				user: {},
				errors: null,
				isAuthenticating: false,
				sessionExpired: true
			});
		case types.LOAD_USER_PROFILE_REQUESTED:
		case types.UPDATE_USER_PROFILE_REQUESTED: {
			return Object.assign({}, state, { isLoading: true, isLoaded: false });
		}
		case types.LOAD_USER_PROFILE_SUCCESS:
		case types.UPDATE_USER_PROFILE_SUCCESS: {
			const { user } = action.payload;
			return Object.assign({}, state, {
				user: Object.assign({}, state.user, user),
				isLoading: false,
				isLoaded: true,
				errors: null
			});
		}
		case types.UPDATE_USER_SUCCESS: {
			const { user } = action.payload;
			if (state.user.id === user.id) {
				return Object.assign({}, state, {user: Object.assign({}, state.user, user)});
			}
			return state;
		}
		case types.UPDATE_USER_PROFLE_ERROR: {
			const { errors } = action.payload;
			return Object.assign({}, state, { errors, isLoading: false, isLoaded: true });
		}
		case types.CHANGE_PASSWORD_SUCCESS:
			return Object.assign({}, state, {
				errors: null,
				passwordChanged: true
			});
		case types.RESET_CHANGED_PASSWORD:
			return Object.assign({}, state, {
				passwordChanged: false
			});
		case types.CLEAR_AUTH_ERRORS:
			return Object.assign({}, state, {
				errors: null,
			});
		case types.CHANGE_PASSWORD_ERROR:
			return Object.assign({}, state, {
				errors: action.payload,
			});
		case types.REGISTER_USER_REQUESTED :
			return Object.assign({}, state, {
				user: {
					registerSuccess: false,
					registerRequested: true
				},
				errors: null
			});
		case types.REGISTER_USER_SUCCESS :
			return Object.assign({}, state, {
				user: {
					registerSuccess: true,
					registerRequested: false
				}
			});
		case types.REGISTER_USER_ERROR :
			return Object.assign({}, state, {
				user: {
					registerSuccess: false,
					registerRequested: false
				},
				errors: action.payload,
			});
		case types.ACTIVATE_USER_SUCCESS :
			return Object.assign({}, state, {
				user: Object.assign({}, state.user, {
					activateSuccess: true
				})
			});
		case types.COMMON_BACKEND_ERROR :
			return Object.assign({}, state, {
				errors: action.payload,
			});
		case types.COMMON_BACKEND_ERROR_RESET :
			return Object.assign({}, state, {
				errors: null,
			});
		
		default: return state;
	}
};