import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../common/modal';
import UserChangePassword from '../../scenes/login/UserChangePassword';
import { useTranslation } from 'react-i18next';

const UserChangePasswordModal = ({ onClose, width }) => {
	const { t } = useTranslation();
	return (
		<Modal title={t('edit_profile_title_change_password')} onClose={onClose} hasFooter={false} width={width} enableOverlayClickClose={false}>
			<UserChangePassword onDiscard={onClose} />
		</Modal>
	);
};
UserChangePasswordModal.propTypes = { onClose: PropTypes.func.isRequired, width: PropTypes.number };
export default UserChangePasswordModal;