import * as types from './actionTypes';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { graphPost, graphPut, graphGet, graphDel } from '../api/expressApi';
import { handleCommonError, handleValidationErrors, handleVersionErrors } from './errorActions';
import { addNotification } from './miscActions';
import { notificationLevel } from '../helpers/notification';
import {
	mapToUserSearchDetailsModel,
	mapToUserRoleUpdateModel,
	mapToFormModel,
	mapToRequestModel
} from './models/userModel';

function createUserInProgress() {
	return { type: types.CREATE_USER_REQUESTED };
}

function createUserSuccess(user) {
	return { type: types.CREATE_USER_SUCCESS, payload: { user } };
}

function updateUserInProgress() {
	return { type: types.UPDATE_USER_REQUESTED };
}

function updateUserSuccess(user) {
	return { type: types.UPDATE_USER_SUCCESS, payload: { user } };
}

function setUserRoleSuccess(userId, roleId, version) {
	return { type: types.SET_USER_ROLE_SUCCESS, payload: { userId, roleId, version } };
}

function loadUserDataSuccess(user) {
	return { type: types.LOAD_USER_DATA_SUCCESS, payload: { user } };
}

function loadUserDataSearchResultSuccess(userSearchDetails) {
	return { type: types.LOAD_USER_SEARCH_DATA_SUCCESS, payload: { userSearchDetails } };
}

function loadUsersListDataSuccess(users) {
	return { type: types.LOAD_USERS_SUCCESS, payload: {users} };
}

function loadFilteredUsers() {
	return { type: types.LOAD_FILTERED_USERS };
}

function loadFilteredUsersSuccess(users) {
	return { type: types.LOAD_FILTERED_USERS_SUCCESS, payload: users };
}

function changeUsersStatusSuccess(userIds, activeStatus) {
	return { type: types.USER_STATUS_CHANGE_SUCCESS, payload: { userIds, activeStatus } };
}

function deleteUsersSuccess(deletedUsers) {
	return { type: types.USERS_DELETE_SUCCESS, payload: { deletedUsers } };
}

export function resetSearchUserData() {
	return dispatch => {
		dispatch({ type: types.RESET_SEARCH_USER_DATA_SUCCESS });
	};
}

export function createUser(userPayload) {
	return async dispatch => {
		dispatch(showLoading());
		try {
			dispatch(createUserInProgress());
			const createdUser = await graphPost('users/create', userPayload);
			dispatch(
				addNotification(
					`User ${userPayload.firstName} ${userPayload.lastName} was created in organization.`,
					notificationLevel.success
				)
			);
			dispatch(createUserSuccess(createdUser));
			dispatch(hideLoading());
		} catch (error) {
			if (error.validationErrors) {
				dispatch(hideLoading());
				handleValidationErrors(dispatch, error, types.CREATE_USER_ERROR);
			} else {
				handleCommonError(dispatch, error);
			}
		}
	};
}

export function updateUser(userId, userPayload) {
	return async dispatch => {
		dispatch(showLoading());
		try {
			dispatch(updateUserInProgress());
			const updatedUser = await graphPut(`users/edit/${userId}`, mapToRequestModel(userPayload));
			dispatch(
				addNotification(
					`User ${userPayload.firstName} ${userPayload.lastName} updated successfully.`,
					notificationLevel.success
				)
			);
			dispatch(updateUserSuccess(mapToFormModel(updatedUser)));
			dispatch({ type: types.RESET_EDIT_USER_SUCCESS });
			dispatch(hideLoading());
		} catch (error) {
			if (error.validationErrors) {
				dispatch(hideLoading());
				handleValidationErrors(dispatch, error, types.UPDATE_USER_ERROR);
			} else {
				handleVersionErrors(dispatch, error, { type: types.UPDATE_USER_VERSION_ERROR });
				handleCommonError(dispatch, error);
			}
		}
	};
}

export function setUserRole(user, roleId, entityId) {
	return async dispatch => {
		dispatch(showLoading());
		const model = mapToUserRoleUpdateModel(user, roleId, entityId);
		try {
			const userRole = await graphPut(`/users/${user.id}/roles`, model);
			dispatch(setUserRoleSuccess(user.id, roleId, userRole.version));
			dispatch(hideLoading());
		} catch (error) {
			handleVersionErrors(dispatch, error, { type: types.UPDATE_USER_VERSION_ERROR });
			handleCommonError(dispatch, error);
		}
	};
}

export function getUserDetailsByEmail(email) {
	return (dispatch) => {
		dispatch(showLoading());
		graphGet(`/users/findby?email=${email}`).then(user => {
            dispatch(loadUserDataSearchResultSuccess(mapToUserSearchDetailsModel(user)));
            dispatch(hideLoading());
		}, 
		error => {
			handleCommonError(dispatch, error);			
		});
	};
}

export function getUserDetailsById(id) {
	return (dispatch) => {
		dispatch(showLoading());
		graphGet(`/users/${id}`).then(user => {
			dispatch(loadUserDataSuccess(mapToFormModel(user)));
            dispatch(hideLoading());
		}, 
		error => {
			handleCommonError(dispatch, error);			
		});
	};
}

export function getAllUsers() {
	return (dispatch) => {
		dispatch(showLoading());
		graphGet('/users').then(users => {
			const mappedUsers = users.map(user => mapToFormModel(user));
			dispatch(loadUsersListDataSuccess(mappedUsers));
			dispatch(hideLoading());
		},
		error => {
			handleCommonError(dispatch, error);
		});
	};
}

/**
 * Get users list by filters criteria. Currently a simple one, using only one criteria
 * @param {Object} filters filters critera
 */
export function getFilteredUsers(filters) {
	return async dispatch => {
		dispatch(showLoading());

		const criteraNames = Object.keys(filters);		
		const urlQueryEntries = [];

		criteraNames.forEach(value => {
			urlQueryEntries.push(`${value}=${filters[value]}`);
		});
		
		const urlWithQuery = `/users/filter/?${urlQueryEntries.join('&')}`;
		
		dispatch(loadFilteredUsers());
		
		try {
			const foundUsers = await graphGet(urlWithQuery);
			dispatch(loadFilteredUsersSuccess(foundUsers));
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			handleCommonError(dispatch, error);
		}
	};
}

export function changeUsersStatus(userIds, activeStatus) {
	return (dispatch) => {
		dispatch(showLoading());
		
		graphPut('/users/changestatus', {userIds, activeStatus}).then(updatedUsersIds => {
			dispatch(changeUsersStatusSuccess(updatedUsersIds, activeStatus));
			dispatch(hideLoading());
		},
		error => {
			handleVersionErrors(dispatch, error, { type: types.UPDATE_USER_VERSION_ERROR });
			handleCommonError(dispatch, error);
		});
	};
}

export function deleteUsers(userIds) {
	return (dispatch) => {
		dispatch(showLoading());
		
		graphDel(`/users/delete`, {}, { userIds }).then(
			(deletedUsers) => {
				dispatch(addNotification(`${ deletedUsers.length } users were successfully deleted`, notificationLevel.success));
				dispatch(deleteUsersSuccess(deletedUsers));
				dispatch(hideLoading());
			},
			error => {
				handleCommonError(dispatch, error);
			});
	};
}

export function changeUsersSelection(userIds, isSelected) {
	return (dispatch) => {
		dispatch({ type: types.CHANGE_USERS_SELECTED, payload: { userIds, isSelected } });
	};
}

export function resetCreatedUser() {
	return dispatch => dispatch({ type: types.RESET_CREATE_USER_SUCCESS });
}

export function resetEditedUser() {
	return dispatch => dispatch({ type: types.RESET_EDIT_USER_SUCCESS });
}

export function resetSelectedUsers() {
	return (dispatch) => {
		dispatch({ type: types.RESET_SELECTED_USERS });
	};
}

export function resetUserSearchResult(){
	return dispatch => {
		dispatch({ type: types.RESET_USER_SEARCH_RESULT });
	};
}

export function resetUserRequestErrors() {
	return dispatch => {
		dispatch({ type: types.RESET_USER_REQUEST_ERRORS });
	};
}