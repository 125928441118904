import io from 'socket.io-client';
import apiServers from '../resources/apiServers';
import Logger from '../helpers/logger';

import { mapFromPushVoteModel } from '../actions/models/voteModel';
import { receiveNewVoteForTopic } from '../actions/graphDataActions';

let _socket = null;
let _isConnecting = false;

const socketMiddleware = store => next => action => { 

  const onOpen = (store) => () => {
    //Send a handshake, or authenticate with remote end
    _isConnecting = false;
    Logger.debug("Connected to websocket");
  };

  const onClose = (store) => reason => {
    Logger.debug("Websocket connection closed: " + reason);
  };

  const onMessage = (type, store) => (data) => {
    Logger.debug("Received message for: " + type);
    Logger.debug(data);
    const { topicId, topicName, vote } = data;
    store.dispatch(receiveNewVoteForTopic(topicId, topicName, mapFromPushVoteModel(vote)));
  };

  const onError = (store) => evt => {
    Logger.info(`WebSocket error: ${evt}`);
  };

  
  switch(action.type) {
    case 'CONNECT_SOCKET':
      //Start a new connection to the server
      if (_socket != null) {
        _socket.close();
      }

      if (!_isConnecting) {
        _isConnecting = true;
        const serverUrl = apiServers.getGraphUrl();  // at the moment we only use graph server for the dashboard
        _socket = io(serverUrl);
        _socket.on('newVote', onMessage("newVote", store));
        _socket.on('updatedVote', onMessage("updatedVote", store));
        _socket.on('disconnect', onClose(store));
        _socket.on('connect', onOpen(store));
        _socket.on('error', onError(store));
      }
      break;

    //The user wants us to disconnect
    case 'DISCONNECT_SOCKET':
      if(_socket != null) {
        _socket.close();
      }
      _socket = null;
      break;

      //This action is irrelevant to us, pass it on to the next middleware
    default:
      return next(action);
  }

  next(action);  
};

export default socketMiddleware;