import React from 'react';
import { Link, IndexLink } from 'react-router';
import PropTypes from 'prop-types';
import { Grid, List, Box, User, Users, LogOut, Bell, PlusCircle, Settings, Activity, Key, Star } from 'react-feather';
import './header.css';
import PermissionsContext from './contexts/permissionsContext';
import { PERMISSIONS } from '../helpers/permissionsHelper';
import { USER_CHANGE_PASSWORD_MODAL, USER_PROFILE_EDIT_MODAL } from '../helpers/modalTypes';
import planColorsCss from '../components/common/css/plansCommon.module.css';
import FeaturesContext from './contexts/featuresContext';
import { COMPARATORS } from '../helpers/featuresHelper';
import messages from '../helpers/messages';
import NoPermissionLabel from '../components/common/noPermissionLabel';
import { ENTITY_FEATURES } from 'hollerlive-shared/constants';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
    const { liveFeedActive, unreadVotesCout, auth, entity } = props;

    const { t } = useTranslation();

    const showChangePasswordModal = () => {
        const config = { onClose: props.hideModal, width: 480 };
        props.showModal(USER_CHANGE_PASSWORD_MODAL, config);
    };
    
    const showEditProfileModal = () => {
        const config = { onClose: props.hideModal, onRedirectToChangePassword: showChangePasswordModal, width: 840 };
        props.showModal(USER_PROFILE_EDIT_MODAL, config);
    };

    const subscriptionPlanName = entity ? entity.tierName : '';

    const isUserLoaded = auth && auth.user;
    
    return (
        <nav className={`navbar navbar-default ${props.className}`}>
            <div className="container">
                <div className="navbar-header">
                    <IndexLink to="/" className="navbar-brand pull-left" activeClassName="active">
                        <img style={{ height: '37px' }} src="/kazva-logo.png" alt="Kazva BG Logo"/>
                    </IndexLink>
    
                    { auth.isAuthenticated &&
                    <div className="collapse navbar-collapse pull-left">
                        <ul id="left-menu" className="nav navbar-nav">
                            <li>
                                <IndexLink to="/" activeClassName="active" onClick={ props.onMenuItemClick }>
                                    <Grid/>
                                    <span className="navbar-menu-item-tooltip">{t('nav_header_topics_overview')}</span>
                                </IndexLink>
                            </li>
                            <li>
                                <Link to="/topics" activeClassName="active" onClick={ props.onMenuItemClick }>
                                    <List/>
                                    <span className="navbar-menu-item-tooltip">{t('nav_header_topics_management')}</span>
                                </Link>
                            </li>                            
                            <li>
                                <PermissionsContext hideIfNoPermission={true} requiredPermissions={ [PERMISSIONS.ADMIN] }>
                                    <Link to="/entities" activeClassName="active" onClick={ props.onMenuItemClick }>
                                        <Box/>
                                        <span className="navbar-menu-item-tooltip">
                                        {t('nav_header_organizations_list')}</span>
                                    </Link>
                                </PermissionsContext>
                            </li>
                            <li>
                                <PermissionsContext hideIfNoPermission={true} requiredPermissions={ [PERMISSIONS.ADMIN] }>
                                    <Link to="/users" activeClassName="active" onClick={ props.onMenuItemClick }>
                                        <Users/>
                                        <span className="navbar-menu-item-tooltip">
                                        {t('nav_header_users_list')}</span>
                                    </Link>
                                </PermissionsContext>
                            </li>
                            <li>
                                <PermissionsContext hideIfNoPermission={true} requiredPermissions={ [PERMISSIONS.ADMIN] }>
                                    <Link to="/admin" activeClassName="active" onClick={ props.onMenuItemClick }>
                                        <Settings/>
                                        <span className="navbar-menu-item-tooltip">{t('nav_header_admin')}</span>
                                    </Link>
                                </PermissionsContext>
                            </li>
                        </ul>
                    </div>
                    }

                    { auth.isAuthenticated && <div className="collapse navbar-collapse pull-right">
                        <ul className="nav navbar-nav">
                            <li>
                                <button 
                                    onClick={props.onLiveFeedToggleClick}
                                    className={`btn btn-transparent btn-live-feed ${liveFeedActive ? 'active' : ''}`}
                                >
                                    <Activity size={24} />
                                    {!liveFeedActive && unreadVotesCout > 0 && <span className="new-votes-count">{unreadVotesCout}</span>}
                                </button>
                            </li>
                        </ul>
                    </div> }
                    
                    <div className="navbar-collapse pull-right">                    
                        <ul className="nav navbar-nav">
                            <li className="dropdown">
                                <div className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                    {auth.isAuthenticated ? 
                                        <div className="entity-nav-item">
                                            <span className={`logo ${entity && entity.logoUrl ? 'uitest-logo' : 'hidden'}`}><img alt="logo" width="32" height="32" src={entity && entity.logoUrl ? entity.logoUrl : ''}/></span>
                                            <span className="name">
                                                <small>{t('nav_header_organization_your')}, <span className={`subscription ${planColorsCss[subscriptionPlanName ? `${subscriptionPlanName.toLowerCase()}TextColors` : '']}`}><Star size={12} /> {subscriptionPlanName}</span></small> 
                                                {entity ? entity.name : ""}</span>
                                            <span className="caret"></span>
	
											<ul className="dropdown-menu">
                                                {entity &&
                                                    <React.Fragment>
                                                        <li>
                                                            <PermissionsContext hideIfNoPermission={true} requiredPermissions={[PERMISSIONS.VIEW_ENTITY]}>
                                                                <Link to={`/account/details/${entity.id}`}>
                                                                    <Star size={18}/><span>{t('nav_header_organization_my_account')}</span>
                                                                </Link>
                                                            </PermissionsContext>
                                                        </li>

                                                        <li>
                                                            <PermissionsContext hideIfNoPermission={true} requiredPermissions={[PERMISSIONS.EDIT_ENTITIES]}>
                                                                <FeaturesContext 
                                                                    requiredFeatures={[{ 
                                                                        name: ENTITY_FEATURES.HAS_TOPIC_ALERTS, 
                                                                        compare: COMPARATORS.IS_TRUE
                                                                    }]}
                                                                    renderOtherwise={
                                                                        <button className="btn btn-transparent" disabled>
                                                                            <NoPermissionLabel position="right" message={messages.permissions.features.noAlerts} />
                                                                            <Bell size={18}/><span>{t('nav_header_organization_alerts')}</span>
                                                                        </button>
                                                                    }
                                                                >
                                                                    <Link to={`/account/details/${entity.id}`}>
                                                                        <Bell size={18}/><span>{t('nav_header_organization_alerts')}</span>
                                                                    </Link>
                                                                </FeaturesContext>
                                                            </PermissionsContext>
                                                        </li>
                                                
                                                        <PermissionsContext hideIfNoPermission={true} requiredPermissions={[PERMISSIONS.ADMIN]}>
                                                            <li role="separator" className="divider"></li>

                                                            <li>
                                                                <Link to={`/entities`}><Box size={18}/><span>{t('nav_header_organization_manage')}</span></Link>
                                                            </li>
                                                            
                                                            <li>
                                                                <Link to={`/entities/create`}><PlusCircle size={18}/><span>{t('nav_header_organization_create')}</span></Link>
                                                            </li>
                                                        </PermissionsContext>
                                                    </React.Fragment>
                                                }
											</ul>
                                        </div>
                                        :
                                        <div className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                            {props.locationPathName && props.locationPathName.indexOf("login") > -1 ? 
                                                <span className="name">
                                                    <small>{t('nav_header_organization_dont_have_account')}</small> 
                                                    <Link to="/register">{t('nav_header_organization_sign_up')}</Link>
                                                </span>
                                            :
                                                <span className="name">
                                                    <small>{t('nav_header_organization_already_have_account')}</small> 
                                                    <Link to="/login">{t('nav_header_organization_sign_in')}</Link>
                                                </span>
                                            }
                                        </div>
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                    {auth.isAuthenticated &&                     
                    <div className="collapse navbar-collapse pull-right">                    
                        <ul className="nav navbar-nav">
                            <li className="dropdown">
                                <div className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                    <span className={`avatar ${isUserLoaded && auth.user.avatarUrl ? 'uitest-avatar' : 'hidden'}`}><img alt="avatar" width="32" height="32" src={isUserLoaded && auth.user.avatarUrl ? auth.user.avatarUrl : ''}/></span>
                                    <span className="name"><small>{t('nav_header_profile_hello')},</small> {auth.user.firstName} {auth.user.lastName}</span>
                                    <span className="caret"></span>

                                    <ul className="dropdown-menu">
                                        <li>
                                            <button onClick={showEditProfileModal} className='btn btn-transparent uitest-editprofile-button'>
                                                <User size={18} />
                                                <span>{t('nav_header_profile_edit')}</span>
                                            </button>
                                        </li>
                                        <li>
                                            <button onClick={showChangePasswordModal} className='btn btn-transparent'>
                                                <Key size={18} />
                                                <span>{t('nav_header_profile_change_password')}</span>
                                            </button>
                                        </li>
                                        <li><Link to="/" onClick={(e) => {props.onLogoutClick(e); props.onMenuItemClick(e);}}>
                                            <LogOut size={18} /><span>{t('nav_header_profile_logout')}</span></Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    }
                </div>
            </div>
        </nav>
    );
};

Header.propTypes = {
	auth: PropTypes.shape({
		user: PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string,
			permissions: PropTypes.arrayOf(PropTypes.string)
		}).isRequired,
		isAuthenticated: PropTypes.bool.isRequired
	}).isRequired,

	entity: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
        logoUrl: PropTypes.string,
        tierName: PropTypes.string
	}),

	locationPathName: PropTypes.string,

	className: PropTypes.string,

	/** @prop {func} onMenuItemClick action sent on each top menu item click  */
	onMenuItemClick: PropTypes.func.isRequired,

	/** @prop {func} onLogoutClick action sent on clik the logout menu item */
	onLogoutClick: PropTypes.func.isRequired,

	onLiveFeedToggleClick: PropTypes.func.isRequired,
	showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
	liveFeedActive: PropTypes.bool,
	unreadVotesCout: PropTypes.number
};

Header.defaultProps = {
	auth: {
		isAuthenticated: false
	},
    liveFeedActive: true,
    unreadVotesCout: 0,
	showModal: () => {},
    hideModal: () => {},
};

export default Header;
