import apiServers from '../resources/apiServers';
import axios from 'axios';
import { CONNECTIVITY_ERROR } from '../resources/apiConstants';

export function graphGet(endpoint, queryParams) {
    const apiProps = {
        baseURL: apiServers.getGraphUrl() + "/api",
        withCredentials: true,
        headers: { 'Accept': 'application/json' }
    };
    const graphApi = axios.create(apiProps);

    return graphApi.get(endpoint, { params: queryParams })
        .then(onSuccess)
        .catch(onError);
}

export function graphPing() {

    const apiProps = {
        baseURL: apiServers.getGraphUrl() + "/api",
        headers: { 'Accept': 'application/json' },
        timeout: 5000,
    };
    const graphApi = axios.create(apiProps);

    return graphApi.get()
        .then(onSuccess)
        .catch(onError);
}

export function graphPost(endpoint, payload) {
    const apiProps = {
        baseURL: apiServers.getGraphUrl() + "/api",
        withCredentials: true,
        headers: { 'Accept': 'application/json' }
    };
    
    if(payload instanceof FormData){
        apiProps.headers = { 'content-type': 'multipart/form-data' };
    }
    
    const graphApi = axios.create(apiProps);

    return graphApi.post(endpoint, payload)
        .then(onSuccess)
        .catch(onError);
}

export function graphDel(endpoint, queryParams, requestBody) {
    const apiProps = {
        baseURL: apiServers.getGraphUrl() + "/api",
        withCredentials: true
    };
    const graphApi = axios.create(apiProps);

    return graphApi.delete(endpoint, { params: queryParams, data: requestBody })
        .then(onSuccess)
        .catch(onError);
}

export function graphPut(endpoint, payload) {
    const apiProps = {
        baseURL: apiServers.getGraphUrl() + "/api",
        withCredentials: true,
        headers: { 'Accept': 'application/json' }
    };

    const graphApi = axios.create(apiProps);

    return graphApi.put(endpoint, payload)
        .then(onSuccess)
        .catch(onError);
}

export function streamGet(endpoint, queryParams) {
    const apiProps = {
        baseURL: apiServers.getStreamUrl() + "/api",
        withCredentials: true,
        headers: { 'Accept': 'application/json' }
    };

    const streamApi = axios.create(apiProps);

    return streamApi.get(endpoint, { params: queryParams })
        .then(onSuccess)
        .catch(onError);
}

export function streamPost(endpoint, payload) {
    const apiProps = {
        baseURL: apiServers.getStreamUrl() + "/api",
        withCredentials: true,
        headers: { 'Accept': 'application/json' }
    };

    const streamApi = axios.create(apiProps);

    return streamApi.post(endpoint, payload)
        .then(onSuccess)
        .catch(onError);
}

export function streamPut(endpoint, payload) {
    const apiProps = {
        baseURL: apiServers.getStreamUrl() + "/api",
        withCredentials: true,
        headers: { 'Accept': 'application/json' }
    };

    const streamApi = axios.create(apiProps);

    return streamApi.put(endpoint, payload)
        .then(onSuccess)
        .catch(onError);
}

export function staticGet(endpoint, queryParams) {
    const apiProps = {
		baseURL: apiServers.getStaticContentUrl(),
		withCredentials: // on local env, pass credential headers(that are required by the graph service for static content)
			process.env.REACT_APP_DEPLOY_ENV !== 'staging' && process.env.REACT_APP_DEPLOY_ENV !== 'production'
	};
    const staticServer = axios.create(apiProps);
    return staticServer.get(endpoint, { params: queryParams })
        .then(onSuccess)
        .catch(onError);
}

const onSuccess = (response) => {
    return Promise.resolve(response.data);
};

const onError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const statusError = Object.assign({}, error.response.data, {status: error.response.status});
        return Promise.reject(statusError);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return Promise.reject({type: CONNECTIVITY_ERROR, message: "Request failed, server is down or blocked by a firewall."});
    } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.reject('Something unexpected happened! ' + error.message);
    }
};