import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Disc, CheckCircle, ThumbsUp } from 'react-feather';
import './css/lifetimeStatBox.css';
import { useTranslation } from 'react-i18next';

const StatsBox = ({ label, value, icon, iconPosition, className }) => {
	const Icon = icon;

	return (
		<div className={`stats-box ${className}`}>
			{iconPosition === "left" && <Icon className="stats-icon" size={24} />}

			<div className={`stats-text ${iconPosition === "left" ? 'text-left' : 'text-right'}`}>
				<label className='text-muted'>{label}</label>
				<strong className='uitest-firstvote'>
					{value}
				</strong>
			</div>

			{iconPosition === "right" && <Icon className="stats-icon" size={24} />}
		</div>
	);
};

StatsBox.defaultProps = {
	iconPosition: "left"
};

const LifetimeStatBox = ({ firstVoteTime, lastVoteTime, allVotesCount, allAnswersCount }) => {

	const { t } = useTranslation();

	return (
		<div className='lifetime-statbox'>
			<StatsBox 
				label={t('lifetime_stats_first_vote')}
				value={firstVoteTime ? moment(firstVoteTime).format('D MMM YYYY') : 'no votes yet'}
				icon={Disc}
				iconPosition="right"
				className="uitest-firstvote"
			/>

			<hr />
			
			<StatsBox 
				label={t('lifetime_stats_all_votes')} 
				value={Number(allVotesCount).toLocaleString()}
				icon={ThumbsUp}
				className="uitest-allvotes"
			/>

			<StatsBox 
				label={t('lifetime_stats_all_answers')} 
				value={Number(allAnswersCount).toLocaleString()}
				icon={CheckCircle}
				className="uitest-allanswers"
			/>

			{lastVoteTime && <hr />}

			{lastVoteTime && <StatsBox 
				label={t('lifetime_stats_last_vote_on')} 
				value={lastVoteTime && moment(lastVoteTime).format('D MMM YYYY')}
				icon={Disc}
				className="uitest-lastvote"
			/>}
		</div>
	);
};

LifetimeStatBox.propTypes = {
	firstVoteTime: PropTypes.string,
	lastVoteTime: PropTypes.string,
	allVotesCount: PropTypes.number,
	allAnswersCount: PropTypes.number
};

LifetimeStatBox.defaultProps = {
	firstVoteTime: '',
	lastVoteTime: '',
	allVotesCount: 0,
	allAnswersCount: 0
};

export default LifetimeStatBox;
