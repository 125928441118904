import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/usersActions';

import { Edit3 } from 'react-feather';
import { isEmpty } from 'lodash';
import { showModal, hideModal } from '../../actions/modalActions';

import { userHasSpecialPermissions, PERMISSIONS, userHasPermissions } from '../../helpers/permissionsHelper';
import PermissionsContext from '../contexts/permissionsContext';
import UserDetailsEdit from '../../components/users/userDetailsEdit';
import UserDetails from '../../components/users/userDetails';
import UserEntitiesGrid from '../../components/users/userEntitiesGrid';
import './css/userDetailsPage.css';

export const IN_EDIT_PANEL_NAMES = { DETAILS: 'DETAILS', ENTITIES: 'ENTITIES' };

export class UserDetailsPage extends Component {
	constructor(props) {
		super(props);

		this.state = { activeInEditPanel: null };

		this.handleCloseInPageForm = this.handleCloseInPageForm.bind(this);
		this.handleUserEditClick = this.handleUserEditClick.bind(this);
		this.handleUserEdit = this.handleUserEdit.bind(this);
	}
	
	componentDidMount() {
		this.props.actions.getUserDetailsById(this.props.userId);
	}

	handleUserEditClick() {
		this.setState({ activeInEditPanel: IN_EDIT_PANEL_NAMES.DETAILS });
	}

	handleCloseInPageForm() {
		this.setState({ activeInEditPanel: null });
	}

	handleUserEdit(values) {
        const { userId } = this.props;
		this.props.actions.updateUser(userId, values);
		this.handleCloseInPageForm();
	}
    
	get canEditUsers() {
		return userHasPermissions([ PERMISSIONS.EDIT_USERS ], this.props.dashUser);
	}

	get canViewUsers() {
		return userHasPermissions([ PERMISSIONS.VIEW_USERS ], this.props.dashUser);
	}

	get editModeEnabled() {
		return this.state.activeInEditPanel !== null;
	}

	get userExists() {
		const { userData } = this.props;
		return !isEmpty(userData) && Number.isInteger(userData.id);
	}

	render() {
		const { userData, entities, usersList, requestErrors, hasSpecialPermissions, actions } = this.props;
		const { activeInEditPanel } = this.state;
		return this.userExists ?
		<PermissionsContext hideIfNoPermission={true} requiredPermissions={[PERMISSIONS.VIEW_USERS]}>
			<div className={`user-details-page ${this.editModeEnabled ? 'in-edit' : ''}`}>	
				<div className={`user-details-panel panel ${activeInEditPanel === IN_EDIT_PANEL_NAMES.DETAILS ? 'in-edit' : ''}`}>	
					{activeInEditPanel === IN_EDIT_PANEL_NAMES.DETAILS ? 
						<UserDetailsEdit 
							userDetails={userData}
							entities={entities}
							usersList={usersList}
							hasSpecialPermissions={hasSpecialPermissions}
							onSubmit={this.handleUserEdit}
							onCancel={this.handleCloseInPageForm}
							showModal={actions.showModal}
							hideModal={actions.hideModal}
							requestErrors={requestErrors}
							resetUserRequestErrors={actions.resetUserRequestErrors}
						/>
					:
						<>
							<PermissionsContext hideIfNoPermission={true} requiredPermissions={ [PERMISSIONS.EDIT_USERS] } >
								<button onClick={this.handleUserEditClick} className='btn btn-outline btn-xs btn-edit'>
									<Edit3 size={13} /> edit user details
								</button>
							</PermissionsContext>
							<UserDetails user={userData} />
						</>
					}

				</div>

				<div className={`user-entities-panel panel ${activeInEditPanel === IN_EDIT_PANEL_NAMES.ENTITIES ? 'in-edit' : ''}`}>	
					<div className='user-entities-grid'>
						<UserEntitiesGrid entities={userData.entities} />
					</div>
				</div>			
			</div>
		</PermissionsContext>
		:
		<div/>;
	}
};

UserDetailsPage.propTypes = {
    router: PropTypes.shape({ push: PropTypes.func }),
	/** @prop {bool} hasSpecialPermissions 
     * logged user must have special permissions to access special features like adding a superadmin user */
	hasSpecialPermissions: PropTypes.bool.isRequired,
    userData: PropTypes.shape({ id: PropTypes.number }),
    entities: PropTypes.array,
    usersList: PropTypes.array,
    requestErrors: PropTypes.shape({}),
	userId: PropTypes.number.isRequired,
	dashUser: PropTypes.shape({ permissions: PropTypes.arrayOf(PropTypes.string).isRequired }),
	actions: PropTypes.shape(),
};

UserDetailsPage.defaultProps = {
	hasSpecialPermissions: false,
	userData: {},
};

const mapStateToProps = (state, props) => {
	let dashUser = {};
	if (state.auth && state.auth.user) {
		dashUser = state.auth.user;
    }

    const userId = Number(props.params.id);
    const userData = state.usersData ? 
    (state.usersData.usersList.length > 0 ? state.usersData.usersList.find(u => u.id === userId) : {})
    :
    {};

    let entities = [];
    if (state.entitiesList && state.entitiesList.length > 0) {
		entities = state.entitiesList;
    }
    
    const usersList = state.usersData ? state.usersData.usersList : null;
    const requestErrors = state.usersData ? state.usersData.requestErrors : null;

	return {
		hasSpecialPermissions: userHasSpecialPermissions(state.auth.user),
        userData,
        requestErrors,
		userId,
        dashUser,
        entities,
        usersList
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({ ...userActions, showModal, hideModal }, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsPage);
