import { USER_ROLES } from '../../helpers/user';
import { isEmpty } from 'lodash';
import { isStrongPassword } from '../../helpers/validators';
import formValidators from '../../helpers/formValidators';
import Messages from '../../helpers/messages';
import utils from 'hollerlive-shared/utils';
import { mapToUserEntityModel } from './entityModel';
import sharedValidators from 'hollerlive-shared/validators';

export const DEFAULT_CREATE_USER = { active: true, roleId: USER_ROLES.entityViewer };
export const MAX_WIDTH_AVATAR = 128;
export const MAX_HEIGHT_AVATAR = 128;

export const DEFAULT_USER_MODEL = {
    active: false,
    entityId: null,
    email: '',
    avatarUrl: '',
    avatarDataUrl: null,
    firstName: '',
    lastName: '',
    phone: '',
    locale: 'en_US',
    timeZone: '',
    roleId: USER_ROLES.entityViewer,
    version: null,
    tierId: null,
	isOnline: false,
    lastOnlineOn: null,
    entities: null,
    language: ''
};

export function mapToFormModel(model) {
    const formModel = {
		active: model.active || DEFAULT_USER_MODEL.active,
		entityId: model.entity ? model.entity.id : DEFAULT_USER_MODEL.entityId,
        email: model.email || DEFAULT_USER_MODEL.email,
        avatarUrl: model.avatarUrl || '',
        avatarDataUrl: model.avatarDataUrl || null,
		firstName: model.firstName || DEFAULT_USER_MODEL.firstName,
		lastName: model.lastName || DEFAULT_USER_MODEL.lastName,
		phone: model.phone || DEFAULT_USER_MODEL.phone,
		locale: model.locale || DEFAULT_USER_MODEL.locale,
		timeZone: model.timeZone ? model.timeZone.toString() : DEFAULT_USER_MODEL.timeZone,
		roleId: model.roleId || DEFAULT_USER_MODEL.roleId,
		tierId: model.tierId || DEFAULT_USER_MODEL.tierId,
		version: model.version || DEFAULT_USER_MODEL.version,
		isOnline: model.isOnline || DEFAULT_USER_MODEL.isOnline,
        lastOnlineOn: model.lastOnlineOn || DEFAULT_USER_MODEL.lastOnlineOn,
        entities: model.entities || DEFAULT_USER_MODEL.entities,
        language: model.language || DEFAULT_USER_MODEL.language
	};
	if (model.id) {
		formModel.id = model.id;
	}
	if (!isEmpty(model.entity)) {
		formModel.entityId = model.entity.id;
		formModel.entity = Object.assign({}, model.entity);
	}
	return formModel;
}

export const mapToRequestModel = (model) => {
    const { 
        email, 
        avatarUrl,
        avatarDataUrl,
        firstName, 
        lastName, 
        entityId, 
        password,
        confirmPassword,
        phone, 
        locale, 
        timeZone, 
        active, 
        roleId,
        entityName,
        industryId,
        version,
        language
    } = model;
    const requestModel = {
        active: active !== undefined ? active : true,
        avatarUrl,
        avatarDataUrl,
        firstName,
        lastName,
        phone,
        locale,
        timeZone: timeZone && timeZone.length > 0 ? Number(timeZone) : null,
        roleId,
        version,
        language
    };

    if (entityId) {
        requestModel.entityId = entityId;
    }
    if (!isEmpty(email)) {
        requestModel.email = email;
    } else {
        throw Error('Email property is required to user model');
    }
    if (!isEmpty(entityName)) {
        requestModel.entityName = entityName;
    }
    if (!isEmpty(password) && !isEmpty(confirmPassword)) {
        requestModel.password = password;
    }
    if (industryId !== undefined) {
        const industries = utils.getIndustries();
        if (industries) {
            requestModel.industry = industries[Number(model.industryId)];
            delete requestModel.industryId;
        }
    }

    return requestModel;
};

export const mapToUserSearchDetailsModel = model => {
	return {
		id: model.id,
		active: model.active,
        email: model.email,
        avatarUrl: model.avatarUrl || '',
        avatarDataUrl: model.avatarDataUrl || null,
		firstName: model.firstName,
		lastName: model.firstName,
		phone: model.phone,
		locale: model.locale,
		timeZone: model.timeZone,
		permissions: model.permissions,
		entities: model.entities,
		tierId: model.tierId,
		version: model.version
	};
};

export const mapToUserRoleUpdateModel = (user, roleId, entityId) => {
    return { entityId, roleId, version: user.version };
};

const _validateRequiredNameInput = function(name) {
    if (!formValidators.required(name)) {
        return Messages.errors.required;
    } 
    if (!formValidators.length(name, null, 60)) {
        return Messages.errors.length(null, 60);
    } 
    if (!formValidators.onlyNames(name)) {
        return Messages.errors.onlyNames;
    }
    return '';
};

/**
 * Validate all user-related fields, unless otherwise specified with validateOptions parameter
 * @param {object} values the values to be tested againstx
 * @param {object} validationOptions specify false if validation needs to be skipped, otherwise it is not */
export const validateUser = function (values = {}, validationOptions = {}) {
    const { shouldValidateEntity, shouldValidateEmail, shouldValidatePassword  } = validationOptions;

    let errors = {};
    const { entityId, email, password, confirmPassword, firstName, lastName, phone} = values;
    
    // validate entity
    if (!(shouldValidateEntity === false) && !formValidators.required(entityId)) {
        errors.entityId = Messages.errors.required;
    }

    if (!(shouldValidateEmail === false)) {
        if (!formValidators.required(email)) {
            errors.email = Messages.errors.required;
        } else if(!sharedValidators.isEmailValid(email.trim())){
            errors.email = Messages.errors.email;
        }
    }

    // validate names
    const errorInFirstName = _validateRequiredNameInput(firstName);
    const errorInLastName = _validateRequiredNameInput(lastName);
    if (errorInFirstName.length > 0) {
        errors.firstName = errorInFirstName;
    }
    if (errorInLastName.length > 0) {
        errors.lastName = errorInLastName;
    }

    // validate number if exists
    if (phone && phone.length > 0) {            
        if (phone.substring(0, 1) === '+') {
            if (!formValidators.required(phone.substring(1))) {
                errors.phone = 'Must contain numbers';
            } else if(!formValidators.onlyNumeric(phone.substring(1))) {
                errors.phone = Messages.errors.onlyNumeric;
            }
        } else if(!formValidators.onlyNumeric(phone)) {
            errors.phone = Messages.errors.onlyNumeric + ' or start with a \'+\'';
        }
    }

    if (!(shouldValidatePassword === false)) {
        if (!formValidators.required(password)) {
            errors.password = Messages.errors.required;
        } else if(!isStrongPassword(password)){
            errors.password = Messages.errors.password;
        }

        if (!formValidators.required(confirmPassword)) {
            errors.confirmPassword = Messages.errors.required;
        } else if (password !== confirmPassword) {
            errors.confirmPassword = Messages.errors.confirmPassword;
        }
    }
    
    return errors;
};

/**
 * Destructs user-response object into user(with its primary entity) and entities array
 * @param{object} response */
export function destructUserResponse(response) {
    if (!response) {
        throw new Error("User response is empty.");
    }
    if (!response.entities || isEmpty(response.entities)) {
        throw new Error("User response has no entities.");
    }
    const primaryEntity = response.entities.find(entity => entity.id === response.entityId);
    const user = Object.assign({}, response, { entity: mapToUserEntityModel(primaryEntity), entities: undefined });
    return { user, entities: response.entities };
}
