import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../css/graphs.css';
import {isEqual} from 'lodash';
import StackedHorizontalBarChart from './stackedHorizontalBarChart';
import {STACKING_OPTIONS_NAMES, STACKING_OPTIONS} from '../../../helpers/graphsService';
import {reduceToFlatQuestionData} from '../../../actions/models/qnaModel';
import i18n from '../../../i18n';

class QuestionsByVolumeChart extends Component {
    constructor(props) {
		super(props);
		
		this.state = {
			chartData: _applyDataTransformOnStackingOption(props.data, STACKING_OPTIONS_NAMES.sentiment),
			selectedStackingOption: STACKING_OPTIONS_NAMES.sentiment,
			t: i18n.t.bind(i18n)
		};

		this.handleStackingChange = this.handleStackingChange.bind(this);
	}

	static getDerivedStateFromProps(nextProps, state){
		const newTransformedData = _applyDataTransformOnStackingOption(nextProps.data, state.selectedStackingOption);
		if(!isEqual(newTransformedData, state.chartData)){
			return {chartData: newTransformedData};
		}
		return null;
	}

	handleStackingChange(selectedStackingOption){
		const chartData = _applyDataTransformOnStackingOption(this.props.data, selectedStackingOption.displayName);
		this.setState({chartData, selectedStackingOption: selectedStackingOption.displayName});
	}
	
	render() {
		const {width, height} = this.props;
		const t = this.state.t;
		
		return (
			<StackedHorizontalBarChart
				width={width}
				height={height} 
				data={this.state.chartData} 
				dataFieldName="shortQuestion"
				aggregationFieldName="answerCount"
				stackFieldOptions={[STACKING_OPTIONS.sentiment, STACKING_OPTIONS.ageGroups, STACKING_OPTIONS.gender]}
				chartYAxisTitle={t('topic_analytics_chart_questions_yaxis')}
				chartXAxisTitle={t('topic_analytics_chart_questions_xaxis')}
				showLegend={true}
				className={"export-pdf-chart-wrapper"}
				skipTransform={true}
				onStackingChange={this.handleStackingChange}
			/>
		);
	}
}

const _applyDataTransformOnStackingOption = (data, stackingOptionName) => {
	return [].concat(data).reduce(reduceToFlatQuestionData(stackingOptionName), []);
};

QuestionsByVolumeChart.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	data: PropTypes.array
};

export default QuestionsByVolumeChart;